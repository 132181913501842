import React, { Component } from 'react';
import DataTableWithServerSizePagination from '../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
import * as sapActualsActions from '../../../store/actions/sapActuals';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import monthsLong from '../../../constants/monthsLong';
const defaultColumns = [
    {
        header: "Activity Year",
        dataProperty: 'activityYear',
        isVisible: true,
        width: 120
    },
    {
        header: "Local Cost Center",
        dataProperty: 'costCenter',
        isVisible: true,
        width: 120
    },    
    {
        header: "Platform",
        dataProperty: 'domain',
        isVisible: true,
        width: 120
    },
    {
        header: "Sub-platform",
        dataProperty: 'subDomain',
        isVisible: true,
        width: 120
    },
    {
        header: "Activity",
        dataProperty: 'activity',
        isVisible: true,
        width: 120
    },
    {
        header: "WBS",
        dataProperty: 'powbs',
        isVisible: true,
        width: 120
    },
    {
        header: "Expense Type",
        dataProperty: 'expenseType',
        isVisible: true,
        width: 120
    },
    {
        header: "Currency",
        dataProperty: 'currency',
        isVisible: true,
        width: 120
    },
    {
        header: "Period / Month",
        dataProperty: 'month',
        isVisible: true,
        width: 120
    },
    {
        header: "Actuals Amount",
        dataProperty: 'actual',
        isVisible: true,
        width: 120
    },
    {
        header: "Comments /Gr Text",
        dataProperty: 'grText',
        isVisible: true,
        width: 120
    },
]

const mapStateToProps = (state) => {
    const columns = state.sapActuals && state.sapActuals.columns && state.sapActuals.columns.length !== 0 ? state.sapActuals.columns : defaultColumns;
    return {
        tableState: state.table.parsedFilters,
        columns: columns,
        loading: state.sapActuals.loading || state.sapActuals.loading,
        items: state.sapActuals.items && state.sapActuals.items.items ? state.sapActuals.items.items : [],
        currentPage: state.sapActuals.items && state.sapActuals.items.currentPage ? state.sapActuals.items.currentPage : 1,
        count: state.sapActuals.items && state.sapActuals.items.itemsCount ? state.sapActuals.items.itemsCount : 0,
    }
};

const mapDispatchToProps = (dispatch) => ({
    loadView: (payload) => {
        dispatch(sapActualsActions.getSapActualsStarted({params: payload.queryParameters}));
        dispatch(sapActualsActions.getSapActualsColumnsStarted(payload))
    },
    updateColumns: (payload) => {
        dispatch(sapActualsActions.updateSapActualsColumnsStarted(payload));
    },
    getColumns: (payload) => {
        dispatch(sapActualsActions.getSapActualsColumnsStarted(payload))
    },
    getItems: (payload) => {
        dispatch(sapActualsActions.getSapActualsStarted(payload))
    }
});

const years = [];
const currentYear = new Date().getFullYear();
years.push({label: currentYear -5, value: currentYear -5});
years.push({label: currentYear -4, value: currentYear -4});
years.push({label: currentYear -3, value: currentYear -3});
years.push({label: currentYear -2, value: currentYear -2});
years.push({label: currentYear -1, value: currentYear -1});
years.push({label: currentYear, value: currentYear});
for (let i = 1; i <= 4; i++) {
    const year = currentYear + i;
    years.push({label: year, value: year});
}
const months = monthsLong.map((month, index) => {
    return {
        label: month,
        value: index +1
    }
})


class SapActualsContainer extends Component {

    componentDidMount() {
        const tableState = this.props.tableState[this.getTableName(this.props.selectedDomain)];
        if (tableState) {
            this.props.loadView({
                queryParameters: {...tableState},
            });
        } else {
            this.props.loadView({
                queryParameters: {},
            });
        }
    }
    getTableName = () => {
        return `sapActuals`
    }


    onFetchData = (data, wait) => {
        const queryParameters = data;
        this.props.getItems({params: queryParameters, wait: wait});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({columns: payload});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({columns: payload});
    }


    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleEditClick, label: 'no actions'}
                    ]
                }
                rowData={data}
            />
        )
    }
    render() {
        return (
            <React.Fragment>
                <div style={{marginRight: 48, marginTop: 32} }>
                <DataExportContainer url="api/export/sapActuals"/>
                <DataTableWithServerSizePagination
                    sortable
                    tableName={this.getTableName()}
                    minWidth={980}
                    customColumnsWidthEnabled={true}
                    loading={this.props.loading}
                    count={this.props.count}
                    general={{}}
                    columns={this.props.columns}
                    onFetchData={this.onFetchData}
                    data={this.props.items}   
                    actionMenu={this.createActionMenu}
                    onColumnsOrderChanged={this.onColumnOrderChanged}
                    onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                    currentPage={this.props.currentPage}
                    customFilters={{
                        activityYear: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="activityYear"
                                    label="Activity Year"
                                    small
                                    width="100%"
                                    minWidth={100}
                                    suggestions={years}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                        month: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="month"
                                    label="Period / Month"
                                    small
                                    width="100%"
                                    minWidth={100}
                                    suggestions={months}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        },
                    }
                }
                />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SapActualsContainer);