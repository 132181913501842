import React, { Component } from 'react';
import { connect } from 'react-redux';
import LastModifiedBy from './components/LastModifiedBy';
import formatDate from '../../../utils/dateFormatter';

const mapStateToProps = (state) => {
    return {
        lastModifiedBy: state.regionalChangelogForm.lastModifiedBy ? `${state.regionalChangelogForm.lastModifiedBy.name} (${state.regionalChangelogForm.lastModifiedBy.login}) \n ${formatDate(state.regionalChangelogForm.lastModifiedDate)}` : "",
    }
}

class LastModifiedByContainer extends Component {
    render() {
        return (
            this.props.lastModifiedBy ? <LastModifiedBy lastModifiedBy={this.props.lastModifiedBy}/> : null
        )
    }
}
export default connect(mapStateToProps, null)(LastModifiedByContainer);