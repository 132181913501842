import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreatedBy from './components/CreatedBy';
const mapStateToProps = (state) => {
    return {
        createdBy: state.deliveryConfirmationForm.createdBy,
    }
}
class CreatedByContainer extends Component {
    render() {
        return (
            <CreatedBy createdBy={this.props.createdBy}/>
        )
    }
}
export default connect(mapStateToProps, null)(CreatedByContainer);