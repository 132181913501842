import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as vendorRateActions from '../../../../store/actions/vendorRates';
import assignmentSites from '../../../../constants/assignmentSites';
import VendorRateData from './VendorRateData';
import resourceTypes from '../../../../constants/resourceTypes';
import { isVendorTypeExternal } from '../../../../utils/typesAssertions';

const assignmentSiteSuggestions = assignmentSites.map((assignmentSite, index) => {
    return {
        label: assignmentSite,
        value: index
    }
})
const rateYears = [];
const currentYear = new Date().getFullYear();
for (let i = currentYear -1; i < currentYear + 3; i++) {
    rateYears.push({
        label: i,
        value: i
    })
}
const getVendors = (state) => {
    const hasResourceTypeVendorType = state.resourceTypeVendorType.resourceTypes && state.resourceTypeVendorType.resourceTypes.length !==0
    let vendors = [];
    if (hasResourceTypeVendorType) {
        if (state.vendorRateForm.resourceType && state.vendorRateForm.resourceType.label) {
            const selectedResourceTypeVendorType = state.resourceTypeVendorType.resourceTypes.find((item) => {
                return item.resourceType === state.vendorRateForm.resourceType.value
            })
            if (selectedResourceTypeVendorType && isVendorTypeExternal(selectedResourceTypeVendorType.vendorType)) {
                vendors = state.vendorRateForm.externalVendors;
            }
            if (selectedResourceTypeVendorType && !isVendorTypeExternal(selectedResourceTypeVendorType.vendorType)) {
                vendors = state.vendorRateForm.internalVendors;
            }
        }
    }
    return vendors;
}
const mapStateToProps = (state) => {
    const vendors = getVendors(state);
    return {
        data: state.vendorRateForm,
        currencies: state.currencies.items && state.currencies.length !== 0 ? state.currencies.items.map((currency) => {
            return {
                label: currency.name,
                value: currency.id
            }
        }) : [],
        resourceLocations: state.resourceLocations.items && state.resourceLocations.items.length !==0 ?
         state.resourceLocations.items.map((resourceLocation) => {
             return {
                 label: resourceLocation.name,
                 value: resourceLocation.id
             }
         }) : [],
        grades: state.grades.items && state.grades.items.length !== 0 ? state.grades.items.map((grade) => {
            return {
                label: grade.name,
                value: grade.id
            }
        }) : [],
        resourceTypes: state.resourceTypeVendorType.resourceTypes && state.resourceTypeVendorType.resourceTypes.length !==0 ?
        state.resourceTypeVendorType.resourceTypes.map((item) => {
            return {
                label: resourceTypes[item.resourceType],
                value: item.resourceType
            }
        }) : [],
        vendors: vendors && vendors.length !== 0 ? 
            vendors.map((vendor) => {
                return {
                    label: vendor.name,
                    value: vendor.id
                }
            }) : [],
        errors: state.vendorRateForm.errors ? state.vendorRateForm.errors : {},    
}};
  
  const mapDispatchToProps = (dispatch) => ({
    vendorRatesFormVendorChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormVendorChanged(payload)),
    vendorRatesFormRateYearChanged: (payload) =>
        dispatch(vendorRateActions.vendorRatesFormRateYearChanged(payload)),    
    vendorRatesFormResourceTypeChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormResourceTypeChanged(payload)),
    vendorRatesFormAssignmentSiteChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormAssignmentSiteChanged(payload)),
    vendorRatesFormResourceLocationChanged: (payload) =>
        dispatch(vendorRateActions.vendorRatesFormResourceLocationChanged(payload)),
    vendorRatesFormGradeChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormGradeChanged(payload)),
    vendorRatesFormUnitPriceChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormUnitPriceChanged(payload.target.value)),    
    vendorRatesFormPerDiemRateChanged: (payload) =>
        dispatch(vendorRateActions.vendorRatesFormPerDiemRateChanged(payload.target.value)),
    vendorRatesFormCurrencyChanged: (payload) => 
        dispatch(vendorRateActions.vendorRatesFormCurrencyChanged(payload)),
    vendorRatesFormInactiveChanged: (payload) =>
        dispatch(vendorRateActions.vendorRatesFormInactiveChanged(payload.target.checked))    
});

class VendorRateDataContainer extends Component {
    render() {
        return (
            <VendorRateData {...this.props} assignmentSites={assignmentSiteSuggestions} rateYears={rateYears}/>
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(VendorRateDataContainer);