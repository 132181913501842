import React from 'react';
import AutoSelect from './AutoSelect';

export default (props) => {
    const {additionalData, handleChangeSingle, ...other} = props;
    const handleChangeSingleWithAdditonalData = (data) => {
        handleChangeSingle(data, additionalData);
    }
    return (
        <AutoSelect {...other} handleChangeSingle={handleChangeSingleWithAdditonalData}/>
    )
}