import * as actionTypes from '../actions/actionTypes';

const initialState = {
    open: false,
    message: '',
    doNotChangeMessage: false,
    key: ''

}
const snackBarReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SNACKBAR_CHANGED: {
            return {
                open: action.payload.open,
                message: action.payload.message || '',
                key: action.payload.key || ''
            }
        }
        default: {
            return state;
        }
    }
}
export default snackBarReducer;