import { put, takeEvery, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as costCenterActions from '../store/actions/costCenters';
import * as navbarActionCreators from '../store/actions/Navbar';
import axios from '../axios';

export function* getAllCostCenters() {
    try {
        const response = yield axios.get('api/costCenters?withDeleted=true');
        yield put(costCenterActions.getAllCostCentersFinished(response.data))
    } catch(error) {
        yield put(costCenterActions.getAllCostCentersFailed())
    }
}

export function* getCostCenters(optionalId) {
    try {
        const response = yield axios.get('api/costCenters');
        let costCenter = response.data.find((costCenter) => {
            return costCenter.id === optionalId;
        })
        if (!costCenter && optionalId) {
            costCenter = yield axios.get('api/costCenters/' + optionalId);
            const costCenters = [...response.data, costCenter];
            yield put(costCenterActions.getCostCentersFinished(costCenters))
        }
        yield put(costCenterActions.getCostCentersFinished(response.data))
    } catch(error) {
        yield put(costCenterActions.getCostCentersFailed())
    }
} 

export function* editCostCenter(action) {
    try {
        yield axios.put('api/costCenters', action.payload);
        yield* getAllCostCenters();
        yield put(costCenterActions.editCostCenterFinished(action.payload.id));
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(costCenterActions.editCostCenterFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}
export function* addCostCenter(action) {
    try {
        const newItem = yield select(store => store.costCenters.newItemObject);
        yield axios.post('api/costCenters', newItem);
        yield* getAllCostCenters();
        yield put(costCenterActions.addCostCenterFinished());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(costCenterActions.addCostCenterFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}
const sagas =  [
    takeEvery(actionTypes.GET_ALL_COST_CENTERS_STARTED, getAllCostCenters),
    takeEvery(actionTypes.EDIT_COST_CENTER_STARTED, editCostCenter),
    takeEvery(actionTypes.ADD_COST_CENTER_STARTED, addCostCenter)
]
export default sagas