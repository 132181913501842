import * as actionTypes from './actionTypes';

export const getChangelogColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_COLUMNS_STARTED, payload: myParam};
};
export const getChangelogColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_COLUMNS_FAILED, payload: myParam};
};
export const getChangelogColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_COLUMNS_FINISHED, payload: myParam};
};

export const getChangelogsStarted =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOGS_STARTED, payload: myParam};
};
export const getChangelogsFailed =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOGS_FAILED, payload: myParam};
};
export const getChangelogsFinished =  (myParam) => {
    return {type: actionTypes.GET_CHANGELOGS_FINISHED, payload: myParam};
};

export const updateChangelogColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_CHANGELOG_COLUMNS_STARTED, payload: myParam};
};
export const updateChangelogColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_CHANGELOG_COLUMNS_FAILED, payload: myParam};
};
export const updateChangelogColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_CHANGELOG_COLUMNS_FINISHED, payload: myParam};
};
export const resetView = (myParam) => {
    return {type: actionTypes.GET_CHANGELOGS_FINISHED, payload: []}
}

export const intializeChangelogListViewStarted = (myParam) => {
    return {type: actionTypes.INITIALIZE_CHANGELOG_LIST_VIEW_STARTED, payload: myParam};
}
export const initializeChangelogListViewFailed = (myParam) => {
    return {type: actionTypes.INITIALIZE_CHANGELOG_LIST_VIEW_FAILED, payload: myParam};
}
export const initializeChangelogListViewFinished = (myParam) => {
    return {type: actionTypes.INITIALIZE_CHANGELOG_LIST_VIEW_FINISHED, payload: myParam};
}

export const changelogListViewLoadingSpinnerChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_LIST_VIEW_LOADING_SPINNER_CHANGED, payload: myParam};
}
