
import * as actionTypes from './actionTypes';


export const getAllPoLocationsStarted =  (myParam) => {
    return {type: actionTypes.GET_ALL_PO_LOCATIONS_STARTED, payload: myParam};
};
export const getAllPoLocationsFinished =  (myParam) => {
    return {type: actionTypes.GET_ALL_PO_LOCATIONS_FINISHED, payload: myParam};
};
export const getAllPoLocationsFailed =  (myParam) => {
    return {type: actionTypes.GET_ALL_PO_LOCATIONS_FAILED, payload: myParam};
};

export const getPoLocationsStarted =  (myParam) => {
    return {type: actionTypes.GET_PO_LOCATIONS_STARTED, payload: myParam};
};
export const getPoLocationsFinished =  (myParam) => {
    return {type: actionTypes.GET_PO_LOCATIONS_FINISHED, payload: myParam};
};
export const getPoLocationsFailed =  (myParam) => {
    return {type: actionTypes.GET_PO_LOCATIONS_FAILED, payload: myParam};
};

export const addPoLocationStarted =  (myParam) => {
    return {type: actionTypes.ADD_PO_LOCATION_STARTED, payload: myParam};
};
export const addPoLocationFailed =  (myParam) => {
    return {type: actionTypes.ADD_PO_LOCATION_FAILED, payload: myParam};
};
export const addPoLocationFinished =  (myParam) => {
    return {type: actionTypes.ADD_PO_LOCATION_FINISHED, payload: myParam};
};

export const deletePoLocationStarted =  (myParam) => {
    return {type: actionTypes.DELETE_PO_LOCATION_STARTED, payload: myParam};
};
export const deletePoLocationFailed =  (myParam) => {
    return {type: actionTypes.DELETE_PO_LOCATION_FAILED, payload: myParam};
};
export const deletePoLocationFinished =  (myParam) => {
    return {type: actionTypes.DELETE_PO_LOCATION_FINISHED, payload: myParam};
};

export const editPoLocationStarted =  (myParam) => {
    return {type: actionTypes.EDIT_PO_LOCATION_STARTED, payload: myParam};
};
export const editPoLocationFailed =  (myParam) => {
    return {type: actionTypes.EDIT_PO_LOCATION_FAILED, payload: myParam};
};
export const editPoLocationFinished =  (myParam) => {
    return {type: actionTypes.EDIT_PO_LOCATION_FINISHED, payload: myParam};
};

export const newPoLocationNameChanged =  (myParam) => {
    return {type: actionTypes.NEW_PO_LOCATION_NAME_CHANGED, payload: myParam};
};
export const newPoLocationNumberChanged =  (myParam) => {
    return {type: actionTypes.NEW_PO_LOCATION_NUMBER_CHANGED, payload: myParam};
};
export const editPoLocationName =  (myParam) => {
    return {type: actionTypes.EDIT_PO_LOCATION_NAME, payload: myParam};
};
export const editPoLocationNumber =  (myParam) => {
    return {type: actionTypes.EDIT_PO_LOCATION_NUMBER, payload: myParam};
};
