import React, { Component } from 'react';
import ResourcesContainer from './ChangeLogCreation/ResourcesContainer';
import DeliveryInformationContainer from './ChangeLogCreation/DeliveryInformationContainer';
import SupportingDocumentationContainer from './ChangeLogCreation/SupportingDocumentationContainer';
import RolesContainer from './ChangeLogCreation/RolesContainer';
import CommentsContainer from './ChangeLogCreation/CommentsContainer';
import FormConfirmationContainer from './ChangeLogCreation/FormConfirmationContainer';
import FormSubmitPanelContainer from './ChangeLogCreation/FormSubmitPanelContainer';
import StatusContainer from './ChangeLogEdit/ChangelogStatusContainer';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as changelogFormActionsCreators from '../../../store/actions/changeLogFormActions';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SummaryContainer from './ChangeLogCreation/SummaryContainer';
import TagContainer from './ChangeLogCreation/TagContainer';
import Can from '../../../config/Can';
import * as rules from '../../../config/Rules';

const styles = {
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.changelogData.loading || state.changelogForm.submitting,
        role: state.user.selectedRole
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: () => {
        dispatch(changelogFormActionsCreators.changeLogFormInitialize());
      },
      resetForm: () => {
        dispatch(changelogFormActionsCreators.resetForm());
      }
  });


class ChangeLogCreation extends Component {
    componentDidMount() {
        this.props.loadView();
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render() {
        return (
            <React.Fragment>
                <SummaryContainer/>
                <div className={this.props.classes.container}>
                    <LoadingSpinner loading={this.props.loading} spinnerCentered={true}/>
                    <Grid container className="form-grid" spacing={2} ref={this.gridRef}>
                        <Can 
                            role={this.props.role}
                            perform={rules.EDIT_CHANGELOG_STATUS}
                            yes={() => {
                                return (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <StatusContainer />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                        </Grid> */}
                                    </React.Fragment>
                                )
                            }}
                        />
                        <Can 
                            role={this.props.role}
                            perform={rules.EDIT_FORM_TAGS}
                            yes={() => {
                                return (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <TagContainer />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                        </Grid> */}
                                    </React.Fragment>
                                )
                            }}
                        />
                        <Grid item md={6} xs={12} id="delivery-confirmation-row">
                            <DeliveryInformationContainer/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <RolesContainer />
                        </Grid>                                   
                        <Grid item xs={12}>
                            <ResourcesContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <SupportingDocumentationContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <CommentsContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <FormConfirmationContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubmitPanelContainer />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ChangeLogCreation);