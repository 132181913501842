import React, { Component } from 'react';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import { connect } from 'react-redux';
import AutoSelect from '../../../../components/Input/AutoSelect';
import formSectionWrapper from '../components/FormSectionWrapper';
import  * as rules  from '../../../../config/Rules';
import Can, {canFunction} from '../../../../config/Can';
import TextWithIcon from '../../../../components/Input/TextWithIcon';
import {statusesInternal, statusesExternal} from '../../../../constants/changelogStatuses';
import Flag from '@material-ui/icons/FlagOutlined';
import {isVendorTypeExternal} from '../../../../utils/typesAssertions';
import withStyles from '@material-ui/styles/withStyles';
import { compose } from 'redux';
import {getChangelogChanges} from '../../../../store/reducers/ChangeLogFormReducer'

const mapStateToProps = (state) => {
    return {
        status: state.changelogForm.status,
        selectedRole: state.user.selectedRole,
        isChangelogInternal: state.changelogForm.vendorType,
        editMode: state.changelogForm.editMode,
        changes: getChangelogChanges(state)
    }
}
const styles = {
    inputUpdated: {
        border: '1px solid #39acff',
        backgroundColor: '#e1f5fe',
        borderRadius: 16,
        padding: 5,
        // display: 'inline-block'
    }
}
const mapDispatchToProps = (dispatch) => ({
    statusChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormStatusChanged(payload))        
    }
);

class ChangelogStatusContainer extends Component {
    render() {
        let statusSuggestions = []
        if (this.props.isChangelogInternal && !isVendorTypeExternal(this.props.isChangelogInternal.value)) {
            statusSuggestions = statusesInternal;
        } else if (this.props.isChangelogInternal && isVendorTypeExternal(this.props.isChangelogInternal.value)) {
            statusSuggestions = statusesExternal;
        }
        return (
            <div style={{position: 'relative', paddingTop: canFunction(this.props.selectedRole, rules.EDIT_CHANGELOG_STATUS) ? 8 : 20}}>
                    <Can 
                        role={this.props.selectedRole}
                        perform={rules.EDIT_CHANGELOG_STATUS}
                        yes={() => {
                            return (
                                <React.Fragment>
                                    {this.props.editMode || !this.props.editing ?
                                    <AutoSelect 
                                        label="Status" 
                                        width="100%" 
                                        suggestions={statusSuggestions} 
                                        value={this.props.status} 
                                        handleChangeSingle={this.props.statusChanged}
                                        className={this.props.changes.status ? "input-updated": ""}
                                        tooltipText={this.props.changes.status}
                                    />
                                    : <TextWithIcon className={this.props.changes.status ? this.props.classes.inputUpdated: null} tooltipText={this.props.changes.status} label="Status" value={this.props.status.label} icon={Flag}/>
                                    }
                                </React.Fragment>
                            );
                        }}
                        no ={() => {
                            return (
                                <TextWithIcon className={this.props.changes.status ? this.props.classes.inputUpdated: null} tooltipText={this.props.changes.status} label="Status" value={this.props.status.label} icon={Flag}/>
                            );
                        }}
                    />
            </div>
        )
    }
}
export default formSectionWrapper(compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ChangelogStatusContainer));