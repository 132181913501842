import * as actionTypes from '../actions/actionTypes';

const initialState = {
    wbses: {},
    loading: false,
    columns: []
}
const wbsViewerReducer = (state = initialState, action) => {
    switch(action.type) {
        // case actionTypes.GET_WBS_LIST_STARTED: {
        //     return {
        //         ...initialState,
        //         loading: true,
        //     }
        // }
        case actionTypes.WBS_LIST_CHANGE_LOADING_SPINNER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.GET_WBS_LIST_FINISHED: {
            return {
                ...state,
                loading: false,
                wbses: action.payload
            }
        }
        case actionTypes.GET_WBS_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                wbses: {}
            }
        }
        case actionTypes.UPDATE_WBS_COLUMNS_STARTED:
        case actionTypes.UPDATE_WBS_COLUMNS_FAILED:    
        case actionTypes.UPDATE_WBS_COLUMNS_FINISHED:
        case actionTypes.GET_WBS_COLUMNS_FAILED:  
        case actionTypes.GET_WBS_COLUMNS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_WBS_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default wbsViewerReducer;