const awsmobile = {
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      storage: sessionStorage
    },
    Analytics: {
      disabled: true
    },
  };
  
  export default awsmobile;