
import * as actionTypes from './actionTypes';

export const activityFormInactiveChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_INACTIVE_CHANGED, payload: myParam};
}
export const activityFormActivityNameChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_ACTIVITY_NAME_CHANGED, payload: myParam}
}

export const activityFormWorkIdChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_WORK_ID_CHANGED, payload: myParam}
}

export const activityFormPlannerChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_PLANNER_CHANGED, payload: myParam}
}

export const activityFormLeaderChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_LEADER_CHANGED, payload: myParam}
}

export const activityFormSourceDataLinkChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_SOURCE_DATA_LINK_CHANGED, payload: myParam} 
}
export const activityFormErrorsChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_ERRORS_CHANGED, payload: myParam}
}

export const getActivitiesListStarted = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_LIST_STARTED, payload: myParam};
}
export const getActivitiesListFinished = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_LIST_FINISHED, payload: myParam};
}
export const getActivitiesListFailed = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_LIST_FAILED, payload: myParam};
}

export const getActivitiesColumnsStarted = (myParam) => {
    return {type: actionTypes.GET_ACTIVITES_COLUMNS_STARTED, payload: myParam};
}
export const getActivitiesColumnsFailed = (myParam) => {
    return {type: actionTypes.GET_ACTIVITES_COLUMNS_FAILED, payload: myParam};
}
export const getActivitiesColumnsFinished = (myParam) => {
    return {type: actionTypes.GET_ACTIVITES_COLUMNS_FINISHED, payload: myParam};
}

export const updateActivitiesColumnsStarted = (myParam) => {
    return {type: actionTypes.UPDATE_ACTIVITIES_COLUMNS_STARTED, payload: myParam};
}
export const updateActivitiesColumnsFailed = (myParam) => {
    return {type: actionTypes.UPDATE_ACTIVITIES_COLUMNS_FAILED, payload: myParam};
}
export const updateActivitiesColumnsFinished = (myParam) => {
    return {type: actionTypes.UPDATE_ACTIVITIES_COLUMNS_FINISHED, payload: myParam};
}

export const submitEditActivityFormStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_ACTIVITY_FORM_STARTED, payload: myParam};
}
export const submitEditActivityFormFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_ACTIVITIY_FORM_FAILED, payload: myParam};
}
export const submitEditActivityFormFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_ACTIVITIY_FORM_FINISHED, payload: myParam};
}

export const submitCreateActivityFormStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_CREATE_ACTIVITY_FORM_STARTED, payload: myParam};
}
export const submitCreateActivityFormFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_CREATE_ACTIVITIY_FORM_FAILED, payload: myParam};
}
export const submitCreateActivityFormFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_CREATE_ACTIVITIY_FORM_FINISHED, payload: myParam};
}

export const resetActivityForm = (myParam) => {
    return {type: actionTypes.RESET_ACTIVITY_FORM, payload: myParam};
}
export const activityFormInitializeStarted = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_INITIALIZE_STARTED, payload: myParam};
}
export const activityFormInitializeFailed = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_INITIALIZE_FAILED, payload: myParam};
}
export const activityFormInitializeFinished = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_INITIALIZE_FINISHED, payload: myParam};
}

export const activityEditFormInitializeStarted = (myParam) => {
    return {type: actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_STARTED, payload: myParam};
}
export const activityEditFormInitializeFailed = (myParam) => {
    return {type: actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_FAILED, payload: myParam};
}
export const activityEditFormInitializeFinished = (myParam) => {
    return {type: actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_FINISHED, payload: myParam};
}
export const activityFormIdChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_ID_CHANGED, payload: myParam}
}

export const activityFormProjectNumberChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_PROJECT_NUMBER_CHANGED, payload: myParam}
}
export const activityFormDemandNumberChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_DEMAND_NUMBER_CHANGED, payload: myParam}
}
export const activityFormLegacyNumberChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_LEGACY_NUMBER_CHANGED, payload: myParam}
}

export const activityFormActivityTypeChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_ACTIVITY_TYPE_CHANGED, payload: myParam};
}

export const activityFormClientIdChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_CLIENT_ID_CHANGED, payload: myParam};
}
export const activitiesListLoadingSpinnerChanged = (myParam) => {
    return {type: actionTypes.ACTIVITIES_LIST_LOADING_SPINNER_CHANGED, payload: myParam};
}

export const activityFormRegionTypeChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_REGION_TYPE_CHANGED, payload: myParam};
}

export const activityFormDomainChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_DOMAIN_CHANGED, payload: myParam}
}
export const activityFormSubDomainChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_SUB_DOMAIN_CHANGED, payload: myParam}
}
export const activityFormCostCenterChanged = (myParam) => {
    return {type: actionTypes.ACTIVITY_FORM_COST_CENTER_CHANGED, payload: myParam}
}