import * as actionTypes from '../actions/actionTypes';

const initialState = {
    changelogs: {},
    columns: [],
    loading: false,
}

const changelogReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CHANGELOG_LIST_VIEW_LOADING_SPINNER_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        // case actionTypes.GET_CHANGELOGS_STARTED: {
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // }
        case actionTypes.GET_CHANGELOGS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.GET_CHANGELOGS_FINISHED: {
            return {
                ...state,
                loading: false,
                changelogs: action.payload
            }
        } 
        case actionTypes.GET_CHANGELOG_COLUMNS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_CHANGELOG_COLUMNS_FAILED: {
            return {
                ...state,
                columns: [],
                loading: false
            }
        }
        case actionTypes.GET_CHANGELOG_COLUMNS_FINISHED: {
            return {
                ...state,
                loading: false,
                columns: action.payload
            }
        }
        case actionTypes.UPDATE_CHANGELOG_COLUMNS_STARTED: {
            return {
                ...state,
                loading: true,
            }
        }
        case actionTypes.UPDATE_CHANGELOG_COLUMNS_FAILED: {
            return {
                ...state,
                loading: false,

            }
        }
        case actionTypes.UPDATE_CHANGELOG_COLUMNS_FINISHED: {
            return {
                ...state,
                loading: false,
            }
        }
        default: {
            return state
        }
    }
}
export default changelogReducer;