import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import Input from '../../../components/Input/Input';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/poLocation';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
const mapStateToProps = (state) => {
    return {
        poLocations: state.poLocation.allPoLocations || [],
        selectedItems: state.poLocation.selectedItems,
        loading: state.poLocation.loading,
        newItemNumber: state.poLocation.newItemNumber,
        newItemName: state.poLocation.newItemName,
        numberBeingEditedMap: state.poLocation.numberBeingEditedMap,
        nameBeingEditedMap: state.poLocation.nameBeingEditedMap,
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    addPoLocation: (payload) => 
        dispatch(actionCreators.addPoLocationStarted(payload)),
    deletePoLocation: (data, id) => 
        dispatch(actionCreators.deletePoLocationStarted(id)),
    editPoLocation: (payload) => 
        dispatch(actionCreators.editPoLocationStarted(payload)),
    newItemNameChanged: (event) => {
        dispatch(actionCreators.newPoLocationNameChanged(event))
    },
    newItemNumberChanged: (domain) => 
        dispatch(actionCreators.newPoLocationNumberChanged(domain)),
    editPoLocationName: (event) =>
        dispatch(actionCreators.editPoLocationName(event)),
    editPoLocationNumber: (event) =>
        dispatch(actionCreators.editPoLocationNumber(event))
});

class PoLocationPickList extends Component {
    state = {
        filtered: {
        }
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (row[key].toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                }
            })
            return isValid;
        })
    }
    addItem = (event) => {
        if (event.target.value.length === 0) {
            return;
        }
        this.props.addPoLocation({number: this.props.newItemNumber, name: event.target.value});
    }

    editPoLocation = (event, prop) => {
        const targetPoLocation = this.props.poLocations.find(poLocation => poLocation.id === parseInt(event.target.name));
        if (targetPoLocation[prop] !== event.target.value) {
            const editedPoLocation = {
                ...targetPoLocation,
                [prop]: event.target.value
            }
            this.props.editPoLocation(editedPoLocation);
        }
    }
    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.addItem(event);
        }
    }
    handleEnterOnEditInput = (event, prop) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.editPoLocation(event, prop);
        }
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.poLocations.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.editPoLocation(copy)
    }
    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    render() {
        const filteredData = this.filterData(this.props.poLocations);
        return (
            <div>
                <DataExportContainer url="api/export/poLocations"/>
                <DataTable
                // key={this.props.count}
                loading={this.props.loading}
                filtered={this.state.filtered}
                onFilteredChanged={this.onFilteredChanged}
                enabledAddition
                data={filteredData}  
                general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                onSelectedChanged={this.selectedItemsChanged}
                selected={this.props.selectedItems}
                addNewItemRenderer= { (dataProperty) => {
                    if (dataProperty !== 'name') {
                        return (
                            <Input
                                width={292}
                                value={this.props.newItemNumber}
                                inputProps={{name: dataProperty}}
                                onChange={this.props.newItemNumberChanged}
                            />
                        );
                    }
                    return <Input 
                                width={292} 
                                placeholder="Add new..."
                                onBlur={this.addItem}
                                inputProps={{name: dataProperty}}
                                disabled={this.props.newItemNumber === ''}
                                value={this.props.newItemName}
                                onChange={this.props.newItemNameChanged}
                                onKeyPress={this.handleEnterOnAddInput}
                            />
                    }
                }
                customRowCells={{
                    name: (data, id, row) => {
                        return (
                        <Input width={292} 
                            // onDeleteIconClicked={this.props.deletePoLocation.bind(this, data, id, row)}
                            onBlur={(e)=>{this.editPoLocation(e, "name")}}
                            inputProps={{name: id}}
                            value={this.props.nameBeingEditedMap[id] ? this.props.nameBeingEditedMap[id]: data}
                            onChange={this.props.editPoLocationName}
                            onKeyPress={(e)=>{this.handleEnterOnEditInput(e, "name")}}
                        />
                        )
                    },
                    number: (data, id, row) => {
                        return (
                            <Input
                                
                                width={292}
                                inputProps={{name: id}}
                                value={this.props.numberBeingEditedMap[id] ? this.props.numberBeingEditedMap[id]: data}
                                onChange={this.props.editPoLocationNumber}
                                onBlur={(e)=>{this.editPoLocation(e, "number")}}
                                onKeyPress={(e) => {this.handleEnterOnEditInput(e, "number")}}
                            />
                        )
                    },
                }}
                columns={[
                    {
                        header:"Number",
                        dataProperty:"number",
                        isVisible: true
                    },
                    {
                        header:"Name",
                        dataProperty:"name",
                        isVisible: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PoLocationPickList);