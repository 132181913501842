import React from "react";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  tooltipWrapper: {
      '&:hover': {
      '& .tooltip': {
          zIndex:1
      }
  }
  }
});
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isChromiumEdge = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
const autocompleteString = isChromiumEdge ? "nope" : "off";
function Input(props) {
    const classes = useStyles();
    //create classNames
    //small is used separately because I didn't anticipate I will need more customization
    //TODO: refactor small props here and in autoselect component
    const classNames = [];
    if (props.className) {
      classNames.push(props.className);
    }
    if (props.small) {
      classNames.push("small");
    }
    const classNamesString = classNames.join(" ");

    const [values, setValues] = React.useState({
        mouseOver: false,
        focused: false,
        value: ''
      });
    
      const handleChange = (prop, val) => event => {
        setValues({ ...values, [prop]: val });
      };

      const handleFocus = (prop, val) => event => {
        setValues({ ...values, [prop]: val });
        if (props.onFocus) {
            props.onFocus(event);
        }
      };
      
      const handleBlur = (prop, val) => event => {
        if (event.target.value === '.' && props.type === 'number') {
          event.target.value = '';
          setValues({...values, value: ''})
          if (props.onChange) {
            props.onChange(event);
          }
        }
        setValues({ ...values, [prop]: val });
        if (props.onBlur) {
            props.onBlur(event);
        }
      };
      const isAdornmentVisible = !values.focused && values.mouseOver && props.onDeleteIconClicked;

      const {onDeleteIconClicked, small, minWidth, errorText, adornmentAlwaysVisible, helpProperty, tooltipText, ...other} = props;
      function onChange(event) {
        if (props.type === 'number') {
          checkDec(event);
        }
        if (props.onChange) {
          props.onChange(event);
        }
      }

      function checkDec(event) {
        var el = event.target
        el.value = el.value.replace(/,/g, '.')
        var ex = /^-?\d*[.]?\d{0,2}$/;
        if (props.precision === 3) {
          ex = /^-?\d*[.]?\d{0,3}$/;
        }
        if (ex.test(el.value)===false) {
          el.value = values.value;
        }
        setValues({...values, value: el.value})
       }
       function clickOnDeleteIcon(event) {
        onDeleteIconClicked({target: {name: other.inputProps && other.inputProps.name ? other.inputProps.name : "", value: ''}})
        if (props.type === 'number') {
          setValues({...values, value: ''})
        }
       }
  let input = (
      <div style={{ width: props.width ? props.width :'100%', minWidth: minWidth,position:'relative'}} className={classes.tooltipWrapper}>
        <FormControl className={props.errorText ? "error" : ""} style={{ width: '100%', position: 'relative'}} fullWidth={props.fullWidth}>
          {props.label ? (
            <InputLabel className={classNamesString} shrink>
              {props.label}
              {helpProperty !== undefined ?
                <span style={{position: 'absolute', cursor:'pointer', paddingLeft: 4}} className="tooltip">
                  <Tooltip interactive title={<span>If you are not able to find your {helpProperty} please contact <a href="mailto:ISDBackOffice.PMI@pmi.com" style={{color: 'inherit'}}>backoffice team</a></span>}>
                      <HelpOutlineIcon className="help"/>
                  </Tooltip> 
                </span> : null
              }
              </InputLabel>
          ) : null}
          <InputBase
            {...other}
            type="text"
            onChange={onChange}
            autoComplete={autocompleteString}
            onFocus={handleFocus('focused', true)}
            onBlur={handleBlur('focused', false)}
            onMouseEnter={handleChange('mouseOver', true)}
            onMouseLeave={handleChange('mouseOver', false)}
            className={classNamesString}
            multiline={props.disabled && !props.inputComponent}
            // Todo: create styles for normal input adornment (only small will display correctly now)
            endAdornment={((isAdornmentVisible && !adornmentAlwaysVisible) || (adornmentAlwaysVisible && props.value.length !== 0)) ? <InputAdornment position="end">
              <span onClick={clickOnDeleteIcon} style={{marginRight: '9px',lineHeight: '24px',display: 'flex', textAlign: 'center'}}>
                  <Clear className="delete-adornment"/>
              </span>
              </InputAdornment>: null
              }
          />
          <div style={{position: 'absolute', 'bottom': 0, left: 12, color: 'red', fontSize:12, whiteSpace: 'nowrap'}}>{props.errorText}</div>
        </FormControl>
      </div>
  );
  if (props.tooltipText) {
    input = (
      <Tooltip interactive title={props.tooltipText} placement="top">
        {input}
      </Tooltip>
    )
  }
  return input;
}

export default Input;
