import React from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import Input from '../../../components/Input/Input';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import {isPerDiemVisible, isPlannedUnitPrice, isTripplePrecision } from '../../../utils/typesAssertions';

const columns = [
    {
        header: "",
        dataProperty: 'header',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jan",
        dataProperty: '1',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Feb",
        dataProperty: '2',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Mar",
        dataProperty: '3',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Apr",
        dataProperty: '4',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "May",
        dataProperty: '5',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jun",
        dataProperty: '6',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jul",
        dataProperty: '7',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Aug",
        dataProperty: '8',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Sep",
        dataProperty: '9',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Oct",
        dataProperty: '10',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Nov",
        dataProperty: '11',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Dec",
        dataProperty: '12',
        isVisible: true,
        headerOnly: true
    },
]

const ResourceTable = (props) => {
    const changes = props.changes;
    const testData = [{
        'header': 'Planned',
        id:"Planned",
    }, {
        'header': isPlannedUnitPrice(props.resourceType) ? 'Actual number of units' : "Actual amount",
        id: "actualUnitPrice",
    }];
    let poPosition = 2;
    if (props.perDiemEditable) {
        testData.push({
            'header': 'actual number of per diems',
            id:"actualPerDiem"
        })
        poPosition = 3;
    }

    const checkIfOvertimeShouldBeVisibleInViewMode = (overtimeProperty) => {
        if (!props.generateDc || props.reviewMode) {
            return true;
        }
        let shouldBeHidden = true;

        if (props[overtimeProperty]) {
            Object.keys(props[overtimeProperty]).forEach((month) => {
                if (props[overtimeProperty][month] !== undefined && props[overtimeProperty][month] !== null) {
                    shouldBeHidden = false;
                }
            })
        } else {
            shouldBeHidden = true;
        }
        return !shouldBeHidden
    }
    let perDiemAddition = props.perDiemEditable ? 1 : 0;
    let numberOfOvertimes = 0;
    let overtime125Position = 2 + perDiemAddition;
    let overtime150Position = 2 + perDiemAddition;
    let overtime175Position = 2 + perDiemAddition;
    let overtime200Position = 2 + perDiemAddition;
    if (props.overtimeEditable) {
        if (checkIfOvertimeShouldBeVisibleInViewMode("overtime125")) {
            overtime125Position += numberOfOvertimes;
            numberOfOvertimes = numberOfOvertimes + 1;
            testData.push({
                'header': 'overtime 125%',
                id:"overtime125"
            });
        }
        if (checkIfOvertimeShouldBeVisibleInViewMode("overtime150")) {
            overtime150Position += numberOfOvertimes;
            numberOfOvertimes = numberOfOvertimes + 1;
            testData.push({
                'header': 'overtime 150%',
                id:"overtime150"
            });
        }
        if (checkIfOvertimeShouldBeVisibleInViewMode("overtime175")) {
            overtime175Position += numberOfOvertimes;
            numberOfOvertimes = numberOfOvertimes + 1;
            testData.push({
                'header': 'overtime 175%',
                id:"overtime175"
            });
        }
        if (checkIfOvertimeShouldBeVisibleInViewMode("overtime200")) {
            overtime200Position += numberOfOvertimes;
            numberOfOvertimes = numberOfOvertimes + 1;
            testData.push({
                'header': 'overtime 200%',
                id:"overtime200"
            });
        }
        poPosition = poPosition + numberOfOvertimes;
    }
    testData.push({
        'header': 'PO',
        id: "PO",
    });
    for (let i = 1; i <= 12; i++) {
        if (props.planned && props.planned[parseInt(i)] !== undefined) {
            testData[0][parseInt(i)] = props.planned[parseInt(i)];
        }
        if (props.actualUnitPrice && props.actualUnitPrice[parseInt(i)] !== undefined) {
            testData[1][parseInt(i)] = props.actualUnitPrice[parseInt(i)];
        }
        if (props.perDiemEditable && props.actualPerDiem && props.actualPerDiem[parseInt(i)] !== undefined) {
            testData[2][parseInt(i)] = props.actualPerDiem[parseInt(i)];
        }
        if (props.overtime125 && props.overtime125[parseInt(i)] !== undefined) {
            testData[overtime125Position][parseInt(i)] = props.overtime125[parseInt(i)];
        }
        if (props.overtime150 && props.overtime150[parseInt(i)] !== undefined) {
            testData[overtime150Position][parseInt(i)] = props.overtime150[parseInt(i)];
        }
        if (props.overtime175 && props.overtime175[parseInt(i)] !== undefined) {
            testData[overtime175Position][parseInt(i)] = props.overtime175[parseInt(i)];
        }
        if (props.overtime200 && props.overtime200[parseInt(i)] !== undefined) {
            testData[overtime200Position][parseInt(i)] = props.overtime200[parseInt(i)];
        }
        if (props.po && props.po[parseInt(i)] !== undefined) {
            testData[parseInt(poPosition)][parseInt(i)] = props.po[parseInt(i)];
        }
    }
    const poChanged = (e) => {
        const poCopy = {...props.po};
        if (!e.target.value) {
            delete poCopy[e.target.name];
        } else {
            poCopy[e.target.name] = e.target.value;
        }
        props.resourceModelFormPoChanged(poCopy);
    }
    const actualsChanged = (e) => {
        const splittedName = e.target.name.split(',');
        const actualsCopy = {...props[splittedName[1]]};
        actualsCopy[splittedName[0]] = e.target.value;
        if (e.target.value.length === 0) {
            delete actualsCopy[splittedName[0]];
        }
        props.resourceModelFormActualsChanged({name: splittedName[1], value: actualsCopy});
    }
    const createCustomRowCellsObject = function(monthStart) {
        const columnsCopyFromMonth = [...columns];
        const customCellRendererObject = columnsCopyFromMonth.reduce((obj, item) => {
            if (item.dataProperty === 'header') {
                obj[item.dataProperty] = (data, id) => {
                    return (
                        <div style={{minWidth: 54, textAlign: 'right', fontSize: 12}}>{data}</div>
                    )
                }
            }
            else {
                obj[item.dataProperty] = (data, id, test, month) => {
                    if (id === 'Planned') {
                        if (props.reviewMode || (changes && changes[id] && changes[id][month] !== undefined)) {
                            return (
                                <Input 
                                    width="56px"
                                    minWidth="56px"
                                    inputProps={{name:item.dataProperty}}
                                    value={data !== undefined ? data : ""}
                                    disabled={true}
                                    tooltipText={changes && changes[id] && changes[id][month] !== undefined  ? changes[id][month] : ""} 
                                    className={changes && changes[id] && changes[id][month] !== undefined ? "input-updated-table" : ""} 
                                />
                            )
                        }
                        return (
                            <div style={{minWidth: 54, paddingLeft: 6, fontSize: 12}}>{data}</div>
                        )
                    } else if (id === 'PO') {
                        return (
                            <Input
                                width="56px"
                                minWidth="56px"
                                inputProps={{name:item.dataProperty}}
                                value={data !== undefined ? data : ""}
                                onChange={poChanged}
                                disabled={props.reviewMode}
                                tooltipText={changes && changes[id] && changes[id][month] !== undefined  ? changes[id][month] : ""} 
                                className={changes && changes[id] && changes[id][month] !== undefined ? "input-updated-table" : ""} 
                            />
                        )
                    }
                    else {
                        if (!props.reviewMode && (props.generateDc || (props.dcLinks && props.dcLinks[item.dataProperty])) && !(changes && changes[id] && changes[id][month] !== undefined)) {
                            return (<div style={{minWidth: 54, paddingLeft: 6, fontSize: 12}}>{data}</div>)
                        }
                        return (
                            <Input 
                                width="56px"
                                minWidth="56px" 
                                type="number"
                                precision={(id === "actualUnitPrice" || id === "actualPerDiem" ) && isTripplePrecision(props.resourceType) ? 3: 2}
                                value={data !== undefined ? data : ""}
                                inputProps={{name: item.dataProperty+","+id, min: 0}}
                                onChange={actualsChanged}
                                disabled={props.reviewMode || (props.generateDc || (props.dcLinks && props.dcLinks[item.dataProperty]))}
                                tooltipText={changes && changes[id] && changes[id][month] !== undefined  ? changes[id][month] : ""} 
                                className={changes && changes[id] && changes[id][month] !== undefined ? "input-updated-table" : ""} 
                            />
                        )
                    }
                }
            }    
            return obj;
        }, {});
        return customCellRendererObject;
    }
    const copyLinkToDc = (value) => {
        const copyText = document.getElementById(value);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }
    const placeholderFnc = () => {};
    return (
        <DataTable 
            minWidth={props.minWidth}
            data={testData}
            customRowCells={createCustomRowCellsObject(props.monthStart)}
            columns={columns}
            tableClass="month-table"
            enabledAddition={props.dcLinks && Object.keys(props.dcLinks).length !==0}
            addNewItemRenderer={ (dataProperty) => {
                return (
                    <div style={{position:'relative', height: 40}}>
                        {props.dcLinks[dataProperty] ?
                        <Tooltip title="Copy link to Delivery Confirmation">
                                <div style={{position: 'absolute', left: 8, top:10}} onClick={copyLinkToDc.bind('',props.dcLinks[dataProperty] + "dc" + dataProperty)}>
                                    <input
                                        style={{position: 'absolute',left: -9999}}
                                        id={props.dcLinks[dataProperty] + "dc" + dataProperty}
                                        value={window.location.origin+"/deliveryconfirmation/view/"+props.dcLinks[dataProperty]}
                                        onChange={placeholderFnc}
                                    />
                                    <label htmlFor={props.dcLinks[dataProperty] + "dc" + dataProperty}>
                                        <span>
                                            <FileCopyIcon className="copy-link" fontSize="small"color="primary" />
                                        </span>
                                    </label>
                                </div>
                        </Tooltip> : null
                        }
                    </div>
                )
            }}

        />
    )
}
export default ResourceTable;

