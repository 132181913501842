import React, { Component } from 'react';
import { connect } from 'react-redux';
import formSectionWrapper from '../ChangelogForm/components/FormSectionWrapper';
import Resource from './Resource';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import { getRmChanges } from '../../../store/reducers/ResourceModelFormReducer';

const mapStateToProps = (state) => {
    return {
        resource: state.resourceModelForm.resourceModel || {},
        changes: getRmChanges(state)
    }
}
const mapDispatchToProps = (dispatch) => ({
    resourceModelFormPoChanged: (payload) => 
        dispatch(resourceModelFormActions.resourceModelFormPoChanged(payload)),
    resourceModelFormActualsChanged: (payload) => 
        dispatch(resourceModelFormActions.resourceModelFormActualsChanged(payload)),
    resourceModelFormGenerateDcChanged: (payload) =>
        dispatch(resourceModelFormActions.resourceModelFormGenerateDcChanged(payload.target.checked))
    }
);
class DeliveryInformationContainer extends Component {
    render() {
        return (
            <Resource {...this.props}/>
        )
    }
}
export default formSectionWrapper(connect(mapStateToProps, mapDispatchToProps)(DeliveryInformationContainer), "Resource");