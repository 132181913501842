import React from 'react';
import { makeStyles} from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    commentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    commentSectionHeader: {
        fontSize: 14,
        color: 'rgba(89, 89, 89, 0.75)',
        textAlign: 'left',
        width: 312
    },
    toolbar: theme.mixins.toolbar,
    card: {
        marginTop: 24,
        width: 312,
        position: 'relative'
      },
    textArea: {
        border: '0 !important',
        width: 280,
        fontFamily: 'Lato, sans-serif',
        fontSize: 14,
        outline: 'none',
        resize: 'none'
    },
    media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    },
    expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    historyCommentContent: {
        display: 'flex'
    },
    historyCommentText: {
        marginLeft: 10
    }
}))
function formatDate(date) {
    return (new Date(date + "Z").toLocaleDateString() + " " + new Date(date + "Z").toLocaleTimeString());
}
 

export default (props) => {
    const viewRevision = () => {
        props.viewRevision(props.index);
    }
    const classes = useStyles();
    return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {props.history.modifiedBy.substring(0, 1).toUpperCase()}
                    </Avatar>
                    }
                    title={props.history.modifiedBy}
                    subheader={formatDate(props.history.modifiedDate.substring(0, props.history.modifiedDate.length -2))}
                />
                <CardContent className="changelog-content">
                <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                    <span className={classes.historyCommentContent}>
                        <AssignmentOutlined fontSize="small" />
                        <span className={classes.historyCommentText}>
                            {props.history.status}
                        </span>
                    </span>
                    <Button color="primary" 
                        style={{marginLeft: 'auto', width: 60}} 
                        onClick={viewRevision} 
                        className="removed-text-padding">
                        VIEW
                    </Button>
                </div>
                </CardContent>
            </Card>
    )
}