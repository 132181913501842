import { resourceTypesEnums } from '../constants/resourceTypes';
import { assignmentSitesEnums } from '../constants/assignmentSites';
import { vendorTypeEnums } from '../constants/vendorTypes';
import { activityTypeEnums } from '../constants/activityTypes';
import { YesNoEnums } from '../constants/YesNoConsent';

export const isPerDiemVisible = (resourceType, assignmentSite) => {
    return resourceTypesEnums["T&M"] === resourceType && assignmentSitesEnums.Landed === assignmentSite;
}
export const isTimeAndMaterial = (resourceType) => {
    return resourceTypesEnums["T&M"] === resourceType;
}

export const isAssignmentSiteVisible = (resourceType) => {
    return resourceType === resourceTypesEnums["T&M"];
}

export const isGradeVisible = (resourceType) => {
    return [resourceTypesEnums["T&M"], resourceTypesEnums.IT, resourceTypesEnums.Business].indexOf(resourceType) !== -1;
}

export const isLocationVisible = (resourceType) => {
    return [resourceTypesEnums["T&M"], resourceTypesEnums.IT, resourceTypesEnums.Business].indexOf(resourceType) !== -1;
}

export const isPlannedUnitPrice = (resourceType) => {
    return resourceTypesEnums["T&M"] === resourceType || resourceTypesEnums.IT === resourceType || resourceTypesEnums.Business === resourceType;
}

export const isVendorTypeExternal = (vendorType) => {
    return vendorType === vendorTypeEnums.External;
}
export const isActivityTypeBAU = (activityType) => {
    return activityType === activityTypeEnums.BAU;
}
export const isConsentTypeYes = (consentType) => {
    return consentType === YesNoEnums.YES;
}


export const isUnitPriceDisabledOnChangelogForm = (resourceType) => {
    return [resourceTypesEnums.FXP, resourceTypesEnums["T&E"], resourceTypesEnums.FMS].indexOf(resourceType) !== -1;
}

export const isTripplePrecision = (resourceType) => {
    return [resourceTypesEnums["T&M"], resourceTypesEnums.IT, resourceTypesEnums.Business].indexOf(resourceType) !== -1;
}