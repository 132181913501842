import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';
import ResourceItem from './ResourceItem';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import { getDcChanges } from '../../../../store/reducers/DeliveryConfirmationFormReducer';

const mapStateToProps = (state) => {
    return {
        resourceType: state.deliveryConfirmationForm.resourceType,
        currency: state.deliveryConfirmationForm.currency,
        unitRate: state.deliveryConfirmationForm.unitRate,
        grade: state.deliveryConfirmationForm.grade,
        perDiemRate: state.deliveryConfirmationForm.perDiemRate,
        rejectReason: state.deliveryConfirmationForm.rejectReason,
        plannedUnitPrice: state.deliveryConfirmationForm.plannedUnitPrice,
        actualUnitPrice: state.deliveryConfirmationForm.actualUnitPrice,
        plannedPerDiem: state.deliveryConfirmationForm.plannedPerDiem,
        actualPerDiem: state.deliveryConfirmationForm.actualPerDiem,
        overtime: state.deliveryConfirmationForm.overtime,
        rate: state.deliveryConfirmationForm.rate,
        year: state.deliveryConfirmationForm.year,
        month: state.deliveryConfirmationForm.month,
        numberOfExtraHours: state.deliveryConfirmationForm.numberOfExtraHours,
        comment: state.deliveryConfirmationForm.comment,
        assignmentSite: state.deliveryConfirmationForm.assignmentSite,
        attachments: state.deliveryConfirmationForm.attachments || [],
        overtimeHours: state.deliveryConfirmationForm.overtimeHours || [],
        dcNumber: state.deliveryConfirmationForm.dcNumber || '',
        status: state.deliveryConfirmationForm.status,
        vendorType: state.deliveryConfirmationForm.vendorType,
        isDcApprover: state.deliveryConfirmationForm.isApprover,
        isSupplier: state.deliveryConfirmationForm.isSupplier,
        isEditing: state.deliveryConfirmationForm.editButtonPressed,
        errors: state.deliveryConfirmationForm.errors,
        comments: state.deliveryConfirmationForm.comments,
        rmId: state.deliveryConfirmationForm.rmId,
        clId: state.deliveryConfirmationForm.clId,
        changes: getDcChanges(state)
    }
};
const mapDispatchToProps = (dispatch) => ({
    actualUnitPriceChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActualUnitPriceChanged(payload.target.value)),
    actualPerDiemChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActualPerDiemChanged(payload.target.value)),     
    overtimeChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormOvertimeChanged(payload.value === 'true')),
    rateChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormRateChanged(payload.value)),
    numberOfExtraHoursChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormNumberOfExtraHoursChanged(payload.target.value)),           
    commentChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormCommentChanged(payload)),
    attachmentAdded: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormAttachmentAdded(payload)),        
    attachmentDeleted: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormAttachmentDeleted(payload)),
    deliveryConfirmationFormOvertimeHoursChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormOvertimeHoursChanged(payload)),  
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))   
})

class ResourcesContainer extends Component {
    render() {
        return (
            <div>
                <ResourceItem
                        {...this.props}
                />
           </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesContainer);