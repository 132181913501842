import React, { Component } from 'react';
import FormSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles'
import * as vendorRatesActions from '../../../../store/actions/vendorRates';
import TextArea from '../../../../components/Input/TextArea';
import formatDate from '../../../../utils/dateFormatter';

const mapStateToProps = (state) => {
    return {
        comment: state.vendorRateForm.comment,
        comments: state.vendorRateForm.comments || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    commentChanged: (payload) => 
        dispatch(vendorRatesActions.vendorRateFormCommentChanged(payload))      
    }
);
const styles = {
    commentsListContainer: {
        paddingTop: 18
    },
    commentContainer: {
        paddingBottom: 16,
        borderRadius: '25px',
        width: '100%',
        wordWrap: 'break-word'
    },
    commentHeader: {
        color: '#595959',
        fontSize: 12,
        fontWeight: '500'
    },
    commentContent: {
        color: '#262626',
        lineHeight: '20px',
        fontSize: 16,
        paddingTop: 10
    }
}

class CommentsContainer extends Component {
    render () {
        return (
        <div>
            <TextArea
                label="Comment"
                value={this.props.comment ? this.props.comment : ""}
                onChange={this.props.commentChanged}
            ></TextArea>
             {this.props.comments.length ? 
                <div className={this.props.classes.commentsListContainer}>
                    {this.props.comments.map((comment) => {
                        return (
                            <div key={comment.userName + comment.date} className={this.props.classes.commentContainer}>
                                <h4 className={this.props.classes.commentHeader}>
                                    {`${comment.userName}, ${formatDate(comment.date)}`}
                                </h4>
                                <TextArea value={comment.message} disabled editorStyle={{height: 'auto', border: 0, padding: 0}} />
                            </div>
                        )
                    })}
                </div>
            : null }
        </div>
        )
    }
}
export default FormSectionWrapper(compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(CommentsContainer), "Comments")