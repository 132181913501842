import { put, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as wbsActions from '../store/actions/wbs';
import axios from '../axios';
import vendorTypes from '../constants/vendorTypes';
import { encodeParamObject } from '../utils/queryParamsFormatter';

function* getWbsColumnsConfig(action) {
    let columns = yield localStorage.getItem("wbs-column-config"+ process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(wbsActions.getWbsColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(wbsActions.getWbsColumnsFailed())
    }
}

function* saveColumnsConfig(action) {
    yield localStorage.setItem("wbs-column-config" + process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getWbsColumnsConfig(action)
    yield put(wbsActions.updateWbsColumnsFinished())
}

function* getWbses(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(wbsActions.wbsListChangeLoadingSpinner(true));
        
        let data = yield axios.get('api/wbs?withDeleted=true', {params: encodeParamObject(action.payload.params)});
        const inactive = {};
        data.data.items.forEach((item) => {
            if (item.isDeleted) {
                inactive[item.id] = true;
            }
        })
        const dataCopy = data.data.items.map((item) => {
            return {
                ...item,
                canSelect: false,
                poLocation: item.poLocation.name + ` (${item.poLocation.number})`,
                vendorType: vendorTypes[item.vendorType],
            }
        });
        data.data.inactiveMap = inactive;
        data.data.items = dataCopy
        yield put(wbsActions.getWbsListFinished(data.data));
    } catch (error) {
        yield put(wbsActions.getWbsListFailed(error));
    }
}

export default [
    takeEvery(actionTypes.GET_WBS_COLUMNS_STARTED, getWbsColumnsConfig),
    takeEvery(actionTypes.UPDATE_WBS_COLUMNS_STARTED, saveColumnsConfig),
    takeLatest(actionTypes.GET_WBS_LIST_STARTED, getWbses),
];