import { put, takeLatest, takeEvery, take, race, call, all, fork, delay } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as changelogFormActionsCreators from '../store/actions/changeLogFormActions';
import * as userActions from '../store/actions/User';
import { select } from 'redux-saga/effects';
import * as changelogDataSagas from './changelogDataSagas';
import * as changelogDataActionsCreators from '../store/actions/changelogDataActions';
import * as backOfficeCommentsActions from '../store/actions/backofficeChangelogCommentsActions';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import * as oneColumnActions from '../store/actions/OneColumnPickList';
import { oneColumnDictionariesSagas, getAllPoLocations } from './dictionariesSagas';
import { getDomains } from './domainsSagas';
import axios from '../axios';
import history from '../history';
import { deleteFiles, attachFiles, downloadFile } from './formAttachmentSagas';
import { statuses } from '../constants/changelogStatuses';
import { canFunction } from '../config/Can';
import * as rules from '../config/Rules';
import resourceTypes from '../constants/resourceTypes';
import activityTypes from '../constants/activityTypes';
import YesNoConsent from '../constants/YesNoConsent';
import assignmentSites from '../constants/assignmentSites';
import vendorTypesEnums from '../constants/vendorTypes';
import React from 'react';
import historyDataFormatter from '../utils/historyDataMapper';
import { formatDateWithoutHours } from '../utils/dateFormatter';
import formatDate from '../utils/dateFormatter';
import ChangelogPdf from '../ChangelogPdf';
import { saveAs } from 'file-saver';
import scrollToErrorSaga from './scrollToErrorSaga';
import { isVendorTypeExternal, isPerDiemVisible, isGradeVisible, isLocationVisible, isAssignmentSiteVisible } from '../utils/typesAssertions';
import { getWbsesAfterActivity, getWbsesAfterVendorType, getWbsesAfterPoLocation } from '../store/reducers/ChangeLogFormReducer';
import * as commentUtils from '../utils/commentUtils';
function* getDictionaritesSaga(changelog) {
    try {
        changelog = changelog || {}
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        let optionalSagas = []
        if (canFunction('', rules.VIEW_TAGS_PICKLIST)) {
            optionalSagas.push(call(changelogDataSagas.getChangelogTags));
            optionalSagas.push(call(changelogDataSagas.getChangelogResourceTags));
        }
        yield all([
            call(getDomains, changelog.domainId),
            call(oneColumnDictionariesSagas['grades'].getAll),
            call(oneColumnDictionariesSagas['currencies'].getAll),
            call(oneColumnDictionariesSagas['resourceLocations'].getAll),
            call(oneColumnDictionariesSagas['costTypes'].getAll),
            call(getAllPoLocations),
            ...optionalSagas
        ]);
        if (history.location.pathname.indexOf("create") !== -1) {
            yield put(changelogFormActionsCreators.changelogFormDataLoaded());
        }
        yield put(changelogFormActionsCreators.changelogFormInitialized());
    } catch (error) {
        yield put(changelogFormActionsCreators.changelogFormInitialized());
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}

function* changelogStatusSaga(action) {
    yield put(changelogFormActionsCreators.changelogFormStatusChanged(statuses[0]));
}

function* activitiesSaga(action) {
    try {
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        const activityBaseId = yield select((store) => store.changelogForm.activityBaseId);
        const domain = yield select((state) => state.changelogForm.domain);
        const subDomain = yield select((state) => state.changelogForm.subDomain);
        const activityType = yield select((state) => state.changelogForm.activityType);
        if (!domain || domain.value === undefined || !subDomain || subDomain.value === undefined || !activityType || activityType.value === undefined) {
            yield put(changelogFormActionsCreators.changelogFormActivityChanged(''));
            yield put(changelogFormActionsCreators.changelogFormActivitiesFilteredByExpenseTypeChanged([]));
            return;
        }
        yield* changelogDataSagas.getActivities({ payload: { domainId: domain.value, subDomainId: subDomain.value, activityType: activityType.value } }, activityBaseId);
        const getActivites = (state) => state.changelogData.activities;
        const filteredActivities = yield select(getActivites);
        if (filteredActivities.length === 1) {
            yield put(changelogFormActionsCreators.changelogFormActivityChanged({
                label: filteredActivities[0].name,
                value: filteredActivities[0].id,
            }));
        } else {
            yield put(changelogFormActionsCreators.changelogFormActivityChanged(''));
        }
        yield put(changelogFormActionsCreators.changelogFormActivitiesFilteredByExpenseTypeChanged(filteredActivities));
    } catch (e) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}


function* subDomainSaga(action) {
    try {
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        yield* changelogDataSagas.getSubDomainsByDomain({ payload: action.payload.value });
        yield put(changelogFormActionsCreators.changelogFormSubDomainChanged(''));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getVendors = (state) => state.changelogData.vendors;

function* vendorSaga(action) {
    try {
        const getVendor = (state) => {
            return state.changelogData.vendorTypes.find((vendorType) => {
                return vendorType === parseInt(action.payload.value)
            })
        }
        const vendorType = yield select(getVendor);
        const getDomain = (state) => state.changelogForm.domain;
        const domain = yield select(getDomain);
        let domainId;
        if (!domain) {
            domainId = '';
        } else {
            domainId = domain.value;
        }
        if (action.payload.value === undefined) {
            changelogDataActionsCreators.getResourceTypesByDomainAndVendorTypeFinished([]);
        } else {
            yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
            yield* changelogDataSagas.getResourceTypesByDomainAndVendorType({
                payload: {
                    vendorType: action.payload.value,
                    domainId: domainId
                }
            });
        }
        //if vendorType was cleared clear vendor aswell
        //and clear vendors available for selection
        if (vendorType === undefined) {
            yield put(changelogFormActionsCreators.changelogFormVendorChanged(''));
            yield put(changelogDataActionsCreators.getVendorsByVendorTypeFinished([]));
            return;
        }
        //get vendors available for given vendorType
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        const vendorBaseId = yield select((store) => store.changelogForm.vendorBaseId);
        yield* changelogDataSagas.getVendorsByVendorType({ payload: { vendorType: vendorType } }, vendorBaseId);
        const vendors = yield select(getVendors);
        //if there is only one vendor available pick it
        if (vendors.length === 1) {
            yield put(changelogFormActionsCreators.changelogFormVendorChanged({ label: vendors[0].name, value: vendors[0].id }));
            //otherwise clear selection  
        } else {
            yield put(changelogFormActionsCreators.changelogFormVendorChanged(''));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* poWbsSaga(action) {
    try {
        yield put(changelogFormActionsCreators.changelogFormPoWbsChanged(''));
        const activity = yield select((state) => state.changelogForm.activity);
        if (activity.label === undefined) {
            yield put(changelogDataActionsCreators.getPoWbsesByActivityFinished([]));
            return;
        }
        const activities = yield select((state) => state.changelogData.activities);
        const wbsBaseId = yield select((state) => state.changelogForm.wbsBaseId);
        let activityWithName = activities.find((activ) => {
            return activ.id === activity.value;
        })
        if (!activityWithName) {
            const response = yield axios.get(`api/activities/${activity.value}`);
            activityWithName = response.data;
        }
        const activityName = activityWithName.name
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        yield* changelogDataSagas.getWbsesByActivity({ payload: { wbsBaseId, params: { activityName: activityName } } });
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getVendorRates = (state) => state.changelogData.vendorRates;

function* vendorRateSaga(action) {
    const getVendor = (state) => {
        return state.changelogData.vendors.find((vendor) => {
            return vendor.id === action.payload.value;
        })
    }

    try {
        const vendor = yield select(getVendor);
        //if vendor was cleared clear vendorRates
        if (!vendor) {
            yield put(changelogFormActionsCreators.changelogFormRateYearChanged(''));
            yield put(changelogDataActionsCreators.getVendorRatesByVendorFinished([]));
            return;
        }
        //get vendorRates for given vendor
        yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
        yield* changelogDataSagas.getVendorRatesByVendor({ payload: vendor.id });
        const vendorRates = yield select(getVendorRates);
        //if there is only one pick it
        if (vendorRates.length === 1) {
            yield put(changelogFormActionsCreators.changelogFormRateYearChanged({
                label: vendorRates[0].rateYear,
                value: vendorRates[0].rateYear
            }));
            //otherwise clear selection  
        } else {
            yield put(changelogFormActionsCreators.changelogFormRateYearChanged(''));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* plannerSaga(action) {
    try {
        if (!action.payload.value) {
            yield put(changelogFormActionsCreators.changeLogFormPlannerChanged(""));
            return;
        }
        const getDomain = (state) => {
            return state.domains.domains.find((domain) => {
                return domain.id === action.payload.value;
            })
        }
        const domain = yield select(getDomain);
        yield put(changelogFormActionsCreators.changeLogFormPlannerChanged(domain.planner));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getResourceTypes = (state) => state.changelogData.resourceTypes
const getChangelogResources = (state) => state.changelogForm.resources;
const getRateYear = (state) => state.changelogForm.rateYear;

function* exitSaga(index) {
    const resources = yield select(getResources);
    if (resources.length === 0) {
        return true;
    }
    const selectedResourceType = yield select((state) => state.changelogForm.resources[parseInt(index)].resourceType);
    if (!selectedResourceType) {
        return true;
    }
    return false;
}
function* getSelectedResourceType(index) {
    const selectedResourceType = yield select((state) => state.changelogForm.resources[parseInt(index)].resourceType);
    return selectedResourceType.value;
}

function* narrowVendorRates(index, vendorRateStartProperty, vendorRateEndProperty, vendorRateFilterProperty, filterValue) {
    const getNarrowedVendorRates = (state) => state.changelogForm.resources[parseInt(index)].vendorRates[vendorRateStartProperty]
    const narrowedVendorRates = yield select(getNarrowedVendorRates);
    const newNarrowedVendorRates = narrowedVendorRates.filter((vendorRate) => {
        if (filterValue === "null") {
            filterValue = null;
        }
        if (filterValue === "undefined") {
            filterValue = undefined;
        }
        return vendorRate[vendorRateFilterProperty] === filterValue;
    });
    yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableVendorRatesChanged(
        index, newNarrowedVendorRates, vendorRateEndProperty));
    return newNarrowedVendorRates;
}

function narrowOptions(vendorRates, objectsToBeFiltered, vendorRateFilterProperty, objectToBeFilteredProperty) {
    const uniqueItems = {};
    vendorRates.forEach((vendorRate) => {
        uniqueItems[vendorRate[vendorRateFilterProperty]] = true;
    })
    if (objectsToBeFiltered === null) {
        objectsToBeFiltered = Object.keys(uniqueItems);
    }
    return objectsToBeFiltered.filter((object) => {
        return uniqueItems[object[objectToBeFilteredProperty] || object];
    });
}
const getFormLoaded = (store) => { return store.changelogForm.dataLoaded };
function* resourceTypeSaga(action) {
    try {
        const changelogResources = yield select(getChangelogResources);
        let rateYear;
        if (action.type === actionTypes.CHANGELOG_FORM_RATE_YEAR_CHANGED) {
            rateYear = action.payload;
        } else {
            rateYear = yield select(getRateYear);
        }

        const resourceTypess = yield select(getResourceTypes);
        const vendorRates = yield select(getVendorRates);
        let availableResourceTypes = [];
        let availableVendorRates = [];
        //if rate year was selected narrow vendorRates and resourceTypes available
        if (rateYear.value) {
            const uniqueResourceTypes = {};
            availableVendorRates = vendorRates.filter((vendorRate) => {
                const isValid = vendorRate.rateYear === rateYear.value
                //if it matched rateYear criteria than keep it in unique map 
                if (isValid) {
                    uniqueResourceTypes[vendorRate.resourceType] = isValid;
                }
                return isValid;
            });
            availableResourceTypes = resourceTypess.filter((resourceType) => {
                return uniqueResourceTypes[resourceType];
            });
        }
        let selectedResourceType = '';
        //if there is any resource type available choose first as a selected option
        if (availableResourceTypes.length) {
            selectedResourceType = {
                label: resourceTypes[availableResourceTypes[0]],
                value: availableResourceTypes[0]
            }
        }
        //dispatch narrowed vendor rates, resource type options and selected default resource type
        for (let index = 0; index < 1; index++) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableVendorRatesChanged(index, availableVendorRates, 'resourceType'));
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableResourceTypesChanged(index, availableResourceTypes.map((resourceType) => {
                return {
                    label: resourceTypes[resourceType],
                    value: resourceType
                }
            })))
            const shouldSetValue = yield select(getFormLoaded);
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemTypeChanged(index, selectedResourceType));
                if (selectedResourceType.label) {
                    yield race({
                        task: take(actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED),
                        cancel: take(actionTypes.OPEN_ERROR_DIALOG)
                    })
                }
            }
        }
        const state = yield select((store) => store.changelogForm);
        const stateCopy = { ...state };
        const copyResource = state.resources[0];
        stateCopy.resources = [];
        stateCopy.resources.push(state.resources[0]);
        for (let i = 1; i < changelogResources.length; i++) {
            stateCopy.resources.push({
                ...state.resources[i],
                resourceType: copyResource.resourceType,
                resourceTypes: copyResource.resourceTypes,
                location: copyResource.location,
                locations: copyResource.locations,
                assignmentSite: copyResource.assignmentSite,
                assignmentSites: copyResource.assignmentSites,
                grade: copyResource.grade,
                grades: copyResource.grades,
                unitPrice: copyResource.unitPrice,
                unitPrices: copyResource.unitPrices,
                perDiem: copyResource.perDiem,
                perDiems: copyResource.perDiems,
                currency: copyResource.currency,
                currencies: copyResource.currencies,
                vendorRates: copyResource.vendorRates,
            });
        }
        if (changelogResources.length > 1) {
            yield put(changelogFormActionsCreators.changelogFormSetState(stateCopy));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* resourceItemAddedSaga(action) {
    try {
        const getResources = (state) => state.changelogForm.resources;
        const resources = yield select(getResources);
        const index = resources.length - 1;
        if (index === 0) {
            const rateYear = yield select(getRateYear);
            yield* resourceTypeSaga(changelogFormActionsCreators.changelogFormRateYearChanged(rateYear));
        } else {
            if (!resources[0].vendorRates || !resources[0].vendorRates['resourceType'] || resources[0].vendorRates['resourceType'].length === 0) {
                return;
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableVendorRatesChanged(index, resources[0].vendorRates['resourceType'], 'resourceType'));
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableResourceTypesChanged(index, resources[0].resourceTypes))
            yield put(changelogFormActionsCreators.changelogFormResourceItemTypeChanged(index, resources[0].resourceTypes[0]));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getLocations = (state) => state.resourceLocations.allItems;
const getResources = (state) => state.changelogForm.resources;

function* locationSaga(action) {
    try {
        const shouldSetValue = yield select(getFormLoaded);
        const shouldExit = yield* exitSaga(action.index);
        const resourceType = yield* getSelectedResourceType(action.index)
        if (shouldExit || !isLocationVisible(resourceType)) {
            return;
        }
        //if resource type was cleared clear location selection and clear available location options
        if (action.payload.value === undefined) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableLocationsChanged(action.index, []));
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemLocationChanged(action.index, {}));
            }
            return;
        }
        const vendorRates = yield* narrowVendorRates(action.index, 'resourceType', 'location', 'resourceType', action.payload.value);
        const locations = yield select(getLocations);
        const availableLocations = narrowOptions(vendorRates, locations, 'resourceLocationId', 'id');
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableLocationsChanged(action.index, availableLocations.map((location) => {
            return {
                label: location.name,
                value: location.id
            }
        })));
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemLocationChanged(action.index, { label: availableLocations[0].name, value: availableLocations[0].id }));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* assignmentSiteSaga(action) {
    try {
        const shouldExit = yield* exitSaga(action.index);
        const shouldSetValue = yield select(getFormLoaded);
        const resourceType = yield* getSelectedResourceType(action.index)
        if (shouldExit || !isAssignmentSiteVisible(resourceType)) {
            return;
        }
        //if location was cleared clear assignment site selection and clear available assignment site options
        if (action.payload.value === undefined) {
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemAssignmentSiteChanged(action.index, {}));
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableAssignmentSitesChanged(action.index, []));
            return;
        }
        const vendorRates = yield* narrowVendorRates(action.index, 'location', 'assignmentSite', 'resourceLocationId', action.payload.value);
        const options = narrowOptions(vendorRates, null, 'assignmentSite');
        const assignmentSiteOptions = options
            .map((assignmentSite) => {
                return {
                    label: assignmentSites[parseInt(assignmentSite)],
                    value: parseInt(assignmentSite)
                }
            });
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemAssignmentSiteChanged(action.index, assignmentSiteOptions[0]));
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableAssignmentSitesChanged(action.index, assignmentSiteOptions));

    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getGrades = (state) => state.grades.allItems;

function* gradeSaga(action) {
    try {

        const shouldSetValue = yield select(getFormLoaded);
        const shouldExit = yield* exitSaga(action.index);
        const resourceType = yield* getSelectedResourceType(action.index)
        if (shouldExit || !isGradeVisible(resourceType)) {
            return;
        }
        //saga is listening for location and assignment site changes
        //in case of time and material it should change only depending on assignment site
        const selectedResourceType = yield select((state) => state.changelogForm.resources[action.index].resourceType);
        if (isAssignmentSiteVisible(selectedResourceType.value) & action.type === actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED) {
            return;
        }
        //if payload was cleared clear grades and grades options
        if (action.payload.value === undefined) {
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemGradeChanged(action.index, {}));
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableGradesChanged(action.index, []));
            return;
        }

        let vendorRates;
        //if it is timeAndMaterial than next filter applied by assignmentSite
        if (isAssignmentSiteVisible(selectedResourceType.value)) {
            vendorRates = yield* narrowVendorRates(action.index, 'assignmentSite', 'grade', 'assignmentSite', action.payload.value);
        } else {
            vendorRates = yield* narrowVendorRates(action.index, 'location', 'grade', 'resourceLocationId', action.payload.value);
        }
        const grades = yield select(getGrades);
        const options = narrowOptions(vendorRates, grades, 'gradeId', 'id');
        const availableGrades = options.map((grade) => {
            return {
                label: grade.name,
                value: grade.id
            }
        })
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemGradeChanged(action.index, availableGrades[0]));
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableGradesChanged(action.index, availableGrades));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* unitPriceSaga(action) {
    try {
        const shouldExit = yield* exitSaga(action.index);
        const shouldSetValue = yield select(getFormLoaded);
        if (shouldExit) {
            return;
        }
        //if payload was cleared clear grades and grades options
        const selectedResourceType = yield select((state) => state.changelogForm.resources[action.index].resourceType);
        //saga is listening for type and grade changes
        //in case of time and material, business and IS it should change only depending on grade
        if (action.type === actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED && isGradeVisible(selectedResourceType.value)) {
            return;
        }

        if (action.payload.value === undefined) {
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemUnitPriceChanged(action.index, {}));
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableUnitPricesChanged(action.index, []));
            return;
        }

        let vendorRates;
        if (isGradeVisible(selectedResourceType.value)) {
            vendorRates = yield* narrowVendorRates(action.index, 'grade', 'unitPrice', 'gradeId', action.payload.value);
        } else {
            vendorRates = yield* narrowVendorRates(action.index, 'resourceType', 'unitPrice', 'resourceType', action.payload.value);
        }
        const options = narrowOptions(vendorRates, null, 'unitPrice');
        const availableUnitPrices = options.map((unitPrice) => {
            let price = 'null';
            if (!Number.isNaN(parseFloat(unitPrice))) {
                price = parseFloat(unitPrice);
            }
            return {
                label: unitPrice,
                value: price
            }
        });
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemUnitPriceChanged(action.index, availableUnitPrices[0]));
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableUnitPricesChanged(action.index, availableUnitPrices));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* perDiemSaga(action) {
    try {
        const shouldSetValue = yield select(getFormLoaded);
        const shouldExit = yield* exitSaga(action.index);
        const resourceType = yield* getSelectedResourceType(action.index)
        let selectedAssignmentSite = yield select((state) => state.changelogForm.resources[action.index].assignmentSite);
        selectedAssignmentSite = selectedAssignmentSite || {};
        if (shouldExit || !isPerDiemVisible(resourceType, selectedAssignmentSite.value)) {
            return;
        }
        if (action.payload.value === undefined) {
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemPerDiemRateChanged(action.index, {}));
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailablePerDiemRatesChanged(action.index, []));
            return;
        }
        const vendorRates = yield* narrowVendorRates(action.index, 'unitPrice', 'perDiemRate', 'unitPrice', action.payload.value);
        const options = narrowOptions(vendorRates, null, 'perDiemRate');
        const availablePerDiemRates = options.map((perDiemRate) => {
            return {
                label: perDiemRate,
                value: perDiemRate
            }
        })
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemPerDiemRateChanged(action.index, availablePerDiemRates[0]));
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailablePerDiemRatesChanged(action.index, availablePerDiemRates));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

const getCurrencies = (state) => state.currencies.allItems;

function* currencySaga(action) {
    try {
        const shouldSetValue = yield select(getFormLoaded);
        const shouldExit = yield* exitSaga(action.index);
        if (shouldExit) {
            return
        }
        let selectedAssignmentSite = yield select((state) => state.changelogForm.resources[action.index].assignmentSite);
        selectedAssignmentSite = selectedAssignmentSite || {}
        const selectedResourceType = yield select((state) => state.changelogForm.resources[action.index].resourceType);
        if (isPerDiemVisible(selectedResourceType.value, selectedAssignmentSite.value) && action.type === actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED) {
            return
        }
        if (action.type === actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED && !isPerDiemVisible(selectedResourceType.value, selectedAssignmentSite.value)) {
            return;
        }
        if (action.payload.value === undefined) {
            if (shouldSetValue) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemCurrencyChanged(action.index, {}));
            }
            yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableCurrenciesChanged(action.index, []));
            return;
        }
        let vendorRates;
        if (isPerDiemVisible(selectedResourceType.value, selectedAssignmentSite.value)) {
            let value = "null";
            if (!Number.isNaN(parseFloat(action.payload.value))) {
                value = parseFloat(action.payload.value);
            }
            vendorRates = yield* narrowVendorRates(action.index, 'perDiemRate', 'currency', 'perDiemRate', value);
        } else {
            let value = "null";
            if (!Number.isNaN(parseFloat(action.payload.value))) {
                value = parseFloat(action.payload.value);
            }
            vendorRates = yield* narrowVendorRates(action.index, 'unitPrice', 'currency', 'unitPrice', value);
        }
        const currencies = yield select(getCurrencies);
        const options = narrowOptions(vendorRates, currencies, 'currencyId', 'id');
        const availableCurrencies = options.map((currency) => {
            return {
                label: currency.name,
                value: currency.id
            }
        });
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemCurrencyChanged(action.index, availableCurrencies[0]));
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemAvailableCurrenciesChanged(action.index, availableCurrencies));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating changelog form data. Please try again later."));
    }
}

function* submitEditChangelogSaga(action) {
    try {
        const isDraft = action.payload;
        const getFormState = (state) => state.changelogForm;
        const formState = yield select(getFormState);
        const errors = validate(formState);
        if (Object.keys(errors).length && !isDraft) {
            yield put(changelogFormActionsCreators.changelogFormSubmitValidationErrorsChanged(errors));
            yield put(changelogFormActionsCreators.submitChangelogFormFailed());
            yield* scrollToErrorSaga();
            return;
        }
        const formData = {

            domainId: formState.domain.value,
            subDomainId: formState.subDomain.value,
            tags: formState.changelogTags ? formState.changelogTags.map((tag) => {
                return tag.value
            }) : [],
            ActivityId: formState.activity.value,
            VendorId: formState.vendor.value,
            Year: formState.year.value,
            Comment: commentUtils.getPureText(formState.comment).length !== 0 ? commentUtils.prepareEditorStateForSendingToDatabase(formState.comment) : "",
            IsConfirmed: formState.isConfirmed,
            WbsId: formState.poWbs.value,
            approverId: formState.approver ? formState.approver.id : null,
            Attachments: [],
            Status: formState.status.value || 0,
            LeanIxId: formState.leanixdto ? formState.leanixdto.id : null,
            EppmId: formState.ePPMDto && formState.consentType.value === 1 ? formState.ePPMDto.id : null,
            IsITBau: formState.consentType ? formState.consentType.value : 0
        }
        if (formState.id) {
            formData.id = formState.id;
        }
        if (formState.vendorType && isVendorTypeExternal(formState.vendorType.value)) {
            formData.supplierRepresentativeId = formState.supplierRepresentative ? formState.supplierRepresentative.id : null
        }
        if (formState.resources) {
            formData.Resources = [];
        }
        formState.resources.forEach((resource) => {
            //it may change in the future. Right now last filter applied is currency for all resource types
            let vendorRateId;
            if (resource.vendorRates && resource.vendorRates['currency']) {
                const vendorRate = resource.vendorRates['currency'].find((vendorRate) => {
                    return vendorRate.currencyId === resource.currency.value;
                });
                if (!vendorRate) {
                    vendorRateId = resource.baseVendorRateId;
                } else {
                    vendorRateId = vendorRate.id;
                }
            }
            let values
            if (resource.monthAmount) {
                values = Object.keys(resource.monthAmount).map((monthIndex) => {
                    return {
                        Month: parseInt(monthIndex),
                        Value: resource.monthAmount[parseInt(monthIndex)]
                    }
                })
            }
            const parsedResource = {
                ExpenseTypeId: resource.expenseType ? resource.expenseType.value : null,
                ResourceType: resource.resourceType ? resource.resourceType.value : null,
                StartDate: resource.startDate,
                Name: resource.name,
                VendorRateId: vendorRateId,
                Values: values,
                prepayment: resource.prepayment,
                tags: resource.tags ? resource.tags.map((tag) => {
                    return tag.value
                }) : null
            }
            if (resource.id) {
                parsedResource.id = resource.id;
            }
            formData.Resources.push(parsedResource);
        });
        const val = yield* attachFiles(formState.attachments);
        formData.Attachments = val;
        if (!isDraft) {
            yield axios.post('api/changelogs', formData);
        } else {
            yield axios.post('api/changelogs/draft', formData);
        }
        if (formState.id) {
            const getPreviousAttachments = (state) => state.changelogData.changelog.attachments;
            const previousAttachments = yield select(getPreviousAttachments);
            yield* deleteFiles(formState.attachments, previousAttachments)
        }
        yield put(changelogFormActionsCreators.submitEditChangelogFormFinished());
        const getDomains = (state) => state.domains.domains;
        const domains = yield select(getDomains);
        const selectedDomain = domains.find((domain) => {
            return domain.id === formState.domain.value && !domain.additional;
        })
        if (!selectedDomain) {
            yield put(userActions.selectDomain({ name: "view all", id: "view all" }));
        } else {
            yield put(userActions.selectDomain(selectedDomain));
        }

        if (isDraft || !(canFunction('', rules.NAVIGATE_CHANGELOG_VIEWALL))) {
            yield history.push('/changelogs/my');
        } else {
            yield history.push('/changelogs/viewAll');
        }

        yield put(changelogFormActionsCreators.resetForm());
    } catch (e) {
        yield put(changelogFormActionsCreators.submitEditChangelogFormFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting changelog form data. Please try again later."));
    }
}

function validate(formState) {
    
    const errors = {};
    if (!formState.domain.value) {
        errors['domain'] = "Platform cannot be empty";
    }
    if (!formState.subDomain.value) {
        errors['subDomain'] = "Sub-platform cannot be empty";
    }
    if (!formState.activityType.label) {
        errors['activityType'] = "Activity Type cannot be empty";
    }
    if (!formState.activity.value) {
        errors['activity'] = "Activity cannot be empty"
    }
    if (!formState.vendor.value) {
        errors['vendor'] = "Vendor cannot be empty";
    }
    if (!formState.poWbs.value) {
        errors['poWbs'] = 'PO WBS cannot be empty'
    }
    if (!formState.year.value) {
        errors['year'] = "Year cannot be empty";
    }
    if (!formState.approver.value) {
        errors['approver'] = "Approver cannot be empty";
    }
    if (!formState.supplierRepresentative.value && isVendorTypeExternal(formState.vendorType.value)) {
        errors['supplierRepresentative'] = "Supplier Representative cannot be empty";
    }
    if (!formState.isConfirmed) {
        errors['isConfirmed'] = "Confirmation is required"
    }
    if ((formState.consentType.value === 1) && (formState.ePPMDto === undefined || formState.ePPMDto == "")) {
        errors['eppm'] = "Project ID cannot be empty"
    }

    if (formState.resources) {
        const resourcesErrors = {};
        formState.resources.forEach((resource, index) => {
            if (!resource.expenseType || !resource.expenseType.value) {
                if (!resourcesErrors[parseInt(index)]) {
                    resourcesErrors[parseInt(index)] = {}
                }
                resourcesErrors[parseInt(index)]['expenseType'] = "Expense Type cannot be empty";
            }
            if (!resource.resourceType || !resource.resourceType.label) {
                if (!resourcesErrors[parseInt(index)]) {
                    resourcesErrors[parseInt(index)] = {}
                }
                resourcesErrors[parseInt(index)]['resourceType'] = "Resource Type cannot be empty";
            }
            if (!resource.startDate || !resource.startDate) {
                if (!resourcesErrors[parseInt(index)]) {
                    resourcesErrors[parseInt(index)] = {}
                }
                resourcesErrors[parseInt(index)]['startDate'] = "Start date cannot be empty";
            }
            if (!resource.name) {
                if (!resourcesErrors[parseInt(index)]) {
                    resourcesErrors[parseInt(index)] = {}
                }
                resourcesErrors[parseInt(index)]['name'] = "Resource name cannot be empty";
            }
        });
        if (Object.keys(resourcesErrors).length !== 0) {
            errors['resources'] = resourcesErrors;
        }
    }
    return errors;
}

function* getChangelogSpecificData(changelog) {
    try {
        const sagaArray = [];
        sagaArray.push(call(getDictionaritesSaga, changelog));
        if (changelog.domainId) {
            sagaArray.push(call(changelogDataSagas.getSubDomainsByDomain, { payload: changelog.domainId }, changelog.subDomainId));
        }
        if (changelog.activityType !== null && changelog.subDomainId !== null) {
            sagaArray.push(call(changelogDataSagas.getActivities, { payload: { activityType: changelog.activityType, subDomainId: changelog.subDomainId } }, changelog.activityId));
        }
        if (changelog.vendorType !== null) {
            sagaArray.push(call(changelogDataSagas.getVendorsByVendorType, { payload: { vendorType: changelog.vendorType } }, changelog.vendorId));
        }
        if (changelog.activityName) {
            sagaArray.push(call(changelogDataSagas.getWbsesByActivity, { payload: { wbsBaseId: changelog.wbsId, params: { activityName: changelog.activityName } } }));
        }
        if (changelog.vendorId) {
            sagaArray.push(call(changelogDataSagas.getVendorRatesByVendor, { payload: changelog.vendorId }));
        }
        if (changelog.vendorType !== null) {
            sagaArray.push(call(changelogDataSagas.getResourceTypesByDomainAndVendorType, {
                payload: {
                    vendorType: changelog.vendorType,
                    domainId: changelog.domainId
                }
            }));
        }
        if (changelog.costTypeId) {
            sagaArray.push(call(costTypeSaga, { payload: { value: changelog.costTypeId } }));
        }
        yield all(sagaArray);
        if (changelog.activityName) {
            const activities = yield select((state) => state.changelogData.activities);
            yield put(changelogFormActionsCreators.changelogFormActivitiesFilteredByExpenseTypeChanged(activities));
        }
        const vendorRates = yield select(getVendorRates);
        if (!vendorRates || vendorRates.length === 0 || !changelog.resources[0].rateYear) {
            yield put(changelogFormActionsCreators.changelogFormDataLoaded());
            return;
        }
        const resources = yield select((store) => store.changelogForm.resources);
        if (changelog.resources && changelog.resources.length) {
            yield put(changelogFormActionsCreators.changelogFormRateYearChanged({
                label: changelog.resources[0].rateYear,
                value: changelog.resources[0].rateYear
            }));
            //to prevent lag on ie
            yield delay(100);
            for (let i = 0; i < changelog.resources.length; i++) {
                const resource = resources[i];
                yield put(changelogFormActionsCreators.changelogFormResourceItemTypeChanged(i, resource.resourceType));
                if (isLocationVisible(resource.resourceType.value)) {
                    yield put(changelogFormActionsCreators.changelogFormResourceItemLocationChanged(i, resource.location));
                }
                if (isAssignmentSiteVisible(resource.resourceType.value)) {
                    yield put(changelogFormActionsCreators.changelogFormResourceItemAssignmentSiteChanged(i, resource.assignmentSite));
                }
                if (isGradeVisible(resource.resourceType.value)) {
                    yield put(changelogFormActionsCreators.changelogFormResourceItemGradeChanged(i, resource.grade));
                }
                yield put(changelogFormActionsCreators.changelogFormResourceItemUnitPriceChanged(i, resource.unitPrice));
                if (isPerDiemVisible(resource.resourceType.value, resource.assignmentSite.value)) {
                    yield put(changelogFormActionsCreators.changelogFormResourceItemPerDiemRateChanged(i, resource.perDiem));
                }
                yield put(changelogFormActionsCreators.changelogFormResourceItemCurrencyChanged(i, resource.currency));
            }
        }
        yield put(changelogFormActionsCreators.changelogFormDataLoaded());
    } catch (error) {
        yield put(changelogFormActionsCreators.changelogFormDataLoaded());
        yield put(errorDialogActions.openErrorDialog("There was an error while loading changelog form data. Please try again later."));
    }
}
function* getChangelogsByActivity(action) {
    try {
        const response = yield axios.get('api/changelogs/activity/' + action.payload);
        yield put(changelogFormActionsCreators.changelogFormGetChangelogsWithGivenActivityFinished(response.data));
    } catch (error) {
        yield put(changelogFormActionsCreators.changelogFormGetChangelogsWithGivenActivityFailed());
    }
}
function* loadChangelogFromData(action) {
    try {
        if (canFunction('', rules.VIEW_CHANGELOG_COMMENTS)) {
            yield fork(getChangelogCommentsSaga, { payload: action.payload });
        }
        if (canFunction('', rules.VIEW_CHANGELOG_HISTORY)) {
            yield fork(changelogHistorySaga, { payload: action.payload });
        }
        yield* changelogDataSagas.getChangelog(action);
        const getChangelog = (state) => state.changelogData.changelog;
        const changelog = yield select(getChangelog);

        if (canFunction('', rules.VIEW_CHANGELOG_ACTIVITIES_CHANGELOGS) && changelog.activityId) {
            yield fork(getChangelogsByActivity, { payload: changelog.activityId })
        }
        const resources = changelog.resources.map((resource) => {
            let dateString;
            if (resource.startDate) {
                const currentDate = new Date(resource.startDate);
                const date = ("0" + currentDate.getDate()).slice(-2)
                const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
                const year = currentDate.getFullYear();
                dateString = year + "-" + month + "-" + date;
            }
            let monthAmount
            if (resource.values) {
                monthAmount = {}
                resource.values.forEach((month) => {
                    monthAmount[month.month] = month.value;
                })
            }
            return {
                key: Math.random(),
                ref: React.createRef(),
                baseVendorRateId: resource.vendorRateId,
                tags: resource.tags ? resource.tags.map((tag) => {
                    return {
                        label: tag.value,
                        value: tag.id
                    }
                }) : null,
                resourceModelId: resource.resourceModelId,
                expenseType: resource.expenseTypeId ? {
                    label: resource.expenseTypeName,
                    value: resource.expenseTypeId
                } : '',
                poNumbers: resource.poNumbers,
                dcs: resource.changelogDeliveryConfirmations,
                id: resource.id,
                resourceType: resource.resourceType !== null ? {
                    value: resource.resourceType,
                    label: resourceTypes[resource.resourceType]
                } : "",
                prepayment: resource.prepayment || false,
                startDate: dateString,
                monthAmount: monthAmount,
                name: resource.name,
                location: resource.locationId !== null ? { label: resource.locationName, value: resource.locationId } : "",
                assignmentSite: resource.assignmentSite !== null ? { label: assignmentSites[resource.assignmentSite], value: resource.assignmentSite } : "",
                grade: resource.gradeId !== null ? { label: resource.gradeName, value: resource.gradeId } : "",
                unitPrice: resource.vendorRateId !== null ? { label: resource.unitPrice, value: resource.unitPrice } : "",
                perDiem: resource.vendorRateId !== null ? { label: resource.perDiemRate, value: resource.perDiemRate } : "",
                currency: resource.currencyId !== null ? { label: resource.currencyName, value: resource.currencyId } : "",
            }
        });
        let resourceModelGenerated = false;
        changelog.resources.forEach((res) => {
            if (res.resourceModelId) {
                resourceModelGenerated = true;
            }
        });
        const object = {
            id: changelog.id,
            changelogTags: changelog.tags ? changelog.tags.map((tag) => {
                return {
                    label: tag.value,
                    value: tag.id
                }
            }) : [],
            resourceModelGenerated: resourceModelGenerated,
            isDraft: changelog.isDraft,
            wbsBaseId: changelog.wbsId,
            activityBaseId: changelog.activityId,
            vendorBaseName: changelog.vendorName,
            vendorBaseId: changelog.vendorId,
            createdBy: changelog.createdBy ? `${changelog.createdBy.userEmail} (${changelog.createdBy.login})` : "",
            createdDate: changelog.createdDate,
            domain: changelog.domainId !== null ? {
                label: changelog.domainName,
                value: changelog.domainId
            } : "",
            planner: changelog.planner,
            subDomain: changelog.subDomainId !== null ? {
                label: changelog.subDomainName,
                value: changelog.subDomainId
            } : "",
            activityType: changelog.activityType !== null ? {
                label: activityTypes[changelog.activityType],
                value: changelog.activityType
            } : "",
            status: changelog.status !== null ? statuses.find((status) => {
                return status.value === changelog.status;
            }) : "",
            lastModifiedBy: (changelog.lastModifiedBy ? `${changelog.lastModifiedBy.userEmail} (${changelog.lastModifiedBy.login})` : "") + (changelog.lastModifiedDate ? ("\n" + formatDate(changelog.lastModifiedDate)) : ""),
            activity: changelog.activityId !== null ? {
                label: changelog.activityName + ` (${changelog.activityWorkId})`,
                value: changelog.activityId
            } : "",
            vendorType: changelog.vendorType !== null ? {
                label: vendorTypesEnums[changelog.vendorType],
                value: changelog.vendorType
            } : "",
            vendor: changelog.vendorId !== null ? {
                label: changelog.vendorName,
                value: changelog.vendorId
            } : "",
            poWbs: changelog.wbsId !== null ? {
                label: `${changelog.powbs} - ${changelog.wbsDescription}`,
                value: changelog.wbsId
            } : "",
            poLocation: changelog.poLocationId !== null ? {
                label: changelog.poLocationName,
                value: changelog.poLocationId
            } : "",
            costType: changelog.costTypeId !== null ? {
                label: changelog.costTypeName,
                value: changelog.costTypeId
            } : "",
            year: changelog.year ? {
                label: changelog.year,
                value: changelog.year
            } : "",
            rateYear: changelog.resources && changelog.resources[0] ? {
                label: changelog.resources[0].rateYear,
                value: changelog.resources[0].rateYear
            } : "",
            approver: changelog.approver ? {
                label: changelog.approver.name,
                name: changelog.approver.name,
                value: changelog.approver.userEmail,
                accountId: changelog.approver.accountId,
                login: changelog.approver.login,
                id: changelog.approver.id
            } : "",
            leanixdto: changelog.leanixdto ? {
                label: changelog.leanixdto.leanIxUniqueId,
                name: changelog.leanixdto.leanIxUniqueId,
                value: changelog.leanixdto.leanIxName,
                id: changelog.leanixdto.id
            } : "",
            ePPMDto: changelog.ePPMDto ? {
                label: changelog.ePPMDto.eppmUniqueId,
                name: changelog.ePPMDto.eppmUniqueId,
                value: changelog.ePPMDto.eppmName,
                id: changelog.ePPMDto.id
            } : "",
            consentType: changelog.consentType !== null ? {
                label: YesNoConsent[changelog.consentType],
                value: changelog.isITBau
            } : "",
            supplierRepresentative: isVendorTypeExternal(changelog.vendorType) && changelog.supplierRepresentative ? {
                label: changelog.supplierRepresentative.name,
                name: changelog.supplierRepresentative.name,
                value: changelog.supplierRepresentative.userEmail,
                accountId: changelog.supplierRepresentative.accountId,
                login: changelog.supplierRepresentative.login,
                id: changelog.supplierRepresentative.id
            } : "",
            comments: changelog.comments.map((comment) => {
                return {
                    ...comment,
                    message: commentUtils.getEditorState(comment.message)
                };
            }),
            resources: resources,
            resourcesThrottled: resources
        }
        yield put(changelogFormActionsCreators.changelogFormSetState(object));
        if (changelog.attachments) {
            for (let i = 0; i < changelog.attachments.length; i++) {
                changelog.attachments[parseInt(i)].key = changelog.attachments[parseInt(i)].id;
                yield put(changelogFormActionsCreators.changelogFormAttachmentAdded({ target: { files: [changelog.attachments[parseInt(i)]] } }));
            }
        }
        if (canFunction('', rules.HAS_ENABLED_CHANGELOG_EDIT_FORM_TICKBOX_BY_DEFAULT)) {
            yield put(changelogFormActionsCreators.changelogFormIsConfirmedChanged({ target: { checked: true } }));
        }
        yield fork(getChangelogSpecificData, changelog);
        yield put(changelogFormActionsCreators.changelogFormLoaded());
    } catch (error) {
        yield put(changelogFormActionsCreators.changelogFormLoaded());
        yield put(changelogFormActionsCreators.changelogFormDataLoaded());
        yield put(errorDialogActions.openErrorDialog("There was an error while loading changelog form data. Please try again later."));
    }
}

function* getChangelogCommentsSaga(action) {
    try {
        const data = yield axios.get(`api/changelogs/${action.payload}/comments`);
        yield put(backOfficeCommentsActions.getBackofficeChangelogCommentsFinished(data.data));
    } catch (e) {
        yield put(backOfficeCommentsActions.getBackofficeChangelogCommentsFailed());
    }
}

function* postChangelogCommentsSaga(action) {
    try {
        const comment = yield select((state) => state.backofficeChangelogCommments.comment)
        const id = yield select((state) => state.changelogData.changelog.id)
        yield axios.post(`api/changelogs/${id}/comments`, { message: comment });
        yield* getChangelogCommentsSaga({ payload: id });
        yield put(backOfficeCommentsActions.postBackofficeChangelogCommentFinished())
    } catch (e) {
        yield put(backOfficeCommentsActions.postBackofficeChangelogCommentFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting changelog post. Please try again later."));
    }
}

function* loadChangelogWrapperSaga(action) {
    yield race({
        task: call(loadChangelogFromData, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}



function* postChangelogCommentsWrapperSaga(action) {
    yield race({
        task: call(postChangelogCommentsSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* activitiesWrapperSaga(action) {
    yield race({
        task: call(activitiesSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* changelogStatusWrapperSaga(action) {
    yield race({
        task: call(changelogStatusSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* getDictionaritesWrapperSaga(action) {
    yield race({
        task: call(getDictionaritesSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* subDomainWrapperSaga(action) {
    yield race({
        task: call(subDomainSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* vendorWrapperSaga(action) {
    yield race({
        task: call(vendorSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* getChangelogCommentsWrappperSaga(action) {
    yield race({
        task: call(getChangelogCommentsSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* vendorRateWrapperSaga(action) {
    yield race({
        task: call(vendorRateSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* plannerWrapperSaga(action) {
    yield race({
        task: call(plannerSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* resourceTypeWrapperSaga(action) {
    yield race({
        task: call(resourceTypeSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* locationWrapperSaga(action) {
    yield race({
        task: call(locationSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* assignmentSiteWrapperSaga(action) {
    yield race({
        task: call(assignmentSiteSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* gradeWrapperSaga(action) {
    yield race({
        task: call(gradeSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* unitPriceWrapperSaga(action) {
    yield race({
        task: call(unitPriceSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* perDiemWrapperSaga(action) {
    yield race({
        task: call(perDiemSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* currencyWrapperSaga(action) {
    yield race({
        task: call(currencySaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* resourceItemAddedWrapperSaga(action) {
    yield race({
        task: call(resourceItemAddedSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* submitEditChangelogFormWrapperSaga(action) {
    yield race({
        task: call(submitEditChangelogSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* activityYearWrapperSaga(action) {
    yield race({
        task: call(activityYearSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* startDateWrapperSaga(action) {
    yield race({
        task: call(startDateSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}
function* deleteDraftWrapperSaga(action) {
    yield race({
        task: call(deleteDraftSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* pdfSagaWrapper(action) {
    yield race({
        task: call(pdfSaga, action),
        cancel: take(actionTypes.CHANGELOG_FORM_REST_FORM)
    })
}

function* startDateSaga(action) {
    try {
        let monthAmount = yield select((store) => store.changelogForm.resources[action.index]);
        monthAmount = monthAmount ? monthAmount.monthAmount : null;
        let month = action.payload.target.value ? new Date(action.payload.target.value).getUTCMonth() + 1 : 0;
        for (let i = 1; i < month; i++) {
            if (monthAmount && monthAmount[i] !== null && monthAmount[i] !== undefined && monthAmount[i] !== 0) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemStartDateModalChanged(true));
                return
            }
        }
        yield put(changelogFormActionsCreators.changelogFormResourceItemStartDateChanged(action.index, action.payload));
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating resource start date."));
    }
}
function* activityYearSaga(action) {
    try {
        const currentYear = yield select((store) => store.changelogForm.year);
        if (action.payload === currentYear) {
            return;
        }
        const resources = yield select((store) => store.changelogForm.resources);
        let canModify = true;
        resources.forEach((resource) => {
            let monthAmount = resource.monthAmount;
            if (!monthAmount) {
                return;
            }
            for (let i = 1; i <= 12; i++) {
                if (monthAmount[i] !== null && monthAmount[i] !== undefined && monthAmount[i] !== 0) {
                    canModify = false;
                }
            }
        });
        if (!canModify) {
            yield put(changelogFormActionsCreators.changelogFormResourceItemStartDateModalChanged(true));
            return
        }
        yield put(changelogFormActionsCreators.changelogFormYearChanged(action.payload))
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating year of activity."));
    }
}

function* deleteDraftSaga(action) {
    try {
        const formState = yield select((state) => state.changelogForm);
        yield axios.delete('api/changelogs/draft/' + formState.id);
        yield* deleteFiles([], formState.attachments);
        yield put(changelogFormActionsCreators.deleteChangelogDraftFinished());
        yield history.push('/changelogs/my');
    } catch (error) {
        yield put(changelogFormActionsCreators.deleteChangelogDraftFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while deleting changelog draft."));
    }
}
function getRevisionChanges(currentRevision, previousRevision) {
    let sortedResourcesOnPreviousArray = [];
    if (previousRevision) {
        previousRevision = { ...previousRevision };
        previousRevision.rateYear = previousRevision.resources[0] ? previousRevision.resources[0].vendorRate.rateYear : null;
        let comparisonProperty;
        if (previousRevision.resources[0] && previousRevision.resources[0].id !== undefined) {
            comparisonProperty = "id";
            currentRevision.resources.forEach((resource) => {
                const oldRes = previousRevision.resources.find((old) => {
                    return old[comparisonProperty] === resource[comparisonProperty];
                });
                sortedResourcesOnPreviousArray.push(oldRes);
            });
        } else {
            sortedResourcesOnPreviousArray = [...currentRevision.resources]
        }

        previousRevision.resources = sortedResourcesOnPreviousArray;
    }

    try {
        let changes = getRevisionDifferences(currentRevision, previousRevision);
        return changes;
    } catch (error) {
        return {};
    }
}
function getRevisionDifferences(currentRevision, previousRevision) {
    if (!previousRevision) {
        const attachmentsParsed = currentRevision.attachments.map((att) => {
            return {
                name: att,
                type: 'unchanged'
            }
        })
        return { resources: [], attachments: attachmentsParsed }
    }
    const defaultValue = "Default";
    const differences = {};
    differences.tags = ""
    let tagsChanged = false;
    if (currentRevision.tags) {
        currentRevision.tags.forEach((tag) => {
            if (!previousRevision.tags || !previousRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        })
    }
    if (previousRevision.tags) {
        previousRevision.tags.forEach((tag) => {
            if (!currentRevision.tags || !currentRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        })
    }
    if (tagsChanged) {
        differences.tags = previousRevision.tags ? previousRevision.tags.map(tag => tag.label).join(', ') : "Default"
    }
    if (currentRevision.domainName !== previousRevision.domainName) {
        differences.domain = previousRevision.domainName || defaultValue;
    }
    if (currentRevision.planner !== previousRevision.planner) {
        differences.planner = previousRevision.planner || defaultValue;
    }
    if (currentRevision.subDomainName !== previousRevision.subDomainName) {
        differences.subDomain = previousRevision.subDomainName || defaultValue;
    }
    if (currentRevision.costTypeName !== previousRevision.costTypeName) {
        differences.costType = previousRevision.costTypeName || defaultValue;
    }
    if (currentRevision.poLocationName !== previousRevision.poLocationName) {
        differences.poLocation = previousRevision.poLocationName || defaultValue;
    }
    if (currentRevision.activityType !== previousRevision.activityType) {
        differences.activityType = activityTypes[previousRevision.activityType];
    }
    if (currentRevision.vendorType !== previousRevision.vendorType) {
        differences.vendorType = vendorTypesEnums[previousRevision.vendorType];
    }
    if (currentRevision.poLocationName !== previousRevision.poLocationName) {
        differences.poLocation = previousRevision.poLocationName || defaultValue;
    }

    if (currentRevision.activityName !== previousRevision.activityName) {
        differences.activity = previousRevision.activityName || defaultValue;
    }
    if (currentRevision.vendor !== previousRevision.vendor) {
        differences.vendor = previousRevision.vendor || defaultValue;
    }
    if (currentRevision.wbsIdentifier !== previousRevision.wbsIdentifier) {
        differences.wbs = previousRevision.wbsIdentifier || defaultValue;
    }
    if (currentRevision.year !== previousRevision.year) {
        differences.year = previousRevision.year || defaultValue;
    }
    currentRevision = { ...currentRevision };
    currentRevision.resources = currentRevision.resources || [];
    previousRevision = { ...previousRevision };
    previousRevision.resources = previousRevision.resources || [];
    if ((!currentRevision.resources[0] && previousRevision.rateYear) ||
        (currentRevision.resources[0] && !previousRevision.rateYear) ||
        (currentRevision.resources[0] && previousRevision.rateYear && (currentRevision.resources[0].vendorRate.rateYear !== previousRevision.rateYear))) {
        differences.rateYear = previousRevision.rateYear || defaultValue;
    }
    if ((!currentRevision.approver && previousRevision.approver) ||
        (currentRevision.approver && !previousRevision.approver) ||
        (currentRevision.approver && previousRevision.approver && (currentRevision.approver.name !== previousRevision.approver.name))) {
        differences.approver = previousRevision.approver.name
    }
    if ((!currentRevision.supplierRepresentative && previousRevision.supplierRepresentative) ||
        (currentRevision.supplierRepresentative && !previousRevision.supplierRepresentative) ||
        (currentRevision.supplierRepresentative && previousRevision.supplierRepresentative && (currentRevision.supplierRepresentative.name !== previousRevision.supplierRepresentative.name))) {
        differences.supplierRepresentative = previousRevision.supplierRepresentative ? previousRevision.supplierRepresentative.name : "Default";
    }
    if (currentRevision.status !== previousRevision.status) {
        differences.status = previousRevision.status || defaultValue;
    }
    differences.resources = [];
    currentRevision.resources.forEach((resource, index) => {
        const resourceDiff = { id: resource.id };
        differences.resources.push(resourceDiff);
        if (!previousRevision.resources || !previousRevision.resources[index]) {
            resourceDiff.status = "added";
            return;
        }
        const previousRevisionResource = previousRevision.resources[index];
        if (previousRevisionResource.prepayment !== undefined && resource.prepayment !== previousRevisionResource.prepayment) {
            resourceDiff.prepayment = previousRevisionResource.prepayment ? "Yes" : "No";
        }
        if (resource.name !== previousRevisionResource.name) {
            resourceDiff.name = previousRevisionResource.name;
        }
        if (formatDateWithoutHours(resource.startDate.split("Z")[0]) !== formatDateWithoutHours(previousRevisionResource.startDate.split("Z")[0])) {
            resourceDiff.startDate = formatDateWithoutHours(previousRevisionResource.startDate) || defaultValue;
        }
        if (resource.expenseTypeName !== previousRevisionResource.expenseTypeName) {
            resourceDiff.expenseType = previousRevisionResource.expenseTypeName || defaultValue;
        }

        if (resource.vendorRate.assignmentSite !== previousRevisionResource.vendorRate.assignmentSite) {
            resourceDiff.assignmentSite = previousRevisionResource.vendorRate.assignmentSite || defaultValue;
        }
        if (resource.vendorRate.resourceType !== previousRevisionResource.vendorRate.resourceType) {
            resourceDiff.resourceType = previousRevisionResource.vendorRate.resourceType || defaultValue;
        }
        if (resource.vendorRate.resourceLocation !== previousRevisionResource.vendorRate.resourceLocation) {
            resourceDiff.location = previousRevisionResource.vendorRate.resourceLocation || defaultValue;
        }
        if (resource.vendorRate.grade !== previousRevisionResource.vendorRate.grade) {
            resourceDiff.grade = previousRevisionResource.vendorRate.grade || defaultValue;
        }
        if (resource.vendorRate.unitPrice !== previousRevisionResource.vendorRate.unitPrice) {
            resourceDiff.unitPrice = previousRevisionResource.vendorRate.unitPrice;
            if (previousRevisionResource.vendorRate.unitPrice === undefined) {
                resourceDiff.unitPrice = defaultValue;
            }
        }
        if (resource.vendorRate.perDiemRate !== previousRevisionResource.vendorRate.perDiemRate) {
            resourceDiff.perDiemRate = previousRevisionResource.vendorRate.perDiemRate;
            if (previousRevisionResource.vendorRate.perDiemRate === undefined) {
                resourceDiff.perDiem = defaultValue;
            }
        }
        if (resource.vendorRate.currency !== previousRevisionResource.vendorRate.currency) {
            resourceDiff.currency = previousRevisionResource.vendorRate.currency || defaultValue;
        }
        resourceDiff.values = {};
        for (let i = 1; i <= 12; i++) {
            const current = resource.values.find((val) => {
                return val.month === i;
            })
            const previous = previousRevisionResource.values.find((val) => {
                return val.month === i;
            });

            if ((!current && previous)
                || (current && !previous)
                || (current && previous && current.value !== previous.value)) {
                resourceDiff.values[previous ? previous.month : i] = previous ? previous.value.toString() : "0";
            }
        }
        resourceDiff.tags = ""
        let tagsChanged = false;
        if (resource.tags) {
            resource.tags.forEach((tag) => {
                if (!previousRevisionResource.tags || !previousRevisionResource.tags.find((prevtag) => {
                    return tag.label === prevtag.label;
                })) {
                    tagsChanged = true;
                }
            })
        }
        if (previousRevisionResource.tags) {
            previousRevisionResource.tags.forEach((tag) => {
                if (!resource.tags || !resource.tags.find((prevtag) => {
                    return tag.label === prevtag.label;
                })) {
                    tagsChanged = true;
                }
            })
        }
        if (tagsChanged) {
            resourceDiff.tags = previousRevisionResource.tags ? previousRevisionResource.tags.map(tag => tag.label).join(', ') : "Default"
        }
    });
    differences.attachments = { deleted: [] };
    currentRevision.attachments.forEach((attachment) => {
        if (previousRevision.attachments.find((prevAttachment) => {
            return prevAttachment === attachment;
        })) {
            differences.attachments[attachment] = ""
        } else {
            differences.attachments[attachment] = "added"
        }
    });
    previousRevision.attachments.forEach((attachment) => {
        if (!currentRevision.attachments.find((currAttachment) => {
            return currAttachment === attachment
        })) {
            differences.attachments.deleted.push({ name: attachment, type: "deleted" });
        }
    });
    return differences;
}
function* changelogHistorySaga(action) {
    try {
        let initial = true;
        const fn = function* () {
            const history = yield axios.get('api/changelogs/history/' + action.payload);
            history.data = historyDataFormatter(history.data);
            history.data = history.data.map((revision) => {
                return {
                    ...revision,
                    comment: revision.comment ? {
                        ...revision.comment,
                        message: commentUtils.getEditorState(revision.comment.message),
                    } : "",
                    tags: revision.tags ? revision.tags.split(',').map((tag) => {
                        return {
                            label: tag,
                            value: tag
                        }
                    }) : null,
                    resources: revision.resources.map((resource) => {
                        return {
                            ...resource,
                            tags: resource.tags ? resource.tags.split(',').map((tag) => {
                                return {
                                    label: tag,
                                    value: tag
                                }
                            }) : null
                        }
                    })
                }
            })
            const oldHistory = yield select((store) => store.changelogForm.history);
            const isSubmitting = yield select((store) => store.changelogForm.submitting);
            if (initial) {
                if (history.data.length >= 2) {
                    const changes = getRevisionChanges(history.data[0], history.data[1]);
                    yield put(changelogFormActionsCreators.changelogFormLatestChangesChanged(changes));
                }
                initial = false;
            }
            if (oldHistory && oldHistory.length !== 0 && oldHistory.length < history.data.length && !isSubmitting) {
                yield put({ type: actionTypes.SNACKBAR_CHANGED, payload: { open: true, key: "newCl", message: "Changelog was updated. In order to get current version please refresh the page." } });
            }
            yield put(changelogFormActionsCreators.getChangelogHistoryFinished(history.data));
        }
        while (true) {
            yield fn();
            yield delay(10000)
        }
    } catch (e) {
        yield put(changelogFormActionsCreators.getChangelogHistoryFailed(history.data));
    }
}

function* pdfSaga() {
    try {
        const formState = yield select((state) => state.changelogForm);
        const document = yield ChangelogPdf(formState);
        saveAs(document, `Changelog_${formState.id}.pdf`);
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while creating pdf file."));
    }

}

function* clearVendorType(action) {
    yield* poWbsSaga();
    const vendorType = yield select((state) => state.changelogForm.vendorType.value);
    if (action.payload && action.payload.value && vendorType !== undefined) {
        const wbsesAfterActivity = yield select(getWbsesAfterActivity);
        const wbsesContainsGivenVendorType = wbsesAfterActivity.find((wbs) => {
            return wbs.vendorType === vendorType
        })
        if (!wbsesContainsGivenVendorType) {
            yield put(changelogFormActionsCreators.changelogFormVendorTypeChanged(''))
        }
    } else {
        yield put(changelogFormActionsCreators.changelogFormVendorTypeChanged(''))
    }
    yield* clearPoLocation();

}
function* clearPoLocation(action) {
    const poLocationId = yield select((state) => state.changelogForm.poLocation.value);
    if (poLocationId !== undefined) {
        const wbsesAfterVendorType = yield select(getWbsesAfterVendorType);
        const wbsesContainsGivenPoLocation = wbsesAfterVendorType.find((wbs) => {
            return wbs.poLocation.id === poLocationId;
        })
        if (!wbsesContainsGivenPoLocation) {
            yield put(changelogFormActionsCreators.changelogFormPoLocationChanged(''));
        }
    } else {
        yield put(changelogFormActionsCreators.changelogFormPoLocationChanged(''));
    }

    yield* clearCostType();
}

function* clearCostType(action) {
    const costTypeId = yield select((state) => state.changelogForm.costType.value);
    if (costTypeId !== undefined) {
        const wbsesAfterPolocation = yield select(getWbsesAfterPoLocation);
        const wbsesContainsGivenCostType = wbsesAfterPolocation.find((wbs) => {
            return wbs.costTypeId === costTypeId;
        });
        if (!wbsesContainsGivenCostType) {
            yield put(changelogFormActionsCreators.changelogFormCostTypeChanged(''));
        }
    } else {
        yield put(changelogFormActionsCreators.changelogFormCostTypeChanged(''));
    }
}
function* costTypeSaga(action) {
    try {
        const resources = yield select((store) => store.changelogForm.resources);
        const shouldSetValue = yield select(getFormLoaded);
        if (action.payload.value === undefined) {
            yield put(oneColumnActions.getPicklistItemsFinished('expenseTypes', []))
            for (let i = 0; i < resources.length; i++) {
                yield put(changelogFormActionsCreators.changelogFormResourceItemExpenseTypeChanged(i, ''));
            }
        } else {
            yield put(changelogDataActionsCreators.forceLoadingDataSpinner());
            const expenseTypes = yield axios.get('api/expenseTypes/costType/' + action.payload.value);
            yield put(oneColumnActions.getPicklistItemsFinished('expenseTypes', expenseTypes.data));
            for (let i = 0; i < resources.length; i++) {
                if (shouldSetValue && !expenseTypes.data.find((expenseType) => { return resources[i].expenseType && expenseType.id === resources[i].expenseType.value })) {
                    yield put(changelogFormActionsCreators.changelogFormResourceItemExpenseTypeChanged(i, ''));
                }
            }
            yield put(changelogDataActionsCreators.forceLoadingDataSpinner(false));
        }
        if (shouldSetValue) {
            yield put(changelogFormActionsCreators.changelogFormPoWbsChanged(''));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while loading changelog form data. Please try again later."));
    }
}
function* setResourcesSaga(action) {
    const resources = yield select((state) => state.changelogForm.resources);
    yield delay(100);
    yield put(changelogFormActionsCreators.setResourcesThrottled(resources));
}
export default [
    takeEvery([actionTypes.CREATE_CHANGELOG_PDF], pdfSagaWrapper),
    takeEvery([actionTypes.CHANGELOG_FORM_ACTIVITY_CHANGED], clearVendorType),
    takeEvery([actionTypes.CHANGELOG_FORM_VENDOR_TYPE_CHANGED], clearPoLocation),
    takeEvery([actionTypes.CHANGELOG_FORM_PO_LOCATION_CHANGED], clearCostType),
    takeLatest([actionTypes.CHANGELOG_FORM_COST_TYPE_CHANGED], costTypeSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_ACTIVITY_TYPE_CHANGED, actionTypes.CHANGELOG_FORM_SUBDOMAIN_CHANGED], activitiesWrapperSaga),
    takeLatest([actionTypes.CHANGELOG_FORM_INITIALIZE], getDictionaritesWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_DOMAIN_CHANGED], subDomainWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_VENDOR_TYPE_CHANGED], vendorWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_VENDOR_CHANGED], vendorRateWrapperSaga),
    takeLatest([actionTypes.CHANGELOG_FORM_DOMAIN_CHANGED], plannerWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RATE_YEAR_CHANGED], resourceTypeWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED], locationWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED], assignmentSiteWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED,
    actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED], gradeWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED,
    actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED], unitPriceWrapperSaga),

    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED], perDiemWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED,
    actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED], currencyWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED_STARTED], startDateWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_YEAR_CHANGED_STARTED], activityYearWrapperSaga),
    takeEvery([actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ADDED], resourceItemAddedWrapperSaga),
    takeLatest([actionTypes.SUBMIT_CHANGELOG_FORM_STARTED], submitEditChangelogFormWrapperSaga),
    takeLatest([actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_STARTED], submitEditChangelogFormWrapperSaga),
    takeLatest([actionTypes.EDIT_CHANGE_LOG_FORM_INITIALIZE], loadChangelogWrapperSaga),
    takeLatest([actionTypes.CHANGELOG_FORM_DOWNLOAD_ATTACHMENT], downloadFile),
    takeEvery([actionTypes.CHANGELOG_FORM_VENDOR_TYPE_CHANGED], changelogStatusWrapperSaga),
    takeLatest([actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENTS_STARTED], getChangelogCommentsWrappperSaga),
    takeLatest([actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_STARTED], postChangelogCommentsWrapperSaga),
    takeLatest([actionTypes.DELETE_CHANGELOG_DRAFT_STARTED], deleteDraftWrapperSaga),
    takeLatest([
        actionTypes.CHANGELOG_RESOURCE_TAGS_CHANGED,
        actionTypes.CHANGELOG_FORM_YEAR_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DELETED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ADDED,
        actionTypes.CHANGELOG_FORM_PREPAYMENT_CHANGED,
        actionTypes.CHANGELOG_FORM_DUPLICATE_RESOURCE_ITEM,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED,
        actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED,
        actionTypes.CHANGELOG_FORM_SET_STATE
    ], setResourcesSaga)
]