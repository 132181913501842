import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';
import {getEmptyComment} from '../../utils/commentUtils';

const initialState = {
    id: '',
    actualsSubmittedBy: '',
    actualsApprovedBy: '',
    activityName: '',
    deliverable: '',
    poNumber: '',
    year: '',
    month: '',
    vendor: '',
    supplierRepresentative: '',
    dcApprover: '',
    grade: '',
    unitRate: '',
    perDiemRate: '',
    currency: '',
    plannedUnitPrice: '',
    actualUnitPrice: '',
    plannedPerDiem: '',
    actualPerDiem: '',
    overtime: false,
    overtimeHours: [],
    rate: '',
    comment: getEmptyComment(),
    attachments: [],
    vendorType: '',
    numberOfExtraHours: '',
    resourceType: '',
    status: '',
    lastModifiedBy: '',
    loading: false,
    submitting: false,
    editButtonPressed: false,
    comments: [],
    rejectReason: getEmptyComment(),
    dcNumber: '',
    submitAndApproveModalOpen: false,
    rejectModalOpen: false,
    assignmentSite: '',
    isSupplier: false,
    isApprover: false,
    rmId: '',
    createdBy: "",
    showChanges: false,
    changes: {},
    errors: {
    }
}

const getNewErrors = (state, value, errorProperty, errorProperty2) => {
    const newErrors = {...state.errors};
    if (value || value === 0 || value === false) {
        newErrors[errorProperty] = ''
        if (errorProperty2) {
            newErrors[errorProperty2] = ''
        }
    }
    return newErrors;
}

const dcFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.DELIVERY_CONFIRMATION_FORM_TAGS_CHANGED: {
            return {
                ...state,
                tags: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_TAGS_SUGGESTIONS_CHANGED: {
            return {
                ...state,
                tagsSuggestions: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_LATEST_CHANGES_CHANGED: {
            return {
                ...state,
                changes: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SHOW_CHANGES_CHANGED: {
            return {
                ...state,
                showChanges: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_CREATED_BY_CHANGED: {
            return {
                ...state,
                createdBy: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_COMMENT_TEXT_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'comment')
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATION_HISTORY_FINISHED: {
            return {
                ...state,
                history: action.payload
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATION_HISTORY_FAILED: {
            return {
                ...state,
                history: []
            }
        }
        case actionTypes.DELIERY_CONFIRMATION_FORM_RM_ID_CHANGED: {
            return {
                ...state,
                rmId: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_IS_SUPPLIER_CHANGED: {
            return {
                ...state,
                isSupplier: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_IS_APPROVER_CHANGED: {
            return {
                ...state,
                isApprover: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_LOADING_SPINNER_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_STARTED: {
            return {
                ...state,
                loading: true,
                submitting: true
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_FINISHED:
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_FAILED: {
            return {
                ...state,
                loading: false,
                submitting: false
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ASSIGNMENT_SITE_CHANGED: {
            return {
                ...state,
                assignmentSite: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_AND_APPROVE_MODAL_STATE_CHANGED: {
            return {
                ...state,
                submitAndApproveModalOpen: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_REJECT_MODAL_STATE_CHANGED: {
            return {
                ...state,
                rejectModalOpen: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_COMMENTS_CHANGED: {
            return {
                ...state,
                comments: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_REJECT_REASON_CHANGED: {
            return {
                ...state,
                rejectReason: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_DC_NUMBER_CHANGED: {
            return {
                ...state,
                dcNumber: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_VENDOR_TYPE_CHANGED: {
            return {
                ...state,
                vendorType: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_EDIT_STATE_CHANGED: {
            return {
                ...state,
                editButtonPressed: action.payload
            }
        }
        case actionTypes.INITIALIZE_DELIERY_CONFIRMATION_FORM_STARTED: {
            return {
                ...state,
                loading: true,
            }
        }
        case actionTypes.INITIALIZE_DELIVERY_CONFIRMATION_FORM_FINISHED:
        case actionTypes.INITIALIZE_DELIVERY_CONFIRMATION_FORM_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_OVERTIME_HOURS_CHANGED: {
            return {
                ...state,
                overtimeHours: action.payload,
                errors: getNewErrors(state, action.payload, 'comment')
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ID_CHANGED: {
            return {
                ...state,
                id: action.payload,
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUALS_SUBMITTED_BY_CHANGED: {
            return {
                ...state,
                actualsSubmittedBy: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUALS_APPROVED_BY_CHANGED: {
            return {
                ...state,
                actualsApprovedBy: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ACTIVITY_NAME_CHANGED: {
            return {
                ...state,
                activityName: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_DELIVERABLE_CHANGED: {
            return {
                ...state,
                deliverable: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_PO_NUMBER_CHANGED: {
            return {
                ...state,
                poNumber: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_YEAR_CHANGED: {
            return {
                ...state,
                year: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_MONTH_CHANGED: {
            return {
                ...state,
                month: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_VENDOR_CHANGED: {
            return {
                ...state,
                vendor: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_SUPPLIER_REPRESENTATIVE_CHANGED: {
            return {
                ...state,
                supplierRepresentative: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_DC_APPROVER_CHANGED: {
            return {
                ...state,
                dcApprover: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_GRADE_CHANGED: {
            return {
                ...state,
                grade: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_UNIT_RATE_CHANGED: {
            return {
                ...state,
                unitRate: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_PER_DIEM_RATE_CHANGED: {
            return {
                ...state,
                perDiemRate: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_CURRENCY_CHANGED: {
            return {
                ...state,
                currency: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_PLANNED_UNIT_PRICE_CHANGED: {
            return {
                ...state,
                plannedUnitPrice: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUAL_UNIT_PRICE_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'actualUnitPrice', 'comment'),
                actualUnitPrice: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_PLANNED_PER_DIEM_CHANGED: {
            return {
                ...state,
                plannedPerDiem: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUAL_PER_DIEM_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'actualPerDiem', 'comment'),
                actualPerDiem: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_OVERTIME_CHANGED: {
            return {
                ...state,
                overtime: action.payload,
                errors: getNewErrors(state, action.payload, 'comment')
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_RATE_CHANGED: {
            return {
                ...state,
                rate: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_NUMBER_OF_EXTRA_HOURS_CHANGED: {
            return {
                ...state,
                numberOfExtraHours: action.payload,
                errors: getNewErrors(state, action.payload, 'comment')
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_ATTACHMENT_ADDED: {
            const newAttachments = [...state.attachments];
            const file = action.payload.target.files[0];
            file.key = Math.random();
            newAttachments.push(file);
            return {
                ...state,
                errors: getNewErrors(state, action.payload, 'attachments'),
                attachments: newAttachments
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_ATTACHMENT_DELETED: {
            const deleteFile = state.attachments.find((file) => {
                return file.key === action.payload.key;
            })
            const deleteIndex = state.attachments.indexOf(deleteFile);
            const newAttachments = [
                ...state.attachments.slice(0, deleteIndex),
                ...state.attachments.slice(deleteIndex + 1)
            ];
            return {
                ...state,
                attachments: newAttachments
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_COMMENT_CHANGED: {
            return {
                ...state,
                comment: action.payload,
                errors: getNewErrors(state, action.payload, 'comment')
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_RESOURCE_TYPE_CHANGED: {
            return {
                ...state,
                resourceType: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_LAST_MODIFIED_BY_CHANGED: {
            return {
                ...state,
                lastModifiedBy: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_STATUS_CHANGED: {
            return {
                ...state,
                status: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_FORM_CL_ID_CHANGED: {
            return {
                ...state,
                clId: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_RESET_FORM: {
            return {
                ...initialState
            };
        }
        default: {
            return state
        }
    }
}

export const getChanges = (state) => state.deliveryConfirmationForm.changes
export const getShowChanges = (state) => state.deliveryConfirmationForm.showChanges
export const getDcChanges = createSelector([getShowChanges, getChanges], (showChanges, changes) => {
    return showChanges ? changes : {}
});

export default dcFormReducer;