import * as actionTypes from '../actions/actionTypes';

const initialState = {
    vendors: [],
    loading: false,
    newItemVendorSapNumber: '',
    newItemVendorName: '',
    vendorSapNumberBeingEditedMap: {},
    vendorNameBeingEditedMap: {},
}

const VendorsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_VENDORS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_VENDORS_FINISHED: {
            const selectedItems = {};
            action.payload.items.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            });
            return {
                ...state,
                loading: false,
                vendors: action.payload,
                selectedItems: selectedItems
            }
        }
        case actionTypes.GET_VENDORS_FAILED: {
            return {
                ...state,
                loading: false,
                vendors: []
            }
        }
        case actionTypes.ADD_VENDOR_STARTED:
        case actionTypes.DELETE_VENDOR_STARTED:
        case actionTypes.EDIT_VENDOR_STARTED: {
            return {
                ...state,
                loading: true
            }
        } 
        case actionTypes.ADD_VENDOR_FINISHED:
        case actionTypes.DELETE_VENDOR_FINISHED:
        case actionTypes.EDIT_VENDOR_FINISHED:         
        case actionTypes.ADD_VENDOR_FAILED:
        case actionTypes.DELETE_VENDOR_FAILED:
        case actionTypes.EDIT_VENDOR_FAILED: {
            return {
                ...state,
                newItemVendorName: '',
                newItemVendorSapNumber: '',
                vendorSapNumberBeingEditedMap: {},
                vendorNameBeingEditedMap: {},
                loading: false
            }
        }
        case actionTypes.NEW_VENDOR_NAME_CHANGED: {
            return {
                ...state,
                newItemVendorName: action.payload.target.value
            }
        } 
        case actionTypes.NEW_VENDOR_SAP_NUMBER_CHANGED: {
            return {
                ...state,
                newItemVendorSapNumber: action.payload.target.value
            }
        }
        case actionTypes.EDIT_VENDOR_NAME: {
            const itemsBeingEditedCopy = {...state.vendorNameBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                vendorNameBeingEditedMap: itemsBeingEditedCopy
            }
        }
        case actionTypes.EDIT_VENDOR_SAP_NUMBER: {
            const itemsBeingEditedCopy = {...state.vendorSapNumberBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                vendorSapNumberBeingEditedMap: itemsBeingEditedCopy
            }
        }
        default: {
            return state;
        }
    }
}
export default VendorsReducer;