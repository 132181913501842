import React, {Component} from 'react';
import AppBarContainer from '../../containers/AppBarContainer/AppBarContainer';
import DrawerContainer from '../../containers/DrawerContainer/DrawerContainer';
import { Route, Switch, Redirect } from 'react-router-dom';
import StaticPickLists from './StaticPickList/StaticPickLists';
import ChangeLogViewer from './ChangelogViewer/ChangeLogViewer';
import withStyles from '@material-ui/core/styles/withStyles';
import ChangeLogCreation from './ChangelogForm/ChangeLogCreation';
import ChangeLogEdit from './ChangelogForm/ChangeLogEdit';
import ChangelogEditMenuContainer from './ChangelogForm/ChangeLogEdit/ChangelogEditMenuContainer';
import DeliveryConfirmationViewerContainer from './DeliveryConfirmationViewer/DeliveryConfirmationViewerContainer';
import DeliveryConfirmationForm from './DeliveryConfirmationForm/Form/DeliveryConfirmationForm';
import ResourceModelViewer from './ResourceModelViewer/ResourceModelViewer';
import ResourceModelEditFormContainer from './ResourceModelEditForm/ResourceModelEditFormContainer';
import ActivityCreateForm from './MasterData/ActivityForm/ActivityCreateForm';
import ActivitiesViewer from './MasterData/ActivitiesViewer/ActivitiesViewer';
import WbsViewer from './MasterData/WbsViewer/WbsViewer';
import VendorRatesViewer from './MasterData/VendorRatesViewer/VendorRatesViewer';
import WbsForm from './MasterData/WbsForm/WbsForm';
import VendorRateForm from './MasterData/VendorRateForm/VendorRateForm';
import ImportMasterData from './MasterData/ImportMasterData/ImportMasterData';
import { canFunction } from '../../config/Can';
import * as rules from '../../config/Rules';
import VendorsView from './MasterData/Vendors/VendorsView';
import GradeView from './MasterData/Grades/GradeView';
import ResourceModelHistoryContainer from './ResourceModelEditForm/ResourceModelHistoryContainer';
import DeliveryConfirmationHistoryContainer from './DeliveryConfirmationForm/Form/DeliveryConfirmationHistoryContainer';
import RegionalChangelogForm from './RegionalChangelogForm/RegionalChangelogForm';
import RegionalChangelogViewer from './RegionalChangelogViewer/RegionalChangelogViewer';
import RegionalChangelogFormHistoryContainer from './RegionalChangelogForm/RegionalChangelogFormHistoryContainer';
import SapActualsContainer from './SapActuals/SapActualsContainer';

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    contentWrapper: {
        display: 'flex',
        height: '100%',
    },
    stretch: {
        flexGrow: 1,
        paddingLeft: 48,
        overflowY: 'auto',
        height: '100%',
    }
})

class MainPage extends Component {
    render () {
        return (
            <React.Fragment>
                <AppBarContainer />
                <div className={this.props.classes.contentWrapper}>
                    <DrawerContainer backgroundTaskRunning={this.props.backgroundTaskRunning} />
                    <div className={this.props.classes.stretch} id="content" style={this.props.backgroundTaskRunning ? {height: 'calc(100% - 30px);'} : {}}>
                        <div className={this.props.classes.toolbar}>
                        </div>
                        <Switch>
                            {canFunction('', rules.NAVIGATE_CHANGELOG_CREATE) ?
                                <Route path="/changelogs/create" component={ChangeLogCreation}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_CHANGELOG_VIEWMY) ?
                                <Route path="/changelogs/my" key='mychangelogs' exact component={ChangeLogViewer}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_CHANGELOG_VIEWALL) ?
                                <Route path="/changelogs/viewAll" key='viewAll' exact component={ChangeLogViewer}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_EDITPICKLIST) ?
                                <Route path="/staticpicklist" exact component={StaticPickLists}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_CHANGELOG_CREATE) ?
                                <Route path="/changelogs/my/:id" component={ChangeLogEdit}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_CHANGELOG_CREATE) ?
                                <Route path="/changelogs/viewAll/:id" component={ChangeLogEdit} key={this.props.location.pathname}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_DELIVERY_REVIEW) ?
                                <Route path="/deliveryconfirmation/viewMy" key="viewMy" exact component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            <Route path="/deliveryconfirmation/viewMy/:id" component={DeliveryConfirmationForm}></Route>
                            <Route path="/deliveryconfirmation/view/:id" component={DeliveryConfirmationForm}></Route>
                            {canFunction('', rules.NAVIGATE_DELIVERY_APPROVE) ?
                                <Route path="/deliveryconfirmation/approve" key="approve" exact component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            <Route path="/deliveryconfirmation/approve/:id" component={DeliveryConfirmationForm}></Route>
                            {canFunction('', rules.NAVIGATE_DELIVERY_SUBMIT) ?
                                <Route path="/deliveryconfirmation/submit" key="submit" exact component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_DELIVERY_FILL_IN) || canFunction('', rules.IS_REQUESTOR) ?
                                <Route path="/deliveryconfirmation/submit" key="fillIn" exact component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            <Route path="/deliveryconfirmation/submit/:id" component={DeliveryConfirmationForm}></Route>
                            {canFunction('', rules.NAVIGATE_DELIVERY_VIEW_ALL) ?
                                <Route path="/deliveryconfirmation/viewAll" exact key="viewAlldc" component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            <Route path="/deliveryconfirmation/viewAll/:id" component={DeliveryConfirmationForm}></Route>
                            {canFunction('', rules.NAVIGATE_DELIVERY_VIEW_ALL) ?
                                <Route path="/deliveryconfirmation/toBeProcessed" exact key="toBeProcessed" component={DeliveryConfirmationViewerContainer}></Route> : null
                            }
                            <Route path="/deliveryconfirmation/toBeProcessed/:id" component={DeliveryConfirmationForm}></Route>
                            {canFunction('', rules.NAVIGATE_RESOURCEMODEL) ?
                                <Route path="/resourcemodel" exact component={ResourceModelViewer}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_RESOURCEMODEL) ?
                                <Route path="/resourcemodel/:id" component={ResourceModelEditFormContainer}></Route> : null
                            }
                            {canFunction('', rules.VIEW_ACTIVITY_FORM) ?
                                <Route path="/masterdata/createactivity" key="create" component={ActivityCreateForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_WBS_FORM) ?
                                <Route path="/masterdata/createwbs" key="create" component={WbsForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_WBS_FORM) ?
                                <Route path="/masterdata/createvendorrate" key="create" component={VendorRateForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_ACTIVITIES) ?
                                <Route path="/masterdata/viewallactivities" exact component={ActivitiesViewer}></Route> : null
                            }
                            {canFunction('', rules.VIEW_ACTIVITY_FORM) ?
                                <Route path="/masterdata/viewallactivities/:id" key="edit" component={ActivityCreateForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_WBSES) ?
                                <Route path="/masterdata/viewallwbses" exact component={WbsViewer}></Route> : null
                            }
                            {canFunction('', rules.VIEW_WBS_FORM) ?
                                <Route path="/masterdata/viewallwbses/:id" key="edit" component={WbsForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_VENDOR_RATES) ?
                                <Route path="/masterdata/viewallvendorrates" exact component={VendorRatesViewer}></Route> : null
                            }
                            {canFunction('', rules.VIEW_VENDOR_RATE_FORM) ?
                                <Route path="/masterdata/viewallvendorrates/:id" component={VendorRateForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_VENDOR_PICKLIST) ?
                                <Route path="/masterdata/vendors" component={VendorsView}></Route> : null
                            }
                            {canFunction('', rules.VIEW_IMPORT_MASTERDATA) ?
                                <Route path="/masterdata/importmasterdata" exact component={ImportMasterData}></Route> : null
                            }
                            {canFunction('', rules.VIEW_GRADES_PICKLIST) ?
                                <Route path="/masterdata/grades" exact component={GradeView}></Route> : null
                            } 
                            {canFunction('', rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC) ?
                                <Route path="/budgetPlans/createByCc" key="cc" exact component={RegionalChangelogForm}></Route> : null
                            }
                            {canFunction('', rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY) ?
                                <Route path="/budgetPlans/createByActivity" key="activity" exact component={RegionalChangelogForm}></Route> : null
                            }
                            {canFunction('', rules.VIEW_SAP_ACTUALS) ?
                                <Route path="/sapActuals" exact component={SapActualsContainer}></Route> : null
                            }
                            <Route path="/budgetPlans/viewAll" exact key="viewAll" component={RegionalChangelogViewer}></Route>
                            <Route path="/budgetPlans/my" exact key="my" component={RegionalChangelogViewer}></Route>
                            <Route path="/budgetPlans/viewAll/:id" key="edit" component={RegionalChangelogForm}></Route>
                            <Route path="/budgetPlans/my/:id" key="edit" component={RegionalChangelogForm}></Route>
                            <Route>
                                <Redirect to="/"/>
                            </Route>
                        </Switch>
                    </div>
                    <Route path="/changelogs/my/:id" component={ChangelogEditMenuContainer}></Route>
                    <Route path="/changelogs/viewAll/:id" component={ChangelogEditMenuContainer}></Route>
                    <Route path="/resourcemodel/:id" component={ResourceModelHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/viewMy/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/view/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/approve/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/submit/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/viewAll/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/deliveryconfirmation/toBeProcessed/:id" component={DeliveryConfirmationHistoryContainer}></Route>
                    <Route path="/budgetPlans/my/:id" component={RegionalChangelogFormHistoryContainer}></Route>
                    <Route path="/budgetPlans/viewAll/:id" component={RegionalChangelogFormHistoryContainer}></Route>

                </div>
            </React.Fragment>
        )
    }
}
export default withStyles(styles)(MainPage)