import React from 'react';
import AutoSelect from '../../../../../components/Input/AutoSelect';
import {canFunction} from '../../../../../config/Can';
import * as rules from '../../../../../config/Rules';

export default ({isEditing, tags, tagsSuggestions, tagsChanged, changes}) => {
    return (
        <React.Fragment>
        {canFunction('', rules.EDIT_FORM_TAGS) ?
            <AutoSelect 
                label="Tags" 
                isMulti
                width="100%" 
                disabled={!isEditing}
                value={tags}
                suggestions={tagsSuggestions} 
                handleChangeSingle={tagsChanged}
                className={changes && changes.tags ? "input-updated": ""}
                tooltipText={changes && changes.tags}
            />
        : 
        null
        }
    </React.Fragment>
    )
}

