import * as actionTypes from './actionTypes';

export const getWbsColumnsStarted = (myParam) => {
    return {type: actionTypes.GET_WBS_COLUMNS_STARTED, payload: myParam}
}
export const getWbsColumnsFailed = (myParam) => {
    return {type: actionTypes.GET_WBS_COLUMNS_FAILED, payload: myParam}
}
export const getWbsColumnsFinished = (myParam) => {
    return {type: actionTypes.GET_WBS_COLUMNS_FINISHED, payload: myParam}
}

export const getWbsListStarted = (myParam) => {
    return {type: actionTypes.GET_WBS_LIST_STARTED, payload: myParam}
}
export const getWbsListFailed = (myParam) => {
    return {type: actionTypes.GET_WBS_LIST_FAILED, payload: myParam}
}
export const getWbsListFinished = (myParam) => {
    return {type: actionTypes.GET_WBS_LIST_FINISHED, payload: myParam}
}

export const updateWbsColumnsStarted = (myParam) => {
    return {type: actionTypes.UPDATE_WBS_COLUMNS_STARTED, payload: myParam}
}
export const updateWbsColumnsFailed = (myParam) => {
    return {type: actionTypes.UPDATE_WBS_COLUMNS_FAILED, payload: myParam}
}
export const updateWbsColumnsFinished = (myParam) => {
    return {type: actionTypes.UPDATE_WBS_COLUMNS_FINISHED, payload: myParam}
}


export const wbsFormInitializeStarted = (myParam) => {
    return {type: actionTypes.WBS_FORM_INITIALIZE_STARTED, payload: myParam}
}
export const wbsFormInitializeFailed = (myParam) => {
    return {type: actionTypes.WBS_FORM_INITIALIZE_FAILED, payload: myParam}
}
export const wbsFormInitializeFinished = (myParam) => {
    return {type: actionTypes.WBS_FORM_INITIALIZE_FINISHED, payload: myParam}
}

export const wbsEditFormInitializeStarted = (myParam) => {
    return {type: actionTypes.WBS_EDIT_FORM_INITIALIZE_STARTED, payload: myParam}
}
export const wbsEditFormInitializeFailed = (myParam) => {
    return {type: actionTypes.WBS_EDIT_FORM_INITIALIZE_FAILED, payload: myParam}
}
export const wbsEditFormInitializeFinished = (myParam) => {
    return {type: actionTypes.WBS_EDIT_FORM_INITIALIZE_FINISHED, payload: myParam}
}

export const wbsFormDomainChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_DOMAIN_CHANGED, payload: myParam}
}
export const wbsFormCareCcChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_CARE_CC_CHANGED, payload: myParam}
}
export const wbsFormCostTypeChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_COST_TYPE_CHANGED, payload: myParam}
}
export const wbsFormVendorTypeChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_VENDOR_TYPE_CHANGED, payload: myParam}
}
export const wbsFormCProjectChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_C_PROJECT_CHANGED, payload: myParam}
}

export const wbsFormCWbsChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_C_WBS_CHANGED, payload: myParam}
}
export const wbsFormPoCcChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_PO_CC_CHANGED, payload: myParam}
}
export const wbsFormActivityNameChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_ACTIVITY_NAME_CHANGED, payload: myParam}
}
export const wbsFormPoWbsChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_PO_WBS_CHANGED, payload: myParam}
}
export const wbsFormDeprecationStartDateChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_DEPRECATION_START_DATE_CHANGED, payload: myParam}
}
export const wbsFormDeprecationDurationChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_DEPRECATION_DURATION_CHANGED, payload: myParam}
}
export const wbsFormDeprecationCostCenterChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_DEPRECATION_COST_CENTER_CHANGED, payload: myParam}
}
export const wbsFormErrorsChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_ERRORS_CHANGED, payload: myParam}
}
export const submitWbsFormStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_WBS_FORM_STARTED, payload: myParam}
}
export const submitWbsFormFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_WBS_FORM_FAILED, payload: myParam}
}
export const submitWbsFormFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_WBS_FORM_FINISHED, payload: myParam}
}
export const resetWbsForm = (myParam) => {
    return {type: actionTypes.RESET_WBS_FORM, payload: myParam};
}
export const wbsFormIdChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_ID_CHANGED, payload: myParam};
}
export const getWbsStarted = (myParam) => {
    return {type: actionTypes.GET_WBS_STARTED, payload: myParam};
}
export const getWbsFailed = (myParam) => {
    return {type: actionTypes.GET_WBS_FAILED, payload: myParam};
}
export const getWbsFinished = (myParam) => {
    return {type: actionTypes.GET_WBS_FINISHED, payload: myParam};
}
export const availableWbsFormActivitiesChanged = (myParam) => {
    return {type: actionTypes.AVAILABLE_WBS_FORM_ACTIVITIES_CHANGED, payload: myParam};
}
export const forceWbsFormLoadingSpinnner = (myParam) => {
    return {type: actionTypes.FORCE_WBS_FORM_LOADING_SPINNER, payload: myParam};
}
export const forceWbsFormCloseLoadingSpinner = (myParam) => {
    return {type: actionTypes.FORCE_WBS_FORM_CLOSE_LOADING_SPINNER, payload: myParam};
}

export const wbsFormPoWbsDescriptionChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_PO_WBS_DESCRIPTION_CHANGED, payload: myParam};
}

export const wbsFormPoLocationChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_PO_LOCATION_CHANGED, payload: myParam};
}

export const wbsFormClientIdChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_CLIENT_ID_CHANGED, payload: myParam};
}
export const wbsListChangeLoadingSpinner = (myParam) => {
    return {type: actionTypes.WBS_LIST_CHANGE_LOADING_SPINNER, payload: myParam};
}
export const wbsFormInactiveChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_INACTIVE_CHANGED, payload: myParam};
}
export const wbsFormAlreadyInUseChanged = (myParam) => {
    return {type: actionTypes.WBS_FORM_ALREADY_IN_USE_CHANGED, payload: myParam};
}
