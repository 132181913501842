import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeliveryInformation from './DeliveryInformation';
import { getRmChanges } from '../../../store/reducers/ResourceModelFormReducer';

const mapStateToProps = (state) => {
    return {
        domain: state.resourceModelForm.resourceModel.domain || '',
        subDomain: state.resourceModelForm.resourceModel.subDomain || '',
        activityType: state.resourceModelForm.resourceModel.activityType,
        activityName: state.resourceModelForm.resourceModel.activityName || '',
        costType: state.resourceModelForm.resourceModel.costType || '',
        powbs: state.resourceModelForm.resourceModel.powbs || '',
        poLocation: state.resourceModelForm.resourceModel.poLocation && state.resourceModelForm.resourceModel.poLocation.name ?
        state.resourceModelForm.resourceModel.poLocation.name + ` (${state.resourceModelForm.resourceModel.poLocation.number})` : '',
        year: state.resourceModelForm.resourceModel.year || '',
        changes: getRmChanges(state)
    }
}
class DeliveryInformationContainer extends Component {
    render() {
        return (
            <DeliveryInformation {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, null)(DeliveryInformationContainer);