import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import DataContainer from './DataContainer'
import SubmitPanelContainer from './SubmitPanelContainer';
import * as activityActions from '../../../../store/actions/activity';
const styles = {
    container: {
        paddingTop: '20px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.activityForm.loading || state.activityForm.submitting
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: () => {
        dispatch(activityActions.activityFormInitializeStarted());
      },
      loadEditForm: (payload) =>
        dispatch(activityActions.activityEditFormInitializeStarted(payload)),
      resetForm: () => {
        dispatch(activityActions.resetActivityForm());
      }
  });
class DeliveryConfirmationApproverForm extends Component {
    componentDidMount() {
        if (this.props.location.pathname.indexOf('/masterdata/viewallactivities') === -1) {
            this.props.loadView();
        } else {
            this.props.loadEditForm(this.props.location.pathname.split('/')[3]);
        }
        
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render () {
        return (
            <div className={this.props.classes.container}>
                <LoadingSpinner loading={this.props.loading} spinnerCentered={true}></LoadingSpinner>
                <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                        <DataContainer />
                    </Grid>
                    <Grid item xs={12}>
                        <SubmitPanelContainer />
                    </Grid>
                </Grid>
            </div>
        )
    }
}


  export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(DeliveryConfirmationApproverForm);