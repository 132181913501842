import * as actionTypes from '../actions/actionTypes';
import regionalResourceReducer from './regionalResourceReducer';
import { createSelector } from 'reselect';
import {getAllCostTypesParsedWithDeletedFiltered} from './OneColumnPicklistReducer';
import {getEmptyComment} from '../../utils/commentUtils';

const initialState = {
    domain: '',
    subDomain: '',
    costCenter: '',
    costCenters: [],
    budgetYear: '',
    planByCostCenter: false,
    loading: false,
    submitting: false,
    resources: [{}],
    errors: {},
    activities: [],
    history: [],
    comment: getEmptyComment(),
    showChanges: false,
    changes: null
}

const getNewErrors = (state, errorProperty) => {
    const newErrors = {...state.errors};
    newErrors[errorProperty] = ''
    return newErrors;
}

const regionalChangelogReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_REGIONAL_CHANGELOG_TAGS_FINISHED: {
            return {
                ...state,
                tagsSuggestions: action.payload
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOG_LINE_TAGS_FINISHED: {
            return {
                ...state,
                lineTagsSuggestions: action.payload

            }
        }
        case actionTypes.REGIONAL_CHANGELOG_TAGS_CHANGED: {
            return {
                ...state,
                tags: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_LATEST_CHANGES_CHANGED: {
            return {
                ...state,
                changes: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_SHOW_CHANGES_CHANGED: {
            return {
                ...state,
                showChanges: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_COMMENT_CHANGED: {
            return {
                ...state,
                comment: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_HISTORY_CHANGED: {
            return {
                ...state,
                history: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_CHANGE_LOADING_SPINNER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_ACTIVITIES_CHANGED: {
            return {
                ...state,
                activities: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM: {
            return initialState
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_STARTED: {
            return {
                ...state,
                loading: true,
                submitting: true
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_FAILED:
        case actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_FINISHED: {
            return {
                ...state,
                loading: false,
                submitting: false
            }
        }
        case actionTypes.INITIALIZE_EDIT_REGIONAL_CHANGELOG_FORM:
        case actionTypes.INITIALIZE_REGIONAL_CHANGELOG_FORM: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.INITIALIZE_REGIONAL_CHANGELOG_FORM_FINISHED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_DOMAIN_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, 'domain'),
                domain: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, 'subDomain'),
                subDomain: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_COST_CENTER_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, 'costCenter'),
                costCenter: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_BUDGET_YEAR_CHANGED: {
            return {
                ...state,
                errors: getNewErrors(state, 'budgetYear'),
                budgetYear: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_SET_STATE: {
            return {
                ...state,
                errors: {},
                ...action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_ADD_RESOURCE_ITEM: {
            const newResources = [...state.resources];
            newResources.push({});
            return {
                ...state,
                resources: newResources
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_DELETE_RESOURCE_ITEM: {
            return {
                ...state,
                resources: [
                    ...state.resources.slice(0, action.payload),
                    ...state.resources.slice(action.payload + 1)
                ]
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_LINE_TAGS_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_STARTED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FAILED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_STARTED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FAILED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_PLANS_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTUALS_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED: {
            const errors = state.errors.resources && state.errors.resources[action.index] ? state.errors.resources[action.index] : null;
            let newErrors = {...state.errors};
            if (errors) {
                let newResourceErrors = {...newErrors.resources};
                let property = getResourceProperty(action.type);
                newResourceErrors[action.index] = {...newErrors.resources[action.index], [property]: ''};
                newErrors['resources'] = newResourceErrors;
            }
            return {
                ...state,
                errors: newErrors,
                resources: [
                    ...state.resources.slice(0, action.index),
                    regionalResourceReducer(state.resources[action.index], action),
                    ...state.resources.slice(action.index + 1)
                ]
            }
        }

        default: {
            return state;
        }
    }
}
function getResourceProperty(actionType) {
    switch(actionType) {
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED: {
            return 'costCenter';
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED: {
            return 'activity';
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED: {
            return 'costType';
        }  
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED: {
            return 'wbs';
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED: {
            return 'expenseType';
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED: {
            return 'currency';
        }
        default: {
            return '';
        }
    }
}
export default regionalChangelogReducer;
const getResources = (state) => state.regionalChangelogForm.resources;
const getPlanByCostCenter = (state) => state.regionalChangelogForm.planByCostCenter;
export const getCostTypesPerResource = createSelector([getResources, getPlanByCostCenter, getAllCostTypesParsedWithDeletedFiltered], (resources, planByCostCenter, costTypes) => {
    const map = {}
    resources.forEach((resource, index) => {
        if (planByCostCenter) {
            map[index] = costTypes ? costTypes: []
        } else {
            if (!resource.activity || !resource.activity.value) {
                return []
            }
            const uniqueCostTypes = resource.wbses && resource.wbses.length > 0 ?
            resource.wbses.filter(wbs => !wbs.isDeleted).map((vendor) => {return {[vendor.costTypeId]:true}})
            .reduce((obj1, obj2) => Object.assign(obj1, obj2), {}) : [];
            const availableCostTypes = costTypes && costTypes.length ?
            costTypes
            .filter((costType) => {
                return uniqueCostTypes[costType.value]
            }) : [];
            map[index] = availableCostTypes;
        }
    })
    return map;
});
export const getWbsesPerResource = createSelector([getResources, getPlanByCostCenter], (resources, planByCostCenter) => {
    if (planByCostCenter) {
        return {};
    }
    const map = {};
    resources.forEach((resource, index) => {
        if (!resource.costType || !resource.costType.value) {
            map[index] = [];
        } else {
            map[index] = resource.wbses ? resource.wbses.filter((wbs) => {
                return wbs.costTypeId === resource.costType.value && !wbs.isDeleted
            }).map((wbs) => {
                return {
                    label: wbs.identifier,
                    value: wbs.id
                }
            }) : [];
        }
    })
    return map;
})

const getActivites = (store) => store.regionalChangelogForm.activities; 
export const getActivitiesPerResource = createSelector([getResources, getPlanByCostCenter, getActivites], (resources, planByCostCenter, globalActivities) => {
    if (planByCostCenter) {
        return {};
    }
    const map = {};
    resources.forEach((resource, index) => {  
        if (resource.costCenter && resource.costCenter.value) {
            map[index] = resource.activities ? resource.activities.filter((activity) => !activity.isDeleted).map((activity) => {
                return {
                    label: activity.name,
                    value: activity.id
                }
            }) : [];
        } else {
            map[index] = globalActivities ? globalActivities.filter((activity) => !activity.isDeleted).map((activity) => {
                return {
                    label: activity.name,
                    value: activity.id
                }
            }) : [];
        }

    })
    return map;
});

const getChanges = (state) => state.regionalChangelogForm.changes
const getShowChanges = (state) => state.regionalChangelogForm.showChanges
export const getRegionalChangelogChanges = createSelector([getShowChanges, getChanges], (showChanges, changes) => {
    return showChanges ? changes.differences || "" : ""
});
