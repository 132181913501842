import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as regionalChangelogActions from '../../../store/actions/regionalChangelogActions';
import Comments from './components/Comments';

const mapStateToProps = (state) => {
    return {
        comment: state.regionalChangelogForm.comment,
        comments: state.regionalChangelogForm.comments || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    commentChanged: (payload) => 
        dispatch(regionalChangelogActions.regionalChangelogFormCommentChanged(payload))        
    }
);


class CommentsContainer extends Component {
    render () {
        return (
            <Comments {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer)