import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: {},
}

const dataExportReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.EXPORT_DATA_LOADING_CHANGED: {
            const loadingCopy = {...state.loading};
            loadingCopy[action.payload.url] = action.payload.loading
            return {
                ...state,
                loading: loadingCopy
            }
        }
        default: {
            return state
        }
    }
}

export default dataExportReducer;