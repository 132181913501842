import { delay } from 'redux-saga/effects'
export default function*() { 
    yield delay(250);
    const errors = document.querySelectorAll('#content .error');
    if (!errors || errors.length === 0) {
        document.querySelector('#content').scrollTop = 0;
    }
    let lowestOffset;
    errors.forEach((error) => {
        if (lowestOffset === undefined || error.getBoundingClientRect().top < lowestOffset) {
            lowestOffset = error.getBoundingClientRect().top;
        }
    })
    var height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
    lowestOffset -= height/2;
    const container = document.querySelector('#content');
    container.scrollBy({behavior: 'smooth', top: lowestOffset})
}