import * as actionTypes from './actionTypes';

export const postBackofficeChangelogCommentStarted = (myParam) => {
    return {type: actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_STARTED, payload: myParam}
}
export const postBackofficeChangelogCommentFailed = (myParam) => {
    return {type: actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_FAILED, payload: myParam}
}
export const postBackofficeChangelogCommentFinished = (myParam) => {
    return {type: actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_FINISHED, payload: myParam}
}
export const backofficeChangelogCommentChanged = (myParam) => {
    return {type: actionTypes.BACKOFFICE_CHANGELOG_COMMENT_CHANGED, payload: myParam}
}

export const getBackofficeChangelogCommentsStarted = (myParam) => {
    return {type: actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENTS_STARTED, payload: myParam}
}
export const getBackofficeChangelogCommentsFailed = (myParam) => {
    return {type: actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENTS_FAILED, payload: myParam}
}
export const getBackofficeChangelogCommentsFinished = (myParam) => {
    return {type: actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENT_FINISHED, payload: myParam}
}