import { canFunction } from '../config/Can';

const specialVendorName = 'PLAN'
//budget plan id
export const canEdit = (props, rule) => {
    return  (!props.viewMode) && (!props.id || canFunction(props.role, rule));
}
//resource id
export const canEditWithResource = (props, rule) => {
    return (!props.viewMode) && (!props.id || canFunction(props.role, rule));
}