import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
    RmIdWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        fontSize: 16,
        marginLeft: -12
    },
    RmIdTextWrapper: {
        marginLeft: 12,
        display: 'flex',
        alignItems:'center',
    },
    RmIdTextLabel: {
        marginLeft: 4,
        color: '#595959',
        fontWeight: 'bold',
    },
    RmIdTextValueWrapper: {
        marginLeft: 12
    },
    RmIdTextIdWrapper: {
        color: '#262626',
        whiteSpace: 'pre-line'
    }
}
const useStyles = makeStyles(styles);

export default (props) => {
    const classes = useStyles();
    let textWithIcon =  (
        <span className={classes.RmIdWrapper + " " + props.className}>
            <span className={classes.RmIdTextWrapper}>
                <props.icon fontSize="small"/>
                <span className={classes.RmIdTextLabel}>{props.label}</span>
            </span>
            <span className={classes.RmIdTextValueWrapper}>
                <span className={classes.RmIdTextIdWrapper}>{props.value}</span>
            </span>
        </span>
    )
    if (props.tooltipText) { 
        textWithIcon = (<Tooltip interactive title={props.tooltipText} placement="top">
                            {textWithIcon}
                        </Tooltip>
                        )
    }
    return textWithIcon;
}