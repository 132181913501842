import { canFunction } from '../config/Can';
//isDraft prop is enabled when user is editing changelog draft
//editing prop is set to true if changelog edit form is open, on create form it is set to false
//isChangelogOwner means that user is requestor but he is not backoffice
//editMode is a prop on edit form for backoffice. It is set to true when they press edit button

const specialVendorName = 'PLAN'
//it means can edit changelog field with requestor role being always forbidden
export const canEdit = (props, rule) => {
    return  (props.isDraft || !props.editing || (canFunction(props.role, rule) && props.editMode)) || (props.isChangelogOwner && (props.vendorBaseName === specialVendorName || !props.resourceModelGenerated));
}
//it means can edit changelog field with requestor condition
export const canEditWithRequestor = (props, rule) => {
    return (props.isDraft || 
        (!(props.editing && !props.isChangelogOwner)) || 
        (canFunction(props.role, rule) && props.editMode));
}
//it means can edit changelog resource field with requestor being forbidden
export const canEditWithResource = (props, rule) => {
    return (props.isDraft || 
            ((!props.resource.resourceModelId || props.vendorBaseName === specialVendorName) && props.isChangelogOwner) ||
            !props.editing || 
            (canFunction(props.role, rule) && props.editMode));
}
//it means can edit changelog resource field with requestor condition
export const canEditWithResourceAndRequestor = (props, rule) => {
    return props.isDraft || 
    ((!props.resource.id) && ( props.isChangelogOwner || !props.editing || (canFunction(props.role, rule) && props.editMode)))
}
//it means field is disabled on  changelog resource
export const isDisabledWithResource = (props, rule, allow) => {
    return !canEditWithResource(props, rule) && !(allow && props.isChangelogOwner);
}
//it means field is disabled with requestor always having this field disabled
export const isDisabled = (props, rule) => {
    return !canEdit(props, rule)
}
//it means field is disabled with requestor condition checked
export const isDisabledWithRequestor = (props, rule) => {
    return !canEditWithRequestor(props,rule)
}
