import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApprovedBy from './components/ApprovedBy'

const mapStateToProps = (state) => {
    return {
        actualsApprovedBy: state.deliveryConfirmationForm.actualsApprovedBy,
    }
}

class ActualsApprovedByContainer extends Component {
    render() {
        return (
            <ApprovedBy actualsApprovedBy={this.props.actualsApprovedBy}/>
        )
    }
}
export default connect(mapStateToProps, null)(ActualsApprovedByContainer);