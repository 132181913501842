import React, { PureComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearAll from '@material-ui/icons/ClearAll'
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Input from '../Input/Input';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TableSortLabel from './TableSortLabel';


function ColumnCustomizationModal(props) {
  const onDragEnd = function (result) {
    if (!result.destination) {
      return;
    }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  props.onColumnsOrderChanged(reorder(props.columns, result.source.index, result.destination.index));
  }
  const columnVisibilityChanged = (event) => {
    const newColumns = [...props.columns];
    const columnToBeChanged = newColumns.find((column) => {
        return column.header === event.target.name
    })
    const indexOfChangedColumn = newColumns.indexOf(columnToBeChanged);
    const changedColumnCopy = {...columnToBeChanged};
    changedColumnCopy.isVisible = event.target.checked;
    newColumns[parseInt(indexOfChangedColumn)] = changedColumnCopy;
    props.onColumnVisibilityChanged(newColumns);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {props.columns.map((item, index) => (
              <Draggable key={item.header} draggableId={item.header} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Checkbox color="primary" checked={item.isVisible} name={item.header} onChange={columnVisibilityChanged}>
                    </Checkbox>
                    {item.header}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}


function ColumnCustomizationButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    function handleClick(event) {
      setAnchorEl(event.currentTarget);
    }
  
    function handleClose() {
      setAnchorEl(null);
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div>
        <Tooltip title="Customize table" placement="right">
            <SettingsIcon aria-describedby={id} variant="contained" onClick={handleClick}>
            </SettingsIcon>
        </Tooltip>
        <Popover
          className={"table-customization"}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
            <ColumnCustomizationModal 
                onColumnsOrderChanged={props.onColumnsOrderChanged}
                columns={props.columns}
                onColumnVisibilityChanged ={props.onColumnVisibilityChanged}
            />
        </Popover>
      </div>
    );
  }

function EnhancedTableHead(props) {
    const { tableRowHeadClass, selectionEnabled, columns, onFilteredChanged, filtered, onFilteredCleared, onColumnsOrderChanged, selectionColumnHeader } = props;
    const createSortHandler = property => event => {
      if (props.onSortClick) {
        props.onSortClick(event, property);
      }
    };
    return (
        <TableHead>
            <TableRow className={tableRowHeadClass ? tableRowHeadClass: "header-row"}>
                {selectionEnabled ? 
                        <TableCell padding="none">
                          <div style={{width: 40}}>
                            {selectionColumnHeader ?
                            <Input fullWidth small label={selectionColumnHeader} className="onlyHeader"/> : null
                            }
                          </div>
                        {/* <Tooltip title="Select All" placement="bottom">
                            <Checkbox
                              color="primary"
                              indeterminate={numSelected > 0 && numSelected < rowCount}
                              checked={numSelected === rowCount}
                              onChange={onSelectedAll}
                              inputProps={{ 'aria-label': 'Select all' }}
                            />
                        </Tooltip> */}
                      </TableCell> : null
                }

                {columns.map((column) => {
                    if (!column.isVisible) {
                      return null;
                    }
                    if (column.headerOnly) {
                      return (<TableCell align="center" padding="none" key={column.header} className="header-text-only">{column.header}</TableCell>);
                    }
                    return (<TableCell key={column.header} padding="none">
                              <TableSortLabel
                                  sortable={props.sortable}
                                  active={filtered.sortBy === column.dataProperty}
                                  direction={filtered.sortBy === column.dataProperty && filtered.desc === 1 ?  'desc' : 'asc'}
                                  onClick={createSortHandler(column.dataProperty)}
                              >
                                {props.customFilters && props.customFilters[column.dataProperty] ? props.customFilters[column.dataProperty](filtered[column.dataProperty] ? filtered[column.dataProperty] : '', props.dropdownFilteredChanged) :
                                <Input onDeleteIconClicked={onFilteredChanged} adornmentAlwaysVisible disabled={column.disabledFiltering} placeholder={column.disabledFiltering ? "disabled" : "Search..."} fullWidth small label={column.header} onChange={onFilteredChanged} inputProps={{name:column.dataProperty}} value={filtered[column.dataProperty] ? filtered[column.dataProperty] : ''}/>}
                              </TableSortLabel>
                            </TableCell>
                            )
                })}
                {props.customizationEnabled ? 
                <TableCell padding="none">
                  <div style={{display:"flex", flexDirection:"column", paddingLeft: 4, paddingRight: 4}}>
                    <ColumnCustomizationButton 
                        onColumnsOrderChanged={onColumnsOrderChanged}
                        columns={columns}
                        onColumnVisibilityChanged ={props.onColumnVisibilityChanged}
                    />
                    <Tooltip title="Clear all filters" placement="right">
                        <ClearAll onClick={onFilteredCleared}/>
                    </Tooltip>
                  </div>
                </TableCell> : null
                }

            </TableRow>
        </TableHead>
    )
}

class EnhancedTableBody extends PureComponent  {

  getColumnsCount = () => {
    let visibleColumnsCount = 0;
    this.props.columns.forEach((column) => {
      if (column.isVisible) {
        visibleColumnsCount +=1;
      }
    })
    if (this.props.selectionEnabled) {
      visibleColumnsCount +=1;
    }
    if (this.props.actionMenu) {
      visibleColumnsCount +=1;
    }
    return visibleColumnsCount;
  }

  render() {
    const {canSelectRowProperty, tooltipProperty, columns, data, onSelectedChanged, addNewItemRenderer, selectionEnabled, selected, actionMenu} = this.props;
    const emptyRows = this.props.rowsPerPage - data.length;
    const preventScroll = function(e) { 
      if (e.button === 1) {
        e.preventDefault();
        return false; 
      }
     
    }
    return (
        <TableBody>
        {data.map((row) => {
            return <TableRow className="body-row" hover  key={row.id}>
            {selectionEnabled ? 
                          <TableCell padding="none">
                            {row[tooltipProperty] ? 
                            <Tooltip title={row[tooltipProperty]} placement="right">  
                                <span>
                                  <Checkbox
                                      color="primary"
                                      disabled={!row[canSelectRowProperty]}
                                      checked={selected[row.id] === true}
                                      onChange={onSelectedChanged}
                                      inputProps={{ 'aria-label': 'select row', name: row.id }}
                                  />
                                </span>  
                            </Tooltip>
                            :
                            <span>
                            <Checkbox
                                color="primary"
                                disabled={!row[canSelectRowProperty]}
                                checked={selected[row.id] === true}
                                onChange={onSelectedChanged}
                                inputProps={{ 'aria-label': 'select row', name: row.id }}
                            />
                          </span>  
                            }
                        </TableCell> : null}

                {columns.map((column) => {
                  if (!column.isVisible) {
                    return null;
                  }
                  return <TableCell 
                  onMouseDown={preventScroll}
                  onMouseUp={this.props.navigateToDetail !== undefined ? this.props.navigateToDetail.bind(this, row) : null}
                  // onClick={this.props.navigateToDetail !== undefined ? this.props.navigateToDetail.bind(this, row) : null} 
                  key={column.header} padding="none" className={this.props.customRowCells && this.props.customRowCells[column.dataProperty] ? "" : (`data-table-cell ${this.props.navigateToDetail !== undefined ? "pointer" : ""}`)}>
                      {this.props.customRowCells && this.props.customRowCells[column.dataProperty] ? this.props.customRowCells[column.dataProperty](row[column.dataProperty], row.id, row, column.dataProperty) : row[column.dataProperty]}
                  </TableCell>
                })}  
            {actionMenu ?
              <TableCell padding="none" className="pointer">
                  {actionMenu(row)}
              </TableCell> : null
            }
            </TableRow>          
        })}
        {this.props.paginationEnabled && emptyRows > 0 ? 
              <TableRow style={{height:40 * emptyRows}}>
                <TableCell colSpan={this.getColumnsCount()}></TableCell>
              </TableRow> : null
        }

        {this.props.enabledAddition ? 
        <TableRow className="body-row" hover>
          { this.props.selectionEnabled ?
            <TableCell></TableCell> : null
          }
          {columns.map((column) => {
            return <TableCell key={column.header} padding="none">
                    {addNewItemRenderer(column.dataProperty)}
                  </TableCell>
          })}  
        </TableRow> : null
        }
        </TableBody>
    );
      }
}
const SORTABLE_ADDITION = 26;
function Colgroup(props) {
  let sortableAddition = SORTABLE_ADDITION;
  if (!props.sortable) {
    sortableAddition = 0;
  }
  const {columns, customizationEnabled, selectionEnabled} = props
  let totalWidth = 0;
  columns.forEach((column) => {
    if (column.isVisible) {
      totalWidth += (column.width + sortableAddition);
    }
  })
  // if (customizationEnabled) {
  //   totalWidth +=40;
  // }
  // if (selectionEnabled) {
  //   totalWidth +=40;
  // }
  return (
    <colgroup>
      {selectionEnabled ? <col style={{width: 40}}></col> : null}
      {columns.map((column) => {
        if (!column.isVisible) {
          return null;
        }
        return (
          <col style={{width: 100*((column.width+sortableAddition)/totalWidth)+"%"}} key={column.header}></col>
        )
      })}
      {customizationEnabled ? <col style={{width: 40}}></col> : null}
    </colgroup>
  )
}
function getMinWidthSortableAddition(columns, sortable) {
  let addition = 0;
  if (sortable) {
    columns.forEach((col) => {
      if (col.isVisible) {
        addition += SORTABLE_ADDITION;
      }
    })
    return addition;
  } else {
    return 0;
  }
}
export default function DataTable(props) {
    return (
        <div style={{position: 'relative'}}>
          <LoadingSpinner loading={props.loading}/>
            <div style={{overflowX: props.minWidth ? 'auto': null, maxHeight: props.maxHeight ? props.maxHeight: null, minHeight: props.minHeight ? props.minHeight: null}}>
              <Table style={{ minWidth: props.minWidth ? props.minWidth + getMinWidthSortableAddition(props.columns, props.sortable): null}} className={props.tableClass} stickyHeader={props.stickyHeader}>
                  {props.customColumnsWidthEnabled ? <Colgroup 
                                                      sortable={props.sortable}
                                                      columns={props.columns}
                                                      customizationEnabled={!!props.actionMenu}
                                                      selectionEnabled={props.general.selectionEnabled}
                                                      /> : null}
                  <EnhancedTableHead 
                      sortable={props.sortable}
                      onSortClick={props.onSortClick}
                      onSelectedAll={props.onSelectedAll}
                      onFilteredCleared={props.onFilteredCleared}
                      selectionEnabled={props.general ? props.general.selectionEnabled : false}
                      selectionColumnHeader={props.general ? props.general.selectionColumnHeader : null}
                      numSelected={props.selected ? Object.keys(props.selected).length: 0}
                      rowCount={props.data.length}
                      filtered={props.filtered}
                      onFilteredChanged={props.onFilteredChanged}
                      dropdownFilteredChanged={props.dropdownFilteredChanged}
                      columns={props.columns}
                      onColumnsOrderChanged={props.onColumnsOrderChanged}
                      onColumnVisibilityChanged ={props.onColumnVisibilityChanged}
                      customizationEnabled={!!props.actionMenu}
                      customFilters={props.customFilters}
                  />
                  <EnhancedTableBody
                      navigateToDetail={props.navigateToDetail}
                      data={props.data} 
                      selectionEnabled={props.general ? props.general.selectionEnabled : false}
                      columns={props.columns}
                      canSelectRowProperty={props.general ? props.general.canSelectRowProperty : ""}
                      tooltipProperty={props.general ? props.general.tooltipProperty : ""}
                      actionMenu={props.actionMenu}
                      onSelectedChanged={props.onSelectedChanged}
                      selected={props.selected}
                      enabledAddition={props.enabledAddition}
                      addNewItemRenderer={props.addNewItemRenderer}
                      customRowCells={props.customRowCells}
                      rowsPerPage={props.pagination && props.pagination.itemsPerPage ? props.pagination.itemsPerPage : 0}
                      paginationEnabled={!!props.pagination}
                  />
              </Table>
            </div>
            {props.pagination ? 
            <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={props.count}
                rowsPerPage={props.pagination.itemsPerPage}
                page={props.currentPage -1 }
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={props.onPageChanged}
                onChangeRowsPerPage={props.onPageSizeChanged}
            /> : null
            }
        </div>
    )
}