import { put, takeEvery, take, race, call } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import { select } from 'redux-saga/effects';
import * as activityFormActions from '../store/actions/activity';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import axios from '../axios';
import history from '../history';
import activityTypes from '../constants/activityTypes';
import { getDomains } from './domainsSagas';
import { getSubDomains } from './subDomainsSagas';
import scrollToErrorSaga from './scrollToErrorSaga';
import { getCostCenters } from './costCenterSagas';
import regionTypes, { regionTypeEnums } from '../constants/regionTypes';

function* initializeActivityFormSaga() {
    try {
        yield* getDomains();
        yield* getSubDomains();
        yield* getCostCenters();
        yield put(activityFormActions.activityFormInitializeFinished());

    } catch (error) {
        yield put(activityFormActions.activityFormInitializeFailed());
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}

function* initializeEditActivityFormSaga(action) {
    try {
        const data = yield axios.get('api/Activities/'+ action.payload);
        yield* getDomains(data.data.domainId);
        yield put(activityFormActions.activityFormRegionTypeChanged({label: regionTypes[data.data.regionType], value: data.data.regionType}));
        yield* getSubDomains(data.data.subDomainId);
        yield* getCostCenters(data.data.costCenterId);
        if (data.data.domainId) {
            const domains = yield select((store) => store.domains.domains);
            const domain = domains.find((domain) => {
                return domain.id === data.data.domainId;
            })
            yield put(activityFormActions.activityFormDomainChanged({value: domain.id, label: domain.name}));
        }
        if (data.data.subDomainId) {
            const subDomains = yield select((store) => store.subDomains.subDomains);
            const subDomain = subDomains.find((subDomain) => {
                return subDomain.id === data.data.subDomainId;
            })
            yield put(activityFormActions.activityFormSubDomainChanged({value: subDomain.id, label: subDomain.name}));
        }
        if (data.data.costCenterId) {
            const costCenters = yield select((store) => store.costCenters.items);
            const costCenter = costCenters.find((costCenter) => {
                return costCenter.id === data.data.costCenterId;
            })
            yield put(activityFormActions.activityFormCostCenterChanged({value: costCenter.id, label: costCenter.name}));
        }
        yield put(activityFormActions.activityFormActivityTypeChanged({label: activityTypes[data.data.activityType], value: data.data.activityType}))
        yield put(activityFormActions.activityFormIdChanged(data.data.id));
        yield put(activityFormActions.activityFormActivityNameChanged(data.data.name));
        yield put(activityFormActions.activityFormWorkIdChanged(data.data.workId));
        yield put(activityFormActions.activityFormSourceDataLinkChanged(data.data.sourceDataLink));
        yield put(activityFormActions.activityFormClientIdChanged(data.data.clientId));
        yield put(activityFormActions.activityFormProjectNumberChanged(data.data.projectNumber));
        yield put(activityFormActions.activityFormDemandNumberChanged(data.data.demandNumber));
        yield put(activityFormActions.activityFormLegacyNumberChanged(data.data.legacyNumber));
        yield put(activityFormActions.activityFormInactiveChanged(data.data.isDeleted || false));
        yield put(activityFormActions.activityEditFormInitializeFinished());
    } catch (error) {
        yield put(activityFormActions.activityEditFormInitializeFailed());
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}

function* submitFormSaga() {
    try {
        const formState = yield select((state) => state.activityForm);
        const errors = validate(formState);
        if (Object.keys(errors).length !== 0) {
            yield put(activityFormActions.activityFormErrorsChanged(errors));
            yield put(activityFormActions.submitCreateActivityFormFailed())
            yield* scrollToErrorSaga();
            return;
        }
        const data = {
            isDeleted: formState.inactive,
            clientId: formState.clientId,
            activityType: formState.activityType.value,
            name: formState.activityName,
            workId: formState.workId,
            regionType: formState.regionType.value
        }
        if (formState.sourceDataLink) {
            data.sourceDataLink = formState.sourceDataLink
        }
        if (formState.projectNumber) {
            data.projectNumber = formState.projectNumber
        }
        if (formState.demandNumber) {
            data.demandNumber = formState.demandNumber;
        }
        if (formState.legacyNumber) {
            data.legacyNumber = formState.legacyNumber;
        }
        if (formState.domain) {
            data.domainId = formState.domain.value
        }
        if (formState.subDomain) {
            data.subDomainId = formState.subDomain.value
        }
        if (formState.regionType.value !== regionTypeEnums.Central) {
            data.costCenterId = formState.costCenter.value
        }
        let id = formState.id;
        if (id) {
            data.id = id;
            yield axios.put('api/Activities', data);
            yield put(activityFormActions.submitEditActivityFormFinished());
        } else {
            yield axios.post('api/Activities', data);
            yield put(activityFormActions.submitCreateActivityFormFinished());
        }
        history.push('/masterdata/viewallactivities');
    } catch(error) {
        const formState = yield select((state) => state.activityForm);
        if (formState.id) {
            yield put(activityFormActions.submitEditActivityFormFailed());
        } else {
            yield put(activityFormActions.submitCreateActivityFormFailed());
        }
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting form. Please try again later"));
    }
}

function* clearSubDomain() {
    yield put(activityFormActions.activityFormSubDomainChanged(''));
}

function* clearCostCenter() {
    yield put(activityFormActions.activityFormCostCenterChanged(''));
}

function* initializeActivityFormWrapperSaga(action) {
    yield race({
        task: call(initializeActivityFormSaga, action),
        cancel: take(actionTypes.RESET_ACTIVITY_FORM)
    })
}
function* submitFormWrapperSaga(action) {
    yield race({
        task: call(submitFormSaga, action),
        cancel: take(actionTypes.RESET_ACTIVITY_FORM)
    })
}
function* initalizeEditActivityFormWrapperSaga(action) {
    yield race({
        task: call(initializeEditActivityFormSaga, action),
        cancel: take(actionTypes.RESET_ACTIVITY_FORM)
    })
}

function validate(formState) {
    const errors = {};
    if (!formState.activityType || formState.activityType.label === undefined) {
        errors['activityType'] = "Activity Type cannot be empty";
    }
    if (formState.sourceDataLink && formState.sourceDataLink.length !== 0 && !formState.sourceDataLink.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)) {
        errors['sourceDataLink'] = "Source data link must be a valid url";
    }
    
    if (!formState.activityName) {
        errors['activity'] = "Activity name cannot be empty";
    }
    if (!formState.regionType || !formState.regionType.label) {
        errors['regionType'] = 'Region type cannot be empty';
    }
    if (formState.regionType && formState.regionType.value !== regionTypeEnums.Central) {
        if (!formState.domain || !formState.domain.value) {
            errors['domain'] = "Platform cannot be empty"
        }
        if (!formState.subDomain || !formState.subDomain.value) {
            errors['subDomain'] = "Sub-platform cannot be empty"
        }
        if (!formState.costCenter || !formState.costCenter.value) {
            errors['costCenter'] = "Cost center cannot be empty"
        }
    }
    return errors;
}

export default [
    takeEvery([actionTypes.ACTIVITY_FORM_INITIALIZE_STARTED], initializeActivityFormWrapperSaga),
    takeEvery([actionTypes.ACTIVITY_FORM_DOMAIN_CHANGED], clearSubDomain),
    takeEvery([actionTypes.ACTIVITY_FORM_SUB_DOMAIN_CHANGED], clearCostCenter),
    takeEvery([actionTypes.SUBMIT_CREATE_ACTIVITY_FORM_STARTED,
        actionTypes.SUBMIT_EDIT_ACTIVITY_FORM_STARTED], submitFormWrapperSaga),
    takeEvery([actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_STARTED], initalizeEditActivityFormWrapperSaga)
]