import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {Editor} from "react-draft-wysiwyg";
import { makeStyles } from '@material-ui/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles({
    textArea: {
        border: '0px !important',
        width: '100%',
        fontFamily: 'Lato, sans-serif',
        fontSize: 14,
        outline: 'none',
        resize: 'none'
    },
    textAreaWrapper: {
        borderRadius: '16px',
        height: 120,
        border: '1px solid #a9a9a9',
        padding: 16,
        width: '100%',
        display: 'block',
        position: 'relative',
        overflow: 'hidden'
    },
    textAreaLabel: {
        color: '#595959',
        fontSize: '12px',
        lineHeight: '16px'
    },
    editor: {
        border: '1px solid #F1F1F1',
        padding: 16
    }
})
const placeholder = {}
function Input(props) {
    const classes = useStyles();
    const editorStyle = props.editorStyle || placeholder;
  return (
    <div style={{minWidth: props.minWidth ? props.minWidth: ""}}>
        <span className={classes.textAreaLabel}>{props.label}</span>
        <Editor 
            editorState={props.value}
            readOnly={props.disabled}
            onEditorStateChange={props.onChange}
            toolbarStyle={props.disabled && {display: 'none'}}
            editorClassName={classes.editor}
            editorStyle={props.errorText ? {borderColor: 'red', ...editorStyle} : editorStyle}
        />
        <div className={props.errorText ? "error" : ""} style={{color: 'red', fontSize:12, paddingLeft: 16}}>{props.errorText}</div>
    </div>
  );
}

export default Input;
