import React from 'react';
import DataTable from '../../../../components/DataTable/DataTable2';
import Input from '../../../../components/Input/Input';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { isTripplePrecision } from '../../../../utils/typesAssertions';

const columns = [
    {
        header: "Jan",
        dataProperty: '1',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Feb",
        dataProperty: '2',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Mar",
        dataProperty: '3',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Apr",
        dataProperty: '4',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "May",
        dataProperty: '5',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jun",
        dataProperty: '6',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jul",
        dataProperty: '7',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Aug",
        dataProperty: '8',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Sep",
        dataProperty: '9',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Oct",
        dataProperty: '10',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Nov",
        dataProperty: '11',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Dec",
        dataProperty: '12',
        isVisible: true,
        headerOnly: true
    },
]
const placeholderFnc = () => {

}

const ResourceItemTable = (props) => {
    const [monthAmount, setMonthAmount] = React.useState(0); 

    React.useEffect(() => {
        setMonthAmount(props.value);
    }, [props.value]);

    const monthAmountLocalChanged = (e) => {
        const newMonths = props.value ? {...props.value}: {};
        // const newValue = parseFloat(e.target.value);
        if (e.target.value.length === 0) {
            delete newMonths[e.target.name]
        } else {
            newMonths[e.target.name] = e.target.value;
        }
        setMonthAmount(newMonths);
    }

    const onMonthValueChanged = (e) => {
        const newMonths = props.value ? {...props.value}: {};
        if (e.target.value.length === 0) {
            delete newMonths[e.target.name]
        } else {
            newMonths[e.target.name] = e.target.value;
        }
        props.onMonthValueChanged(newMonths);
    }

    const createCustomRowCellsObject = function(monthStart) {
        const columnsCopyFromMonth = [...columns];
        const customCellRendererObject = columnsCopyFromMonth.reduce((obj, item) => {
            if (parseInt(item.dataProperty) >= monthStart) {
                obj[item.dataProperty] = (data, id, test) => {
                    return (
                        <Input
                            width="62px"
                            minWidth="62px"
                            type="number"
                            precision={(isTripplePrecision(props.resourceType)) ? 3: 2}
                            disabled={props.disabledInputs}
                            inputProps={{name:item.dataProperty, step:0.01, min:0 }}
                            value={data ? data : ""}
                            onChange={monthAmountLocalChanged}
                            onBlur={onMonthValueChanged}
                            className={props.changes && props.changes[item.dataProperty] !== undefined ? "input-updated-table": ""} 
                            tooltipText={props.changes ? props.changes[item.dataProperty] : ""}
                        />
                    )
                }
            } else {

                obj[item.dataProperty] = (data, id) => {
                    return (
                        <div style={{minWidth: monthStart === 13 && parseInt(item.dataProperty) === 12 ? 66 : 62}}></div>
                    )
                }
            }
            return obj;
        }, {});
        return customCellRendererObject;
    }
    const copyValue = (value) => {
        const copyText = document.getElementById(value);
        copyText.select()
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }
    
        const data = [{...monthAmount, ...{id:"id"}}];
        let poNumbersMap = {};
        let dcsMap = {};
        if (props.poNumbers && props.poNumbers.length !== 0) {
            props.poNumbers.forEach((poNumber) => {
                poNumbersMap[poNumber.month] = poNumber.number;
            })
        }
        if (props.dcs && props.dcs.length !== 0) {
            props.dcs.forEach((dcs) => {
                dcsMap[dcs.month] = dcs.id;
            })
        }
        return (
            <DataTable 
                minWidth={props.minWidth}
                data={data}
                customRowCells={createCustomRowCellsObject(props.monthStart)}
                columns={columns}
                tableClass="month-table"
                enabledAddition={(props.dcs && props.dcs.length !==0) || (props.poNumbers && props.poNumbers.length !==0)}
                addNewItemRenderer={ (dataProperty) => {
                    return (
                        <div style={{position:'relative', height: 40}}>
                            {dcsMap[dataProperty] ?
                            <Tooltip title="Copy link to Delivery Confirmation">
                                <div style={{position: 'absolute', left: 8, top:10}} onClick={copyValue.bind('',props.id + "dc" + dataProperty)}>
                                    <input
                                        style={{position: 'absolute',left: -9999}}
                                        id={props.id + "dc" + dataProperty}
                                        value={window.location.origin+"/deliveryconfirmation/view/"+dcsMap[dataProperty]}
                                        onChange={placeholderFnc}
                                    />
                                    <label htmlFor={props.id + "dc" + dataProperty}>
                                        <span>
                                            <FileCopyIcon className="copy-link" fontSize="small"color="primary" />
                                        </span>
                                    </label>
                                </div>
                            </Tooltip> : null
                            }
                            {poNumbersMap[dataProperty] ?
                            <Tooltip title="Copy Po Number" PopperProps={{ style: { pointerEvents: 'none' } }}>
                                <div style={{position: 'absolute', right: 8, top: 10}} onClick={copyValue.bind('',props.id + "po" + dataProperty)}>
                                    <input
                                        style={{position: 'absolute',left: -9999}}
                                        id={props.id + "po" + dataProperty}
                                        value={poNumbersMap[dataProperty]}
                                        onChange={placeholderFnc}
                                    />
                                    <label htmlFor={props.id + "po" + dataProperty}>
                                        <span>
                                            <AssignmentTurnedInIcon className="copy-link" color="primary" fontSize="small"/>
                                        </span>
                                    </label>
                                </div>
                            </Tooltip> : null
                            }
                        </div>
                    )
                }}
            />
        )
}
export default ResourceItemTable;

