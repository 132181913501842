import rules from "./rolesConfig";
import {store} from '../index';
import {NAVIGATE_DELIVERY_FILL_IN } from './Rules';

const check = (rules, role, action, data) => {
  const roles = store.getState().user.roles;
  let canPerformAction = false;
  roles.forEach((role) => {
    if (canPerformAction) {
      return;
    }
    const permissions = rules[role];
    if (!permissions) {
      // role is not present in the rules
      canPerformAction = false;
    }
  
    const staticPermissions = permissions.static;
  
    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      canPerformAction = true;
    }
    if (action === NAVIGATE_DELIVERY_FILL_IN) {    
      if (store.getState().user.supplierTabsVisible) {
        canPerformAction = true;
      }
    }  
    // const dynamicPermissions = permissions.dynamic;
  
    // if (dynamicPermissions) {
    //   const permissionCondition = dynamicPermissions[action];
    //   if (!permissionCondition) {
    //     // dynamic rule not provided for action
    //     canPerformAction = false;
    //   }
  
    //   canPerformAction = permissionCondition(data);
    // }
  });

  return canPerformAction;
};

const Can = props =>
  check(rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export const canFunction = function (role, action, data) {
  return check(rules, role, action, data);
}
export default Can;