import React, { Component } from 'react';
import { connect } from 'react-redux';
import Status from './components/Status';
import { getDcChanges } from '../../../../store/reducers/DeliveryConfirmationFormReducer';

const mapStateToProps = (state) => {
    return {
        status: state.deliveryConfirmationForm.status,
        vendorType: state.deliveryConfirmationForm.vendorType,
        changes: getDcChanges(state)
    }
}
class ChangelogLastModifiedByContainer extends Component {
    render() {
        return (
            <Status status={this.props.status} vendorType={this.props.vendorType} oldStatus={this.props.changes.status}/>
        )
    }
}
export default connect(mapStateToProps, null)(ChangelogLastModifiedByContainer);