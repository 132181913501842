import React, { Component } from 'react';
import ResourcesContainer from './ResourcesContainer';
import DeliveryInformationContainer from './DeliveryInformationContainer';
import FormSubmitPanelContainer from './FormSubmitPanelContainer';
import CommentsContainer from './CommentsContainer';
import CreatedBy from './CreatedByContainer';
import LastModifiedBy from './LastModifiedByContainer';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import * as regionalChangelogFormActions from '../../../store/actions/regionalChangelogActions';
import history from '../../../history';

const styles = {
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.regionalChangelogForm.loading || state.regionalChangelogForm.submitting,
        role: state.user.selectedRole
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
          dispatch(regionalChangelogFormActions.initializeRegionalChangelogForm(payload))
      },
      loadEditForm: (payload) =>
        dispatch(regionalChangelogFormActions.initializeEditRegionalChangelogForm(payload)),
      resetForm: () => {
       dispatch(regionalChangelogFormActions.regionalChangelogFormResetForm());
      }
  });

class RegionalChangelogForm extends Component {
    componentDidMount() {
        let byCostCenter;
        if (history.location.pathname.indexOf("createByCc") !== -1) {
            byCostCenter = true;
        } else {
            byCostCenter = false;
        }
        if (history.location.pathname.indexOf("budgetPlans/viewAll") !== -1 || history.location.pathname.indexOf("budgetPlans/my") !== -1) {
            this.props.loadEditForm(history.location.pathname.split("/")[3]);
        } else {
            this.props.loadView(byCostCenter);
        }
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render() {
        return (
            <React.Fragment>
                <div className={this.props.classes.container}>
                    <LoadingSpinner loading={this.props.loading} spinnerCentered={true}/>
                    <Grid container className="form-grid" spacing={2} ref={this.gridRef}>
                        <Grid item md={6} xs={12}>
                            <CreatedBy/>
                        </Grid>                                  
                        <Grid item md={6} xs={12}>
                            <LastModifiedBy />
                        </Grid>
                        <Grid item xs={12}>
                            <DeliveryInformationContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <ResourcesContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <CommentsContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubmitPanelContainer />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(RegionalChangelogForm);