import * as actionTypes from '../actions/actionTypes';
import vendorTypes from '../../constants/vendorTypes';

const initialState = {
    wbs: {},
    id: null,
    activities: [],
    description: '',
    domain: '',
    careCc: '',
    costType: '',
    vendorType: '',
    cProject: '',
    cWbs: '',
    poCc: '',
    activity: '',
    poWbs: '',
    depreciationStartDate: '',
    depreciationDuration: '',
    depreciationCostCenter: '',
    poLocation: '',
    loading: false,
    errors: {},
    submitting: false,
    clientId: null,
    alreadyInUse: false
}

const getNewErrors = (state, value, errorProperty, additionalProperties) => {
    const newErrors = {...state.errors};
    if (value) {
        newErrors[errorProperty] = ''
    }
    if (additionalProperties && additionalProperties.length !== 0) {
        additionalProperties.forEach((prop) => {
            newErrors[prop] = ''
        })
    }
    return newErrors;
}

const WbsFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.WBS_FORM_ALREADY_IN_USE_CHANGED: {
            return {
                ...state,
                alreadyInUse: action.payload
            }
        }
        case actionTypes.WBS_FORM_CLIENT_ID_CHANGED: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case actionTypes.WBS_FORM_INACTIVE_CHANGED: {
            return {
                ...state,
                isDeleted: action.payload
            }
        }
        case actionTypes.WBS_FORM_PO_LOCATION_CHANGED: {
            return {
                ...state,
                poLocation: action.payload,
                errors: getNewErrors(state, action.payload, 'poLocation')
            }
        }
        case actionTypes.FORCE_WBS_FORM_LOADING_SPINNER: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.FORCE_WBS_FORM_CLOSE_LOADING_SPINNER: {
            return {
                ...state,
                loading: false,
            }
        }
        case actionTypes.WBS_FORM_PO_WBS_DESCRIPTION_CHANGED: {
            return {
                ...state,
                description: action.payload,
                errors: getNewErrors(state, action.payload, 'description')
            }
        }
        case actionTypes.AVAILABLE_WBS_FORM_ACTIVITIES_CHANGED: {
            return {
                ...state,
                loading: false,
                activities: action.payload,
            }
        }
        case actionTypes.GET_WBS_STARTED:
        case actionTypes.WBS_EDIT_FORM_INITIALIZE_STARTED:
        case actionTypes.WBS_FORM_INITIALIZE_STARTED: {
            return {
                ...state,
                loading: true,
            }
        }
        case actionTypes.GET_WBS_FINISHED: {
            return {
                ...state,
                loading: false,
                wbs: action.payload
            }
        }
        case actionTypes.GET_WBS_FAILED: {
            return {
                ...state,
                loading: false,
                wbs: {}
            }
        }
        case actionTypes.WBS_EDIT_FORM_INITIALIZE_FAILED:
        case actionTypes.WBS_EDIT_FORM_INITIALIZE_FINISHED:    
        case actionTypes.WBS_FORM_INITIALIZE_FINISHED:
        case actionTypes.WBS_FORM_INITIALIZE_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }
        case actionTypes.WBS_FORM_DOMAIN_CHANGED: {
            return {
                ...state,
                domain: action.payload,
                errors: getNewErrors(state, action.payload, 'domain')
            }
        }
        case actionTypes.WBS_FORM_CARE_CC_CHANGED: {
            return {
                ...state,
                careCc: action.payload,
                errors: getNewErrors(state, action.payload, 'careCc')
            }
        }
        case actionTypes.WBS_FORM_COST_TYPE_CHANGED: {
            return {
                ...state,
                costType: action.payload,
                errors: getNewErrors(state, action.payload, 'costType', ['careCc', 'cProject', 'cWbs'])
            }
        }
        case actionTypes.WBS_FORM_VENDOR_TYPE_CHANGED: {
            return {
                ...state,
                vendorType: {label: vendorTypes[parseInt(action.payload)], value: parseInt(action.payload)},
                errors: getNewErrors(state, action.payload, 'vendorType')
            }
        }
        case actionTypes.WBS_FORM_C_PROJECT_CHANGED: {
            return {
                ...state,
                cProject: action.payload,
                errors: getNewErrors(state, action.payload, 'cProject')
            }
        }
        case actionTypes.WBS_FORM_C_WBS_CHANGED: {
            return {
                ...state,
                cWbs: action.payload,
                errors: getNewErrors(state, action.payload, 'cWbs')
            }    
        }
        case actionTypes.WBS_FORM_PO_CC_CHANGED: {
            return {
                ...state,
                poCc: action.payload,
                errors: getNewErrors(state, action.payload, 'poCc')
            }   
        }
        case actionTypes.WBS_FORM_ACTIVITY_NAME_CHANGED: {
            return {
                ...state,
                activity: action.payload,
                errors: getNewErrors(state, action.payload, 'activity')
            }   
        }
        case actionTypes.WBS_FORM_PO_WBS_CHANGED: {
            return {
                ...state,
                poWbs: action.payload,
                errors: getNewErrors(state, action.payload, 'poWbs')
            }   
        }
        case actionTypes.WBS_FORM_DEPRECATION_START_DATE_CHANGED: {
            return {
                ...state,
                depreciationStartDate: action.payload,
            }   
        }
        case actionTypes.WBS_FORM_DEPRECATION_DURATION_CHANGED: {
            return {
                ...state,
                depreciationDuration: action.payload,
            }   
        }
        case actionTypes.WBS_FORM_DEPRECATION_COST_CENTER_CHANGED: {
            return {
                ...state,
                depreciationCostCenter: action.payload,
            }   
        }
        case actionTypes.WBS_FORM_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload,
            }   
        }
        case actionTypes.SUBMIT_WBS_FORM_STARTED: {
            return {
                ...state,
                loading: true,
                submitting: true,
            }
        }
        case actionTypes.SUBMIT_WBS_FORM_FINISHED:
        case actionTypes.SUBMIT_WBS_FORM_FAILED: {
            return {
                ...state,
                loading: false,
                submitting: false,
            }
        }
        case actionTypes.RESET_WBS_FORM: {
            return {
                ...initialState
            }
        }
        case actionTypes.WBS_FORM_ID_CHANGED: {
            return {
                ...state,
                id: action.payload
            }
        }
        default: {
            return state
        }
    }
}
export default WbsFormReducer;
