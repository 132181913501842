import React, { Component } from 'react';
import ResourceList from './components/ResourceList';
import DeliveryInformation from './components/DeliveryInformation';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles'
import Comments from './components/Comments';

const useStyles = makeStyles({
    container: {
        maxWidth: 880,
        margin: '0 auto',
        display: 'flex',
        position: 'relative'
    }
})
export const getChanges = (currentRevision, previousRevision) => {
    const differences = {};
    if (!previousRevision) {
        return {differences, currentRevision};
    }
    if (currentRevision.resources) {
        currentRevision = {...currentRevision};
        currentRevision.resources = [...currentRevision.resources].sort((a,b) => {
            if (a.id > b.id) {
                return 1;
            }
            if (b.id > a.id) {
                return -1;
            }
            return 0;
        });
    }
    let sortedResourcesOnPreviousArray = [];
    //create new resource array here. Resources are matched by id, if id cannot be found in previous revision
    //it means that this is a new resource and undefined is pushed to array to keep resources order
    previousRevision = {...previousRevision};
    currentRevision = {...currentRevision};
    currentRevision.resource = {...currentRevision.resource};
    let comparisonProperty = "id";
    currentRevision.resources.forEach((resource) => {
        const oldRes = previousRevision.resources.find((old) => {
            return old[comparisonProperty] === resource[comparisonProperty];
        });
        if (!oldRes) {
            resource.added = true;
        }
        sortedResourcesOnPreviousArray.push(oldRes);
    });
    const deletedResources = [];
    previousRevision.resources.forEach((resource) => {
        const isPresent = currentRevision.resources.find((curr) => {
            return curr.id === resource.id
        })
        if (!isPresent) {
            deletedResources.push({...resource, deleted: true})
        }
    })
    
    currentRevision.resources = [...currentRevision.resources];
    previousRevision.resources = sortedResourcesOnPreviousArray;


    differences.tags = ""
    let tagsChanged = false;
    if (currentRevision.tags) {
        currentRevision.tags.forEach((tag) => {
            if (!previousRevision.tags || !previousRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        })
    }
    if (previousRevision.tags) {
        previousRevision.tags.forEach((tag) => {
            if (!currentRevision.tags || !currentRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        }) 
    }
    if (tagsChanged) {
        differences.tags = previousRevision.tags ? previousRevision.tags.map(tag => tag.label).join(', ') : "Default"
    }



    if (currentRevision.domain.label !== previousRevision.domain.label) {
        differences.domain = previousRevision.domain.label
    }
    if (currentRevision.subDomain.label !== previousRevision.subDomain.label) {
        differences.subDomain = previousRevision.subDomain.label;
    }
    if (currentRevision.budgetYear.label !== previousRevision.budgetYear.label) {
        differences.budgetYear = previousRevision.budgetYear.label;
    }
    if (currentRevision.costCenter.label !== previousRevision.costCenter.label) {
        differences.costCenter = previousRevision.costCenter.label;
    }
    currentRevision = {...currentRevision};
    currentRevision.resources = currentRevision.resources || [];
    previousRevision = {...previousRevision};
    previousRevision.resources = previousRevision.resources || [];
    differences.resources = [];
    differences.resourcesMap = {deleted: deletedResources};
    currentRevision.resources.forEach((resource, index) => {
        const resourceDiff = {id: resource.id, added: resource.added};
        differences.resources.push(resourceDiff);
        if (!resource.deleted) {
            differences.resourcesMap[resource.id] = resourceDiff;
        }
        if (!previousRevision.resources || !previousRevision.resources[index]) {
            return;
        }
        const previousRevisionResource = previousRevision.resources[index];
        
        if (resource.costCenter.label !== previousRevisionResource.costCenter.label) {
            resourceDiff.costCenter = previousRevisionResource.costCenter.label;
        }
        if (resource.activity.label !== previousRevisionResource.activity.label) {
            resourceDiff.activity = previousRevisionResource.activity.label;
        }
        if (resource.costType.label !== previousRevisionResource.costType.label) {
            resourceDiff.costType = previousRevisionResource.costType.label;
        }
        if (resource.wbs.label !== previousRevisionResource.wbs.label) {
            resourceDiff.wbs = previousRevisionResource.wbs.label;
        }
        if (resource.expenseType.label !== previousRevisionResource.expenseType.label) {
            resourceDiff.expenseType = previousRevisionResource.expenseType.label;
        }
        if (resource.currency.label !== previousRevisionResource.currency.label) {
            resourceDiff.currency = previousRevisionResource.currency.label;
        }
        resourceDiff.plans = {};
        for (let i = 1; i <= 12; i++) {
            const current = resource.plans[i];
            const previous = previousRevisionResource.plans[i];
            if ((!current && previous) 
            || (current && !previous)
            || (current && previous && current.value !== previous.value)) {
                resourceDiff.plans[i] = previous !== undefined ? previous : "0";
            }
        }
        resourceDiff.actuals = {};
        for (let i = 1; i <= 12; i++) {
            const current = resource.actuals[i];
            const previous = previousRevisionResource.actuals[i];
            if ((!current && previous) 
            || (current && !previous)
            || (current && previous && current.value !== previous.value)) {
                resourceDiff.actuals[i] = previous !== undefined ? previous : "0";
            }
        }
        resourceDiff.tags = ""
        let tagsChanged = false;
        if (resource.tags) {
            resource.tags.forEach((tag) => {
                if (!previousRevisionResource.tags || !previousRevisionResource.tags.find((prevtag) => {
                    return tag.label === prevtag.label;
                })) {
                    tagsChanged = true;
                }
            })
        }
        if (previousRevisionResource.tags) {
            previousRevisionResource.tags.forEach((tag) => {
                if (!resource.tags || !resource.tags.find((prevtag) => {
                    return tag.label === prevtag.label;
                })) {
                    tagsChanged = true;
                }
            }) 
        }
        if (tagsChanged) {
            resourceDiff.tags = previousRevisionResource.tags ? previousRevisionResource.tags.map(tag => tag.label).join(', ') : "Default"
        }
    });
    return {differences, currentRevision};
}
export default ({history, index}) => {
    const classes = useStyles();
    const {differences, currentRevision} = getChanges(history[index], history[index+1]);
    return (
        <React.Fragment>
            <div className={classes.container}>
                <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                        <DeliveryInformation changes={differences} viewMode {...currentRevision} />
                    </Grid>
                    <Grid item xs={12}>
                        <ResourceList viewMode changes={differences} {...currentRevision} budgetYear={currentRevision.budgetYear ? currentRevision.budgetYear.label : 0}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Comments viewMode comment={currentRevision.comment && currentRevision.comment.message ? currentRevision.comment.message : ""} comments={[]} />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}