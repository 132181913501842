import * as actionTypes from './actionTypes';

export const getAllDomainsStarted =  (myParam) => {
    return {type: actionTypes.GET_ALL_DOMAINS_STARTED, payload: myParam};
}
export const getAllDomainsFailed =  (myParam) => {
    return {type: actionTypes.GET_ALL_DOMAINS_FAILED, payload: myParam};
}
export const getAllDomainsFinished =  (myParam) => {
    return {type: actionTypes.GET_ALL_DOMAINS_FINISHED, payload: myParam};
}

export const getDomainsStarted =  (myParam, userOnly) => {
    return {type: actionTypes.GET_DOMAINS_STARTED, payload: myParam, userOnly: userOnly};
}
export const getDomainsFailed =  (myParam) => {
    return {type: actionTypes.GET_DOMAINS_FAILED, payload: myParam};
}
export const getDomainsFinished =  (myParam) => {
    return {type: actionTypes.GET_DOMAINS_FINISHED, payload: myParam};
}
export const addDomainStarted =  (myParam) => {
    return {type: actionTypes.ADD_DOMAIN_STARTED, payload: myParam};
}
export const addDomainFailed =  (myParam) => {
    return {type: actionTypes.ADD_DOMAIN_FAILED, payload: myParam};
}
export const addDomainFinished =  (myParam) => {
    return {type: actionTypes.ADD_DOMAIN_FINISHED, payload: myParam};
}
export const editDomainStarted =  (myParam) => {
    return {type: actionTypes.EDIT_DOMAIN_STARTED, payload: myParam};
}
export const editDomainFailed =  (myParam) => {
    return {type: actionTypes.EDIT_DOMAIN_FAILED, payload: myParam};
}
export const editDomainFinished =  (myParam) => {
    return {type: actionTypes.EDIT_DOMAIN_FINISHED, payload: myParam};
}
export const domainPicklistNewItemDomainChanged =  (myParam) => {
    return {type: actionTypes.DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED, payload: myParam};
}
export const domainPicklistNewItemPlannerChanged =  (myParam) => {
    return {type: actionTypes.DOMAIN_PICKLIST_NEW_ITEM_PLANNER_CHANGED, payload: myParam};
}
export const domainPicklistEditItemDomainChanged =  (myParam) => {
    return {type: actionTypes.DOMAIN_PICKLIST_EDIT_ITEM_DOMAIN_CHANGED, payload: myParam};
}
export const domainPicklistEditItemPlannerChanged =  (myParam) => {
    return {type: actionTypes.DOMAIN_PICKLIST_EDIT_ITEM_PLANNER_CHANGED, payload: myParam};
}
export const domainsLoadingChanged = (myParam) => {
    return {type: actionTypes.DOMAINS_LOADING_CHANGED, payload: myParam};
}
