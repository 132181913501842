import * as actionTypes from './actionTypes';

export const getCostCentersStarted =  (myParam) => {
    return {type: actionTypes.GET_COST_CENTERS_STARTED, payload: myParam};
}
export const getCostCentersFinished =  (myParam) => {
    return {type: actionTypes.GET_COST_CENTERS_FINISHED, payload: myParam};
}
export const getCostCentersFailed =  (myParam) => {
    return {type: actionTypes.GET_COST_CENTERS_FAILED, payload: myParam};
}

export const getAllCostCentersStarted =  (myParam) => {
    return {type: actionTypes.GET_ALL_COST_CENTERS_STARTED, payload: myParam};
}
export const getAllCostCentersFinished =  (myParam) => {
    return {type: actionTypes.GET_ALL_COST_CENTERS_FINISHED, payload: myParam};
}
export const getAllCostCentersFailed =  (myParam) => {
    return {type: actionTypes.GET_ALL_COST_CENTERS_FAILED, payload: myParam};
}

export const editCostCenterActionPressed =  (myParam) => {
    return {type: actionTypes.EDIT_COST_CENTER_ACTION_PRESSED, payload: myParam};
}
export const editCostCenterProperty =  (myParam) => {
    return {type: actionTypes.EDIT_COST_CENTER_PROPERTY, payload: myParam};
}
export const editNewCostCenterProperty =  (myParam) => {
    return {type: actionTypes.EDIT_NEW_COST_CENTER_PROPERTY, payload: myParam};
}
export const addCostCenterStarted =  (myParam) => {
    return {type: actionTypes.ADD_COST_CENTER_STARTED, payload: myParam};
}
export const addCostCenterFailed =  (myParam) => {
    return {type: actionTypes.ADD_COST_CENTER_FAILED, payload: myParam};
}
export const addCostCenterFinished =  (myParam) => {
    return {type: actionTypes.ADD_COST_CENTER_FINISHED, payload: myParam};
}
export const editCostCenterStarted =  (myParam) => {
    return {type: actionTypes.EDIT_COST_CENTER_STARTED, payload: myParam};
}
export const editCostCenterFailed =  (myParam) => {
    return {type: actionTypes.EDIT_COST_CENTER_FAILED, payload: myParam};
}
export const editCostCenterFinished =  (myParam) => {
    return {type: actionTypes.EDIT_COST_CENTER_FINISHED, payload: myParam};
}
