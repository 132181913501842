import React, { Component } from 'react';
import { connect } from 'react-redux';
import Roles from './Roles';
import { getRmChanges } from '../../../store/reducers/ResourceModelFormReducer';

const mapStateToProps = (state) => {
    return {
        supplier: state.resourceModelForm.resourceModel.supplier ? state.resourceModelForm.resourceModel.supplier : '',
        supplierRepresentative: state.resourceModelForm.resourceModel.supplierRepresentative ? state.resourceModelForm.resourceModel.supplierRepresentative : '',
        approver: state.resourceModelForm.resourceModel.approver ? state.resourceModelForm.resourceModel.approver : '',
        planner: state.resourceModelForm.resourceModel.planner || '',
        changes: getRmChanges(state)
    }
}
class RolesContainer extends Component {
    render() {
        return (
            <Roles {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, null)(RolesContainer);