import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';



export default function RadioButtonsGroup(props) {

    function handleChange(event) {
       
    props.onChange({
      label: event.target.value,
      value: event.target.value
    });
  }
  let value;
  if (props.value.value === undefined) {
    value = ""
  } else if (typeof props.value.value === "boolean") {
    value = new Boolean(props.value.value).toString();
  } else {
    value = props.value.value;
  }
  let classes = "";
  if (props.className) {
    classes = props.className;
  }
  if (props.header) {
    classes = classes + " radio-group-with-header"
  } else {
    classes = classes + " radio-group"
  }
  let radio = (
      <FormControl component="fieldset" className={props.errorText ? "error" : ""}>
        <FormLabel component="legend">{props.header}
        {props.helpProperty !== undefined ?
              <span style={{position: 'absolute', cursor:'pointer', paddingLeft: 4}} className="tooltip">
                <Tooltip interactive title={<span>If you are not able to find your {props.helpProperty} please contact <a href="mailto:ISDBackOffice.PMI@pmi.com" style={{color: 'inherit'}}>backoffice team</a></span>}>
                    <HelpOutlineIcon className="help"/>
                </Tooltip> 
              </span> : null
            }
        </FormLabel>
        <RadioGroup
            className={classes}
            name={props.name}
            value={value}
            onChange={handleChange}
            style={{flexDirection:props.horizontal ? 'row':'column'}}
        >
            {props.options.map((option) => {
              let value = option.value;
                if (typeof option.value === "boolean") {
                  value = new Boolean(option.value).toString();
                }
                return (
                    <FormControlLabel value={value} control={<Radio color="primary"/>} label={option.label} key={option.value} disabled={props.disabled}/>
                )
            })}
        </RadioGroup>
        <div style={{position: 'absolute', 'bottom': 0, left: 12, color: 'red', fontSize:12}} className={props.errorText ? "error" : ""}>{props.errorText}</div>
      </FormControl>
  );
  if (props.tooltipText) {
    radio = (
      <Tooltip interactive title={props.tooltipText} placement="top">
        {radio}
      </Tooltip>
    )
  }
  return radio;
}