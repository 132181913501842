import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import Input from '../../../components/Input/Input';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/domains';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
const mapStateToProps = (state) => {
    return {
        domains: state.domains.allDomains || [],
        loading: state.domains.loading,
        newItemDomainText: state.domains.newItemDomainText || "",
        newItemPlanner: state.domains.newItemPlanner,
        namesBeingEditedMap: state.domains.namesBeingEditedMap || {},
        plannersBeingEditedMap: state.domains.plannersBeingEditedMap || {},
        selectedItems: state.domains.selectedItems
    }
};
  
  const mapDispatchToProps = (dispatch) => ({
      addDomain: (payload) => 
        dispatch(actionCreators.addDomainStarted(payload)),
      editDomain: (payload) => 
          dispatch(actionCreators.editDomainStarted(payload)),
      newItemDomainChanged: (event) => {
        dispatch(actionCreators.domainPicklistNewItemDomainChanged(event))
      },
      newItemPlannerChanged: (event) => {
        dispatch(actionCreators.domainPicklistNewItemPlannerChanged(event))
      },
      editItemDomainChanged: (event) =>
        dispatch(actionCreators.domainPicklistEditItemDomainChanged(event)),
      editItemPlannerChanged: (event) =>
        dispatch(actionCreators.domainPicklistEditItemPlannerChanged(event))
  });

class DomainPickList extends Component {

    state = {
        filtered: {
        }
    }
    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (row[key].toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                }
            })
            return isValid;
        })
    }
    addItem = (event) => {
        if (event.target.value.length === 0) {
            return;
        }
        this.props.addDomain({name: this.props.newItemDomainText, planner: event.target.value});
    }

    editItem = (event, prop) => {
        const targetDomain = this.props.domains.find(domain => domain.id === parseInt(event.target.name));
        if (targetDomain[prop] !== event.target.value) {
            const editedPoLocation = {
                ...targetDomain,
                [prop]: event.target.value
            }
            this.props.editDomain(editedPoLocation);
        }
    }
    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.addItem(event);
        }
    }
    handleEnterOnEditInput = (event, prop) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.editItem(event, prop);
        }
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.domains.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.editDomain(copy)
    }

    render() {
        const filteredData = this.filterData(this.props.domains);
        return (
            <div>
                <DataExportContainer url="/api/export/domains"/>
                <DataTable
                loading={this.props.loading}
                enabledAddition
                filtered={this.state.filtered}
                general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                onSelectedChanged={this.selectedItemsChanged}
                selected={this.props.selectedItems}

                onFilteredChanged={this.onFilteredChanged}
                data={filteredData}  
                addNewItemRenderer= { (dataProperty) => {
                    if (dataProperty === 'name') {
                        return (
                            <Input 
                                width={292} 
                                inputProps={{name: dataProperty}}
                                value={this.props.newItemDomainText}
                                onChange={this.props.newItemDomainChanged}
                            />
                        );
                    }
                    return <Input
                                width={292} 
                                placeholder="Add new..."
                                inputProps={{name: dataProperty}}
                                value={this.props.newItemPlanner}
                                onChange={this.props.newItemPlannerChanged}
                                onBlur={this.addItem}
                                disabled={!this.props.newItemDomainText}
                                onKeyPress={this.handleEnterOnAddInput}
                            />
                    }
                }
                customRowCells={{
                    name: (data, id, row) => {
                        return (
                        <Input width={292} 
                            onBlur={(e)=>{this.editItem(e, "name")}}
                            inputProps={{name: id}}
                            value={this.props.namesBeingEditedMap[id] ? this.props.namesBeingEditedMap[id]: data}
                            onChange={this.props.editItemDomainChanged}
                            onKeyPress={(e)=>{this.handleEnterOnEditInput(e, "name")}}
                        />
                        )
                    },
                    planner: (data, id, row) => {
                        return (
                            <Input 
                                onBlur={(e)=>{this.editItem(e, "planner")}}
                                width={292} 
                                inputProps={{name: id}}
                                value={this.props.plannersBeingEditedMap[id] ? this.props.plannersBeingEditedMap[id]: data}
                                onChange={this.props.editItemPlannerChanged}
                                onKeyPress={(e) => {this.handleEnterOnEditInput(e, "planner")}}
                            />
                        )
                    },
                }}
                columns={[
                    {
                        header:"Platform",
                        dataProperty:"name",
                        isVisible: true
                    },
                    {
                        header:"Planner",
                        dataProperty:"planner",
                        isVisible: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainPickList);