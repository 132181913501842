import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import history from '../../../history';
import * as regionalChangelogFormActions from '../../../store/actions/regionalChangelogActions';

const mapStateToProps = (state) => {
    return {
        submitting: state.regionalChangelogForm.submitting,
        option: state.regionalChangelogs.tabs.find((option) => {
            return option.value === state.regionalChangelogForm.planByCostCenter
        })
    }
};

const mapDispatchToProps = (dispatch) => ({
    submitFormStarted: () => 
        dispatch(regionalChangelogFormActions.regionalChangelogFormSubmitStarted()),
    resetForm: () => 
        dispatch(regionalChangelogFormActions.regionalChangelogFormResetForm()),
    regionalChangelogsTabChanged: (payload) =>
        dispatch(regionalChangelogFormActions.regionalChangelogsTabChanged(payload))
    }
);

class FormSubmitPanelContainer extends Component {
    cancelForm = () => {
        this.props.resetForm();
        this.props.regionalChangelogsTabChanged(this.props.option);
        if (history.location.pathname.indexOf('/budgetPlans/viewAll') !== -1) {
            history.push('/budgetPlans/viewAll');
        } else {
            history.push('/budgetPlans/my');
        }
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 100}}>
                <Fab variant="extended" className="form-submit-panel form-cancel" onClick={this.cancelForm}>Cancel</Fab>
                <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-submit" onClick={this.props.submitFormStarted}>Submit</Fab>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitPanelContainer);