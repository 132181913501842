import React from 'react';
import AutoSelect from '../../../../components/Input/AutoSelect';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Input from '../../../../components/Input/Input'
import formSectionWrapper from './FormSectionWrapper';
import * as rules from '../../../../config/Rules';
import {canEdit, isDisabled} from '../../../../utils/changelogFormEditPrivileges';

const useStyles = makeStyles({
    twoColumnWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap:'wrap'
    },
    tooltipWrapper: {
        '&:hover': {
        '& .tooltip': {
            zIndex:1
        }
    }
    }
});


const DeliveryInformation = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {canEdit(props,rules.EDIT_CHANGELOG_DOMAIN) ?
            <AutoSelect 
                errorText={props.errors.domain} 
                label="Platform"
                width="100%"
                className={props.changes.domain ? "input-updated": ""}
                tooltipText={props.changes.domain}
                suggestions={props.domains}
                value={props.domain}
                handleChangeSingle={props.onDomainChanged}
            /> :
            <Input 
                label="Platform"
                value={props.domain.label}
                className={props.changes.domain ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.domain}
                disabled
            />
            }
            {canEdit(props,rules.EDIT_CHANGELOG_SUB_DOMAIN) ?
            <AutoSelect 
                errorText={props.errors.subDomain} 
                label="Sub-platform"
                width="100%"
                className={props.changes.subDomain ? "input-updated": ""}
                tooltipText={props.changes.subDomain}
                suggestions={props.subDomains}
                value={props.subDomain}
                handleChangeSingle={props.onSubDomainChanged}
            /> :
            <Input 
                label="Sub-platform"
                value={props.subDomain.label}
                disabled
                className={props.changes.subDomain ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.subDomain}
            />
            }
            <RadioButtons 
                header="Activity Type"
                horizontal={true}
                onChange={props.onActivityTypeChanged}
                options={props.activityTypes}
                value={props.activityType}
                disabled={isDisabled(props, rules.EDIT_CHANGELOG_ACTIVITY_TYPE)}
                className={props.changes.activityType ? "input-updated": ""}
                tooltipText={props.changes.activityType}
            />
            {canEdit(props, rules.EDIT_CHANGELOG_ACTIVITY_NAME) ?
            <AutoSelect 
                label="Activity name"
                errorText={props.errors.activity} 
                width="100%"
                suggestions={props.activityNames}
                value={props.activityName}
                handleChangeSingle={props.onActivityNameChanged}
                helpProperty="activity"
                className={props.changes.activity ? "input-updated": ""}
                tooltipText={props.changes.activity}
            /> :
            <Input 
                label="Activity name"
                value={props.activityName.label}
                disabled
                className={props.changes.activity ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.activity}
            />
            }
            <RadioButtons 
                header="Vendor Type"
                horizontal={true}
                helpProperty="vendor type"
                onChange={props.onVendorTypeChanged}
                options={props.vendorTypes}
                value={props.vendorType}
                disabled={isDisabled(props, rules.EDIT_CHANGELOG_VENDOR_TYPE)}
                className={props.changes.vendorType ? "input-updated": ""}
                tooltipText={props.changes.vendorType}
            />
            {canEdit(props, rules.EDIT_CHANGELOG_VENDOR) ?
            <AutoSelect
                label="Supplier/Team"
                width="100%"
                suggestions={props.vendors}
                value={props.vendor}
                handleChangeSingle={props.onVendorChanged}
                errorText={props.errors.vendor} 
                helpProperty="supplier"
                className={props.changes.vendor ? "input-updated": ""}
                tooltipText={props.changes.vendor}
            /> :
            <Input 
                label="Supplier/Team"
                value={props.vendor.label}
                disabled
                className={props.changes.vendor ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.vendor}
            />
            }
            {canEdit(props, rules.EDIT_CHANGELOG_PO_WBS) ?
            <AutoSelect
                label="PO Location"
                width="100%"
                suggestions={props.poLocations}
                value={props.poLocation}
                handleChangeSingle={props.poLocationChanged}
                helpProperty="po location"
                className={props.changes.poLocation ? "input-updated": ""}
                tooltipText={props.changes.poLocation}
            /> :
            <Input 
                label="PO Location"
                value={props.poLocation.label || ""}
                disabled
                className={props.changes.poLocation ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.poLocation}
            />
            }

            {canEdit(props, rules.EDIT_CHANGELOG_PO_WBS) ?
            <AutoSelect
                label="Cost Type"
                width="100%"
                suggestions={props.costTypes}
                value={props.costType}
                handleChangeSingle={props.costTypeChanged}
                className={props.changes.costType ? "input-updated": ""}
                tooltipText={props.changes.costType}
            /> :
            <Input 
                label="Cost Type"
                value={props.costType.label || ''}
                disabled
                className={props.changes.costType ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.costType}
            />
            }
            {canEdit(props, rules.EDIT_CHANGELOG_PO_WBS) ?
            <AutoSelect
                label="PO WBS"
                width="100%"
                suggestions={props.poWbses}
                value={props.poWbs}
                handleChangeSingle={props.onWbsChanged}
                errorText={props.errors.poWbs} 
                helpProperty="po wbs"
                className={props.changes.wbs ? "input-updated": ""}
                tooltipText={props.changes.wbs}
            /> :
            <Input 
                label="PO WBS"
                value={props.poWbs.label}
                disabled
                className={props.changes.wbs ? "input-updated": "disabled-form-input"}
                tooltipText={props.changes.wbs}
            />
            }
            <div className={classes.twoColumnWrapper}>
                {canEdit(props, rules.EDIT_CHANGELOG_YEAR) ?
                <AutoSelect
                    label="Activity Year"
                    width="45%"
                    handleChangeSingle={props.onYearChanged}
                    value={props.year}
                    suggestions={props.years}
                    errorText={props.errors.year}
                    className={props.changes.year ? "input-updated": ""}
                    tooltipText={props.changes.year}
                /> :
                <Input 
                    label="Year"
                    value={props.year.label}
                    disabled
                    width="45%"
                    className={props.changes.year ? "input-updated": "disabled-form-input"}
                    tooltipText={props.changes.year}
                />
                }
                {canEdit(props, rules.EDIT_CHANGELOG_RATE_YEAR) ?
                <AutoSelect
                    label="Rate Year"
                    width="45%"
                    handleChangeSingle={props.onRateYearChanged}
                    value={props.rateYear}
                    suggestions={props.rateYears}
                    helpProperty="rate year"
                    className={props.changes.rateYear ? "input-updated": ""}
                    tooltipText={props.changes.rateYear}
                /> : 
                <Input 
                    label="Rate Year"
                    value={props.rateYear.label}
                    disabled
                    width="45%"
                    className={props.changes.rateYear ? "input-updated": "disabled-form-input"}
                    tooltipText={props.changes.rateYear}
                />
                }
            </div>
            </React.Fragment>
    )
}
export default formSectionWrapper(DeliveryInformation, "Delivery Information");