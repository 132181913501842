import * as actionTypes from './actionTypes';

export const getImportMasterDataListStarted =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_LIST_STARTED, payload: myParam};
};
export const getImportMasterDataListFinished =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_LIST_FINISHED, payload: myParam};
};
export const getImportMasterDataListFailed =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_LIST_FAILED, payload: myParam};
};

export const updateImportMasterDataColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_STARTED, payload: myParam};
};
export const updateImportMasterDataColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_FAILED, payload: myParam};
};
export const updateImportMasterDataColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_FINISHED, payload: myParam};
};

export const getImportMasterDataColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_STARTED, payload: myParam};
};
export const getImportMasterDataColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_FAILED, payload: myParam};
};
export const getImportMasterDataColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_FINISHED, payload: myParam};
};
export const importMasterDataFileUploadStarted = (myParam) => {
    return {type: actionTypes.IMPORT_MASTERDATA_FILE_UPLOAD_STARTED, payload: myParam};
}
export const importMasterDataFileUploadFailed = (myParam) => {
    return {type: actionTypes.IMPORT_MASTERDATA_FILE_UPLOAD_FAILED, payload: myParam};
}
export const importMasterDataFileUploadFinished = (myParam) => {
    return {type: actionTypes.IMPORT_MASTERDATA_FILE_UPLOAD_FINISHED, payload: myParam};
}
export const importMasterDataLoadingSpinnerChanged = (myParam) => {
    return {type: actionTypes.IMPORT_MASTERDATA_LOADING_SPINNER_CHANGED, payload: myParam};
}
export const importMasterDataFiltersChanged = (myParam) => {
    return {type: actionTypes.IMPORT_MASTERDATA_FILTERS_CHANGED, payload: myParam};
}

