import * as actionTypes from '../actions/actionTypes';

const initialState = {
    statusComment: "",
    drawerVisible: true,
    changelogTabSelected: "history",
}
const navbarReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CHANGELOG_TAB_SELECTION_CHANGED: {
            return {
                ...state,
                changelogTabSelected: action.payload
            }
        }
        case actionTypes.SHOW_DRAWER: {
            return {
                ...state,
                drawerVisible: true
            }
        }
        case actionTypes.HIDE_DRAWER: {
            return {
                ...state,
                drawerVisible: false
            }
        }
        case actionTypes.TOGGLE_DRAWER: {
            return {
                ...state,
                drawerVisible: !state.drawerVisible
            }
        }
        case actionTypes.SET_NAVBAR_STATUS_COMMENT: {
            return {
                ...state,
                statusComment: action.payload
            }
        }
        default: {
            return state;
        }
    }
}
export default navbarReducer;