import React, {Component} from 'react';
import Dialog from '../../components/Dialog/Dialog';
import { withRouter } from "react-router";
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as errorActions from '../../store/actions/errorDialogActions';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    errorDialogContent: {
        display: 'flex',
        alignItems: 'center',
        color: '#D8000C'
    }
});

const mapStateToProps = (state) => {
    return {
        isDialogVisible: state.error.isDialogVisible,
        message: state.error.errorMessage,
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    onClose: () =>
        dispatch(errorActions.closeErrorDialog()),   
});

class AppBarContainer extends Component {
    render() {
        return (
            <Dialog 
                open={this.props.isDialogVisible}
                onClose={this.props.onClose}
                cancelButtonText="OK"
                title="ERROR"
            >
                <div className={this.props.classes.errorDialogContent}>
                    <ErrorOutline />
                    <span>{this.props.message}</span>
                </div>
            </Dialog>
        )
    }
}
export default compose(withRouter, withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AppBarContainer)
