import * as actionTypes from '../actions/actionTypes';
import {getEmptyComment} from '../../utils/commentUtils';

const initialState = {
    columns: [],
    deliveryConfirmations: {},
    loading: false,
    rejectModalOpen: false,
    commentModalOpen: false,
    quickEditModalOpen: false,
    rejectReason: getEmptyComment(),
    comment: getEmptyComment(),
    selectedDc: '',
    filters: null,
    restartModalOpen: false
}

const changelogReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.DELIVERY_CONFIRMATION_RESTART_MODAL_CHANGED: {
            return {
                ...state,
                restartModalOpen: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_VIEWER_FILTERS_CHANGED: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_LIST_CHANGE_LOADING_SPINNER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_REJECT_REASON_CHANGED: {
            return {
                ...state,
                rejectReason: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_COMMENT_TEXT_CHANGED: {
            return {
                ...state,
                comment: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_SELECTED_DC_CHANGED: {
            return {
                ...state,
                selectedDc: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_REJECT_DC_MODAL_CHANGED: {
            return {
                ...state,
                rejectModalOpen: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_COMMENT_DC_MODAL_CHANGED: {
            return {
                ...state,
                commentModalOpen: action.payload
            }
        }
        case actionTypes.DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_CHANGED: {
            return {
                ...state,
                quickEditModalOpen: action.payload
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATIONS_FAILED: {
            return {
                ...state,
                deliveryConfirmations: {},
                loading: false
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATIONS_FINISHED: {
            return {
                ...state,
                deliveryConfirmations: action.payload,
                loading: false
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_STARTED: {
            return {
                ...state,
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_FAILED: {
            return {
                ...state,
                columns: [],
            }
        }
        case actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        case actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_STARTED: {
            return {
                ...state,
            }
        }
        case actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FAILED: {
            return {
                ...state,
            }
        }
        case actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FINISHED: {
            return {
                ...state,
            }
        }
        default: {
            return state
        }
    }
}
export default changelogReducer;