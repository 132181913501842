import React, { Component } from 'react';
import StaticPickList from './components/StaticPickList';
import Input from '../../../components/Input/Input';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/OneColumnPickList';

const mapStateToProps = (state, ownProps) => {
    const myState = state[ownProps.namespace];
    return {
        loading: myState.loading,
        newItemText: myState.newItemText,
        itemsBeingEdited: myState.itemsBeingEdited,
        items: myState.allItems,
        selectedItems: myState.selectedItems || {}
    }};
  
  const mapDispatchToProps = (dispatch, ownProps) => ({
    handleNewItemTextChanged: (e) =>
        dispatch(actionCreators.newItemTextChanged(ownProps.namespace, e)),
    handleAddItem: (e) => {
        if (e.target.value.length !== 0) {
            dispatch(actionCreators.addPicklistItemStarted(ownProps.namespace, {name: e.target.value}))
        }
    }, 
    handleEditText: (e) => {
        dispatch(actionCreators.editItemTextChanged(ownProps.namespace, e))
    },
    handleEditItem: (e, item) => {
        //tutaj sprawdz czy sie zmienilo
        dispatch(actionCreators.editPicklistItemStarted(ownProps.namespace, {id:e.target.name, name: e.target.value, isDeleted: item.isDeleted, clientId: item.clientId}))
    },
    handleEditIsDeleted: (object) => {
        dispatch(actionCreators.editPicklistItemStarted(ownProps.namespace, object))
    }
  });
  
class OneColumPickList extends Component {

    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.props.handleAddItem(event);
        }
    }
    handleEditItem = (event) => {
        if (this.checkIfShouldEditField(event)) {
            const item = this.props.items.find((item) => {
                return item.id === parseInt(event.target.name)
            })
            this.props.handleEditItem(event, item);
        }
    }
    handleEnterOnEditInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0 && this.checkIfShouldEditField(event)) {
            this.props.handleEditItem(event);
        }
    }
    checkIfShouldEditField = (event) => {
        const itemToBeEdited = this.props.items.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        if (itemToBeEdited === undefined) {
            return false;
        }
        return itemToBeEdited.name !== event.target.value;
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.items.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.handleEditIsDeleted(copy)
    }
    
    render() {
        return (
        <StaticPickList
        namespace={this.props.namespace}
        header={this.props.header}
        loading={this.props.loading}
        enabledAddition
        general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
        onSelectedChanged={this.selectedItemsChanged}
        selected={this.props.selectedItems}
        addNewItemRenderer= { (dataProperty) => {
            return <Input 
                width={292} 
                placeholder="Add new..."
                onBlur={this.props.handleAddItem}
                inputProps={{name: dataProperty}}
                value={this.props.newItemText}
                onChange={this.props.handleNewItemTextChanged}
                onKeyPress={this.handleEnterOnAddInput}
            />
            }
        }
        data={this.props.items}
        customRowCells={{
            "name": (data, key) => {
                return (
                <Input width={292} 
                    onBlur={this.handleEditItem}
                    onKeyPress={this.handleEnterOnEditInput}
                    inputProps={{name: key}}
                    value={this.props.itemsBeingEdited[key] !== undefined && this.props.itemsBeingEdited[key] !== null ? this.props.itemsBeingEdited[key]: data}
                    onChange={this.props.handleEditText}/>
                )
            },
        }}
        columns={[
            {
                header: this.props.header,
                dataProperty:"name",
                isVisible: true
            }
            ]}
            
    />
    )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneColumPickList);
