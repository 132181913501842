import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    sectionWrapper: {
        '& > *': {
            paddingTop: 8,
            paddingBottom: 8,
        }
    },
    header: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '28px',
        color: '#262626'
    }
})

export default (Component, header) => {
    return (props) => {
        const classes = useStyles();
        return (
            <div className={classes.sectionWrapper}>
                <h3 className={classes.header}>{header}</h3>
                    <Component {...props} />
            </div>
        )
};
  };