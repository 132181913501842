import React, { Component } from 'react';
import DeliveryInformation from '../components/DeliveryInformation';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import activityTypes from '../../../../constants/activityTypes';
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import { shouldComponentUpdate } from '../../../../store/reducers/ChangeLogFormReducer';
import * as selectors from '../../../../store/reducers/ChangeLogFormReducer';
import { getSubDomainsParsed } from '../../../../store/reducers/subDomain'
import { getDomainsParsed } from '../../../../store/reducers/DomainsReducer'

const activityTypesParsed = [{label:activityTypes[0], value:0}, {label:activityTypes[1], value: 1}]
const mapStateToProps = (state) => {
    return {
        vendorBaseName: state.changelogForm.vendorBaseName,
        resourceModelGenerated: state.changelogForm.resourceModelGenerated,
        isDraft: state.changelogForm.isDraft,
        errors: state.changelogForm.errors,
        domains: getDomainsParsed(state),
        domain: state.changelogForm.domain,
        subDomains: getSubDomainsParsed(state),
        subDomain: state.changelogForm.subDomain,
        activityTypes: activityTypesParsed,
        activityType: state.changelogForm.activityType || '',
        activityNames: selectors.getActivitiesParsed(state),
        activityName: state.changelogForm.activity,
        vendorTypes: selectors.getUniqueVendorTypes(state),
        vendorType: state.changelogForm.vendorType,
        vendors: selectors.getVendorsParsed(state),
        vendor: state.changelogForm.vendor,
        poWbses: selectors.getWbsesAfterCostType(state),
        poWbs: state.changelogForm.poWbs,
        year: state.changelogForm.year,
        years: selectors.getYears(state),
        rateYears: selectors.getRateYears(state),
        rateYear: state.changelogForm.rateYear,
        role: state.user.selectedRole,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        costTypes: selectors.getCostTypes(state),
        poLocations: selectors.getPoLocations(state),
        poLocation: state.changelogForm.poLocation || "",
        costType: state.changelogForm.costType || "",
        shouldUpdate: shouldComponentUpdate(state.changelogForm),
        editMode: state.changelogForm.editMode,
        changes: selectors.getChangelogChanges(state)
    }
};

const mapDispatchToProps = (dispatch) => ({   
    activityChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormActivityChanged(payload)),
    vendorTypeChanged: (payload) => {
        dispatch(changelogFormActionsCreators.changelogFormVendorTypeChanged(payload))
    },
    vendorChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormVendorChanged(payload)),    
    poWbsChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormPoWbsChanged(payload)),
    yearChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormYearChangedStarted(payload)),
    rateYearChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormRateYearChanged(payload)),  
    domainChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormDomainChanged(payload)),
    subDomainChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormSubDomainChanged(payload)),
    activityTypeChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormActivityTypeChanged(payload)),
    costTypeChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormCostTypeChanged(payload)),
    poLocationChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormPoLocationChanged(payload))       
}
);

class DeliveryInfromationContainer extends Component {
    shouldComponentUpdate() {
        return this.props.shouldUpdate;
    }
    render() {
        return (
            <DeliveryInformation 
                changes={this.props.changes}
                isDraft={this.props.isDraft}
                editMode={this.props.editMode}
                role={this.props.role}
                isChangelogOwner={this.props.isChangelogOwner}
                editing={this.props.editing}
                vendorBaseName={this.props.vendorBaseName}
                resourceModelGenerated={this.props.resourceModelGenerated}

                errors = {this.props.errors}
                onActivityNameChanged={this.props.activityChanged}
                activityName={this.props.activityName}
                activityNames={this.props.activityNames}
                
                onVendorTypeChanged={this.props.vendorTypeChanged}
                vendorType={this.props.vendorType}
                vendorTypes={this.props.vendorTypes}

                onVendorChanged={this.props.vendorChanged}
                vendor={this.props.vendor}
                vendors={this.props.vendors}

                onWbsChanged={this.props.poWbsChanged}
                poWbs={this.props.poWbs}
                poWbses={this.props.poWbses}

                onYearChanged={this.props.yearChanged}
                year={this.props.year}
                years={this.props.years}

                onRateYearChanged={this.props.rateYearChanged}
                rateYear={this.props.rateYear}
                rateYears={this.props.rateYears}
                domains={this.props.domains}
                domain={this.props.domain}
                subDomains={this.props.subDomains}
                subDomain={this.props.subDomain}
                activityTypes={this.props.activityTypes}
                activityType={this.props.activityType}
                onDomainChanged={this.props.domainChanged}
                onSubDomainChanged={this.props.subDomainChanged}
                onActivityTypeChanged={this.props.activityTypeChanged}

                poLocations={this.props.poLocations}
                poLocation={this.props.poLocation}
                poLocationChanged={this.props.poLocationChanged}

                costTypes={this.props.costTypes}
                costType={this.props.costType}
                costTypeChanged={this.props.costTypeChanged}
            />
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfromationContainer);