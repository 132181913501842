import * as actionTypes from '../actions/actionTypes';
import vendorTypesArray from '../../constants/vendorTypes';

const initialState = {
    changelog: {},
    activities: [],
    vendorTypes: vendorTypesArray.map((vendorType, index) => {return index}),
    vendors: [],
    poWbses: [],
    vendorRates: [],
    resourceTypes: [],
    changelogTags: [],
    changelogResourceTags: [],
    subDomains: [],
    loading: false
}
const changelogDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_CHANGELOG_RESOURCE_TAGS_FINISHED: {
            return {
                ...state,
                changelogResourceTags: action.payload
            }
        }
        case actionTypes.GET_CHANGELOG_TAGS_FINISHED: {
            return {
                ...state,
                changelogTags: action.payload
            }
        }
        case actionTypes.GET_SUBDOMAINS_BY_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_SUBDOMAINS_BY_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
                subDomains: []
            }
        }
        case actionTypes.GET_SUBDOMAINS_BY_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
                subDomains: action.payload
            }
        }
        case actionTypes.FORCE_LOADING_DATA_SPINNER: {
            return {
                ...state,
                loading: action.payload === false ? false : true
            }
        }
        case actionTypes.GET_CHANGELOG_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_CHANGELOG_FAILED: {
            return {
                ...state,
                loading: false,
                changelog: {}
            }
        }
        case actionTypes.GET_CHANGELOG_FINISHED: {
            return {
                ...state,
                loading: false,
                changelog: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_REST_FORM: {
            return {
                ...initialState
            }
        }
        case actionTypes.GET_ACTIVITIES_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ACTIVITIES_FAILED: {
            return {
                ...state,
                activities: [],
                loading: false
            }
        }
        case actionTypes.GET_ACTIVITIES_FINISHED: {
            return {
                ...state,
                activities: action.payload,
                loading: false
            }
        }
        case actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_FAILED: {
            return {
                ...state,
                vendorTypes: [],
                loading: false
            }
        }
        case actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_FINISHED: {
            return {
                ...state,
                vendorTypes: action.payload,
                loading: false
            }
        }

        case actionTypes.GET_VENDORS_BY_VENDOR_TYPE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }

        case actionTypes.GET_VENDORS_BY_VENDOR_TYPE_FAILED: {
            return {
                ...state,
                loading: false,
                vendors: []
            }
        }

        case actionTypes.GET_VENDORS_BY_VENDOR_TYPE_FINISHED: {
            return {
                ...state,
                loading: false,
                vendors: action.payload
            }
        }

        case actionTypes.GET_PO_WBSES_BY_ACTIVITY_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_PO_WBSES_BY_ACTIVITY_FAILED: {
            return {
                ...state,
                loading: false,
                poWbses: []
            }
        }
        case actionTypes.GET_PO_WBSES_BY_ACTIVITY_FINISHED: {
            return {
                ...state,
                loading: false,
                poWbses: action.payload
            }
        }

        case actionTypes.GET_VENDOR_RATES_BY_VENDOR_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_VENDOR_RATES_BY_VENDOR_FAILED: {
            return {
                ...state,
                vendorRates: [],
                loading: false
            }
        }
        case actionTypes.GET_VENDOR_RATES_BY_VENDOR_FINISHED: {
            return {
                ...state,
                vendorRates: action.payload,
                loading: false
            }
        }
        case actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FAILED: {
            return {
                ...state,
                loading: false,
                resourceTypes: []
            }
        }
        case actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FINISHED: {
            return {
                ...state,
                loading: false,
                resourceTypes: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_INITIALIZE: {
           return {
                ...state,
                loading: true
            }
        }
        case actionTypes.CHANGELOG_FORM_INITIALIZED: {
            return {
                ...state,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}

export default changelogDataReducer;