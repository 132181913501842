import * as actionTypes from '../actions/actionTypes';

const initialState = {
    items: {},
    loading: false,
    columns: [],
}

const importMasterDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SAP_ACTUALS_LOADING_SPINNER_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.GET_SAP_ACTUALS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_SAP_ACTUALS_FINISHED: {
            return {
                ...state,
                loading: false,
                items: action.payload
            }
        }
        case actionTypes.GET_SAP_ACTUALS_FAILED: {
            return {
                ...state,
                loading: false,
                items: {}
            }
        }
        case actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_STARTED:
        case actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_FAILED:    
        case actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_FINISHED:
        case actionTypes.GET_SAP_ACTUALS_COLUMNS_FAILED:  
        case actionTypes.GET_SAP_ACTUALS_COLUMNS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_SAP_ACTUALS_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default importMasterDataReducer;