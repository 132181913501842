import React from 'react';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={props.maxWidth}
        fullWidth={props.fullWidth || false}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        <DialogActions>
            <Fab variant="extended" className="form-submit-panel form-cancel" onClick={props.onClose}>
                {props.cancelButtonText}
            </Fab>
            {props.submitButtonText ? 
            <Fab onClick={props.onSubmit} variant="extended" className="form-submit-panel form-submit" disabled={props.submitDisabled}>
                {props.submitButtonText}
            </Fab> : null
            }
        </DialogActions>
      </Dialog>
    </div>
  );
}
