import React, {Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as snackbarActions from '../../store/actions/snackbarActions';
import { withStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    errorDialogContent: {
        display: 'flex',
        alignItems: 'center',
        color: '#D8000C'
    }
});

const mapStateToProps = (state) => {
    return {
        isSnackbarVisible: state.snackbar.open,
        message: state.snackbar.message,
        keyProp: state.snackbar.key
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    onClose: () =>
        dispatch(snackbarActions.snackbarChanged({open:false})),   
});

class SnackbarContainer extends Component {
    render() {
        return (
            <Snackbar
              key={this.props.keyProp}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={this.props.onClose}
              open={this.props.isSnackbarVisible}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id" key="message">{this.props.message}</span>}
              action={[
                <IconButton
                  aria-label="close"
                  color="inherit"
                  key="button"
                  // className={classes.close}
                  onClick={this.props.onClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
          />
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SnackbarContainer)
