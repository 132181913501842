import React, { Component } from 'react';
import FormSectionWrapper from '../components/FormSectionWrapper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import AttachmentComponent from '../components/AttachmentComponent'
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import { isDisabledWithRequestor } from '../../../../utils/changelogFormEditPrivileges';
import {getChangelogChanges} from '../../../../store/reducers/ChangeLogFormReducer'

const mapStateToProps = (state) => {
    return {
        isDraft: state.changelogForm.isDraft,
        attachments: state.changelogForm.attachments || [],
        role: state.user.selectedRole,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        editMode: state.changelogForm.editMode,
        changes: getChangelogChanges(state)
        // isChangelogOwner: state.changelogForm.approver && state.changelogForm.approver.value ?
        //     state.changelogForm.approver.value === state.user.username : false,
    }
};
const mapDispatchToProps = (dispatch) => ({
    attachmentAdded: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormAttachmentAdded(payload)),
    attachmentRemoved: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormAttachmentDeleted(payload)),
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))                        
})
class SupportingDocumentationContainer extends Component {
    isDisabled = () => {
        return isDisabledWithRequestor(this.props, rules.EDIT_CHANGELOG_ADD_ATTACHMENT);
    }
    render () {
        return (
            <AttachmentComponent 
                changes={this.props.changes}
                disabled={this.isDisabled()}
                attachments={this.props.attachments}
                downloadAttachment={this.props.downloadAttachment}
                attachmentRemoved={this.props.attachmentRemoved}
                attachmentAdded={this.props.attachmentAdded}
            />
        )
    }
}
export default FormSectionWrapper(compose(connect(mapStateToProps, mapDispatchToProps))(SupportingDocumentationContainer), "Supporting Documentation");