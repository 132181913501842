import * as actionTypes from './actionTypes';

export const getDeliveryConfirmationColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_STARTED, payload: myParam};
};
export const getDeliveryConfirmationColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_FAILED, payload: myParam};
};
export const getDeliveryConfirmationColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_FINISHED, payload: myParam};
};
export const updateDeliveryConfirmationColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_STARTED, payload: myParam};
};
export const updateDeliveryConfirmationColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FAILED, payload: myParam};
};
export const updateDeliveryConfirmationColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_FINISHED, payload: myParam};
};

export const getDeliveryConfirmationsStarted = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATIONS_STARTED, payload: myParam};
}
export const getDeliveryConfirmationsFailed = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATIONS_FAILED, payload: myParam};
}
export const getDeliveryConfirmationsFinished = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATIONS_FINISHED, payload: myParam};
}
export const deliveryConfirmationRestartDc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_RESTART_DC, payload: myParam};
}
export const deliveryConfirmationProcessingNADc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_PROCESSINGNA_DC, payload: myParam};
}
export const deliveryConfirmationApproveDc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_APPROVE_DC, payload: myParam};
}
export const deliveryConfirmationProcessDc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_PROCESS_DC, payload: myParam};
}
export const deliveryConfirmationRejectDc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_REJECT_DC, payload: myParam};
}
export const deliveryConfirmationCommentDc = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_COMMENT_DC, payload: myParam};
}
export const deliveryConfirmationInitializeQuickEdit = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_INITIALIZE_QUICK_EDIT, payload: myParam};
}
export const deliveryConfirmationRejectDcModalChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_REJECT_DC_MODAL_CHANGED, payload: myParam};
}
export const deliveryConfirmationCommentDcModalChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_COMMENT_DC_MODAL_CHANGED, payload: myParam};
}
export const deliveryConfirmationQuickEditModalChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_CHANGED, payload: myParam}
}
export const deliveryConfirmationSelectedDcChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_SELECTED_DC_CHANGED, payload: myParam}
}
export const deliveryConfirmationRejectReasonChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_REJECT_REASON_CHANGED, payload: myParam}
}
export const deliveryConfirmationCommentTextChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_COMMENT_TEXT_CHANGED, payload: myParam}
}
export const deliveryConfirmationQuickEditModalSubmitted = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_SUBMITTED, payload: myParam}
}
export const deliveryConfirmationListChangeLoadingSpinner = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_LIST_CHANGE_LOADING_SPINNER, payload: myParam}
}
export const deliveryConfirmationViewerFiltersChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_VIEWER_FILTERS_CHANGED, payload: myParam}
}

export const deliveryConfirmationGeneratePdf = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_GENERATE_PDF, payload: myParam};
}

export const deliveryConfirmationRestartDcModalChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_RESTART_MODAL_CHANGED, payload: myParam};
    
}