import { put, takeEvery, takeLatest, delay, select, all, call } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as vendorRatesActions from '../store/actions/vendorRates';
import axios from '../axios';
import assignmentSites from '../constants/assignmentSites';
import resourceTypes from '../constants/resourceTypes';
import { encodeParamObject } from '../utils/queryParamsFormatter';

function* getVendorRatesColumnsConfig(action) {
    let columns = yield localStorage.getItem("vendor-rates-column-config" +process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(vendorRatesActions.getVendorRatesColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(vendorRatesActions.getVendorRatesColumnsFailed())
    }
}
function* getPicklists(action) {
    try {
        yield call(getVendorRates, action)
        yield put(vendorRatesActions.intializeVendorRatesViewerFinished())
    } catch(error) {
        yield put(vendorRatesActions.intializeVendorRatesViewerFailed())
    }
}

function* saveColumnsConfig(action) {
    yield localStorage.setItem("vendor-rates-column-config"+ process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getVendorRatesColumnsConfig(action)
    yield put(vendorRatesActions.updateVendorRatesColumnsFinished())
}

function* getVendorRates(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(vendorRatesActions.vendorRateListChangeLoadingSpinner(true));
        let params = action.payload.params || {};
        params = {...params, withDeleted: true};
        let data = yield axios.get('api/vendorrates', {params: encodeParamObject(params)});
        const currencies = yield select((store) => store.currencies.allItems);
        const grades = yield select((store) => store.grades.allItems);
        const resourceLocations = yield select((store) => store.resourceLocations.allItems);
        const currenciesMap = {}
        currencies.forEach((currency) => {
            currenciesMap[currency.id] = currency.name
        });
        const gradesMap = {};
        grades.forEach((grade) => {
            gradesMap[grade.id] = grade.name
        });
        const resourceLocationMap = {};
        resourceLocations.forEach((resourceLocation) => {
            resourceLocationMap[resourceLocation.id] = resourceLocation.name
        });
        const dataCopy = data.data.items.map((item) => {
            return {
                ...item,
                resourceType: item.resourceType !== undefined ? resourceTypes[item.resourceType] : "",
                assignmentSite: item.assignmentSite !== undefined ? assignmentSites[item.assignmentSite] : ""
            }
        });
        data.data.items = dataCopy
        yield put(vendorRatesActions.getVendorRatesListFinished(data.data));
    } catch (error) {
        yield put(vendorRatesActions.getVendorRatesListFailed(error));
    }
}

export default [
    takeEvery(actionTypes.GET_VENDOR_RATES_COLUMNS_STARTED, getVendorRatesColumnsConfig),
    takeEvery(actionTypes.UPDATE_VENDOR_RATES_COLUMNS_STARTED, saveColumnsConfig),
    takeLatest(actionTypes.GET_VENDOR_RATES_LIST_STARTED, getVendorRates),
    takeLatest(actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_STARTED, getPicklists)
];