import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../../../../components/Input/Input';
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import months from '../../../../constants/monthsLong';
const mapStateToProps = (state) => {
    return {
        activityName: state.deliveryConfirmationForm.activityName,
        deliverable: state.deliveryConfirmationForm.deliverable,
        poNumber: state.deliveryConfirmationForm.poNumber,
        year: state.deliveryConfirmationForm.year,
        month: state.deliveryConfirmationForm.month ? months[state.deliveryConfirmationForm.month -1] : "",
    }
}
class DeliveryInformationContainer extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{position: 'relative'}}>
                    <Input label="Activity name" width="100%" disabled value={this.props.activityName || ""} className="disabled-form-input"/>
                </div>
                <div style={{position: 'relative'}}>
                    <Input label="Deliverable" width="100%" disabled value={this.props.deliverable || ""} className="disabled-form-input"/>
                </div>
                <div style={{position: 'relative'}}>
                    <Input label="PO number" width="100%" disabled value={this.props.poNumber || ""} className="disabled-form-input"/>
                </div>
                <div>
                    <div style={{position: 'relative', width: '50%', display:'inline-block'}}>
                        <Input label="Year" width="100%" disabled value={this.props.year || ""} className="disabled-form-input"/>
                    </div>
                    <div style={{position: 'relative', width:"50%", display:'inline-block'}}>
                        <Input label="Month" width="100%" disabled value={this.props.month || ""} className="disabled-form-input"/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default formSectionWrapper(connect(mapStateToProps, null)(DeliveryInformationContainer), "Delivery Information");