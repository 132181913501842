import React, { Component } from 'react';
import Roles from '../components/Roles';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import { shouldComponentUpdate, getChangelogChanges } from '../../../../store/reducers/ChangeLogFormReducer';
import YesNoConsent from '../../../../constants/YesNoConsent';

const consentTypeParsed = [{ label: YesNoConsent[0], value: 1 }, { label: YesNoConsent[1], value: 0 }]
const mapStateToProps = (state) => {
    return {
        isDraft: state.changelogForm.isDraft,
        planner: state.changelogForm.planner,
        errors: state.changelogForm.errors,
        approver: state.changelogForm.approver,
        supplierRepresentative: state.changelogForm.supplierRepresentative,
        leanixdto: state.changelogForm.leanixdto,
        ePPMDto: state.changelogForm.ePPMDto,
        role: state.user.selectedRole,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        editMode: state.changelogForm.editMode,
        vendorType: state.changelogForm.vendorType,
        shouldUpdate: shouldComponentUpdate(state.changelogForm),
        changes: getChangelogChanges(state),
        activityType: state.changelogForm.activityType,
        consentTypes: consentTypeParsed,
        consentType: state.changelogForm.consentType || '',

    }
};

const mapDispatchToProps = (dispatch) => ({
    supplierChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormSupplierChanged(payload)),
    approverChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormApproverChanged(payload)),
    leanixChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormLeanixChanged(payload)),
    eppmChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormEppmChanged(payload)),
    consentTypeChanged: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormConsentTypeChanged(payload)),
}

);

class DeliveryInfromationContainer extends Component {
    shouldComponentUpdate() {
        return this.props.shouldUpdate;
    }
    render() {
        return (
            <Roles
                isDraft={this.props.isDraft}
                editing={this.props.editing}
                changes={this.props.changes || {}}
                editMode={this.props.editMode}
                role={this.props.role}
                isChangelogOwner={this.props.isChangelogOwner}
                supplierChanged={this.props.supplierChanged}
                approverChanged={this.props.approverChanged}
                errors={this.props.errors}
                approver={this.props.approver}
                supplierRepresentative={this.props.supplierRepresentative}
                planner={this.props.planner}
                vendorType={this.props.vendorType}
                leanixChanged={this.props.leanixChanged}
                leanixdto={this.props.leanixdto}
                ePPMDto={this.props.ePPMDto}
                eppmChanged={this.props.eppmChanged}
                activityType={this.props.activityType}
                consentTypes={this.props.consentTypes}
                onconsentTypeChanged={this.props.consentTypeChanged}
                consentType={this.props.consentType}
            />
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfromationContainer);