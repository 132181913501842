import React, {Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as exportData from '../../store/actions/exportData';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import { canFunction } from '../../config/Can';
import * as rules from '../../config/Rules';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.dataExport.loading[ownProps.url],
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    exportData: (payload) =>
        dispatch(exportData.fileExportStarted(payload)),   
});

class DataExportContainer extends Component {
    render() {
        return (
            <React.Fragment>
                {canFunction('', rules.EXPORT_DATA) ?
                <div style={{width: 134}}>
                    <div style={{display: 'flex', paddingRight: 8, marginTop: 10, position:'relative'}}>
                        <Button
                            disabled={this.props.loading}
                            style={{marginRight: 'auto'}} 
                            onClick={this.props.exportData.bind(this, this.props.url)} 
                            className="removed-text-padding blue-color">
                            <LaunchIcon />
                            Export Data
                        </Button>
                        <LoadingSpinner loading={this.props.loading}/>
                    </div> 
                </div> : null
            }
        </React.Fragment>
        )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(DataExportContainer)
