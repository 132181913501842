import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect'

const initialState = {
    loading: false,
    allItems: [],
    items: [],
    itemsBeingEditedMap: {},
    newItemObject: {},
    errors: {}
}

const constCenterReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_COST_CENTERS_FINISHED: {
            return {
                ...state,
                loading: false,
                items: action.payload
            }
        }
        case actionTypes.GET_COST_CENTERS_STARTED:
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_COST_CENTER_ACTION_PRESSED: {
            if (state.itemsBeingEditedMap[action.payload]) {
                const itemsBeingEditedMapCopy = {...state.itemsBeingEditedMap};
                delete itemsBeingEditedMapCopy[action.payload]
                return {
                    ...state,
                    itemsBeingEditedMap: itemsBeingEditedMapCopy
                }
            } else {
                const item = state.allItems.find((item) => {
                    return item.id === action.payload
                })
                const itemCopy = {...item, canBeSaved: true}
                const itemsBeingEditedMapCopy = {...state.itemsBeingEditedMap};
                itemsBeingEditedMapCopy[action.payload] = itemCopy;
                return {
                    ...state,
                    itemsBeingEditedMap: itemsBeingEditedMapCopy
                }
            }
        }
        case actionTypes.EDIT_COST_CENTER_PROPERTY: {
            const itemsBeingEditedMapCopy = {...state.itemsBeingEditedMap};
            const newItem = {
                ...itemsBeingEditedMapCopy[action.payload.id], 
                [action.payload.dataProperty]: action.payload.value
            }
            if (action.payload.dataProperty === 'domainId') {
                delete newItem.subDomainId
            }
            if (action.payload.dataProperty === 'name' && action.payload.value.length !== 10 && action.payload.validate) {
                newItem.errorName = "Name must contain exactly 10 digits"
            } else if (action.payload.dataProperty === 'name') {
                newItem.errorName = "";
            }
            itemsBeingEditedMapCopy[action.payload.id] = newItem
            if (newItem.description && newItem.name && newItem.name.length === 10 && newItem.domainId && newItem.subDomainId) {
                newItem.canBeSaved = true
            } else {
                newItem.canBeSaved = false
            }
            return {
                ...state,
                itemsBeingEditedMap: itemsBeingEditedMapCopy,
            }
        }
        case actionTypes.EDIT_NEW_COST_CENTER_PROPERTY: {
            const newItemObject = {...state.newItemObject, [action.payload.dataProperty]: action.payload.value};
            if (action.payload.dataProperty === 'domainId') {
                delete newItemObject.subDomainId
            }
            if (action.payload.dataProperty === 'name' && action.payload.value.length !== 10 && action.payload.validate) {
                newItemObject.errorName = "Name must contain exactly 10 digits"
            } else if (action.payload.dataProperty === 'name') {
                newItemObject.errorName = ""
            }
            if (newItemObject.description && newItemObject.name && newItemObject.name.length === 10 && newItemObject.domainId && newItemObject.subDomainId) {
                newItemObject.canBeSaved = true
            } else {
                newItemObject.canBeSaved = false
            }
            return {
                ...state,
                newItemObject
            }
        }
        case actionTypes.EDIT_COST_CENTER_STARTED:
        case actionTypes.ADD_COST_CENTER_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_COST_CENTERS_FAILED:
        case actionTypes.EDIT_COST_CENTER_FAILED:
        case actionTypes.ADD_COST_CENTER_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.EDIT_COST_CENTER_FINISHED: {
            const itemsBeingEditedMapCopy = {...state.itemsBeingEditedMap};
            delete itemsBeingEditedMapCopy[action.payload]
            return {
                ...state,
                itemsBeingEditedMap: itemsBeingEditedMapCopy,
                loading: false
            }
        }
        case actionTypes.ADD_COST_CENTER_FINISHED: {
            return {
                ...state,
                newItemObject: {},
                loading: false
            }
        }
        case actionTypes.GET_ALL_COST_CENTERS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_COST_CENTERS_FINISHED: {
            return {
                ...state,
                loading: false,
                allItems: action.payload
            }
        }
        case actionTypes.GET_ALL_COST_CENTERS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}

const getAllCostCenters = (state) => state.costCenters.allItems;
const getCostCenters = (state) => state.costCenters.items;
const getItemsBeingEdited = (state) => state.costCenters.itemsBeingEditedMap;
export const getSelectedCostCenters = createSelector([getAllCostCenters, getItemsBeingEdited], (costCenters, itemsBeingEdited) => {
    const selectedItems = {}
    costCenters && costCenters.forEach((costCenter) => {
        let isDeleted;
        if (itemsBeingEdited && itemsBeingEdited[costCenter.id]) {
            isDeleted = itemsBeingEdited[costCenter.id].isDeleted
        } else {
            isDeleted = costCenter.isDeleted;
        }
        selectedItems[costCenter.id] = isDeleted;
    });
    return selectedItems;
});
export const getCostCentersParsed = createSelector([getCostCenters], (costCenters) => {
    return costCenters ? costCenters.map((costCenter) => {
        return {
            label: costCenter.name,
            value: costCenter.id
        }
    }) : []
});

export const getCostCentersBySubDomain = createSelector([getCostCenters], (costCenters) => {
    const costCentersPerSubDomain = {};
    if (costCenters) {
        costCenters.forEach((costCenter) => {
            const costCenterSuggestion = {
                label: costCenter.name,
                value: costCenter.id
            }
            if (costCentersPerSubDomain[costCenter.subDomainId]) {
                costCentersPerSubDomain[costCenter.subDomainId].push(costCenterSuggestion)
            } else {
                costCentersPerSubDomain[costCenter.subDomainId] = [costCenterSuggestion]
            }
        })
    }
    return costCentersPerSubDomain;
});

export const getAllCostCentersPerSubDomainWithDeletedFiltered = createSelector([getAllCostCenters], (costCenters) => {
    const costCenterPerSubDomain = {};
    if (costCenters) {
        costCenters.forEach((costCenter) => {
            if (costCenter.isDeleted) {
                return;
            }
            const costCenterSuggestion = {
                label: costCenter.name,
                value: costCenter.id
            }
            if (costCenterPerSubDomain[costCenter.subDomainId]) {
                costCenterPerSubDomain[costCenter.subDomainId].push(costCenterSuggestion)
            } else {
                costCenterPerSubDomain[costCenter.subDomainId] = [costCenterSuggestion]
            }
        })
    }
    return costCenterPerSubDomain
})

export const getAllItemsWithSelectionProperty = createSelector([getAllCostCenters, getItemsBeingEdited], (costCenters, itemsBeingEdited) => {
    return costCenters ? costCenters.map((costCenter) => {
        return {
            ...costCenter,
            canBeEdited: itemsBeingEdited[costCenter.id] !== undefined
        }
    }) : [];
});

export default constCenterReducer;