import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default (props) => {
    return (
        <React.Fragment>
        {props.loading ? 
        <div style={
          {
            // position: props.spinnerCentered ? 'fixed' : 'absolute', 
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 2,
          pointerEvents: props.enablePointerEvents ? '': 'none',
          width:'100%',
          minHeight: props.spinnerCentered ? 'calc(100vh - 64px)' : '',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems:'center',
          justifyContent:'center',
          }}>
            {props.spinnerCentered ? 
            <div style={{position: 'fixed', top: '50%'}}>
              <CircularProgress color="primary"/>
            </div> :
              <CircularProgress color="primary"/>
            }
        </div>
        : null}
        </React.Fragment>
        )
}