import * as actionTypes from './actionTypes';

export const selectUserRole =  (myParam) => {
    return {type: actionTypes.SELECT_USER_ROLE, payload: myParam};
};
export const getUserDomainsStarted = (myParam) => {
    return {type: actionTypes.GET_USER_DOMAINS_STARTED, payload: myParam};
}
export const getUserDomainsFinished = (myParam) => {
    return {type: actionTypes.GET_USER_DOMAINS_FINISHED, payload: myParam};
}
export const getUserDomainsFailed = (myParam) => {
    return {type: actionTypes.GET_USER_DOMAINS_FAILED, payload: myParam};
}
export const selectUserDomain =  (myParam) => {
    return {type: actionTypes.SELECT_USER_DOMAIN, payload: myParam};
};
export const selectDomain =  (myParam) => {
    return {type: actionTypes.SELECT_DOMAIN, payload: myParam};
};
export const selectUsername = (myParam) => {
    return {type: actionTypes.SELECT_USERNAME, payload: myParam};
}
export const dcSupplierTabsVisibilityChanged = (myParam) => {
    return {type: actionTypes.DC_SUPPLIER_TABS_VISIBILITY_CHANGED, payload: myParam};
}
export const selectName = (myParam) => {
    return {type: actionTypes.SELECT_NAME, payload: myParam};
}
export const getCurrentlyLoggedInUser = (myParam) => {
    return {type: actionTypes.GET_CURRENTLY_LOGGED_IN_USER, payload: myParam};
}
export const setUserLogin = (myParam) => {
    return {type: actionTypes.SET_USER_LOGIN, payload: myParam}
}


