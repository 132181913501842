import * as actionTypes from './actionTypes';


export const changelogFormGetChangelogsWithGivenActivityStarted = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_STARTED, payload: myParam};
}
export const changelogFormGetChangelogsWithGivenActivityFailed = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FAILED, payload: myParam};
}
export const changelogFormGetChangelogsWithGivenActivityFinished = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_GET_CHANGELOGS_WITH_GIVEN_ACTIVITY_FINISHED, payload: myParam};
}
export const changelogFormStatusChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_STATUS_CHANGED, payload: myParam};
}
export const changelogFormLastModifiedByChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_LAST_MODIFIED_BY_CHANGED, payload: myParam};
}
export const changelogFormSupplierChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_SUPPLIER_CHANGED, payload: myParam};
}
export const changelogFormApproverChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_APPROVER_CHANGED, payload: myParam};
}
export const changelogFormLeanixChanged = (myParam) => {
    return { type: actionTypes.CHANGELOG_FORM_LEANIXID_CHANGED, payload: myParam };
}
export const changelogFormEppmChanged = (myParam) => {
    return { type: actionTypes.CHANGELOG_FORM_EPPM_CHANGED, payload: myParam };
}


export const deleteChangelogDraftStarted = (myParam) => {
    return {type: actionTypes.DELETE_CHANGELOG_DRAFT_STARTED, payload: myParam}
}
export const deleteChangelogDraftFinished = (myParam) => {
    return {type: actionTypes.DELETE_CHANGELOG_DRAFT_FINISHED, payload: myParam}
}
export const deleteChangelogDraftFailed = (myParam) => {
    return {type: actionTypes.DELETE_CHANGELOG_DRAFT_FAILED, payload: myParam}
}

export const changelogFormActivityChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_ACTIVITY_CHANGED, payload: myParam};
};
export const changelogFormActivitiesFilteredByExpenseTypeChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_ACTIVITIES_FILTERED_BY_EXPENSE_TYPE_CHANGED, payload: myParam};
}

export const changelogFormVendorTypeChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_VENDOR_TYPE_CHANGED, payload: myParam};
};
export const changelogFormVendorChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_VENDOR_CHANGED, payload: myParam};
};
export const changelogFormPoWbsChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_POWBS_CHANGED, payload: myParam};
}
export const changelogFormYearChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_YEAR_CHANGED, payload: myParam};
}
export const changelogFormYearChangedStarted = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_YEAR_CHANGED_STARTED, payload: myParam};
}
export const changelogFormRateYearChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RATE_YEAR_CHANGED, payload: myParam};
}

export const changelogFormClientIdChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_CLIENT_ID_CHANGED, payload: myParam};
}
export const changelogFormResourceItemClientIdChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CLIENT_ID_CHANGED, payload: myParam, index}
}
export const changelogFormResourceItemStartDateChangedStarted = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED_STARTED, payload: myParam, index}
}
export const changelogFormResourceItemStartDateModalChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_MODAL_CHANGED, payload: myParam}
}

export const changelogFormResourceItemExpenseTypeChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED, payload: myParam, index}
}
export const changelogFormResourceItemTypeChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_TYPE_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemStartDateChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_START_DATE_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemNameChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_NAME_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemLocationChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_LOCATION_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemAssignmentSiteChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ASSIGNMENT_SITE_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemGradeChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_GRADE_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemUnitPriceChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_UNIT_PRICE_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemPerDiemRateChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PER_DIEM_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemCurrencyChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_CURRENCY_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemMonthAmountChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_MONTH_AMOUNT_CHANGED, payload: myParam, index};
}

export const changelogFormResourceItemAvailableVendorRatesChanged = (index, myParam, step) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_VENDOR_RATES_CHANGED, payload: myParam, index, step}
}

export const changelogFormResourceItemAvailableResourceTypesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_RESOURCE_TYPES_CHANGED, payload: myParam, index}
}

export const changelogFormResourceItemAvailableAssignmentSitesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_ASSIGNMENT_SITES_CHANGED, payload: myParam, index}
}

export const changelogFormResourceItemAvailableLocationsChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_LOCATIONS_CHANGED, payload: myParam, index}
}

export const changelogFormResourceItemAvailableGradesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_GRADES_CHANGED, payload: myParam, index}
}

export const changelogFormResourceItemAvailableUnitPricesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_UNIT_PRICES_CHANGED, payload: myParam, index}
}
export const changelogFormResourceItemAvailablePerDiemRatesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_PER_DIEM_RATES_CHANGED, payload: myParam, index}
}

export const changelogFormResourceItemAvailableCurrenciesChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_AVAILABLE_CURRENCIES_CHANGED, payload: myParam, index}
}
export const changelogResourceTagsChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_RESOURCE_TAGS_CHANGED, payload: myParam, index}
}
export const changelogTagsChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_TAGS_CHANGED, payload: myParam};
}

export const changeLogFormInitialize = () => {
    return {type: actionTypes.CHANGELOG_FORM_INITIALIZE};
}
export const changelogFormInitialized = () => {
    return {type: actionTypes.CHANGELOG_FORM_INITIALIZED};
}
export const changeLogFormPlannerChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_PLANNER_CHANGED, payload: myParam};
}
export const changeLogFormRequestorChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_REQUESTOR_CHANGED, payload: myParam};
}

export const changelogFormAddResourceItem = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ADDED, payload: myParam};
}

export const changelogFormResourceItemDeleted = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DELETED, payload: myParam}
}

export const changelogFormCommentChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_COMMENT_CHANGED, payload: myParam};
}
export const changelogFormIsConfirmedChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_IS_CONFIRMED_CHANGED, payload: myParam}
}

export const submitChangelogFormStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_CHANGELOG_FORM_STARTED, payload: myParam};
}
export const submitChangelogFormFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_CHANGELOG_FORM_FAILED, payload: myParam};
}
export const submitChangelogFormFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_CHANGELOG_FORM_FINISHED, payload: myParam};
}

export const changelogFormSubmitValidationErrorsChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_SUBMIT_VALIDATION_ERRORS_CHANGED, payload: myParam};
}
export const changelogFormAttachmentAdded = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_ATTACHMENT_ADDED, payload: myParam};
}

export const resetForm = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_REST_FORM}
}
export const editChangeLogFormInitialize = (myParam) => {
    return {type: actionTypes.EDIT_CHANGE_LOG_FORM_INITIALIZE, payload: myParam};
}

export const changelogFormCommentsChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_COMMENTS_CHANGED, payload: myParam}
}
export const changelogFormAttachmentDeleted = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_ATTACHMENT_DELETED, payload: myParam}
}
export const changelogFormLoaded = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_LOADED, payload: myParam};
}
export const changelogFormResourceItemKeyChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_KEY_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemIdChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_ID_CHANGED, payload: myParam, index};
}

export const changelogFormChangelogIdChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_CHANGELOG_ID_CHANGED, payload: myParam};
}
export const submitEditChangelogFormStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_STARTED, payload: myParam};
}
export const submitEditChangelogFormFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_FAILED, payload: myParam};
}
export const submitEditChangelogFormFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_EDIT_CHANGELOG_FORM_FINISHED, payload: myParam};
}

export const viewChangelogRevisionStarted = (myParam) => {
    return {type: actionTypes.VIEW_CHANGELOG_REVISION_STARTED, payload: myParam};
}
export const viewChangelogRevisionFailed = (myParam) => {
    return {type: actionTypes.VIEW_CHANGELOG_REVISION_FAILED, payload: myParam};
}
export const viewChangelogRevisionFinished = (myParam) => {
    return {type: actionTypes.VIEW_CHANGELOG_REVISION_FINISHED, payload: myParam};
}

export const changelogFormDownloadAttachment = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_DOWNLOAD_ATTACHMENT, payload: myParam};
}

export const getChangelogHistoryStarted = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_FORM_HISTORY_STARTED, payload: myParam};
}
export const getChangelogHistoryFinished = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_FORM_HISTORY_FINISHED, payload: myParam};
}
export const getChangelogHistoryFailed = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_FORM_HISTORY_FAILED, payload: myParam};
}

export const changelogFormDomainChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_DOMAIN_CHANGED, payload: myParam};
}
export const changelogFormSubDomainChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_SUBDOMAIN_CHANGED, payload: myParam};
}
export const changelogFormActivityTypeChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_ACTIVITY_TYPE_CHANGED, payload: myParam};
}
export const changelogFormConsentTypeChanged = (myParam) => {
    return { type: actionTypes.CONSENT_TYPE_CHANGED, payload: myParam };
}

export const changelogFormDuplicateResourceItem = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_DUPLICATE_RESOURCE_ITEM, payload: myParam};
}

export const changelogFormResourceItemPoNumbersChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_PO_NUMBERS_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemDcsChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_DCS_CHANGED, payload: myParam, index};
}
export const changelogFormResourceItemResourceModelIdChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_RESOURCE_ITEM_RESOURCE_MODEL_ID_CHANGED, payload: myParam, index};
}
export const changelogFormCostTypeChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_COST_TYPE_CHANGED, payload: myParam};
}
export const changelogFormPoLocationChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_PO_LOCATION_CHANGED, payload: myParam};
}
export const changelogFormChangelogCreatedByChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_CHANGELOG_CREATED_BY_CHANGED, payload: myParam};
}
export const changelogFormEditModeChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_EDIT_MODE_CHANGED, payload: myParam};
}

export const changelogFormSetState = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_SET_STATE, payload: myParam};
}
export const changelogFormDataLoaded = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_DATA_LOADED, payload: myParam};
}
export const changelogFormPrepaymentChanged = (index, myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_PREPAYMENT_CHANGED, payload: myParam, index};
}

export const setResourcesThrottled = (myParam) => {
    return {type: actionTypes.SET_CHANGELOG_RESOURCES_THROTTLED, payload: myParam};
}
export const createChangelogPdf = (myParam) => {
    return {type: actionTypes.CREATE_CHANGELOG_PDF, payload: myParam};
} 
export const changelogFormLatestChangesChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_LATEST_CHANGES_CHANGED, payload: myParam};
} 
export const changelogFormShowChangesChanged = (myParam) => {
    return {type: actionTypes.CHANGELOG_FORM_SHOW_CHANGES_CHANGED, payload: myParam};
} 
