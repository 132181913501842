import React from 'react';
import { makeStyles} from "@material-ui/core/styles";
import CardItem from './CardItem';

const useStyles = makeStyles(theme => ({
    commentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
}))
export default (props) => {
    const classes = useStyles();
    return (
        <div className={classes.commentWrapper}>
            {props.history.map((history, index) => {
                return (
                    <CardItem key={history.modifiedDate} history={history} index={index} viewRevision={props.onViewRevisionClicked}/>
                )
            })}
     </div>
    )
}