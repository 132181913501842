import * as actionTypes from './actionTypes';

export const getDomainsStarted =  (myParam) => {
    return {type: actionTypes.GET_DOMAINS_STARTED, payload: myParam};
};
export const getDomainsFailed =  (myParam) => {
    return {type: actionTypes.GET_DOMAINS_FAILED, payload: myParam};
};
export const getDomainsFinished =  (myParam) => {
    return {type: actionTypes.GET_DOMAINS_FINISHED, payload: myParam};
};
export const getExpenseTypeByDomainStarted = (myParam) => {
    return {type: actionTypes.GET_EXPENSE_TYPES_BY_DOMAIN_STARTED, payload: myParam};
}
export const getExpenseTypesStarted =  (myParam) => {
    return {type: actionTypes.GET_EXPENSE_TYPES_STARTED, payload: myParam};
};
export const getExpenseTypesFailed =  (myParam) => {
    return {type: actionTypes.GET_EXPENSE_TYPES_FAILED, payload: myParam};
};
export const getExpenseTypesFinished =  (myParam) => {
    return {type: actionTypes.GET_EXPENSE_TYPES_FINISHED, payload: myParam};
};
export const addExpenseTypeStarted =  (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_STARTED, payload: myParam};
};
export const addExpenseTypeFailed =  (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_FAILED, payload: myParam};
};
export const addExpenseTypeFinished =  (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_FINISHED, payload: myParam};
};
export const editExpenseTypeStarted =  (myParam) => {
    return {type: actionTypes.EDIT_EXPENSE_TYPE_STARTED, payload: myParam};
};
export const editExpenseTypeFailed =  (myParam) => {
    return {type: actionTypes.EDIT_EXPENSE_TYPE_FAILED, payload: myParam};
};
export const editExpenseTypeFinished =  (myParam) => {
    return {type: actionTypes.EDIT_EXPENSE_TYPE_FINISHED, payload: myParam};
};
export const deleteExpenseTypeStarted =  (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_STARTED, payload: myParam};
};
export const deleteExpenseTypeFailed =  (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_FAILED, payload: myParam};
};
export const deleteExpenseTypeFinished =  (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_FINISHED, payload: myParam};
};
export const expenseTypePicklistNewItemDomainChanged =  (myParam) => {
    return {type: actionTypes.EXPENSE_TYPE_PICKLIST_NEW_ITEM_DOMAIN_CHANGED, payload: myParam}
}
export const expenseTypePicklistNewItemExpenseTypeChanged =  (myParam) => {
    return {type: actionTypes.EXPENSE_TYPE_PICKLIST_NEW_ITEM_EXPENSE_TYPE_CHANGED, payload: myParam}
}
export const expenseTypePicklistEditItemTextChanged = (myParam) => {
    return {type: actionTypes.EXPENSE_TYPE_PICKLIST_EDIT_ITEM_TEXT_CHANGED, payload: myParam}
}
export const expenseTypeItemGlAccountChanged = (myParam) => {
    return {type: actionTypes.EXPENSE_TYPE_ITEM_GL_ACCOUNT_CHANGED, payload: myParam};
}