import * as actionTypes from './actionTypes';

export const getAllExpenseTypeCostTypeMappingStarted = (myParam) => {
    return {type: actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_STARTED, payload: myParam};
}
export const getAllExpenseTypeCostTypeMappingFailed = (myParam) => {
    return {type: actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FAILED, payload: myParam};
}
export const getAllExpenseTypeCostTypeMappingFinished = (myParam) => {
    return {type: actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FINISHED, payload: myParam};
}
export const deleteExpenseTypeCostTypeConnectionStarted = (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED, payload: myParam};
}
export const deleteExpenseTypeCostTypeConnectionFailed = (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED, payload: myParam};
}
export const deleteExpenseTypeCostTypeConnectionFinished = (myParam) => {
    return {type: actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED, payload: myParam};
}
export const addExpenseTypeCostTypeConnectionStarted = (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED, payload: myParam};
}
export const addExpenseTypeCostTypeConnectionFailed = (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED, payload: myParam};
}
export const addExpenseTypeCostTypeConnectionFinished = (myParam) => {
    return {type: actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED, payload: myParam};
}

