import React from "react";
import Select, {wrapMenuList} from "react-select-async-paginate";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Input from "./Input";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  noOptionsMessage: {
    paddingLeft: 10
  },
  noOptionsMessageSmall: {
    fontSize: 10,
    paddingLeft:10
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    paddingLeft: 12,
    fontSize: 16,
    position: 'relative',
    textOverflow: 'ellipsis'
  },
  valueContainerSmall: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    paddingLeft: 12,
    position: 'relative',
    textOverflow: 'ellipsis',
    fontSize: 10,
  },
  // singleValue: {
  //   fontSize: 16,
  //   width: 'calc(100% - 6px)',
  //   textOverflow: 'ellipsis'
  // },
  // singleValueSmall: {
  //   fontSize: 10,
  //   width: 'calc(100% - 6px)',
  //   lineHeight: '10px',
  //   textOverflow: 'ellipsis'
  // },
  placeholder: {
    position: "absolute",
    left: 13,
    bottom: 6,
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.4)'
  },
  placeholderSmall: {
    position: "absolute",
    left: 13,
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.4)'
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.small ? 
        props.selectProps.classes.noOptionsMessageSmall : props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.inputValue.length < 3 ? "Start typing name to search" : props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, width, small, errorText, tooltipText, className }
  } = props;
  const prop = {
    inputComponent,
    width,
    tooltipText,
    className,
    small,
    errorText,
    inputProps: {
      className: classes.input,
      width: props.width,
      ref: innerRef,
      children,
      ...innerProps
    }
  };
  return <Input {...prop} {...TextFieldProps} />;
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 800 : 400,
        fontSize: props.selectProps.small ? 10 : 16
      }}
      {...newInnerProps}
    >
      {`${props.data.name} (${props.data.value})`}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.small ? props.selectProps.classes.placeholderSmall : props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.small ? props.selectProps.classes.singleValueSmall : props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return (
    <div 
    className={props.selectProps.small ? 
      props.selectProps.classes.valueContainerSmall 
      : props.selectProps.classes.valueContainer
    }>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};


function Menu(props) {
    const {onMouseMove, onMouseOver, ...newInnerProps} = props.innerProps;
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...newInnerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};
const IndicatorSeparator = () => null;
const menu = wrapMenuList(Menu)
const components = {
  Control,
  menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  // SingleValue,
  ValueContainer,
  IndicatorSeparator,
};

export default function IntegrationReactSelect(props) {
  const classes = useStyles();
  const theme = useTheme();

  const isSmallHeight = props.small ? {
    height: 20,
    fontSize: 10
  } : {};
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      ...isSmallHeight,
      "& input": {
        font: "inherit"
      }
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: props.small ? 0 : 7,
      paddingRight: 7
    }),
    clearIndicator: base => ({
      ...base,
      padding: props.small ? 0 : 7,
      paddingRight: 0
    }),
    menuList: base => ({
      ...base,
      maxHeight: 200
    }),
    singleValue: (base, state) =>({
      ...base,
      height: 20,
      lineHeight:'20px',
      display: state.selectProps.menuIsOpen ? 'none' : 'block'
    })
  };
  return (
    <div style={{ width: props.width, minWidth: props.minWidth }}>
      <Select
        placeholder="Type..." 
        debounceTimeout={200}
        width="100%"
        isClearable={props.isClearable}
        className={props.className}
        tooltipText={props.tooltipText}
        small={props.small}
        errorText={props.errorText}
        additional={props.additional}
        classes={classes}
        styles={selectStyles}
        TextFieldProps={{
          label: props.label,
        }}
        loadOptions={props.loadOptions}
        // options={props.suggestions}
        components={components}
        value={props.value}
        onChange={props.handleChangeSingle}
        isDisabled={props.disabled}
      />
    </div>
  );
}
