import React, { Component } from 'react';
import FormSectionWrapper from '../ChangelogForm/components/FormSectionWrapper'
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import AttachmentComponent from '../ChangelogForm/components/AttachmentComponent';
import * as changelogFormActionsCreators from '../../../store/actions/changeLogFormActions';
import { getRmChanges } from '../../../store/reducers/ResourceModelFormReducer';

const mapStateToProps = (state) => {
    return {
        attachments: state.resourceModelForm.resourceModel && state.resourceModelForm.resourceModel.attachments ? state.resourceModelForm.resourceModel.attachments : [],
        changes: getRmChanges(state)
    }
};
const mapDispatchToProps = (dispatch) => ({
    attachmentAdded: (payload) =>
        dispatch(resourceModelFormActions.resourceModelFormAttachmentAdded(payload)),
    attachmentRemoved: (payload) =>
        dispatch(resourceModelFormActions.resourceModelFormAttachmentDeleted(payload)),
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))                       
})

class SupportingDocumentationContainer extends Component {
    render () {
        return (
            <AttachmentComponent 
                changes={this.props.changes}
                attachments={this.props.attachments}
                attachmentRemoved={this.props.attachmentRemoved}
                attachmentAdded={this.props.attachmentAdded}
                downloadAttachment={this.props.downloadAttachment}
            />
        )
    }
}
export default FormSectionWrapper(compose(connect(mapStateToProps, mapDispatchToProps))(SupportingDocumentationContainer), "Supporting Documentation");