import * as actionTypes from '../actions/actionTypes';

const initialState = {
    poLocations: [],
    allPoLocations: [],
    selectedItems: {},
    loading: false,
    newItemNumber: '',
    newItemName: '',
    numberBeingEditedMap: {},
    nameBeingEditedMap: {}
}

const PoLocationReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_ALL_PO_LOCATIONS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_PO_LOCATIONS_FAILED: {
            return {
                ...state,
                loading: false,
                allPoLocations: [],
                selectedItems: {}
            }
        }
        case actionTypes.GET_ALL_PO_LOCATIONS_FINISHED: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                loading: false,
                allPoLocations: action.payload,
                selectedItems: selectedItems
            }
        }
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_PO_LOCATIONS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_PO_LOCATIONS_FINISHED: {
            return {
                ...state,
                loading: false,
                poLocations: action.payload
            }
        }
        case actionTypes.GET_PO_LOCATIONS_FAILED: {
            return {
                ...state,
                loading: false,
                poLocations: []
            }
        }
        case actionTypes.ADD_PO_LOCATION_STARTED:
        case actionTypes.DELETE_PO_LOCATION_STARTED:
        case actionTypes.EDIT_PO_LOCATION_STARTED: {
            return {
                ...state,
                loading: true
            }
        } 
        case actionTypes.ADD_PO_LOCATION_FINISHED:
        case actionTypes.DELETE_PO_LOCATION_FINISHED:
        case actionTypes.EDIT_PO_LOCATION_FINISHED:         
        case actionTypes.ADD_PO_LOCATION_FAILED:
        case actionTypes.DELETE_PO_LOCATION_FAILED:
        case actionTypes.EDIT_PO_LOCATION_FAILED: {
            return {
                ...state,
                newItemNumber: '',
                newItemName: '',
                numberBeingEditedMap: {},
                nameBeingEditedMap: {},
                loading: false
            }
        }
        case actionTypes.NEW_PO_LOCATION_NAME_CHANGED: {
            return {
                ...state,
                newItemName: action.payload.target.value
            }
        } 
        case actionTypes.NEW_PO_LOCATION_NUMBER_CHANGED: {
            return {
                ...state,
                newItemNumber: action.payload.target.value
            }
        }
        case actionTypes.EDIT_PO_LOCATION_NAME: {
            const itemsBeingEditedCopy = {...state.vendorNameBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                nameBeingEditedMap: itemsBeingEditedCopy
            }
        }
        case actionTypes.EDIT_PO_LOCATION_NUMBER: {
            const itemsBeingEditedCopy = {...state.vendorSapNumberBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                numberBeingEditedMap: itemsBeingEditedCopy
            }
        }
        default: {
            return state;
        }
    }
}
export default PoLocationReducer;