import React, { Component } from 'react';
import { connect } from 'react-redux';
import formSectionWrapper from '../ChangelogForm/components/FormSectionWrapper';
import { getAllCostCentersPerSubDomainWithDeletedFiltered } from '../../../store/reducers/costCenterReducer';
import * as regionalChangelogActions from '../../../store/actions/regionalChangelogActions';
import { getAllCurrenciesParsedWithDeletedFiltered } from '../../../store/reducers/OneColumnPicklistReducer';
import {getExpenseTypesPerCostTypesWithFilteredDeleted} from '../../../store/reducers/expenseTypeCostTypeReducer';
import {getCostTypesPerResource, getWbsesPerResource, getActivitiesPerResource} from '../../../store/reducers/regionalChangelogFormReducer';
import ResourceList from './components/ResourceList';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { getRegionalChangelogChanges } from '../../../store/reducers/regionalChangelogFormReducer';

const arrayPlaceholder = []
const mapStateToProps = (state) => {
    return {
        tagsSuggestions: state.regionalChangelogForm.lineTagsSuggestions,
        budgetYear: state.regionalChangelogForm.budgetYear ? state.regionalChangelogForm.budgetYear.value : 0,
        currentMonth: state.regionalChangelogForm.currentMonth,
        currentYear: state.regionalChangelogForm.currentYear,
        errors: state.regionalChangelogForm.errors,
        domain: state.regionalChangelogForm.domain,
        planByCostCenter: state.regionalChangelogForm.planByCostCenter,
        resources: state.regionalChangelogForm.resources,
        currencies: getAllCurrenciesParsedWithDeletedFiltered(state),
        costTypesPerResource: getCostTypesPerResource(state),
        wbsesPerResource: getWbsesPerResource(state),
        activitiesPerResource: getActivitiesPerResource(state),
        expenseTypes: getExpenseTypesPerCostTypesWithFilteredDeleted(state),
        costCenters: state.regionalChangelogForm.subDomain ? getAllCostCentersPerSubDomainWithDeletedFiltered(state)[state.regionalChangelogForm.subDomain.value] : arrayPlaceholder,
        changes: getRegionalChangelogChanges(state),
    }
}

const mapDispatchToProps = (dispatch) => ({
    regionalChangelogLineTagsChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogLineTagsChanged(index, payload)),
    regionalChangelogResourceItemCostCenterChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemCostCenterChanged(index, payload)),
    regionalChangelogResourceItemActivityChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemActivityChanged(index, payload)),
    regionalChangelogResourceItemCostTypeChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemCostTypeChanged(index, payload)),
    regionalChangelogResourceItemWbsChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemWbsChanged(index, payload)), 
    regionalChangelogResourceItemExpenseTypeChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemExpenseTypeChanged(index, payload)), 
    regionalChangelogResourceItemCurrencyChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemCurrencyChanged(index, payload)), 
    plansChanged: (index, payload) => 
        dispatch(regionalChangelogActions.regionalChangelogResourceItemPlansChanged(index, payload)),
    actualsChanged: (index, payload) =>
        dispatch(regionalChangelogActions.regionalChangelogResourceItemActualsChanged(index, payload)),
    addResource: () =>
        dispatch(regionalChangelogActions.regionalChangelogAddResourceItem()),
    deleteResource: (index) => {
        dispatch(regionalChangelogActions.regionalChangelogDeleteResourceItem(index))   
    }
             
});


class ResourcesContainer extends Component {
    render() {
        return (
            <div>
                <ResourceList {...this.props} />
                <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                    <Button color="primary" 
                        style={{marginLeft: 'auto'}} 
                        onClick={this.props.addResource} 
                        className="removed-text-padding">
                        <AddIcon />
                        ADD NEW RESOURCE
                    </Button>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResourcesContainer);