import React, { Component } from 'react';
import DeliveryInformation from './components/DeliveryInformation';
import { connect } from 'react-redux';
import { getAllDomainsParsedWithDeletedFilterd } from '../../../store/reducers/DomainsReducer';
import { getAllSubDomainsPerDomainWithDeletedFiltered } from '../../../store/reducers/subDomain';
import * as regionalChangelogFormActions from '../../../store/actions/regionalChangelogActions'
import {getYears} from '../../../store/reducers/ChangeLogFormReducer';
import { getAllCostCentersPerSubDomainWithDeletedFiltered } from '../../../store/reducers/costCenterReducer';
import { getRegionalChangelogChanges } from '../../../store/reducers/regionalChangelogFormReducer';

const mapStateToProps = (state) => {
    return {
        tags: state.regionalChangelogForm.tags,
        tagsSuggestions: state.regionalChangelogForm.tagsSuggestions,
        id: state.regionalChangelogForm.id,
        errors: state.regionalChangelogForm.errors,
        planByCostCenter: state.regionalChangelogForm.planByCostCenter,
        budgetYears: getYears(state),
        budgetYear: state.regionalChangelogForm.budgetYear,
        domain: state.regionalChangelogForm.domain,
        subDomain: state.regionalChangelogForm.subDomain,
        costCenter: state.regionalChangelogForm.costCenter,
        costCenters: state.regionalChangelogForm.subDomain ? getAllCostCentersPerSubDomainWithDeletedFiltered(state)[state.regionalChangelogForm.subDomain.value] : [],
        domains: getAllDomainsParsedWithDeletedFilterd(state),
        subDomains: state.regionalChangelogForm.domain ? getAllSubDomainsPerDomainWithDeletedFiltered(state)[state.regionalChangelogForm.domain.value] : [],
        changes: getRegionalChangelogChanges(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    tagsChanged: (payload) =>
        dispatch(regionalChangelogFormActions.regionalChangelogTagsChanged(payload)),
    domainChanged: (payload) => 
        dispatch(regionalChangelogFormActions.regionalChangelogDomainChanged(payload)),
    subDomainChanged: (payload) =>
        dispatch(regionalChangelogFormActions.regionalChangelogSubDomainChanged(payload)),
    costCenterChanged: (payload) =>
        dispatch(regionalChangelogFormActions.regionalChangelogCostCenterChanged(payload)),
    budgetYearChanged: (payload) =>
        dispatch(regionalChangelogFormActions.regionalChangelogBudgetYearChanged(payload))  
});


class DeliveryInformationContainer extends Component {
    render() {
        return (
            <DeliveryInformation {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInformationContainer);