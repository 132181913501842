import React from 'react';
import DataTable from '../../../../components/DataTable/DataTable2';
import Input from '../../../../components/Input/Input';
import NumberFormat from 'react-number-format';

const columns = [
    {
        header: "",
        dataProperty: 'header',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jan",
        dataProperty: '1',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Feb",
        dataProperty: '2',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Mar",
        dataProperty: '3',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Apr",
        dataProperty: '4',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "May",
        dataProperty: '5',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jun",
        dataProperty: '6',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Jul",
        dataProperty: '7',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Aug",
        dataProperty: '8',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Sep",
        dataProperty: '9',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Oct",
        dataProperty: '10',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Nov",
        dataProperty: '11',
        isVisible: true,
        headerOnly: true
    },
    {
        header: "Dec",
        dataProperty: '12',
        isVisible: true,
        headerOnly: true
    },
]

const ResourceTable = (props) => {
    const [plans, setPlans] = React.useState(0); 
    const [actuals, setActuals] = React.useState(0); 
    React.useEffect(() => {
        setPlans(props.plans);
    }, [props.plans]);
    React.useEffect(() => {
        setActuals(props.actuals);
    }, [props.actuals]);
    const testData = [{
        'header': 'Planned',
        id:"plans",
    }, {
        'header': "Actual amount",
        id: "actuals",
    }];

    for (let i = 1; i <= 12; i++) {
        if (props.plans && props.plans[parseInt(i)] !== undefined) {
            testData[0][parseInt(i)] = plans[parseInt(i)];
        }
        if (props.actuals && props.actuals[parseInt(i)] !== undefined) {
            testData[1][parseInt(i)] = actuals[parseInt(i)];
        }
    }
    const isPlanEnabled = (month) => props.allPlansEnabled || (props.plansEnabled && props.budgetYear && props.budgetYear >= props.currentYear && (month >= props.currentMonth || props.budgetYear > props.currentYear))
    const isActualEnabled = (month) =>  props.actualsEnabled
    const actualsChanged = (e) => {
        const splittedName = e.target.name.split(',');
        const actualsCopy = {...props[splittedName[1]]};
        actualsCopy[splittedName[0]] = e.target.value;
        if (e.target.value.length === 0) {
            delete actualsCopy[splittedName[0]];
        }
        setActuals(actualsCopy);
    }
    const plannedChanged = (e) => {
        const splittedName = e.target.name.split(',');
        const actualsCopy = {...props[splittedName[1]]};
        actualsCopy[splittedName[0]] = e.target.value;
        if (e.target.value.length === 0) {
            delete actualsCopy[splittedName[0]];
        }
        setPlans(actualsCopy)
    }
    const createCustomRowCellsObject = function(monthStart) {
        const columnsCopyFromMonth = [...columns];
        const customCellRendererObject = columnsCopyFromMonth.reduce((obj, item) => {
            if (item.dataProperty === 'header') {
                obj[item.dataProperty] = (data, id) => {
                    return (
                        <div style={{minWidth: 56, textAlign: 'right', fontSize: 12}}>{data}</div>
                    )
                }
            }
            else {
                obj[item.dataProperty] = (data, id, test, month) => {
                    if (id === 'plans') {
                        if (isPlanEnabled(item.dataProperty) || props.viewMode) {
                            return (
                                <Input 
                                    width="56px"
                                    minWidth="56px"
                                    inputComponent={CustomInput}
                                    inputProps={{name: item.dataProperty+","+id, min: 0}}
                                    value={data}
                                    onChange={plannedChanged}
                                    onBlur={props.plansChanged ? props.plansChanged.bind(null, plans) : null}
                                    disabled={props.viewMode}
                                    className={props.changes.plans && props.changes.plans[item.dataProperty] !== undefined ? "input-updated-table" : item.dataProperty >= props.actualsEnd  ? "green" : "line-through"}
                                    tooltipText={props.changes.plans && props.changes.plans[item.dataProperty] !== undefined ? props.changes.plans[item.dataProperty]: "" }
                                />
                            )
                        }
                        return (
                            <div style={{minWidth: 56, paddingLeft: 6, fontSize: 12, textDecoration: item.dataProperty >= props.actualsEnd  ?'' : "line-through", color: item.dataProperty >= props.actualsEnd ? "#28c101" : ""}}>
                                      <NumberFormat 
                                        value={data}
                                        displayType={'text'}
                                        thousandSeparator 
                                        decimalScale={2}
                                        allowedDecimalSeparators={[".",","]}
                                        isNumericString
                                    />
                            </div>
                        )
                    } else {
                        if (!isActualEnabled(item.dataProperty) && !props.viewMode) { 
                            return (<div style={{minWidth: 56, paddingLeft: 6, fontSize: 12, color: item.dataProperty < props.actualsEnd ? "#28c101" : ""}}>
                                    <NumberFormat 
                                        value={data}
                                        displayType={'text'}
                                        thousandSeparator 
                                        decimalScale={2}
                                        allowedDecimalSeparators={[".",","]}
                                        isNumericString
                                    />
                                    </div>)
                        }
                        return (
                            <Input 
                                width="56px"
                                minWidth="56px" 
                                value={data}
                                disabled={props.viewMode}
                                inputComponent={CustomInput}
                                onBlur={props.actualsChanged ? props.actualsChanged.bind(null, actuals) : null}
                                inputProps={{name: item.dataProperty+","+id, min: 0}}
                                onChange={actualsChanged}
                                className={props.changes.actuals && props.changes.actuals[item.dataProperty] !== undefined ? "input-updated-table" : item.dataProperty < props.actualsEnd ? "green" : ""}
                                tooltipText={props.changes.actuals && props.changes.actuals[item.dataProperty] !== undefined ? props.changes.actuals[item.dataProperty]: "" }

                            />
                        )
                    }
                }
            }    
            return obj;
        }, {});
        return customCellRendererObject;
    }
    return (
        <DataTable 
            minWidth={props.minWidth}
            data={testData}
            customRowCells={createCustomRowCellsObject(props.monthStart)}
            columns={columns}
            tableClass="month-table"
        />
    )
}
const CustomInput = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalScale={2}
        allowedDecimalSeparators={[".",","]}
        isNumericString
      />
    );
}
export default ResourceTable;

