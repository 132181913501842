import dictionariesSagas from './dictionariesSagas';
import initializeAppSagas from './initializeAppSagas';
import changelogsSagas from './changelogsSagas';
import changelogDataSagas from './changelogDataSagas';
import changelogFormSagas from './changelogFormSagas';
import deliveryConfirmationSagas from './deliveryConfirmationSagas';
import resourceModelSagas from './resourceModelSagas';
import deliveryConfirmationFormSagas from './deliveryConfirmationFormSagas';
import resourceModelFormSagas from './resourceModelFormSagas';
import activityFormSagas from './activityFormSagas';
import activitiesViewerSagas from './activitiesViewerSagas';
import wbsViewerSagas from './wbsViewerSagas';
import vendorRatesSagas from './vendorRatesViewerSagas';
import wbsFormSagas from './wbsFormSagas';
import vendorRateFormSagas from './vendorRateFormSagas';
import subDomainSagas from './subDomainsSagas';
import domainsSagas from './domainsSagas';
import importMasterDataSagas from './importMasterDataSagas';
import dataExportSagas from './dataExportSagas';
import costCenterSagas from './costCenterSagas';
import regionalChangelogFormSagas from './regionalChangelogFormSagas';
import regionalChangelogSagas from './regionalChangelogSagas';
import sapActualsSagas from './sapActualsSagas';
import tagsSagas from './tagsSagas';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        ...initializeAppSagas,
        ...dictionariesSagas,
        ...changelogsSagas,
        ...changelogDataSagas,
        ...changelogFormSagas,
        ...deliveryConfirmationSagas,
        ...resourceModelSagas,
        ...deliveryConfirmationFormSagas,
        ...resourceModelFormSagas,
        ...activityFormSagas,
        ...activitiesViewerSagas,
        ...wbsViewerSagas,
        ...vendorRatesSagas,
        ...wbsFormSagas,
        ...vendorRateFormSagas,
        ...subDomainSagas,
        ...domainsSagas,
        ...importMasterDataSagas,
        ...dataExportSagas,
        ...costCenterSagas,
        ...regionalChangelogFormSagas,
        ...regionalChangelogSagas,
        ...sapActualsSagas,
        ...tagsSagas
    ])
  }