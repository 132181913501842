import React, { Component } from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableWithServerSizePagination from '../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import * as deliveryConfirmationActions from '../../../store/actions/deliveryConfirmationActions';
import * as userActionCreators from '../../../store/actions/User';
import * as deliveryConfirmationFormActions from '../../../store/actions/deliveryConfirmationFormActions';
import * as domainsActions from '../../../store/actions/domains';
import history from '../../../history';
import DataExportContainer from '../../../containers/DataExport/DataToExcelContainer';
import { canFunction } from '../../../config/Can';
import * as rules from '../../../config/Rules';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles'
import QuickEditModal from './QuickEditModal';

import Dialog from '../../../components/Dialog/Dialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import monthsLong from '../../../constants/monthsLong';
import resourceTypes from '../../../constants/resourceTypes';
import TextArea from '../../../components/Input/TextArea';
import externalDcStatuses from '../../../constants/dcStatusesExternal';
import internalDcStatuses from '../../../constants/dcStatusesInternal';
import {isVendorTypeExternal} from '../../../utils/typesAssertions';
import vendorTypes, {vendorTypeEnums} from '../../../constants/vendorTypes';
import {dcStatusesEnums} from '../../../constants/dcStatuses';
import excelTypes from '../../../constants/excelTypes';
import {getEmptyComment} from '../../../utils/commentUtils'

const placeholderFnc = () => {}
const monthsSuggestions = monthsLong.map((month, index) => {
    return {
        label: month,
        value: index +1
    }
})
const exportToExcelConfig = {
    'Activity Name': excelTypes.Text,
    'Actuals approval date': excelTypes.Date,
    'Actuals approved by': excelTypes.Text,
    'Actuals per diem value': excelTypes.DecimalNumber,
    'Actuals submission date': excelTypes.Date,
    'Actuals submitted by': excelTypes.Text,
    'Actuals unit value': excelTypes.DecimalNumber,
    'Assignment Site': excelTypes.Text,
    'Created by': excelTypes.Text,
    'Created on': excelTypes.Date,
    'Currency': excelTypes.Text,
    'DC ID': excelTypes.WholeNumber,
    'DC URL': excelTypes.Text,
    'DC Unique ID': excelTypes.WholeNumber,
    'Deliverable': excelTypes.Text,
    'Delivery Confirmation Approver': excelTypes.Text,
    'Grade': excelTypes.Text,
    'Last modified by': excelTypes.Text,
    'Last modified on': excelTypes.Date,
    'Month': excelTypes.Text,
    'Overtime125': excelTypes.DecimalNumber,
    'Overtime150': excelTypes.DecimalNumber,
    'Overtime175': excelTypes.DecimalNumber,
    'Overtime200': excelTypes.DecimalNumber,
    'PO Number': excelTypes.Text,
    'Per Diem Rate': excelTypes.DecimalNumber,
    'Planned Value': excelTypes.DecimalNumber,
    'Platform': excelTypes.Text,
    'Rejection Reason': excelTypes.DecimalNumber,
    'Resource Type': excelTypes.Text,
    'Status': excelTypes.Text,
    'Supplier Name': excelTypes.Text,
    'Supplier Representative': excelTypes.Text,
    'Unit Price': excelTypes.DecimalNumber,
    'Year': excelTypes.WholeNumber
}
const years = [];
const currentYear = new Date().getFullYear();
years.push({label: currentYear -2, value: currentYear -2});
years.push({label: currentYear -1, value: currentYear -1});
years.push({label: currentYear, value: currentYear});
for (let i = 1; i <= 4; i++) {
    const year = currentYear + i;
    years.push({label: year, value: year});
}

const approveMy = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 100,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "Planned amount",
        width: 140,
        dataProperty: 'plannedAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual amount",
        width: 140,
        dataProperty: 'actualAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of units",
        width: 180,
        dataProperty: 'plannedUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of per diems",
        width: 180,
        dataProperty: 'plannedPerDiem',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of units",
        width: 180,
        dataProperty: 'actualUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of per diems",
        width: 180,
        dataProperty: 'actualPerDiemRate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Overtime",
        width: 80,
        dataProperty: 'hasOvertime',
        isVisible: true
    }
]
const viewAllDc = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 100,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "Planned amount",
        width: 140,
        dataProperty: 'plannedAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual amount",
        width: 140,
        dataProperty: 'actualAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of units",
        width: 180,
        dataProperty: 'plannedUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of per diems",
        width: 180,
        dataProperty: 'plannedPerDiem',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of units",
        width: 180,
        dataProperty: 'actualUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of per diems",
        width: 180,
        dataProperty: 'actualPerDiemRate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Overtime",
        width: 80,
        dataProperty: 'hasOvertime',
        isVisible: true
    }
]

const toBeProcessed = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 100,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "DC Approver",
        width: 140,
        dataProperty: 'approver',
        isVisible: true
    },
    {
        header: "Last Modified date",
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "EPPM Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "EPPM ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    }
]
const viewAllBo = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 100,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "DC Approver",
        width: 140,
        dataProperty: 'approver',
        isVisible: true
    },
    {
        header: "Last Modified date",
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "EPPM Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "EPPM ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    }
]
const viewAllDcBo = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 100,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "DC Approver",
        width: 140,
        dataProperty: 'approver',
        isVisible: true
    },
    {
        header: "Planned amount",
        width: 140,
        dataProperty: 'plannedAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual amount",
        width: 140,
        dataProperty: 'actualAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of units",
        width: 180,
        dataProperty: 'plannedUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of per diems",
        width: 180,
        dataProperty: 'plannedPerDiem',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of units",
        width: 180,
        dataProperty: 'actualUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of per diems",
        width: 180,
        dataProperty: 'actualPerDiemRate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Overtime",
        width: 80,
        dataProperty: 'hasOvertime',
        isVisible: true
    },
    {
        header: "Last Modified date",
        width: 140,
        dataProperty: 'lastModifiedDate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "EPPM Name",
        width: 140,
        dataProperty: 'eppmName',
        isVisible: true
    },
    {
        header: "EPPM ID",
        width: 140,
        dataProperty: 'eppmUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX ID",
        width: 140,
        dataProperty: 'leanIxUniqueId',
        isVisible: true
    },
    {
        header: "LeanIX Name",
        width: 140,
        dataProperty: 'leanIxName',
        isVisible: true
    }
]
const supplier = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'Platform',
        dataProperty: 'domain',
        width: 110,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 110,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "DC Approver",
        width: 140,
        dataProperty: 'approver',
        isVisible: true
    },
    {
        header: "Planned amount",
        width: 140,
        dataProperty: 'plannedAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual amount",
        width: 140,
        dataProperty: 'actualAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of units",
        width: 180,
        dataProperty: 'plannedUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of per diems",
        width: 180,
        dataProperty: 'plannedPerDiem',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of units",
        width: 180,
        dataProperty: 'actualUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of per diems",
        width: 180,
        dataProperty: 'actualPerDiemRate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Overtime",
        width: 80,
        dataProperty: 'hasOvertime',
        isVisible: true
    }
]
const viewMy = [
    {
        header: "DC ID",
        dataProperty: 'id',
        width: 80,
        isVisible: true,
    },
    {
        header: 'Platform',
        dataProperty: 'domain',
        width: 110,
        isVisible: true,
    },
    {
        header: 'DC Status',
        dataProperty: 'status',
        width: 110,
        isVisible: true
    },
    {
        header: "Supplier/Team",
        dataProperty: 'vendor',
        width: 110,
        isVisible: true
    },
    {
        header: 'PO number',
        dataProperty: 'poNumber',
        isVisible: true,
        width: 100
    },
    {
        header: "Activity Name",
        dataProperty: 'activityName',
        width: 300,
        isVisible: true,
    },
    {
        header: "Deliverable",
        dataProperty: 'deliverable',
        width: 110,
        isVisible: true,
    },
    {
        header: 'Resource Type',
        dataProperty: 'resourceType',
        width: 150,
        isVisible: true
    },
    {
        header: "Profile/Grade",
        dataProperty: 'grade',
        width: 110,
        isVisible: true,
    },
    {
        header: "Month",
        dataProperty: 'month',
        width: 100,
        isVisible: true,
    },
    {
        header: "Year",
        dataProperty: 'year',
        width: 70,
        isVisible: true
    },
    {
        header: "Supplier Representative",
        width: 140,
        dataProperty: 'supplierRepresentative',
        isVisible: true
    },
    {
        header: "DC Approver",
        width: 140,
        dataProperty: 'approver',
        isVisible: true
    },
    {
        header: "Planned amount",
        width: 140,
        dataProperty: 'plannedAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual amount",
        width: 140,
        dataProperty: 'actualAmount',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of units",
        width: 180,
        dataProperty: 'plannedUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Planned number of per diems",
        width: 180,
        dataProperty: 'plannedPerDiem',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of units",
        width: 180,
        dataProperty: 'actualUnitPrice',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Actual number of per diems",
        width: 180,
        dataProperty: 'actualPerDiemRate',
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Overtime",
        width: 80,
        dataProperty: 'hasOvertime',
        isVisible: true
    }
]
const styles = {

}
let defaultColumns = supplier;

const mapStateToProps = (state) => {
    let role = 0;
    if (history.location.pathname.indexOf("approve") !== -1) {
        defaultColumns = approveMy
        role = 0;
    }
    if (history.location.pathname.indexOf("viewAll") !== -1) {
        if (state.user.roles.indexOf("backOffice") !== -1 || state.user.roles.indexOf("admin") !== -1) {
            role = role + 5;
            defaultColumns = viewAllBo
        }
        if (state.user.roles.indexOf("dcApprover") !== -1) {
            role = role + 1;
            defaultColumns = viewAllDc;
        }
    }
    if (role === 6) {
        defaultColumns = viewAllDcBo;
    }
    if (history.location.pathname.indexOf("submit") !== -1) {
        defaultColumns = supplier;
        role = 2;
    }
    if (history.location.pathname.indexOf("viewMy") !== -1) {
        defaultColumns = viewMy;
        role = 3;
    }
    if (history.location.pathname.indexOf("toBeProcessed") !== -1) {
        defaultColumns = toBeProcessed
        role = 4;
    }
    const columns = state.deliveryConfirmation.columns && state.deliveryConfirmation.columns.length !== 0 ? state.deliveryConfirmation.columns : defaultColumns;
    let domains = [];
    if (history.location.pathname.indexOf("viewMy") !== -1) {
        domains = [...(state.user.userDomains['viewMy'] || [])];
    } else if (history.location.pathname.indexOf("submit") !== -1) {
        domains = [...(state.user.userDomains['submit'] || [])];
    } else if (history.location.pathname.indexOf("approve") !== -1) {
        domains = [...(state.user.userDomains['approve'] || [])];
    } else {
        domains = [...state.domains.domains];
    }
    domains.push({name:"view all", id: "view all"});
    return {
        role: role,
        resourceTypesSuggestions: state.resourceTypeVendorType.resourceTypes.filter((res) => {
            if (!canFunction('',rules.IS_BACKOFFICE_OR_ADMIN)) {
                return isVendorTypeExternal(res.vendorType);
            } else {
                return true;
            }
        }).map((item) => {
            return {
                label: resourceTypes[item.resourceType],
                value: item.resourceType
            }
        }),
        tableState: state.table.parsedFilters,
        formLoading: state.deliveryConfirmationForm.loading,
        rejectReason: state.deliveryConfirmation.rejectReason,
        comment: state.deliveryConfirmation.comment,
        rejectModalOpen: state.deliveryConfirmation.rejectModalOpen,
        restartModalOpen: state.deliveryConfirmation.restartModalOpen,
        commentModalOpen: state.deliveryConfirmation.commentModalOpen,
        domains: domains,
        selectedDomain: state.user.selectedDomain,
        columns: columns,
        loading: state.deliveryConfirmation.loading,
        deliveryConfirmationCount: state.deliveryConfirmation.deliveryConfirmations && state.deliveryConfirmation.deliveryConfirmations.itemsCount ? state.deliveryConfirmation.deliveryConfirmations.itemsCount : 0,
        deliveryConfirmations: state.deliveryConfirmation.deliveryConfirmations && state.deliveryConfirmation.deliveryConfirmations.items ? state.deliveryConfirmation.deliveryConfirmations.items : [],
        currentPage: state.deliveryConfirmation.deliveryConfirmations && state.deliveryConfirmation.deliveryConfirmations.currentPage ? state.deliveryConfirmation.deliveryConfirmations.currentPage : 1,
        submitAndApproveModalOpen: state.deliveryConfirmationForm.submitAndApproveModalOpen,
        dcNumber: state.deliveryConfirmationForm.dcNumber,
        tabLoading: state.domains.loading || state.user.loading,
        errors: state.deliveryConfirmationForm.errors,
        dcId: state.deliveryConfirmation.selectedDc,
    }
};
  
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
          let params = {};
          if (payload.queryParameters) {
            params = payload.queryParameters
            } 
        dispatch(deliveryConfirmationActions.getDeliveryConfirmationColumnsStarted({domain: payload.domain.name, initial: true, role: payload.role}));
        dispatch(deliveryConfirmationActions.getDeliveryConfirmationsStarted({params: params, initial: true, domain: payload.domain}));
      },
      getDomains: () => 
        dispatch(domainsActions.getDomainsStarted()),
      updateColumns: (payload) => {
        dispatch(deliveryConfirmationActions.updateDeliveryConfirmationColumnsStarted(payload));
      },
      getDeliveryConfirmations: (payload) => {
        dispatch(deliveryConfirmationActions.getDeliveryConfirmationsStarted(payload));
      },
      selectDomain: (payload) => {
        dispatch(userActionCreators.selectDomain(payload))
      },
      getColumns: (payload) => {
        dispatch(deliveryConfirmationActions.getDeliveryConfirmationColumnsStarted(payload))
      },
      deliveryConfirmationCommentDcModalChanged: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationCommentDcModalChanged(payload))
      },
      deliveryConfirmationRejectDcModalChanged: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationRejectDcModalChanged(payload))
      },
      deliveryConfirmationSelectedDcChanged: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationSelectedDcChanged(payload))
      },
      deliveryConfirmationApproveDc: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationApproveDc(payload));
      },
      deliveryConfirmationRestartDc: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationRestartDc(payload));
      },
      deliveryConfirmationProcessingNADc: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationProcessingNADc(payload));
      },
      deliveryConfirmationRestartModalChanged: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationRestartDcModalChanged(payload));
      },
      deliveryConfirmationRejectDc: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationRejectDc(payload))
      },
      deliveryConfirmationCommentDc: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationCommentDc(payload))
      },
      deliveryConfirmationProcessDc: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationProcessDc(payload))
      },
      generatePdf: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationGeneratePdf(payload))
      },
      deliveryConfirmationQuickEditModalChanged: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationQuickEditModalChanged(true))
      },
      deliveryConfirmationRejectReasonChanged: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationRejectReasonChanged(payload))
      },
      deliveryConfirmationCommentTextChanged: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationCommentTextChanged(payload))
      },
      initializeDcFormData: (payload) => {
          dispatch(deliveryConfirmationFormActions.initializeDeliveryConfirmationFormStarted(payload))
      },
      deliveryConfirmationViewerFiltersChanged: (payload) => {
          dispatch(deliveryConfirmationActions.deliveryConfirmationViewerFiltersChanged(payload));
      },
      handleModalSubmitAndApproveButtonPressed: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActionButtonClicked('submitAndApproveModal'));
      },
      closeSubmitAndApproveModal: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormSubmitAndApproveModalStateChanged(false))
      },
      
  });

class DeliveryConfirmationViewerContainer extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    state = {
        copyValue: ""
    }
    openChangelog = (id) => {
        if (canFunction('', rules.NAVIGATE_CHANGELOG_VIEWALL)) {
            history.push('/changelogs/viewAll/'+ id);
        } else {
            history.push('/changelogs/my/'+ id);
        }
        
    }
    openCommentPopup = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationCommentDcModalChanged(true);
    }
    openApprovePopup = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationApproveDc(id);
    }
    openRestartPopup = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationRestartModalChanged(true);
    }
    openProcessPopup = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationProcessDc(id);
    }
    openProcessingNaPopup = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationProcessingNADc(id);
    }
    openRejectModal = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id)
        this.props.deliveryConfirmationRejectDcModalChanged(true);
    }
    openQuickEditModal = (id) => {
        this.props.deliveryConfirmationSelectedDcChanged(id);
        this.props.initializeDcFormData(id);
        this.props.deliveryConfirmationQuickEditModalChanged(true);
    }
    generatePdf = (id) => {
        this.props.generatePdf(id);
    }
    copyLinkToDc = (value) => {
        var textField = document.createElement('textarea')
        textField.innerText = 'foo bar baz'
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({
            copyValue: value
        }, () => {
            //TODO use react-copy-to-clipboard
            setTimeout(() => {
                this.myRef.current.select();
                document.execCommand("copy");
            });
        })

    }

    isApproveView = () => {
        return this.props.location.pathname.indexOf('/approve') !== -1;
    }
    isViewAll = () => {
        return this.props.location.pathname.indexOf('/viewAll') !== -1;
    }
    isBo = () => {
        return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
    }
    getStatuses = () => {
        if (!canFunction('',rules.IS_BACKOFFICE_OR_ADMIN)) {
            return externalDcStatuses.map((status, index) => {
                return {
                    label: status,
                    value: index
                }
            })
        } else {
            return externalDcStatuses.map((status, index) => {
                return {
                    label: status + ((internalDcStatuses[parseInt(index)].length !== 0 && internalDcStatuses[parseInt(index)] !== status) ? ` / ${internalDcStatuses[parseInt(index)]}` : ""),
                    value: index
                }
            })
        }
    }

    componentDidMount() {
        const selectedDomain = this.props.selectedDomain;
        const tableState = this.props.tableState[this.getTableName(this.props.selectedDomain)];
        if (tableState) {
            let domain = selectedDomain;
            const queryParameters = {...tableState};
            this.props.loadView({
                queryParameters: queryParameters,
                domain: domain,
                role: this.props.role
            });
        } else {
            this.props.loadView({
                domain: selectedDomain,
                role: this.props.role
            });
        }
    }

    handleEdit = (data, e) => {
        if (e && e.button === 2) {
            return;
        }
        if (e && (e.ctrlKey || e.button === 1)) {
            window.open(window.location.origin+this.props.location.pathname + "/" + data.id, "_blank"); 
        } else {
            history.push(this.props.location.pathname + "/" + data.id);
        }
    }
    handleChangeDomain = (e, index) => {
        this.props.selectDomain(this.props.domains[parseInt(index)]);
        this.props.getColumns({domain: this.props.domains[parseInt(index)].name, role: this.props.role});
        const tableState = this.props.tableState[this.getTableName(this.props.domains[parseInt(index)])];
        if (tableState) {
            const domain = this.props.domains[parseInt(index)];
            const queryParameters = {...tableState};
            this.props.getDeliveryConfirmations({params: queryParameters, domain});
        } else {
            this.props.getDeliveryConfirmations({ params: {},domain: this.props.domains[parseInt(index)]});
        }
    }

    onFetchData = (data, wait) => {
        const domain = this.props.selectedDomain;
        const queryParameters = data;
        this.props.getDeliveryConfirmations({params: queryParameters, wait: wait, domain});
    }
    getTableName = (selectedDomain) => {
        return `${this.props.location.pathname}DC${selectedDomain && selectedDomain.name !=="view all" ? `-${selectedDomain.id}` : ""}`
    }
    getUrlStart = () => {
        let urlStart;
        if (history.location.pathname.indexOf("viewMy") !== -1) {
            urlStart = "/MyDcs";
        }
        if (history.location.pathname.indexOf("submit") !== -1) {
            urlStart = "/ToSubmit";
        }
        if (history.location.pathname.indexOf("approve") !== -1) {
            urlStart = "/ToApprove";
        }
        if (history.location.pathname.indexOf("toBeProcessed") !== -1) {
            urlStart = "/ToBeProcessed"
        }
        if (history.location.pathname.indexOf("viewAll") !== -1) {
            urlStart=""
        }
        return urlStart;
    }
    getFileName = () => {
        let fileName = "DeliveryConfirmation-";
        if (history.location.pathname.indexOf("viewMy") !== -1) {
            fileName += "ViewMyDCs";
        }
        if (history.location.pathname.indexOf("submit") !== -1) {
            if (canFunction('', rules.NAVIGATE_DELIVERY_FILL_IN)) {
                fileName += "FillInMyDCs";
            } else {
                fileName += "SubmitMyDCs"
            }
        }
        if (history.location.pathname.indexOf("approve") !== -1) {
            fileName += "ApproveMyDCs";
        }
        if (history.location.pathname.indexOf("toBeProcessed") !== -1) {
            fileName += "ToBeProcessed"
        }
        if (history.location.pathname.indexOf("viewAll") !== -1) {
            fileName +="ViewAll"
        }
        return fileName;
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({domain: this.props.selectedDomain.name, columns: payload, role: this.props.role});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({domain: this.props.selectedDomain.name, columns: payload, role: this.props.role});
    }


    createActionMenu = (data) => {
            const matchSupplier = () => {
                return data.isSupplier
            }
            const matchApprover = () => {
                return data.isApprover
            }
            const buttons = [
                'Comment',
                'Quick Edit',
                'generatePdf',
                'restart',
                'process',
                'approve',
                'reject',
                'processingNotApplicable'
            ];
            const buttonsOnStatusesCommon = {
                [dcStatusesEnums.ToBeFilledIn]: {
                    'Comment': true,
                    'Quick Edit': true,
                    'processingNotApplicable': true
                },
                [dcStatusesEnums.ToBeCorrected]: {
                    'Comment': true,
                    'Quick Edit': true,
                    'processingNotApplicable': true
                },
                [dcStatusesEnums.Completed]: {
                    'Comment': true,
                    'generatePdf': true,
                    'restart': true,
                    'processingNotApplicable': true,
                },
                [dcStatusesEnums.ProcessingNotApplicable]: {
                    'Comment': true,
                    'restart': true,
                }
            }
            const internalButtons = {
                ...buttonsOnStatusesCommon
            }
            internalButtons[dcStatusesEnums.ToBeApproved] = {
                'Comment': true,
                'Quick Edit': true,
                'restart': true,
                'processingNotApplicable': true,
                'process': true,
            };
            const externalButtons = {
                ...buttonsOnStatusesCommon
            }
            externalButtons[dcStatusesEnums.ToBeCorrected] = {
                ...buttonsOnStatusesCommon[dcStatusesEnums.ToBeCorrected],
                submitAndApprove: true
            }
            externalButtons[dcStatusesEnums.ToBeFilledIn] = {
                ...buttonsOnStatusesCommon[dcStatusesEnums.ToBeFilledIn],
                submitAndApprove: true
            }
            externalButtons[dcStatusesEnums.Approved] = {
                'Comment': true,
                'generatePdf': true,
                'restart': true,
                'process': true,
                'processingNotApplicable': true,
            }
            externalButtons[dcStatusesEnums.ToBeApproved] = {
                'Comment': true,
                'Quick Edit': true,
                'restart': true,
                'processingNotApplicable': true,
                'approve': true,
                'reject': true,
            };
            const buttonsOnStatuses = {
                [vendorTypeEnums.Internal]: internalButtons,
                [vendorTypeEnums.External] : externalButtons,
            }
            const matchInternalExceptions = (button) => {
                if (isVendorTypeExternal(data.vendorType)) {
                    return true;
                }
                if (!canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) {
                    return false;
                }
                return true;
            }
            const matchExceptionsConditions = (button) => {
                if (!isVendorTypeExternal(data.vendorType)) {
                    return true;
                }
                if (button === 'Comment' && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) {
                    if (data.statusEnum === dcStatusesEnums.ToBeFilledIn) {
                        return matchApprover() || matchSupplier();
                    }
                    if (data.statusEnum === dcStatusesEnums.ToBeCorrected) {
                        return matchSupplier();
                    }
                    if (data.statusEnum === dcStatusesEnums.ToBeApproved) {
                        return matchApprover() || matchSupplier();
                    }
                    return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
                }
                if (button === 'generatePdf') {
                    return data.statusEnum === dcStatusesEnums.Completed || (data.statusEnum === dcStatusesEnums.Approved && canFunction('', rules.DC_edit_External));
                }
                if (button === 'approve' || button === 'reject') {
                    return matchApprover();
                }
                if (button === 'processingNotApplicable' && matchApprover() && data.statusEnum === dcStatusesEnums.ToBeFilledIn ) {
                    return true
                }
                if (button === 'processingNotApplicable') {
                    return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
                }
                if (button === 'restart') {
                    return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
                }
                if (button === 'process') {
                    return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN);
                }
                return true;
            }
            const matchSupplierException = (button) => {
                if (button === 'Quick Edit'|| button === 'generatePdf' || button === 'Comment') {
                    return matchSupplier();
                }
                return false;
            }
            const getButtons = () => {
                if ((!data.vendorType && !isVendorTypeExternal(data.vendorType)) || (!data.statusEnum && data.statusEnum !== 0)) {
                    return null;
                }
                return buttons.map((button) => {
                    if (buttonsOnStatuses[data.vendorType][data.statusEnum][button]
                        && ((!isVendorTypeExternal(data.vendorType) || matchSupplierException(button)) ? true : canFunction('', "DC_" + button+ "_" + vendorTypes[data.vendorType]))
                        && matchExceptionsConditions(button) && matchInternalExceptions(button)) {
                        return button;
                    }
                    return null;
                })
            }
            const filteredButtons = getButtons();
        let items = [
            {onClick: this.handleEdit, label: 'View'}
        ]
        if (data.isApprover || canFunction('', rules.DC_edit_External)) {
            items.push({
                onClick: this.openChangelog.bind(this, data.changelogId), label: 'Open Change Log'
            });
        }
        if (filteredButtons.indexOf('restart') !== -1) {
            items.push({
                onClick: this.openRestartPopup.bind(this, data.id), label: 'Restart'
            });
        }
        if (filteredButtons.indexOf('processingNotApplicable') !== -1) {
            items.push({
                onClick: this.openProcessingNaPopup.bind(this, data.id), label: 'Processing N/A'
            });
        }
        if (filteredButtons.indexOf('process') !== -1) {
            items.push({
                onClick: this.openProcessPopup.bind(this, data.id), label: 'Process'
            });
        }
        if (filteredButtons.indexOf('Comment') !== -1) {
            items.push({
                onClick: this.openCommentPopup.bind(this, data.id), label: 'Comment'
            });
        }
        if (filteredButtons.indexOf('approve') !== -1) {
            items.push({
                onClick: this.openApprovePopup.bind(this, data.id), label: 'Approve'
            });
        }
        if (filteredButtons.indexOf('reject') !== -1) {
            items.push({
                onClick: this.openRejectModal.bind(this, data.id), label: 'Reject'
            });
        }
        if (filteredButtons.indexOf('Quick Edit') !== -1) {
            items.push({
                onClick: this.openQuickEditModal.bind(this, data.id), label: 'Quick Edit'
            });
        }
        if (filteredButtons.indexOf('generatePdf') !== -1) {
            items.push({
                onClick: this.generatePdf.bind(this, data.id), label: 'Generate PDF'
            });
        }
        items.push({
            onClick: this.copyLinkToDc.bind(this, window.location.origin+"/deliveryconfirmation/view/" + data.id), label: 'Copy Link to DC'
        });
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={items}
                rowData={data}
            />
        )
    }
    closeRejectReasonModal = () => {
        this.props.deliveryConfirmationRejectDcModalChanged(false);
        this.props.deliveryConfirmationRejectReasonChanged(getEmptyComment());
    }
    closeCommentModal = () => {
        this.props.deliveryConfirmationCommentDcModalChanged(false);
        this.props.deliveryConfirmationCommentTextChanged(getEmptyComment());
    }
    //domains is required when view all is selected, because then all exense types should be available
    render() {
        let selectedDomain = this.props.selectedDomain;
        let cols = [...this.props.columns]
        if (selectedDomain && selectedDomain.name ==="view all") {
            const item = cols.find((col) => {
                return col.header === "Platform";
            });
            const index = cols.indexOf(item);
            if (index === -1) {
                cols.unshift({
                    header: "Platform",
                    dataProperty: 'domain',
                    width: 140,
                    isVisible: true,
                })
            }
        }
        let minWidth = 0;
        cols.forEach((column) => {
            if (column.isVisible) {
                minWidth += column.width;
            }
        });
        minWidth +=40;
        return (
            <React.Fragment>
                {canFunction('', rules.IS_SUPPLIER) ? null : 
                    <Tabs onTabChanged={this.handleChangeDomain} selectedTab={this.props.selectedDomain} tabs={this.props.domains} loading={this.props.tabLoading}/>
                }
                <div style={{marginRight: 48, marginTop: 32} }>


                <Dialog 
                    open={this.props.rejectModalOpen}
                    onClose={this.closeRejectReasonModal}
                    onSubmit={this.props.deliveryConfirmationRejectDc.bind(this, this.props.selected)}
                    cancelButtonText="CANCEL"
                    submitButtonText="REJECT"
                    title={"REJECT DC"}
                >
                    <div style={{position: 'relative'}}>
                        <LoadingSpinner loading={this.props.formLoading}></LoadingSpinner>
                        <TextArea
                            label="Reject reason"
                            value={this.props.rejectReason}
                            onChange={this.props.deliveryConfirmationRejectReasonChanged}
                            className={this.props.classes.textArea} 
                            minWidth={512}
                        ></TextArea>

                    </div>
                </Dialog>
                <Dialog 
                    open={this.props.submitAndApproveModalOpen}
                    onClose={this.props.closeSubmitAndApproveModal}
                    onSubmit={this.props.handleModalSubmitAndApproveButtonPressed}
                    cancelButtonText="NO"
                    submitButtonText="YES"
                    title={"DC " + this.props.dcNumber}
                >
                    <span>Do you really want to submit and approve?</span>
                </Dialog>

                <Dialog 
                    open={this.props.restartModalOpen}
                    onClose={this.props.deliveryConfirmationRestartModalChanged.bind(this, false)}
                    onSubmit={this.props.deliveryConfirmationRestartDc.bind(this, this.props.dcId)}
                    cancelButtonText="CANCEL"
                    submitButtonText="RESTART"
                    title={"RESTART DC"}
                >
                    <div style={{position: 'relative'}}>
                        <LoadingSpinner loading={this.props.formLoading}></LoadingSpinner>
                        <TextArea
                            label="restart reason"
                            errorText={this.props.errors.comment}
                            value={this.props.comment}
                            onChange={this.props.deliveryConfirmationCommentTextChanged}
                            className={this.props.classes.textArea} 
                            minWidth={512}
                        ></TextArea>
                    </div>
                </Dialog>

                <Dialog 
                    open={this.props.commentModalOpen}
                    onClose={this.closeCommentModal}
                    onSubmit={this.props.deliveryConfirmationCommentDc}
                    cancelButtonText="CANCEL"
                    submitButtonText="COMMENT"
                    title={"COMMENT DC"}
                >
                    <div style={{position: 'relative'}}>
                        <LoadingSpinner loading={this.props.formLoading}></LoadingSpinner>
                        <TextArea
                            label="Comment"
                            value={this.props.comment}
                            onChange={this.props.deliveryConfirmationCommentTextChanged}
                            className={this.props.classes.textArea} 
                            minWidth={512}
                        ></TextArea>
                    </div>
                </Dialog>
                        <input
                            value={this.state.copyValue}
                            ref={this.myRef}
                            style={{position: 'absolute',left: -9999, zIndex: 2000}}
                            tabIndex="-1"
                            id={"dclist"}
                            onChange={placeholderFnc}
                    />
                    <QuickEditModal />
                    <DataExportContainer 
                        url={`api/dc/export${this.getUrlStart()}${this.props.selectedDomain && this.props.selectedDomain.name !=="view all" ? `/?domainId=${this.props.selectedDomain.id}` : ""}`}
                        tableName={this.getTableName(this.props.selectedDomain)}
                        fileName={this.getFileName()}
                        fieldsTypes={exportToExcelConfig}
                     />
                    <DataTableWithServerSizePagination
                        sortable
                        navigateToDetail={this.handleEdit}
                        tableName={this.getTableName(this.props.selectedDomain)}
                        saveFilterState={this.props.deliveryConfirmationViewerFiltersChanged}
                        key={this.props.selectedDomain.name}
                        minWidth={minWidth}
                        customColumnsWidthEnabled={true}
                        loading={this.props.loading}
                        count={this.props.deliveryConfirmationCount}
                        general={{}}
                        columns={cols}
                        onFetchData={this.onFetchData}
                        data={this.props.deliveryConfirmations}   
                        actionMenu={this.createActionMenu}
                        onColumnsOrderChanged={this.onColumnOrderChanged}
                        onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                        currentPage={this.props.currentPage}
                        customFilters={{
                            status: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="status"
                                        label="Status"
                                        small
                                        width="100%"
                                        minWidth={220}
                                        suggestions={this.getStatuses()}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            month: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="month"
                                        label="Month"
                                        small
                                        width="100%"
                                        minWidth={120}
                                        suggestions={monthsSuggestions}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            resourceType: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="resourceType"
                                        label="Resource Type"
                                        small
                                        width="100%"
                                        minWidth={150}
                                        suggestions={this.props.resourceTypesSuggestions}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            year: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="year"
                                        label="Year"
                                        small
                                        width="100%"
                                        minWidth={100}
                                        suggestions={years}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            hasOvertime: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="hasOvertime"
                                        label="Overtime"
                                        small
                                        width="100%"
                                        minWidth={100}
                                        suggestions={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            }
                        }
                    }
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(DeliveryConfirmationViewerContainer);