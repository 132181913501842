import React from 'react';
import Select from '../../../../components/Input/AutoSelect'
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import Input from '../../../../components/Input/Input';
import {canEdit} from '../../../../utils/budgetPlanEditPrivileges';
import * as rules from '../../../../config/Rules'
import {canFunction} from '../../../../config/Can';

export default formSectionWrapper((props) => {
    return (
        <React.Fragment>
            { canFunction("", rules.EDIT_FORM_TAGS) ?
                <React.Fragment>
                    <Select 
                        label="Tags" 
                        isMulti
                        width="100%" 
                        disabled={!canEdit(props, rules.EDIT_FORM_TAGS)}
                        value={props.tags || ""} 
                        suggestions={props.tagsSuggestions} 
                        handleChangeSingle={props.tagsChanged}
                        className={props.changes.tags ? "input-updated": ""}
                        tooltipText={props.changes.tags}
                    />
                </React.Fragment>
            : null
            }
            {
                canEdit(props, rules.EDIT_BUDGET_PLAN_DOMAIN) ?
                    <Select
                        label="Platform"
                        suggestions={props.domains}
                        handleChangeSingle={props.domainChanged}
                        value={props.domain}
                        errorText={props.errors.domain}
                        className={props.changes && props.changes.domain !== undefined ? "input-updated" : !props.changes && ""}
                        tooltipText={props.changes && props.changes.domain !== undefined ? props.changes.domain : ""}
                    />
                :
                    <Input 
                        label="Platform" 
                        disabled 
                        width="100%" 
                        value={props.domain.label || ""} 
                        className={props.changes && props.changes.domain !== undefined ? "input-updated" : !props.changes && "disabled-form-input"}
                        tooltipText={props.changes && props.changes.domain !== undefined ? props.changes.domain : ""}
                    />    
            }
            {
                canEdit(props, rules.EDIT_BUDGET_PLAN_SUBDOMAIN) ?
                    <Select 
                        label="Sub-platform"
                        suggestions={props.subDomains}
                        handleChangeSingle={props.subDomainChanged}
                        value={props.subDomain}
                        errorText={props.errors.subDomain}
                        className={props.changes && props.changes.subDomain !== undefined ? "input-updated" : !props.changes && ""}
                        tooltipText={props.changes && props.changes.subDomain !== undefined ? props.changes.subDomain : ""}
                    />
                :
                    <Input 
                        label="Sub-platform" 
                        disabled 
                        width="100%" 
                        value={props.subDomain.label || ""} 
                        className={props.changes && props.changes.subDomain !== undefined ? "input-updated" : !props.changes && "disabled-form-input"}
                        tooltipText={props.changes && props.changes.subDomain !== undefined ? props.changes.subDomain : ""}
                    />    
            }


            {
                props.planByCostCenter ?
                    canEdit(props, rules.EDIT_BUDGET_PLAN_COST_CENTER) ?
                        <Select 
                            label="Cost center"
                            suggestions={props.costCenters}
                            handleChangeSingle={props.costCenterChanged}
                            value={props.costCenter}
                            errorText={props.errors.costCenter}
                            className={props.changes && props.changes.costCenter !== undefined ? "input-updated" : !props.changes && ""}
                            tooltipText={props.changes && props.changes.costCenter !== undefined ? props.changes.costCenter : ""}
                        />
                    :
                        <Input 
                            label="Cost center" 
                            disabled 
                            width="100%" 
                            value={props.costCenter.label || ""} 
                            className={props.changes && props.changes.costCenter !== undefined ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.changes && props.changes.costCenter !== undefined ? props.changes.costCenter : ""}
                        /> 
                :
                    null
            }
            {
                canEdit(props, rules.EDIT_BUDGET_PLAN_BUDGET_YEAR) ?
                    <Select
                        label="Budget Year"
                        suggestions={props.budgetYears}
                        handleChangeSingle={props.budgetYearChanged}
                        value={props.budgetYear}
                        errorText={props.errors.budgetYear}
                        className={props.changes && props.changes.budgetYear !== undefined ? "input-updated" : !props.changes && ""}
                        tooltipText={props.changes && props.changes.budgetYear !== undefined ? props.changes.budgetYear : ""}
                    />
                :
                    <Input 
                        label="Budget Year"
                        disabled width="100%"
                        value={props.budgetYear.label || ""} 
                        className={props.changes && props.changes.budgetYear !== undefined ? "input-updated" : !props.changes && "disabled-form-input"}
                        tooltipText={props.changes && props.changes.budgetYear !== undefined ? props.changes.budgetYear : ""}
                    />    
            }

        </React.Fragment>
        
    )
    
}, "Header")