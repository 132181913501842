import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect'

const initialState = {
    domains: [],
    loading: false,
    newItemDomainText: '',
    newItemPlanner: '',
    namesBeingEditedMap: {},
    plannersBeingEditedMap: {},
    selectedItems: {},
    allDomains: []
}

const DomainPickListReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.DOMAINS_LOADING_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.CHANGELOG_FORM_REST_FORM: {
            return {
                ...state,
                domains: state.domains.filter((domain) => {
                    return !domain.additional;
                })
            }
        }
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_DOMAINS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_DOMAINS_FAILED: {
            return {
                ...state,
                loading: false,
                allDomains: [],
                selectedItems: {}
            }
        }
        case actionTypes.GET_ALL_DOMAINS_FINISHED: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                loading: false,
                allDomains: action.payload,
                selectedItems: selectedItems
            }
        }

        case actionTypes.GET_DOMAINS_STARTED: 
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_DOMAINS_FAILED: {
            return {
                ...state,
                loading: false,
                domains: []
            }
        }
        case actionTypes.GET_DOMAINS_FINISHED: {
            return {
                ...state,
                loading: false,
                domains: action.payload
            }
        }
        case actionTypes.ADD_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
                newItemDomainText: "",
                newItemPlanner: "",
                namesBeingEditedMap: {},
                plannersBeingEditedMap: {}
            }
        }
        case actionTypes.ADD_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
                newItemDomainText: "",
                newItemPlanner: "",
                namesBeingEditedMap: {},
                plannersBeingEditedMap: {}
            }
        }      
        case actionTypes.EDIT_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
                newItemDomainText: "",
                newItemPlanner: "",
                namesBeingEditedMap: {},
                plannersBeingEditedMap: {}
            }
        }
        case actionTypes.EDIT_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
                newItemDomainText: "",
                newItemPlanner: "",
                namesBeingEditedMap: {},
                plannersBeingEditedMap: {}
            }
        }
        case actionTypes.DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED: {
            return {
                ...state,
                newItemDomainText: action.payload.target.value
            }
        }
        case actionTypes.DOMAIN_PICKLIST_NEW_ITEM_PLANNER_CHANGED: {
            return {
                ...state,
                newItemPlanner: action.payload.target.value
            }
        }
        case actionTypes.DOMAIN_PICKLIST_EDIT_ITEM_DOMAIN_CHANGED: {
            const itemsBeingEditedCopy = {...state.namesBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                namesBeingEditedMap: itemsBeingEditedCopy
            }
        }
        case actionTypes.DOMAIN_PICKLIST_EDIT_ITEM_PLANNER_CHANGED: {
            const itemsBeingEditedCopy = {...state.plannersBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                plannersBeingEditedMap: itemsBeingEditedCopy
            }
        }
        default:
            return state;
    }
}
const getDomains = (state) => state.domains.domains;
const getAllDomains = (state) => state.domains.allDomains;
export const getDomainsParsed = createSelector([getDomains], (domains) => {
    return domains ? domains.map((domain) => {
            return {
                label: domain.name,
                value: domain.id
            }
    }) : [];
});
export const getAllDomainsParsedWithDeletedFilterd = createSelector([getAllDomains], (domains) => {
    return domains ? domains.filter((domain) => !domain.isDeleted).map((domain) => {
            return {
                label: domain.name,
                value: domain.id
            }
    }) : [];
});
export const getAllDomainsParsed = createSelector([getAllDomains], (domains) => {
    return domains ? domains.map((domain) => {
            return {
                label: domain.name,
                value: domain.id
            }
    }) : [];
});
export const getDomainsMap = createSelector([getDomains], (domains) => {
    const domainsMap = {}
    if (domains) {
        domains.forEach((domain) => {
            domainsMap[domain.id] = domain.name;
          });
    }
    return domainsMap;
})
export default DomainPickListReducer;