import { put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as importMasterDataActions from '../store/actions/importMasterData';
import axios from '../axios';
import { axiosForFileUploadFunction } from '../axios';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import formatDate from '../utils/dateFormatter';

function* getImportMasterDataColumnsConfig(action) {
    let columns = yield localStorage.getItem("importmasterdata-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(importMasterDataActions.getImportMasterDataColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(importMasterDataActions.getImportMasterDataColumnsFailed())
    }
}

function* saveColumnsConfig(action) {
    yield localStorage.setItem("importmasterdata-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getImportMasterDataColumnsConfig(action)
    yield put(importMasterDataActions.updateImportMasterDataColumnsFinished())
}

function* getFiles(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(importMasterDataActions.importMasterDataLoadingSpinnerChanged(true));
        let data = yield axios.get('api/ImportMetaData', {params: encodeParamObject(action.payload.params)});
        const newData = data.data.items.map((item) => {
            const dateString = formatDate(item.createdDateTimeUtc)
            return {
                ...item,
                createdDateTimeUtc: dateString,
                
            }
        })
        data.data.items = newData;
        yield put(importMasterDataActions.getImportMasterDataListFinished(data.data));
    } catch (error) {
        yield put(importMasterDataActions.getImportMasterDataListFailed(error));
    }
}

function* uploadFileSaga(action) {
    try {
        yield put(importMasterDataActions.importMasterDataLoadingSpinnerChanged(true));
        const file = action.payload.target.files[0]
        const userEmail = yield select(state => state.user.username);
        const dataForFileUpload = yield axios.get('s3/documents/uploadUrlForImport/' + encodeURIComponent(file.name));
        const axiosInstanceForFileUpload = axiosForFileUploadFunction(dataForFileUpload.data.uploadUrl);
        yield axiosInstanceForFileUpload.put('', file
        , {
            headers: {
                'x-amz-meta-user-email': userEmail
            }
        }
        );
        const filters = yield select(state => state.importMasterData.filters);
        yield* getFiles({payload: {params: filters}});
    } catch (error) {
        yield put(importMasterDataActions.importMasterDataLoadingSpinnerChanged(false));
        yield put(errorDialogActions.openErrorDialog('There was an error while uploading CSV. Please try again later'))
    }
}
export default [
    takeEvery(actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_STARTED, getImportMasterDataColumnsConfig),
    takeEvery(actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_STARTED, saveColumnsConfig),
    takeLatest(actionTypes.GET_IMPORT_MASTERDATA_LIST_STARTED, getFiles),
    takeEvery(actionTypes.IMPORT_MASTERDATA_FILE_UPLOAD_STARTED, uploadFileSaga)
];
