import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import Comments from './Comments';

const mapStateToProps = (state) => {
    return {
        comment: state.resourceModelForm.resourceModel.comment,
        comments: state.resourceModelForm.resourceModel && state.resourceModelForm.resourceModel.comments ?  state.resourceModelForm.resourceModel.comments : [],
    }
};

const mapDispatchToProps = (dispatch) => ({
    commentChanged: (payload) => 
        dispatch(resourceModelFormActions.resourceModelFormCommentChanged(payload))        
    }
);

class CommentsContainer extends Component {
    render () {
        return (
            <Comments {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer)