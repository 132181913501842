import React from 'react';
import Input from '../../../components/Input/Input';
import formSectionWrapper from '../ChangelogForm/components/FormSectionWrapper'

export default formSectionWrapper((props) => {
    return (
        <React.Fragment>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Planner" 
                    width="100%" 
                    disabled 
                    value={props.planner} 
                    className={props.changes && props.changes.planner ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes && props.changes.planner ? props.changes.planner : null}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Supplier Name" 
                    width="100%"
                    disabled 
                    value={props.supplier || ''} 
                    className={props.changes && props.changes.supplier ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes && props.changes.supplier ? props.changes.supplier : null}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="DC Approver" 
                    width="100%" 
                    disabled 
                    value={props.approver.name || ''} 
                    className={props.changes && props.changes.approver ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes && props.changes.approver ? props.changes.approver : null}
                />
            </div>
            {props.supplierRepresentative ?
            <div style={{position: 'relative'}}>
                <Input
                    label="Supplier Representative"
                    width="100%"
                    disabled
                    value={props.supplierRepresentative.name || ''}
                    className={props.changes && props.changes.supplierRepresentative ? "input-updated" : "disabled-form-input"}
                    tooltipText={props.changes && props.changes.supplierRepresentative ? props.changes.supplierRepresentative : null}
                />
            </div> : null
            }
        </React.Fragment>
    )
}, "Roles");