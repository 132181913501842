import React from 'react';
import ErrorDialogContainer from './containers/ErrorDialogContainer/ErrorDialogContainer';
  
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         errorFound: false
        };
    }

    static getDerivedStateFromError(error) {
        return { errorFound: true };
      }
  
    componentDidCatch(error, info) {
        this.setState({
            errorFound: true
           });
        this.props.dispatchError();
    }
  
    render() {
        if (this.state.errorFound) {
            return (
                <ErrorDialogContainer />
            );
        }
      return this.props.children;
    }
  }
  export default ErrorBoundary;