import React, { Component } from 'react';
import DataTableWithServerSizePagination from '../../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import history from '../../../../history';
import * as activityActions from '../../../../store/actions/activity';
import DataExportContainer from '../../../../containers/DataExport/DataExportContainer';
import AutoSelect from '../../../../components/Input/AutoSelectWithDataOnChange';
import activityTypes from '../../../../constants/activityTypes';
import regionTypes from '../../../../constants/regionTypes';

const activityTypeSuggestios = activityTypes.map((item, index) => {
    return {
        label: item,
        value: index
    }
})

const regionTypeSuggestions = regionTypes.map((item, index) => {
    return {
        label: item,
        value: index
    }
})

const defaultColumns = [
    {
        header: "Activity ID",
        dataProperty: 'id',
        width: 100,
        isVisible: true,
    },
    {
        header: "Activity name",
        dataProperty: 'name',
        width: 300,
        isVisible: true,
    },
    {
        header: "Activity type",
        dataProperty: "activityType",
        width: 120,
        isVisible: true
    },
    {
        header: "Region type",
        dataProperty: 'regionType',
        width: 120,
        isVisible: true
    },
    {
        header: "Platform",
        dataProperty: "domain",
        width: 180,
        isVisible: true
    },
    {
        header: "Sub-platform",
        dataProperty: 'subDomain',
        width: 180,
        isVisible: true
    },
    {
        header: "Cost center",
        dataProperty: 'costCenter',
        width: 120,
        isVisible: true
    },
    {
        header: "Work Id",
        dataProperty: 'workId',
        width: 100,
        isVisible: true,  
    },
    {
        header: "Source Data Link",
        dataProperty: 'sourceDataLink',
        width: 250,
        isVisible: true,
    },
    {
        header: "Project Number",
        dataProperty: 'projectNumber',
        width: 100,
        isVisible: true
    },
    {
        header: "Demand Number",
        dataProperty: 'demandNumber',
        width: 100,
        isVisible: true
    },
    {
        header: "Legacy Number",
        dataProperty: 'legacyNumber',
        width: 100,
        isVisible: true
    },
]
const mapStateToProps = (state) => {
    const columns = state.activity.columns && state.activity.columns.length !== 0 ? state.activity.columns : defaultColumns;
    let activities = [];
    let inactive = {};
    if (state.activity.activities && state.activity.activities.items) {
        activities = state.activity.activities.items.map((item) => {
            return {
                ...item,
            }
        })
        inactive = state.activity.activities.inactiveMap;
    }
    return {
        tableState: state.table.parsedFilters,
        columns: columns,
        loading: state.activity.loading,
        activities: activities,
        inactive: inactive,
        currentPage: state.activity.activities && state.activity.activities.currentPage ? state.activity.activities.currentPage : 1,
        count: state.activity.activities && state.activity.activities.itemsCount ? state.activity.activities.itemsCount : 0,
    }
};
  
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(activityActions.getActivitiesListStarted({params: payload.queryParameters}));
        dispatch(activityActions.getActivitiesColumnsStarted(payload));
      },
      updateColumns: (payload) => {
        dispatch(activityActions.updateActivitiesColumnsStarted(payload));
      },
      getColumns: (payload) => {
        dispatch(activityActions.getActivitiesColumnsStarted(payload))
      },
      getActivities: (payload) => {
          dispatch(activityActions.getActivitiesListStarted(payload))
      }
  });

class ActivitesViewer extends Component {

    componentDidMount() {
        const tableState = this.props.tableState[this.getTableName()];
        if (tableState) {
            this.props.loadView({
                queryParameters: tableState,
            });
        } else {
            this.props.loadView({
                wait: false
            });
        }
    }
    handleEditClick(data) {
        history.push(`/masterdata/viewallactivities/${data.id}`);
    }

    onFetchData = (data, wait) => {
        const queryParameters = data;
        this.props.getActivities({params: queryParameters, wait: wait});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({columns: payload});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({columns: payload});
    }
    getTableName = () => {
        return "activities";
    }


    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleEditClick, label: 'Edit'}
                    ]
                }
                rowData={data}
            />
        )
    }
    openLink = (link) => {
        window.open(link, "_blank"); 
    }
    getMinWidth = () => {
        let minWidth = 0;
        this.props.columns.forEach((column) => {
            if (column.isVisible) {
                minWidth += column.width;
            }
        });
        minWidth +=40;
        return minWidth;
    }
    render() {
        return (
            <React.Fragment> 
                <div style={{marginRight: 48, marginTop: 32}}>
                    <DataExportContainer url="api/export/Activities"/>
                    <DataTableWithServerSizePagination
                        tableName={this.getTableName()}
                        minWidth={this.getMinWidth()}
                        customRowCells={{
                            sourceDataLink: (data, id, row) => {
                                return (
                                    <div style={{paddingLeft: 12}}>
                                        { data ?
                                            <span style={{color:"#39acff", cursor:'pointer'}} onClick={this.openLink.bind('', data)}>{data}</span>
                                        : null}
                                    </div>
                                )
                            }
                        }}
                        sortable
                        general={{selectionEnabled: true, canSelectRowProperty: "canSelect", selectionColumnHeader: "Inactive"}}
                        selected={this.props.inactive}
                        customColumnsWidthEnabled={true}
                        loading={this.props.loading}
                        count={this.props.count}
                        columns={this.props.columns}
                        onFetchData={this.onFetchData}
                        data={this.props.activities}   
                        actionMenu={this.createActionMenu}
                        onColumnsOrderChanged={this.onColumnOrderChanged}
                        onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                        currentPage={this.props.currentPage}
                        customFilters={{
                            activityType: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="activityType"
                                        label="Activity type"
                                        small
                                        width="100%"
                                        minWidth={120}
                                        suggestions={activityTypeSuggestios}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            regionType: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="regionType"
                                        label="Region type"
                                        small
                                        width="100%"
                                        minWidth={120}
                                        suggestions={regionTypeSuggestions}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            }
                        }
                    }
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitesViewer);