import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';
import { canFunction } from '../../../../config/Can';
import * as Rules from '../../../../config/Rules';
import Roles from './components/Roles';
import { isVendorTypeExternal } from '../../../../utils/typesAssertions';
import { getDcChanges } from '../../../../store/reducers/DeliveryConfirmationFormReducer';

const mapStateToProps = (state) => {
    return {
        vendor: state.deliveryConfirmationForm.vendor,
        vendorType: state.deliveryConfirmationForm.vendorType,
        supplierRepresentative: state.deliveryConfirmationForm.supplierRepresentative,
        dcApprover: state.deliveryConfirmationForm.dcApprover,
        isEditing: state.deliveryConfirmationForm.editButtonPressed,
        dcState: state.deliveryConfirmationForm.status,
        changelogType: state.deliveryConfirmationForm.vendorType,
        changes: getDcChanges(state)
    }
}
const mapDispatchToProps = (dispatch) => ({
    supplierChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormSupplierRepresentativeChanged(payload)), 
    approverChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormDcApproverChanged(payload))      
})
class RolesContainer extends Component {
    canEdit = () => {
        return canFunction('', Rules.EDIT_DC_FORM_USERS) && this.props.isEditing && this.checkDcStateCondition();
    }
    checkDcStateCondition = () => {
        if (!isVendorTypeExternal(this.props.changelogType)) {
            if (this.props.dcState === 4 || this.props.dcState === 2) {
                return false;
            }
        }
        if (isVendorTypeExternal(this.props.changelogType)) {
            if (this.props.dcState === 4 || this.props.dcState === 3) {
                return false;
            }
        }
        return true;
    }
    render() {
        return (
            <Roles {...this.props} canEdit={this.canEdit}/>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);