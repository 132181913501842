import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import AutoSelect from '../../../components/Input/AutoSelect';
import Input from '../../../components/Input/Input';
import { connect } from 'react-redux';
import * as resourceTypesActionCreators from '../../../store/actions/resourceTypes';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
import vendorTypes from '../../../constants/vendorTypes';
import resourceTypes from '../../../constants/resourceTypes';
const vendorTypesSuggestions = vendorTypes.map((vendorType, index) => {
    return {
        label: vendorType,
        value: index
    }
});


  const mapStateToProps = (state) => {
      return {
        resourceTypes: state.resourceTypeVendorType.resourceTypes,
        loading: state.resourceTypeVendorType.loading
      }
};
  
  const mapDispatchToProps = (dispatch) => ({
    editResourceType: (payload) =>
        dispatch(resourceTypesActionCreators.editResourceTypeByVendorStarted(payload))
  });

class DomainResourceTypePickList extends Component {

    state = {
        filtered: {
        }
    }
    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                let mapper;
                if (key === 'resourceType') {
                    mapper = resourceTypes
                } else {
                    mapper = vendorTypes
                }
                isValid = mapper[row[key]].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) !== -1
            });
            return isValid;
        })
    }

    editResourceType(selectedOption, row) {
        const newItem = {...row};
        newItem.vendorType = selectedOption.value;
        this.props.editResourceType(newItem);
    }
    
    render() {
        const filteredData = this.filterData(this.props.resourceTypes);
        return (
            <div>
                <DataExportContainer url="api/export/resourceTypes/vendorTypeSettings"/>
                <DataTable
                loading={this.props.loading}
                filtered={this.state.filtered}
                general={{}}
                onFilteredChanged={this.onFilteredChanged}
                data={filteredData}  
                customRowCells={{
                    resourceType: (data, id, row) => {
                        return (
                        <Input 
                            width={292} 
                            value={ resourceTypes[data] }
                            disabled
                        />
                        )
                    },
                    vendorType: (data, id, row) => {
                        return (
                            <AutoSelect
                                suggestions={vendorTypesSuggestions}
                                width={292}
                                value={{value: data, label: vendorTypes[data]}}
                                handleChangeSingle={(selectedOption) => {this.editResourceType(selectedOption, row)}}
                            />
                        )
                    },
                }}
                columns={[
                    {
                        header: "Resource Type",
                        dataProperty: "resourceType",
                        isVisible: true
                    },
                    {
                        header: "Vendor Type",
                        dataProperty: "vendorType",
                        isVisible: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainResourceTypePickList);