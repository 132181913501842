import React, { Component } from 'react';
import ActivityData from './ActivityData';
import { connect } from 'react-redux';
import activityTypes from '../../../../constants/activityTypes';
import * as activityFormActions from '../../../../store/actions/activity';
import { getDomainsParsed } from '../../../../store/reducers/DomainsReducer';
import { getSubDomainsPerDomain } from '../../../../store/reducers/subDomain';
import { getCostCentersBySubDomain } from '../../../../store/reducers/costCenterReducer'
import regionTypes from '../../../../constants/regionTypes';

const regionTypesSuggestions = regionTypes.map((regionType, index) => {
    return {
        label: regionType,
        value: index
    }
})

const mapStateToProps = (state) => {
    return {
        activityName: state.activityForm.activityName || '',
        workId: state.activityForm.workId || '',
        sourceDataLink: state.activityForm.sourceDataLink || '',
        legacyNumber: state.activityForm.legacyNumber || '',
        demandNumber: state.activityForm.demandNumber || '',
        projectNumber: state.activityForm.projectNumber || '',
        errors: state.activityForm.errors,
        activityTypes: [{label:activityTypes[0], value:0}, {label:activityTypes[1], value: 1}],
        activityType: state.activityForm.activityType || '',
        isDeleted: state.activityForm.inactive,
        domainsSuggestions: getDomainsParsed(state),
        subDomainsPerDomains: getSubDomainsPerDomain(state),
        regionTypesSuggestions: regionTypesSuggestions,
        regionType: state.activityForm.regionType,
        domain: state.activityForm.domain,
        subDomain: state.activityForm.subDomain,
        costCenter: state.activityForm.costCenter,
        costCenterSuggestions: state.activityForm.subDomain ?  getCostCentersBySubDomain(state)[state.activityForm.subDomain.value] : []
    }
};

const mapDispatchToProps = (dispatch) => ({
    inactiveChanged: (payload) =>
        dispatch(activityFormActions.activityFormInactiveChanged(payload.target.checked)), 
    activityFormActivityNameChanged: (payload) =>
        dispatch(activityFormActions.activityFormActivityNameChanged(payload.target.value)),    
    activityFormActivityTypeChanged: (payload) => {
        dispatch(activityFormActions.activityFormActivityTypeChanged({label: activityTypes[parseInt(payload.value)], value:parseInt(payload.value)}))
    },
    activityFormSourceDataLinkChanged: (payload) =>
        dispatch(activityFormActions.activityFormSourceDataLinkChanged(payload.target.value)),   
    activityFormProjectNumberChanged: (payload) =>
        dispatch(activityFormActions.activityFormProjectNumberChanged(payload.target.value)),  
    activityFormDemandNumberChanged: (payload) =>
        dispatch(activityFormActions.activityFormDemandNumberChanged(payload.target.value)),  
    activityFormLegacyNumberChanged: (payload) =>
        dispatch(activityFormActions.activityFormLegacyNumberChanged(payload.target.value)), 
    activityFormRegionTypeChanged: (payload) =>
        dispatch(activityFormActions.activityFormRegionTypeChanged(payload)), 
    activityFormDomainChanged: (payload) =>
        dispatch(activityFormActions.activityFormDomainChanged(payload)), 
    activityFormSubDomainChanged: (payload) =>
        dispatch(activityFormActions.activityFormSubDomainChanged(payload)), 
    activityFormCostCenterChanged: (payload) =>
        dispatch(activityFormActions.activityFormCostCenterChanged(payload)),     
    });
class DataContainer extends Component {
    render() {
        return (
            <ActivityData 
                {...this.props}
            />
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DataContainer);