import React, { Component } from 'react';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import { connect } from 'react-redux';
import AutoSelect from '../../../../components/Input/AutoSelect';
import  * as rules  from '../../../../config/Rules';
import Can from '../../../../config/Can';
import { compose } from 'redux';
import {getChangelogChanges} from '../../../../store/reducers/ChangeLogFormReducer'

const mapStateToProps = (state) => {
    return {
        selectedTags: state.changelogForm.changelogTags,
        changelogTagsSuggestions: state.changelogData.changelogTags,
        editMode: state.changelogForm.editMode,
        changes: getChangelogChanges(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    statusChanged: (payload) => {
        dispatch(changelogFormActionsCreators.changelogTagsChanged(payload)) 
    }
    }
);

class TagContainer extends Component {
    render() {
        return (
            <div style={{position: 'relative', paddingTop: 8}}>
                    <Can 
                        role={this.props.selectedRole}
                        perform={rules.EDIT_FORM_TAGS}
                        yes={() => {
                            return (
                                <React.Fragment>
                                    <AutoSelect 
                                        label="Tags" 
                                        isMulti
                                        width="100%" 
                                        disabled={(!this.props.editMode && this.props.editing)}
                                        value={this.props.selectedTags} 
                                        suggestions={this.props.changelogTagsSuggestions} 
                                        handleChangeSingle={this.props.statusChanged}
                                        className={this.props.changes.tags ? "input-updated": ""}
                                        tooltipText={this.props.changes.tags}
                                    />
                                </React.Fragment>
                            );
                        }}
                    />
            </div>
        )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(TagContainer);