import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import Input from '../../../components/Input/Input';
import AutoSelect from '../../../components/Input/AutoSelect';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/subDomains';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
  const mapStateToProps = (state) => {
      const domainsMap = {

      }
      state.domains.domains.forEach((domain) => {
        domainsMap[domain.id] = domain.name;
      });
      const suggestions = state.domains.domains.map(domain => ({
        value: domain.id,
        label: domain.name
      })); 
      return {
        domains: domainsMap,
        suggestions: suggestions,
        subDomains: state.subDomains.allSubDomains,
        selectedItems: state.subDomains.selectedItems,
        loading: state.subDomains.loading,
        newItemSubDomainText: state.subDomains.newItemSubDomainText,
        newItemDomain: state.subDomains.newItemDomain,
        itemsBeingEditedMap: state.subDomains.itemsBeingEditedMap,
      }
};
  
  const mapDispatchToProps = (dispatch) => ({
      addSubDomain: (payload) => 
        dispatch(actionCreators.addSubDomainStarted(payload)),
      deleteSubDomain: (data, id) => 
        dispatch(actionCreators.deleteSubDomainStarted(id)),
      editSubDomain: (payload) => 
        dispatch(actionCreators.editSubDomainStarted(payload)),
      newItemSubDomainChanged: (event) => {
        dispatch(actionCreators.subDomainPicklistNewItemTextChanged(event))
      },
      picklistNewItemDomainChanged: (domain) => 
          dispatch(actionCreators.subDomainPicklistNewItemDomainChanged(domain)),
      picklistEditItemTextChanged: (event) =>
          dispatch(actionCreators.subDomainPicklistEditItemTextChanged(event))
  });

class DomainExpenseTypePickList extends Component {

    state = {
        filtered: {
        }
    }
    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (key === 'domainId' && this.props.domains[row[key]] === undefined) {
                    isValid = false;
                    return;
                }
                if (key === 'domainId' && this.props.domains[row[key]].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                } else if ( key !== 'domainId' && row[key].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                }
            })
            return isValid;
        })
    }

    addItem = (event) => {
        if (event.target.value.length === 0) {
            return;
        }
        this.props.addSubDomain({domainId: this.props.newItemDomain.value, name: event.target.value});
    }
    editSubDomain = (event) => {
        const targetSubDomain = this.props.subDomains.find(subDomain => subDomain.id === parseInt(event.target.name));
        if (targetSubDomain.name !== event.target.value) {
            const editedSubDomain = {
                ...targetSubDomain,
                name: event.target.value
            }
            this.props.editSubDomain(editedSubDomain);
        }
    }

    editSubDomainDomain = (event, row) => {
        if (row.domainId !== event.value) {
            this.props.editSubDomain({
                ...row,
                domainId: event.value
            })
        }
    }
    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.addItem(event);
        }
    }
    handleEnterOnEditInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.editSubDomain(event);
        }
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.subDomains.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.editSubDomain(copy)
    }
    render() {
        const filteredData = this.filterData(this.props.subDomains);
        return (
            <div>
                <DataExportContainer url="api/export/SubDomains"/>
                <DataTable
                general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                onSelectedChanged={this.selectedItemsChanged}
                selected={this.props.selectedItems}
                loading={this.props.loading}
                enabledAddition
                filtered={this.state.filtered}
                onFilteredChanged={this.onFilteredChanged}
                data={filteredData}  
                addNewItemRenderer= { (dataProperty) => {
                    if (dataProperty === 'domainId') {
                        return (
                            <AutoSelect
                                suggestions={this.props.suggestions}
                                width={292}
                                value={this.props.newItemDomain}
                                handleChangeSingle= {this.props.picklistNewItemDomainChanged}
                            />
                        );
                    }
                    return <Input 
                                width={292} 
                                placeholder="Add new..."
                                onBlur={this.addItem}
                                inputProps={{name: dataProperty}}
                                disabled={this.props.newItemDomain.value === undefined}
                                value={this.props.newItemSubDomainText}
                                onChange={this.props.newItemSubDomainChanged}
                                onKeyPress={this.handleEnterOnAddInput}
                            />
                    }
                }
                customRowCells={{
                    name: (data, id, row) => {
                        return (
                        <Input width={292} 
                            // onDeleteIconClicked={this.props.deleteSubDomain.bind(this, data, id, row)}
                            onBlur={this.editSubDomain}
                            inputProps={{name: id}}
                            value={this.props.itemsBeingEditedMap[id] ? this.props.itemsBeingEditedMap[id]: data}
                            onChange={this.props.picklistEditItemTextChanged}
                            onKeyPress={this.handleEnterOnEditInput}
                        />
                        )
                    },
                    domainId: (data, id, row) => {
                        return (
                            <AutoSelect
                                suggestions={this.props.suggestions}
                                width={292}
                                disabled={row.alreadyInUse}
                                value={{value: data, label: this.props.domains[data]}}
                                handleChangeSingle={(selectedOption) => {this.editSubDomainDomain(selectedOption, row)}}
                            />
                        )
                    },
                }}
                columns={[
                    {
                        header:"Platform",
                        dataProperty:"domainId",
                        isVisible: true
                    },
                    {
                        header:"Sub-platform",
                        dataProperty:"name",
                        isVisible: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainExpenseTypePickList);