import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect'

const initialState = {
    subDomains: [],
    allSubDomains: [],
    selectedItems: {},
    loading: false,
    newItemSubDomainText: '',
    newItemDomain: '',
    itemsBeingEditedMap: {},
}

const expenseTypePickListReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_SUB_DOMAINS_STARTED: 
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_ALL_SUB_DOMAINS_FAILED: {
            return {
                ...state,
                loading: false,
                allSubDomains: [],
                selectedItems: {}
            }
        }
        case actionTypes.GET_ALL_SUB_DOMAINS_FINISHED: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                loading: false,
                allSubDomains: action.payload,
                selectedItems: selectedItems
            }
        }

        case actionTypes.GET_SUB_DOMAINS_STARTED: 
            return {
                ...state,
                loading: true
            }
        case actionTypes.GET_SUB_DOMAINS_FAILED: {
            return {
                ...state,
                loading: false,
                subDomains: [],
            }
        }
        case actionTypes.GET_SUB_DOMAINS_FINISHED: {
            return {
                ...state,
                loading: false,
                subDomains: action.payload
            }
        }
        case actionTypes.ADD_SUB_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_SUB_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
                newItemDomain: {},
                newItemSubDomainText: ""
            }
        }
        case actionTypes.ADD_SUB_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
                newItemDomain: {},
                newItemSubDomainText: ""
            }
        }      
        case actionTypes.EDIT_SUB_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_SUB_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
                itemsBeingEditedMap: {}
            }
        }
        case actionTypes.EDIT_SUB_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
                itemsBeingEditedMap: {}
            }
        }
        case actionTypes.DELETE_SUB_DOMAIN_STARTED: {
            return {
                ...state,
                loading: true,
            }
        }
        case actionTypes.DELETE_SUB_DOMAIN_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }
        case actionTypes.DELETE_SUB_DOMAIN_FINISHED: {
            return {
                ...state,
                loading: false,
            }
        }
        case actionTypes.SUB_DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED: {
            return {
                ...state,
                newItemDomain: action.payload
            }
        }

        case actionTypes.SUB_DOMAIN_PICKLIST_NEW_ITEM_TEXT_CHANGED: {
            return {
                ...state,
                newItemSubDomainText: action.payload.target.value
            }
        }
        case actionTypes.SUB_DOMAIN_PICKLIST_EDIT_ITEM_TEXT_CHANGED: {
            const itemsBeingEditedCopy = {...state.itemsBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                itemsBeingEditedMap: itemsBeingEditedCopy
            }
        }
        
        default:
            return state;
    }
}
const getChangelogSubDomains = (state) => state.changelogData.subDomains;
const getSubDomains = (state) => state.subDomains.subDomains;
const getAllSubDomains = (state) => state.subDomains.allSubDomains;

export const getSubDomainsParsed = createSelector([getChangelogSubDomains], (subDomains) => {
    return subDomains ? subDomains.map((domain) => {
        return {
            label: domain.name,
            value: domain.id
        }
}) : [];
})
export const getAllSubDomainsParsed = createSelector([getAllSubDomains], (subDomains) => {
    return subDomains ? subDomains.map((domain) => {
        return {
            label: domain.name,
            value: domain.id
        }
}) : [];
})
export const getSubDomainsMap = createSelector([getSubDomains], (subDomains) => {
    const subDomainsMap = {};
    if (subDomains) {
        subDomains.forEach((subDomain) => {
            subDomainsMap[subDomain.id] = subDomain.name
        })
    }
    return subDomainsMap;
})
export const getSubDomainsPerDomain = createSelector([getSubDomains], (subDomains) => {
    const subDomainsPerDomain = {};
    if (subDomains) {
        subDomains.forEach((subDomain) => {
            const subDomainSuggestion = {
                label: subDomain.name,
                value: subDomain.id
            }
            if (subDomainsPerDomain[subDomain.domainId]) {
                subDomainsPerDomain[subDomain.domainId].push(subDomainSuggestion)
            } else {
                subDomainsPerDomain[subDomain.domainId] = [subDomainSuggestion]
            }
        })
    }
    return subDomainsPerDomain
})
export const getAllSubDomainsPerDomainWithDeletedFiltered = createSelector([getAllSubDomains], (subDomains) => {
    const subDomainsPerDomain = {};
    if (subDomains) {
        subDomains.forEach((subDomain) => {
            if (subDomain.isDeleted) {
                return;
            }
            const subDomainSuggestion = {
                label: subDomain.name,
                value: subDomain.id
            }
            if (subDomainsPerDomain[subDomain.domainId]) {
                subDomainsPerDomain[subDomain.domainId].push(subDomainSuggestion)
            } else {
                subDomainsPerDomain[subDomain.domainId] = [subDomainSuggestion]
            }
        })
    }
    return subDomainsPerDomain
})

export default expenseTypePickListReducer;