import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import Input from '../../../components/Input/Input';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/costCenters';
import Button from '@material-ui/core/Button';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import { getDomainsMap, getDomainsParsed } from '../../../store/reducers/DomainsReducer';
import { getSubDomainsMap, getSubDomainsPerDomain } from '../../../store/reducers/subDomain';
import { getSelectedCostCenters, getAllItemsWithSelectionProperty } from '../../../store/reducers/costCenterReducer';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';

const mapStateToProps = (state) => {
    return {
        costCenters: getAllItemsWithSelectionProperty(state) || [],
        loading: state.costCenters.loading,
        selectedItems: getSelectedCostCenters(state),
        itemsBeingEditedMap: state.costCenters.itemsBeingEditedMap,
        newItemObject: state.costCenters.newItemObject,
        domains: getDomainsParsed(state),
        domainsMap: getDomainsMap(state),
        subDomainsMap: getSubDomainsMap(state),
        subDomainsPerDomain: getSubDomainsPerDomain(state),
    }
};
  const mapDispatchToProps = (dispatch) => ({
    addCostCenter: () =>
        dispatch(actionCreators.addCostCenterStarted()),
    editCostCenter: (payload) =>
        dispatch(actionCreators.editCostCenterStarted(payload)),    
    editCostCenterActionPressed: (payload) => 
        dispatch(actionCreators.editCostCenterActionPressed(payload)),
    editCostCenterProperty: (id, dataProperty, value) => {
        const payload = {
            id,
            dataProperty,
            validate: value.type === 'blur'
        }
        if (value.value !== undefined) {
            payload.value = value.value
        } else {
            payload.value = value.target.value
        }
        dispatch(actionCreators.editCostCenterProperty(payload))
    },
    editNewCostCenterProperty: (dataProperty, value) => {
        const payload = {
            dataProperty,
            validate: value.type === 'blur'
        }
        if (value.value) {
            payload.value = value.value
        } else {
            payload.value = value.target.value
        }
        dispatch(actionCreators.editNewCostCenterProperty(payload))
    }
        
  });

class CostCenterPickList extends Component {

    state = {
        filtered: {
        }
    }
    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    selectedItemsChanged = (event, isDeleted) => {
        this.props.editCostCenterProperty(event.target.name, 'isDeleted', {value: isDeleted})
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (key === 'domainId' && this.props.domainsMap[row[key]] === undefined) {
                    isValid = false;
                    return;
                }
                if (key === 'subDomainId' && this.props.subDomainsMap[row[key]] === undefined) {
                    isValid = false;
                    return;
                }
                if (key === 'domainId' && this.props.domainsMap[row[key]].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                } else if (key === 'subDomainId' && this.props.subDomainsMap[row[key]].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                } else if ( key !== 'domainId' && key !== 'subDomainId' && row[key].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                }
            })
            return isValid;
        })
    }
    render() {
        const filteredData = this.filterData(this.props.costCenters);
        return (
            <div>
                <DataExportContainer url="/api/export/costcenters"/>
                <DataTable
                loading={this.props.loading}
                enabledAddition
                filtered={this.state.filtered}
                general={{selectionEnabled: true, canSelectRowProperty: "canBeEdited", selectionColumnHeader: "Inactive"}}
                onSelectedChanged={this.selectedItemsChanged}
                selected={this.props.selectedItems}
                onFilteredChanged={this.onFilteredChanged}
                data={filteredData}  
                addNewItemRenderer= { (dataProperty) => {
                    if (dataProperty === 'action') {
                        return (
                            <Button onClick={this.props.addCostCenter} color="primary" disabled={!this.props.newItemObject.canBeSaved || this.props.loading}>Add</Button>
                            )
                    }
                    if (dataProperty === 'name' || dataProperty === 'description') {
                        return (
                            <Input 
                                width="100%"
                                errorText={dataProperty === 'name' ? this.props.newItemObject.errorName : ''}
                                onBlur={this.props.editNewCostCenterProperty.bind(this, dataProperty)}
                                value={this.props.newItemObject[dataProperty] || ""}
                                onChange= {this.props.editNewCostCenterProperty.bind(this, dataProperty)}
                            />
                        )
                    }
                    if (dataProperty === 'domainId') {
                        return (
                            <AutoSelect
                                suggestions={this.props.domains}
                                width="100%"
                                value={{label: this.props.domainsMap[this.props.newItemObject.domainId], value: this.props.newItemObject.domainId}}
                                handleChangeSingle={this.props.editNewCostCenterProperty.bind(this, dataProperty)}
                        />
                        )
                    }
                    return (
                        <AutoSelect
                            suggestions={this.props.newItemObject.domainId ? this.props.subDomainsPerDomain[this.props.newItemObject.domainId] : []}
                            width="100%"
                            value={{label: this.props.subDomainsMap[this.props.newItemObject.subDomainId], value: this.props.newItemObject.subDomainId}}
                            handleChangeSingle= {this.props.editNewCostCenterProperty.bind(this, dataProperty)}
                        />
                    )
                }}
                customRowCells={{
                    action: (data, id, row) => {
                        if (this.props.itemsBeingEditedMap[id]) {
                            return (
                                <React.Fragment>
                                    <Button color="primary" onClick={this.props.editCostCenterActionPressed.bind(this, id)}>Back</Button>
                                    <Button color="primary" onClick={this.props.editCostCenter.bind(this, this.props.itemsBeingEditedMap[id])}  disabled={!this.props.itemsBeingEditedMap[id].canBeSaved || this.props.loading}>Save</Button>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <Button color="primary" onClick={this.props.editCostCenterActionPressed.bind(this, id)}>Edit</Button>
                            )
                        }
                    },
                    name: (data, id, row) => {
                        if (this.props.itemsBeingEditedMap[id]) {
                            return (
                                <Input 
                                    width="100%"
                                    errorText={this.props.itemsBeingEditedMap[id].errorName}
                                    value={this.props.itemsBeingEditedMap[id].name}
                                    onBlur={this.props.editCostCenterProperty.bind(this, id, "name")}
                                    onChange={this.props.editCostCenterProperty.bind(this, id, "name")}
                                />
                            )
                        }
                        return (
                            <div>{data}</div>
                        )
                    },
                    description: (data, id, row) => {
                        if (this.props.itemsBeingEditedMap[id]) {
                            return (
                                <Input 
                                    width="100%"
                                    value={this.props.itemsBeingEditedMap[id].description}
                                    onChange={this.props.editCostCenterProperty.bind(this, id, "description")}
                                />
                            )
                        }
                        return (
                            <div>{data}</div>
                        )
                    },
                    domainId: (data, id, row) => {
                        if (this.props.itemsBeingEditedMap[id]) {
                            return (
                                <AutoSelect
                                    suggestions={this.props.domains}
                                    width="100%"
                                    value={{label: this.props.domainsMap[this.props.itemsBeingEditedMap[id].domainId], value: this.props.itemsBeingEditedMap[id].domainId}}
                                    handleChangeSingle= {this.props.editCostCenterProperty.bind(this, id, "domainId")}
                                />
                            )
                        }
                        return (
                            <div>{this.props.domainsMap[data]}</div>
                        )
                    },
                    subDomainId: (data, id, row) => {
                        if (this.props.itemsBeingEditedMap[id]) {
                            return (
                                <AutoSelect
                                    suggestions={this.props.itemsBeingEditedMap[id].domainId ? this.props.subDomainsPerDomain[this.props.itemsBeingEditedMap[id].domainId] : []}
                                    width="100%"
                                    value={{label: this.props.subDomainsMap[this.props.itemsBeingEditedMap[id].subDomainId], value: this.props.itemsBeingEditedMap[id].subDomainId}}
                                    handleChangeSingle= {this.props.editCostCenterProperty.bind(this, id, "subDomainId")}
                                />
                            )
                        }
                        return (
                            <div>{this.props.subDomainsMap[data]}</div>
                        )
                    },
                }}
                columns={[
                    {
                        header:"Name",
                        dataProperty:"name",
                        isVisible: true
                    },
                    {
                        header:"Description",
                        dataProperty:"description",
                        isVisible: true
                    },
                    {
                        header:"Platform",
                        dataProperty:"domainId",
                        isVisible: true
                    },
                    {
                        header:"Sub-platform",
                        dataProperty:"subDomainId",
                        isVisible: true
                    },
                    {
                        header: "Action",
                        dataProperty: "action",
                        isVisible: true,
                        disabledFiltering: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostCenterPickList);