import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreatedBy from './components/CreatedBy';
import formatDate from '../../../utils/dateFormatter';

const mapStateToProps = (state) => {
    return {
        createdBy: state.regionalChangelogForm.createdBy ? `${state.regionalChangelogForm.createdBy.name} (${state.regionalChangelogForm.createdBy.login}) \n ${formatDate(state.regionalChangelogForm.createdDate)}` : "",
    }
}

class CreatedByContainer extends Component {
    render() {
        return (
            this.props.createdBy ? <CreatedBy createdBy={this.props.createdBy}/> : null
        )
    }
}
export default connect(mapStateToProps, null)(CreatedByContainer);