import React from 'react';
import Input from '../../../../components/Input/Input';
import AutoSelect from '../../../../components/Input/AutoSelect';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
const rates = [
    {label: '125%', value: 125},
    {label: '150%', value: 150},
    {label: '175%', value: 175},
    {label: '200%', value: 200}
]

export default (props) => {
    const addOvertime = () => {
        const newHours = [...props.rates];
        newHours.push({
            rate: rates[0].value, 
            numberOfExtraHours: 0, 
            key:Math.random()
        });
        props.hoursChanged(newHours);
    }

    const deleteOvertime = (key) => {
        const modifiedRecord = props.rates.find((record) => {
            return record.key === key;
        });
        const modifiedIndex = props.rates.indexOf(modifiedRecord);
        const newHours = [...props.rates.slice(0, modifiedIndex), ...props.rates.slice(modifiedIndex +1)];
        props.hoursChanged(newHours);
    }

    const rateChanged = (key, value) => {
        const newHours = [...props.rates];
        const modifiedRecord = newHours.find((record) => {
            return record.key === key;
        })
        const modifiedIndex = newHours.indexOf(modifiedRecord);
        newHours[parseInt(modifiedIndex)].rate = value.value;
        props.hoursChanged(newHours);
    }

    const recordHoursChanged = (e) => {
        const newHours = [...props.rates];
        const modifiedRecord = newHours.find((record) => {
            return record.key.toString() === e.target.name;
        })
        const modifiedIndex = newHours.indexOf(modifiedRecord);

        newHours[parseInt(modifiedIndex)] = {
            ...modifiedRecord,
            numberOfExtraHours: e.target.value
        }
        props.hoursChanged(newHours);
    }

    const getRate = (selectedRate, key, deleted) => {
        let rate;
        if (props.isApproving || deleted) {
            rate = (<Input label="Rate" width="45%" value={selectedRate+"%"} disabled className="disabled-form-input"/>);
        } else {
            rate = (<AutoSelect label="Rate" width="45%" value={{label: selectedRate+"%", value: selectedRate}} suggestions={rates} handleChangeSingle={(value) => {rateChanged(key, value)}}></AutoSelect>) 
        }
        return rate;
    }
    const getClassName = (previous, deleted) => {
        if (previous) {
            return 'input-updated';
        }
        if (deleted) {
            return 'input-deleted';
        }
        return props.isApproving ? "disabled-form-input" : null;

    }
    const numberOfHours = (numberOfHours, key, previous, deleted) => { 
        return (<Input 
            type="number" 
            label="Number of hours" 
            tooltipText={previous}
            disabled={props.isApproving || deleted} 
            width="45%" 
            value={numberOfHours} 
            onChange={recordHoursChanged} 
            inputProps={{name: key}}
            className={getClassName(previous, deleted)}
            onDeleteIconClicked={props.isApproving || deleted ? null : () => {deleteOvertime(key)}}
            />)
    }
    return (
        <div>
            <div>
            {props.rates.map((item, index) => {
                return (
                    <div key={item.key} style={{display: 'flex', justifyContent: 'space-between', paddingTop: index === 0 ? 0 : 8, paddingBottom: 8}}>
                        {getRate(item.rate, item.key)}
                        {numberOfHours(item.numberOfExtraHours, item.key, props.changes? props.changes[item.rate] : "", item.type)}
                    </div>
                )
            })}
            {
                props.changes && props.changes.deleted ?
                    props.changes.deleted.map((item, index) => {
                        return (
                            <div key={item.key} style={{display: 'flex', justifyContent: 'space-between', paddingTop: index === 0 ? 0 : 8, paddingBottom: 8}}>
                                {getRate(item.rate, item.key, true)}
                                {numberOfHours(item.numberOfExtraHours, item.key, "", true)}
                            </div>
                        )
                    }) : null
            }
            </div>
            {props.isApproving ? null : 
            <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                    <Button color="primary" 
                        style={{marginLeft: 'auto'}} 
                        onClick={addOvertime} 
                        className="removed-text-padding">
                        <AddIcon />
                        ADD OVERTIME
                    </Button>
            </div> 
            }
        </div>
    )

}