export default function (variant) {
    switch(variant) {
        case 'changelogs':
        case 'budgetPlans':
            return {
                    palette: {
                            primary: {
                                light: '#e1f5fe',
                                main: '#39acff',
                                dark: '#007ad2',
                                contrastText: '#fff'
                            }
                        }
                    }
        
        case 'deliveryconfirmation':
                return {
                    palette: {
                            primary: {
                                light: '#bcaaa4',
                                main: '#9f7e5f',
                                dark: '#6d4c41',
                                contrastText: '#fff'
                            }
                        }
                    }   
        case 'sapActuals':             
        case 'resourcemodel':
        case 'staticpicklist':
        case 'masterdata':      
                return {
                    palette: {
                            primary: {
                                light: '#f5f5f5',
                                main: '#595959',
                                dark: '#757575',
                                contrastText: '#fff'
                            }
                        }
                    }
        default: return {
            palette: {
                primary: {
                    main: '#0068b3',
                    contrastText: '#fff',
                    secondary: '#fff'
                }
            }
        }
    }
}