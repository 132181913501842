
import * as actionTypes from './actionTypes';

export const initializeDeliveryConfirmationFormStarted =  (myParam) => {
    return {type: actionTypes.INITIALIZE_DELIERY_CONFIRMATION_FORM_STARTED, payload: myParam};
}
export const initializeDeliveryConfirmationFormFailed =  (myParam) => {
    return {type: actionTypes.INITIALIZE_DELIVERY_CONFIRMATION_FORM_FAILED, payload: myParam};
}
export const initializeDeliveryConfirmationFormFinished =  (myParam) => {
    return {type: actionTypes.INITIALIZE_DELIVERY_CONFIRMATION_FORM_FINISHED, payload: myParam};
}

export const deliveryConfirmationFormOvertimeHoursChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_OVERTIME_HOURS_CHANGED, payload: myParam}
}

export const deliveryConfirmationFormResourceTypeChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_RESOURCE_TYPE_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormIdChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ID_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormActualsSubmittedByChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUALS_SUBMITTED_BY_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormActualsApprovedByChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUALS_APPROVED_BY_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormActivityNameChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTIVITY_NAME_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormDeliverableChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_DELIVERABLE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormPoNumberChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_PO_NUMBER_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormYearChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_YEAR_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormMonthChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_MONTH_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormVendorChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_VENDOR_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormSupplierRepresentativeChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SUPPLIER_REPRESENTATIVE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormDcApproverChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_DC_APPROVER_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormGradeChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_GRADE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormUnitRateChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_UNIT_RATE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormPerDiemRateChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_PER_DIEM_RATE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormCurrencyChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_CURRENCY_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormPlannedUnitPriceChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_PLANNED_UNIT_PRICE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormActualUnitPriceChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUAL_UNIT_PRICE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormPlannedPerDiemChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_PLANNED_PER_DIEM_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormActualPerDiemChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTUAL_PER_DIEM_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormOvertimeChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_OVERTIME_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormRateChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_RATE_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormNumberOfExtraHoursChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_NUMBER_OF_EXTRA_HOURS_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormAttachmentAdded =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_ATTACHMENT_ADDED, payload: myParam};
};
export const deliveryConfirmationFormAttachmentDeleted =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_ATTACHMENT_DELETED, payload: myParam};
};
export const deliveryConfirmationFormCommentChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_COMMENT_CHANGED, payload: myParam};
};
export const resetForm = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_RESET_FORM, payload: myParam};
}
export const deliveryConfirmationFormLastModifiedByChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_LAST_MODIFIED_BY_CHANGED, payload: myParam};
};
export const deliveryConfirmationFormStatusChanged =  (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_STATUS_CHANGED, payload: myParam};
};

export const deliveryConfirmationFormActionButtonClicked = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ACTION_BUTTON_CLICKED, payload: myParam}
}

export const deliveryConfirmationFormEditStateChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_EDIT_STATE_CHANGED, payload: myParam};
}

export const deliveryConfirmationFormVendorTypeChanged = (myParam) => {
    return {type:actionTypes.DELIVERY_CONFIRMATION_FORM_VENDOR_TYPE_CHANGED, payload: myParam};
}

export const deliveryConfirmationFormCommentsChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_COMMENTS_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormRejectReasonChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_REJECT_REASON_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormDcNumberChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_DC_NUMBER_CHANGED, payload: myParam};
}

export const deliveryConfirmationFormSubmitAndApproveModalStateChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_AND_APPROVE_MODAL_STATE_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormRejectModalStateChanged = (myParam) => {
    return {type:actionTypes.DELIVERY_CONFIRMATION_FORM_REJECT_MODAL_STATE_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormAssignmentSiteChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ASSIGNMENT_SITE_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormErrorsChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_ERRORS_CHANGED, payload: myParam};
}
export const deliveryConfirmationFormSubmitStarted = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_STARTED, payload: myParam};
}
export const deliveryConfirmationFormSubmitFailed = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_FAILED, payload: myParam};
}
export const deliveryConfirmationFormSubmitFinished = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_FINISHED, payload: myParam};
}

export const deliveryConfirmationFormLoadingSpinnerChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_LOADING_SPINNER_CHANGED, payload: myParam};
}
export const deliveryConfirmationIsSupplierChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_IS_SUPPLIER_CHANGED, payload: myParam};
}
export const deliveryConfirmationIsApprovedChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_IS_APPROVER_CHANGED, payload: myParam};
}
export const downloadDcFileFromLink = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_DOWNLOAD_DC_FILE_FROM_LINK, payload: myParam};
}
export const deliveryConfirmationFormRmIdChanged = (myParam) => {
    return {type: actionTypes.DELIERY_CONFIRMATION_FORM_RM_ID_CHANGED, payload: myParam}
} 

export const getDeliveryConfirmationHistoryStarted = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_HISTORY_STARTED, payload: myParam}
} 
export const getDeliveryConfirmationHistoryFinished = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_HISTORY_FINISHED, payload: myParam}
} 
export const getDeliveryConfirmationHistoryFailed = (myParam) => {
    return {type: actionTypes.GET_DELIVERY_CONFIRMATION_HISTORY_FAILED, payload: myParam}
} 
export const deliveryConfirmationFormCreatedByChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_CREATED_BY_CHANGED, payload: myParam}
} 
export const deliveryConfirmationFormLatestChangesChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_LATEST_CHANGES_CHANGED, payload: myParam}
}
export const deliveryConfirmationFormShowChangesChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_SHOW_CHANGES_CHANGED, payload: myParam}
}
export const deliveryConfirmationFormClIdChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_CL_ID_CHANGED, payload: myParam}
}

export const deliveryConfirmationFormTagsChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_TAGS_CHANGED, payload: myParam}
}
export const deliveryConfirmationFormTagsSuggestionsChanged = (myParam) => {
    return {type: actionTypes.DELIVERY_CONFIRMATION_FORM_TAGS_SUGGESTIONS_CHANGED, payload: myParam}
}
