import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
}

const attachmentReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ATTACHMENT_LOADING_CHANGED: {
            return {
                loading: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default attachmentReducer;