import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as wbsActions from '../../../../store/actions/wbs';
import WbsData from './WbsData';
const mapStateToProps = (state) => {
    return {
        data: state.wbsForm,
        costTypes: state.costTypes.items && state.costTypes.items.length ?
            state.costTypes.items.map((costType) => {
                return {
                    label: costType.name,
                    value: costType.id
                }
            }) : [],
        poLocations: state.poLocation.poLocations ? state.poLocation.poLocations.map((poLocation) => {
            return {
                label: poLocation.name + ` (${poLocation.number})`,
                value: poLocation.id
            }
        }) : []  
    }};
  
  const mapDispatchToProps = (dispatch) => ({
    inactiveChanged: (payload) =>
        dispatch(wbsActions.wbsFormInactiveChanged(payload.target.checked)),  
    domainChanged: (payload) => 
        dispatch(wbsActions.wbsFormDomainChanged(payload)),
    wbsFormCareCcChanged: (payload) =>
        dispatch(wbsActions.wbsFormCareCcChanged(payload.target.value)),
    wbsFormCostTypeChanged: (payload) =>
        dispatch(wbsActions.wbsFormCostTypeChanged(payload)),
    wbsFormVendorTypeChanged: (payload) =>
        dispatch(wbsActions.wbsFormVendorTypeChanged(payload.value)),
    wbsFormCProjectChanged: (payload) =>
        dispatch(wbsActions.wbsFormCProjectChanged(payload.target.value)), 
    wbsFormCWbsChanged: (payload) =>
        dispatch(wbsActions.wbsFormCWbsChanged(payload.target.value)),   
    wbsFormPoCcChanged: (payload) =>
        dispatch(wbsActions.wbsFormPoCcChanged(payload.target.value)), 
    wbsFormActivityNameChanged: (payload) =>
        dispatch(wbsActions.wbsFormActivityNameChanged(payload)), 
    wbsFormPoWbsChanged: (payload) =>
        dispatch(wbsActions.wbsFormPoWbsChanged(payload.target.value)),    
    wbsFormDeprecationStartDateChanged: (payload) => {
        dispatch(wbsActions.wbsFormDeprecationStartDateChanged(payload));
    }, 
    wbsFormDeprecationDurationChanged: (payload) =>
        dispatch(wbsActions.wbsFormDeprecationDurationChanged(payload.target.value)), 
    wbsFormDeprecationCostCenterChanged: (payload) =>
        dispatch(wbsActions.wbsFormDeprecationCostCenterChanged(payload.target.value)),    
    wbsFormPoWbsDescriptionChanged: (payload) =>
        dispatch(wbsActions.wbsFormPoWbsDescriptionChanged(payload.target.value)),
    wbsFormPoLocationChanged: (payload) =>
        dispatch(wbsActions.wbsFormPoLocationChanged(payload))
           
});

class DeliveryInfromationContainer extends Component {
    render() {
        return (
            <WbsData 
                {...this.props}
            />
        )
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfromationContainer);