export const statuses = [
    { label: "CL created/modified - pending Back Office action", value: 0 },
    { label: "CL on hold - additional information required (see comments)", value: 1 },
    { label: "PO creation/modification in progress - Back Office to follow-up", value: 2 },
    { label: "CL incorrectly created - pending Requestor action", value: 3 },
    { label: "CL inactive - plan removed", value: 4 },
    { label: "CL approved - PO to be created by Back Office", value: 5 },
    { label: "CL on hold - PO documentation missing from Requestor", value: 6 },
    { label: "PO created/modified - no action pending", value: 7 },
    { label: "CL Approved - no action pending", value: 8 },
    { label: "CL on hold - insufficient budget - pending Requestor action", value: 9 },
    { label: "CL plan for future years - no action pending", value: 10 },

    { label: "CL plan for tracking - no action pending", value: 11 }
]
export const statusesInternal = [
    { label: "CL created/modified - pending Back Office action", value: 0 },
    { label: "CL on hold - additional information required (see comments)", value: 1 },
    { label: "CL incorrectly created - pending Requestor action", value: 3 },
    { label: "CL inactive - plan removed", value: 4 },
    { label: "CL Approved - no action pending", value: 8 },
    { label: "CL on hold - insufficient budget - pending Requestor action", value: 9 },
    { label: "CL plan for future years - no action pending", value: 10 },

    { label: "CL plan for tracking - no action pending", value: 11 }
]
export const statusesExternal = [
    { label: "CL created/modified", value: 0 },
    { label: "CL on hold - additional information required (see comments)", value: 1 },
    { label: "PO creation/modification in progress - Back Office to follow-up", value: 2 },
    { label: "CL incorrectly created - pending Requestor action", value: 3 },
    { label: "CL inactive - plan removed", value: 4 },
    { label: "CL approved - PO to be created by Back Office", value: 5 },
    { label: "CL on hold  - PO documentation missing from Requestor", value: 6 },
    { label: "PO created/modified - no action pending", value: 7 },
    { label: "CL on hold - insufficient budget - pending Requestor action", value: 9 },
    { label: "CL plan for future years - no action pending", value: 10 },

    { label: "CL plan for tracking - no action pending", value: 11 }
]