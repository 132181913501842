import { put, takeEvery, take, race, call, all } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import { select } from 'redux-saga/effects';
import * as wbsFormActions from '../store/actions/wbs';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import { oneColumnDictionariesSagas, getPoLocations } from './dictionariesSagas';
import axios from '../axios';
import history from '../history';
import scrollToErrorSaga from './scrollToErrorSaga';

function* initializeWbsFormSaga() {
    try {
        const [filteredActivities] = yield all([
            call(axios.get, 'api/activities', {params:{pageable: false}}),
            call(oneColumnDictionariesSagas['costTypes'].get),
            call(getPoLocations)
        ])
        const activities = filteredActivities.data.items.map((activity) => {
            return {
                label: activity.name + ` (${activity.workId})`,
                value: activity.id
            }
        });
        yield put(wbsFormActions.availableWbsFormActivitiesChanged(activities));
        yield put(wbsFormActions.wbsFormInitializeFinished());
    } catch (error) {
        yield put(wbsFormActions.wbsFormInitializeFailed());
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}
function* initializeEditWbsFormSaga(action) {
    try {
        const response = yield axios.get('api/wbs/'+ action.payload);
        const wbs = response.data;
        const [filteredActivities] = yield all([
            call(axios.get, 'api/activities', {params:{pageable: false}}),
            call(oneColumnDictionariesSagas['costTypes'].get),
            call(getPoLocations, wbs.poLocation.id)
        ])
        let activity = filteredActivities.data.items.find((act) => {
            return act.id === wbs.activityId;
        });
        if (!activity) {
            const res = yield axios.get(`api/activities/${wbs.activityId}`);
            activity = res.data;
            filteredActivities.data.items.push(activity);
        }
        const activities = filteredActivities.data.items.map((activity) => {
            return {
                label: activity.name + ` (${activity.workId})`,
                value: activity.id
            }
        });
        yield put(wbsFormActions.availableWbsFormActivitiesChanged(activities));
        yield put(wbsFormActions.wbsFormActivityNameChanged({
            label: activity.name + ` (${activity.workId})`,
            value: wbs.activityId
        }))
        yield put(wbsFormActions.wbsFormAlreadyInUseChanged(wbs.alreadyInUse));
        yield put(wbsFormActions.wbsFormIdChanged(wbs.id));
        yield put(wbsFormActions.wbsFormInactiveChanged(wbs.isDeleted));
        yield put(wbsFormActions.wbsFormCProjectChanged(wbs.cProject));
        yield put(wbsFormActions.wbsFormCareCcChanged(wbs.careCostCenter));
        const costTypes = yield select((state) => state.costTypes.items);
        const costType = costTypes.find((costType) => {
            return costType.id === wbs.costTypeId;
        })
        if (costType) {
            yield put(wbsFormActions.wbsFormCostTypeChanged({
                label: costType.name,
                value: costType.id
            }));
        }
        const poLocations = yield select((store) => store.poLocation.poLocations);
        const poLocation = poLocations.find((poLocation) => {
            return poLocation.id === wbs.poLocationId;
        })
        if (poLocation) {
            yield put(wbsFormActions.wbsFormPoLocationChanged({
                label: poLocation.name + ` (${poLocation.number})`,
                value: poLocation.id
            }));
        }
        yield put(wbsFormActions.wbsFormCWbsChanged(wbs.cwbs));
        if (wbs.depreciationCostCenter) {
            yield put(wbsFormActions.wbsFormDeprecationCostCenterChanged(wbs.depreciationCostCenter));
        }
        if (wbs.depreciationDuration) {
            yield put(wbsFormActions.wbsFormDeprecationDurationChanged(wbs.depreciationDuration));
        }
        
        if (wbs.depreciationStartDate) {
            yield put(wbsFormActions.wbsFormDeprecationStartDateChanged(wbs.depreciationStartDate));
        }
        
        yield put(wbsFormActions.wbsFormPoCcChanged(wbs.pocc));
        yield put(wbsFormActions.wbsFormPoWbsChanged(wbs.powbs));
        yield put(wbsFormActions.wbsFormPoWbsDescriptionChanged(wbs.description));
        yield put(wbsFormActions.wbsFormVendorTypeChanged(wbs.vendorType));
        yield put(wbsFormActions.wbsFormClientIdChanged(wbs.clientId));
        yield put(wbsFormActions.wbsFormInitializeFinished())
    } catch (error) {
        yield put(wbsFormActions.wbsFormInitializeFailed())
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}

function* submitFormSaga() {
    try {
        const formState = yield select((state) => state.wbsForm);
        const errors = validate(formState);
        if (Object.keys(errors).length !== 0) {
            yield put(wbsFormActions.wbsFormErrorsChanged(errors));
            yield put(wbsFormActions.submitWbsFormFailed())
            yield* scrollToErrorSaga();
            return;
        }
        const data = {
            isDeleted: formState.isDeleted || false,
            clientId: formState.clientId,
            activityId: formState.activity.value,
            activityName: formState.activity.label,
            careCostCenter: formState.careCc,
            costTypeId: formState.costType.value,
            poLocationId: formState.poLocation.value,
            vendorType: formState.vendorType.value,
            cProject: formState.cProject,
            cwbs: formState.cWbs,
            pocc: formState.poCc,
            powbs: formState.poWbs,
            description: formState.description,
            depreciationCostCenter: formState.depreciationCostCenter,
            depreciationDuration: formState.depreciationDuration,
            depreciationStartDate: formState.depreciationStartDate,     
        }
        let id = formState.id;
        if (id) {
            data.id = id;
            yield axios.put('api/wbs', data);
            yield put(wbsFormActions.submitWbsFormFinished());
        } else {
            yield axios.post('api/wbs', data);
            yield put(wbsFormActions.submitWbsFormFinished());
        }
        history.push('/masterdata/viewallwbses');
    } catch(error) {
        yield put(wbsFormActions.submitWbsFormFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting form. Please try again later"));
    }
}

function* initializeWbsFormWrapperSaga(action) {
    yield race({
        task: call(initializeWbsFormSaga, action),
        cancel: take(actionTypes.RESET_WBS_FORM)
    })
}
function* submitFormWrapperSaga(action) {
    yield race({
        task: call(submitFormSaga, action),
        cancel: take(actionTypes.RESET_WBS_FORM)
    })
}
function* initalizeEditActivityFormWrapperSaga(action) {
    yield race({
        task: call(initializeEditWbsFormSaga, action),
        cancel: take(actionTypes.RESET_WBS_FORM)
    })
}

function validate(formState) {
    const errors = {};
    const canSkipBecauseOpexIsSelected = formState.costType && formState.costType.label === 'Opex';
    if (!canSkipBecauseOpexIsSelected) {
        if (!formState.careCc) {
            errors['careCc'] = "CARE Cost center cannot be empty";
        }
        if (!formState.cProject) {
            errors['cProject'] = "C-Project cannot be empty";
        }
        if (!formState.cWbs) {
            errors['cWbs'] = "C-WBS type cannot be empty";
        }
    }
    if (!formState.poLocation || !formState.poLocation.value) {
        errors['poLocation'] = "Po Location cannot be empty";
    }
    if (!formState.description) {
        errors['description'] = "WBS description name cannot be empty";
    }
    if (!formState.costType || !formState.costType.value) {
        errors['costType'] = "Cost type cannot be empty";
    }
    if (!formState.vendorType) {
        errors['vendorType'] = "Vendor type cannot be empty";
    }
    if (!formState.poCc) {
        errors['poCc'] = "PO CC cannot be empty";
    }
    if (!formState.activity.value) {
        errors['activity'] = "Activity name cannot be empty"
    }
    if (!formState.poWbs || formState.poWbs.length < 6) {
        errors['poWbs'] = "PO WBS must contain at least 6 characters";
    }
    return errors;
}

export default [
    takeEvery([actionTypes.WBS_FORM_INITIALIZE_STARTED], initializeWbsFormWrapperSaga),
    takeEvery([actionTypes.WBS_EDIT_FORM_INITIALIZE_STARTED], initalizeEditActivityFormWrapperSaga),
    takeEvery(actionTypes.SUBMIT_WBS_FORM_STARTED, submitFormWrapperSaga)
]