import {EditorState, ContentState, convertFromRaw, convertToRaw} from "draft-js"

export const getEditorState = (EditorStateString) => {
    if (isStringJSON(EditorStateString)) {
        const object = JSON.parse(EditorStateString);
        if (object && typeof object === 'object') {
            return EditorState.createWithContent(convertFromRaw(object));
        }
        return EditorState.createWithContent(ContentState.createFromText(EditorStateString));
    }
    return EditorState.createWithContent(ContentState.createFromText(EditorStateString));
}

export const prepareEditorStateForSendingToDatabase = (EditorState) => {
    return JSON.stringify(convertToRaw(EditorState.getCurrentContent()))
}

export const getEmptyComment = () => {
    return EditorState.createEmpty();
}
export const getPureText = (EditorState) => {
    return EditorState.getCurrentContent().getPlainText();
}
const isStringJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}