import React, { Component } from 'react';
import AsyncSelect from '../../components/Input/AsyncSelect';
import axios from '../../axios';

class ePPMPicker extends Component {
    state = {
        page: {},
    }
    loadeppm = async (input, loadedOptions, { page }) => {
        try {
            if (input.length >= 3) {
                const response = await axios.get('api/EPPM', { params: { eppmName: input, page: this.state.page[input] || 1 } });
                this.setState({
                    page: { [input]: response.data.currentPage }
                })
                const options = response.data.items.map((res) => {
                    return {
                        //eppmuniqueid: res.ePPM_UniqueId,
                        //eppmname: res.ePPM_Name,
                        label: res.eppmUniqueId,
                        value: res.eppmName,
                        id: res.id,
                        name: res.eppmUniqueId
                    }
                })

                return {
                    options: options,
                    hasMore: response.data.currentPage !== response.data.pageCount,
                    additional: {
                        page: page + 1,
                    },
                }
            } else {
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                }
            }
        } catch (error) {
            this.setState({
                page: {}
            })
        }
    }
    render() {
        return (
            <AsyncSelect
                loadOptions={this.loadeppm}
                className={this.props.className}
                tooltipText={this.props.tooltipText}
                errorText={this.props.error}
                value={this.props.eppm}
                additional={{
                    page: 1
                }}
                handleChangeSingle={this.props.onChange}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                label={this.props.label}
                width={this.props.width ? this.props.width : "100%"}
            />
        )
    }
}
export default ePPMPicker;