import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isDialogVisible: false,
    message: ''
}
const errorDialogReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.OPEN_INFO_DIALOG: {
            return {
                isDialogVisible: true,
                message: action.payload
            }
        }
        case actionTypes.CLOSE_INFO_DIALOG: {
            return {
                isDialogVisible: false,
                message: ''
            }
        }
        default: {
            return state;
        }
    }
}
export default errorDialogReducer;