import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/Navbar'; 
import * as vendorActions from '../../../../store/actions/vendors';
import VendorPickList from './VendorPickList';

const styles = {
    container: {
        padding: 48,
        display: 'flex',
        justifyContent: 'center'
    },
    twoColumn: {
        margin: '0 auto',
        display: 'inline-block',
        width: 640,
        padding: 20,
        verticalAlign: 'top',
    },
    twoColumnWithCheckbox: {
        display: 'inline-block',
        width: 690,
        padding: 20,
        verticalAlign: 'top',
    },
    oneColumn: {
        display: 'inline-block',
        width: 390,
        padding: 20,
        verticalAlign: 'top'
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteNavbarStatusComment: () =>
        dispatch(actionCreators.setNavbarStatusComment("")),
    getVendors: () =>
        dispatch(vendorActions.getVendorsStarted())

});

class StaticPickLists extends Component {

    componentDidMount() {
        this.props.getVendors();
    }

    componentWillUnmount() {
        this.props.deleteNavbarStatusComment();
    }
    render() {
        return (
            <React.Fragment>
            <div className={this.props.classes.container}>
                <div className={this.props.classes.twoColumn}>
                    <VendorPickList />
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export default compose(withStyles(styles), connect(null, mapDispatchToProps))(StaticPickLists)