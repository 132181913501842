import React from 'react';
import AutoSelect from '../../../../components/Input/AutoSelect';
import Input from '../../../../components/Input/Input';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import vendorTypes from '../../../../constants/vendorTypes'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import MomentUtils from '@date-io/moment';
  import moment from 'moment-timezone';
import Checkbox from '@material-ui/core/Checkbox';

moment.tz.setDefault('UTC');
class UTCUtils extends MomentUtils {
    format(value, formatString) {
        if (formatString === 'MMMM Do') {
            formatString = 'DD/MM/YYYY'
        }
        return moment(value)
            .utc()
            .format(formatString);
    }
}

const WbsData =  (props) => {
    return (
        <React.Fragment>
            <AutoSelect 
                suggestions={props.data.activities}
                errorText={props.data.errors.activity} 
                value={props.data.activity}
                disabled={props.data.alreadyInUse}
                handleChangeSingle={props.wbsFormActivityNameChanged}
                label="Activity name" 
                width="100%"
            />
            <Input
                width="100%"
                label="Care cost center"
                errorText={props.data.errors.careCc}
                onChange={props.wbsFormCareCcChanged}
                value={props.data.careCc || ''} 
            />
            <AutoSelect 
                suggestions={props.costTypes}
                errorText={props.data.errors.costType} 
                value={props.data.costType || ''}
                handleChangeSingle={props.wbsFormCostTypeChanged}
                label="Cost Type" 
                width="100%"
            />
            <AutoSelect 
                suggestions={props.poLocations}
                errorText={props.data.errors.poLocation} 
                value={props.data.poLocation || ''}
                handleChangeSingle={props.wbsFormPoLocationChanged}
                label="Po Location" 
                width="100%"
            />
            <div>
                <RadioButtons 
                    header="Vendor Type" 
                    horizontal={true} 
                    errorText={props.data.errors.vendorType} 
                    onChange={props.wbsFormVendorTypeChanged} 
                    options={[{label:vendorTypes[0], value: 0}, {label:vendorTypes[1], value: 1}]} 
                    value={props.data.vendorType || ""} 
                />
            </div>
            <Input
                width="100%"
                label="C Project"
                errorText={props.data.errors.cProject}
                onChange={props.wbsFormCProjectChanged}
                value={props.data.cProject || ''} 
            />
            <Input
                width="100%"
                label="C WBS"
                errorText={props.data.errors.cWbs}
                onChange={props.wbsFormCWbsChanged}
                value={props.data.cWbs || ''} 
            />
            <Input
                width="100%"
                label="PO CC"
                errorText={props.data.errors.poCc}
                onChange={props.wbsFormPoCcChanged}
                value={props.data.poCc || ''} 
            />
            <Input
                width="100%"
                label="PO WBS"
                errorText={props.data.errors.poWbs}
                onChange={props.wbsFormPoWbsChanged}
                value={props.data.poWbs || ''} 
            />
            <Input
                width="100%"
                label="WBS Description Name"
                errorText={props.data.errors.description}
                onChange={props.wbsFormPoWbsDescriptionChanged}
                value={props.data.description || ''} 
            />
            {/* <Input
                width="100%"
                label="Depreciation start date"
                onChange={props.wbsFormDeprecationStartDateChanged}
                type="date"
                value={props.data.depreciationStartDate || ''} 
            /> */}
            <div style={{width: '100%'}}>
            <MuiPickersUtilsProvider utils={UTCUtils}>
                <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    InputProps={{
                        disableUnderline: true,
                        disabled: true
                    }}
                    error={false}
                    variant="dialog"
                    label="Depreciation start date"
                    emptyLabel=" "
                    value={props.data.depreciationStartDate || null} 
                    onChange={(e) => {props.wbsFormDeprecationStartDateChanged(moment.utc(e.format('YYYY-MM-DD')).utc().format())}}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                
            </MuiPickersUtilsProvider>
            </div>

            <Input
                width="100%"
                label="Depreciation duration"
                onChange={props.wbsFormDeprecationDurationChanged}
                value={props.data.depreciationDuration || ''} 
            />
            <Input
                width="100%"
                label="Depreciation cost center"
                onChange={props.wbsFormDeprecationCostCenterChanged}
                value={props.data.depreciationCostCenter || ''} 
            />
            <Checkbox color="primary" checked={props.data.isDeleted || false} onChange={props.inactiveChanged}/>Inactive        
        </React.Fragment>
    )
}
export default formSectionWrapper(WbsData, ""); 