import * as actionTypes from '../actions/actionTypes';
const tabs = [
    {
        name: "Budget plans by cost center",
        id: 1,
        value: true
    },
    {
        name: "Budget plans by activity",
        id: 2,
        value: false
    },
]
const initialState = {
    regionalChangelogs: {},
    loading: false,
    tabs: tabs,
    planByCostCenter: tabs[0],
    notificationModalOpen: false,
    notificationModalLoading: false,
    notificationModalQuestion: "",
    sendingNotifications: false,
    notificationModalDisabled: true
}
const regionalChangelogsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_DISABLED_CHANGED: {
            return {
                ...state,
                notificationModalDisabled: action.payload
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_STARTED: {
            return {
                ...state,
                sendingNotifications: true
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_FINISHED: {
            return {
                ...state,
                sendingNotifications: false
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_FAILED: {
            return {
                ...state,
                sendingNotifications: false
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_VISIBILITY_CHANGED: {
            return {
                ...state,
                notificationModalOpen: action.payload,
                notificationModalDisabled: true
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_LOADING_CHANGED: {
            return {
                ...state,
                notificationModalLoading: action.payload
            }
        }
        case actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_QUESTION_CHANGED: {
            return {
                ...state,
                notificationModalQuestion: action.payload
            }
        }
        case actionTypes.INITIALIZE_REGIONAL_CHANGELOG_LIST: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_FAILED: {
            return {
                ...state,
                columns: []
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOGS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOGS_FINISHED: {
            return {
                ...state,
                loading: false,
                regionalChangelogs: action.payload
            }
        }
        case actionTypes.GET_REGIONAL_CHANGELOGS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.REGIONAL_CHANGELOGS_TAB_CHANGED: {
            return {
                ...state,
                planByCostCenter: action.payload
            }
        }
        default: {
            return state;
        }
    }
}
export default regionalChangelogsReducer;