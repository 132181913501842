import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import history from '../../../history';

const mapStateToProps = (state) => {
    return {
        submitting: state.resourceModelForm.submitting,
    }
};

const mapDispatchToProps = (dispatch) => ({
    submitFormStarted: () => 
        dispatch(resourceModelFormActions.resourceModelFormSubmitStarted()),
    resetForm: () => 
        dispatch(resourceModelFormActions.resetResourceModelForm())
    }
);

class FormSubmitPanelContainer extends Component {
    cancelForm = () => {
        this.props.resetForm();
        history.push('/resourcemodel');
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 100}}>
                <Fab variant="extended" className="form-submit-panel form-cancel" onClick={this.cancelForm}>Cancel</Fab>
                <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-submit" onClick={this.props.submitFormStarted}>Submit</Fab>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitPanelContainer);