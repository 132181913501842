import * as actionTypes from '../actions/actionTypes';

const initialState = {
    visible: false,
    message: ''
}

const backgroundTaskReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.BACKGROUND_TASK_VISIBILITY_CHANGED: {
            return {
                ...state,
                visible: action.payload
            }
        }
        case actionTypes.BACKGROUND_TASK_MESSAGE_CHANGED: {
            return {
                ...state,
                message: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default backgroundTaskReducer;