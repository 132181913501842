import React from 'react';
import DeliveryInformationContainer from './DeliveryInformationContainer';
import Grid from '@material-ui/core/Grid';
import Status from './components/Status';
import LastModifiedBy from './components/LastModifiedBy';
import SubmittedBy from './components/SubmittedBy';
import ApprovedBy from './components/ApprovedBy';
import Roles from './components/Roles';
import ResourceItem from './ResourceItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isVendorTypeExternal } from '../../../../utils/typesAssertions';
import CreatedByContainer from './CreatedByContainer';
import Tags from './components/Tags';

const useStyles = makeStyles(theme =>({
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        display: 'flex',
        position: 'relative'
    },
}));
const canEdit = () => {
    return false;
}
const placeholderFunc = () => {};

const Comparator = ({history, index}) => {
    const currentRevision = history[index];
    const classes = useStyles();
    const changes = getChanges(currentRevision, history[index+1]);
    return (
        <div className={classes.container} id="delivery-confirmation-form">
        <Grid container className="form-grid" spacing={2}>
            <Grid item xs={12}>
                <Status status={currentRevision.statusIndex} vendorType={currentRevision.vendorType} oldStatus={changes.status}/>
            </Grid>
            <Grid item xs={12}>
                <Tags tags={currentRevision.tags}  changes={changes}/>
            </Grid>
            <Grid item md={6} xs={12}>
                <CreatedByContainer />
            </Grid>
            <Grid item md={6} xs={12}>
                <LastModifiedBy lastModifiedBy={currentRevision.lastModifiedBy} />
            </Grid> 
            <Grid item md={6} xs={12}>
                <SubmittedBy actualsSubmittedBy={currentRevision.actualsSubmittedBy}/>
            </Grid>   
            <Grid item md={6} xs={12}>
                <ApprovedBy actualsApprovedBy={currentRevision.actualsApprovedBy}/>
            </Grid>                 
            <Grid item md={6} xs={12}>
                <DeliveryInformationContainer />
            </Grid>
            <Grid item md={6} xs={12}>
                <Roles 
                    changes={changes}
                    vendor={currentRevision.vendor}
                    canEdit={canEdit}
                    vendorType={currentRevision.vendorType}
                    supplierRepresentative={currentRevision.supplierRepresentative}
                    dcApprover={currentRevision.dcApprover}
                />
            </Grid>
            <Grid item xs={12}>
                <ResourceItem 
                    {...currentRevision} 
                    errors={{}}
                    hideSubmitPanel={true}
                    viewMode={true}
                    changes={changes}
                    attachments={currentRevision.attachments.map((attach) => {
                        return {
                            name: attach
                        }
                    })}
                    attachmentRemoved={placeholderFunc}
                    attachmentAdded={placeholderFunc}
                    downloadAttachment={placeholderFunc}
                />
            </Grid>
        </Grid>
    </div>
    )
}
const getChanges = (currentRevision, previousRevision) => {
    const differences = {
        overtimeHours: currentRevision.overtimeHours,
        attachments: []
    };
    if (!previousRevision) {
        return differences;
    }
    if (isVendorTypeExternal(currentRevision.vendorType)) {
        if (currentRevision.supplierRepresentative.label !== previousRevision.supplierRepresentative.label) {
            differences.supplierRepresentative = previousRevision.supplierRepresentative.label;
        }
    }
    if (currentRevision.dcApprover.label !== previousRevision.dcApprover.label) {
        differences.dcApprover = previousRevision.dcApprover.label;
    }
    if (currentRevision.actualUnitPrice !== previousRevision.actualUnitPrice) {
        differences.actualUnitPrice = previousRevision.actualUnitPrice !== undefined ? previousRevision.actualUnitPrice : "0";
    }

    if (currentRevision.actualUnitPrice !== previousRevision.actualUnitPrice) {
        differences.actualUnitPrice = previousRevision.actualUnitPrice !== undefined ? previousRevision.actualUnitPrice : "0";
    }
    if (currentRevision.status !== previousRevision.status) {
        differences.status = previousRevision.status;
    }
    if (currentRevision.actualPerDiem !== previousRevision.actualPerDiem) {
        differences.actualPerDiem = previousRevision.actualPerDiem !== undefined ? previousRevision.actualPerDiem : "0";
    }
    differences.overtimeHours = {deleted:[]};
    currentRevision.overtimeHours.forEach((overtime) => {
        const previousOvertime = previousRevision.overtimeHours.find((previousOvertime) => {
            return previousOvertime.rate === overtime.rate;
        })
        if (previousOvertime && overtime.numberOfExtraHours !== previousOvertime.numberOfExtraHours) {
            differences.overtimeHours[overtime.rate] = previousOvertime.numberOfExtraHours;
        } else if (!previousOvertime) {
            differences.overtimeHours[overtime.rate] ="0";
        }
    })
    previousRevision.overtimeHours.forEach((previousOvertime) => {
        if(!currentRevision.overtimeHours.find((overtime) => {
            return overtime.rate === previousOvertime.rate
        })) {
            differences.overtimeHours.deleted.push({...previousOvertime})
        }
    });
    differences.attachments = {deleted: []};
    currentRevision.attachments.forEach((attachment) => {
        if (previousRevision.attachments.find((prevAttachment) => {
            return prevAttachment === attachment;
        })) {
            differences.attachments[attachment]= ""
        } else {
            differences.attachments[attachment] = "added"
        }
    });
    previousRevision.attachments.forEach((attachment) => {
        if(!currentRevision.attachments.find((currAttachment) => {
            return currAttachment === attachment
        })) {
            differences.attachments.deleted.push({name: attachment, type: "deleted"});
        }
    });

    differences.tags = ""
    let tagsChanged = false;
    if (currentRevision.tags) {
        currentRevision.tags.forEach((tag) => {
            if (!previousRevision.tags || !previousRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        })
    }
    if (previousRevision.tags) {
        previousRevision.tags.forEach((tag) => {
            if (!currentRevision.tags || !currentRevision.tags.find((prevtag) => {
                return tag.label === prevtag.label;
            })) {
                tagsChanged = true;
            }
        }) 
    }
    if (tagsChanged) {
        differences.tags = previousRevision.tags ? previousRevision.tags.map(tag => tag.label).join(', ') : "Default"
    }

    return differences;
}
export {Comparator as default, getChanges };