import * as actionTypes from './actionTypes';

export const regionalChangelogFormSubmitStarted =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_STARTED, payload: myParam};
};
export const regionalChangelogFormSubmitFailed =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_FAILED, payload: myParam};
};
export const regionalChangelogFormSubmitFinished =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_FINISHED, payload: myParam};
};
export const regionalChangelogFormResetForm =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM, payload: myParam};
};

export const regionalChangelogDomainChanged =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_DOMAIN_CHANGED, payload: myParam};
};
export const regionalChangelogSubDomainChanged =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED, payload: myParam};
};
export const regionalChangelogCostCenterChanged =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_COST_CENTER_CHANGED, payload: myParam};
};
export const regionalChangelogBudgetYearChanged =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_BUDGET_YEAR_CHANGED, payload: myParam};
};
export const regionalChangelogSetState =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_SET_STATE, payload: myParam};
};

export const initializeRegionalChangelogForm =  (myParam) => {
    return {type: actionTypes.INITIALIZE_REGIONAL_CHANGELOG_FORM, payload: myParam};
};
export const initializeRegionalChangelogFormFinished =  (myParam) => {
    return {type: actionTypes.INITIALIZE_REGIONAL_CHANGELOG_FORM_FINISHED, payload: myParam};
};
export const initializeEditRegionalChangelogForm =  (myParam) => {
    return {type: actionTypes.INITIALIZE_EDIT_REGIONAL_CHANGELOG_FORM, payload: myParam};
};

export const getRegionalChangelogTagsFinished =  (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOG_TAGS_FINISHED, payload: myParam};
};
export const regionalChangelogTagsChanged =  (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_TAGS_CHANGED, payload: myParam};
};
export const regionalChangelogLineTagsChanged =  (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_LINE_TAGS_CHANGED, payload: myParam, index};
};
export const getRegionalChangelogLineTagsFinished =  (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOG_LINE_TAGS_FINISHED, payload: myParam};
};


export const regionalChangelogResourceItemCostCenterChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemActivityChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemCostTypeChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemWbsChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemExpenseTypeChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemCurrencyChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED, payload: myParam, index}
}

export const regionalChangelogResourceItemPlansChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_PLANS_CHANGED, payload: myParam, index}
}
export const regionalChangelogResourceItemActualsChanged = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTUALS_CHANGED, payload: myParam, index}
}

export const regionalChangelogAddResourceItem = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_ADD_RESOURCE_ITEM, payload: myParam}
}
export const regionalChangelogDeleteResourceItem = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_DELETE_RESOURCE_ITEM, payload: myParam}
}
export const regionalChangelogFormErrorsChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_ERRORS_CHANGED, payload: myParam}
}

export const regionalChangelogResourceItemGetActivitiesStarted = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_STARTED, payload: myParam, index}
}
export const regionalChangelogResourceItemGetActivitiesFailed = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FAILED, payload: myParam, index}
}
export const regionalChangelogResourceItemGetActivitiesFinished = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED, payload: myParam, index}
}

export const regionalChangelogActivitiesChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_ACTIVITIES_CHANGED, payload: myParam};
}
export const regionalChangelogChangeLoadingSpinner = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_CHANGE_LOADING_SPINNER, payload: myParam}
}
export const regionalChangelogResourceItemGetWbsesStarted = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_STARTED, payload: myParam, index}
}
export const regionalChangelogResourceItemGetWbsesFailed = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FAILED, payload: myParam, index}
}
export const regionalChangelogResourceItemGetWbsesFinished = (index, myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED, payload: myParam, index}
}

export const initializeRegionalChangelogList = (myParam) => {
    return {type: actionTypes.INITIALIZE_REGIONAL_CHANGELOG_LIST, payload: myParam};
}
export const getRegionalChangelogColumnsStarted = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_STARTED, payload: myParam};
}
export const getRegionalChangelogColumnsFinished = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_FINISHED, payload: myParam};
}
export const getRegionalChangelogColumnsFailed = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_FAILED, payload: myParam};
}
export const getRegionalChangelogsStarted = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOGS_STARTED, payload: myParam};
}
export const getRegionalChangelogsFinished = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOGS_FINISHED, payload: myParam};
}
export const getRegionalChangelogsFailed = (myParam) => {
    return {type: actionTypes.GET_REGIONAL_CHANGELOGS_FAILED, payload: myParam};
}
export const updateRegionalChangelogColumns = (myParam) => {
    return {type: actionTypes.UPDATE_REGIONAL_CHANGELOG_COLUMNS, payload: myParam};
}
export const regionalChangelogsTabChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOGS_TAB_CHANGED, payload: myParam};
}
export const regionalChangelogHistoryChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_HISTORY_CHANGED, payload: myParam};
}

export const sendBudgetPlanNotificationsModalVisibilityChanged = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_VISIBILITY_CHANGED, payload: myParam};
}
export const sendBudgetPlanNotificationsModalLoadingChanged = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_LOADING_CHANGED, payload: myParam};
}
export const sendBudgetPlanNotificationsModalQuestionChanged = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_QUESTION_CHANGED, payload: myParam};
}
export const sendBudgetPlanNotificationsStarted = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_STARTED, payload: myParam};
}
export const sendBudgetPlanNotificationsFailed = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_FAILED, payload: myParam};
}
export const sendBudgetPlanNotificationsFinished = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_FINISHED, payload: myParam};
}
export const sendBudgetPlanNotificationsModalDisabledChanged = (myParam) => {
    return {type: actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_DISABLED_CHANGED, payload: myParam};
}
export const regionalChangelogFormCommentChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_COMMENT_CHANGED, payload: myParam};
}
export const regionalChangelogFormLatestChangesChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_LATEST_CHANGES_CHANGED, payload: myParam};
}
export const regionalChangelogFormshowChangesChanged = (myParam) => {
    return {type: actionTypes.REGIONAL_CHANGELOG_FORM_SHOW_CHANGES_CHANGED, payload: myParam};
}