import * as actionTypes from '../actions/actionTypes';

const regionalResourceReducer = (state, action) => {
    switch(action.type) {
        case actionTypes.REGIONAL_CHANGELOG_LINE_TAGS_CHANGED: {
            return {
                ...state,
                tags: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FAILED: {
            return {
                ...state,
                activities: []
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED: {
            return {
                ...state,
                activities: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_STARTED:
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FAILED: {
            return {
                ...state,
                wbses: []
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED: {
            return {
                ...state,
                wbses: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_PLANS_CHANGED: {
            return {
                ...state,
                plans: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTUALS_CHANGED: {
            return {
                ...state,
                actuals: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED: {
            return {
                ...state,
                costCenter: action.payload
            }
        }

        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED: {
            return {
                ...state,
                activity: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED: {
            return {
                ...state,
                costType: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_WBS_CHANGED: {
            return {
                ...state,
                wbs: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_EXPENSE_TYPE_CHANGED: {
            return {
                ...state,
                expenseType: action.payload
            }
        }
        case actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_CURRENCY_CHANGED: {
            return {
                ...state,
                currency: action.payload
            }
        }
        default: {
            return state;
        }    
    }
}
export default regionalResourceReducer;