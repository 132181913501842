import { put, takeEvery, take, race, call, select, takeLatest, delay, fork } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as deliveryConfirmationActions from '../store/actions/deliveryConfirmationActions';
import * as deliveryConfirmationFormActionsCreators from '../store/actions/deliveryConfirmationFormActions';
import axios, { axiosForFileUploadFunction } from '../axios';
import history from '../history';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import {deleteFiles, attachFiles} from './formAttachmentSagas';
import { canFunction } from '../config/Can';
import * as rules from '../config/Rules';
import pdf from '../Pdf';
import {saveAs} from 'file-saver';
import statusesInternal from '../constants/dcStatusesInternal';
import statusesExternal from '../constants/dcStatusesExternal';
import formatDate from '../utils/dateFormatter';
import historyDataFormatter from '../utils/historyDataMapper';
import { isVendorTypeExternal, isPerDiemVisible } from '../utils/typesAssertions';
import { resourceTypesEnums } from '../constants/resourceTypes';
import { dcStatusesEnums } from '../constants/dcStatuses';
import scrollToErrorSaga from './scrollToErrorSaga';
import {getChanges} from '../views/MainPage/DeliveryConfirmationForm/Form/DeliveryConfirmationRevisionComparator';
import * as commentUtils from '../utils/commentUtils';

function* initializeDcForm(action, disableHistory) {
    try {
    const dc = yield axios.get('api/dc/'+ action.payload);
    if (!disableHistory) {
        yield fork(getDcHistorySaga, action.payload, dc.data);
    }
    const data = dc.data
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormIdChanged(data.id));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormVendorTypeChanged(data.vendorType));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormResourceTypeChanged(data.resourceType));
    if (data.submittedBy && data.submittedBy.name) {
        yield put(
            deliveryConfirmationFormActionsCreators
            .deliveryConfirmationFormActualsSubmittedByChanged(data.submittedBy.userEmail  + "\n" + formatDate(data.submittedDate)));
    }
    if (data.approvedBy && data.approvedBy.name) {
        yield put(deliveryConfirmationFormActionsCreators
            .deliveryConfirmationFormActualsApprovedByChanged(data.approvedBy.userEmail + '\n' + formatDate(data.approvedDate)));
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormAssignmentSiteChanged(data.assignmentSite));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormActivityNameChanged(data.activityName));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormDeliverableChanged(data.deliverable));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormPoNumberChanged(data.poNumber));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormYearChanged(data.year));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormMonthChanged(data.month));
    if (canFunction('', rules.VIEW_TAGS_PICKLIST)) {
        const tags = yield axios.get('api/tags/deliveryConfirmation');
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormTagsSuggestionsChanged(tags.data.map((tag) => {
            return {
                label: tag.value,
                value: tag.id
            }
        })))
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormTagsChanged(data.tags.map((tag) => {
        return {
            label: tag.value,
            value: tag.id
        }
    })));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormVendorChanged(data.vendorName));
    const login = yield select((state) => state.user.login);
    const email = yield select((state) => state.user.username);
    if (isVendorTypeExternal(data.vendorType)) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSupplierRepresentativeChanged({
            label: data.supplierRepresentative.name,
            name: data.supplierRepresentative.name,
            accountId: data.supplierRepresentative.accountId,
            login: data.supplierRepresentative.login,
            id: data.supplierRepresentative.id,
            value: data.supplierRepresentative.userEmail}));
        let isSupplier;
        if (login) {
            isSupplier = data.supplierRepresentative.login === login
        } else {
            isSupplier = data.supplierRepresentative.userEmail === email
        }
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationIsSupplierChanged(isSupplier))    
    }
    let isApprover
    if (login) {
        isApprover = data.approver.login === login
    } else {
        isApprover = data.approver.userEmail === email;
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationIsApprovedChanged(isApprover)) 
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormDcApproverChanged({
        label: data.approver.name,
        name: data.approver.name,
        accountId: data.approver.accountId,
        login: data.approver.login,
        value: data.approver.userEmail,
        id: data.approver.id
    }));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormGradeChanged(data.grade));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormUnitRateChanged(data.unitPrice));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormPerDiemRateChanged(data.perDiemRate));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormCurrencyChanged(data.currency));
    
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormPlannedUnitPriceChanged(data.planned));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormActualUnitPriceChanged(data.actualUnitPrice));
    if (isPerDiemVisible(data.resourceType, data.assignmentSite)) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormPlannedPerDiemChanged(data.planned));
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormActualPerDiemChanged(data.actualPerDiemRate));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormOvertimeChanged(data.isOvertime));
    const overtimeParsed = data.overtimes.map((overtime) => {
        return {
            key: Math.random(),
            rate: overtime.percentageOfTheOvertime,
            numberOfExtraHours: overtime.amountOvertimeHours  
        }
    })
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormOvertimeHoursChanged(overtimeParsed));
    if (data.createdBy) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormCreatedByChanged(data.createdBy.userEmail + '\n' + formatDate(data.createdDate)));
    }
    if (data.lastModifiedBy) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormLastModifiedByChanged(data.lastModifiedBy.userEmail + '\n' + formatDate(data.lastModifiedDate)));
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormStatusChanged(data.status));
    for (let i = 0; i < data.attachments.length; i++) {
        data.attachments[parseInt(i)].key = data.attachments[parseInt(i)].id;
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormAttachmentAdded({target: {files:[data.attachments[parseInt(i)]]}}));
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormCommentsChanged(data.comments.map((comment) => {
        return {
            ...comment,
            message: commentUtils.getEditorState(comment.message)
        }
    })))
    if (data.status === dcStatusesEnums.ToBeCorrected && data.rejectReason) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormRejectReasonChanged(commentUtils.getEditorState(data.rejectReason)))
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormDcNumberChanged(data.dcNumber));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormRmIdChanged(data.resourceModelId));
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormClIdChanged(data.changelogId));
    yield put(deliveryConfirmationFormActionsCreators.initializeDeliveryConfirmationFormFinished());
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while loading form. Please try again later."));
        yield put(deliveryConfirmationFormActionsCreators.initializeDeliveryConfirmationFormFailed());
    }
}

// function* handle
function* handleActionButton(action) {
    try {
        const formState = yield select((state) => state.deliveryConfirmationForm);
        const errors = validate(formState, action.payload);
        if (Object.keys(errors).length) {
            yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormErrorsChanged(errors));
            yield* scrollToErrorSaga();
            return;
        }
        let url;
        switch(action.payload) {
            case ('back'): {
                if (history.location.pathname.indexOf('deliveryconfirmation/view/') !== -1) {
                    if (canFunction('', rules.NAVIGATE_DELIVERY_REVIEW)) {
                        history.push('/deliveryconfirmation/viewMy');
                    } else {
                        history.push('/deliveryconfirmation/viewAll');
                    }
                    yield put(deliveryConfirmationFormActionsCreators.resetForm());
                    return;
                }
                const splittedUrl = history.location.pathname.split('/');
                let url = '';
                for (let i = 1; i< splittedUrl.length -1; i++) {
                    url = url + "/" +splittedUrl[parseInt(i)];
                }
                history.push(url);
                yield put(deliveryConfirmationFormActionsCreators.resetForm());
                return;
            }
            case ('edit'): {
                yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormEditStateChanged(true));
                return;
            }
            case ('save'): {
                url="api/dc";
                break;
            }
            case ('generatePdf'): {
                const modifiedFormState = {...formState};
                modifiedFormState.lastModifiedBy = formState.lastModifiedBy ? formState.lastModifiedBy.split('\n')[0] + '\n' +  formState.lastModifiedBy.split('\n')[1].split(' ')[0] : "";
                modifiedFormState.actualsApprovedBy = formState.actualsApprovedBy ? formState.actualsApprovedBy.split('\n')[0] + '\n' +  formState.actualsApprovedBy.split('\n')[1].split(' ')[0] : "";
                modifiedFormState.actualsSubmittedBy = formState.actualsSubmittedBy ? formState.actualsSubmittedBy.split('\n')[0] + '\n' +  formState.actualsSubmittedBy.split('\n')[1].split(' ')[0] : "";
                const document = yield pdf(modifiedFormState);
                saveAs(document, `DeliveryConfirmation_${formState.id}.pdf`);
                return;
            }
            case ('submitForApproval'): {
                url="api/dc/actions/submitForApproval";
                break;
            }
            case ('submitAndApprove'): {
                yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSubmitAndApproveModalStateChanged(true));
                return;
            }
            case ('submitUpdated'): {
                url="api/dc/actions/submitForApproval";
                break;
            }
            case ('restart'): {
                url="api/dc/actions/restart";
                break;
            }
            case('process'): {
                url="api/dc/actions/process";
                break;
            }
            case('approve'): {
                url="api/dc/actions/approve";
                break;
            }
            case('reject'): {
                yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormRejectModalStateChanged(true));
                return;
            }
            case('processingNotApplicable'): {
                url="api/dc/actions/processingNotApplicable";
                break;
            }
            case('submitAndApproveModal'): {
                yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSubmitAndApproveModalStateChanged(false));
                url="api/dc/actions/submitAndApprove";
                break;
            }
            case('rejectModal'): {
                yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormRejectModalStateChanged(false));
                url="api/dc/actions/reject";
                break;
            }
            default: {
                break;
            }
        }
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSubmitStarted())
        yield* handleAction(formState, url);
        return;
    } catch(error) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSubmitFailed())
        yield put(errorDialogActions.openErrorDialog("There was an error while performing action. Please try again later."));
    }
}
function validate(formState, button) {
    const errors = {};
    if (button === 'restart') {
        if (commentUtils.getPureText(formState.comment).length === 0) {
            errors['comment'] = "Please explain the restart reason"
        }
    }
    if (button === 'submitForApproval' 
    || button === 'submitAndApprove' 
    || button === 'submitUpdated'
    || button === 'approve'
    || button === 'process') {
        if (!formState.actualUnitPrice && formState.actualUnitPrice !== 0) {
            errors['actualUnitPrice'] = "Actual number of units cannot be empty.";
        }
        if (isPerDiemVisible(formState.resourceType, formState.assignmentSite)) {
            if (!formState.actualPerDiem && formState.actualPerDiem !== 0) {
                errors['actualPerDiem'] = "Actual number of per diems cannot be empty.";
            }
        }
        if (isVendorTypeExternal(formState.vendorType) && formState.resourceType === resourceTypesEnums["T&E"]) {
            if (!formState.attachments || formState.attachments.length === 0) {
                errors['attachments'] = "At least one attachment is required."
            }
        }
    }
    if (button === 'save' 
    && (formState.status === dcStatusesEnums.Completed || formState.status === dcStatusesEnums.ToBeApproved || formState.status === dcStatusesEnums.Approved)) {
        if (isVendorTypeExternal(formState.vendorType) && formState.resourceType === resourceTypesEnums["T&E"]) {
            if (!formState.attachments || formState.attachments.length === 0) {
                errors['attachments'] = "At least one attachment is required."
            }
        }
        if (!formState.actualUnitPrice && formState.actualUnitPrice !== 0) {
            errors['actualUnitPrice'] = "Actual number of units cannot be empty.";
        }
        if (isPerDiemVisible(formState.resourceType, formState.assignmentSite)) {
            if (!formState.actualPerDiem && formState.actualPerDiem !== 0) {
                errors['actualPerDiem'] = "Actual number of per diems cannot be empty.";
            }
        }
    }
    return errors;
}

function* handleAction(formState, url) {
    const obj = {
        approverId: formState.dcApprover.id,
        id: formState.id,
        tags: formState.tags ? formState.tags.map((tag) => {
            return tag.value
        }) : null,
        actualUnitPrice: formState.actualUnitPrice,
        comment: commentUtils.getPureText(formState.comment).length ? commentUtils.prepareEditorStateForSendingToDatabase(formState.comment) : "",
    }
    if (url === 'api/dc/actions/process') {
        const modifiedFormState = {...formState};
        const name = yield select((state) => state.user.username)
        modifiedFormState.status = dcStatusesEnums.Completed;
        modifiedFormState.lastModifiedBy = name + '\n' + new Date().toLocaleDateString();
        modifiedFormState.actualsApprovedBy = formState.actualsApprovedBy ? formState.actualsApprovedBy.split('\n')[0] + '\n' + formState.actualsApprovedBy.split('\n')[1].split(' ')[0] : "";
        modifiedFormState.actualsSubmittedBy = formState.actualsSubmittedBy ? formState.actualsSubmittedBy.split('\n')[0] + '\n' + formState.actualsSubmittedBy.split('\n')[1].split(' ')[0] : "";
        const document = yield pdf(modifiedFormState);
        const header = formState.dcApprover.value + (isVendorTypeExternal(formState.vendorType) ?"," + (formState.supplierRepresentative.value) : "");
        const dataForFileUpload = yield axios.get(`s3/documents/uploadUrl/dc/DeliveryConfirmation_${formState.id}.pdf`, {
            headers: {
                'allowed-users': header
            }
        });
        const axiosInstanceForFileUpload = axiosForFileUploadFunction(dataForFileUpload.data.uploadUrl);
        yield axiosInstanceForFileUpload.put('', document, {
            headers: {
                'x-amz-meta-allowed-users': header
            }
        });
        obj.pdfUrl = dataForFileUpload.data.fileName;
    }
    if (isVendorTypeExternal(formState.vendorType)) {
        obj.supplierRepresentativeId = formState.supplierRepresentative.id;
    }
    if (commentUtils.getPureText(formState.rejectReason).length) {
        obj.rejectReason = commentUtils.prepareEditorStateForSendingToDatabase(formState.rejectReason);
    }
    if (formState.resourceType === resourceTypesEnums["T&M"]) {
        if (formState.actualPerDiem !== null && formState.actualPerDiem !== undefined) {
            obj.actualPerDiemRate  = formState.actualPerDiem;
        }
        const overtimesMap = {};
        if (formState.overtime) {
            const overtimes = [];
            formState.overtimeHours.forEach((item) => {
                if (!item.numberOfExtraHours) {
                    return;
                }
                if (!overtimesMap[item.rate]) {
                    overtimesMap[item.rate] = parseFloat(item.numberOfExtraHours);
                } else {
                    overtimesMap[item.rate] = overtimesMap[item.rate] + parseFloat(item.numberOfExtraHours);
                }
            });
            Object.keys(overtimesMap).forEach((key) => {
                overtimes.push({
                    amountOvertimeHours: overtimesMap[key],
                    percentageOfTheOvertime: parseFloat(key)
                })
            })
            obj.overtimes = overtimes;
        }
    }
    const oldObject = yield axios.get('api/dc/'+ formState.id);
    const val = yield* attachFiles(formState.attachments);
    obj.attachments = val;
    yield axios.post(url, obj);
    yield* deleteFiles(formState.attachments, oldObject.data.attachments);
    const splittedUrl = history.location.pathname.split('/');
    let url2 = '';
    for (let i = 1; i < 3; i++) {
        url2 = url2 + "/" +splittedUrl[parseInt(i)];
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormSubmitFinished())
    if (history.location.pathname.indexOf('deliveryconfirmation/view/') !== -1) {
        if (canFunction('', rules.NAVIGATE_DELIVERY_REVIEW)) {
            history.push('/deliveryconfirmation/viewMy');
        } else {
            history.push('/deliveryconfirmation/viewAll');
        }
    } else {
        history.push(url2);
    }

}

function* initializeDcFormWrapperSaga(action) {
    yield race({
        task: call(initializeDcForm, action),
        cancel: take(actionTypes.DELIVERY_CONFIRMATION_RESET_FORM)
    })
}

function* statusDcSaga(action) {
    yield put(deliveryConfirmationActions.deliveryConfirmationListChangeLoadingSpinner(true));
    yield* initializeDcForm(action, true);
    let statusButton;
    if (action.type === actionTypes.DELIVERY_CONFIRMATION_APPROVE_DC) {
        statusButton = 'approve';
    }
    if (action.type === actionTypes.DELIVERY_CONFIRMATION_PROCESSINGNA_DC) {
        statusButton = 'processingNotApplicable';
    }
    if (action.type === actionTypes.DELIVERY_CONFIRMATION_PROCESS_DC) {
        statusButton = 'process';
    }
    yield* handleActionButton({payload: statusButton})
    yield put(deliveryConfirmationActions.deliveryConfirmationListChangeLoadingSpinner(false));

    const domain = yield select(state => state.user.selectedDomain);
    let queryParameters = yield select(state => state.deliveryConfirmation.filters)
    if (!queryParameters) {
        queryParameters = {}
    }
    yield put(deliveryConfirmationActions.getDeliveryConfirmationsStarted({userOnly: false, params: queryParameters, wait: false, domain}))
}

function* rejectDcSaga() {
    const dcId = yield select(state => state.deliveryConfirmation.selectedDc);
    const rejectReason = yield select(state => state.deliveryConfirmation.rejectReason);
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormLoadingSpinnerChanged(true));
    yield* initializeDcForm({payload:dcId}, true);
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormRejectReasonChanged(rejectReason))
    yield* handleActionButton({payload:'rejectModal'});
    yield put(deliveryConfirmationActions.deliveryConfirmationRejectDcModalChanged(false));
    yield put(deliveryConfirmationFormActionsCreators.resetForm());
    yield put(deliveryConfirmationActions.deliveryConfirmationRejectReasonChanged(commentUtils.getEmptyComment()))

    const domain = yield select(state => state.user.selectedDomain);
    let queryParameters = yield select(state => state.deliveryConfirmation.filters)
    if (!queryParameters) {
        queryParameters = {}
    }
    yield put(deliveryConfirmationActions.getDeliveryConfirmationsStarted({userOnly: false, params: queryParameters, wait: false, domain: domain}))
}

function* restartDcSaga() {
    const dcId = yield select(state => state.deliveryConfirmation.selectedDc);
    const comment = yield select(state => state.deliveryConfirmation.comment);
    if (comment.length === 0) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormErrorsChanged({comment: 'Please explain the restart reason'}));
        return;
    }
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormLoadingSpinnerChanged(true));
    yield* initializeDcForm({payload:dcId}, true);
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormCommentChanged(comment));
    yield* handleActionButton({payload:'restart'});
    yield put(deliveryConfirmationActions.deliveryConfirmationRestartDcModalChanged(false));
    yield put(deliveryConfirmationFormActionsCreators.resetForm());
    yield put(deliveryConfirmationActions.deliveryConfirmationCommentTextChanged(commentUtils.getEmptyComment()))
    const domain = yield select(state => state.user.selectedDomain);
    let queryParameters = yield select(state => state.deliveryConfirmation.filters)
    if (!queryParameters) {
        queryParameters = {}
    }
    yield put(deliveryConfirmationActions.getDeliveryConfirmationsStarted({userOnly: false, params: queryParameters, wait: false, domain}))

}

function* commentDcSaga(action) {
    const dcId = yield select(state => state.deliveryConfirmation.selectedDc);
    const comment = yield select(state => state.deliveryConfirmation.comment);
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormLoadingSpinnerChanged(true));
    yield* initializeDcForm({payload:dcId}, true);
    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormCommentChanged(comment));
    yield* handleActionButton({payload:'save'});
    yield put(deliveryConfirmationActions.deliveryConfirmationCommentDcModalChanged(false));
    yield put(deliveryConfirmationFormActionsCreators.resetForm());
    yield put(deliveryConfirmationActions.deliveryConfirmationCommentTextChanged(commentUtils.getEmptyComment()))

    const domain = yield select(state => state.user.selectedDomain);
    let queryParameters = yield select(state => state.deliveryConfirmation.filters)
    if (!queryParameters) {
        queryParameters = {}
    }
    yield put(deliveryConfirmationActions.getDeliveryConfirmationsStarted({userOnly: false, params: queryParameters, wait: false, domain}))
}

function* generatePdf(action) {
    try {
        yield* initializeDcForm(action, true);
        yield* handleActionButton({payload:'generatePdf'});
        yield put(deliveryConfirmationFormActionsCreators.resetForm());
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while generating pdf. Please try again later."));
    }
}

function* submitQuickEdit(action) {
    const formState = yield select((state) => state.deliveryConfirmationForm);
    let status = "submitForApproval";
    if (canFunction('', rules.IS_REQUESTOR) && formState.isApprover && (formState.isSupplier) && (formState.status === dcStatusesEnums.ToBeFilledIn || formState.status === dcStatusesEnums.ToBeCorrected)) {
        status = "submitAndApprove"
    }
    const errors = validate(formState, "submitForApproval");
    if (Object.keys(errors).length) {
        yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormErrorsChanged(errors));
        return;
    }
    yield* handleActionButton({payload: status});
    if (status === "submitForApproval") {
        yield* closeQuickEditSaga();
        return;
    }
    yield take(actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_AND_APPROVE_MODAL_STATE_CHANGED);
    const {submit, close} = yield race({
        submit: take(actionTypes.DELIVERY_CONFIRMATION_FORM_SUBMIT_FINISHED),
        close: take(actionTypes.DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_CHANGED)
    })
    if (submit) {
        yield* closeQuickEditSaga();
    }
}
function* closeQuickEditSaga() {
    yield put(deliveryConfirmationActions.deliveryConfirmationQuickEditModalChanged(false));
    yield put(deliveryConfirmationFormActionsCreators.resetForm());

    const domain = yield select(state => state.user.selectedDomain);
    let queryParameters = yield select(state => state.deliveryConfirmation.filters)
    if (!queryParameters) {
        queryParameters = {}
    }
    yield put(deliveryConfirmationActions.getDeliveryConfirmationsStarted({userOnly: false, params: queryParameters, wait: false, domain: domain}))
}

function* downloadDcFromLink(action) {
    try {
        yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: true});
        const file = yield axios.get('s3/documents/dc/' + action.payload);
        const base64 = file.data;
        const binaryString  = window.atob(base64);
        // const binaryString = base64
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[parseInt(i)] = ascii;
        }
        const blob = new Blob([bytes], {type: 'application/pdf'});
        saveAs(blob, action.payload);
        yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: false});
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while downloading pdf. Please try again later."));
    }
}

function* getDcHistorySaga(id, dc) {
    try {
        let initial = true;
        const fn = function*() {
            const history = yield axios.get(`api/dc/history/${id}`);
            history.data = historyDataFormatter(history.data).map((res, index) => {
                return {
                    ...res,
                    overtimeHours: res.overtimes.map((overtime) => {
                        return {
                            key: Math.random(),
                            rate: overtime.percentageOfTheOvertime,
                            numberOfExtraHours: overtime.amountOvertimeHours  
                        }
                    }),
                    comment: res.comment ? commentUtils.getEditorState(res.comment.message) : commentUtils.getEditorState(""),
                    rejectReason: res.rejectReason ? commentUtils.getEditorState(res.rejectReason) : null,
                    actualPerDiem: res.actualPerDiemRate,
                    attachments: res.attachments || [],
                    plannedUnitPrice: dc.planned,
                    plannedPerDiem: dc.planned,
                    tags: res.tags ? res.tags.split(',').map((tag) => {
                        return {
                            label: tag,
                            value: tag
                        }
                    }) : null,
                    unitRate: dc.unitPrice,
                    perDiemRate: dc.perDiemRate,
                    overtime: dc.isOvertime,
                    resourceType: dc.resourceType,
                    assignmentSite: dc.assignmentSite,
                    currency: dc.currency,
                    grade: dc.grade,
                    vendor: dc.vendorName,
                    actualsSubmittedBy: res.submittedBy ?  (res.submittedBy + "\n" +  formatDate(res.submittedDate.substring(0, res.submittedDate.length -2))) : "",
                    actualsApprovedBy: res.approvedBy ? (res.approvedBy + '\n' + formatDate(res.approvedDate.substring(0, res.approvedDate.length -2))) : "",
                    vendorType: dc.vendorType,
                    lastModifiedBy: res.lastModifiedBy ? (res.lastModifiedBy + '\n' + formatDate(res.lastModifiedDate.substring(0, res.lastModifiedDate.length -2))) : "",
                    modifiedBy: res.lastModifiedBy || "Default",
                    modifiedDate: res.lastModifiedDate || Math.random().toString(),
                    statusIndex: res.status,
                    createdBy: res.createdBy + '\n' + formatDate(res.createdDate),
                    dcApprover: {label: res.approver},
                    supplierRepresentative: {label: res.supplierRepresentative},
                    status: (isVendorTypeExternal(dc.vendorType) ? statusesExternal[res.status] : statusesInternal[res.status]) || "Default"
                }
            });
            const oldHistory = yield select((store) => store.deliveryConfirmationForm.history);
            const isSubmitting = yield select((store) => store.deliveryConfirmationForm.submitting);
            if (initial) {
                if (history.data.length >= 2) {
                    let changes;
                    try {
                        changes =  getChanges(history.data[0], history.data[1]);
                    } catch(error) {
                        changes = null;
                    }
                    yield put(deliveryConfirmationFormActionsCreators.deliveryConfirmationFormLatestChangesChanged(changes));
                }
                initial = false;
            }
            if (oldHistory && oldHistory.length !==0 && oldHistory.length < history.data.length && !isSubmitting) {
                yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: true, key:"newDc", message: "Delivery confirmation was updated. In order to get current version please refresh the page."}});
            }
            yield put(deliveryConfirmationFormActionsCreators.getDeliveryConfirmationHistoryFinished(history.data));
        }
        while(true) {
            yield fn();
            yield delay(10000)
        }
    } catch(e) {
        yield put(deliveryConfirmationFormActionsCreators.getDeliveryConfirmationHistoryFailed());
    }
}


export default [
    takeEvery(actionTypes.INITIALIZE_DELIERY_CONFIRMATION_FORM_STARTED, initializeDcFormWrapperSaga),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_FORM_ACTION_BUTTON_CLICKED, handleActionButton),
    takeEvery([actionTypes.DELIVERY_CONFIRMATION_APPROVE_DC, 
        actionTypes.DELIVERY_CONFIRMATION_PROCESSINGNA_DC,
        actionTypes.DELIVERY_CONFIRMATION_PROCESS_DC], statusDcSaga),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_REJECT_DC, rejectDcSaga),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_RESTART_DC, restartDcSaga),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_COMMENT_DC, commentDcSaga),
    takeLatest(actionTypes.DELIVERY_CONFIRMATION_QUICK_EDIT_MODAL_SUBMITTED, submitQuickEdit),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_GENERATE_PDF, generatePdf),
    takeEvery(actionTypes.DELIVERY_CONFIRMATION_DOWNLOAD_DC_FILE_FROM_LINK, downloadDcFromLink)
];