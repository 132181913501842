import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';
import Button from '@material-ui/core/Button';
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import vendorTypes, {vendorTypeEnums} from '../../../../constants/vendorTypes';
import { isVendorTypeExternal } from '../../../../utils/typesAssertions';
import dcStatuses, { dcStatusesEnums } from '../../../../constants/dcStatuses';

const mapStateToProps = (state) => {
    return {
        status: state.deliveryConfirmationForm.status,
        changelogType: state.deliveryConfirmationForm.vendorType,
        isApprover: state.deliveryConfirmationForm.isApprover,
        isSupplier: state.deliveryConfirmationForm.isSupplier,
        editButtonPressed: state.deliveryConfirmationForm.editButtonPressed,
    }
}
const mapDispatchToProps = (dispatch) => ({
    handleClick: (payload) => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActionButtonClicked(payload))
    }
})
const buttonNameMap = {
    'back': 'back',
    'edit': 'edit',
    'save': 'save',
    'generatePdf': 'Generate PDF',
    'submitForApproval': 'Submit for approval',
    'submitAndApprove': 'Submit and approve',
    'submitUpdated': 'submit updated',
    'restart': 'restart',
    'process': 'process',
    'approve': 'approve',
    'reject': 'reject',
    'processingNotApplicable': 'processing N/A'
}
const buttons = [
    'back',
    'edit',
    'save',
    'generatePdf',
    'submitForApproval',
    'submitAndApprove',
    'submitUpdated',
    'restart',
    'process',
    'approve',
    'reject',
    'processingNotApplicable'
];
const buttonsOnStatusesCommon = {
    [dcStatusesEnums.ToBeFilledIn]: {
        'back': true,
        'edit': true,
        'save': true,
        'submitForApproval': true,
        'processingNotApplicable': true
    },
    [dcStatusesEnums.ToBeCorrected]: {
        'back': true,
        'edit': true,
        'save': true,
        'submitUpdated': true,
        'processingNotApplicable': true
    },
    [dcStatusesEnums.Completed]: {
        'back': true,
        'edit': true,
        'save': true,
        'generatePdf': true,
        'restart': true,
        'processingNotApplicable': true,
    },
    [dcStatusesEnums.ProcessingNotApplicable]: {
        'back': true,
        'edit': true,
        'save': true,
        'restart': true,
    }
}
const internalButtons = {
    ...buttonsOnStatusesCommon
}
internalButtons[dcStatusesEnums.ToBeApproved] = {
    'back': true,
    'edit': true,
    'save': true,
    'submitUpdated': true,
    'restart': true,
    'processingNotApplicable': true,
    'process': true,
};
const externalButtons = {
    ...buttonsOnStatusesCommon
}
externalButtons[dcStatusesEnums.ToBeCorrected] = {
    ...buttonsOnStatusesCommon[dcStatusesEnums.ToBeCorrected],
    submitAndApprove: true
}
externalButtons[dcStatusesEnums.ToBeFilledIn] = {
    ...buttonsOnStatusesCommon[dcStatusesEnums.ToBeFilledIn],
    submitAndApprove: true
}
externalButtons[dcStatusesEnums.Approved] = {
    'back': true,
    'edit': true,
    'save': true,
    'generatePdf': true,
    'restart': true,
    'process': true,
    'processingNotApplicable': true,
}
externalButtons[dcStatusesEnums.ToBeApproved] = {
    'back': true,
    'edit': true,
    'save': true,
    'submitUpdated': true,
    'restart': true,
    'processingNotApplicable': true,
    'approve': true,
    'reject': true,
};
const buttonsOnStatuses = {
    [vendorTypeEnums.Internal]: internalButtons,
    [vendorTypeEnums.External] : externalButtons,
}
class FormSubmitPanel extends Component {

    handleClick = (button) => {
        return this.props.handleClick.bind(this, button);
    }

    matchSupplier = () => {
        return this.props.isSupplier
    }
    matchApprover = () => {
        return this.props.isApprover
    }
    matchInternalExceptions = (button) => {
        if (isVendorTypeExternal(this.props.changelogType)) {
            return true;
        }
        if (!canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && button !== 'back') {
            return false;
        }
        if (button === 'edit') {
            return !this.props.editButtonPressed;
        }
        if (button === 'submitForApproval') {
            return this.props.editButtonPressed;
        }
        if (button === 'submitUpdated') {
            return this.props.editButtonPressed;
        }
        if (button === 'save') {
            return this.props.editButtonPressed;
        }
        if (button === 'approve') {
            return this.props.editButtonPressed;
        }
        if (button === 'processingNotApplicable') {
            return this.props.editButtonPressed;
        }
        if (button === 'process') {
            return this.props.editButtonPressed;
        }
        if (button === 'restart') {
            return this.props.editButtonPressed || (this.props.status === dcStatusesEnums.ProcessingNotApplicable && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN));
        }
        return true;
    }
    matchExceptionsConditions = (button) => {
        const isEditButtonNotClickedAsBackoffice = canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && !this.props.editButtonPressed;

        if (!isVendorTypeExternal(this.props.changelogType)) {
            return true;
        }
        if (button === 'generatePdf') {
            return this.props.status === dcStatusesEnums.Completed || (this.props.status === dcStatusesEnums.Approved && canFunction('', rules.DC_edit_External));
        }
        if (button === 'edit') {
            return !this.props.editButtonPressed;
        }
        if (button === 'save') {
            return this.props.editButtonPressed;
        }
        if (button === 'submitAndApprove') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return (this.matchSupplier() && (this.matchApprover() && canFunction('', rules.IS_REQUESTOR)));
        }
        if (button === 'submitForApproval') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return this.matchSupplier() && (!this.matchApprover() || !canFunction('', rules.IS_REQUESTOR));
        }
        if (button === 'submitUpdated') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            if (this.props.status === dcStatusesEnums.ToBeApproved) {
                return this.matchSupplier();
            }
            return this.matchSupplier() && (!this.matchApprover() || !canFunction('', rules.IS_REQUESTOR));
        }
        if (button === 'approve' || button === 'reject') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return this.matchApprover();
        }
        if (button === 'processingNotApplicable' && this.matchApprover() && this.props.status === dcStatusesEnums.ToBeFilledIn ) {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return true
        }
        if (button === 'processingNotApplicable') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return this.props.editButtonPressed;
        }
        if (button === 'restart') {
            if (isEditButtonNotClickedAsBackoffice) {
                return false;
            }
            return this.props.editButtonPressed || (this.props.status === dcStatusesEnums.ProcessingNotApplicable && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN));
        }
        if (button === 'process') {
            return this.props.editButtonPressed;
        }
        return true;
    }
    matchSupplierException = (button) => {
        if (button === 'submitUpdated' 
        || button === 'submitForApproval' 
        || button === 'generatePdf'
        || button === 'back') {
            return this.matchSupplier();
        }
        return false;
    }
    checkSecondRowOfButtons = () => {
        if ((this.props.status === dcStatusesEnums.Approved && isVendorTypeExternal(this.props.changelogType))
            || (this.props.status === dcStatusesEnums.ToBeApproved && !isVendorTypeExternal(this.props.changelogType))) {
            return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && this.props.editButtonPressed;
        }
        return false;
    }
    matchSecondRowCondition = (button) => {
        if (button !== 'restart' && button !== 'process') {
            return true;
        }
        return !this.checkSecondRowOfButtons();
    }
    getButtons = () => {
        if ((!this.props.changelogType && !isVendorTypeExternal(this.props.changelogType)) || (!this.props.status && this.props.status !== 0)) {
            return null;
        }
        return buttons.map((button) => {
            if (buttonsOnStatuses[this.props.changelogType][this.props.status][button] 
                && ((!isVendorTypeExternal(this.props.changelogType) || this.matchSupplierException(button)) ? true : canFunction('', "DC_" + button+ "_" + vendorTypes[this.props.changelogType]))
                && this.matchExceptionsConditions(button) && this.matchInternalExceptions(button)
                && this.matchSecondRowCondition(button)) {
                return (
                    <div key={button}><Button color="primary" onClick={this.handleClick(button)}>{buttonNameMap[button]}</Button></div>
                )
            }
            return null;
        })
    }

    render() {
        return (
            <React.Fragment>
            {this.checkSecondRowOfButtons() ? 
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div key="restart"><Button color="primary" onClick={this.handleClick("restart")}>{buttonNameMap["restart"]}</Button></div>
                    <div key='process'><Button color="primary" onClick={this.handleClick('process')}>{buttonNameMap['process']}</Button></div>
                </div> : null
            }
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {this.getButtons()}
            </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitPanel);