import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import * as rules from '../../../../config/Rules';
import { canFunction } from '../../../../config/Can';
import { shouldComponentUpdate } from '../../../../store/reducers/ChangeLogFormReducer';
import { isDisabledWithRequestor } from '../../../../utils/changelogFormEditPrivileges';
const mapStateToProps = (state) => {
    return {
        isDraft: state.changelogForm.isDraft,
        isConfirmed: state.changelogForm.isConfirmed,
        errors: state.changelogForm.errors,
        editMode: state.changelogForm.editMode,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        shouldUpdate: shouldComponentUpdate(state.changelogForm)
    }
}
const mapDispatchToProps = (dispatch) => ({
    commentChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormIsConfirmedChanged(payload))        
    }
);

class FormConfirmationContainer extends Component {
    shouldComponentUpdate() {
        return this.props.shouldUpdate;
    }
    openLink = () => {
        window.open("https://pmicloud.sharepoint.com/sites/RefITPT/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FRefITPT%2FShared%20Documents%2FPO%20Creation%20%2D%20Supporting%20Documentation%2Epdf&parent=%2Fsites%2FRefITPT%2FShared%20Documents", "_blank");
    }
    isDisabled = () => {
        return isDisabledWithRequestor(this.props, rules.IS_BACKOFFICE_OR_ADMIN);
    }
    render() {
        const hasError = this.props.errors.isConfirmed;
        return (
            <div style={{position: 'relative', paddingBottom: hasError ? 14 : null}}>
                <div style={{display: 'flex', alignItems: "flex-start"}}>
                    <Checkbox 
                    color="primary" 
                    className="confirm-form" 
                    disabled={this.isDisabled()}
                    checked={this.props.isConfirmed}
                    onChange={this.props.commentChanged}/>
                    <div style={{paddingLeft: 20}}>
    I confirm that I have either (a) attached all required supporting documentation according to the <span style={{color:"#39acff", cursor:'pointer'}} onClick={this.openLink}>guideline</span>, or (b) will provide the documentation before the supplier will commence any work.</div>
                </div>
                {hasError && <div style={{fontSize: 12, color: 'red', whiteSpace: 'nowrap'}} className="error">{hasError}</div>}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormConfirmationContainer);