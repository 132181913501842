import { put, takeEvery, fork, select, delay } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as userActionCreators from '../store/actions/User';
import axios from '../axios';
import axios2 from 'axios';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import * as domainsActions from '../store/actions/domains';
import { snackbarChanged } from '../store/actions/snackbarActions';

function* getUsernameSaga(action) {
    try {
        const email = yield select((store) => store.user.username);
        if (localStorage.getItem("username")) {
            yield put(userActionCreators.selectName(JSON.parse(localStorage.getItem("username"))));
        }
        const users = yield axios.get('api/users', { params: { userEmail: email.toLowerCase(), page: 1 } });
        yield put(userActionCreators.selectName(users.data.items[0].name));
        localStorage.setItem("username", JSON.stringify(users.data.items[0].name));
    } catch (error) {
        yield put(userActionCreators.selectName("Unknown name"));
    }
}
function* checkDcTabSaga(action) {
    try {
        const data = yield axios.get('api/dc/checkToSubmit');
        yield put(userActionCreators.dcSupplierTabsVisibilityChanged(data.data));
    } catch (error) {
        yield put(userActionCreators.dcSupplierTabsVisibilityChanged(false));
    }
}
function* checkIfNewAppVersionIsAvailable(initialTimeStamp) {
    let continueChecking = true;
    try {
        while (continueChecking) {
            const test = yield axios2.get(window.location.origin + "/appVersion.json");
            if (test.data.data !== initialTimeStamp) {
                continueChecking = false;
                yield put(snackbarChanged({ open: true, key: "newApp", message: "There is a new app version available. In order to get it please refresh your browser window." }))
            }
            const delayPeriod = 2 * 1000 * 3600;
            yield delay(delayPeriod);
        }
    } catch (error) {
        continueChecking = false;
    }
}
function* initializeAppState(action) {
    try {
        if (action.payload.attributes['custom:AccountName']) {
            yield put(userActionCreators.setUserLogin(action.payload.attributes['custom:AccountName'].toLowerCase()));
        }
        const appVersion = yield axios2.get(window.location.origin + "/appVersion.json");
        yield fork(checkIfNewAppVersionIsAvailable, appVersion.data.data);
        yield put(userActionCreators.selectUsername(action.payload.attributes.email.toLowerCase()));
        let domains = [];
        if (localStorage.getItem("domains") !== null) {
            domains = JSON.parse(localStorage.getItem("domains"));
        }
        yield put(domainsActions.getDomainsFinished(domains));
        const roles = [];
        if (!action.payload.attributes['custom:Role'] || (action.payload.attributes['custom:Role'].indexOf('Back-office') === -1 && action.payload.attributes['custom:Role'].indexOf('Super-user') === -1 && action.payload.attributes['custom:Role'].indexOf('Requestor') === -1 && action.payload.attributes['custom:Role'].indexOf('Super-requestor') === -1 && action.payload.attributes['custom:Role'].indexOf('Regional-requestor') === -1)) {
            roles.push('vendor');
        }
        if (action.payload.attributes['custom:Role'] && action.payload.attributes['custom:Role'].indexOf('Back-office') !== -1) {
            roles.push('backOffice');
        }
        if (action.payload.attributes['custom:Role'] && action.payload.attributes['custom:Role'].indexOf('Super-user') !== -1) {
            roles.push('admin');
        }
        if (action.payload.attributes['custom:Role'] && action.payload.attributes['custom:Role'].indexOf('Requestor') !== -1) {
            roles.push('dcApprover');
        }
        if (action.payload.attributes['custom:Role'] && action.payload.attributes['custom:Role'].indexOf('Super-requestor') !== -1) {
            roles.push('superRequestor');
        }
        if (action.payload.attributes['custom:Role'] && action.payload.attributes['custom:Role'].indexOf('Regional-requestor') !== -1) {
            roles.push('regionalRequestor');
        }
        if (action.payload.attributes['custom:Role'] && (action.payload.attributes['custom:Role'].indexOf('Requestor') !== -1 || action.payload.attributes['custom:Role'].indexOf('Super-requestor') !== -1)) {
            yield fork(checkDcTabSaga);
        }
        yield put({ type: actionTypes.GET_USER_ROLES_FINISHED, payload: roles });
        yield put({ type: actionTypes.SET_USER_EMAIL_CASE_SENSITIVE, payload: action.payload.attributes.email });
        const viewAll = { name: "view all", id: "view all" };
        yield put(userActionCreators.selectUserDomain(viewAll));
        yield put(userActionCreators.selectDomain(viewAll));
        yield put({ type: actionTypes.USER_DATA_LOADED })
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog('There was an error while initializing application. Please try again later.'));
    }
}
export default [
    takeEvery(actionTypes.INITIALIZE_APP_STATE, initializeAppState),
    takeEvery(actionTypes.GET_CURRENTLY_LOGGED_IN_USER, getUsernameSaga)
]