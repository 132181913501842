import * as actionTypes from '../actions/actionTypes';

const initialState = {
    columns: [],
    loading: false,
    resourceModels: [],
    modalLoading: false,
    generateDcModalOpen: false,
    shouldConfirmFutureMonths: false,
    verifiedFutureMonths: false,
    generateDcModalData: {},
    generateDcModalSelectedMonths: {},
    generateDcModalDcToBeGeneratedCount: 0,
    selectedResourceModels: {},
    futureMonthsToBeValidated: {},
    selectedYear: {},
    selectAll: false,
    generatingDCs: false,
    dcGenerationProgress: 0,
}

const resourceModelReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.RESOURCE_MODEL_GENERATING_DCS_CHANGED: {
            return {
                ...state,
                generatingDCs: action.payload,
            }
        }
        case actionTypes.RESOURCE_MODEL_DC_GENERATION_PROGRESS_CHANGED: {
            return {
                ...state,
                dcGenerationProgress: action.payload
            }
        }
        case actionTypes.SELECT_ALL_RESOURCE_MODELS_CHANGED: {
            return {
                ...state,
                selectAll: action.payload
            }
        }
        case actionTypes.RESOURCE_MODAL_SELECTED_YEAR_CHANGED: {
            return {
                ...state,
                selectedYear: action.payload
            }
        }
        case actionTypes.RESET_RESOURCE_MODEL_VIEW: {
            return {
                ...initialState
            }
        }
        case actionTypes.RESOURCE_MODEL_LIST_CHANGE_LOADING_SPINNER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        // case actionTypes.GET_RESOURCE_MODELS_STARTED: {
        //     return {
        //         ...state,
        //         loading: true
        //     }
        // }
        case actionTypes.GET_RESOURCE_MODELS_FAILED: {
            return {
                ...state,
                loading: false,
                resourceModels: []
            }
        }
        case actionTypes.GET_RESOURCE_MODELS_FINISHED: {
            return {
                ...state,
                loading: false,
                resourceModels: action.payload
            }
        }
        case actionTypes.RESOURCE_MODAL_SHOULD_CONFIRM_FUTURE_MONTHS_CHANGED: {
            return {
                ...state,
                shouldConfirmFutureMonths: action.payload
            }
        }
        case actionTypes.RESOURCE_MODAL_VERIFIED_FUTURE_MONTHS_CHANGED: {
            return {
                ...state,
                verifiedFutureMonths: action.payload
            }
        }
        case actionTypes.SELECTED_RESOURCE_MODEL_ROWS_CHANGED: {
            return {
                ...state,
                selectedResourceModels: action.payload
            }
        }
        case actionTypes.RESOURCE_MODAL_SELECTED_MONTHS_CHANGED: {
            let dcAmount = 0;   
            if (action.payload) {
                let monthsTouched = Object.keys(action.payload);
                monthsTouched.forEach((item) => {
                    if (!action.payload[item]) {
                        return;
                    }
                    dcAmount += state.generateDcModalData[item].amount;
                })
            }
            return {
                ...state,
                generateDcModalSelectedMonths: action.payload,
                generateDcModalDcToBeGeneratedCount: dcAmount

            }
        }
        case actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_OPENED: {
            return {
                ...state,
                generateDcModalOpen: true
            }
        }
        case actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED: {
            return {
                ...state,
                generateDcModalOpen: false
            }
        }
        case actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_STARTED: {
            return {
                ...state,
                modalLoading: true
            }
        }
        case actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FAILED: {
            return {
                ...state,
                modalLoading: false
            }
        }
        case actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FINISHED: {
            return {
                ...state,
                generateDcModalData: action.payload,
                modalLoading: false
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_COLUMNS_STARTED: {
            return {
                ...state,
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_COLUMNS_FAILED: {
            return {
                ...state,
                columns: [],
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        case actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_STARTED: {
            return {
                ...state,
            }
        }
        case actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_FAILED: {
            return {
                ...state,

            }
        }
        case actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_FINISHED: {
            return {
                ...state,
            }
        }
        case actionTypes.SUBMIT_RESOURCE_MODAL_STARTED: {
            return {
                ...state,
                modalLoading: true,
            }
        }
        case actionTypes.SUBMIT_RESOURCE_MODAL_FINISHED: {
            return {
                ...state,
                modalLoading: false
            }
        }
        case actionTypes.SUBMIT_RESOURCE_MODAL_FAILED: {
            return {
                ...state,
                modalLoading: false
            }
        }
        case actionTypes.RESOURCE_MODAL_FUTURE_MONTHS_TO_BE_VALIDATED_CHANGED: {
            return {
                ...state,
                futureMonthsToBeValidated: action.payload
            }
        }
        default: {
            return state
        }
    }
}
export default resourceModelReducer;