import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import activityTypes from '../../../constants/activityTypes';
import resourceTypes from '../../../constants/resourceTypes';
import assignmentSites from '../../../constants/assignmentSites';
import AttachmentComponent from '../ChangelogForm/components/AttachmentComponent';
import Roles from './Roles';
import DeliveryInformation from './DeliveryInformation';
import Resource from './Resource';
import Comments from './Comments';

const useStyles = makeStyles(theme =>({
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        display: 'flex',
        position: 'relative'
    },
}))
const placeholderFunc = () => {};

const RevisionComparator =  ({index, history}) => {
    const currentRevision = history[index];
    const previousRevision = history[index +1];
    const changes = getChanges(currentRevision, previousRevision)
    const classes = useStyles();
    return (
        <div className={classes.container}>
        <Grid container className="form-grid" spacing={2}>
            <Grid item md={6} xs={12}>
                <DeliveryInformation {...currentRevision} changes={changes}/>
            </Grid>
            <Grid item md={6} xs={12}>
                <Roles {...currentRevision} changes={changes}/> 
            </Grid>                                   
            <Grid item xs={12}>
                <Resource resource={currentRevision} errors={{}} changes={changes} reviewMode={true}/>
            </Grid>
            <Grid item xs={12}>
                <AttachmentComponent 
                    viewMode={true}
                    changes={changes}
                    attachments={currentRevision.attachments.map((attach) => {
                        return {
                            name: attach
                        }
                    })}
                    disabled={true}
                    attachmentRemoved={placeholderFunc}
                    attachmentAdded={placeholderFunc}
                    downloadAttachment={placeholderFunc}
                 />
            </Grid>
            <Grid item xs={12}>
                <Comments disabled comment={currentRevision.comment ? currentRevision.comment.message : null} resource={currentRevision} comments={[]} />
            </Grid>
        </Grid>
    </div>
    )
}
const getChanges = (currentRevision, previousRevision) => {
    const differences = {
        Planned: {},
        actualUnitPrice: {},
        actualPerDiem: {},
        overtime125: {},
        overtime150: {},
        overtime175: {},
        overtime200: {},
        PO: {},
        attachments: []
    };
    if (!previousRevision) {
        return differences;
    }
    for (let index =1; index <= 12; index++ ) {
        if (currentRevision.planned[index] !== previousRevision.planned[index]) {
            differences.Planned[index] = previousRevision.planned[index] || "0";
        }
        if (currentRevision.actualUnitPrice[index] !== previousRevision.actualUnitPrice[index]) {
            differences.actualUnitPrice[index] = previousRevision.actualUnitPrice[index] ||"0";
        }
        if (currentRevision.actualPerDiem[index] !== previousRevision.actualPerDiem[index]) {
            differences.actualPerDiem[index] = previousRevision.actualPerDiem[index] || "0";
        }
        if (currentRevision.po[index] !== previousRevision.po[index]) {
            differences.PO[index] = previousRevision.po[index] || "Default";
        }
        if (currentRevision.overtime125[index] !== previousRevision.overtime125[index]) {
            differences.overtime125[index] = previousRevision.overtime125[index] || "0";
        }
        if (currentRevision.overtime150[index] !== previousRevision.overtime150[index]) {
            differences.overtime150[index] = previousRevision.overtime150[index] || "0";
        }
        if (currentRevision.overtime175[index] !== previousRevision.overtime175[index]) {
            differences.overtime175[index] = previousRevision.overtime175[index] || "0";
        }
        if (currentRevision.overtime200[index] !== previousRevision.overtime200[index]) {
            differences.overtime200[index] = previousRevision.overtime200[index] || "0";
        }
    }
    if (currentRevision.generateDc !== previousRevision.generateDc) {
        differences.generateDc = previousRevision.generateDc ? "Yes" : "No";
    }
    if (currentRevision.domain !== previousRevision.domain) {
        differences.domain = previousRevision.domain;
    }
    if (currentRevision.subDomain !== previousRevision.subDomain) {
        differences.subDomain = previousRevision.subDomain;
    }
    if (currentRevision.activityType !== previousRevision.activityType) {
        differences.activityType = activityTypes[previousRevision.activityType];
    }
    if (currentRevision.activityName !== previousRevision.activityName) {
        differences.activityName = previousRevision.activityName;
    }
    if (currentRevision.poLocation !== previousRevision.poLocation) {
        differences.poLocation = previousRevision.poLocation;
    }
    if (currentRevision.costType !== previousRevision.costType) {
        differences.costType = previousRevision.costType;
    }
    if (currentRevision.powbs !== previousRevision.powbs) {
        differences.powbs = previousRevision.powbs;
    }
    if (currentRevision.year !== previousRevision.year) {
        differences.year = previousRevision.year;
    }
    if (currentRevision.planner !== previousRevision.planner) {
        differences.planner = previousRevision.planner;
    }
    if (currentRevision.supplier !== previousRevision.supplier) {
        differences.supplier = previousRevision.supplier;
    }
    const currentSupplier = currentRevision.supplierRepresentative && currentRevision.supplierRepresentative.name;
    const previousSupplier =  previousRevision.supplierRepresentative && previousRevision.supplierRepresentative.name;
    if (currentRevision.supplierRepresentative && currentSupplier !== previousSupplier) {
        differences.supplierRepresentative = previousSupplier || "Default";
    }
    const currentApprover = currentRevision.approver && currentRevision.approver.name;
    const previousApprover =  previousRevision.approver && previousRevision.approver.name;
    if (currentApprover !== previousApprover) {
        differences.approver = previousApprover;
    }
    if (currentRevision.resourceType !== previousRevision.resourceType) {
        differences.resourceType = resourceTypes[previousRevision.resourceType];
    }
    if (currentRevision.name !== previousRevision.name) {
        differences.name = previousRevision.name;
    }
    if (currentRevision.expenseType !== previousRevision.expenseType) {
        differences.expenseType = previousRevision.expenseType;
    }
    if (currentRevision.location !== previousRevision.location) {
        differences.location = previousRevision.location;
    }
    if (currentRevision.assignmentSite !== previousRevision.assignmentSite) {
        differences.assignmentSite = assignmentSites[previousRevision.assignmentSite];
    }
    if (currentRevision.grade !== previousRevision.grade) {
        differences.grade = previousRevision.grade;
    }
    if (currentRevision.unitPrice !== previousRevision.unitPrice) {
        differences.unitPrice = previousRevision.unitPrice;
    }
    if (currentRevision.perDiem !== previousRevision.perDiem) {
        differences.perDiem = previousRevision.perDiem;
    }
    if (currentRevision.currency !== previousRevision.currency) {
        differences.currency = previousRevision.currency;
    }
    differences.attachments = {deleted: []};
    currentRevision.attachments.forEach((attachment) => {
        if (previousRevision.attachments.find((prevAttachment) => {
            return prevAttachment === attachment;
        })) {
            differences.attachments[attachment]= ""
        } else {
            differences.attachments[attachment] = "added"
        }
    });
    previousRevision.attachments.forEach((attachment) => {
        if(!currentRevision.attachments.find((currAttachment) => {
            return currAttachment === attachment
        })) {
            differences.attachments.deleted.push({name: attachment, type: "deleted"});
        }
    });
    return differences;
}
export {RevisionComparator as default, getChanges}