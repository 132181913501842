import * as actionTypes from '../actions/actionTypes';

const initialState = {
    filters: {},
    parsedFilters: {}
}

const tableReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.TABLE_STATE_CHANGED: {
            const filtersCopy = {...state.filters};
            filtersCopy[action.payload.tableName] = action.payload.state
            const parsedFiltersCopy = {...state.parsedFilters};
            parsedFiltersCopy[action.payload.tableName] = action.payload.parsedFilters;
            return {
                ...state,
                filters: filtersCopy,
                parsedFilters: parsedFiltersCopy
            }
        }
        default: {
            return state;
        }
    }
}
export default tableReducer;