import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Can from '../../../config/Can';
import * as rules from '../../../config/Rules';
import OneColumnPickList from './OneColumnPickList';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/Navbar'; 
import * as staticPicklistsActions from '../../../store/actions/staticPicklists'; 
import * as actionTypes from '../../../store/actions/actionTypes'
import DomainResourceTypePickList from './DomainResourceTypePickList';
import DomainPickList from './DomainPickList';
import SubDomainPickList from './SubDomainPickList';
import PoLocationPickList from './PoLocationPickList';
import ExpenseTypeCostTypePickList from './ExpenseTypeCostTypePickList';
import Tabs from '../../../components/Tabs/Tabs';
import CostCenterPickList from './CostCenterPickList';
import TagsPickList from './TagsPickList';

const styles = {
    container: {
        padding: 48,
        display: 'flex',
        justifyContent: 'center'
    },
    twoColumn: {
        margin: '0 auto',
        display: 'inline-block',
        width: 640,
        padding: 20,
        verticalAlign: 'top',
    },
    twoColumnWithCheckbox: {
        display: 'inline-block',
        width: 690,
        padding: 20,
        verticalAlign: 'top',
    },
    oneColumn: {
        display: 'inline-block',
        width: 390,
        padding: 20,
        verticalAlign: 'top'
    },
    fullWidth: {
        width: '100%'
    }
}

const mapStateToProps = (state) => {
    return {
      selectedRole: state.user.selectedRole,
      picklists: state.staticPicklists.picklists,
      selectedPicklist: state.staticPicklists.selectedPicklist

}};

const mapDispatchToProps = (dispatch) => ({
    deleteNavbarStatusComment: () =>
        dispatch(actionCreators.setNavbarStatusComment("")),
    getPickliststs: () =>
        dispatch({type:actionTypes.GET_STATIC_PICKLISTS}),
    selectedPicklistChanged: (payload) => {
        dispatch(staticPicklistsActions.selectedPicklistChanged(payload))
    }

});

class StaticPickLists extends Component {

    handlePicklistChanged = (e, index) => {
        this.props.selectedPicklistChanged(this.props.picklists[parseInt(index)]);
    }

    componentDidMount() {
        this.props.selectedPicklistChanged(this.props.selectedPicklist);
    }

    componentWillUnmount() {
        this.props.deleteNavbarStatusComment();
    }
    render() {
        return (
            <React.Fragment>
            <Tabs onTabChanged={this.handlePicklistChanged} selectedTab={this.props.selectedPicklist} tabs={this.props.picklists}></Tabs>
            <div className={this.props.classes.container}>
                {
                    this.props.selectedPicklist.id === "Platforms" ?
                    <Can 
                        role={this.props.selectedRole}
                        perform={rules.VIEW_DOMAIN_PICKLIST}
                        yes={() => {
                            return (
                                <div className={this.props.classes.twoColumnWithCheckbox}>
                                    <DomainPickList />
                                </div>
                            )
                        }}
                    /> : null
                }
                {
                    this.props.selectedPicklist.id === "Sub-platforms" ?
                 <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_SUB_DOMAIN_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.twoColumnWithCheckbox}>
                                <SubDomainPickList />
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Po Locations" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_PO_LOCATION_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.twoColumnWithCheckbox}>
                                <PoLocationPickList />
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Expense Types" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_EXPENSE_TYPE_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.fullWidth}>
                                <ExpenseTypeCostTypePickList />
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Cost Types" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_COST_TYPE_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.oneColumn}>
                                <OneColumnPickList namespace="costTypes" header="Cost Types"/>
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Currencies" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_CURRENCIES_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.oneColumn}>
                                <OneColumnPickList namespace="currencies" header="Currencies"/>
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Grades" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_GRADES_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.oneColumn}>
                                <OneColumnPickList namespace="grades" header="Grades"/>
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Resource Locations" ?
                <Can 
                    role={this.props.selectedRole}
                    perform={rules.VIEW_RESOURCE_LOCATIONS_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.oneColumn}>
                                <OneColumnPickList namespace="resourceLocations" header="Resource Locations"/>
                            </div>
                        )
                    }}
                /> : null
                }
                {
                    this.props.selectedPicklist.id === "Resource Types per Vendor Type" ?
                <Can
                    role={this.props.selectedRole}
                    perform={rules.VIEW_RESOURCE_TYPE_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.twoColumn}>
                                <DomainResourceTypePickList />
                            </div>
                        )
                    }}
                 /> : null
                }
                {
                    this.props.selectedPicklist.id === "Cost Centers" ?
                <Can
                    role={this.props.selectedRole}
                    perform={rules.VIEW_COST_CENTER_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.fullWidth}>
                                <CostCenterPickList />
                            </div>
                        )
                    }}
                 /> : null
                }
                {
                    this.props.selectedPicklist.id === "Tags" ?
                <Can
                    role={this.props.selectedRole}
                    perform={rules.VIEW_TAGS_PICKLIST}
                    yes={() => {
                        return (
                            <div className={this.props.classes.fullWidth}>
                                <TagsPickList />
                            </div>
                        )
                    }}
                 /> : null
                }

            </div>
            </React.Fragment>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(StaticPickLists)