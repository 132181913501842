import { put, takeLatest, takeEvery, all, call, select, race, take, delay, fork} from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import axios from '../axios';
import {regionTypeEnums} from '../constants/regionTypes';
import {getAllDomains} from './domainsSagas';
import { getAllSubDomains } from './subDomainsSagas';
import { oneColumnDictionariesSagas, getExpenseTypeCostTypeMapping } from './dictionariesSagas';
import {getAllCostCenters} from './costCenterSagas';
import * as regionChangelogActions from '../store/actions/regionalChangelogActions';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import {getAllCostCentersPerSubDomainWithDeletedFiltered} from '../store/reducers/costCenterReducer';
import {getExpenseTypesPerCostTypesWithFilteredDeleted} from '../store/reducers/expenseTypeCostTypeReducer';
import {getCostTypesPerResource} from '../store/reducers/regionalChangelogFormReducer';
import history from '../history';
import historyDataFormatter from '../utils/historyDataMapper';
import scrollToErrorSaga from './scrollToErrorSaga';
import {getChanges} from '../views/MainPage/RegionalChangelogForm/RegionalChangelogRevisionComparator';
import * as commentUtils from '../utils/commentUtils';
import { canFunction } from '../config/Can';
import * as rules from '../config/Rules';


function* getBudgetPlanTags() {
    const tags = yield axios.get('api/tags/budgetPlan');
    yield put(regionChangelogActions.getRegionalChangelogTagsFinished(tags.data.map((tag) => {
        return {
            label: tag.value,
            value: tag.id
        }
    })));
}
function* getBudgetPlanResourceTags() {
    const tags = yield axios.get('api/tags/budgetPlanLine');
    yield put(regionChangelogActions.getRegionalChangelogLineTagsFinished(tags.data.map((tag) => {
        return {
            label: tag.value,
            value: tag.id
        }
    })));

}
export function* initializeCreateForm(action) {
    try {
        const sagaArray = [];
        if (canFunction('', rules.VIEW_TAGS_PICKLIST)) {
            sagaArray.push(call(getBudgetPlanTags));
            sagaArray.push(call(getBudgetPlanResourceTags))
        }
        sagaArray.push(call(getAllDomains));
        sagaArray.push(call(getAllSubDomains));
        sagaArray.push(call(oneColumnDictionariesSagas['currencies'].getAll));
        sagaArray.push(call(getExpenseTypeCostTypeMapping));
        sagaArray.push(call(getAllCostCenters));
        sagaArray.push(call(oneColumnDictionariesSagas['costTypes'].getAll));
        yield put(regionChangelogActions.regionalChangelogSetState({
            planByCostCenter: action.payload,
            currentYear: new Date().getUTCFullYear(),
            currentMonth: new Date().getUTCMonth() +1,
            budgetYear: {
                label: new Date().getUTCFullYear(),
                value: new Date().getUTCFullYear()
            }
        }))
        yield all(sagaArray);
        yield put(regionChangelogActions.initializeRegionalChangelogFormFinished());
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while initializing budget plan data. Please try again later."));
    }
}

export function* submitForm(action) {
    try {
        const formState = yield select(store => store.regionalChangelogForm);
        if (formState.planByCostCenter) {
            yield* submitBudgetPlanByCostCenter(formState);
        } else {
            yield* submitBudgetPlanByActivity(formState);
        }
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting budget plan data. Please try again later."));
    }
}
function* submitBudgetPlanByCostCenter(formState) {
    try {
        const errors = validatePlanByCostCenterForm(formState);
        if (Object.keys(errors).length) {
            yield put(regionChangelogActions.regionalChangelogFormErrorsChanged(errors));
            yield put(regionChangelogActions.regionalChangelogFormSubmitFailed());
            yield* scrollToErrorSaga();
            return
        }
        const form = {
            id: formState.id,
            domainId: formState.domain.value,
            subDomainId: formState.subDomain.value,
            costCenterId: formState.costCenter.value,
            budgetYear: formState.budgetYear.value,
            tags: formState.tags ? formState.tags.map((tag) => tag.value) : null,
            resources: [],
            comment: commentUtils.getPureText(formState.comment).length !== 0 ? commentUtils.prepareEditorStateForSendingToDatabase(formState.comment) : null
        }
        formState.resources.forEach((resource) => {
            const plans = [];
            if (resource.plans) {
                Object.keys(resource.plans).forEach((month) => {
                    plans.push({
                        month: parseFloat(month),
                        value: parseFloat(resource.plans[month])
                    })
                })
            }
            const actuals = [];
            if (resource.actuals) {
                Object.keys(resource.actuals).forEach((month) => {
                    actuals.push({
                        month: parseFloat(month),
                        value: parseFloat(resource.actuals[month])
                    })
                })
            }
            form.resources.push({
                id: resource.id,
                tags: resource.tags ? resource.tags.map((tag) => tag.value) : null,
                costTypeId: resource.costType.value,
                expenseTypeId: resource.expenseType.value,
                currencyId: resource.currency.value,
                actuals: actuals,
                plans: plans
            })
        });
        yield axios.post('api/budgetPlans/costCenter/submit', form);
        yield put(regionChangelogActions.regionalChangelogFormSubmitFinished());
        yield* redirectSaga(formState);
    } catch(error) {
        yield put(regionChangelogActions.regionalChangelogFormSubmitFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting budget plan data. Please try again later."));
    }
}
function* redirectSaga(formState) {
    const options = yield select((store) => store.regionalChangelogs.tabs);
    const option = options.find((option) => {
        return option.value === formState.planByCostCenter
    })
    yield put(regionChangelogActions.regionalChangelogsTabChanged(option));
    if (formState.id) {
        let isOwner
        const login = yield select((state) => state.user.login);
        const email = yield select((state) => state.user.username);
        if (login) {
            isOwner = formState.createdBy.login === login
        } else {
            isOwner = formState.createdBy.userEmail === email
        }
        if (isOwner) {
            history.push('/budgetPlans/my');
        } else {
            history.push('/budgetPlans/viewAll');
        }
    } else {
        history.push('/budgetPlans/my');
    }

}
function validatePlanByCostCenterForm(formState) {
    const errors = {};
    if (!formState.domain.value) {
        errors['domain'] = "Platform cannot be empty";
    }
    if (!formState.subDomain.value) {
        errors['subDomain'] = "Sub-platform cannot be empty";
    }
    if (!formState.budgetYear.value) {
        errors['budgetYear'] = "Budget year cannot be empty";
    }
    if (!formState.costCenter.value) {
        errors['costCenter'] = "Cost center cannot be empty";
    }
    if (formState.resources) {
        const resourcesErrors = {};
            formState.resources.forEach((resource, index) => {
                if (!resource.expenseType || !resource.expenseType.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['expenseType'] = "Expense Type cannot be empty";
                }
                if (!resource.costType || !resource.costType.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['costType'] = "Cost Type cannot be empty";
                }
                if (!resource.currency || !resource.currency.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['currency'] = "Currency cannot be empty";
                }
            });
            if (Object.keys(resourcesErrors).length !== 0) {
                errors['resources'] = resourcesErrors;
            }
    }
    return errors;
}
function* submitBudgetPlanByActivity(formState) {
    try {
        const errors = validatePlanByActivityForm(formState);
        if (Object.keys(errors).length) {
            yield put(regionChangelogActions.regionalChangelogFormErrorsChanged(errors));
            yield put(regionChangelogActions.regionalChangelogFormSubmitFailed());
            return;
        }
        const form = {
            id: formState.id,
            domainId: formState.domain.value,
            subDomainId: formState.subDomain.value,
            budgetYear: formState.budgetYear.value,
            resources: [],
            tags: formState.tags ? formState.tags.map((tag) => tag.value) : null,
            comment: commentUtils.getPureText(formState.comment).length !== 0 ? commentUtils.prepareEditorStateForSendingToDatabase(formState.comment) : null
        }
        formState.resources.forEach((resource) => {
            const plans = [];
            if (resource.plans) {
                Object.keys(resource.plans).forEach((month) => {
                    plans.push({
                        month: parseFloat(month),
                        value: parseFloat(resource.plans[month])
                    })
                })
            }
            const actuals = [];
            if (resource.actuals) {
                Object.keys(resource.actuals).forEach((month) => {
                    actuals.push({
                        month: parseFloat(month),
                        value: parseFloat(resource.actuals[month])
                    })
                })
            }
            form.resources.push({
                id: resource.id,
                tags: resource.tags ? resource.tags.map((tag) => tag.value) : null,
                costCenterId: resource.costCenter.value,
                activityId: resource.activity.value,
                wbsId: resource.wbs.value,
                costTypeId: resource.costType.value,
                expenseTypeId: resource.expenseType.value,
                currencyId: resource.currency.value,
                actuals: actuals,
                plans: plans
            });
        })
        yield axios.post('api/budgetPlans/activity/submit', form);
        yield put(regionChangelogActions.regionalChangelogFormSubmitFinished());
        yield* redirectSaga(formState)
    } catch(error) {
        yield put(regionChangelogActions.regionalChangelogFormSubmitFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting budget plan data. Please try again later."));
    }
} 
function validatePlanByActivityForm(formState) {
    const errors = {};
    if (!formState.domain.value) {
        errors['domain'] = "Platform cannot be empty";
    }
    if (!formState.subDomain.value) {
        errors['subDomain'] = "Sub-platform cannot be empty";
    }
    if (!formState.budgetYear.value) {
        errors['budgetYear'] = "Budget year cannot be empty";
    }
    if (formState.resources) {
        const resourcesErrors = {};
            formState.resources.forEach((resource, index) => {
                if (!resource.costCenter || !resource.costCenter.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['costCenter'] = "Cost center cannot be empty";
                }
                if (!resource.activity || !resource.activity.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['activity'] = "Activity cannot be empty";
                }
                if (!resource.wbs || !resource.wbs.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['wbs'] = "Po wbs cannot be empty";
                }
                if (!resource.expenseType || !resource.expenseType.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['expenseType'] = "Expense Type cannot be empty";
                }
                if (!resource.costType || !resource.costType.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['costType'] = "Cost Type cannot be empty";
                }
                if (!resource.currency || !resource.currency.value) {
                    if (!resourcesErrors[parseInt(index)]) {
                        resourcesErrors[parseInt(index)] = {}
                    }
                    resourcesErrors[parseInt(index)]['currency'] = "Currency cannot be empty";
                }
            });
            if (Object.keys(resourcesErrors).length !== 0) {
                errors['resources'] = resourcesErrors;
            }
    }
    return errors;
}
function* loadResourceActivitiesAndWbses(costCenterId, activityId) {
    const activities = yield axios.get('api/wbs/activities', {params: {withDeleted: true, pageable: false, regionType: regionTypeEnums.Market, costCenterId: costCenterId}});
    const activity = activities.data.items.find((activity) => {
        return activity.id === activityId;
    })
    const wbses = yield axios.get('api/wbs', {params: {pageable: false, activityName: activity.name}});
    return [
        activities.data.items,
        wbses.data.items
    ]
}
export function* initializeEditForm(action) {
    try {
        yield fork(changelogHistorySaga, {payload: action.payload});
        const response = yield axios.get('api/budgetPlans/' + action.payload);
        const planByCostCenter = response.data.costCenterId !== null;
        const sagaArray = [];
        if (!planByCostCenter) {
            sagaArray.push(call(axios.get, 'api/wbs/activities', {params: {withDeleted: true, pageable: false, regionType: regionTypeEnums.Market, subDomainId: response.data.subDomainId}}))
        }
        if (canFunction('', rules.VIEW_TAGS_PICKLIST)) {
            sagaArray.push(call(getBudgetPlanTags));
            sagaArray.push(call(getBudgetPlanResourceTags))
        }
        sagaArray.push(call(getAllDomains));
        sagaArray.push(call(getAllSubDomains));
        sagaArray.push(call(oneColumnDictionariesSagas['currencies'].getAll));
        sagaArray.push(call(getExpenseTypeCostTypeMapping));
        sagaArray.push(call(getAllCostCenters));
        sagaArray.push(call(oneColumnDictionariesSagas['costTypes'].getAll));
        if (!planByCostCenter) {
            response.data.resources.forEach((resource) => {
                sagaArray.push(call(loadResourceActivitiesAndWbses, resource.costCenterId, resource.activityId));
            })
        }
        const sagasResponse = yield all(sagaArray);
        const costTypes = yield select(store => store.costTypes.allItems);
        const expenseTypes = yield select(store => store.expenseTypeCostType.data);
        const currencies = yield select(store => store.currencies.allItems);
        const costCenters = yield select(store => store.costCenters.allItems);
        const resources = response.data.resources.map((resource, index) => {
            let costType = costTypes.find((costType) => {
                return costType.id === resource.costTypeId
            });
            let expenseType = expenseTypes.find((expenseType) => {
                return expenseType.id === resource.expenseTypeId;
            });
            let currency = currencies.find((currency) => {
                return currency.id === resource.currencyId;
            });
            const plans = {};
            resource.plans.forEach((plan) => {
                plans[plan.month] = plan.value
            })
            const actuals = {};
            resource.actuals.forEach((actual) => {
                actuals[actual.month] = actual.value
            })
            let resourceParsed = {
                id: resource.id,
                costType: {
                    label: costType.name,
                    value: costType.id
                },
                tags: resource.tags ? resource.tags.map((tag) => {
                    return {
                        label: tag.value,
                        value: tag.id
                    }
                }) : null,
                expenseType: {
                    label: expenseType.name,
                    value: expenseType.id
                },
                currency: {
                    label: currency.name,
                    value: currency.id
                },
                plans: plans,
                actuals: actuals,
                hasActuals: resource.actuals.length >=1
            }
            if (!planByCostCenter) {
                const newCostCenter = costCenters.find((costCenter) => {
                    return costCenter.id === resource.costCenterId
                });
                resourceParsed.costCenter = {
                    label: newCostCenter.name,
                    value: newCostCenter.id
                }
                const [activities, wbses ] = sagasResponse[sagasResponse.length - response.data.resources.length + index];
                resourceParsed.wbses = wbses;
                resourceParsed.activities = activities;
                const activity = activities.find((activity) => {
                    return activity.id === resource.activityId;
                });
                const wbs = wbses.find((wbs) => {
                    return wbs.id === resource.wbsId;
                })
                resourceParsed.activity = {
                    label: activity.name,
                    value: activity.id
                };
                resourceParsed.wbs = {
                    label: wbs.identifier,
                    value: wbs.id
                }
            }
            return resourceParsed;
        });
        const domains = yield select(store => store.domains.allDomains);
        const subDomains = yield select(store => store.subDomains.allSubDomains);
        const newDomain = domains.find((domain) => {
            return domain.id === response.data.domainId
        })
        const newSubDomain = subDomains.find((subDomain) => {
            return subDomain.id === response.data.subDomainId
        })
        const newCostCenter = costCenters.find((costCenter) => {
            return costCenter.id === response.data.costCenterId
        });
        yield put(regionChangelogActions.regionalChangelogSetState({
            id: response.data.id,
            comments: response.data.comments.map((comment) => {
                return {
                    ...comment,
                    message: commentUtils.getEditorState(comment.message)
                }
            }),
            tags: response.data.tags ? response.data.tags.map((tag) => {
                return {
                    label: tag.value,
                    value: tag.id
                }
            }) : null,
            activities: !planByCostCenter ? sagasResponse[0].data.items : null,
            planByCostCenter: planByCostCenter,
            lastModifiedBy: response.data.lastModifiedBy,
            lastModifiedDate: response.data.lastModifiedDate,
            createdBy: response.data.createdBy,
            createdDate: response.data.createdDate,
            domain: {
                label: newDomain.name,
                value: newDomain.id
            },
            subDomain: {
                label: newSubDomain.name,
                value: newSubDomain.id
            },
            costCenter: planByCostCenter ? {
                label: newCostCenter.name,
                value: newCostCenter.id
            } : null,
            budgetYear: {
                label: response.data.budgetYear,
                value: response.data.budgetYear
            },
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getUTCMonth() +1,
            resources: resources,
            initialResources: resources
        }));
        yield put(regionChangelogActions.initializeRegionalChangelogFormFinished());
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while initializing budget plan data. Please try again later."));
    }
}

function* changelogHistorySaga(action) {
    try {
        let initial = true;
        const fn = function*() {
            const history = yield axios.get('api/budgetPlans/history/'+ action.payload);
            history.data = historyDataFormatter(history.data).map((revision, index) => {
                const resources = revision.resources.map((resource) => {
                    const plans = {};
                    resource.plans.forEach((plan) => {
                        plans[plan.month] = plan.value
                    })
                    const actuals = {};
                    resource.actuals.forEach((actual) => {
                        actuals[actual.month] = actual.value
                    })
                    return {
                        ...resource,
                        tags: resource.tags ? resource.tags.split(',').map((tag) => {
                            return {
                                label: tag,
                                value: tag
                            }
                        }) : null,
                        costCenter: {
                            label: resource.costCenterName
                        },
                        activity: {
                            label: resource.activityName
                        },
                        wbs: {
                            label: resource.poWbs
                        },
                        expenseType: {
                            label: resource.expenseTypeName
                        },
                        costType: {
                            label: resource.costTypeName
                        },
                        currency: {
                            label: resource.currencyName
                        },
                        plans: plans,
                        actuals: actuals
                    }
                })
                return {
                    ...revision,
                    tags: revision.tags ? revision.tags.split(',').map((tag) => {
                        return {
                            label: tag,
                            value: tag
                        }
                    }) : null,
                    comment: revision.comment ? {...revision.comment, message: commentUtils.getEditorState(revision.comment.message)} : null,
                    modifiedBy: revision.lastModifiedBy.name,
                    status: `Revision nr ${history.data.length - index}`,
                    modifiedDate: revision.lastModifiedDate,
                    planByCostCenter: revision.costCenterName !== null && revision.costCenterName !== undefined,
                    errors: {},
                    domain: {
                        label: revision.domainName
                    },
                    subDomain: {
                        label: revision.subDomainName
                    },
                    costCenter: {
                        label: revision.costCenterName
                    },
                    budgetYear: {
                        label: revision.budgetYear,
                        value: revision.budgetYear
                    },
                    resources: resources,
                    currentYear: new Date(revision.lastModifiedDate).getUTCFullYear(),
                    currentMonth: new Date(revision.lastModifiedDate).getUTCMonth() +1,
                }
            })
            const oldHistory = yield select((store) => store.regionalChangelogForm.history);
            const isSubmitting = yield select((store) => store.regionalChangelogForm.submitting);
            if (initial) {
                if (history.data.length >= 2) {
                    let changes;
                    try {
                        changes =  getChanges(history.data[0], history.data[1]);
                    } catch(error) {
                        changes = null;
                    }
                    yield put(regionChangelogActions.regionalChangelogFormLatestChangesChanged(changes));
                }
                initial = false;
            }
            if (oldHistory && oldHistory.length !==0 && oldHistory.length < history.data.length && !isSubmitting) {
                yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: true, key:"newBp", message: "Budget plan was updated. In order to get current version please refresh the page."}});
            }
            yield put(regionChangelogActions.regionalChangelogHistoryChanged(history.data));
        }
        while(true) {
            yield fn();
            yield delay(10000)
        }
    } catch(e) {
        yield put(regionChangelogActions.regionalChangelogHistoryChanged([]));
        yield put(errorDialogActions.openErrorDialog("There was an error while loading budget plan history."));
    }
}

export function* subDomainSaga(action) {
    try {
        yield put(regionChangelogActions.regionalChangelogSubDomainChanged(''));
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

const getPlanByCostCenter = store => store.regionalChangelogForm.planByCostCenter;
export function* costCenterSaga(action) {
    try {
        const planByCostCenter = yield select(getPlanByCostCenter);
        if (planByCostCenter) {
            yield* costCenterByPlans(action);
        } else {
            yield* costCenterByActivitySaga(action);
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

function* costCenterByPlans(action) {
    try {
        const costCentersBySubDomain = yield select(getAllCostCentersPerSubDomainWithDeletedFiltered);
        const selectedCostCenter = yield select((store) => store.regionalChangelogForm.costCenter);
        if ((!action.payload.value && selectedCostCenter.value) || (selectedCostCenter.value && (!costCentersBySubDomain[action.payload.value] || !costCentersBySubDomain[action.payload.value].find((costCenter) => {
            return costCenter.id === selectedCostCenter.value
        })))) {
            yield put(regionChangelogActions.regionalChangelogCostCenterChanged(''));
        }
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}
const getResources = store => store.regionalChangelogForm.resources;
function* costCenterByActivitySaga(action) {
    try {
        const costCentersBySubDomain = yield select(getAllCostCentersPerSubDomainWithDeletedFiltered);
        const resources = yield select(getResources);
        for (let i =0; i< resources.length; i++) {
            if ((!action.payload.value && resources[i].costCenter && resources[i].costCenter.value) || (resources[i].costCenter && resources[i].costCenter.value && (!costCentersBySubDomain[action.payload.value] || !costCentersBySubDomain[action.payload.value].find((costCenter) => {
                return costCenter.id === resources[i].costCenter.value
            })))) {
                yield put(regionChangelogActions.regionalChangelogResourceItemCostCenterChanged(i, ''));
            }
        }
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

export function* activitySaga(action) {
    try {
        const planByCostCenter = yield select(getPlanByCostCenter);
        if (planByCostCenter) {
            return;
        }
        let params = {params: {pageable: false, regionType: regionTypeEnums.Market,  withDeleted: true}};
        if (action.index !== undefined) {
            if (!action.payload || !action.payload.value) {
                yield put(regionChangelogActions.regionalChangelogResourceItemGetActivitiesFinished(action.index, []));
                yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
                return;
            }
            params.params.costCenterId = action.payload.value;
        } else if (action.payload.value === undefined) {
            const domainId = yield select((store) => store.regionalChangelogForm.domain.value);
            params.params.domainId = domainId;
        } else {
            params.params.subDomainId = action.payload.value
        }
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(true));
        const activities = yield axios.get('api/wbs/activities', params);
        //change only for one resource
        if (action.index !== undefined) {
            yield put(regionChangelogActions.regionalChangelogResourceItemGetActivitiesFinished(action.index, activities.data.items));
        } else {
            yield put(regionChangelogActions.regionalChangelogActivitiesChanged(activities.data.items));
        }
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
    } catch (error) {
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
    
}

export function* wbsSaga(action) {
    try {
        const index = action.index;
        if (!action.payload) {
            yield put(regionChangelogActions.regionalChangelogResourceItemWbsChanged(index, ''));
            yield put(regionChangelogActions.regionalChangelogResourceItemGetWbsesFinished(index, []));
            yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
            return;
        }
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(true));
        const wbses = yield axios.get('api/wbs', {params: {pageable: false, activityName: action.payload.label, withDeleted: true}});
        yield put(regionChangelogActions.regionalChangelogResourceItemGetWbsesFinished(index, wbses.data.items));
        yield put(regionChangelogActions.regionalChangelogResourceItemWbsChanged(index, ''));
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
    } catch(error) {
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
        yield put(regionChangelogActions.regionalChangelogResourceItemGetWbsesFinished(action.index), []);
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

export function* expenseTypeSaga(action) {
    try {
        const expenseTypesPerCostType = yield select(getExpenseTypesPerCostTypesWithFilteredDeleted);
        const expenseTypes = expenseTypesPerCostType[action.payload.value];
        const resources = yield select(getResources);
        const index = action.index;
        if (!expenseTypes || !expenseTypes.find((expenseType) => {return resources[index].expenseType && expenseType.value === resources[index].expenseType.value})) {
            yield put(regionChangelogActions.regionalChangelogResourceItemExpenseTypeChanged(index , ''));
        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

function* loadFormFromActivitySaga(action) {
    try {
        if (!action.payload || action.payload.value === undefined) {
            return;
        }
        const selectedCostCenter = yield select(state => state.regionalChangelogForm.resources[action.index].costCenter);
        if (selectedCostCenter && selectedCostCenter.value !== undefined) {
            return;
        }
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(true));
        let activity = yield axios.get('api/activities/'+ action.payload.value);
        activity = activity.data;
        const domains = yield select(store => store.domains.allDomains);
        const subDomains = yield select(store => store.subDomains.allSubDomains);
        const costCenters = yield select(store => store.costCenters.allItems);
        let newDomain = domains.find((domain) => {
            return domain.id === activity.domainId
        })
        const newSubDomain = subDomains.find((subDomain) => {
            return subDomain.id === activity.subDomainId
        })
        const newCostCenter = costCenters.find((costCenter) => {
            return costCenter.id === activity.costCenterId
        });
        yield put(regionChangelogActions.regionalChangelogSetState({
            domain: {
                label: newDomain.name,
                value: newDomain.id
            },
            subDomain: {
                label: newSubDomain.name,
                value: newSubDomain.id
            },
        }));
        yield* activitySaga({
            payload: {
                label: newSubDomain.name,
                value: newSubDomain.id
            }
        })
        yield put(regionChangelogActions.regionalChangelogResourceItemCostCenterChanged(action.index, {
            label: newCostCenter.name,
            value: newCostCenter.id
        }));
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
        yield put(regionChangelogActions.regionalChangelogChangeLoadingSpinner(false));
    }
}

function* clearActivitySaga(action) {
    try {
        const selectedActivity = yield select(state => state.regionalChangelogForm.resources[action.index].activity);
        if (selectedActivity && selectedActivity.value && !action.payload.find((activity) => {
            return selectedActivity.value === activity.id
        })) {
            yield put(regionChangelogActions.regionalChangelogResourceItemActivityChanged(action.index, ''))
        }
        
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}
function* costTypeSaga(action) {
    try {
        if (!action.payload || action.payload.value === undefined) {
            yield put(regionChangelogActions.regionalChangelogResourceItemCostTypeChanged(action.index, ''));
            return;
        }
        yield take(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_WBSES_FINISHED);
        const costTypesPerResource = yield select(getCostTypesPerResource);
        const selectedCostType = yield select(store => store.regionalChangelogForm.resources[action.index].costType);
        if (!selectedCostType || !costTypesPerResource[action.index] || !costTypesPerResource[action.index].find((costType) => {
            return costType.value === selectedCostType.value
        })) {
            yield put(regionChangelogActions.regionalChangelogResourceItemCostTypeChanged(action.index, ''));
        }
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating budget plan data. Please try again later."));
    }
}

function* loadFormFromActivityWrapperSaga(action) {
    yield race({
        task: call(loadFormFromActivitySaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* initializeCreateWrapperForm(action) {
    yield race({
        task: call(initializeCreateForm, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* initializeEditWrapperForm(action) {
    yield race({
        task: call(initializeEditForm, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* subDomainWrapperSaga(action) {
    yield race({
        task: call(subDomainSaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* costCenterWrapperSaga(action) {
    yield race({
        task: call(costCenterSaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* activityWrapperSaga(action) {
    yield race({
        task: call(activitySaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* wbsWrapperSaga(action) {
    yield race({
        task: call(wbsSaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* costTypeWrapperSaga(action) {
    yield race({
        task: call(costTypeSaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* submitWrapperForm(action) {
    yield race({
        task: call(submitForm, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* expenseTypeWrapperSaga(action) {
    yield race({
        task: call(expenseTypeSaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
function* clearActivityWraperSaga(action) {
    yield race({
        task: call(clearActivitySaga, action),
        cancel: take(actionTypes.REGIONAL_CHANGELOG_FORM_RESET_FORM)
    })
}
export default [
    takeLatest(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED, loadFormFromActivityWrapperSaga),
    takeLatest(actionTypes.INITIALIZE_REGIONAL_CHANGELOG_FORM, initializeCreateWrapperForm),
    takeLatest(actionTypes.INITIALIZE_EDIT_REGIONAL_CHANGELOG_FORM, initializeEditWrapperForm),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_DOMAIN_CHANGED, subDomainWrapperSaga),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED, costCenterWrapperSaga),
    takeLatest([actionTypes.REGIONAL_CHANGELOG_SUBDOMAIN_CHANGED, actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_CENTER_CHANGED], activityWrapperSaga),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED, wbsWrapperSaga),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_ACTIVITY_CHANGED, costTypeWrapperSaga),
    takeLatest(actionTypes.REGIONAL_CHANGELOG_FORM_SUBMIT_STARTED, submitWrapperForm),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_COST_TYPE_CHANGED, expenseTypeWrapperSaga),
    takeEvery(actionTypes.REGIONAL_CHANGELOG_RESOURCE_ITEM_GET_ACTIVITIES_FINISHED, clearActivityWraperSaga),
];