import React, { memo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '../../../../components/Input/AutoSelect';
import Input from '../../../../components/Input/Input';
import ResourceItemTable from './ResourceItemTable';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import ActivitiesPicker from '../ActivitiesPicker';
import * as rules from '../../../../config/Rules';
import {canFunction} from '../../../../config/Can';
import {canEditWithResource as canEdit} from '../../../../utils/budgetPlanEditPrivileges';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
import { resourceModelFormLatestChangesChanged } from '../../../../store/actions/resourceModelFormActions';

const useStyles = makeStyles(theme => ({
    wrapper: {
        border: '1px solid #c8cace',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    wrapperBlue: {
        border: '1px solid #c8cace',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    wrapperRed: {
        border: '1px solid red',
        backgroundColor: '#ff9999',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: -15,
        '& > *': {
            flexBasis: 270,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    inputPadding: {
        display: 'none'
    },
    secondRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -15,
        '& > *': {
            flexBasis: 128,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    totalPriceWrapper: {
        paddingTop: 5
    },
    totalPriceLabel: {
        color: '#28c101',
        fontSize: 16
    },
    totalPriceValue: {
        color: '#28c101',
        fontSize: 16,
        paddingLeft: 12
    }  
}));
//budget year < currentYear
const objPlaceholder = {};
const stringPlaceholder = "";
const getTotalPrice = (props) => {
    let totalPrice = 0;
    if (props.budgetYear === 0) {
        return totalPrice;
    }
    let actualsBudgetEnd = getMonthFromWhichActualsAreNotAddedToTotalPrice(props);
    for (let i = 1; i <= 12; i++) {
        if (i < actualsBudgetEnd) {
            if (props.actuals && props.actuals[i] !== undefined) {
                totalPrice += parseFloat(props.actuals[i]);
            }
        } else {
            if (props.plans && props.plans[i] !== undefined) {
                totalPrice += parseFloat(props.plans[i]);
            }
        }
    }
    return <NumberFormat 
            value={totalPrice}
            displayType={'text'}
            thousandSeparator 
            decimalScale={2}
            fixedDecimalScale={2}
            allowedDecimalSeparators={[".",","]}
            isNumericString
        />;

}
const getMonthFromWhichActualsAreNotAddedToTotalPrice = (props) => {
    if (props.budgetYear === 0) {
        return 0;
    }
    if (props.budgetYear < props.currentYear) {
        return 13;
    }
    if (props.budgetYear > props.currentYear) {
        return 0;
    }
    if (props.budgetYear === props.currentYear) {
        return props.currentMonth;
    }

}

const ResourceItem = (props) => {
    const classes = useStyles();
    let wrapperClass = classes.wrapper;
    if (props.added || (props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].added)) {
        wrapperClass = classes.wrapperBlue
    }
    if (props.deleted) {
        wrapperClass = classes.wrapperRed
    }
    return (
        <div className={wrapperClass}
        >
        {canEdit(props, rules.EDIT_BUDGET_PLAN_DELETE_ITEM) && props.index !==0 && !props.hasActuals &&
            <div style={{
                position:'absolute',
                width:40, 
                height: 40, 
                top:-20, 
                right:-20, 
                borderRadius:"50%", 
                border:'1px solid #c8cace', 
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
                }} 
                onClick={props.deleteResource.bind(null, props.index)}
            >
                <DeleteOutlined color="primary"/>
            </div>}
            {canFunction(props, rules.EDIT_FORM_TAGS) ?
                <div className={classes.row}>
                    <Select 
                        label="Tags" 
                        disabled={!canEdit(props, rules.EDIT_FORM_TAGS)}
                        isMulti
                        width="100%" 
                        value={props.tags || ""} 
                        suggestions={props.tagsSuggestions} 
                        handleChangeSingle={(e) => {props.regionalChangelogLineTagsChanged(props.index, e)}}
                        className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].tags ? "input-updated" : !props.changes && stringPlaceholder}
                        tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].tags ? props.changes.resourcesMap[props.id].tags : stringPlaceholder}
                    />
                </div>
            : null
            }
            <div className={classes.row}>
                {!props.planByCostCenter ?
                    canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_COST_CENTER) ? 
                        <Select 
                            label="Cost Center"
                            errorText={props.errors.costCenter}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemCostCenterChanged(props.index, e)}}
                            suggestions={props.costCenters}
                            value={props.costCenter || stringPlaceholder}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costCenter ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costCenter ? props.changes.resourcesMap[props.id].costCenter : stringPlaceholder}
                        />  :
                        <Input 
                            label="Cost Center" 
                            disabled 
                            width="100%" 
                            value={props.costCenter.label || stringPlaceholder} 
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costCenter ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costCenter ? props.changes.resourcesMap[props.id].costCenter : stringPlaceholder}
                        />
                    : null
                }
                {
                    !canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_ACTIVITY) && !props.planByCostCenter ?
                        <Input 
                            label="Activity" 
                            disabled 
                            width="100%" 
                            value={props.activity.label || stringPlaceholder} 
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? props.changes.resourcesMap[props.id].activity : stringPlaceholder}
                        />
                    :
                    null
                }
                {canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_ACTIVITY) && !props.planByCostCenter ? 
                    props.domain ? 
                        <Select 
                            label="Activity" 
                            errorText={props.errors.activity}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemActivityChanged(props.index, e)}}
                            suggestions={props.activitiesPerResource[props.index]}
                            value={props.activity || stringPlaceholder}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? props.changes.resourcesMap[props.id].activity : stringPlaceholder}
                        /> 
                        : 
                        <ActivitiesPicker 
                            label="Activity" 
                            value={props.activity || stringPlaceholder}
                            errorText={props.errors.activity}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemActivityChanged(props.index, e)}}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].activity ? props.changes.resourcesMap[props.id].activity : stringPlaceholder}
                        /> 
                : null
                }
                {
                    canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_COST_TYPE) ?
                    <Select 
                        label="Cost Type"
                        value={props.costType || stringPlaceholder}
                        errorText={props.errors.costType}
                        suggestions={props.costTypesPerResource[props.index]}
                        handleChangeSingle={(e) => {props.regionalChangelogResourceItemCostTypeChanged(props.index, e)}}
                        className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costType ? "input-updated" : !props.changes && stringPlaceholder}
                        tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costType ? props.changes.resourcesMap[props.id].costType : stringPlaceholder}
                     /> :
                     <Input 
                        label="Cost Type" 
                        disabled 
                        width="100%" 
                        value={props.costType.label || stringPlaceholder}
                        className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costType ? "input-updated" : !props.changes && "disabled-form-input"}
                        tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].costType ? props.changes.resourcesMap[props.id].costType : stringPlaceholder}
                      />
                }    

                {!props.planByCostCenter ? 
                    canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_WBS) ?
                        <Select 
                            label="WBS"
                            value={props.wbs || stringPlaceholder}
                            errorText={props.errors.wbs}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemWbsChanged(props.index, e)}}
                            suggestions={props.wbsesPerResource[props.index]}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].wbs ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].wbs ? props.changes.resourcesMap[props.id].wbs : stringPlaceholder}
                        /> 
                        :
                        <Input 
                            label="WBS" 
                            disabled 
                            width="100%" 
                            value={props.wbs.label || stringPlaceholder} 
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].wbs ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].wbs ? props.changes.resourcesMap[props.id].wbs : stringPlaceholder}
                        />
                    :
                    null
                }
                {
                    canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_EXPENSE_TYPE) ?
                        <Select 
                            label="Expense Type" 
                            value={props.expenseType || stringPlaceholder}
                            errorText={props.errors.expenseType}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemExpenseTypeChanged(props.index, e)}}
                            suggestions={props.costType && props.costType.value ? props.expenseTypes[props.costType.value] : []}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].expenseType ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].expenseType ? props.changes.resourcesMap[props.id].expenseType : stringPlaceholder}
                        />
                    :
                        <Input 
                            label="Expense Type" 
                            disabled 
                            width="100%" 
                            value={props.expenseType.label || stringPlaceholder} 
                            className={props.changes && props.changes.resources && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].expenseType ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.changes && props.changes.resources && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].expenseType ? props.changes.resourcesMap[props.id].expenseType : stringPlaceholder }
                        />
                }
                {
                    canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_CURRENCY) ?
                        <Select 
                            label="Currency" 
                            value={props.currency || stringPlaceholder}
                            errorText={props.errors.currency}
                            handleChangeSingle={(e) => {props.regionalChangelogResourceItemCurrencyChanged(props.index, e)}}
                            suggestions={props.currencies}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].currency ? "input-updated" : !props.changes && stringPlaceholder}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].currency ? props.changes.resourcesMap[props.id].currency : stringPlaceholder}
                        />
                    :
                        <Input 
                            label="Currency" 
                            disabled 
                            width="100%" 
                            value={props.currency.label || stringPlaceholder}
                            className={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].currency ? "input-updated" : !props.changes && "disabled-form-input"}
                            tooltipText={props.id && props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] && props.changes.resourcesMap[props.id].currency ? props.changes.resourcesMap[props.id].currency : stringPlaceholder}
                        />
                }

            </div>
            <div>
                <ResourceItemTable 
                    actualsEnd={getMonthFromWhichActualsAreNotAddedToTotalPrice(props)} 
                    budgetYear={props.budgetYear}
                    plans={props.plans || objPlaceholder}
                    viewMode={props.viewMode}
                    currentMonth={props.currentMonth}
                    currentYear={props.currentYear}
                    allPlansEnabled={canFunction('', rules.EDIT_BUDGET_PLAN_ITEM_ALL_PLANS)}
                    plansEnabled={canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_PLANS)}
                    actualsEnabled={canEdit(props, rules.EDIT_BUDGET_PLAN_ITEM_ACTUALS) && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)}
                    actuals={props.actuals || objPlaceholder}
                    changes={props.changes && props.changes.resourcesMap && props.changes.resourcesMap[props.id] ? props.changes.resourcesMap[props.id] : objPlaceholder}
                    minWidth={840}
                    plansChanged={props.plansChanged && props.plansChanged.bind(null, props.index)}
                    actualsChanged={props.actualsChanged && props.actualsChanged.bind(null, props.index)}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Tooltip interactive title="Sum of actuals from the previous months + Plans from the current month up to December" placement="top">
                    <div className={classes.totalPriceWrapper}>
                        <span className={classes.totalPriceLabel}>
                            Total Budget:
                        </span>
                        <span className={classes.totalPriceValue}>
                        {getTotalPrice(props)} {props.currency ? props.currency.label : null}
                        </span>
                    </div>
                </Tooltip>    
            </div>
        </div>
    )
}
export default memo(ResourceItem);