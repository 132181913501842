import * as actionTypes from '../actions/actionTypes';

const initialState = {
    id: null,
    activityName: '',
    workId: '',
    planner: '',
    leader: '',
    sourceDataLink: '',
    legacyNumber: '',
    demandNumber: '',
    projectNumber: '',
    loading: false,
    errors: {},
    submitting: false,
    activityType: '',
    clientId: null,
    inactive: false,
    domain: '',
    subDomain: '',
    regionType: '',
    costCenter: '',
}

const getNewErrors = (state, value, errorProperty) => {
    const newErrors = {...state.errors};
    newErrors[errorProperty] = ''
    return newErrors;
}

const activityFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTIVITY_FORM_REGION_TYPE_CHANGED: {
            return {
                ...state,
                regionType: action.payload,
                errors: getNewErrors(state, action.payload, 'regionType')
            }
        }
        case actionTypes.ACTIVITY_FORM_SUB_DOMAIN_CHANGED: {
            return {
                ...state,
                subDomain: action.payload,
                errors: getNewErrors(state, action.payload, 'subDomain')
            }
        }
        case actionTypes.ACTIVITY_FORM_COST_CENTER_CHANGED: {
            return {
                ...state,
                costCenter: action.payload,
                errors: getNewErrors(state, action.payload, 'costCenter')
            }
        }
        case actionTypes.ACTIVITY_FORM_DOMAIN_CHANGED: {
            return {
                ...state,
                domain: action.payload,
                errors: getNewErrors(state, action.payload, 'domain')
            }
        }
        case actionTypes.ACTIVITY_FORM_INACTIVE_CHANGED: {
            return {
                ...state,
                inactive: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_CLIENT_ID_CHANGED: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_ACTIVITY_TYPE_CHANGED: {
            return {
                ...state,
                activityType: action.payload,
                errors: getNewErrors(state, action.payload, 'activityType')
            }
        }
        case actionTypes.ACTIVITY_FORM_PROJECT_NUMBER_CHANGED: {
            return {
                ...state,
                projectNumber: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_DEMAND_NUMBER_CHANGED: {
            return {
                ...state,
                demandNumber: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_LEGACY_NUMBER_CHANGED: {
            return {
                ...state,
                legacyNumber: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_ID_CHANGED: {
            return {
                ...state,
                id: action.payload
            }
        }
        case actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.ACTIVITY_EDIT_FORM_INITIALIZE_FINISHED: {
            return {
                ...state,
                loading: false
            }
        }       
        case actionTypes.RESET_ACTIVITY_FORM: {
            return {
                ...initialState,
            }
        }
        case actionTypes.ACTIVITY_FORM_INITIALIZE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ACTIVITY_FORM_INITIALIZE_FINISHED:
        case actionTypes.ACTIVITY_FORM_INITIALIZE_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.SUBMIT_EDIT_ACTIVITY_FORM_STARTED:
        case actionTypes.SUBMIT_CREATE_ACTIVITY_FORM_STARTED: {
            return {
                ...state,
                submitting: true,
                loading: true
            }
        }
        case actionTypes.SUBMIT_EDIT_ACTIVITIY_FORM_FAILED:
        case actionTypes.SUBMIT_CREATE_ACTIVITIY_FORM_FAILED: {
            return {
                ...state,
                submitting: false,
                loading: false
            }
        }
        case actionTypes.SUBMIT_EDIT_ACTIVITIY_FORM_FINISHED:
        case actionTypes.SUBMIT_CREATE_ACTIVITIY_FORM_FINISHED: {
            return {
                ...state,
                submitting: false,
                loading: false
            }
        }

        case actionTypes.ACTIVITY_FORM_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload
            }
        }
        case actionTypes.ACTIVITY_FORM_ACTIVITY_NAME_CHANGED: {
            return {
                ...state,
                activityName: action.payload,
                errors: getNewErrors(state, action.payload, 'activity')
            }
        }
        case actionTypes.ACTIVITY_FORM_WORK_ID_CHANGED: {
            return {
                ...state,
                workId: action.payload,
                errors: getNewErrors(state, action.payload, 'workId')
            }
        }
        case actionTypes.ACTIVITY_FORM_PLANNER_CHANGED: {
            return {
                ...state,
                planner: action.payload,
                errors: getNewErrors(state, action.payload, 'planner')
            }
        }
        case actionTypes.ACTIVITY_FORM_LEADER_CHANGED: {
            return {
                ...state,
                leader: action.payload,
                errors: getNewErrors(state, action.payload, 'leader')
            }
        }
        case actionTypes.ACTIVITY_FORM_SOURCE_DATA_LINK_CHANGED: {
            return {
                ...state,
                sourceDataLink: action.payload,
                errors: getNewErrors(state, action.payload, 'sourceDataLink')
            }
        }
        default: {
            return state
        }
    }
}

export default activityFormReducer;