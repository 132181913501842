import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isDialogVisible: false,
    errorMessage: '',
    doNotChangeMessage: false

}
const errorDialogReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.INVALID_REQUEST_OCCURED: {
            return {
                isDialogVisible: true,
                errorMessage: action.payload,
                doNotChangeMessage: true
            }
        }
        case actionTypes.OPEN_ERROR_DIALOG: {
            return {
                isDialogVisible: true,
                errorMessage: state.doNotChangeMessage ? state.errorMessage : action.payload
            }
        }
        case actionTypes.CLOSE_ERROR_DIALOG: {
            return {
                isDialogVisible: false,
                errorMessage: '',
                doNotChangeMessage: false
            }
        }
        default: {
            return state;
        }
    }
}
export default errorDialogReducer;