import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect'

const initialState = {
    newItemText: "",
    itemsBeingEdited: {},
    items: [],
    allItems: [],
    selectedItems: {},
    loading: false,
}
const oneColumnPickListReducer = (namespace) => (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_STARTED}`:
            return {
                ...state,
                loading: true
            }
        case `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_FAILED}`: {
            return {
                ...state,
                allItems: [],
                selectedItems: {},
                loading: false
            }
        }
        case `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_FINISHED}`: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                allItems: action.payload,
                selectedItems: selectedItems,
                loading: false
            }
        }
        case `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_STARTED}`:
            return {
                ...state,
                loading: true
            }
        case `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_FAILED}`:
            return {
                ...state,
                items: [],
                loading: false
            }
        case `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_FINISHED}`:
            return {
                ...state,
                loading: false,
                items: action.payload
            }
        case `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_STARTED}`:
            return {
                ...state,
                loading: true
            }
        case `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_FAILED}`:
            return {
                ...state,
                loading: false,
                itemsBeingEdited: {}
            }
        case `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_FINISHED}`:
            return {
                ...state,
                loading: false,
                itemsBeingEdited: {}
            }
        case `${namespace}/${actionTypes.NEW_PICKLIST_ITEM_TEXT_CHANGED}`:
            return {
                ...state,
                newItemText: action.payload.target.value
            }
        case `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_TEXT_CHANGED}`: {
            const itemsBeingEditedCopy = {...state.itemsBeingEditedCopy};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                itemsBeingEdited: itemsBeingEditedCopy
            } 
        }
        case `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_STARTED}`:
            return {
                ...state,
                loading: true
            }     
        case `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_FAILED}`:
            return {
                ...state,
                loading: false,
                newItemText: ""
            } 
        case `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_FINISHED}`:
            return {
                ...state,
                loading: false,
                newItemText: ""
            }                                  
        default: 
            return state
    }
}
const getExpenseType = (state) => state.expenseTypes.items;
export const getExpenseTypesParsed = createSelector([getExpenseType], (expenseTypes) => {
    return expenseTypes ? expenseTypes.map((expenseType) => {
        return {
            label: expenseType.name,
            value: expenseType.id
        }
    }) : [];
})

const getAllExpenseTypes = (state) => state.expenseTypes.allItems;
export const getAllExpenseTypesParsed = createSelector([getAllExpenseTypes], (expenseTypes) => {
    return expenseTypes ? expenseTypes.map((expenseType) => {
        return {
            label: expenseType.name,
            value: expenseType.id
        }
    }) : [];
})
const getAllCostTypes = (state) => state.costTypes.allItems;
export const getAllCostTypesParsed = createSelector([getAllCostTypes], (costTypes) => {
    return costTypes ? costTypes.map((costType) => {
        return {
            label: costType.name,
            value: costType.id
        }
    }) : [];
})
export const getAllCostTypesParsedWithDeletedFiltered = createSelector([getAllCostTypes], (costTypes) => {
    return costTypes ? costTypes.filter(costType => !costType.isDeleted).map((costType) => {
        return {
            label: costType.name,
            value: costType.id
        }
    }) : [];
})
const getCurrencies = (state) => state.currencies.items;
export const getCurrenciesParsed = createSelector([getCurrencies], (currencies) => {
    return currencies ? currencies.map((currency) => {
        return {
            label: currency.name,
            value: currency.id
        }
    }) : [];
});
const getAllCurrencies = (state) => state.currencies.allItems;
export const getAllCurrenciesParsedWithDeletedFiltered = createSelector([getAllCurrencies], (currencies) => {
    return currencies ? currencies.filter(currency => !currency.isDeleted).map((currency) => {
        return {
            label: currency.name,
            value: currency.id
        }
    }) : [];
});
const getCostTypes = (state) => state.costTypes.items;
export const getCostTypesParsed = createSelector([getCostTypes], (costTypes) => {
    return costTypes ? costTypes.map((costType) => {
        return {
            label: costType.name,
            value: costType.id
        }
    }) : [];
})

export default oneColumnPickListReducer;