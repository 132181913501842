import { put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as activityActions from '../store/actions/activity';
import axios from '../axios';
import activityTypes from '../constants/activityTypes';
import {encodeParamObject} from '../utils/queryParamsFormatter';
import regionTypes from '../constants/regionTypes';


function* getActivityColumnsConfig(action) {
    let columns = yield localStorage.getItem("activities-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(activityActions.getActivitiesColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(activityActions.getActivitiesColumnsFailed())
    }
}

function* saveColumnsConfig(action) {
    yield localStorage.setItem("activities-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getActivityColumnsConfig(action)
    yield put(activityActions.updateActivitiesColumnsFinished())
}

function* getActivities(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(activityActions.activitiesListLoadingSpinnerChanged(true));
        const params = action.payload.params || {};
        params.withDeleted = true;
        let data = yield axios.get('api/Activities', {params: encodeParamObject(params)});
        const inactive = {};
        data.data.items.forEach((item) => {
            if (item.isDeleted) {
                inactive[item.id] = true;
            }
        });
        data.data.items = data.data.items.map((item) => {
            return {
                ...item,
                activityType: activityTypes[item.activityType],
                regionType: regionTypes[item.regionType],
            }
        });
        data.data.inactiveMap = inactive;
        yield put(activityActions.getActivitiesListFinished(data.data));
    } catch (error) {
        yield put(activityActions.getActivitiesListFailed(error));
    }
}

export default [
    takeEvery(actionTypes.GET_ACTIVITES_COLUMNS_STARTED, getActivityColumnsConfig),
    takeEvery(actionTypes.UPDATE_ACTIVITIES_COLUMNS_STARTED, saveColumnsConfig),
    takeLatest(actionTypes.GET_ACTIVITIES_LIST_STARTED, getActivities),
];