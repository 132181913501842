import React from 'react';
import Input from '../../../../../components/Input/Input';
import UserPicker from '../../../../../containers/UserPicker/UserPicker';
import formSectionWrapper from '../../../ChangelogForm/components/FormSectionWrapper';
import { isVendorTypeExternal } from '../../../../../utils/typesAssertions';

export default formSectionWrapper(({changes, vendorType, canEdit, vendor, supplierRepresentative, dcApprover, approverChanged, supplierChanged}) => {
    return (
        <React.Fragment>
            <div style={{position: 'relative'}}>
                <Input label="Supplier Name" width="100%" disabled value={vendor} className="disabled-form-input"/>
            </div>
            <div style={{position: 'relative'}}>
            {canEdit() ? 
                    <UserPicker 
                        user={dcApprover}
                        onChange={approverChanged}
                        label="Delivery Confirmation Approver"
                        className={changes && changes.dcApprover ? "input-updated" : ""}
                        tooltipText={changes && changes.dcApprover ? changes.dcApprover : null}
                    /> :
                <Input 
                    label="Delivery Confirmation Approver" 
                    width="100%" 
                    disabled 
                    value={dcApprover.label || ''} 
                    className={changes && changes.dcApprover ? "input-updated" : "disabled-form-input"}
                    tooltipText={changes && changes.dcApprover ? changes.dcApprover : null}
                />
            }
            </div>
            {isVendorTypeExternal(vendorType) ?
            <div style={{position: 'relative'}}>
                {canEdit() ?
                    <UserPicker
                        user={supplierRepresentative}
                        onChange={supplierChanged}
                        label="Supplier Representative"
                        className={changes && changes.supplierRepresentative ? "input-updated" : ""}
                        tooltipText={changes && changes.supplierRepresentative ? changes.supplierRepresentative : null}
                    /> :
                    <Input
                        label="Supplier Representative"
                        width="100%"
                        disabled
                        value={supplierRepresentative.label || ''}
                        className={changes && changes.supplierRepresentative ? "input-updated" : "disabled-form-input"}
                        tooltipText={changes && changes.supplierRepresentative ? changes.supplierRepresentative : null}
                    />
                }
            </div> : null
            }
        </React.Fragment>
    )
}, "Roles");