import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextWithIcon from '../../../../components/Input/TextWithIcon';
import formSectionWrapper from '../components/FormSectionWrapper'
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import { getCreatedByString } from '../../../../store/reducers/ChangeLogFormReducer';

const mapStateToProps = (state) => {
    return {
        createdBy: getCreatedByString(state),
    }
}
class ChangelogLastModifiedByContainer extends Component {
    render() {
        return (
            <div style={{position: 'relative', paddingTop: 20}}>
                <TextWithIcon label="Created by" value={this.props.createdBy} icon={AssignmentIndOutlined}/>
            </div>
        )
    }
}
export default formSectionWrapper(connect(mapStateToProps, null)(ChangelogLastModifiedByContainer));