import { put, takeEvery, takeLatest, delay, select, take, race, call, fork } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as resourceModelActions from '../store/actions/resourceModelActions';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import axios from '../axios';
import months from '../constants/months';
import dcGenerationErrorStatuses from '../constants/dcGenerationErrorStatuses';
import resourceTypes from '../constants/resourceTypes';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import formatDate from '../utils/dateFormatter';
import { statuses } from '../constants/changelogStatuses';

function* getResourceModelColumnsConfig(action) {
    //action payload -> domainName
    let columns = yield localStorage.getItem(action.payload.domain +"-resource-model-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(resourceModelActions.getResourceModelColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(resourceModelActions.getResourceModelColumnsFailed())
    }
}

function* saveDeliveryConfirmationColumnsConfig(action) {
    yield localStorage.setItem(action.payload.domain +"-resource-model-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getResourceModelColumnsConfig(action)
    yield put(resourceModelActions.updateResourceModelColumnsFinished());
}

function* getDeliveryConfirmationModalData(action) {
    try {
        yield put(resourceModelActions.getResourceModalGenerateDcModalDataStarted());
        const selectedDomain = yield select((store) => store.user.selectedDomain);
        const selectedDomainUrlAddition = selectedDomain.name !== 'view all' ? "/" + selectedDomain.id : ""
        const selectedYear = yield select((store) => store.resourceModel.selectedYear);
        let data;
        if (action.payload === true) {
            data = yield axios.post('api/resourceModel/generateDC/'+selectedYear.value + selectedDomainUrlAddition, []);

        } else {
            data = yield axios.post('api/resourceModel/generateDC/'+ selectedYear.value + selectedDomainUrlAddition , action.payload);
        }
        const dat = {};
        data.data.forEach((item) => {
            dat[months[item.month-1]] = {}
            dat[months[item.month -1]].amount = item.amount;
            dat[months[item.month-1]].disabled = !item.canGenerate;
            dat[months[item.month-1]].title = item.amount !== 0 ? 
            item.amount +" DC"+ (item.amount !== 1 ? "s": "") + " will be generated for this month" 
            : dcGenerationErrorStatuses[item.status];
        })
        dat.itemsCount = 0;
        yield put(resourceModelActions.getResourceModalGenerateDcModalDataFinished(dat));
    } catch(e) {
        yield put(resourceModelActions.getResourceModalGenerateDcModalDataFailed());
        yield put(errorDialogActions.openErrorDialog('There was an error while loading DC modal data. Please try again later'));
    }
}

function* generateDcModalYearChangedSaga(action) {
    try {
        const selectAll = yield select((store) => store.resourceModel.selectAll);
        yield put(resourceModelActions.resourceModalSelectedMonthsChanged({}));
        if (selectAll) {
            yield* getDeliveryConfirmationModalData({payload:selectAll});
        } else {
            const selectedItems = yield select((store) => store.resourceModel.selectedResourceModels);
            const table = [];
            Object.keys(selectedItems).forEach((item) => {
                if (selectedItems[item]) {
                    table.push(item);
                }
            })
            yield* getDeliveryConfirmationModalData({payload : table});
        }
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog('There was an error while loading DC modal data. Please try again later'));
    }
}

function* generateDCsModalOpenedSaga(action) {
    try {
        const currentYear = new Date().getFullYear();
        const selectAll = action.payload;
        yield put(resourceModelActions.selectAllResourceModelsChanged(selectAll));
        yield put(resourceModelActions.resourceModalSelectedYearChanged({
            label: currentYear,
            value: currentYear
        }))
        yield take([actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FINISHED, actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FAILED]);
        const modalData = yield select((store) => store.resourceModel.generateDcModalData);

        const currentMonth = new Date().getUTCMonth();
        const defaultSelectedMonths = {};
        if (modalData && modalData[months[currentMonth]] && !modalData[months[currentMonth]].disabled) {
            defaultSelectedMonths[months[currentMonth]] = true;
        }
        if (currentMonth !==0 && modalData && modalData[months[currentMonth]] && !modalData[months[currentMonth -1]].disabled) {
            defaultSelectedMonths[months[currentMonth -1]] = true;
        }
        yield put(resourceModelActions.resourceModalSelectedMonthsChanged(defaultSelectedMonths));
    } catch(error) {
        yield put(errorDialogActions.openErrorDialog('There was an error while loading DC modal data. Please try again later'));
    }
}
function* generateDCsModalClosedSaga(action) {
    yield put(resourceModelActions.getResourceModalGenerateDcModalDataFinished({}));
    yield put(resourceModelActions.resourceModalSelectedMonthsChanged({}));
    yield put(resourceModelActions.resourceModalShouldConfirmFutureMonthsChanged(false));
    yield put(resourceModelActions.resourceModalVerifiedFutureMonthsChanged(false));
}

function* generateDCsModalSubmittedSaga(action) {
    try {
        const selectedMonths = yield select((store) => store.resourceModel.generateDcModalSelectedMonths);
        let haveToValidate = false;
        const selectedYear = parseInt(yield select((store) => store.resourceModel.selectedYear.value));
        const futureMonthsToBeValidated = {};
        let currentMonth = new Date().getUTCMonth();
        //If selected year is higher than current year we want to ask for confirmation for all months
        const currentYear = new Date().getFullYear()
        if (selectedYear > currentYear) {
            currentMonth = 0;
        }
        //If selected year is lower than current year we dont' have to ask for confirmation
        if (selectedYear < currentYear) {
            currentMonth = 11;
        }
        for (let i = currentMonth + 1; i < 12; i++) {
            if (selectedMonths[months[parseInt(i)]]) {
                haveToValidate = true;
                futureMonthsToBeValidated[months[parseInt(i)]] = true;
            }
        }
        const verifiedFutureMonths =  yield select((store) => store.resourceModel.verifiedFutureMonths);
        if (haveToValidate && !verifiedFutureMonths) {
            yield put(resourceModelActions.resourceModalShouldConfirmFutureMonthsChanged(true));
            yield put(resourceModelActions.submitResourceModalFinished());
            yield put(resourceModelActions.futureMonthsToBeValidatedChanged(futureMonthsToBeValidated));
            return;
        }
        //submit post
        const selectAll = yield select((store) => store.resourceModel.selectAll);
        const selectedDomain = yield select((store) => store.user.selectedDomain);
        const selectedDomainUrlAddition = selectedDomain.name !== 'view all' ? "/" + selectedDomain.id : "";
        const url = 'api/dc/generate/' + selectedYear + selectedDomainUrlAddition
        const ids = [];
        if (!selectAll) {
            const selectedItems = yield select((store) => store.resourceModel.selectedResourceModels);
            Object.keys(selectedItems).forEach((item) => {
                if (selectedItems[item]) {
                    ids.push(parseInt(item));
                }
            })
        }
        const selectedMonthsArray = []
         Object.keys(selectedMonths).forEach((month) => {
           if (selectedMonths[month]) {
            const monthIndex = months.indexOf(month);
            selectedMonthsArray.push(monthIndex+1);
           }
        })
        const res = yield axios.post(url, {ids: ids, months: selectedMonthsArray});
        yield put(resourceModelActions.resourceModalGenerateDcModalClosed());
        yield put(resourceModelActions.getResourceModalGenerateDcModalDataFinished({}));
        yield put(resourceModelActions.resourceModalShouldConfirmFutureMonthsChanged(false));
        yield put(resourceModelActions.resourceModalVerifiedFutureMonthsChanged(false));
        yield put(resourceModelActions.selectedResourceModelRowsChanged({}));
        yield put(resourceModelActions.resourceModalSelectedMonthsChanged({}));
        yield fork(handleGenerationStatus, res.data.reportId, res.data.executionArn);
    } catch(error) {
        yield put(resourceModelActions.submitResourceModalFinished());
        yield put(errorDialogActions.openErrorDialog('There was an error while submitting DC modal data. Please try again later'));
    }
}

function* getResourceModelsSaga(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(resourceModelActions.resourceModelListChangeLoadingSpinner(true));
        let url = `api/resourceModel${action.payload.domain && action.payload.domain.name !=="view all" ? `/domain/${action.payload.domain.id}` : ""}`;
        const {...other} = action.payload.params
        const data = yield axios.get(`${url}`, {params: encodeParamObject(other)});
        let resourceModels = [];
        if (data.data.items.length !== 0) {
            resourceModels = data.data.items.map((resourceModel) => {
                return {
                    ...resourceModel,
                    prepayment: resourceModel.prepayment ? "Yes" : "No",
                    dcyn: resourceModel.dcyn ? 'Yes' : "No",
                    approver: resourceModel.approver.name,
                    changelogStatus: statuses[resourceModel.changelogStatus].label,
                    supplierRepresentative: resourceModel.supplierRepresentative ? resourceModel.supplierRepresentative.name : "",
                    generationStatus: dcGenerationErrorStatuses[resourceModel.generationStatus] || 'Can generate',
                    lastModifiedDate: resourceModel.lastModifiedDate ? formatDate(resourceModel.lastModifiedDate) : "",
                    resourceType: resourceModel.resourceType !== undefined ? resourceTypes[resourceModel.resourceType] : "",
                    lastModifiedBy: resourceModel.lastModifiedBy ? `${resourceModel.lastModifiedBy.userEmail} (${resourceModel.lastModifiedBy.login})` : ""
                }
            });
        }
        const dataCopy = {
            ...data.data
        }
        dataCopy.items = resourceModels;
        yield put(resourceModelActions.getResourceModelsFinished(dataCopy));
    } catch (error) {
        yield put(resourceModelActions.getResourceModelsFailed());
    }
}

function* generateDCsModalOpenedWrapperSaga(action) {
    yield race({
        task: call(generateDCsModalOpenedSaga, action),
        cancel: take(actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED)
    })
}

function* generateDcModalYearChangedWrapperSaga(action) {
    yield race({
        task: call(generateDcModalYearChangedSaga, action),
        cancel: take(actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED)
    })
}

function* handleGenerationStatus(generationStatusId, executionArn) {
    try {
        const res =  yield axios.get('api/dc/generate/status/'+ generationStatusId + "/" + executionArn);
        if (res.data.errorMessage) {
            yield put(resourceModelActions.resourceModelGeneratingDcsChanged(false))
            yield put(resourceModelActions.resourceModelDcGenerationProgressChanged(0));
            yield put(errorDialogActions.openErrorDialog(res.data.errorMessage));
            return;
        }
        if (res.data.inProgress) {
            yield put(resourceModelActions.resourceModelGeneratingDcsChanged(true))
            yield put(resourceModelActions.resourceModelDcGenerationProgressChanged(100*(res.data.processed/res.data.totalAmount)));
            yield delay(1000);
            yield fork(handleGenerationStatus, generationStatusId, executionArn);
        } else {
            yield put(resourceModelActions.resourceModelGeneratingDcsChanged(false))
            yield put(resourceModelActions.resourceModelDcGenerationProgressChanged(0));
            const parsedFilters = yield select((state) => state.table.parsedFilters);
            const selectedDomain = yield select((state) => state.user.selectedDomain);
            const tableState = parsedFilters[`rm${selectedDomain && selectedDomain.name !=="view all" ? `-${selectedDomain.id}` : ""}`]
            if (tableState) {
                const domain = selectedDomain;
                const queryParameters = {...tableState};
                yield put(resourceModelActions.getResourceModelsStarted({params: encodeParamObject(queryParameters), domain: domain}))
            } else {
                yield put(resourceModelActions.getResourceModelsStarted({params: {}, domain: selectedDomain}));
            }

        }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while fetching DC generation status"));
        yield put(resourceModelActions.resourceModelGeneratingDcsChanged(false))
        yield put(resourceModelActions.resourceModelDcGenerationProgressChanged(0));
    }
}

export default [
    takeEvery(actionTypes.GET_RESOURCE_MODEL_COLUMNS_STARTED, getResourceModelColumnsConfig),
    takeEvery(actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_STARTED, saveDeliveryConfirmationColumnsConfig),
    takeLatest(actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_OPENED, generateDCsModalOpenedWrapperSaga),
    takeLatest(actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED, generateDCsModalClosedSaga),
    takeLatest(actionTypes.SUBMIT_RESOURCE_MODAL_STARTED, generateDCsModalSubmittedSaga),
    takeLatest(actionTypes.GET_RESOURCE_MODELS_STARTED, getResourceModelsSaga),
    takeLatest(actionTypes.RESOURCE_MODAL_SELECTED_YEAR_CHANGED, generateDcModalYearChangedWrapperSaga)
];