import { put, takeEvery, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as domainActions from '../store/actions/domains';
import * as navbarActionCreators from '../store/actions/Navbar';
import * as userActionCreators from '../store/actions/User';
import axios from '../axios';
import history from '../history';

export function* getDomains(domainId) {
    try {
        yield put(domainActions.domainsLoadingChanged(true));
        const domains = yield axios.get('api/domains');
        localStorage.setItem("domains", JSON.stringify(domains.data));
        if (!Number.isNaN(parseInt(domainId))) {
            const item = domains.data.find((domain) => {
                return domain.id === domainId;
            })
            if (!item) {
                const additionalItem = yield axios.get('api/domains/' + domainId);
                additionalItem.data.additional = true;
                domains.data.push(additionalItem.data);
            }
        }
        const dataFormatted = domains.data.map((domain) => {
            if (!domain.planner) {
                domain.planner = {};
            }
            return {
                ...domain
            }
        });
        yield put(domainActions.getDomainsFinished(dataFormatted));
    } catch(error) {
        yield put(domainActions.getDomainsFailed(error))
    }
}
export function* getUserDomains(action) {
    try {
        let urlStart;
        let userDomainProp;
        yield put(userActionCreators.getUserDomainsStarted());
        if (history.location.pathname.indexOf("changelogs/my") !== -1) {
            urlStart= "api/user/changelogs/domains"
            userDomainProp = "my";
        }
        if (history.location.pathname.indexOf("viewMy") !== -1) {
            urlStart = "api/dc/MyDcs/domains";
            userDomainProp = "viewMy";
        }
        if (history.location.pathname.indexOf("submit") !== -1) {
            urlStart = "api/dc/ToSubmit/domains";
            userDomainProp = "submit";
        }
        if (history.location.pathname.indexOf("approve") !== -1) {
            urlStart = "api/dc/ToApprove/domains";
            userDomainProp = "approve";
        }
        const response = yield axios.get(urlStart);
        const domains = response.data;
        // const domains = [{"planner":"Akram, Waqar","id":1,"name":"Consumer Platform","isDeleted":false,"clientId":null}];
        const userDomains = yield select((store) => store.user.userDomains);
        let newUserDomains = {...userDomains};
        newUserDomains[userDomainProp] = domains;
        yield put(userActionCreators.getUserDomainsFinished(newUserDomains))
    } catch (error) {
        yield put(userActionCreators.getUserDomainsFailed());
    }
}
export function* getAllDomains() {
    try {
        const domains = yield axios.get('api/domains', {params:{withDeleted: true}});
        const dataFormatted = domains.data.map((domain) => {
            if (!domain.planner) {
                domain.planner = {};
            }
            return {
                ...domain
            }
        })
        yield put(domainActions.getAllDomainsFinished(dataFormatted));
    } catch(error) {
        yield put(domainActions.getAllDomainsFailed(error))
    }
}

function* addDomain(action) {
    try {
        const domains = yield axios.post('api/domains', action.payload);
        yield* getAllDomains();
        yield* getDomains();
        yield put(domainActions.addDomainFinished(domains.data));
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(domainActions.addDomainFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editDomain(action) {
    try {
        yield axios.put('api/domains', action.payload);
        yield* getAllDomains();
        yield* getDomains();
        yield put(domainActions.editDomainFinished());
        const selectedUserDomain = yield select(state => state.user.selectedUserDomain);
        const selectedDomain = yield select(state => state.user.selectedDomain);
        const domains = yield select(state => state.domains.domains);
        if (action.payload.id === selectedUserDomain.id && action.payload.isDeleted) {
            yield put(userActionCreators.selectUserDomain(domains[0]));
        }
        if (action.payload.id === selectedDomain.id && action.payload.isDeleted) {
            yield put(userActionCreators.selectDomain(domains[0]));
        } 
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(domainActions.editDomainFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}
const sagas =  [
    takeEvery(actionTypes.GET_DOMAINS_STARTED, getDomains),
    takeEvery(actionTypes.GET_ALL_DOMAINS_STARTED, getAllDomains),
    takeEvery(actionTypes.ADD_DOMAIN_STARTED, addDomain),
    takeEvery(actionTypes.EDIT_DOMAIN_STARTED, editDomain)
]
export default sagas