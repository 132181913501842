import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    items: [],
    selectedItems: {},
    itemsBeingEditedMap: {},
    newItem: {},
}

const tagsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_TAG_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_TAG_FAILED: {
            return {
                ...state,
                loading: false,
                newItem: {}
            }
        }
        case actionTypes.ADD_TAG_FINISHED: {
            return {
                ...state,
                loading: false,
                newItem: {}
            }
        }
        case actionTypes.EDIT_TAG_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_TAG_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.EDIT_TAG_FINISHED: {
            const itemsBeingEditedCopy = {...state.itemsBeingEditedMap};
            delete itemsBeingEditedCopy[action.payload];
            return {
                ...state,
                loading: false,
                itemsBeingEditedMap: itemsBeingEditedCopy,
            }
        }
        case actionTypes.TAG_PICKLIST_NEW_ITEM_CHANGED: {
            return {
                ...state,
                newItem: action.payload
            }
        }
        case actionTypes.TAG_PICKLIST_EDIT_ITEM_CHANGED: {
            const itemsBeingEditedCopy = {...state.itemsBeingEditedMap};
            if (!action.payload.canBeSaved) {
                delete itemsBeingEditedCopy[action.payload.id];
            } else {
                itemsBeingEditedCopy[action.payload.id] = action.payload;
            }
            return {
                ...state,
                itemsBeingEditedMap: itemsBeingEditedCopy
            }
        }

        case actionTypes.GET_ALL_TAGS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_ALL_TAGS_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.GET_ALL_TAGS_FINISHED: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                loading: false,
                items: action.payload,
                selectedItems: selectedItems
            }
        }
        default: {
            return state
        }
    }
}

export default tagsReducer;