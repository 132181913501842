import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tags from './components/Tags';
import { getDcChanges } from '../../../../store/reducers/DeliveryConfirmationFormReducer';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';


const mapStateToProps = (state) => {
    return {
        status: state.deliveryConfirmationForm.status,
        isEditing: state.deliveryConfirmationForm.editButtonPressed,
        tags: state.deliveryConfirmationForm.tags,
        tagsSuggestions: state.deliveryConfirmationForm.tagsSuggestions,
        changes: getDcChanges(state)
    }
}
const mapDispatchToProps = (dispatch) => ({
    tagsChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormTagsChanged(payload)),
}) 
class TagsContainer extends Component {
    render() {
        return (
            <Tags {...this.props}/>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TagsContainer);