import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 260,
    backgroundColor: theme.palette.background.paper
  },
  container: {
    paddingTop: 30,
    width: 250
  }
}));
const preventScroll = function(e) {
  if (e.button === 1) {
    e.preventDefault();
    return false;
  }
}
const initialState = {
  mouseX: null,
  mouseY: null,
  data: null
};

export default function SelectedListItem(props) {
  const [state, setState] = React.useState(initialState);

  const preventContextMenu = function(e) {
    e.preventDefault();
    return false;
  }

  const handleClick = (data, event) => {
    event.preventDefault();
    setState({
      data: data,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const menuItemClicked = (e) => {
    window.open(window.location.origin + state.data, "_blank");
  }

  const navigate = (data, e) => {
    if (e && (e.button === 2)) {
      return;
    }
    if (e && (e.button === 1)) {
        window.open(window.location.origin + data, "_blank");
    } else {
        props.onItemClick(data);
    }
  }

  const handleClose = () => {
    setState(initialState);
  };
  
  const classes = useStyles();
  
  return (
    <div className={classes.container} onClick={handleClose} onContextMenu={preventContextMenu}>
      <List component="nav" className={classes.root} disablePadding={true}>
        {props.items.map(item => {
          return item.subItems.length !== 0 ? 
          (
            <div key={item.name}>
              <ListItem
                className="drawer-mainitem"
                onContextMenu={item.subItems.length === 1 ? handleClick.bind('', item.subItems[0].url) : null}
                onMouseDown={preventScroll}
                onClick={handleClose}
                onMouseUp={item.subItems.length === 1 ? navigate.bind(this, item.subItems[0].url) : null}
                key={item.name}
                button
                selected={item.selected}
              >
                {<item.icon color="inherit" />}
                <ListItemText className="drawer-mainitem" primary={item.name} disableTypography/>
              </ListItem>
              <List>
                {item.subItems.length > 1 &&
                  item.subItems.map(subItem => {
                    return (
                      <ListItem
                        onContextMenu={handleClick.bind('', subItem.url)}
                        onClick={handleClose}
                        className="drawer-subitem"
                        key={`${item.name}-${subItem.label}`}
                        button
                        selected={subItem.selected}
                        onMouseDown={preventScroll}
                        onMouseUp={navigate.bind(this, subItem.url)}
                      >
                        <ListItemText className="drawer-subitem" primary={subItem.label} disableTypography/>
                      </ListItem>
                    );
                  })}
              </List>
              
            </div>
          ) : null
        })}
      </List>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={menuItemClicked}>Open in new tab</MenuItem>
      </Menu>
    </div>
  );
}
