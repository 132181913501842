import { put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as oneColumnPicklistActionCreators from '../store/actions/OneColumnPickList';
import * as expenseTypePicklistActionCreators from '../store/actions/ExpenseTypePickList';
import * as navbarActionCreators from '../store/actions/Navbar';
import * as resourceTypeActionCreators from '../store/actions/resourceTypes';
import * as vendorsActions from '../store/actions/vendors';
import * as poLocationActions from '../store/actions/poLocation';
import * as expenseTypeCostTypeActions from '../store/actions/expenseTypeCostType';
import * as rules from '../config/Rules';
import { canFunction } from '../config/Can';
import {getAllDomains, getDomains} from './domainsSagas';
import { getAllCostCenters } from './costCenterSagas'
import axios from '../axios';
import { getAllSubDomains, getSubDomains } from './subDomainsSagas';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import { getAllTags } from './tagsSagas'

const oneColumnDictionaries = ['grades', 'currencies', 'resourceLocations', 'expenseTypes', 'costTypes']
export const oneColumnDictionariesSagas = {
    grades: {

    },
    currencies: {

    },
    resourceLocations: {

    },
    expenseTypes: {

    },
    costTypes: {

    }
}
oneColumnDictionaries.forEach((domain) => {
    //id can be changed for id list
    oneColumnDictionariesSagas[domain].get = function* (id) {
        try {
            const data = yield axios.get(`api/${domain}`);
            if (id) {
                const item = data.data.find((domain) => {
                    return domain.id === id;
                })
                if (!item) {
                    const additionalItem = yield axios.get(`api/${domain}/${id}`);
                    additionalItem.additional = true;
                    data.data.push(additionalItem.data);
                }
            }
            yield put(oneColumnPicklistActionCreators.getPicklistItemsFinished(domain, data.data));
        } catch(error) {
            yield put(oneColumnPicklistActionCreators.getPicklistItemsFailed(domain, error))
        }
    }
    oneColumnDictionariesSagas[domain].getAll = function* (action) {
        try {
            const data = yield axios.get(`api/${domain}`, {params:{withDeleted: true}});
            yield put(oneColumnPicklistActionCreators.getAllPicklistItemsFinished(domain, data.data));
        } catch(error) {
            yield put(oneColumnPicklistActionCreators.getAllPicklistItemsFailed(domain, error))
        }
    }
    oneColumnDictionariesSagas[domain].put = function* (action) {
        try {
            const data = yield axios.put(`api/${domain}`, action.payload);
            yield* oneColumnDictionariesSagas[domain].getAll();
            yield* oneColumnDictionariesSagas[domain].get();
            yield put(oneColumnPicklistActionCreators.editPicklistItemFinished(domain, data.data));
            yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
        } catch(error) {
            yield put(oneColumnPicklistActionCreators.editPicklistItemFailed(domain, error));
            yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
        }
    }
    oneColumnDictionariesSagas[domain].post = function* (action) {
        try {
            yield axios.post(`api/${domain}`, action.payload);
            yield* oneColumnDictionariesSagas[domain].getAll();
            yield* oneColumnDictionariesSagas[domain].get();
            yield put(oneColumnPicklistActionCreators.addPicklistItemFinished(domain));
            yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
        } catch(error) {
            yield put(oneColumnPicklistActionCreators.addPicklistItemFailed(domain, error))
            yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
        }
    }
})

const oneColumnsDictionariesSagas = [];
for (let i = 0; i < oneColumnDictionaries.length; i++) {
    oneColumnsDictionariesSagas.push(takeEvery(`${oneColumnDictionaries[parseInt(i)]}/${actionTypes.EDIT_PICKLIST_ITEM_STARTED}`, oneColumnDictionariesSagas[oneColumnDictionaries[i]].put));
    oneColumnsDictionariesSagas.push(takeEvery(`${oneColumnDictionaries[parseInt(i)]}/${actionTypes.GET_PICKLIST_ITEMS_STARTED}`, oneColumnDictionariesSagas[oneColumnDictionaries[i]].get));
    oneColumnsDictionariesSagas.push(takeEvery(`${oneColumnDictionaries[parseInt(i)]}/${actionTypes.GET_ALL_PICKLIST_ITEMS_STARTED}`, oneColumnDictionariesSagas[oneColumnDictionaries[i]].getAll));
    oneColumnsDictionariesSagas.push(takeEvery(`${oneColumnDictionaries[parseInt(i)]}/${actionTypes.ADD_PICKLIST_ITEM_STARTED}`, oneColumnDictionariesSagas[oneColumnDictionaries[i]].post));
}


export function* getExpenseTypes() {
    try {
        const expenseTypes = yield axios.get('api/expenseTypes');
        yield put(expenseTypePicklistActionCreators.getExpenseTypesFinished(expenseTypes.data));
    } catch(error) {
        yield put(expenseTypePicklistActionCreators.getExpenseTypesFailed(error));
    }
}

function* addExpenseType(action) {
    try {
        const expenseTypes = yield axios.post('api/expenseTypes', action.payload);
        yield put(expenseTypePicklistActionCreators.getExpenseTypesStarted());
        yield put(expenseTypePicklistActionCreators.addExpenseTypeFinished(expenseTypes.data));
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingStarted());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(expenseTypePicklistActionCreators.addExpenseTypeFailed(error));
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editExpenseType(action) {
    try {
        const expenseTypes = yield axios.put('api/expenseTypes', action.payload);
        yield put(expenseTypePicklistActionCreators.getExpenseTypesStarted())
        yield put(expenseTypePicklistActionCreators.editExpenseTypeFinished(expenseTypes.data))
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingStarted());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(expenseTypePicklistActionCreators.editExpenseTypeFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* deleteExpenseType(action) {
    try {
        const expenseTypes = yield axios.delete(`api/expenseTypes/${action.payload}`);
        yield put(expenseTypePicklistActionCreators.getExpenseTypesStarted())
        yield put(expenseTypePicklistActionCreators.deleteExpenseTypeFinished(expenseTypes.data))
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(expenseTypePicklistActionCreators.deleteExpenseTypeFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* getResourceTypesByDomain() {
    try {
        const resourceTypes = yield axios.get('api/resourceTypes/domainSettings');
        yield put(resourceTypeActionCreators.getResourceTypeByDomainFinished(resourceTypes.data));
    } catch (error) {
        yield put(resourceTypeActionCreators.getResourceTypeByDomainFailed());
    }
}

export function* getResourceTypesByVendor() {
    try {
        const resourceTypes = yield axios.get('api/resourceTypes/vendorTypeSettings');
        resourceTypes.data = resourceTypes.data.map((item) => {
            return {
                ...item
            }
        })
        yield put(resourceTypeActionCreators.getResourceTypeByVendorTypeFinished(resourceTypes.data));
    } catch (error) {
        yield put(resourceTypeActionCreators.getResourceTypeByVendorTypeFailed());
    }
}

function* editResourceTypeByVendor(action) {
    try {
        yield axios.put('api/resourceTypes/vendorTypeSettings', action.payload);
        yield* getResourceTypesByVendor(); 
        yield put(resourceTypeActionCreators.editResourceTypeByVendorFinished());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch (error) {
        yield put(resourceTypeActionCreators.editResourceTypeByVendorFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}

export function* getVendors(action) {
    try {
        let vendors
        const params = action && action.payload ? action.payload : {};
        params.withDeleted = true;
        vendors = yield axios.get('api/vendors', {params: encodeParamObject(params)});
        yield put(vendorsActions.getVendorsFinished(vendors.data));
    } catch(error) {
        yield put(vendorsActions.getVendorsFailed(error));
    }
}

function* addVendor(action) {
    try {
        yield axios.post('api/vendors', action.payload);
        yield put(vendorsActions.getVendorsStarted());
        yield put(vendorsActions.addVendorFinished());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(vendorsActions.addVendorFailed(error));
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editVendor(action) {
    try {
        yield axios.put('api/vendors', action.payload);
        yield put(vendorsActions.getVendorsStarted())
        yield put(vendorsActions.editVendorFinished())
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(vendorsActions.editVendorFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* deleteVendor(action) {
    try {
        yield axios.delete(`api/vendors/${action.payload}`);
        yield put(vendorsActions.getVendorsStarted())
        yield put(vendorsActions.deleteVendorFinished())
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(vendorsActions.deleteVendorFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

export function* getPoLocations(id) {
    try {
        let poLocations = yield axios.get('api/poLocations');
        if (id) {
            const item = poLocations.data.find((domain) => {
                return domain.id === id;
            })
            if (!item) {
                const additionalItem = yield axios.get('api/poLocations/' + id);
                additionalItem.additional = true;
                poLocations.data.push(additionalItem.data);
            }
        }
        yield put(poLocationActions.getPoLocationsFinished(poLocations.data));
    } catch(error) {
        yield put(poLocationActions.getPoLocationsFailed(error));
    }
}
export function* getAllPoLocations(action) {
    try {
        let poLocations = yield axios.get('api/poLocations', {params:{withDeleted: true}});
        yield put(poLocationActions.getAllPoLocationsFinished(poLocations.data));
    } catch(error) {
        yield put(poLocationActions.getAllPoLocationsFailed(error));
    }
}

function* addPoLocation(action) {
    try {
        yield axios.post('api/poLocations', action.payload);
        yield put(poLocationActions.getAllPoLocationsStarted());
        yield put(poLocationActions.addPoLocationFinished());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(poLocationActions.addPoLocationFailed(error));
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editPoLocation(action) {
    try {
        yield axios.put('api/poLocations', action.payload);
        yield put(poLocationActions.getAllPoLocationsStarted())
        yield put(poLocationActions.editPoLocationFinished())
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(poLocationActions.editPoLocationFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

export function* getExpenseTypeCostTypeMapping(action) {
    try {
        const response = yield axios.get('api/expenseTypes/full');
        let data = response.data.map((expenseType) => {
            const expenseTypeCopy = {...expenseType};
            expenseTypeCopy.costTypes.forEach((costType) => {
                expenseTypeCopy[costType.id] = true
            });
            return expenseTypeCopy
        });
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingFinished(data));
    } catch(error) {
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}

function* deleteExpenseTypeCostTypeConnection(action) {
    try {
        yield axios.delete(`api/expenseTypes/${action.payload.expenseTypeId}/${action.payload.costTypeId}`)
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingStarted());
        yield put(expenseTypeCostTypeActions.deleteExpenseTypeCostTypeConnectionFinished())
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(expenseTypeCostTypeActions.deleteExpenseTypeCostTypeConnectionFailed())
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}
function* addExpenseTypeCostTypeConnection(action) {
    try {
        yield axios.post(`api/expenseTypes/${action.payload.expenseTypeId}/${action.payload.costTypeId}`)
        yield put(expenseTypeCostTypeActions.getAllExpenseTypeCostTypeMappingStarted());
        yield put(expenseTypeCostTypeActions.addExpenseTypeCostTypeConnectionFinished())
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));

    } catch(error) {
        yield put(expenseTypeCostTypeActions.addExpenseTypeCostTypeConnectionFailed())
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }
}
function* getStaticPicklistsSaga(action) {
    const selectedRole = yield select( (store) => store.user.selectedRole);
    if (canFunction(selectedRole, rules.VIEW_DOMAIN_PICKLIST) && action.payload.id === "Platforms") {
        yield* getAllDomains();
    }
    if (canFunction(selectedRole, rules.VIEW_SUB_DOMAIN_PICKLIST) && action.payload.id === "Sub-platforms") {
        yield* getDomains();
        yield* getAllSubDomains(true);
    }
    if (canFunction(selectedRole, rules.VIEW_PO_LOCATION_PICKLIST) && action.payload.id === "Po Locations") {
        yield* getAllPoLocations();
    }
    if (canFunction(selectedRole, rules.VIEW_EXPENSE_TYPE_PICKLIST) && action.payload.id === "Expense Types") {
        yield* oneColumnDictionariesSagas['costTypes'].getAll();
        yield* getExpenseTypeCostTypeMapping();
    }
    if (canFunction(selectedRole, rules.VIEW_COST_TYPE_PICKLIST) && action.payload.id === "Cost Types") {
        yield* oneColumnDictionariesSagas['costTypes'].getAll();
    }
    if (canFunction(selectedRole, rules.VIEW_GRADES_PICKLIST) && action.payload.id === "Grades") {
        yield* oneColumnDictionariesSagas['grades'].getAll();
    }
    if (canFunction(selectedRole, rules.VIEW_CURRENCIES_PICKLIST) && action.payload.id === "Currencies") {
        yield* oneColumnDictionariesSagas['currencies'].getAll();
    }
    if (canFunction(selectedRole, rules.VIEW_RESOURCE_LOCATIONS_PICKLIST) && action.payload.id === "Resource Locations") {
        yield* oneColumnDictionariesSagas['resourceLocations'].getAll();
    }
    if (canFunction(selectedRole, rules.VIEW_RESOURCE_TYPE_PICKLIST) && action.payload.id === "Resource Types per Vendor Type") {
        yield* getResourceTypesByVendor();
    }
    if (canFunction(selectedRole, rules.VIEW_COST_CENTER_PICKLIST) && action.payload.id === "Cost Centers") {
        yield* getAllCostCenters();
        yield* getDomains();
        yield* getSubDomains();
    }
    if (canFunction(selectedRole, rules.VIEW_TAGS_PICKLIST) && action.payload.id === "Tags") {
        yield* getAllTags();
    }
}

const sagas =  [
    ...oneColumnsDictionariesSagas,
    takeEvery(actionTypes.GET_EXPENSE_TYPES_STARTED, getExpenseTypes),
    takeEvery(actionTypes.GET_EXPENSE_TYPES_BY_DOMAIN_STARTED, getExpenseTypes),
    takeEvery(actionTypes.ADD_EXPENSE_TYPE_STARTED, addExpenseType),
    takeEvery(actionTypes.EDIT_EXPENSE_TYPE_STARTED, editExpenseType),
    takeEvery(actionTypes.DELETE_EXPENSE_TYPE_STARTED, deleteExpenseType),
    takeEvery(actionTypes.GET_RESOURCE_TYPE_BY_DOMAIN_STARTED, getResourceTypesByDomain),
    takeEvery(actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_STARTED, getResourceTypesByVendor),
    takeEvery(actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_STARTED, editResourceTypeByVendor),
    takeLatest(actionTypes.SELECTED_PICKLIST_CHANGED, getStaticPicklistsSaga),
    takeEvery(actionTypes.GET_VENDORS_STARTED, getVendors),
    takeEvery(actionTypes.EDIT_VENDOR_STARTED, editVendor),
    takeEvery(actionTypes.DELETE_VENDOR_STARTED, deleteVendor),
    takeEvery(actionTypes.ADD_VENDOR_STARTED, addVendor),
    takeEvery(actionTypes.GET_PO_LOCATIONS_STARTED, getPoLocations),
    takeEvery(actionTypes.GET_ALL_PO_LOCATIONS_STARTED, getAllPoLocations),
    takeEvery(actionTypes.ADD_PO_LOCATION_STARTED, addPoLocation),
    takeEvery(actionTypes.EDIT_PO_LOCATION_STARTED, editPoLocation),

    takeEvery(actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_STARTED, getExpenseTypeCostTypeMapping),
    takeEvery(actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED, deleteExpenseTypeCostTypeConnection),
    takeEvery(actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED, addExpenseTypeCostTypeConnection),
    // takeEvery(actionTypes.DELETE_PO_LOCATION_STARTED, deletePoLocation),
]
export default sagas