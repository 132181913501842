import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import DeliveryInformationContainer from './DeliveryInformationContainer';
import RolesContainer from './RolesContainer';
import ResourceContainer from './ResourceContainer';
import CommentsContainer from './CommentsContainer';
import FormSubmitPanelContainer from './FormSubmitPanelContainer';
import SupportingDocumentationContainer from './SupportingDocumentationContainer';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import TextWithIcon from '../../../components/Input/TextWithIcon';
import ListAlt from '@material-ui/icons/ListAlt';
const styles = {
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.resourceModelForm.loading || state.resourceModelForm.submitting,
        changelogId: state.resourceModelForm.resourceModel && state.resourceModelForm.resourceModel.changelogId ? state.resourceModelForm.resourceModel.changelogId : ''
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(resourceModelFormActions.getResourceModelStarted(payload));
      },
      resetForm: () => {
        dispatch(resourceModelFormActions.resetResourceModelForm());
      }
  });


class ResourceModelEditFormContainer extends Component {
    componentDidMount() {
        this.props.loadView(this.props.location.pathname.split('/')[2]);
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render() {
        return (
            <div className={this.props.classes.container}>
                <LoadingSpinner loading={this.props.loading} spinnerCentered={true}/>
                <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                            <h3 style={{
                                color: '#000',
                                fontSize: 32,
                                lineHeight: '40px',
                                fontWeight: 'bold',
                                textAlign: 'left'
                            }}>Resource Model for Changelog {this.props.changelogId}</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <TextWithIcon icon={ListAlt} label="RM unique ID" value={this.props.location.pathname.split('/')[2]}/>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <DeliveryInformationContainer />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RolesContainer />
                    </Grid>                                   
                    <Grid item xs={12}>
                        <ResourceContainer />
                    </Grid>
                    <Grid item xs={12}>
                        <SupportingDocumentationContainer />
                    </Grid>
                    <Grid item xs={12}>
                        <CommentsContainer />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSubmitPanelContainer />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ResourceModelEditFormContainer);