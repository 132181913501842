import React, {Component} from 'react';
import Drawer from '../../components/Drawer/Drawer';
import DrawerList from '../../components/Drawer/DrawerList';
import Assignment from "@material-ui/icons/AssignmentOutlined";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import List from "@material-ui/icons/ListOutlined";
import Settings from '@material-ui/icons/Settings';
import { withRouter } from "react-router";
import * as rules  from '../../config/Rules';
import { canFunction } from '../../config/Can';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/Navbar';


const mapStateToProps = (state) => {
  return {
    drawerVisible: state.navbar.drawerVisible,
    selectedRole: state.user.selectedRole,
    userTabsVisible: state.user.supplierTabsVisible
  }};

const mapDispatchToProps = (dispatch) => ({
  hideDrawer: () => 
    dispatch(actionCreators.hideDrawer())    
});


class DrawerContainer extends Component {

    state = {
        isOpen: true,
        views: [
          {
              name: 'Change Logs',
              url:'/changelogs',
              subItems: [
                  {
                    action: rules.NAVIGATE_CHANGELOG_CREATE,
                    label: 'Create new',
                    url: '/changelogs/create'
                  },
                  {
                    action: rules.NAVIGATE_CHANGELOG_VIEWMY,
                    label: 'My Change Logs',
                    url: '/changelogs/my',
                  },
                  {
                      action: rules.NAVIGATE_CHANGELOG_VIEWALL,
                      label: 'View all',
                      url: '/changelogs/viewAll',
                  }

              ],
              icon: Assignment,
              color: '#39acff'
          },
          {
              name: 'Delivery Confirmation',
              url:'/deliveryconfirmation',
              subItems: [
                  {
                      action: rules.NAVIGATE_DELIVERY_REVIEW,
                      label: 'View my DCs',
                      url: '/deliveryconfirmation/viewMy'
                  },
                  {
                      action: rules.NAVIGATE_DELIVERY_SUBMIT,
                      label: 'Submit my DCs',
                      url: '/deliveryconfirmation/submit'
                  },
                  {
                      action: rules.NAVIGATE_DELIVERY_FILL_IN,
                      label: 'Fill in my DCs',
                      url: '/deliveryconfirmation/submit'
                  },
                  {
                      action: rules.NAVIGATE_DELIVERY_APPROVE,
                      label: 'Approve my DCs',
                      url: '/deliveryconfirmation/approve'
                  },
                  {
                    action: rules.NAVIGATE_DELIVERY_VIEW_ALL,
                    label: 'View all',
                    url: '/deliveryconfirmation/viewAll'
                  },
                  {
                    action: rules.NAVIGATE_DELIVERY_TO_BE_PROCESSED,
                    label: 'To be processed',
                    url: '/deliveryconfirmation/toBeProcessed'
                  }
              ],
              icon: AssignmentTurnedIn,
              color: '#9f7e5f'
          },
          {
              name: 'Resource Model',
              url: '/resourcemodel',
              subItems: [
                  {
                      action: rules.NAVIGATE_RESOURCEMODEL,
                      url: '/resourcemodel',
                  }
              ],
              icon: List,
              color: '#595959'
          }, 
          {
            name: 'SAP Actuals',
            url: '/sapActuals',
            subItems: [
              {
                action: rules.VIEW_SAP_ACTUALS,
                url:'/sapActuals'
              }, 
            ],
            icon: Settings,
            color: '#595959'
          },
          {
            name: 'Budget Plans',
            url: "/budgetPlans",
            subItems: [
              {
                action: rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC,
                label: 'Create new plan by cost center',
                url: "/budgetPlans/createByCc"
              },
              {
                action: rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY,
                label: 'Create new plan by activity',
                url: "/budgetPlans/createByActivity"
              },
              {
                action: rules.NAVIGATE_VIEW_MY_BUDGET_PLANS,
                label: "My budget plans",
                url: "/budgetPlans/my"
              },
              {
                action: rules.NAVIGATE_VIEW_ALL_BUDGET_PLANS,
                label: "View all",
                url: "/budgetPlans/viewAll"
              },
            ],
            icon: AccountBalanceOutlinedIcon,
            color: '#39acff'
          },
          {
            name: 'Edit Picklists',
            url: '/staticpicklist',
            divider: true,
            subItems: [
              {
                action: rules.NAVIGATE_EDITPICKLIST,
                url:'/staticpicklist'
              }
            ],
            icon: Settings,
            color: '#595959'
          },
          {
            name: 'Master Data',
            url: '/masterdata',
            subItems: [
              {
                action: rules.VIEW_ACTIVITY_FORM,
                label: 'Activities - create new',
                url:'/masterdata/createactivity'
              },
              {
                action: rules.VIEW_ACTIVITIES,
                label: 'Activities - view all',
                url:'/masterdata/viewallactivities'
              },
              {
                action: rules.VIEW_GRADES_PICKLIST,
                label: 'Grades',
                url:'/masterdata/grades'
              },
              {
                action: rules.VIEW_VENDOR_RATE_FORM,
                label: 'Vendor rates - create new',
                url:"/masterdata/createvendorrate"
              },
              {
                action: rules.VIEW_VENDOR_RATES,
                label: 'Vendor rates - view all',
                url: '/masterdata/viewallvendorrates'
              },
              {
                action: rules.VIEW_VENDOR_PICKLIST,
                label: 'Vendors',
                url: '/masterdata/vendors'
              },
              {
                action: rules.VIEW_WBS_FORM,
                label: 'WBS - create new',
                url: '/masterdata/createwbs'
              },
              {
                action: rules.VIEW_WBSES,
                label: 'WBS - view all',
                url: '/masterdata/viewallwbses'
              },
              {
                action: rules.VIEW_IMPORT_MASTERDATA,
                label: 'Import Master Data',
                url: '/masterdata/importmasterdata'
              },
            ],
            icon: Settings,
            color: '#595959'
          }
      ],
    }
    filterOptionsVisible = (role) => {
      const pathname = this.props.location.pathname;
      //copy all available options
      let views = [...this.state.views];
      views = views.map((view) => {
          const viewCopy = {...view};
          viewCopy.subItems = [];
          return viewCopy
      });
      //filter options based on role
      this.state.views.forEach((view, index) => {
          //selected flag to main item based on current path
          if (pathname.indexOf(view.url) !== -1) {
            views[parseInt(index)].selected = true;
          }
          view.subItems.forEach((subItem) => {
              subItem = {...subItem};
              if (canFunction(role, subItem.action)) {
                  views[parseInt(index)].subItems.push(subItem);
                  //add selected flag based on current path
                  if (pathname.indexOf(subItem.url) !== -1) {
                    subItem.selected = true;
                  }
              }
          })
      });
      return views;
  }

  render() {

    const views = this.filterOptionsVisible(this.props.selectedRole);
      return (
          <Drawer isOpen={this.props.drawerVisible} items={views} hideDrawer={this.props.hideDrawer}>
              {/* <Fab color="primary" className="create-new-changelog-button" onClick={this.navigate.bind(this, '/changelogs/create')}>
                <Add />
                CREATE NEW
              </Fab> */}
              <DrawerList items={views} onItemClick={this.props.history.push}/>
          </Drawer>
      )
  }
}
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DrawerContainer);
// export default withRouter(DrawerContainer);