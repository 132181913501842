import * as actionTypes from './actionTypes';

export const getVendorRatesColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_COLUMNS_STARTED, payload: myParam};
};
export const getVendorRatesColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_COLUMNS_FAILED, payload: myParam};
};
export const getVendorRatesColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_COLUMNS_FINISHED, payload: myParam};
};
export const getVendorRatesListStarted =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_LIST_STARTED, payload: myParam};
};
export const getVendorRatesListFailed =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_LIST_FAILED, payload: myParam};
};
export const getVendorRatesListFinished =  (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_LIST_FINISHED, payload: myParam};
};
export const updateVendorRatesColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_VENDOR_RATES_COLUMNS_STARTED, payload: myParam};
};
export const updateVendorRatesColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_VENDOR_RATES_COLUMNS_FAILED, payload: myParam};
};
export const updateVendorRatesColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_VENDOR_RATES_COLUMNS_FINISHED, payload: myParam};
};

export const intializeVendorRatesViewerStarted =  (myParam) => {
    return {type: actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_STARTED, payload: myParam};
};
export const intializeVendorRatesViewerFailed =  (myParam) => {
    return {type: actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_FAILED, payload: myParam};
};
export const intializeVendorRatesViewerFinished =  (myParam) => {
    return {type: actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_FINISHED, payload: myParam};
};


export const vendorRatesFormInitializeStarted =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_INITIALIZE_STARTED, payload: myParam};
};
export const vendorRatesFormInitializeFailed =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_INITIALIZE_FAILED, payload: myParam};
};
export const vendorRatesFormInitializeFinished =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_INITIALIZE_FINISHED, payload: myParam};
};
export const vendorRatesEditFormInitializeStarted =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_STARTED, payload: myParam};
};
export const vendorRatesEditFormInitializeFailed =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_FAILED, payload: myParam};
};
export const vendorRatesEditFormInitializeFinished =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_FINISHED, payload: myParam};
};
export const vendorRatesFormErrorsChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_ERRORS_CHANGED, payload: myParam};
};
export const vendorRatesFormSubmitStarted =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_SUBMIT_STARTED, payload: myParam};
};
export const vendorRatesFormSubmitFailed =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_SUBMIT_FAILED, payload: myParam};
};
export const vendorRatesFormSubmitFinished =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_SUBMIT_FINISHED, payload: myParam};
};
export const vendorRatesFormIdChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_ID_CHANGED, payload: myParam};
};
export const vendorRatesFormVendorChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_VENDOR_CHANGED, payload: myParam};
};
export const vendorRatesFormRateYearChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_RATE_YEAR_CHANGED, payload: myParam};
};
export const vendorRatesFormResourceTypeChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_RESOURCE_TYPE_CHANGED, payload: myParam};
};
export const vendorRatesFormAssignmentSiteChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_ASSIGNMENT_SITE_CHANGED, payload: myParam};
};
export const vendorRatesFormResourceLocationChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_RESOURCE_LOCATION_CHANGED, payload: myParam};
};
export const vendorRatesFormGradeChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_GRADE_CHANGED, payload: myParam};
};
export const vendorRatesFormUnitPriceChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_UNIT_PRICE_CHANGED, payload: myParam};
};
export const vendorRatesFormPerDiemRateChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_PER_DIEM_RATE_CHANGED, payload: myParam};
};
export const vendorRatesFormCurrencyChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_CURRENCY_CHANGED, payload: myParam};
};
export const vendorRatesFormCommentsChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_COMMENTS_CHANGED, payload: myParam};
};
export const vendorRatesFormAttachmentsChanged =  (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_ATTACHMENTS_CHANGED, payload: myParam};
};
export const resetVendorRatesForm = (myParam) => {
    return {type: actionTypes.RESET_VENDOR_RATES_FORM, payload: myParam};
}
export const vendorRatesFormExternalVendorsChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_EXTERNAL_VENDORS_CHANGED, payload: myParam};
}
export const vendorRatesFormInternalVendorsChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_INTERNAL_VENDORS_CHANGED, payload: myParam};
}
export const vendorRateFormCommentChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_FORM_COMMENT_CHANGED, payload: myParam};
}
export const vendorRateFormAttachmentAdded = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_FORM_ATTACHMENT_ADDED, payload: myParam};
}
export const vendorRateFormAttachmentDeleted = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_FORM_ATTACHMENT_REMOVED, payload: myParam};
}
export const vendorRatesFormInactiveChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_FORM_INACTIVE_CHANGED, payload: myParam};
}
export const vendorRateFormClientIdChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_FORM_CLIENT_ID_CHANGED, payload: myParam}
}
export const vendorRateListChangeLoadingSpinner = (myParam) => {
    return {type: actionTypes.VENDOR_RATE_LIST_CHANGE_LOADING_SPINNER, payload: myParam}
}
export const vendorRatesFormAlreadyInUseChanged = (myParam) => {
    return {type: actionTypes.VENDOR_RATES_FORM_ALREADY_IN_USE_CHANGED, payload: myParam}
}



