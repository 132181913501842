import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

const mapStateToProps = (state) => {
    return {
        loading: state.attachment.loading,
    }
}
const mapDispatchToProps = (dispatch) => ({
    downloadFileFromLink: (payload) => {
        dispatch(deliveryConfirmationFormActions.downloadDcFileFromLink(payload))
    }
})

class DownloadDcComponent extends Component {
    componentDidMount() {
        this.props.downloadFileFromLink(this.props.location.pathname.split("/files/")[1])
    }
    render() {
        return (
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <LoadingSpinner loading={this.props.loading}/>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DownloadDcComponent);