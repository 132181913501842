import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
  
    function handleClose(onClick) {
        setAnchorEl(null);
        if (typeof onClick === "function") {
            onClick(props.rowData);
        }
    }
  
    return (
      <div>
        <props.icon aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        </props.icon>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            {props.items.map((item) => {
                return (
                    <MenuItem onClick={handleClose.bind(this, item.onClick)} key={item.label}>
                        {item.label}
                    </MenuItem>
                )
            })}
        </Menu>
      </div>
    );
  }