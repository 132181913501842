export default (history) => {
    history = history.map((record) => {
        return JSON.parse(record);
    })
    let objectKeysToLowerCase = function (input) {
        if (!input) {
            return;
        }
        if (typeof input !== 'object') return input;
        if (Array.isArray(input)) return input.map(objectKeysToLowerCase);
        return Object.keys(input).reduce(function (newObj, key) {
            let val = input[key];
            let newVal = (typeof val === 'object') ? objectKeysToLowerCase(val) : val;
            newObj[key[0].toLowerCase() + key.substring(1)] = newVal;
            return newObj;
        }, {});
    };
   return objectKeysToLowerCase(history).reverse();
}