import React from 'react';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {withStyles} from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    cursor: 'default',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    '&:hover': {
      '& $icon': {
        color: theme.palette.text.secondary,
        opacity: 0.5,
      },
    },
    '&$active': {
      color: theme.palette.text.primary,
      // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
      '&& $icon': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Styles applied to the icon component. */
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
  },
  /* Styles applied to the icon component if `direction="desc"`. */
  iconDirectiondesc: {
    transform: 'rotate(0deg)',
  },
  /* Styles applied to the icon component if `direction="asc"`. */
  iconDirectionasc: {
    transform: 'rotate(180deg)',
  },
});

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
const TableSortLabel = React.forwardRef(function TableSortLabel(props, ref) {
  const {
    active = false,
    children,
    classes,
    className,
    direction = 'asc',
    hideSortIcon = false,
    IconComponent = ArrowDownwardIcon,
    onClick,
    sortable,
    ...other
  } = props;
  if (!sortable) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <ButtonBase
      className={clsx(classes.root, { [classes.active]: active }, className)}
      component="span"
      disableRipple
      ref={ref}
      {...other}
    >
      {children}
      {hideSortIcon && !active ? null : (
        <IconComponent
            onClick={onClick}
            className={clsx(classes.icon, classes[`iconDirection${direction}`])}
        />
      )}
    </ButtonBase>
  );
});

export default withStyles(styles, { name: 'MuiTableSortLabel' })(TableSortLabel);