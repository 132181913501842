import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
// import "@babel/polyfill";
// import "isomorphic-fetch";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import reducer from './store/reducer';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas/rootSaga'
import history from './history';

smoothscroll.polyfill();
const sagaMiddleware = createSagaMiddleware();
const unhandledExceptionMiddleware = store => next => action => {
    try {
      return next(action);
    } catch (err) {
      next({ type: 'OPEN_ERROR_DIALOG' , payload: "Unexpected error occuured. Please restart application and try again"})
    }
  }
export const store = createStore(reducer, applyMiddleware(sagaMiddleware, unhandledExceptionMiddleware));
sagaMiddleware.run(sagas);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
