import React from 'react';
import Input from '../../../../components/Input/Input'
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import Select from '../../../../components/Input/AutoSelect';
import Checkbox from '@material-ui/core/Checkbox';
import {regionTypeEnums} from '../../../../constants/regionTypes';

const DeliveryInformation = (props) => {
    return (
        <React.Fragment>
            <Input 
                label="Activity name"
                errorText={props.errors.activity} 
                width="100%"
                value={props.activityName || ''}
                onChange={props.activityFormActivityNameChanged}
            />
            <RadioButtons 
                header="Activity Type"
                horizontal={true}
                onChange={props.activityFormActivityTypeChanged}
                options={props.activityTypes}
                value={props.activityType}
                errorText={props.errors.activityType}
            />
            <Select 
                label="Region Type"
                width="100%"
                value={props.regionType || ""}
                suggestions={props.regionTypesSuggestions}
                errorText={props.errors.regionType}
                handleChangeSingle={props.activityFormRegionTypeChanged}
            />
            <Select 
                label="Platform"
                width="100%"
                value={props.domain || ""}
                suggestions={props.domainsSuggestions}
                errorText={props.errors.domain}
                handleChangeSingle={props.activityFormDomainChanged}
            />
            <Select 
                label="Sub-platform"
                width="100%"
                value={props.subDomain || ""}
                suggestions={props.subDomainsPerDomains && props.domain ? props.subDomainsPerDomains[props.domain.value] : []}
                errorText={props.errors.subDomain}
                handleChangeSingle={props.activityFormSubDomainChanged}
            />
            {props.regionType && props.regionType.value !== regionTypeEnums.Central ?
                <Select 
                    label="Cost Center"
                    width="100%"
                    value={props.costCenter || ""}
                    suggestions={props.costCenterSuggestions}
                    errorText={props.errors.costCenter}
                    handleChangeSingle={props.activityFormCostCenterChanged}
                /> : null
            }
            <Input 
                label="Source Data Link"
                errorText={props.errors.sourceDataLink} 
                value={props.sourceDataLink || ''}
                width="100%"
                onChange={props.activityFormSourceDataLinkChanged}
            />
            <Input 
                label="Legacy number"
                errorText={props.errors.legacyNumber} 
                width="100%"
                value={props.legacyNumber || ''}
                onChange={props.activityFormLegacyNumberChanged}
            />

            <Input 
                label="Demand number"
                value={props.demandNumber || ''}
                onChange={props.activityFormDemandNumberChanged}
                width="100%"
                errorText={props.errors.demandNumber} 
            />
            <Input 
                label="Project number"
                errorText={props.errors.projectNumber} 
                value={props.projectNumber || ''}
                width="100%"
                onChange={props.activityFormProjectNumberChanged}
            />
            <Checkbox color="primary" checked={props.isDeleted || false} onChange={props.inactiveChanged}/>Inactive 
            </React.Fragment>
    )
}
export default formSectionWrapper(DeliveryInformation, "Activity data");