import { put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as deliveryConfirmationActions from '../store/actions/deliveryConfirmationActions';
import * as userActions from '../store/actions/User';
import months from '../constants/monthsLong';
import axios from '../axios';
import history from '../history';
import resourceTypes from '../constants/resourceTypes';
import dcStatusesExternal from '../constants/dcStatusesExternal';
import dcStatusesInternal from '../constants/dcStatusesInternal';
import { getResourceTypesByVendor } from './dictionariesSagas';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import { getUserDomains, getDomains } from './domainsSagas';
import { canFunction } from '../config/Can';
import * as rules from '../config/Rules';
import formatDate from '../utils/dateFormatter';
import { isPerDiemVisible, isPlannedUnitPrice, isVendorTypeExternal } from '../utils/typesAssertions';

function* getDeliveryConfirmationColumnsConfig(action) {
    let columns = yield localStorage.getItem(action.payload.domain +"-"+action.payload.role +"-delivery-confirmation-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(deliveryConfirmationActions.getDeliveryConfirmationColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(deliveryConfirmationActions.getDeliveryConfirmationColumnsFailed())
    }
}

function* saveDeliveryConfirmationColumnsConfig(action) {
    yield localStorage.setItem(action.payload.domain +"-"+action.payload.role +"-delivery-confirmation-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getDeliveryConfirmationColumnsConfig(action)
    yield put(deliveryConfirmationActions.updateDeliveryConfirmationColumnsFinished());
}

function* getDeliveryConfirmations(action) {
    try {
        if (action.payload.initial && 
            (history.location.pathname.indexOf("viewMy") !== -1 || history.location.pathname.indexOf("submit") !== -1 || history.location.pathname.indexOf("approve") !== -1) &&
            !canFunction('', rules.IS_SUPPLIER)) {
            yield* getUserDomains();
        } else if (action.payload.initial && !canFunction('', rules.IS_SUPPLIER)) {
            yield* getDomains()
        }
        let domains;
        let urlStart = "";
        let filterUrl = "";
        if (history.location.pathname.indexOf("viewMy") !== -1) {
            urlStart = "MyDcs";
            filterUrl = "/deliveryconfirmation/viewMyDC";
            domains = yield select((store) => store.user.userDomains['viewMy'])
        }
        if (history.location.pathname.indexOf("submit") !== -1) {
            urlStart = "ToSubmit";
            filterUrl = "/deliveryconfirmation/submitDC";
            domains = yield select((store) => store.user.userDomains['submit'])
        }
        if (history.location.pathname.indexOf("approve") !== -1) {
            urlStart = "ToApprove";
            filterUrl = "/deliveryconfirmation/approveDC";
            domains = yield select((store) => store.user.userDomains['approve'])
        }
        if (history.location.pathname.indexOf("toBeProcessed") !== -1) {
            urlStart = "ToBeProcessed"
            filterUrl = "/deliveryconfirmation/toBeProcessedDC";
            domains = yield select((store) => store.domains.domains)
        }
        if (history.location.pathname.indexOf("viewAll") !== -1) {
            domains = yield select((store) => store.domains.domains);
            filterUrl = "/deliveryconfirmation/viewAllDC";
        }
        let isDomainPresent = false
        if (action.payload.domain && !canFunction('', rules.IS_SUPPLIER)) {
            isDomainPresent = domains.find((domain) => {
                return domain.id === action.payload.domain.id
            });
        }
        let selectedDomain = isDomainPresent || {name: 'view all', id: 'view all'};
        let params = action.payload.params;
        if (!isDomainPresent && !canFunction('', rules.IS_SUPPLIER)) {
            yield put(userActions.selectDomain(selectedDomain));
            params = yield select((store) => store.table.parsedFilters[`${filterUrl}${selectedDomain.id !== 'view all' ? "-" + selectedDomain.id : ""}`]);
            if (!params) {
                params = {};
            }
        }
        let url = `/dc/${urlStart}`;
        let {...other} = params
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(deliveryConfirmationActions.deliveryConfirmationListChangeLoadingSpinner(true));
        if (selectedDomain.name !=="view all") {
            other.domainId = selectedDomain.id;
        }
        const dcs = yield axios.get(`api${url}`, {params: encodeParamObject(other)});
        const dcsTableData = dcs.data.items.map((dc) => {
            const isUnitPriceNotPlannedAmount = isPlannedUnitPrice(dc.resourceType);
            const isPerDiem = isPerDiemVisible(dc.resourceType, dc.assignmentSite);
            return {
                id: dc.id,
                domain: dc.domain,
                vendor: dc.vendor,
                poNumber: dc.poNumber,
                activityName: dc.activityName,
                deliverable: dc.deliverable,
                resourceType: resourceTypes[dc.resourceType],
                grade: dc.grade,
                month: months[dc.month-1],
                year: dc.year,

                plannedAmount: !isUnitPriceNotPlannedAmount ? dc.planned : null,
                actualAmount: !isUnitPriceNotPlannedAmount ? dc.actualUnitNumber : null,
                plannedUnitPrice: isUnitPriceNotPlannedAmount ? dc.planned : null,
                plannedPerDiem: isPerDiem ? dc.planned : null,
                actualUnitPrice: isUnitPriceNotPlannedAmount ? dc.actualUnitNumber : null,
                actualPerDiemRate: dc.actualPerDiemRate,

                vendorType: dc.vendorType,
                statusEnum: dc.status,
                status: isVendorTypeExternal(dc.vendorType) ? dcStatusesExternal[dc.status] : dcStatusesInternal[dc.status],
                supplierRepresentative: dc.supplierRepresentative ? dc.supplierRepresentative.name : '',
                approver: dc.approver ? dc.approver.name : '',
                hasOvertime: dc.hasOvertime ? 'Yes' : 'No',
                isApprover: dc.isApprover,
                isSupplier: dc.isSupplier,
                changelogId: dc.changelogId,
                tags: dc.tags,
                lastModifiedDate: dc.lastModifiedDate ? formatDate(dc.lastModifiedDate) : ""
            }
        });
        dcs.data.items = dcsTableData;
        yield put(deliveryConfirmationActions.getDeliveryConfirmationsFinished(dcs.data));
        if (action.payload.initial) {
            yield* getResourceTypesByVendor();
        }
    } catch (error) {
        yield put(deliveryConfirmationActions.getDeliveryConfirmationsFailed(error));
    }
}

export default [
    takeLatest(actionTypes.GET_DELIVERY_CONFIRMATIONS_STARTED, getDeliveryConfirmations),
    takeEvery(actionTypes.GET_DELIVERY_CONFIRMATION_COLUMNS_STARTED, getDeliveryConfirmationColumnsConfig),
    takeEvery(actionTypes.UPDATE_DELIVERY_CONFIRMATION_COLUMNS_STARTED, saveDeliveryConfirmationColumnsConfig),
];