import * as actionTypes from './actionTypes';

export const getVendorsStarted =  (myParam) => {
    return {type: actionTypes.GET_VENDORS_STARTED, payload: myParam};
};
export const getVendorsFinished =  (myParam) => {
    return {type: actionTypes.GET_VENDORS_FINISHED, payload: myParam};
};
export const getVendorsFailed =  (myParam) => {
    return {type: actionTypes.GET_VENDORS_FAILED, payload: myParam};
};

export const addVendorStarted =  (myParam) => {
    return {type: actionTypes.ADD_VENDOR_STARTED, payload: myParam};
};
export const addVendorFinished =  (myParam) => {
    return {type: actionTypes.ADD_VENDOR_FINISHED, payload: myParam};
};
export const addVendorFailed =  (myParam) => {
    return {type: actionTypes.ADD_VENDOR_FAILED, payload: myParam};
};

export const editVendorStarted =  (myParam) => {
    return {type: actionTypes.EDIT_VENDOR_STARTED, payload: myParam};
};
export const editVendorFinished =  (myParam) => {
    return {type: actionTypes.EDIT_VENDOR_FINISHED, payload: myParam};
};
export const editVendorFailed =  (myParam) => {
    return {type: actionTypes.EDIT_VENDOR_FAILED, payload: myParam};
};

export const deleteVendorStarted = (myParam) => {
    return {type: actionTypes.DELETE_VENDOR_STARTED, payload: myParam};
};
export const deleteVendorFinished = (myParam) => {
    return {type: actionTypes.DELETE_VENDOR_FINISHED, payload: myParam};
};
export const deleteVendorFailed = (myParam) => {
    return {type: actionTypes.DELETE_VENDOR_FAILED, payload: myParam};
};

export const newVendorNameChanged =  (myParam) => {
    return {type: actionTypes.NEW_VENDOR_NAME_CHANGED, payload: myParam};
};
export const newVendorSapNumberChanged =  (myParam) => {
    return {type: actionTypes.NEW_VENDOR_SAP_NUMBER_CHANGED, payload: myParam};
};
export const editVendorName =  (myParam) => {
    return {type: actionTypes.EDIT_VENDOR_NAME, payload: myParam};
};
export const editVendorSapNumber = (myParam) => {
    return {type: actionTypes.EDIT_VENDOR_SAP_NUMBER, payload: myParam}
}