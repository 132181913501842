import React from 'react'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      maxWidth: 280,
      paddingTop: 30,
      backgroundColor: theme.palette.background.paper
    },
  }));
const preventContextMenu = function(e) {
    e.preventDefault();
    return false;
}
const preventScroll = function(e) {
    if (e.button === 1) {
        e.preventDefault();
        return false;
    }
}
export default ({items, handleClick, selectedItem}) => {
    const classes = useStyles();
    return (
        <List component="nav" className={classes.root} disablePadding={true}>
        {items.map(item => {
          return (
            <div key={item.name}>
              <ListItem
                className="drawer-landingPage"
                onClick={handleClick.bind('', item)}
                onContextMenu={preventContextMenu}
                onMouseDown={preventScroll}
                key={item.id}
                button
                selected={selectedItem.name === item.name}
              >
                <ListItemText className="drawer-landingPage" primary={item.name} disableTypography/>
              </ListItem>
            </div>
          ) 
        })}
      </List>
    )
}