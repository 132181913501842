import ResourceItem from './ResourceItem';
import React from 'react';
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';

const objPlaceholder = {}
export default formSectionWrapper((props) => {
    const {resources, errors, ...other} = props;
    return (
        <React.Fragment>
        {props.resources.map((resource, index) => {
            return (
                <ResourceItem 
                    errors={errors && errors.resources && errors.resources[index] ? errors.resources[index] : objPlaceholder}
                    key={index} 
                    index={index} 
                    {...other}
                    {...resource}
                />
            )
        })}
        {props.changes.resourcesMap && props.changes.resourcesMap.deleted ? props.changes.resourcesMap.deleted.map((resource, index) => {
            return (
                <ResourceItem 
                    deleted={true}
                    viewMode={true}
                    key={index} 
                    {...other}
                    {...resource}
            />
            )
        }) : null

        }
        </React.Fragment>
    )
}, "Resources");