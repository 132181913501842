import { put,takeLatest, select, delay, fork, race, call, take } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as resourceModelActions from '../store/actions/resourceModelActions';
import * as resourceModelFormActions from '../store/actions/resourceModelFormActions';
import axios from '../axios';
import history from '../history';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import {deleteFiles, attachFiles} from './formAttachmentSagas';
import historyDataFormatter from '../utils/historyDataMapper';
import {getChanges} from '../views/MainPage/ResourceModelEditForm/ResourceModelRevisionComparator';
import * as commentUtils from '../utils/commentUtils';

function formatActuals(data) {
    const {plans, poNumbers, actuals} = data;
    const dataCopy = {...data};
    dataCopy.po = {};
    poNumbers.forEach((poNumber) => {
        dataCopy.po[poNumber.month] = poNumber.number;
    });
    dataCopy.planned = {};
    plans.forEach((plan) => {
        dataCopy.planned[plan.month] = parseFloat(plan.value);
    });
    dataCopy.actualUnitPrice = {};
    dataCopy.actualPerDiem = {};
    dataCopy.overtime125 = {};
    dataCopy.overtime150 = {};
    dataCopy.overtime175 = {};
    dataCopy.overtime200 = {};
    dataCopy.dcLinks = {};
    actuals.forEach((actual) => {
        dataCopy.actualUnitPrice[actual.month] = parseFloat(actual.unitPrice);
        if (isNaN(dataCopy.actualUnitPrice[actual.month])) {
            delete dataCopy.actualUnitPrice[actual.month];
        }
        if (actual.dcId) {
            dataCopy.dcLinks[actual.month] = actual.dcId;
        }
        dataCopy.actualPerDiem[actual.month] = parseFloat(actual.perDiemRate);
        if (isNaN(dataCopy.actualPerDiem[actual.month])) {
            delete dataCopy.actualPerDiem[actual.month];
        }
        actual.overtimes.forEach((overtime) => {
            dataCopy['overtime'+ overtime.percentageOfTheOvertime][actual.month] = overtime.amountOvertimeHours
        });
    });
    return dataCopy;
}

function* loadResourceModelForm(action) {
    try {
        yield fork(getResourceModelHistorySaga, action.payload);
        const response = yield axios.get('api/resourceModel/' + action.payload);
        const {activity, resourceName, assignmentSite, canGenerateDC} = response.data;
        const data = formatActuals(response.data);
        data.activityName = activity;
        data.name = resourceName;
        data.generateDc = canGenerateDC;
        data.assignmentSite = assignmentSite;
        data.comments = data.comments.map((comment) => {
            return {
                ...comment,
                message: commentUtils.getEditorState(comment.message)
            }
        })
        data.comment = commentUtils.getEmptyComment();
        for (let i = 0; i < data.attachments.length; i++) {
            data.attachments[parseInt(i)].key = data.attachments[parseInt(i)].id;
        }
        yield put(resourceModelFormActions.getResourceModelFinished(data));
    } catch(error) {
        yield put(resourceModelFormActions.getResourceModelFailed());
        yield put(errorDialogActions.openErrorDialog('There was an error while loading resource model data. Please try again later'));
    }
}

function* submitResourceModelSaga() {
    try {
        const formData = yield select((state) => state.resourceModelForm.resourceModel);
        const {activityName, comments, name, po, generateDc, assignmentSite, planned, actualUnitPrice,actualPerDiem, overtime125, overtime150, overtime175, overtime200,...data} = formData;
        data.activity = activityName;
        data.resourceName = name;
        data.canGenerateDC = generateDc;
        data.assignmentSite = assignmentSite;
        data.poNumbers = [];
        data.comment = commentUtils.getPureText(data.comment).length !== 0 ? commentUtils.prepareEditorStateForSendingToDatabase(data.comment) : ""
        Object.keys(po).forEach((key) => {
            if (!po[key]) {
                return;
            }
            data.poNumbers.push({month: key, number: po[key]})
        })
        data.plans = [];
        Object.keys(planned).forEach((key) => {
            data.plans.push({month: key, value: planned[key]})
        })
        data.actuals = [];
        for (let i = 1; i <= 12; i++) {
            const oldActual = formData.actuals.find((actual) => {
                return actual.month === i;
            });
            const monthObject = {overtimes:[]}
            if (oldActual) {
                monthObject.id = oldActual.id;
                monthObject.resourceModelId = oldActual.resourceModelId;
            }
            if (actualUnitPrice && actualUnitPrice[parseInt(i)]) {
                monthObject.unitPrice = actualUnitPrice[parseInt(i)];
                monthObject.month = i;
            }
            if (actualPerDiem && actualPerDiem[parseInt(i)]) {
                monthObject.perDiemRate = actualPerDiem[parseInt(i)];
                monthObject.month = i;
            }
            if (overtime125 && overtime125[parseInt(i)]) {
                let old125;
                if (oldActual && oldActual.overtimes) {
                    old125 = oldActual.overtimes.find((overtime) => {
                        return overtime.percentageOfTheOvertime === 125
                    })
                }
                monthObject.overtimes.push({
                    amountOvertimeHours: overtime125[parseInt(i)],
                    percentageOfTheOvertime: 125,
                    id: old125 ? old125.id : null,
                    resourceModelActualId: old125 ? old125.resourceModelActualId : null
                })
                monthObject.month = i;
            }
            if (overtime150 && overtime150[parseInt(i)]) {
                let old150;
                if (oldActual && oldActual.overtimes) {
                    old150 = oldActual.overtimes.find((overtime) => {
                        return overtime.percentageOfTheOvertime === 150
                    })
                }
                monthObject.overtimes.push({
                    amountOvertimeHours: overtime150[parseInt(i)],
                    percentageOfTheOvertime: 150,
                    id: old150 ? old150.id : null,
                    resourceModelActualId: old150 ? old150.resourceModelActualId : null
                })
                monthObject.month = i;
            }
            if (overtime175 && overtime175[parseInt(i)]) {
                let old175;
                if (oldActual && oldActual.overtimes) {
                    old175 = oldActual.overtimes.find((overtime) => {
                        return overtime.percentageOfTheOvertime === 175
                    })
                }
                monthObject.overtimes.push({
                    amountOvertimeHours: overtime175[parseInt(i)],
                    percentageOfTheOvertime: 175,
                    id: old175 ? old175.id : null,
                    resourceModelActualId: old175 ? old175.resourceModelActualId : null
                })
                monthObject.month = i;
            }
            if (overtime200 && overtime200[parseInt(i)]) {
                let old200;
                if (oldActual && oldActual.overtimes) {
                    old200 = oldActual.overtimes.find((overtime) => {
                        return overtime.percentageOfTheOvertime === 200
                    })
                }
                monthObject.overtimes.push({
                    amountOvertimeHours: overtime200[parseInt(i)],
                    percentageOfTheOvertime: 200,
                    id: old200 ? old200.id : null,
                    resourceModelActualId: old200 ? old200.resourceModelActualId : null
                })
                monthObject.month = i;
            }
            if (monthObject.month) {
                data.actuals.push(monthObject);
            }
        }
        const val = yield* attachFiles(data.attachments);
        data.attachments = val;
        const resourceModel = yield axios.get('api/resourceModel/'+ data.id);
        yield axios.post('api/resourceModel', data);
        yield* deleteFiles(data.attachments, resourceModel.data.attachments)
        yield put(resourceModelActions.submitResourceModalFinished());
        history.push('/resourcemodel');
    } catch (error) {
        yield put(resourceModelActions.submitResourceModalFailed());
        yield put(errorDialogActions.openErrorDialog('There was an error while submitting resource model data. Please try again later'));
    }
}

function* getResourceModelHistorySaga(id) {
    try {
        let initial = true;
        const fn = function*() {
            const history = yield axios.get(`api/resourceModel/history/${id}`);
            history.data = historyDataFormatter(history.data).map((res, index) => {
                res.plans = [];
                res.poNumbers = Object.keys(res.poNumbers).map((month, index) => {
                    return {month: index+1, number: res.poNumbers[month]};
                });
                res.actuals = res.actuals.map((actual) => {
                    return {
                        ...actual,
                        overtimes: actual.resourceModelOvertimes,
                        unitPrice: actual.numberOfUnits,
                        perDiemRate: actual.numberOfPerDiems
                    }
                });
                const resFormatted = formatActuals(res);
                resFormatted.planned = {};
                Object.keys(res.plannedValues).forEach((month, index) => {
                    resFormatted.planned[index + 1] =  res.plannedValues[month];
                });
                return {
                    ...resFormatted,
                    comment: res.comment ? {...res.comment, message: commentUtils.getEditorState(res.comment.message)} : null,
                    approver: {name: res.approver},
                    supplierRepresentative: res.supplier ? {name: res.supplier}: null,
                    supplier: res.vendorName,
                    powbs: res.poWBS,
                    domain: res.domainName,
                    subDomain: res.subDomainName,
                    poLocation: res.poLocationName,
                    costType: res.costTypeName,
                    modifiedBy: res.lastModifiedBy || "Default",
                    grade: res.gradeName,
                    expenseType: res.expenseTypeName,
                    location: res.locationName,
                    perDiem: res.perDiemRate,
                    name: res.resourceName,
                    currency: res.currencyName,
                    modifiedDate: res.lastModifiedDate || Math.random().toString(),
                    status: `Revision nr ${history.data.length - index}`,
                    generateDc: res.canGenerateDC
                }
            });
            const oldHistory = yield select((store) => store.resourceModelForm.history);
            const isSubmitting = yield select((store) => store.resourceModelForm.submitting);
            if (initial) {
                if (history.data.length >= 2) {
                    let changes;
                    try {
                        changes =  getChanges(history.data[0], history.data[1]);
                    } catch(error) {
                        changes = null;
                    }
                    yield put(resourceModelFormActions.resourceModelFormLatestChangesChanged(changes));
                }
                initial = false;
            }
            if (oldHistory && oldHistory.length !==0 && oldHistory.length < history.data.length && !isSubmitting) {
                yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: true, key:"newRm", message: "Resource model was updated. In order to get current version please refresh the page."}});
            }
            yield put(resourceModelFormActions.getResourceModelHistoryFinished(history.data));
        }
        while(true) {
            yield fn();
            yield delay(10000)
        }
    } catch(e) {
        yield put(resourceModelFormActions.getResourceModelHistoryFailed());
    }
}

function* loadRmWrapperSaga(action) {
    yield race({
        task: call(loadResourceModelForm, action),
        cancel: take(actionTypes.RESET_RESOURCE_MODEL_FORM)
    })
}

export default [
    takeLatest(actionTypes.GET_RESOURCE_MODEL_STARTED, loadRmWrapperSaga),
    takeLatest(actionTypes.RESOURCE_MODEL_FORM_SUBMIT_STARTED, submitResourceModelSaga),
];