import * as actionTypes from './actionTypes';

export const getResourceModelColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_COLUMNS_STARTED, payload: myParam};
};
export const getResourceModelColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_COLUMNS_FAILED, payload: myParam};
};
export const getResourceModelColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_COLUMNS_FINISHED, payload: myParam};
};
export const updateResourceModelColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_STARTED, payload: myParam};
};
export const updateResourceModelColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_FAILED, payload: myParam};
};
export const updateResourceModelColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_RESOURCE_MODEL_COLUMNS_FINISHED, payload: myParam};
};
export const selectedResourceModelRowsChanged = (myParam) => {
    return {type: actionTypes.SELECTED_RESOURCE_MODEL_ROWS_CHANGED, payload: myParam};
}
export const resourceModalSelectedMonthsChanged =  (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_SELECTED_MONTHS_CHANGED, payload: myParam};
};
export const resourceModalSelectedAllChanged =  (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_SELECTED_ALL_CHANGED, payload: myParam};
};
export const resourceModalGenerateDcModalOpened =  (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_OPENED, payload: myParam};
};
export const resourceModalGenerateDcModalClosed =  (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_GENERATE_DC_MODAL_CLOSED, payload: myParam};
};
export const getResourceModalGenerateDcModalDataStarted =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_STARTED, payload: myParam};
};
export const getResourceModalGenerateDcModalDataFailed =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FAILED, payload: myParam};
};
export const getResourceModalGenerateDcModalDataFinished =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODAL_GENERATE_DC_MODAL_DATA_FINISHED, payload: myParam};
};

export const resourceModalVerifiedFutureMonthsChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_VERIFIED_FUTURE_MONTHS_CHANGED, payload: myParam};
}
export const resourceModalShouldConfirmFutureMonthsChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_SHOULD_CONFIRM_FUTURE_MONTHS_CHANGED, payload: myParam};
}
export const submitResourceModalStarted = (myParam) => {
    return {type: actionTypes.SUBMIT_RESOURCE_MODAL_STARTED, payload: myParam};
}
export const submitResourceModalFinished = (myParam) => {
    return {type: actionTypes.SUBMIT_RESOURCE_MODAL_FINISHED, payload: myParam};
}
export const submitResourceModalFailed = (myParam) => {
    return {type: actionTypes.SUBMIT_RESOURCE_MODAL_FAILED, payload: myParam};
}
export const futureMonthsToBeValidatedChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_FUTURE_MONTHS_TO_BE_VALIDATED_CHANGED, payload: myParam};
}

export const getResourceModelsStarted = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODELS_STARTED, payload: myParam}
}
export const getResourceModelsFailed = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODELS_FAILED, payload: myParam}
}
export const getResourceModelsFinished = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODELS_FINISHED, payload: myParam}
}
export const resetResourceModelView = (myParam) => {
    return {type: actionTypes.RESET_RESOURCE_MODEL_VIEW, payload: myParam}
}
export const resourceModalSelectedYearChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODAL_SELECTED_YEAR_CHANGED, payload: myParam};
}

export const selectAllResourceModelsChanged = (myParam) => {
    return {type: actionTypes.SELECT_ALL_RESOURCE_MODELS_CHANGED, payload: myParam};
}
export const resourceModelGeneratingDcsChanged = (myParam) => {
    return {type:actionTypes.RESOURCE_MODEL_GENERATING_DCS_CHANGED, payload: myParam};
}
export const resourceModelDcGenerationProgressChanged = (myParam) => {
    return {type:actionTypes.RESOURCE_MODEL_DC_GENERATION_PROGRESS_CHANGED, payload: myParam};
}

export const resourceModelListChangeLoadingSpinner = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_LIST_CHANGE_LOADING_SPINNER, payload: myParam};
}

