import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import * as wbsActions from '../../../../store/actions/wbs';
import history from '../../../../history';

const mapStateToProps = (state) => {
    return {
        submitting: state.wbsForm.submitting,
        isEditing: state.wbsForm.id
    }
};

const mapDispatchToProps = (dispatch) => ({
    submitFormStarted: () => 
        dispatch(wbsActions.submitWbsFormStarted()),
    resetForm: () => 
        dispatch(wbsActions.resetWbsForm())
    }
);

class FormSubmitPanelContainer extends Component {
    cancelForm = () => {
        this.props.resetForm();
        history.push('/masterdata/viewallwbses');
    }
    submitForm = () => {
        this.props.submitFormStarted();
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 100}}>
                <Fab variant="extended" className="form-submit-panel form-cancel" onClick={this.cancelForm}>Cancel</Fab>
                <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-submit" onClick={this.submitForm}>Submit</Fab>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitPanelContainer);