import { put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as sapActuals from '../store/actions/sapActuals';
import axios from '../axios';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import formatDate from '../utils/dateFormatter';
import months from '../constants/monthsLong';

function* getImportMasterDataColumnsConfig(action) {
    let columns = yield localStorage.getItem("sapActuals-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(sapActuals.getSapActualsColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(sapActuals.getSapActualsColumnsFailed())
    }
}

function* saveColumnsConfig(action) {
    yield localStorage.setItem("sapActuals-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getImportMasterDataColumnsConfig(action);
    yield put(sapActuals.updateSapActualsColumnsFinished());
}

function* getFiles(action) {
    try {
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(sapActuals.sapActaulsLoadingSpinnerChanged(true));
        let data = yield axios.get('api/sapActuals', {params: encodeParamObject(action.payload.params)});
        const newData = data.data.items.map((item) => {
            return {
                ...item,
                month: months[item.month -1]
            }
        })
        data.data.items = newData;
        yield put(sapActuals.getSapActualsFinished(data.data));
    } catch (error) {
        yield put(sapActuals.getSapActualsFailed(error));
    }
}

export default [
    takeEvery(actionTypes.GET_SAP_ACTUALS_COLUMNS_STARTED, getImportMasterDataColumnsConfig),
    takeEvery(actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_STARTED, saveColumnsConfig),
    takeLatest(actionTypes.GET_SAP_ACTUALS_STARTED, getFiles),
];