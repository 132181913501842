import React, { Component } from 'react';
import * as rules from '../../../../config/Rules';
import Can, { canFunction } from '../../../../config/Can';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as backofficeChangelogCommentsActionsCreators from '../../../../store/actions/backofficeChangelogCommentsActions';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import Dialog from '../../../../components/Dialog/Dialog';
import ChangelogRevisionComparator from './ChangelogRevisionComparator';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import CardList from '../../../../components/CardList/CardList';
import formatDate from '../../../../utils/dateFormatter';
import Checkbox from '@material-ui/core/Checkbox';
import {getChangelogsWithGivenActivityPerYear} from '../../../../store/reducers/ChangeLogFormReducer';
import Collapse from '@material-ui/core/Collapse';
import history from '../../../../history';

const styles = theme => ({
    wrapper: {
        paddingTop: 20,
        width: 360,
        minWidth: 360, 
        height: '100%', 
        backgroundColor: 'rgba(200, 202, 206, 0.15)',
        overflow: 'auto',
    },
    commentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    commentSectionHeader: {
        fontSize: 14,
        color: 'rgba(89, 89, 89, 0.75)',
        // textAlign: 'left',
        // width: 312
    },
    toolbar: theme.mixins.toolbar,
    card: {
        marginTop: 24,
        width: 312,
        position: 'relative'
      },
    textArea: {
        border: '0 !important',
        width: 280,
        fontFamily: 'Lato, sans-serif',
        fontSize: 14,
        outline: 'none',
        resize: 'none'
    },
    media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    },
    expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    historyCommentContent: {
        display: 'flex'
    },
    historyCommentText: {
        marginLeft: 10
    },
    carret: {
        cursor: 'pointer',
        userSelect: 'none',
        '&::before': {
            content: '"\\25B6"',
            color: 'black',
            display: 'inline-block',
            marginRight: 6
        },
        '&[data-down]::before': {
            transform: 'rotate(90deg)'
        }
    }
})

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        changelogTabSelected: state.navbar.changelogTabSelected,
        selectedRole: state.user.selectedRole,
        comment: state.backofficeChangelogCommments.comment,
        submitting: state.backofficeChangelogCommments.submitting,
        comments: state.backofficeChangelogCommments.comments || [],
        history: state.changelogForm.history || [],
        showChanges: state.changelogForm.showChanges,
        changes: state.changelogForm.changes,
        changelogsWithGivenActivity: getChangelogsWithGivenActivityPerYear(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    postComment: () =>
        dispatch(backofficeChangelogCommentsActionsCreators.postBackofficeChangelogCommentStarted()),
    commentChanged: (e) =>
        dispatch(backofficeChangelogCommentsActionsCreators.backofficeChangelogCommentChanged(e)),
    viewRevision: (e) =>
        dispatch(changelogFormActionsCreators.viewChangelogRevisionStarted(e)),
    showChangesChanged: (e) =>
        dispatch(changelogFormActionsCreators.changelogFormShowChangesChanged(e.target.checked))
    });


class ChangelogEditMenuContainer extends Component {
    state = {
        modalOpen: false,
        index: 0,
        length: 0,
        nestedVisibleItems: {}
    }
    static getDerivedStateFromProps(props, current_state) {
        if (props.history.length !== current_state.length) {
            return {
                length: props.history.length,
                index: current_state.index + (props.history.length - current_state.length)
            }
        }
        return null;
    }
    changeActivitiesVisibility = (year) => {
        this.setState({
            nestedVisibleItems: {
                ...this.state.nestedVisibleItems,
                [year]: !this.state.nestedVisibleItems[year]
            }
        })
    }
    viewRevision = (index) => {
        this.setState({
            modalOpen: true,
            index: index,
            length: this.props.history.length
        });
    }
    hideModal = () => {
        this.setState({
            modalOpen: false
        })
    }
    navigateToChangelog = (id, e) => {
        if (e && e.button === 2) {
            return;
        }
        if (!canFunction('', rules.NAVIGATE_CHANGELOG_VIEWALL)) {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin+`/changelogs/my/${id}`, "_blank"); 
            } else {
                history.push(`/changelogs/my/${id}`);
            }
        } else {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin+`/changelogs/viewAll/${id}`, "_blank"); 
            } else {
                history.push(`/changelogs/viewAll/${id}`);
            }
        }
    }
    preventScroll = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            return false; 
          }
    }

    render() {
        return <Can 
            role={this.props.selectedRole}
            perform={rules.VIEW_EDIT_CHANGELOG_MENU}
            yes={() => {
                return (
                    <div className={this.props.classes.wrapper}>
                        { this.state.modalOpen ? 
                        <Dialog fullWidth={true} maxWidth={"md"} open={this.state.modalOpen} onClose={this.hideModal} title="View revision" cancelButtonText="close">
                            <div>
                                <ChangelogRevisionComparator 
                                history={this.props.history} 
                                index={this.state.index}/>
                            </div>
                        </Dialog> : null
                        }
                        <div className={this.props.classes.toolbar}></div>
                            {this.props.changelogTabSelected === "comment" ?
                            <div className={this.props.classes.commentWrapper}>
                                <h3 className={this.props.classes.commentSectionHeader}>Visible for Back Office Team only</h3>
                                <Card className={this.props.classes.card}>
                                        <CardHeader
                                            avatar={
                                            <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                                                {this.props.username.substring(0, 1).toUpperCase()}
                                            </Avatar>
                                            }
                                        />
                                        <CardContent className="changelog-content">
                                        <LoadingSpinner loading={this.props.submitting}/>
                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                                        <TextareaAutosize 
                                        rows={5}
                                        disabled={this.props.submitting}
                                        value={this.props.comment}
                                        onChange={this.props.commentChanged}
                                        className={this.props.classes.textArea} 
                                        placeholder="Add comment..."/>
                                            <Button color="primary" 
                                                style={{marginLeft: 'auto', width: 60}} 
                                                onClick={this.props.postComment} 
                                                disabled={this.props.submitting}
                                                className="removed-text-padding">
                                                POST
                                            </Button>
                                         </div> 
                                        </CardContent>
                                    </Card>
                                {this.props.comments.map((comment) => {
                                    return (
                                    <Card key={comment.date} className={this.props.classes.card}>
                                        <CardHeader
                                            avatar={
                                            <Avatar aria-label="recipe" className={this.props.classes.avatar}>
                                                {comment.userName.substring(0, 1).toUpperCase()}
                                            </Avatar>
                                            }
                                            title={comment.userName}
                                            subheader={formatDate(comment.date)}
                                        />
                                        <CardContent className="changelog-content">
                                            <span>
                                                {comment.message}
                                            </span>
                                        </CardContent>
                                    </Card>
                                    )
                                })}
                            </div>
                             : this.props.changelogTabSelected === "history" ?
                             <div>
                            {canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && this.props.changes ? <div><Checkbox color="primary" value={this.props.showChanges} onChange={this.props.showChangesChanged}/> Show latest changes on form</div> : null}
                             <CardList 
                                history={this.props.history}
                                onViewRevisionClicked={this.viewRevision.bind(this)}
                             />
                             </div>
                             :
                             <div className={this.props.classes.commentWrapper} style={{width: 340}}>
                                <h3 className={this.props.classes.commentSectionHeader}>Changelogs with the same activity</h3>
                                {
                                    <ul style={{paddingBottom: 30}}>
                                    {
                                    Object.keys(this.props.changelogsWithGivenActivity).sort((a,b) => {
                                        if (b > a) return 1;
                                        if (b < a) return -1;
                                        return 0
                                    }).map((year) => {
                                        const dataDown = this.state.nestedVisibleItems[year] ? {'data-down': 'true'} : {};
                                        return (
                                            <li key={year} style={{marginTop: 24}}>
                                                <span 
                                                    {...dataDown}
                                                    className={this.props.classes.carret}  
                                                    onClick={this.changeActivitiesVisibility.bind(this, year)}
                                                >
                                                    {year}
                                                </span>
                                                <Collapse in={this.state.nestedVisibleItems[year]}>
                                                    <ul style={{paddingLeft: 12}}>
                                                        {this.props.changelogsWithGivenActivity[year].map((changelog) => {
                                                            return (
                                                                <li key={changelog.id}>
                                                                    <Card  className={this.props.classes.card}>
                                                                        <CardContent>
                                                                            <div style={{paddingTop: 8, display: 'flex', flexDirection:'column'}}>
                                                                            <div style={{marginBottom: 8, display: 'flex'}}>
                                                                                <span style={{marginRight: 8, color: '#a9a9a9', flexBasis: 60, flexShrink:0, display:'inline-block'}}>id:</span><span>{changelog.id}</span>
                                                                            </div>
                                                                            <div style={{marginBottom: 8, display: 'flex'}}>
                                                                                <span style={{ marginRight: 8, color: '#a9a9a9', flexBasis: 60, flexShrink:0, display:'inline-block'}}>Status:</span><span>{changelog.status}</span>
                                                                            </div>
                                                                            <div style={{marginBottom: 8, display: 'flex'}}>
                                                                                <span style={{marginRight: 8, color: '#a9a9a9', flexBasis: 60, flexShrink:0 , display:'inline-block'}}>Supplier:</span><span>{changelog.vendor}</span>
                                                                            </div>
                                                                            <Button color="primary" 
                                                                                style={{marginLeft: 'auto', width: 60}} 
                                                                                onMouseDown={this.preventScroll}
                                                                                onMouseUp={this.navigateToChangelog.bind(this,changelog.id)} 
                                                                                className="removed-text-padding">
                                                                                VIEW
                                                                            </Button>
                                                                            </div>
                                                                        </CardContent>
                                                                    </Card>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Collapse>
                                            </li>
                                        )
                                    })
                                    }
                                    </ul>
                                }
                             </div>
                             }
                    </div>
                )
            }}
        />    
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ChangelogEditMenuContainer)