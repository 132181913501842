import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const width = 340;

const useStyles = makeStyles(theme => (
    {
        paper: {
            width: 360,
            backgroundColor: theme.palette.primary.main,
            margin: 20,
            padding: 20,
            borderRadius: 16,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        header: {
            display: 'inline-block',
            lineHeight: '24px',
            height: 24,
            fontSize: '20px',
            color: '#fff'
        },
        icon: {
            width: 80,
            height: 80,
            marginBottom: 36,
            color: '#fff',
            paddingTop: 20
          },
        description: {
            color: '#fff',
            textAlign: 'justify',
            textJustify: 'inter-word',
            width: '100%',
            padding: 15
        },
        list: {
            color: '#fff',
            paddingTop: 0,
            paddingBottom: 0,
            width: 'auto'
        },
        arrow: {
            color: '#fff',
            paddingRight: 0,
            minWidth: 40
        }
    }
))
export default function CustomizedMenus(props) {
    const [elevation, setElevation] = React.useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const onMouseOver = () => setElevation(4);
    const onMouseOut = () => setElevation(0);
  return (
        <Paper
            classes={{root: classes.paper}}
            elevation={matches ? 4 : elevation}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        > 
        <div className={classes.content}>
            <props.icon className={classes.icon}/>
            <span className={classes.header}>{props.name ? props.name.toUpperCase() : ""}</span>
            {/* <div style={{visibility: elevation || matches ? "visible" : "hidden", transition: '0.2s'}}> */}
                <div className={classes.description} style={{visibility: elevation || matches ? "visible" : "hidden", transition: '0.2s'}}>
                    {props.description}
                </div>

                    <List component="ul" classes={{root: classes.list}} disablePadding style={{visibility: elevation || matches ? "visible" : "hidden", transition: '0.2s'}}>
                        {props.subItems.map((option) => {
                            return (
                            <ListItem classes={{root: classes.listItem}}button key={option.action} onClick={props.onItemClick.bind(this, option.url)}>
                                <ListItemIcon className={classes.arrow}>
                                    <ArrowForwardIcon />
                                </ListItemIcon>
                                <ListItemText primary={option.label} />
                            </ListItem>
                            )
                        })}
                    </List>
            {/* </div> */}
        </div>
        </Paper>
  );
}
