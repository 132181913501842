import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect'

const initialState = {
    loading: false,
    data: [],
    selectedItems: {},
    itemsBeingEditedMap: {},
    glAccountsBeingEditedMap: {},
    newItemExpensTypeText: '',
}

const dataExportReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EXPENSE_TYPE_ITEM_GL_ACCOUNT_CHANGED: {
            const glAccountsBeingEditedMap = {...state.glAccountsBeingEditedMap};
            glAccountsBeingEditedMap[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                glAccountsBeingEditedMap: glAccountsBeingEditedMap
            }
        }
        case actionTypes.ADD_EXPENSE_TYPE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_EXPENSE_TYPE_FAILED: {
            return {
                ...state,
                loading: false,
                newItemDomain: {},
                newItemExpensTypeText: ""
            }
        }
        case actionTypes.ADD_EXPENSE_TYPE_FINISHED: {
            return {
                ...state,
                loading: false,
                newItemDomain: {},
                newItemExpensTypeText: ""
            }
        }
        case actionTypes.EDIT_EXPENSE_TYPE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_EXPENSE_TYPE_FAILED: {
            return {
                ...state,
                loading: false,
                itemsBeingEditedMap: {},
                glAccountsBeingEditedMap: {}
            }
        }
        case actionTypes.EDIT_EXPENSE_TYPE_FINISHED: {
            return {
                ...state,
                loading: false,
                itemsBeingEditedMap: {},
                glAccountsBeingEditedMap: {}
            }
        }
        case actionTypes.EXPENSE_TYPE_PICKLIST_NEW_ITEM_EXPENSE_TYPE_CHANGED: {
            return {
                ...state,
                newItemExpensTypeText: action.payload.target.value
            }
        }
        case actionTypes.EXPENSE_TYPE_PICKLIST_EDIT_ITEM_TEXT_CHANGED: {
            const itemsBeingEditedCopy = {...state.itemsBeingEditedMap};
            itemsBeingEditedCopy[action.payload.target.name] = action.payload.target.value;
            return {
                ...state,
                itemsBeingEditedMap: itemsBeingEditedCopy
            }
        }

        case actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED:
        case actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_STARTED:
        case actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED:
        case actionTypes.ADD_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED:   
        case actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FINISHED:
        case actionTypes.DELETE_EXPENSE_TYPE_COST_TYPE_CONNECTION_FAILED:
        case actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.GET_ALL_EXPENSE_TYPE_COST_TYPE_MAPPING_FINISHED: {
            const selectedItems = {};
            action.payload.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            })
            return {
                ...state,
                loading: false,
                data: action.payload,
                selectedItems: selectedItems
            }
        }
        default: {
            return state
        }
    }
}
const getFullExpenseTypes = (state) => state.expenseTypeCostType.data;
export const getExpenseTypesPerCostTypes = createSelector([getFullExpenseTypes], (expenseTypes) => {
    const expenseTypesPerCostType = {};
    if (expenseTypes) {
        expenseTypes.forEach((expenseType) => {
            const expenseTypeSuggestion = {
                label: expenseType.name,
                value: expenseType.id
            }
            expenseType.costTypes.forEach((costType) => {
                if (expenseTypesPerCostType[costType.id]) {
                    expenseTypesPerCostType[costType.id][expenseType.id] = expenseTypeSuggestion;
                } else {
                    expenseTypesPerCostType[costType.id] = {[expenseType.id]: expenseTypeSuggestion}
                }
            });
            Object.keys(expenseTypesPerCostType).forEach((costTypeId) => {
                expenseTypesPerCostType[costTypeId] = Object.keys(expenseTypesPerCostType[costTypeId])
                    .map((expenseType) => {
                        return expenseTypesPerCostType[costTypeId][expenseType]
                    })
                    .sort((expenseType1, expenseType2) => expenseType1.name > expenseType2.name)
            })
        })
    }
    return expenseTypesPerCostType
})
export const getExpenseTypesPerCostTypesWithFilteredDeleted = createSelector([getFullExpenseTypes], (expenseTypes) => {
    const expenseTypesPerCostType = {};
    if (expenseTypes) {
        expenseTypes.forEach((expenseType) => {
            if (expenseType.isDeleted) {
                return;
            }
            const expenseTypeSuggestion = {
                label: expenseType.name,
                value: expenseType.id
            }
            expenseType.costTypes.forEach((costType) => {
                if (expenseTypesPerCostType[costType.id]) {
                    expenseTypesPerCostType[costType.id][expenseType.id] = expenseTypeSuggestion;
                } else {
                    expenseTypesPerCostType[costType.id] = {[expenseType.id]: expenseTypeSuggestion}
                }
            });
            Object.keys(expenseTypesPerCostType).forEach((costTypeId) => {
                expenseTypesPerCostType[costTypeId] = Object.keys(expenseTypesPerCostType[costTypeId])
                    .map((expenseType) => {
                        return expenseTypesPerCostType[costTypeId][expenseType]
                    })
                    .sort((expenseType1, expenseType2) => expenseType1.name > expenseType2.name)
            })
        })
    }
    return expenseTypesPerCostType
})

export default dataExportReducer;