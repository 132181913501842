import * as actionTypes from './actionTypes';


export const getResourceTypeByDomainStarted =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_DOMAIN_STARTED, payload: myParam};
};
export const getResourceTypeByDomainFailed =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_DOMAIN_FAILED, payload: myParam};
};
export const getResourceTypeByDomainFinished =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_DOMAIN_FINISHED, payload: myParam};
};
export const getResourceTypeByVendorTypeStarted = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_STARTED, payload: myParam}
}
export const getResourceTypeByVendorTypeFailed = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_FAILED, payload: myParam}
}
export const getResourceTypeByVendorTypeFinished = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_FINISHED, payload: myParam}
}
export const editResourceTypeByVendorStarted = (myParam) => {
    return {type: actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_STARTED, payload: myParam};
}
export const editResourceTypeByVendorFailed = (myParam) => {
    return {type: actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_FAILED, payload: myParam};
}
export const editResourceTypeByVendorFinished = (myParam) => {
    return {type: actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_FINISHED, payload: myParam};
}