import * as actionTypes from './actionTypes';

export const getResourceModelStarted =  (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_STARTED, payload: myParam};
};
export const getResourceModelFailed = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_FAILED, payload: myParam};
}
export const getResourceModelFinished = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_FINISHED, payload: myParam};
};
export const resourceModelFormPoChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_PO_CHANGED, payload: myParam};
};
export const resourceModelFormActualsChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_ACTUALS_CHANGED, payload: myParam};
};
export const resourceModelFormAttachmentAdded = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_ATTACHMENT_ADDED, payload: myParam};
};
export const resourceModelFormAttachmentDeleted = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_ATTACHMENT_DELETED, payload: myParam};
};
export const resourceModelFormCommentChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_COMMENT_CHANGED, payload: myParam};
};
export const resourceModelFormGenerateDcChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_GENERATE_DC_CHANGED, payload: myParam};
};
export const resetResourceModelForm = (myParam) => {
    return {type: actionTypes.RESET_RESOURCE_MODEL_FORM, payload: myParam};
};
export const resourceModelFormSubmitStarted = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_SUBMIT_STARTED, payload: myParam};
};
export const resourceModelFormSubmitFailed = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_SUBMIT_FAILED, payload: myParam};
};
export const resourceModelFormSubmitFinished = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_SUBMIT_FINISHED, payload: myParam};
};

export const getResourceModelHistoryStarted = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_HISTORY_STARTED, payload: myParam};
};
export const getResourceModelHistoryFinished = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_HISTORY_FINISHED, payload: myParam};
};
export const getResourceModelHistoryFailed = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_MODEL_HISTORY_FAILED, payload: myParam};
};
export const resourceModelFormLatestChangesChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_LATEST_CHANGES_CHANGED, payload: myParam};
};
export const resourceModelFormShowChangesChanged = (myParam) => {
    return {type: actionTypes.RESOURCE_MODEL_FORM_SHOW_CHANGES_CHANGED, payload: myParam};
};