import React, { Component } from 'react';
import FormSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as vendorRateActions from '../../../../store/actions/vendorRates';
import AttachmentComponent from '../../ChangelogForm/components/AttachmentComponent';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';

const mapStateToProps = (state) => {
    return {
        attachments: state.vendorRateForm && state.vendorRateForm.attachments ? state.vendorRateForm.attachments : [],
    }
};
const mapDispatchToProps = (dispatch) => ({
    attachmentAdded: (payload) =>
        dispatch(vendorRateActions.vendorRateFormAttachmentAdded(payload)),
    attachmentRemoved: (payload) =>
        dispatch(vendorRateActions.vendorRateFormAttachmentDeleted(payload)),
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))                      
})
class SupportingDocumentationContainer extends Component {
    render () {
        return (
            <AttachmentComponent 
                attachments={this.props.attachments}
                attachmentRemoved={this.props.attachmentRemoved}
                attachmentAdded={this.props.attachmentAdded}
                downloadAttachment={this.props.downloadAttachment}
            />
        )
    }
}
export default FormSectionWrapper(compose(connect(mapStateToProps, mapDispatchToProps))(SupportingDocumentationContainer), "Supporting Documentation");