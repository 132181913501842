import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export default function TabPanel(props) {
    const selectedDomain = props.tabs.find((tab) => {
        return tab.id === props.selectedTab.id;
    })
    const selectedTabIndex = props.tabs.indexOf(selectedDomain);
    return (
        <div style={{position:'relative'}}>
            <div style={{top: 20, position: 'absolute', height: 'calc(100% - 20px)', width: '100%'}}>
                <LoadingSpinner loading={props.loading}/>
            </div>
        <Tabs value={selectedTabIndex !== -1 ? selectedTabIndex : false} onChange={props.onTabChanged} variant="scrollable">
            {props.tabs.map((tab) => {
                return (
                    <Tab label={tab.name} key={tab.id} wrapped/>
                )
            })}
        </Tabs>
        </div>
    )
}