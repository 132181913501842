
import React from 'react';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 276;
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexBasis: props => props.drawerWidth || drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: props => props.drawerWidth || drawerWidth,
  },
  toolbar: theme.mixins.toolbar
}));

export default (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { isOpen } = props
      return (
          <React.Fragment>
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={isOpen}
              onClick={props.hideDrawer}
              onClose={props.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true
              }}
            >
            <div className={classes.toolbar} />
            {props.children}
            </Drawer>
          </Hidden>

          <Hidden smDown implementation="js">
            <nav
              className={classes.drawer}
              style={{ display: isOpen ? "block" : "none" }}
            >
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
              >
              <div className={classes.toolbar} />
              {props.children}
              </Drawer>
            </nav>
          </Hidden>
        </React.Fragment>
      );
}