import * as actionTypes from './actionTypes';

export const getAllPicklistItemsStarted =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_STARTED}`, payload: myParam};
};
export const getAllPicklistItemsFinished =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_FINISHED}`, payload: myParam};
};
export const getAllPicklistItemsFailed =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_ALL_PICKLIST_ITEMS_FAILED}`, payload: myParam};
};

export const getPicklistItemsStarted =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_STARTED}`, payload: myParam};
};
export const getPicklistItemsFailed =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_FAILED}`, payload: myParam};
};
export const getPicklistItemsFinished =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.GET_PICKLIST_ITEMS_FINISHED}`, payload: myParam};
};

export const editPicklistItemStarted =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_STARTED}`, payload: myParam};
};

export const editPicklistItemFailed = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_FAILED}`, payload: myParam};
}

export const editPicklistItemFinished = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_FINISHED}`, payload: myParam};
}

export const newItemTextChanged = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.NEW_PICKLIST_ITEM_TEXT_CHANGED}`, payload: myParam};
}

export const editItemTextChanged = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.EDIT_PICKLIST_ITEM_TEXT_CHANGED}`, payload: myParam}
}

export const addPicklistItemStarted =  (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_STARTED}`, payload: myParam};
};

export const addPicklistItemFailed = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_FAILED}`, payload: myParam};
}

export const addPicklistItemFinished = (namespace, myParam) => {
    return {type: `${namespace}/${actionTypes.ADD_PICKLIST_ITEM_FINISHED}`, payload: myParam};
}
