import React, {Component} from 'react';
import './App.css';
import Amplify, {Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import AppContent from './AppContent';
import { connect } from 'react-redux';
import * as actionTypes from './store/actions/actionTypes';
import history from './history';
import ErrorDialog from './containers/ErrorDialogContainer/ErrorDialogContainer';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import ErrorBoundary from './ErrorBoundary';
import Snackbar from './containers/Snackbar/Snackbar';
import InfoDialogContainer from './containers/InfoDialogContainer/InfoDialogContainer';

const mapStateToProps = (state) => {
  return {
    userDataLoaded: state.user.userDataLoaded,
    backgroundTaskRunning: state.backgroundTask.visible,
    backgroundTaskMessage: state.backgroundTask.message    
  }};

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload) => {
    dispatch({type: actionTypes.INITIALIZE_APP_STATE, payload: payload});
  },
  dispatchError: (payload) => {
    dispatch({ type: actionTypes.OPEN_ERROR_DIALOG , payload: "Unexpected error occured. Please restart application and try again"})
  }
});
let oauth = {
  domain : process.env.REACT_APP_AUTH_DOMAIN,
  redirectSignIn : window.location.origin,
  redirectSignOut: window.location.origin,
  responseType: 'code',
}
awsconfig.Auth = {
  ...awsconfig.Auth,
  oauth: {
    ...oauth
  }
}
Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    Hub.listen('auth', (data) => {
        switch (data.payload.event) {
            case 'signIn':
                this.setState({authState: 'signedIn', authData: data.payload.data});
                Auth.currentAuthenticatedUser().then(user => {
                  this.setState({authState: 'signedIn'});
                  this.props.loadView(user);
                  if (localStorage.getItem('base64Url')) {
                    history.push(atob(localStorage.getItem('base64Url')));
                    localStorage.removeItem('base64Url')
                  }
                });
                break;
            case 'signIn_failure': {
              let redirectUrl = window.location.origin;
              if (localStorage.getItem('base64Url')) {
                redirectUrl = redirectUrl + atob(localStorage.getItem('base64Url'))
                localStorage.removeItem('base64Url')
              }
              window.location.replace(redirectUrl);
                break;
            }

            case 'customOAuthState':
                this.setState({customState: atob(decodeURIComponent(data.payload.data))});
                history.push(atob(decodeURIComponent(data.payload.data)));
                break;
            default:
                break;
        }
    });
    this.state = {
      authState: 'loading',
      authData: null,
      authError: null,
      customState: ''
    }
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
      if (user.attributes['custom:role'] !== undefined && user.attributes['custom:Role'] === undefined) {
        Auth.signOut().then(() => {
          Auth.currentAuthenticatedUser().then(user => {
            this.setState({authState: 'signedIn'});
            this.props.loadView(user);
          })
        })
      } else {
        this.setState({authState: 'signedIn'});
        this.props.loadView(user);
      }
    }).catch(e => {
      if (window.location.search.indexOf('?code') === -1) {
        setTimeout(() => {
          localStorage.setItem('base64Url', btoa(history.location.pathname));
          Auth.federatedSignIn({provider: process.env.REACT_APP_AUTH_PROVIDER});
        });
      }
      this.setState({authState: 'signIn'});
    });
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn'});
    }).catch(e => {
    });
  }

  render() {
    return (
      <ErrorBoundary dispatchError={this.props.dispatchError}>
        <div className="App">
          <ErrorDialog />
          <InfoDialogContainer />
          <Snackbar />
          <LoadingSpinner loading={!this.props.userDataLoaded }/>
          {this.props.userDataLoaded && <AppContent backgroundTaskRunning={this.props.backgroundTaskRunning} backgroundTaskMessage={this.props.backgroundTaskMessage}/>}
        </div>
      </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
