import React, { Component } from 'react';
import { connect } from 'react-redux';
import LastModifiedBy from './components/LastModifiedBy';
const mapStateToProps = (state) => {
    return {
        lastModifiedBy: state.deliveryConfirmationForm.lastModifiedBy,
    }
}
class LastModifiedByContainer extends Component {
    render() {
        return (
            <LastModifiedBy lastModifiedBy={this.props.lastModifiedBy}/>
        )
    }
}
export default connect(mapStateToProps, null)(LastModifiedByContainer);