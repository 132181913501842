import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import Input from '../../../../components/Input/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import SummaryContainerResourceTable from '../components/SummaryContainerResourceTable';
import ReactResizeDetector from 'react-resize-detector';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import { shouldComponentUpdate } from '../../../../store/reducers/ChangeLogFormReducer';

const mapStateToProps = (state) => {
    return {
        activityName: state.changelogForm.activity.label || '',
        vendor: state.changelogForm.vendor.label || '',
        rateYear: state.changelogForm.rateYear.label || '',
        poWbs: state.changelogForm.poWbs.label || '',
        year: state.changelogForm.year.label || '',
        resources: state.changelogForm.resourcesThrottled || [],
    }
};
const mapDispatchToProps = (dispatch) => ({
    attachmentAdded: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormAttachmentAdded(payload)),
    attachmentRemoved: (payload) =>
        dispatch(changelogFormActionsCreators.changelogFormAttachmentDeleted(payload)),
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))                        
});
const styles = {
    wrapper: {
        position: 'fixed',
        width:'100%',
        zIndex: 3,
        backgroundColor: '#fff',
        paddingLeft: 72,
        boxShadow: '0 8px 6px -6px #d8d8d8',
        overflow: 'hidden',
    },
    twoColumns: {
        display: 'flex',
    },
    basicInformationWrapper: {
        width: 350
    },
    classesBasicInformationHeader: {
        color: '#262626',
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 10
    },
    basicInformationContentWrapper: {
        paddingLeft: 8,
        paddingBottom: 18,
        '& > *': {
            marginBottom: 12
        }
    },
    tableWrapper: {
        width: 'calc(100% - 380px)',
    }
}

class SummaryContainer extends Component {
    state = {
        visible: false,
        summaryVisible: true
    }
    handleResourceNameClick = (id) => {
        const resource = this.props.resources.find((resource) => {
            return resource.key === id;
        });
        if (resource && resource.ref && resource.ref.current) {
            // resource.ref.current.scrollIntoView({
            //     behavior: 'smooth', 
            //     block: 'end' 
            // });
            resource.ref.current.scrollIntoView(false);
        }
    }
    changeSummaryVisibility = (visiblity) => {
        this.setState({
            summaryVisible: visiblity
        })
    }    
    render() {
        return (
            <ReactResizeDetector handleWidth onResize={this.handleScroll}>
            <div style={{marginLeft: -48, zIndex: 3}}>
                <div className={this.props.classes.wrapper} style={{width: this.state.width}}>
                    <div style={{display: (this.state.visible && this.state.summaryVisible) ? null : "none"}}>
                    <div style={{display: 'flex',alignItems: 'flex-start'}}>
                        <div>
                            <div className={this.props.classes.basicInformationWrapper}>
                            <h3 className={this.props.classes.classesBasicInformationHeader}>Basic information</h3>
                            <div className={this.props.classes.basicInformationContentWrapper}>
                                <Input label="Activity Name" width="100%" disabled value={this.props.activityName} className="disabled-form-input changelog-popup"/>
                                <Input label="Supplier" width="100%" disabled value={this.props.vendor} className="disabled-form-input changelog-popup"/>
                                <Input label="PO WBS" width="100%" disabled value={this.props.poWbs} className="disabled-form-input changelog-popup"/>
                                <div className={this.props.classes.twoColumns}>
                                <Input label="Year" width="50%" disabled value={this.props.year} className="disabled-form-input changelog-popup"/>
                                <Input label="Rate Year" width="50%" disabled value={this.props.rateYear} className="disabled-form-input changelog-popup"/>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className={this.props.classes.tableWrapper}>
                            <h3 className={this.props.classes.classesBasicInformationHeader}>Resources</h3>
                            <SummaryContainerResourceTable resources={this.props.resources} handleResourceNameClick={this.handleResourceNameClick}/>
                        </div>
                    </div>
                    </div>
                    <div style={{display: this.state.visible ? null : "none"}}>
                        {this.state.summaryVisible ?
                           <IconButton onClick={this.changeSummaryVisibility.bind(this, false)}>
                               <ArrowDropUpIcon />
                            </IconButton> : 
                            <IconButton onClick={this.changeSummaryVisibility.bind(this, true)}>
                                <ArrowDropDownIcon />
                            </IconButton>
                        }
                    </div>
                </div>
             </div>
             </ReactResizeDetector>
        )
    }
    componentDidMount() {
        document.querySelector('#content').addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        document.querySelector('#content').removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = (e) => {
        const threshold = 300;
        const dcRow = document.querySelector('#delivery-confirmation-row');
        const width = document.querySelector('#content').clientWidth;
        if (!dcRow) {
            return;
        }
        const rect = dcRow.getBoundingClientRect();
        if (this.state.width !== width || this.state.visible !== (rect.bottom -threshold  < 0)) {
            this.setState({
                visible: (rect.bottom -threshold  < 0),
                width: width
            })
        }

    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(SummaryContainer);