import * as actionTypes from '../actions/actionTypes';

const initialState = {
    activities: {},
    loading: false,
    columns: []
}
const activityViewerReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ACTIVITIES_LIST_LOADING_SPINNER_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        // case actionTypes.GET_ACTIVITIES_LIST_STARTED: {
        //     return {
        //         ...initialState,
        //         loading: true,
        //     }
        // }
        case actionTypes.GET_ACTIVITIES_LIST_FINISHED: {
            return {
                ...state,
                loading: false,
                activities: action.payload
            }
        }
        case actionTypes.GET_ACTIVITIES_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                activities: {}
            }
        }
        case actionTypes.UPDATE_ACTIVITIES_COLUMNS_STARTED:
        case actionTypes.UPDATE_ACTIVITIES_COLUMNS_FAILED:    
        case actionTypes.UPDATE_ACTIVITIES_COLUMNS_FINISHED:
        case actionTypes.GET_ACTIVITES_COLUMNS_FAILED:  
        case actionTypes.GET_ACTIVITES_COLUMNS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_ACTIVITES_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default activityViewerReducer;