import { put, takeEvery, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as tagsActions from '../store/actions/tags';
import * as navbarActionCreators from '../store/actions/Navbar';
import axios from '../axios';


export function* getAllTags() {
    try {
        const tags = yield axios.get('api/tags', {params:{withDeleted: true}});
        yield put(tagsActions.getAllTagsFinished(tags.data));
    } catch(error) {
        yield put(tagsActions.getAllTagsFailed(error))
    }
}

function* addTag(action) {
    try {
        yield axios.post('api/tags', action.payload);
        yield* getAllTags();
        yield put(tagsActions.addTagFinished());
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(tagsActions.addTagFailed());
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editTag(action) {
    try {
        yield axios.put('api/tags', action.payload);
        yield* getAllTags();
        yield put(tagsActions.editTagFinished(action.payload.id));
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(tagsActions.editTagFailed())
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}
const sagas =  [
    takeEvery(actionTypes.GET_ALL_TAGS_STARTED, getAllTags),
    takeEvery(actionTypes.ADD_TAG_STARTED, addTag),
    takeEvery(actionTypes.EDIT_TAG_STARTED, editTag),
]
export default sagas