import * as actionTypes from '../actions/actionTypes';

const initialState = {
    picklists: [
        {
            name: "Platforms",
            id: "Platforms"
        },
        {
            name: "Sub-platforms",
            id: "Sub-platforms"
        },
        {
            name: "Po Locations",
            id: "Po Locations"
        },
        {
            name: "Expense Types",
            id: "Expense Types"
        },
        {
            name: "Cost Types",
            id: "Cost Types"
        },
        {
            name: "Currencies",
            id: "Currencies"
        },
        {
            name: "Resource Locations",
            id: "Resource Locations"
        },
        {
            name: "Resource Types per Vendor Type",
            id: "Resource Types per Vendor Type"
        },
        {
            name: 'Cost Centers',
            id: 'Cost Centers'
        },
        {
            name: 'Tags',
            id: 'Tags'
        }

    ],
    selectedPicklist: {
        name: "Platforms",
        id: "Platforms"
    },
}

const staticPicklistsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECTED_PICKLIST_CHANGED: {
            return {
                ...state,
                selectedPicklist: action.payload
            }
        }
        default:
            return state;
    }
}
export default staticPicklistsReducer;