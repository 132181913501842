import React, { Component } from 'react';
import DataTable from './DataTable2';
import { connect } from 'react-redux';
import * as tableActions from '../../store/actions/tableActions';

const mapStateToProps = (state, ownProps) => {
    return {
        state: state.table.filters[ownProps.tableName] || {
                filtered: {},
                pagination: {
                    itemsPerPage: 10,
                },
            },
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    tableStateChanged: (payload) =>
        dispatch(tableActions.tableStateChanged(payload)),   
});
class DataTableWithServerSidePagination extends Component {
    // state = {
    //     filtered: {},
    //     pagination: {
    //         itemsPerPage: 10,
    //     },
    // }
    // componentWillUnmount() {
    //     if (this.props.saveFilterState) {
    //         this.props.saveFilterState(null);
    //     }
    // }

    onFilteredCleared = () => {
        const newPagination = {
            ...this.props.state.pagination,
        }
        const newState =  {
            filtered: {},
            pagination: newPagination
        };
        const parsedFilters = this.getParsedFilters(1, newState);
        this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
        this.props.onFetchData(this.getParsedFilters(1, newState));
            if (this.props.saveFilterState) {
                this.props.saveFilterState(parsedFilters)
        }
    }

    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        const newFiltered = {
            ...this.props.state.filtered,
        }
        newFiltered[propName] = value;
        const newPagination = {
            ...this.props.state.pagination,
        }
        const newState = {
            filtered: newFiltered,
            pagination: newPagination
        };
        const parsedFilters = this.getParsedFilters(1, newState);
        this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
        this.props.onFetchData(parsedFilters, true);
        if (this.props.saveFilterState) {
            this.props.saveFilterState(parsedFilters)
        }
    }
    onSortClick = (event, property) => {
        const isAsc = this.props.state.filtered.sortBy === property && this.props.state.filtered.desc === 0;
        const newFiltered = {
            ...this.props.state.filtered,
        }
        newFiltered['sortBy'] = property;
        newFiltered['desc'] = isAsc ?  1 : 0;
        const newPagination = {
            ...this.props.state.pagination,
        }
        const newState = {
            filtered: newFiltered,
            pagination: newPagination
        };
        const parsedFilters = this.getParsedFilters(1, newState);
        this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
        this.props.onFetchData(parsedFilters);
        if (this.props.saveFilterState) {
            this.props.saveFilterState(parsedFilters)
        }

    }

    dropdownFilteredChanged = (data, property) => {
            const newFiltered = {
                ...this.props.state.filtered,
            }
            //when react select clear value is pressed it returns null
            if (data) {
                newFiltered[property] = data;
            } else {
                delete newFiltered[property];
            }
            const newPagination = {
                ...this.props.state.pagination,
            }
            const newState = {
                filtered: newFiltered,
                pagination: newPagination
            };
            const parsedFilters = this.getParsedFilters(1, newState);
            this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
            this.props.onFetchData(parsedFilters);
            if (this.props.saveFilterState) {
                this.props.saveFilterState(parsedFilters)
            }
    }
    
    getParsedFilters(pageNum, state) {
        const parsedState = {...state};
        const keys = Object.keys(parsedState.filtered);
        const filteredParsed = Object.assign({}, ...keys.map((key) => {
            if (typeof parsedState.filtered[key] === 'object') {
                return {[key]: parsedState.filtered[key].value}
            }
            return {[key]: parsedState.filtered[key]};
        }))
        parsedState.pagination = {...state.pagination}
        parsedState.pagination.page = pageNum;
        return  {
            ...filteredParsed,
            ...parsedState.pagination
        };
    } 

    onPageChanged = (event, page) => {
        const newState = {
            pagination: this.props.state.pagination,
            filtered: this.props.state.filtered
        };
        const parsedFilters = this.getParsedFilters(page + 1, newState);
        this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
        this.props.onFetchData(parsedFilters);
        if (this.props.saveFilterState) {
            this.props.saveFilterState(parsedFilters)
        }
    }

    onPageSizeChanged = (event) => {
            const newPagination = {
                ...this.props.state.pagination,
                itemsPerPage: event.target.value
            }
            const newState = {
                pagination: newPagination,
                filtered: this.props.state.filtered
            };
            const parsedFilters = this.getParsedFilters(this.props.currentPage, newState);
            this.props.tableStateChanged({tableName: this.props.tableName, state: newState, parsedFilters: parsedFilters});
            this.props.onFetchData(parsedFilters);
            if (this.props.saveFilterState) {
                this.props.saveFilterState(parsedFilters);
            }
    }

    render() {
        return (
            <DataTable 
                onSortClick={this.onSortClick}
                filtered={this.props.state.filtered}
                pagination={this.props.state.pagination}
                onFilteredCleared={this.onFilteredCleared}
                onFilteredChanged={this.onFilteredChanged}
                dropdownFilteredChanged={this.dropdownFilteredChanged}
                onPageChanged={this.onPageChanged}
                onPageSizeChanged={this.onPageSizeChanged}
                currentPage={this.props.currentPage}
                {...this.props}
            />
        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DataTableWithServerSidePagination)