import React, { Component } from 'react';
import FormSectionWrapper from '../components/FormSectionWrapper';
import Input from '../../../../components/Input/TextArea';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles'
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import { canFunction } from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import { shouldComponentUpdate } from '../../../../store/reducers/ChangeLogFormReducer';
import { isDisabledWithRequestor } from '../../../../utils/changelogFormEditPrivileges';
import formatDate from '../../../../utils/dateFormatter';
import TextArea from '../../../../components/Input/TextArea';

const mapStateToProps = (state) => {
    return {
        isDraft: state.changelogForm.isDraft,
        comment: state.changelogForm.comment,
        comments: state.changelogForm.comments || [],
        role: state.user.selectedRole,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        editMode: state.changelogForm.editMode,
        shouldUpdate: shouldComponentUpdate(state.changelogForm)
    }
};

const mapDispatchToProps = (dispatch) => ({
    commentChanged: (payload) => 
        dispatch(changelogFormActionsCreators.changelogFormCommentChanged(payload))        
    }
);
const styles = {
    commentsListContainer: {
        paddingTop: 18
    },
    commentContainer: {
        paddingBottom: 16,
        borderRadius: '25px',
        width: '100%',
        wordWrap: 'break-word'
    },
    commentHeader: {
        color: '#595959',
        fontSize: 12,
        fontWeight: '500'
    },
    commentContent: {
        color: '#262626',
        lineHeight: '20px',
        fontSize: 16,
        paddingTop: 10
    }
}

class CommentsContainer extends Component {
    shouldComponentUpdate() {
        return this.props.shouldUpdate;
    }
    isDisabled = () => {
        return isDisabledWithRequestor(this.props, rules.EDIT_CHANGELOG_COMMENT);
    }
    render () {
        return (
        <div>
            <Input
                width="100%" 
                label="Comment"
                value={this.props.comment ? this.props.comment : ""}
                onChange={this.props.commentChanged}
                disabled={this.isDisabled()}
             />
             {this.props.comments.length ? 
                <div className={this.props.classes.commentsListContainer}>
                    {this.props.comments.map((comment) => {
                        return (
                            <div key={comment.userName + comment.date} className={this.props.classes.commentContainer}>
                                <h4 className={this.props.classes.commentHeader}>
                                    {`${comment.userName}, ${formatDate(comment.date)}`}
                                </h4>
                                <TextArea value={comment.message} disabled editorStyle={{height: 'auto', border: 0, padding: 0}} />
                            </div>
                        )
                    })}
                </div>
            : null }
        </div>
        )
    }
}
export default FormSectionWrapper(compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(CommentsContainer), "Comments")