import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import months from './constants/months';
import formatDate from './utils/dateFormatter'
import { isVendorTypeExternal, isConsentTypeYes, isLocationVisible, isAssignmentSiteVisible, isGradeVisible, isUnitPriceDisabledOnChangelogForm, isPerDiemVisible, isTimeAndMaterial } from './utils/typesAssertions';
import { Font } from '@react-pdf/renderer'
import { getPureText } from './utils/commentUtils';

Font.registerHyphenationCallback(words => {
    var re = new RegExp('.{1,70}', 'g');
    return words.match(re);
}
);
const styles = StyleSheet.create({
    header: {
        fontSize: 30,
        fontWeight: 'bold',
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: 10
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    label: {
        color: '#a9a9a9',
        fontSize: 10,
        paddingLeft: 12,
        // fontFamily: 'Lato'
    },
    value: {
        color: '#262626',
        fontSize: 12,
        padding: '8px 12px',
        flexDirection: 'row'
        //   fontFamily: 'Lato'
    },
    sectionHeader: {
        color: '#262626',
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        fontSize: 16
    },
    table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: { width: "8.33%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 9 }
});

const Input = (props) => (
    <View wrap={false} style={{ flexGrow: props.shouldntGrow ? 0 : 1, flexBasis: 0, paddingBottom: 5 }}>
        <View style={styles.label}>
            <Text>{props.label}</Text>
        </View>
        <View style={styles.value}>
            <Text style={{ flex: 1, wordBreak: 'break-all', margin: 1 }}>{props.value}</Text>
        </View>
    </View>
)
// status last modified by actuals submitted by actuals approved by

// Create Document Component
const MyDocument = (props) => {
    return (
        <Document>
            <Page style={styles.page}>
                <View style={{ display: 'flex', paddingTop: 10, width: '100%', paddingBottom: 20 }}>
                    <Text style={styles.header}>Change log - ID {props.id}</Text>
                </View>
                <View style={{ display: 'flex', paddingTop: 10, paddingBottom: 20 }}>
                    <Input label="Created by" value={props.createdBy}></Input>
                    <Input label="Last modified by" value={props.lastModifiedBy}></Input>
                    <Input label="Status" value={props.status ? props.status.label : ""}></Input>
                </View>

                <View style={{ display: 'flex', paddingTop: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', flexGrow: 1, flexBasis: 0 }}>
                            <View style={styles.sectionHeader}>
                                <Text>Delivery Information</Text>
                            </View>
                            <View>
                                <Input label="Platform" value={props.domain ? props.domain.label : ""}></Input>
                                <Input label="Sub-platform" value={props.subDomain ? props.subDomain.label : ""}></Input>
                                <Input label="Activity Type" value={props.activityType ? props.activityType.label : ""}></Input>
                                <Input label="Activity name" value={props.activity ? props.activity.label : ""}></Input>
                                <Input label="Vendor Type" value={props.vendorType ? props.vendorType.label : ""}></Input>
                                <Input label="Supplier/Team" value={props.vendor ? props.vendor.label : ""}></Input>
                                <Input label="PO Location" value={props.poLocation ? props.poLocation.label : ""}></Input>
                                <Input label="Cost Type" value={props.costType ? props.costType.label : ""}></Input>
                                <Input label="PO WBS" value={props.poWbs ? props.poWbs.label : ""}></Input>

                                <View style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, flexBasis: 0 }}>
                                    <Input label="Year" value={props.year ? props.year.label : ""}></Input>
                                    <Input label="Rate Year" value={props.rateYear ? props.rateYear.label : ""}></Input>
                                </View>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexGrow: 1, flexBasis: 0 }}>
                            <View style={styles.sectionHeader}>
                                <Text>Roles</Text>
                            </View>
                            <View>
                                <Input label="Planner" value={props.planner}></Input>
                                {isVendorTypeExternal(props.vendorType) ?
                                    <Input label="Supplier Representative" value={props.supplierRepresentative ? props.supplierRepresentative.label : ""}></Input> : null
                                }
                                <Input label="Delivery Confirmation Approver" value={props.approver ? props.approver.label : ""}></Input>
                                <Input label="LeanIX ID" value={props.leanixdto ? props.leanixdto.label : ""}></Input>
                                <Input label="LeanIX Name" value={props.leanixdto ? props.leanixdto.value : ""}></Input>
                                {isConsentTypeYes(props.consentType.value) ?
                                    <Input label="EPPM Project ID" value={props.ePPMDto ? props.ePPMDto.label : ""}></Input>
                                    : null
                                }
                                {isConsentTypeYes(props.consentType.value) ?
                                    <Input label="EPPM Project Name" value={props.ePPMDto ? props.ePPMDto.value : ""}></Input>
                                    : null
                                }

                            </View>
                        </View>
                    </View>
                </View>

                <View break >
                    <View style={styles.sectionHeader}>
                        <Text>Resource</Text>
                    </View>
                    {props.resources && props.resources.map((resource, index) => {
                        function getTotalPrice() {
                            let totalMonthDays = 0;
                            if (resource.monthAmount) {
                                Object.keys(resource.monthAmount).forEach(key => {
                                    if (Number.isNaN(resource.monthAmount[key])) {
                                        return;
                                    }
                                    totalMonthDays += (parseFloat(resource.monthAmount[key]) * parseFloat(resource.unitPrice.value));
                                    if (resource.resourceType && resource.assignmentSite && isPerDiemVisible(resource.resourceType.value, resource.assignmentSite.value) && resource.perDiem) {
                                        totalMonthDays += (parseFloat(resource.monthAmount[key]) * parseFloat(resource.perDiem.value));
                                    }
                                })
                            }
                            return totalMonthDays.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        function getTotalAmountOfManDays() {
                            let totalMonthDays = 0;
                            if (resource.monthAmount) {
                                Object.keys(resource.monthAmount).forEach(key => {
                                    if (Number.isNaN(resource.monthAmount[key])) {
                                        return;
                                    }
                                    totalMonthDays += parseFloat(resource.monthAmount[key]);
                                })
                            }
                            return totalMonthDays.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        const location = <Input label="Resource Type" value={resource.location ? resource.location.label : ""}></Input>;
                        const assignmentSite = <Input label="Assignment Site" value={resource.assignmentSite ? resource.assignmentSite.label : ""}></Input>;
                        const profileGrade = <Input label="Profile/Grade" value={resource.grade ? resource.grade.label : ""}></Input>;
                        const unitPrice = <Input label="Unit Price" value={resource.unitPrice ? resource.unitPrice.label : ""}></Input>;
                        const perDiem = (!(resource.assignmentSite && resource.resourceType && isPerDiemVisible(resource.resourceType.value, resource.assignmentSite.value)) ? null :
                            <Input label="Per Diem" value={resource.perDiem ? resource.perDiem.label : ""}></Input>);
                        const currency = <Input label="Currency" value={resource.currency ? resource.currency.label : ""}></Input>;
                        return (
                            <View wrap={false} key={resource.key} style={
                                {
                                    border: '1pt solid #c8cace',
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 16,
                                    borderBottomRightRadius: 16,
                                    borderBottomLeftRadius: 16,
                                    padding: 16,
                                    marginBottom: 20
                                }
                            }>
                                <View style={{ display: 'flex', justifyContent: resource.prepayment && 'space-between', flexDirection: 'row', paddingBottom: 8, marginTop: -10, marginLeft: -10 }}>
                                    <View><Text>{index + 1}</Text></View>
                                    {resource.prepayment &&
                                        <View><Text>Prepayment required</Text></View>
                                    }
                                    <View><Text></Text></View>
                                </View>
                                <View style={{ display: 'flex' }}>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Input label="Resource Type" value={resource.resourceType ? resource.resourceType.label : ""}></Input>
                                        <Input label="Start Date" value={resource.startDate || ""}></Input>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Input label="Name" value={resource.name || ""}></Input>
                                        <Input label="Expense Type" value={resource.expenseType ? resource.expenseType.label : ""}></Input>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        {isLocationVisible(resource.resourceType.value) ? location : null}
                                        {isAssignmentSiteVisible(resource.resourceType.value) ? assignmentSite : null}
                                        {isGradeVisible(resource.resourceType.value) ? profileGrade : null}
                                        {unitPrice}
                                        {isPerDiemVisible(resource.resourceType.value, resource.assignmentSite ? resource.assignmentSite.value : null) ? perDiem : null}
                                        {currency}
                                    </View>
                                </View>

                                <View style={{ marginTop: 10 }}>
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}>
                                            {months.map((month, index) => {
                                                return (
                                                    <View key={index} style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{month}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                        <View style={styles.tableRow}>
                                            {months.map((month, index) => {
                                                return (
                                                    <View key={index} style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{resource.monthAmount ? resource.monthAmount[index + 1] : ""}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginTop: 10 }}>
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Input label="Prepayment" value={resource.prepayment ? "Yes" : "No"}></Input>
                                    </View>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 12 }}>{`Total price: ${getTotalPrice()} ${resource.currency ? resource.currency.label : ""}`}</Text>
                                </View>
                                {
                                    isTimeAndMaterial(resource.resourceType.value) ?
                                        <View style={{ paddingTop: 8 }}>
                                            <Text style={{ fontSize: 12 }}>{`Total number of man-days:  ${getTotalAmountOfManDays()}`}</Text>
                                        </View>
                                        : null
                                }

                            </View>
                        )
                    })}
                </View>
                <View style={{ display: 'flex', paddingTop: 10 }}>
                    <View break style={styles.sectionHeader}>
                        <Text>Attachments</Text>
                    </View>
                    <View style={{ display: 'flex' }}>
                        {props.attachments ? props.attachments.map((attachment, index) => {
                            return (<Input
                                index={index}
                                label={`Attachment ${index + 1}`}
                                value={attachment.name}
                            />
                            )
                        }) : null}
                    </View>
                </View>
                <View style={{ display: 'flex', paddingTop: 10 }}>
                    <View style={styles.sectionHeader}>
                        <Text>Comments</Text>
                    </View>
                    <View style={{ display: 'flex' }}>
                        {props.comments ? props.comments.map((comment, index) => {
                            return (<Input
                                key={comment.date}
                                index={index}
                                label={`${comment.userName}, ${formatDate(comment.date)}`}
                                value={comment.message ? getPureText(comment.message).replace(/(\r\n|\n|\r)/gm, " ") : ""}
                            />
                            )
                        }) : null}
                    </View>
                </View>

            </Page>
        </Document>
    )
};
const CustomDocumentViewerWithLink = (res) => {
    return pdf((<MyDocument {...res} />)).toBlob();
}
export default CustomDocumentViewerWithLink;