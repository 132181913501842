import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  breadcrumbPadding: {
    paddingLeft: 12
  },
  separatorIconColor: {
    color: '#fff'
  },
  updateComment: {
    marginLeft: 'auto',
    paddingRight: 24,
    fontSize: 20,
    color: '#fff',
    textTransform: 'uppercase'
  }
});

export default (props) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" classes={{...props.classes}}>
      <Toolbar>
        {
          props.hiddenActionButton ? null :
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={props.onIconButtonClicked}
        >
        <MenuIcon />
      </IconButton>
        }

        <Hidden mdDown>
        <Breadcrumbs separator={<NavigateNextIcon className={classes.separatorIconColor} fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumbPadding}>
          {props.paths.map((path, index) => {
            if (index === 0) {
              return (
                <Link className="breadcrumb-link" onClick={props.onBreadCrumbItemClick.bind(this, path.url)} key={path.url}>
                  {path.label}
                </Link>
                )
            } else {
              return (
                <Link className="breadcrumb-link" onClick={props.onBreadCrumbItemClick.bind(this, path.url)} key={path.url}>
                  {path.label}
                </Link>
                )
            }

          })}
        </Breadcrumbs>
        </Hidden>
        <Hidden lgUp>
        <Link className="breadcrumb-link" onClick={props.onBreadCrumbItemClick.bind(this, props.paths[0].url)} key={props.paths[0].url}>
                  {props.paths[0].label}
        </Link>
        </Hidden>
        {props.children}
          {props.statusComment &&
          <span className={classes.updateComment}>
            {props.statusComment}
          </span>
          }
      </Toolbar>
    </AppBar>
  )
}
