import React from 'react';
import TextWithIcon from '../../../../../components/Input/TextWithIcon';
import Flag from '@material-ui/icons/FlagOutlined';
import internalStatuses from '../../../../../constants/dcStatusesInternal';
import externalStatuses from '../../../../../constants/dcStatusesExternal';
import { isVendorTypeExternal } from '../../../../../utils/typesAssertions';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
    inputUpdated: {
        border: '1px solid #39acff',
        backgroundColor: '#e1f5fe',
        borderRadius: 16,
        padding: 5,
    }
})

export default ({status, vendorType, oldStatus}) => {
    const classes = useStyles();
    const value = isVendorTypeExternal(vendorType) ? externalStatuses[status] : internalStatuses[status];
    return (<div style={{position: 'relative'}}>
                <TextWithIcon label="Status" value={value} icon={Flag} tooltipText={oldStatus} className={oldStatus ? classes.inputUpdated: null}/>
        </div>)
}