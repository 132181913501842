import React, { Component } from 'react';
import DataTable from '../../../../components/DataTable/DataTableWithServerSidePagination';
import Input from '../../../../components/Input/Input';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/vendors';
import DataExportContainer from '../../../../containers/DataExport/DataExportContainer';
const mapStateToProps = (state) => {
    return {
        vendors: state.vendors.vendors && state.vendors.vendors.items ? state.vendors.vendors.items : [],
        count: state.vendors.vendors && state.vendors.vendors.itemsCount ? state.vendors.vendors.itemsCount : 0,
        currentPage: state.vendors.vendors && state.vendors.vendors.currentPage ? state.vendors.vendors.currentPage : 1,
        itemsPerPage: state.vendors.vendors && state.vendors.vendors.itemsPerPage ? state.vendors.vendors.itemsPerPage : 10,
        loading: state.vendors.loading,
        newItemVendorSapNumber: state.vendors.newItemVendorSapNumber,
        newItemVendorName: state.vendors.newItemVendorName,
        vendorSapNumberBeingEditedMap: state.vendors.vendorSapNumberBeingEditedMap,
        vendorNameBeingEditedMap: state.vendors.vendorNameBeingEditedMap,
        selectedItems: state.vendors.selectedItems || {}
    }
};
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    getVendorsStarted: (payload) =>
        dispatch(actionCreators.getVendorsStarted(payload)),
    addVendorStarted: (payload) => 
        dispatch(actionCreators.addVendorStarted(payload)),
    deleteVendorStarted: (data, id) => 
        dispatch(actionCreators.deleteVendorStarted(id)),
    editVendor: (payload) => 
        dispatch(actionCreators.editVendorStarted(payload)),
    newVendorNameChanged: (event) => {
        dispatch(actionCreators.newVendorNameChanged(event))
    },
    newVendorSapNumberChanged: (domain) => 
        dispatch(actionCreators.newVendorSapNumberChanged(domain)),
    editVendorName: (event) =>
        dispatch(actionCreators.editVendorName(event)),
    editVendorSapNumber: (event) =>
        dispatch(actionCreators.editVendorSapNumber(event))
});

class VendorPickList extends Component {
    addItem = (event) => {
        if (event.target.value.length === 0) {
            return;
        }
        this.props.addVendorStarted({name: this.props.newItemVendorName, sapNumber: event.target.value});
    }

    editVendor = (event, prop) => {
        const targetVendor = this.props.vendors.find(vendor => vendor.id === parseInt(event.target.name));
        if (targetVendor[prop] !== event.target.value) {
            const editedVendor = {
                ...targetVendor,
                [prop]: event.target.value
            }
            this.props.editVendor(editedVendor);
        }
    }
    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.addItem(event);
        }
    }
    handleEnterOnEditInput = (event, prop) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.editVendor(event, prop);
        }
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.vendors.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.editVendor(copy)
    }
    onFetchData = (data) => {
        this.props.getVendorsStarted(data);
    }
    render() {
        const pagination = {
            itemsPerPage: this.props.itemsPerPage,
            page: this.props.page
        }
        return (
            <div>
                <DataExportContainer url="api/export/vendors"/>
                <DataTable
                // key={this.props.count}
                pagination={pagination}
                general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                onSelectedChanged={this.selectedItemsChanged}
                selected={this.props.selectedItems}
                count={this.props.count}
                currentPage={this.props.currentPage}
                onFetchData={this.onFetchData}
                loading={this.props.loading}
                enabledAddition
                data={this.props.vendors}  
                addNewItemRenderer= { (dataProperty) => {
                    if (dataProperty === 'name') {
                        return (
                            <Input
                                width={292}
                                value={this.props.newItemVendorName}
                                inputProps={{name: dataProperty}}
                                onChange= {this.props.newVendorNameChanged}
                            />
                        );
                    }
                    return <Input 
                                width={292} 
                                placeholder="Add new..."
                                onBlur={this.addItem}
                                inputProps={{name: dataProperty}}
                                disabled={this.props.newItemVendorName === ''}
                                value={this.props.newItemVendorSapNumber}
                                onChange={this.props.newVendorSapNumberChanged}
                                onKeyPress={this.handleEnterOnAddInput}
                            />
                    }
                }
                customRowCells={{
                    name: (data, id, row) => {
                        return (
                        <Input width={292} 
                            onBlur={(e)=>{this.editVendor(e, "name")}}
                            inputProps={{name: id}}
                            value={this.props.vendorNameBeingEditedMap[parseInt(id)] ? this.props.vendorNameBeingEditedMap[parseInt(id)]: data}
                            onChange={this.props.editVendorName}
                            onKeyPress={(e)=>{this.handleEnterOnEditInput(e, "name")}}
                        />
                        )
                    },
                    sapNumber: (data, id, row) => {
                        return (
                            <Input
                                // onDeleteIconClicked={this.props.deleteVendorStarted.bind(this, data, id, row)}
                                width={292}
                                inputProps={{name: id}}
                                value={this.props.vendorSapNumberBeingEditedMap[parseInt(id)] ? this.props.vendorSapNumberBeingEditedMap[parseInt(id)]: data}
                                onChange={this.props.editVendorSapNumber}
                                onBlur={(e)=>{this.editVendor(e, "sapNumber")}}
                                onKeyPress={(e) => {this.handleEnterOnEditInput(e, "sapNumber")}}
                            />
                        )
                    },
                }}
                columns={[
                    {
                        header:"Name",
                        dataProperty:"name",
                        isVisible: true
                    },
                    {
                        header:"SAP number",
                        dataProperty:"sapNumber",
                        isVisible: true
                    }
                    ]}      
            />
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorPickList);