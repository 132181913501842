import * as actionTypes from './actionTypes';

export const addTagStarted =  (myParam) => {
    return {type: actionTypes.ADD_TAG_STARTED, payload: myParam};
};
export const addTagFailed =  (myParam) => {
    return {type: actionTypes.ADD_TAG_FAILED, payload: myParam};
};
export const addTagFinished =  (myParam) => {
    return {type: actionTypes.ADD_TAG_FINISHED, payload: myParam};
};
export const editTagStarted =  (myParam) => {
    return {type: actionTypes.EDIT_TAG_STARTED, payload: myParam};
};
export const editTagFailed =  (myParam) => {
    return {type: actionTypes.EDIT_TAG_FAILED, payload: myParam};
};
export const editTagFinished =  (myParam) => {
    return {type: actionTypes.EDIT_TAG_FINISHED, payload: myParam};
};
export const deleteTagConnectionStarted =  (myParam) => {
    return {type: actionTypes.DELETE_TAG_CONNECTION_STARTED, payload: myParam};
};
export const deleteTagConnectionFailed =  (myParam) => {
    return {type: actionTypes.DELETE_TAG_CONNECTION_FAILED, payload: myParam};
};
export const deleteTagConnectionFinished =  (myParam) => {
    return {type: actionTypes.DELETE_TAG_CONNECTION_FINISHED, payload: myParam};
};
export const addTagConnectionStarted =  (myParam) => {
    return {type: actionTypes.ADD_TAG_CONNECTION_STARTED, payload: myParam};
};
export const addTagConnectionFailed =  (myParam) => {
    return {type: actionTypes.ADD_TAG_CONNECTION_FAILED, payload: myParam};
};
export const addTagConnectionFinished =  (myParam) => {
    return {type: actionTypes.ADD_TAG_CONNECTION_FINISHED, payload: myParam};
};
export const getAllTagsStarted =  (myParam) => {
    return {type: actionTypes.GET_ALL_TAGS_STARTED, payload: myParam};
};
export const getAllTagsFailed =  (myParam) => {
    return {type: actionTypes.GET_ALL_TAGS_FAILED, payload: myParam};
};
export const getAllTagsFinished =  (myParam) => {
    return {type: actionTypes.GET_ALL_TAGS_FINISHED, payload: myParam};
};
export const tagPickListNewItemChanged =  (myParam) => {
    return {type: actionTypes.TAG_PICKLIST_NEW_ITEM_CHANGED, payload: myParam};
};
export const tagPicklistEditItemChanged =  (myParam) => {
    return {type: actionTypes.TAG_PICKLIST_EDIT_ITEM_CHANGED, payload: myParam};
};