import React, { Component } from 'react';
import DataTableWithServerSizePagination from '../../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import history from '../../../../history';
import * as vendorRatesActions from '../../../../store/actions/vendorRates';
import DataExportContainer from '../../../../containers/DataExport/DataExportContainer';
import AutoSelect from '../../../../components/Input/AutoSelectWithDataOnChange';
import resourceTypes from '../../../../constants/resourceTypes';
import assignmentSites from '../../../../constants/assignmentSites';
const assignmentSitesSuggestions = assignmentSites.map((res, index) => {
    return {
        label: res,
        value: index
    }
})
const resourceTypeSuggestios = resourceTypes.map((res, index) => {
    return {
        label: res,
        value: index
    }
})
const defaultColumns = [
    {
        header: "Assignment Site",
        dataProperty: 'assignmentSite',
        width: 60,
        isVisible: true,
    },    
    {
        header: "Currency",
        dataProperty: 'currency',
        width: 60,
        isVisible: true,
    },
    {
        header: "Profile/grade",
        dataProperty: 'grade',
        width: 60,
        isVisible: true,  
    },
    {
        header: "Per diem rate",
        dataProperty: 'perDiemRate',
        width: 60,
        isVisible: true,  
        disabledFiltering: true
    },
    {
        header: "Rate year",
        dataProperty: 'rateYear',
        width: 60,
        isVisible: true,  
    },
    {
        header: "Resource Location",
        dataProperty: 'resourceLocation',
        width: 60,
        isVisible: true,
    },
    {
        header: "Resource Type",
        dataProperty: 'resourceType',
        width: 60,
        isVisible: true,
    },
    {
        header: "Unit price",
        dataProperty: 'unitPrice',
        width: 60,
        isVisible: true,
        disabledFiltering: true
    },
    {
        header: "Vendor",
        dataProperty: 'vendor',
        width: 60,
        isVisible: true,
    },
]

const mapStateToProps = (state) => {
    const columns = state.vendorRates.columns && state.vendorRates.columns.length !== 0 ? state.vendorRates.columns : defaultColumns;
    let vendorRates = []
    if (state.vendorRates.vendorRates && state.vendorRates.vendorRates.items) {
        vendorRates = state.vendorRates.vendorRates.items
    }
    return {
        tableState: state.table.parsedFilters,
        selectedItems: state.vendorRates.selectedItems,
        columns: columns,
        loading: state.vendorRates.loading,
        vendorRates: vendorRates,
        currentPage: state.vendorRates.vendorRates && state.vendorRates.vendorRates.currentPage ? state.vendorRates.vendorRates.currentPage : 1,
        count: state.vendorRates.vendorRates && state.vendorRates.vendorRates.itemsCount ? state.vendorRates.vendorRates.itemsCount : 0,
    }
};
  
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(vendorRatesActions.intializeVendorRatesViewerStarted({params: payload.queryParameters}));
        dispatch(vendorRatesActions.getVendorRatesColumnsStarted(payload))
      },
      updateColumns: (payload) => {
        dispatch(vendorRatesActions.updateVendorRatesColumnsStarted(payload));
      },
      getColumns: (payload) => {
        dispatch(vendorRatesActions.getVendorRatesColumnsStarted(payload))
      },
      getActivities: (payload) => {
          dispatch(vendorRatesActions.getVendorRatesListStarted(payload))
      }
  });

class VendorRatesViewer extends Component {

    componentDidMount() {
        const tableState = this.props.tableState[this.getTableName()];
        if (tableState) {
            this.props.loadView({
                queryParameters: tableState,
            });
        } else {
            this.props.loadView({
                wait: false
            });
        }
    }
    getTableName = () => {
        return "vendorRates";
    }
    handleEditClick(data) {
        history.push(`/masterdata/viewallvendorrates/${data.id}`);
    }

    onFetchData = (data, wait) => {
        const queryParameters = data;
        this.props.getActivities({params: queryParameters, wait: wait});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({columns: payload});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({columns: payload});
    }


    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleEditClick, label: 'Edit'}
                    ]
                }
                rowData={data}
            />
        )
    }
    render() {
        return (
            <React.Fragment> 
                <div style={{marginRight: 48, marginTop: 32}}>
                    <DataExportContainer url="api/export/vendorrates"/>
                    <DataTableWithServerSizePagination
                        sortable
                        tableName={this.getTableName()}
                        minWidth={980}
                        customColumnsWidthEnabled={true}
                        loading={this.props.loading}
                        customFilters={{
                            resourceType: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                        isClearable={true}
                                        additionalData="resourceType"
                                        label="Resource Type"
                                        small
                                        width="100%"
                                        minWidth={150}
                                        suggestions={resourceTypeSuggestios}
                                        value={filterValue}
                                        handleChangeSingle={onChange}
                                    />
                                )
                            },
                            assignmentSite: (filterValue, onChange) => {
                                return (
                                    <AutoSelect
                                    isClearable={true}
                                    additionalData="assignmentSite"
                                    label="Assignment Site"
                                    small
                                    width="100%"
                                    minWidth={150}
                                    suggestions={assignmentSitesSuggestions}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                                )
                            }
                        }
                        }
                        count={this.props.count}
                        general={{selectionEnabled: true, canSelectRowProperty: "canSelect", selectionColumnHeader: "Inactive"}}
                        selected={this.props.selectedItems}
                        columns={this.props.columns}
                        onFetchData={this.onFetchData}
                        data={this.props.vendorRates}   
                        actionMenu={this.createActionMenu}
                        onColumnsOrderChanged={this.onColumnOrderChanged}
                        onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                        currentPage={this.props.currentPage}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorRatesViewer);