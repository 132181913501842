import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import months from './constants/monthsLong';
import resourceTypes from './constants/resourceTypes';
import dcStatusesInternal from './constants/dcStatusesInternal';
import dcStatusesExternal from './constants/dcStatusesExternal';
import formatDate from './utils/dateFormatter'
import { Font } from '@react-pdf/renderer'
import {getPureText} from './utils/commentUtils';

Font.registerHyphenationCallback(words => {
    var re = new RegExp('.{1,80}', 'g');
    return words.match(re);
    }
  );

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  label: {
    color: '#a9a9a9',
    fontSize: 10,
    paddingLeft: 12,
    // fontFamily: 'Lato'
  },
  value: {
    color: '#262626',
    fontSize: 12,
    padding: '8px 12px',
    flexDirection:'row',
  //   fontFamily: 'Lato'
},
  sectionHeader: {
      color: '#262626',
      paddingTop: 10,
      paddingBottom: 10,
      fontWeight: 'bold',
      fontSize: 16
  }
});

const Input = (props) => (
    <View wrap={false} style={{flexGrow: props.shouldntGrow ? 0 : 1, flexBasis: 0, paddingBottom: 5}}>
        <View style={styles.label}>
            <Text>{props.label}</Text>
        </View>
        <View style={styles.value}>
            <Text style={{flex: 1, wordBreak: 'break-all', margin: 1}}>{props.value}</Text>
        </View>
    </View>
)
const formatNumber = (value) => {
if (isNaN(value)) {
    return "";
}
return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
// status last modified by actuals submitted by actuals approved by
 
// Create Document Component
const MyDocument = (props) => {
    const isFlexGrowZero = () => {
        return !(props.resourceType === 2 || props.resourceType === 1 || props.resourceType === 5);
    }
    const canEditActuals = () => true;
    const currency = (<Input shouldntGrow={isFlexGrowZero()} label="Currency" disabled width="100%" value={props.currency || ""} className="disabled-form-input"/>);
    const plannedAmountIn = (<Input shouldntGrow={isFlexGrowZero()} label={(props.resourceType !==0 && props.resourceType !== 3 && props.resourceType !== 4) ? "Planned amount" :"planned number of units"} type="number" precision={(props.resourceType === 0 || props.resourceType === 3 || props.resourceType === 4) ? 3: 2} disabled width="100%" value={formatNumber(props.plannedUnitPrice)} className="disabled-form-input"/>);
    //zalezy od review approve
    const actualAmountIn = (<Input shouldntGrow={isFlexGrowZero()} label={(props.resourceType !==0 && props.resourceType !== 3 && props.resourceType !== 4) ? "Actual amount": "Actual number of units"} type="number" precision={props.resourceType === 0 ? 3: 2} disabled={!canEditActuals()} width="100%" onChange={props.actualUnitPriceChanged} value={(props.actualUnitPrice || props.actualUnitPrice === 0) ? formatNumber(props.actualUnitPrice) : ""} className={!canEditActuals() ? "disabled-form-input" : null}/>);

    const profileGrade = (<Input shouldntGrow={isFlexGrowZero()} label="Profile/Grade" disabled width="100%" value={props.grade || ""} className="disabled-form-input"/>);
    const unitRate = (<Input shouldntGrow={isFlexGrowZero()} label="Unit Rate" disabled width="100%" value={(props.unitRate || props.unitRate === 0) ? formatNumber(props.unitRate):""} className="disabled-form-input"/>);
    const perDiemRate = props.assignmentSite === 2 ? (<Input shouldntGrow={isFlexGrowZero()} label="Per diem Rate" disabled width="100%" value={(props.perDiemRate || props.perDiemRate === 0) ?  formatNumber(props.perDiemRate) : ""} className="disabled-form-input"/>) : null;
    const plannedUnitPrice = (<Input shouldntGrow={isFlexGrowZero()} precision={props.resourceType === 0 ? 3: 2} label="Planned number of units" disabled width="100%" value={(props.plannedUnitPrice || props.plannedUnitPrice === 0) ? formatNumber(props.plannedUnitPrice) : ""} className="disabled-form-input"/>);
    //zalezy od review approve
    const actualUnitPrice = (<Input shouldntGrow={isFlexGrowZero()} label="Actual number of units"  precision={props.resourceType === 0 ? 3: 2} type="number" disabled={!canEditActuals()} width="100%" value={(props.actualUnitPrice || props.actualUnitPrice === 0 )? formatNumber(props.actualUnitPrice) : ""} onChange={props.actualUnitPriceChanged} className={!canEditActuals() ? "disabled-form-input" : null}/>);
    const plannedPerDiem =  props.assignmentSite === 2 ? (<Input shouldntGrow={isFlexGrowZero()} precision={props.resourceType === 0 ? 3: 2} label="Planned per diem" disabled width="100%" value={(props.plannedPerDiem || props.plannedPerDiem === 0 )? formatNumber(props.plannedPerDiem) : ""} className="disabled-form-input"/>) : null;
    const actualPerDiem = props.assignmentSite === 2 ? (<Input shouldntGrow={isFlexGrowZero()} label="Actual per diem" type="number" precision={props.resourceType === 0 ? 3: 2} disabled={!canEditActuals()} width="100%" value={(props.actualPerDiem || props.actualPerDiem === 0) ? formatNumber(props.actualPerDiem): ""} onChange={props.actualPerDiemChanged} className={!canEditActuals() ? "disabled-form-input" : null}/>) : null;
    // const overTime = (<div><RadioButtons header="Overtime" disabled={!canEditActuals()} horizontal={true} onChange={props.overtimeChanged} options={[{label:'Yes', value: true}, {label:'No', value: false}]} value={{value: props.overtime}} /></div>);
    const overTime = (<Input shouldntGrow={isFlexGrowZero()} label="Overtime" value={props.overtime ? "Yes" : "No"}></Input>);
    let secondRow = null;
    switch(props.resourceType) {
        case 2:
        case 1:
        case 5: {
            secondRow = (
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    {currency}
                    {plannedAmountIn}
                    {actualAmountIn}
                </View>
            )
            break;
        }
        case 0: {
            secondRow = (
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{display: 'flex', flexDirection:'column', flexGrow:1}}>
                        {profileGrade}
                        {unitRate}
                        {perDiemRate}
                        {currency}
                    </View>
                    <View style={{display: 'flex', flexDirection:'column', flexGrow:1}}>
                        {plannedUnitPrice}
                        {actualUnitPrice}
                        {plannedPerDiem}
                        {actualPerDiem}
                    </View>
                    <View style={{display: 'flex', flexDirection:'column', flexGrow:1}}>
                    {overTime}
                    {props.overtime ? (
                        props.overtimeHours.map((overtime) => {
                            return (
                                <View style={{display: 'flex', flexDirection: 'row'}}>
                                    <Input label="Rate" value={overtime.rate + "%"}/>
                                    <Input label="Number of  hours" value={formatNumber(overtime.numberOfExtraHours)}/>
                                </View>
                            )
                        })
                        ) : null}
                    </View>
                </View>
            );
            break;
        }
        case 4:
        case 3: {
            secondRow = (
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <View style={{display: 'flex', flexDirection:'column', flexGrow:1}}>
                        {profileGrade}
                        {unitRate}
                        {currency}
                    </View>
                    <View style={{display: 'flex', flexDirection:'column', flexGrow:1}}>
                        {plannedUnitPrice}
                        {actualUnitPrice}
                    </View>
                </View>
            )
            break;
        }
        default: {
            break;
        }
    }


    const getTotalPrice = () => {
        let result = 0;
        if (props.actualUnitPrice && !Number.isNaN(parseFloat(props.actualUnitPrice))) {
            result += parseFloat(props.actualUnitPrice) * parseFloat(props.unitRate);
        }
        if (props.assignmentSite === 2 && props.resourceType === 0 && props.actualPerDiem && !Number.isNaN(parseFloat(props.actualPerDiem))) {
            result += parseFloat(props.actualPerDiem) * parseFloat(props.perDiemRate);
        }
        if (props.overtime) {
            props.overtimeHours.forEach((overtime) => {
                if (overtime.numberOfExtraHours && !Number.isNaN(parseFloat(overtime.numberOfExtraHours))) {
                    result += (parseFloat(overtime.rate)/100) * (parseFloat(overtime.numberOfExtraHours)/8) * parseFloat(props.unitRate);
                }
            })
        }
        return result.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
  <Document>
    <Page size="A4" style={styles.page}>
        <View style={{display: 'flex', paddingTop: 10}}>
            <View style={{display: 'flex', flexDirection: 'row', flexGrow: 1, flexBasis: 0}}>
                <Input label="Status" value={props.vendorType === 0 ? dcStatusesExternal[props.status] : dcStatusesInternal[props.status]}></Input>
                <View></View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', flexGrow: 1, flexBasis: 0}}>
                <Input label="Created by" value={props.createdBy}></Input>
                <Input label="Last modified by" value={props.lastModifiedBy}></Input>
            </View>
            <View style={{display: 'flex', flexDirection: 'row', flexGrow: 1, flexBasis: 0}}>
                <Input label="Actuals submitted by" value={props.actualsSubmittedBy}></Input>
                <Input label="Actuals approved by" value={props.actualsApprovedBy}></Input>
            </View>
        </View>

        <View  style={{display: 'flex', paddingTop: 10}}>
            <View style={{display: 'flex', flexDirection:'row'}}>
                <View style={{display: 'flex', flexGrow: 1, flexBasis: 0}}>
                    <View style={styles.sectionHeader}>
                        <Text>Delivery Information</Text>
                    </View>
                    <View>
                        <Input label="Activity name" value={props.activityName}></Input>
                        <Input label="Deliverable" value={props.deliverable}></Input>
                        <Input label="Po number" value={props.poNumber}></Input>
                        <View style={{display: 'flex', flexDirection: 'row', flexGrow: 1, flexBasis: 0}}>
                            <Input label="Year" value={props.year}></Input>
                            <Input label="Month" value={months[props.month -1]}></Input>
                        </View>
                    </View>
                </View>
                <View style={{display: 'flex', flexGrow: 1, flexBasis: 0}}>
                    <View style={styles.sectionHeader}>
                        <Text>Roles</Text>
                    </View>
                    <View>
                        <Input label="Supplier Name" value={props.vendor}></Input>
                        <Input label="Delivery Confirmation Approver" value={props.dcApprover.label}></Input>
                        {props.vendorType === 0 ?
                            <Input label="Supplier Representative" value={props.supplierRepresentative.label}></Input> : null
                        }
                    </View>
                </View>
            </View>
        </View>
        <View style={{display: 'flex', paddingTop: 10}}>
            <View style={styles.sectionHeader}>
                <Text>{resourceTypes[props.resourceType]}</Text>
            </View>
            <View style={{border: '1pt solid #c8cace',
                        borderTopLeftRadius: 16, 
                        borderTopRightRadius: 16, 
                        borderBottomRightRadius: 16,
                        borderBottomLeftRadius: 16,
                        padding: 16
                        }}>
                <View style={{fontSize: 12, paddingBottom: 7, color: '#262626'}}>
                    <Text>DC unique ID {props.dcNumber}</Text>
                </View>
                <View>
                    {secondRow}
                </View>
                <View style={{marginLeft: 'auto', fontSize: 12, color: '#262626'}}>
                    <Text>Total price: {getTotalPrice()} {props.currency}</Text>
                </View>
            </View>

        </View>
        <View style={{display: 'flex', paddingTop: 10}}>
            <View style={styles.sectionHeader}>
                <Text>Comments</Text>
            </View>
            <View style={{display: 'flex'}}>
                {props.comments ? props.comments.map((comment) => {
                    return (<Input 
                                key={comment.date}
                                label={`${comment.userName}, ${formatDate(comment.date)}`}
                                value={comment.message ? getPureText(comment.message).replace(/(\r\n|\n|\r)/gm," "): ""}
                            />
                        )
                }) : null}
            </View>
        </View>
    </Page>
  </Document>
)
};
const CustomDocumentViewerWithLink = (res) => {
    return pdf((<MyDocument {...res}/>)).toBlob();
}
export default CustomDocumentViewerWithLink;