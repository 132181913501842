import React, { Component } from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import * as deliveryConfirmationActions from '../../../store/actions/deliveryConfirmationActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Link from '@material-ui/core/Link';
import TextArea from '../../../components/Input/TextArea';
import withStyles from '@material-ui/core/styles/withStyles'
import AttachmentComponent from '../ChangelogForm/components/AttachmentComponent';
import Input from '../../../components/Input/Input';
import * as deliveryConfirmationFormActions from '../../../store/actions/deliveryConfirmationFormActions';
import * as changelogFormActionsCreators from '../../../store/actions/changeLogFormActions';
import months from '../../../constants/monthsLong';
import { compose } from 'redux';
import history from '../../../history';
import * as rules from '../../../config/Rules';
import { canFunction } from '../../../config/Can';
import { isVendorTypeExternal, isTripplePrecision, isPerDiemVisible } from '../../../utils/typesAssertions';
import {resourceTypesEnums} from '../../../constants/resourceTypes';
import { dcStatusesEnums } from '../../../constants/dcStatuses';

const styles = {
    container: {
        paddingTop: '20px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    },
    secondRow: {
        display: 'flex',
        flexDirection: 'column'
    },
    secondRowTwoColumn: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}

const mapStateToProps = (state) => {
    return {
        vendorType: state.deliveryConfirmationForm.vendorType,
        status: state.deliveryConfirmationForm.status,
        resourceType: state.deliveryConfirmationForm.resourceType,
        plannedUnitPrice: state.deliveryConfirmationForm.plannedUnitPrice,
        actualUnitPrice: state.deliveryConfirmationForm.actualUnitPrice,
        plannedPerDiem: state.deliveryConfirmationForm.plannedPerDiem,
        actualPerDiem: state.deliveryConfirmationForm.actualPerDiem,
        errors: state.deliveryConfirmationForm.errors,
        deliverable: state.deliveryConfirmationForm.deliverable,
        comment: state.deliveryConfirmationForm.comment,
        attachments: state.deliveryConfirmationForm.attachments || [],
        dcNumber: state.deliveryConfirmationForm.dcNumber || '',
        loading: state.deliveryConfirmationForm.loading,
        dcId: state.deliveryConfirmation.selectedDc,
        modalOpen: state.deliveryConfirmation.quickEditModalOpen,
        year: state.deliveryConfirmationForm.year,
        month: state.deliveryConfirmationForm.month,
        assignmentSite: state.deliveryConfirmationForm.assignmentSite,
        isSupplier: state.deliveryConfirmationForm.isSupplier,
        isDcApprover: state.deliveryConfirmationForm.isApprover,
    }
};
const mapDispatchToProps = (dispatch) => ({
    closeModal: (payload) => {
        dispatch(deliveryConfirmationActions.deliveryConfirmationQuickEditModalChanged(false));
        dispatch(deliveryConfirmationFormActions.resetForm());
    },
    actualUnitPriceChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActualUnitPriceChanged(payload.target.value)),
    actualPerDiemChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActualPerDiemChanged(payload.target.value)),     
    commentChanged: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormCommentChanged(payload)),
    downloadAttachment: (payload) =>      
        dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload)),
    attachmentAdded: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormAttachmentAdded(payload)),        
    attachmentDeleted: (payload) =>
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormAttachmentDeleted(payload)),
    deliveryConfirmationQuickEditModalSubmitted: (payload) =>
        dispatch(deliveryConfirmationActions.deliveryConfirmationQuickEditModalSubmitted())

});
const canEditActuals = () => {
    return true;
} 
class GenerateDCsModal extends Component {    
    canEditActuals = () => {

    }
    canEditAttachments = () => {
        if (canFunction('', rules.EDIT_DC_ATTACHMENTS)) {
            return true;
        }
        if (isVendorTypeExternal(this.props.vendorType)) {
            if ((this.props.status === dcStatusesEnums.ToBeFilledIn || this.props.status === dcStatusesEnums.ToBeCorrected || this.props.status === dcStatusesEnums.ToBeApproved)) {
                return this.props.isSupplier;
            }
        }
        return false;
    }
    canEditComments = () => {
        if (canFunction('', rules.EDIT_DC_ATTACHMENTS)) {
            return true;
        }
        if (isVendorTypeExternal(this.props.vendorType)) {
            if (this.props.status === dcStatusesEnums.ToBeFilledIn || this.props.status === dcStatusesEnums.ToBeCorrected || this.props.status === dcStatusesEnums.ToBeApproved) {
                return this.props.isSupplier;
            }
        }
        return false;
    }
    redirectToDetailView = () => {
        this.props.closeModal();
        history.push(history.location.pathname + "/" + this.props.dcId);
    }

    render() {
        const plannedAmountIn = (<Input label="planned number of units" type="number" precision={isTripplePrecision(this.props.resourceType) ? 3: 2} disabled width="100%" value={this.props.plannedUnitPrice || ""} className="disabled-form-input"/>);
        const actualAmountIn = (<Input errorText={this.props.errors.actualUnitPrice} precision={isTripplePrecision(this.props.resourceType) ? 3: 2} label="Actual number of units" type="number" disabled={!canEditActuals()} width="100%" onChange={this.props.actualUnitPriceChanged} value={this.props.actualUnitPrice || ""} className={!canEditActuals() ? "disabled-form-input" : null}/>);
        const plannedPerDiem = isPerDiemVisible(this.props.resourceType, this.props.assignmentSite) ? (<Input precision={isTripplePrecision(this.props.resourceType) ? 3: 2} label="Planned per diem" disabled width="100%" value={this.props.plannedPerDiem || ""} className="disabled-form-input"/>) : null;
        const actualPerDiem = isPerDiemVisible(this.props.resourceType, this.props.assignmentSite) ? (<Input errorText={this.props.errors.actualPerDiem} label="Actual per diem" type="number" precision={isTripplePrecision(this.props.resourceType) ? 3: 2} disabled={!canEditActuals()} width="100%" value={this.props.actualPerDiem || ""} onChange={this.props.actualPerDiemChanged} className={!canEditActuals() ? "disabled-form-input" : null}/>) : null;
        let secondRow;
        switch(this.props.resourceType) {
            case resourceTypesEnums["T&E"]:
            case resourceTypesEnums.FXP:
            case resourceTypesEnums.FMS: {
                secondRow = (
                    <div className={this.props.classes.secondRow}>
                        {plannedAmountIn}
                        {actualAmountIn}
                    </div>
                )
                break;
            }
            case resourceTypesEnums["T&M"]: {
                secondRow = (
                    <div className={this.props.classes.secondRowTwoColumn}>
                        <div className={this.props.classes.secondRow} style={{flexBasis: '45%'}}>
                            {plannedAmountIn}
                            {actualAmountIn}
                        </div>
                        <div className={this.props.classes.secondRow} style={{flexBasis: '45%'}}>
                            {plannedPerDiem}
                            {actualPerDiem}
                        </div>
                    </div>
                );
                break;
            }
            case resourceTypesEnums.IT:
            case resourceTypesEnums.Business: {
                secondRow = (
                    <div className={this.props.classes.secondRow}>
                            {plannedAmountIn}
                            {actualAmountIn}
                    </div>
                )
                break;
            }
            default: {
                break;
            }
        }
        
        return (
            <Dialog 
                open={this.props.modalOpen} 
                onClose={this.props.closeModal} 
                title="Quick edit"
                cancelButtonText="CANCEL"
                submitButtonText="SAVE"
                onSubmit={this.props.deliveryConfirmationQuickEditModalSubmitted}
            >
            <div style={{position: 'relative', width: 552}}>
                <LoadingSpinner loading={this.props.loading}/>
                <div style={{paddingBottom: 10}}>You are editing Delivery Confirmation {this.props.dcNumber} for<span style={{fontWeight: 'bold'}}> {this.props.deliverable} <span style={{fontWeight:'normal'}}>for</span> {this.props.month ? months[this.props.month -1] : ""} {this.props.year}.</span></div>
                <div>To view all data, please switch to <Link onClick={this.redirectToDetailView}>detailed view</Link>.</div>
                <div style={{paddingTop: 10, paddingBottom: 10}}>
                {secondRow}
                </div>
                <div>
                    <TextArea
                        label="Comment"
                        errorText={this.props.errors.comment}
                        disabled={this.props.submitting || !this.canEditComments()}
                        value={this.props.comment}
                        onChange={this.props.commentChanged}
                        className={this.props.classes.textArea} 
                    ></TextArea>
                </div>
                <div style={{paddingRight: 8, marginTop: 10, marginBottom: 10}}>
                <AttachmentComponent 
                    errorText={this.props.errors.attachments}
                    attachments={this.props.attachments} 
                    disabled={!this.canEditAttachments()}
                    attachmentRemoved={this.props.attachmentDeleted}
                    attachmentAdded={this.props.attachmentAdded}
                    downloadAttachment={this.props.downloadAttachment}
                />
            </div> 
            </div>
        </Dialog>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(GenerateDCsModal);