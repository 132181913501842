import * as actionTypes from '../actions/actionTypes';

const initialState = {
    comment: '',
    comments: [],
    submitting: false
}
const changelogDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CHANGELOG_FORM_REST_FORM: {
            return {
                ...initialState
            }
        }
        case actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENTS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENTS_FAILED: {
            return {
                ...state,
                comments: []
            }
        }
        case actionTypes.GET_BACKOFFICE_CHANGELOG_COMMENT_FINISHED: {
            return {
                ...state,
                comments: action.payload
            }
        }
        case actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_STARTED: {
            return {
                ...state,
                submitting: true,
            }
        }
        case actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_FAILED: {
            return {
                ...state,
                comment: '',
                submitting: false,
            }
        }
        case actionTypes.POST_BACKOFFICE_CHANGELOG_COMMENT_FINISHED: {
            return {
                ...state,
                comment: '',
                submitting: false,
            }
        }
        case actionTypes.BACKOFFICE_CHANGELOG_COMMENT_CHANGED: {
            return {
                ...state,
                comment: action.payload.target.value
            }
        }
        default: {
            return state
        }
    }
}

export default changelogDataReducer;