import React, { Component } from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import * as resourceModelActions from '../../../store/actions/resourceModelActions';
import AutoSelect from '../../../components/Input/AutoSelect';
import { connect } from 'react-redux';
import months from '../../../constants/months';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const currentYear = new Date().getFullYear();
const years = [
    {
        label: currentYear -1,
        value: currentYear -1,
    }, {
        label: currentYear,
        value: currentYear
    }, {
        label: currentYear + 1,
        value: currentYear + 1
    }
]
const mapStateToProps = (state) => {
    return {
        modalLoading: state.resourceModel.modalLoading,
        generateDcModalOpen: state.resourceModel.generateDcModalOpen,
        generateDcModalData: state.resourceModel.generateDcModalData || {},
        generateDcModalSelectedMonths: state.resourceModel.generateDcModalSelectedMonths || {},
        shouldConfirmFutureMonths : state.resourceModel.shouldConfirmFutureMonths,
        verifiedFutureMonths: state.resourceModel.verifiedFutureMonths,
        futureMonthsToBeValidated: state.resourceModel.futureMonthsToBeValidated || {},
        selectedYear: state.resourceModel.selectedYear || '',
        generateDcModalDcToBeGeneratedCount: state.resourceModel.generateDcModalDcToBeGeneratedCount
    }
};
const mapDispatchToProps = (dispatch) => ({
    selectedMonthsChanged: (payload) => {
        dispatch(resourceModelActions.resourceModalSelectedMonthsChanged(payload));
    },
    closeModal: (payload) => {
        dispatch(resourceModelActions.resourceModalGenerateDcModalClosed(payload));
    },
    submitResourceModalStarted: (payload) => {
        dispatch(resourceModelActions.submitResourceModalStarted(payload));
    },
    resourceModalVerifiedFutureMonthsChanged: (payload) => {
        dispatch(resourceModelActions.resourceModalVerifiedFutureMonthsChanged(payload.target.checked))
    },
    resourceModalSelectedYearChanged: (payload) => {
        dispatch(resourceModelActions.resourceModalSelectedYearChanged(payload));
    }
});

class GenerateDCsModal extends Component {

    
    selectedMonthsChanged = (event) => {
        const generateDcModalSelectedMonthsCopy = {...this.props.generateDcModalSelectedMonths};
        generateDcModalSelectedMonthsCopy[event.target.name] = event.target.checked;

        this.props.selectedMonthsChanged(generateDcModalSelectedMonthsCopy);
    }

    render() {
        return (
            <Dialog 
                open={this.props.generateDcModalOpen} 
                onClose={this.props.closeModal} 
                title="Generate DCs"
                cancelButtonText="CANCEL"
                submitButtonText="GENERATE"
                onSubmit={this.props.submitResourceModalStarted}
                submitDisabled={this.props.generateDcModalDcToBeGeneratedCount === 0}
            >
            <div>
                <LoadingSpinner loading={this.props.modalLoading}/>
                {this.props.shouldConfirmFutureMonths ? 
                <div>
                    <div style={{lineHeight: '24px', paddingBottom: 8}}>Are you sure you want to generate Delivery Confirmations also for future months? You can change your choice now.</div>
                    <div style={{marginLeft: -12, display: 'flex', paddingBottom: 40, flexDirection: 'column'}}>
                        {Object.keys(this.props.futureMonthsToBeValidated).map((month) => {
                            return (
                                <span key={month}>
                                    <Checkbox 
                                        color="primary" 
                                        name={month}
                                        onChange={this.selectedMonthsChanged}
                                        checked={this.props.generateDcModalSelectedMonths[month] || false}
                                    >
                                    </Checkbox>
                                    {month} {this.props.selectedYear.value}
                                </span>
                            )
                        })}
                    </div>
                    <div style={{marginLeft: -12}}>
                        <Checkbox color="primary" onChange={this.props.resourceModalVerifiedFutureMonthsChanged} checked={this.props.verifiedFutureMonths || false}>
                        </Checkbox> 
                            Yes, I want to generate Delivery Confirmations for future months. 
                    </div>
                </div> :
                <div>
                    <div style={{paddingBottom: 10}}>
                        <AutoSelect 
                            label="Year" 
                            value={this.props.selectedYear} 
                            suggestions={years}
                            handleChangeSingle={this.props.resourceModalSelectedYearChanged}
                        />
                    </div>
                    <div style={{lineHeight: '24px', paddingBottom: 8}}>You are about to generate Delivery Confirmation for {this.props.generateDcModalDcToBeGeneratedCount} item{this.props.generateDcModalDcToBeGeneratedCount !== 1 ? "s.":". "}</div>
                    <div style={{lineHeight: '24px'}}>Which months do you want to generate DCs for?</div>
                    <div style={{display: 'flex', marginLeft: -12}}>
                        <div style={{display: 'flex', flexDirection:'column', flexBasis:'50%', alignItems:'flex-start'}}>
                            {months.slice(0,6).map((month, index) => {
                                return (
                                    <span key={month} style={{fontWeight: 'bold'}}>
                                        <Tooltip title={(this.props.generateDcModalData[month] && this.props.generateDcModalData[month].title) ? this.props.generateDcModalData[month].title : ""} placement="right">
                                            <span>
                                                <Checkbox 
                                                    color="primary" 
                                                    name={month}
                                                    disabled={(this.props.generateDcModalData[month] && this.props.generateDcModalData[month].title) ? this.props.generateDcModalData[month].disabled : false}
                                                    onChange={this.selectedMonthsChanged}
                                                    checked={this.props.generateDcModalSelectedMonths[month] || false}
                                                >
                                                </Checkbox>
                                            </span>
                                        </Tooltip> {month} {this.props.selectedYear.value}
                                    </span>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection:'column', flexBasis: '50%', alignItems:'flex-start'}}>
                            {months.slice(6).map((month, index) => {
                                return (
                                    <span key={month} style={{fontWeight: 'bold'}}>
                                        <Tooltip title={(this.props.generateDcModalData[month] && this.props.generateDcModalData[month].title) ? this.props.generateDcModalData[month].title : ""} placement="right">
                                            <span>
                                                <Checkbox 
                                                    color="primary" 
                                                    name={month}
                                                    disabled={(this.props.generateDcModalData[month] && this.props.generateDcModalData[month].title) ? this.props.generateDcModalData[month].disabled : false}
                                                    onChange={this.selectedMonthsChanged}
                                                    checked={this.props.generateDcModalSelectedMonths[month] || false}
                                                >
                                                </Checkbox>
                                            </span>
                                        </Tooltip> {month} {this.props.selectedYear.value}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </div>
                }
            </div>
        </Dialog>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GenerateDCsModal);