import React, { Component } from 'react';
import AsyncSelect from '../../../components/Input/AsyncSelect';
import axios from '../../../axios';
import {regionTypeEnums} from '../../../constants/regionTypes'

class ActivitiesPicker extends Component {
    state = {
        page: {},
    }
    loadActivities = async (input, loadedOptions, {page}) => {
        try {
            if (input.length >= 1) {
                const response = await axios.get('api/wbs/activities', {params: {page: this.state.page[input] || 1, regionType: regionTypeEnums.Market, pageable: true, activity: input}})
                this.setState({
                    page: {[input]: response.data.currentPage}
                })
                const options = response.data.items.map((res) => {
                    return {
                        ...res,
                        label: res.name,
                        value: res.id
                    }
                })

                return {
                    options: options,
                    hasMore: response.data.currentPage !== response.data.pageCount,
                    additional: {
                        page: page + 1,
                    },
                }
            } else {
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                }
            }
        } catch(error) {
            this.setState({
                page: {}
            })
        }
    }
    render() {
        return (
            <AsyncSelect 
                loadOptions={this.loadActivities}
                errorText={this.props.errorText} 
                value={this.props.activity}
                additional={{
                    page: 1
                }}
                handleChangeSingle={this.props.handleChangeSingle}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                label={this.props.label}
                width={this.props.width ? this.props.width : "100%"}
            />
        )
    }
}
export default ActivitiesPicker;