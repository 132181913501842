import {saveAs} from 'file-saver';
import axios, { axiosForFileUploadFunction } from '../axios';
import { put } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import axios2 from 'axios';

function stripNameOfInvalidChars(name) {
    var forbiddenChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*'];
    for(var i = 0;i < forbiddenChars.length; i++) {
      name = name.replace(forbiddenChars[i], '_');
    }
    return name;
  }
export function* downloadFile(action) {
    try {
        yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: true});
        if (action.payload.size) {
            yield saveAs(action.payload);
            yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: false});
            return;
        }
        const fileName = action.payload.name;
        const url = yield axios.get('s3/documents/' + encodeURIComponent(fileName));
        const res = yield axios2.get(url.data, {
            responseType: 'blob',
            timeout: 30000,
          });
        saveAs(res.data, stripNameOfInvalidChars(fileName));
        yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: false});
    } catch(error) {
        yield put({type: actionTypes.ATTACHMENT_LOADING_CHANGED, payload: false});
        yield put(errorDialogActions.openErrorDialog("There was an error while downloading file. Please try again later"));
    }
}
export function* attachFiles(currentAttachments) {
    let newAttachments = [];
    for (let i = 0; i < currentAttachments.length; i++) {
        if (!currentAttachments[parseInt(i)].id) {
            const dataForFileUpload = yield axios.get('s3/documents/uploadUrl/' + encodeURIComponent(currentAttachments[parseInt(i)].name));
            const axiosInstanceForFileUpload = axiosForFileUploadFunction(dataForFileUpload.data.uploadUrl);
            yield axiosInstanceForFileUpload.put('', currentAttachments[parseInt(i)]);
            newAttachments.push({name: dataForFileUpload.data.fileName});
        } else {
            newAttachments.push(currentAttachments[parseInt(i)]);
        }
    }
    return newAttachments;
}

export function* deleteFiles(currentAttachments, oldAttachments) {
    const attachmentsToBeDeleted = [];
    if (oldAttachments.length) {
        for (let i = 0; i < oldAttachments.length; i++) {
            const isPresent = currentAttachments.find((attachment) => {
                return attachment.id === oldAttachments[parseInt(i)].id
            });
            if (!isPresent) {
                attachmentsToBeDeleted.push(oldAttachments[parseInt(i)]);
            }
        }
    }
    for (let i = 0; i < attachmentsToBeDeleted.length; i++) {
            yield axios.delete('s3/documents/'+ encodeURIComponent(attachmentsToBeDeleted[parseInt(i)].name));
    }
}