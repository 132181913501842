import React, { memo, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AutoSelect from '../../../../components/Input/AutoSelectWithDataOnChange';
import Input from '../../../../components/Input/Input';
import ResourceItemTable from './ResourceItemTable';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import * as rules from '../../../../config/Rules';
import Button from '@material-ui/core/Button';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import {canFunction} from '../../../../config/Can';
import history from '../../../../history';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { canEditWithResource, canEditWithResourceAndRequestor, isDisabledWithResource } from '../../../../utils/changelogFormEditPrivileges';
import { isPerDiemVisible, isAssignmentSiteVisible, isLocationVisible, isGradeVisible, isUnitPriceDisabledOnChangelogForm, isTimeAndMaterial } from '../../../../utils/typesAssertions'


moment.tz.setDefault('UTC');
class UTCUtils extends MomentUtils {
    format(value, formatString) {
    if (formatString === 'MMMM Do') {
        formatString = 'DD/MM/YYYY'
    }
        return moment(value)
            .utc()
            .format(formatString)
    }
}

const useStyles = makeStyles({
    circleNumber: {
        position: 'relative',
        top: 12,
        left: 12,
       width: 20,
       height: 20,
       color: '#c8cace',
       textAlign: 'center'
      },
    wrapper: {
        border: '1px solid #c8cace',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: -15,
        '& > *': {
            flexBasis: 350,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    inputPadding: {
        display: 'none'
    },
    secondRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -15,
        '& > *': {
            flexBasis: 128,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    totalPriceWrapper: {
        paddingTop: 5
    },
    totalPriceLabel: {
        color: '#595959',
        fontSize: 16
    },
    totalPriceValue: {
        color: '#262626',
        fontSize: 16,
        paddingLeft: 12
    }  
});

const ResourceItem = (props) => {
    const canEdit = (rule) => {
        return canEditWithResource(props, rule);
    }
    const canEditWithChangelogOwner = (rule) => {
        return canEditWithResourceAndRequestor(props, rule)
    }
    const isDisabled = (rule, ignoreResource, ignoreChangelogOwner) => {
        return isDisabledWithResource(props, rule, ignoreResource, ignoreChangelogOwner)
    }
    
    const [values, setValues] = React.useState({
        mouseOver: false,
        name:''
      }); 
    const changeName = (e) => {
        setValues({...values, ...{name: e.target.value}});
    }
    useEffect(() => {
        setValues({...values, ...{name: props.resource.name ? props.resource.name : ""}})
    }, [props.resource]);

    const classes = useStyles();
    let secondRow = null;
    const location = canEdit(rules.EDIT_CHANGELOG_LOCATION) ? 
        (<AutoSelect className={props.changes.location ? "input-updated": ""} tooltipText={props.changes.location} width={128} label="Location" value={props.resource.location} suggestions={props.resource.locations} handleChangeSingle={(e)=>{props.resourceLocationChanged(props.index, e)}}/>)
    :   (<Input width={128} label="Location" value={props.resource.location && props.resource.location.label ? props.resource.location.label : ''} disabled className={props.changes.location ? "input-updated": "disabled-form-input"} tooltipText={props.changes.location}/>);

    const assignmentSite = canEdit(rules.EDIT_CHANGELOG_ASSIGNMENT_SITE) ? (<AutoSelect className={props.changes.assignmentSite ? "input-updated": ""} tooltipText={props.changes.assignmentSite} width={128}  label="Assignment Site" value={props.resource.assignmentSite} suggestions={props.resource.assignmentSites} handleChangeSingle={(e) => {props.resourceAssignmentSiteChanged(props.index, e)}}/>)
    : (<Input width={128} label="Assignment Site" value={props.resource.assignmentSite ? props.resource.assignmentSite.label : ""} disabled className={props.changes.assignmentSite ? "input-updated": "disabled-form-input"} tooltipText={props.changes.assignmentSite} />);
    const profileGrade = canEdit(rules.EDIT_CHANGELOG_GRADE) ? (<AutoSelect className={props.changes.grade ? "input-updated": ""} tooltipText={props.changes.grade} width={128} label="Profile/Grade" value={props.resource.grade} suggestions={props.resource.grades} handleChangeSingle={(e) => {props.resourceGradeChanged(props.index, e)}}/>)
    : (<Input width={128} label="Profile/Grade" value={props.resource.grade && props.resource.grade.label  ? props.resource.grade.label : ""} disabled className={props.changes.grade ? "input-updated": "disabled-form-input"} tooltipText={props.changes.grade}/>);
    const unitPrice = canEdit(rules.EDIT_CHANGELOG_UNIT_PRICE) ? (<AutoSelect className={props.changes.unitPrice !== undefined ? "input-updated": ""} tooltipText={props.changes.unitPrice} width={128} label="Unit Price" value={props.resource.unitPrice} suggestions={props.resource.unitPrices} handleChangeSingle={(e) => {props.resourceUnitPriceChanged(props.index, e)}}/>)
    : (<Input width={128} label="Unit Price" className={props.changes.unitPrice !== undefined ? "input-updated": "disabled-form-input"} tooltipText={props.changes.unitPrice} value={props.resource.unitPrice && props.resource.unitPrice.label ? props.resource.unitPrice.label : ""} disabled />);
    const perDiem = (!(props.resource.assignmentSite && props.resource.resourceType  && isPerDiemVisible(props.resource.resourceType.value, props.resource.assignmentSite.value)) ? null :
    canEdit(rules.EDIT_CHANGELOG_PER_DIEM) ? <AutoSelect width={128} label="Per Diem" className={props.changes.perDiem !== undefined ? "input-updated": ""} tooltipText={props.changes.perDiem} value={props.resource.perDiem} suggestions={props.resource.perDiemRates} handleChangeSingle={(e) => {props.resourcePerDiemChanged(props.index, e)}}/> 
    : (<Input width={128} label="Per Diem" className={props.changes.perDiem !== undefined ? "input-updated": "disabled-form-input"} tooltipText={props.changes.perDiem} value={props.resource.perDiem && props.resource.perDiem.label ? props.resource.perDiem.label : ""} disabled />));
    const currency = canEdit(rules.EDIT_CHANGELOG_CURRENCY) ? (<AutoSelect width={128} label="Currency" className={props.changes.currency !== undefined ? "input-updated": ""} tooltipText={props.changes.currency} value={props.resource.currency} suggestions={props.resource.currencies} handleChangeSingle={(e) => {props.resourceCurrencyChanged(props.index, e)}}/>)
    : (<Input width={128} label="Currency" className={props.changes.currency !== undefined ? "input-updated": "disabled-form-input"} tooltipText={props.changes.currency} value={props.resource.currency && props.resource.currency.label ? props.resource.currency.label : ""} disabled />);
    if (props.resource.resourceType && props.resource.resourceType.label) {
        secondRow = (
            <div className={classes.secondRow}>
                {isLocationVisible(props.resource.resourceType.value) ? location : null}
                {isAssignmentSiteVisible(props.resource.resourceType.value) ? assignmentSite : null}
                {isGradeVisible(props.resource.resourceType.value) ? profileGrade : null}
                {unitPrice}
                {isPerDiemVisible(props.resource.resourceType.value, props.resource.assignmentSite ? props.resource.assignmentSite.value : null) ? perDiem : null}
                {currency}
            </div>
        )
    }

    function getTotalPrice() {
        let totalMonthDays = 0;
        if (props.resource.monthAmount) {
            Object.keys(props.resource.monthAmount).forEach(key => {
                if (Number.isNaN(props.resource.monthAmount[key])) {
                    return;
                }
                totalMonthDays += (parseFloat(props.resource.monthAmount[key])* parseFloat(props.resource.unitPrice.value));
                if (props.resource.resourceType && props.resource.assignmentSite && isPerDiemVisible(props.resource.resourceType.value, props.resource.assignmentSite.value) && props.resource.perDiem) {
                    totalMonthDays += (parseFloat(props.resource.monthAmount[key]) * parseFloat(props.resource.perDiem.value));
                }
            })
        }

        return <NumberFormat 
            value={totalMonthDays}
            displayType={'text'}
            thousandSeparator 
            decimalScale={2}
            fixedDecimalScale={2}
            allowedDecimalSeparators={[".",","]}
            isNumericString
            />;
    }
    function getTotalAmountOfManDays() {
        let totalMonthDays = 0;
        if (props.resource.monthAmount) {
            Object.keys(props.resource.monthAmount).forEach(key => {
                if (Number.isNaN(props.resource.monthAmount[key])) {
                    return;
                }
                totalMonthDays += parseFloat(props.resource.monthAmount[key])
            })
        }
        return parseFloat(totalMonthDays.toFixed(2)).toLocaleString();
    }
    function navigateToResourceModel(id) {
        history.push('/resourcemodel/'+id);
    }
    return (
        <div className={classes.wrapper}
            ref={props.resource.ref}
            style={{
                border:`1px solid ${props.changes.status=== "added" || props.resource.prepayment ? "#39acff": props.changes.status ==='deleted' ? "":  '#c8cace'}`,
                background: props.changes.status === "added" ? '#e1f5fe': props.changes.status === 'deleted' ? "" : "#fff",}}
        >
        <div style={{
                        position:'relative',
                        top:-14,
                        left:-6,
                        background: 'transparent',
                        textAlign: 'center',
                        borderRadius: '50%',
                        color: "#a9a9a9",
                        width: '1.1em',
                        height: '1.1em',
                        padding: '0.25em',
                        boxSizing: 'content-box',
                        lineHeight: '16px',
                        border: '0.1em solid #a9a9a9',
                        fontSize: '16px',
                        display: 'flex',
                        justifyContent: 'center'
                        }}
                    >
                        {parseInt(props.index) + 1}
        </div>
        {props.resource.prepayment &&
            <div style={{
                position:'absolute',
                width:180,
                height: 25,
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px',
                top:-1,
                right:'calc(50% - 90px)',
                color: '#fff',
                border:'1px solid #39acff',
                background: '#39acff',
                textAlign: 'center',
                fontWeight: '600'
                }}
            >
                Prepayment Required
            </div>}
        {canEditWithChangelogOwner(rules.EDIT_CHANGELOG_DELETE_RESOURCE) && props.index !==0 &&
            <div style={{
                position:'absolute',
                width:40, 
                height: 40, 
                top:-20, 
                right:-20, 
                borderRadius:"50%", 
                border:'1px solid #c8cace', 
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
                }} 
                onClick={() => {props.resourceDeleted(props.index)}}
            >
                <DeleteOutlined color="primary"/>
            </div>}
            {canFunction('', rules.VIEW_TAGS_PICKLIST) ?
                <div className={classes.row}>
                    <AutoSelect 
                        label="Tags" 
                        isMulti
                        width="100%" 
                        disabled={(!props.editMode && props.editing)}
                        value={props.resource.tags}
                        suggestions={props.changelogResourceTagsSuggestions} 
                        handleChangeSingle={(e) => {props.resourceTagsChanged(props.index, e)}}
                        className={props.changes.tags ? "input-updated": ""}
                        tooltipText={props.changes.tags}
                    />
                </div> 
                : 
                null
            }
            <div className={classes.row}>
                {canEdit(rules.EDIT_CHANGELOG_RESOURCE_TYPE) ? 
                    <AutoSelect helpProperty="resource type" className={props.changes.resourceType !== undefined ? "input-updated": ""} tooltipText={props.changes.resourceType} errorText={props.errors && props.errors['resourceType'] ? props.errors['resourceType'] : ''} label="Resource Type" value={props.resource.resourceType} suggestions={props.resource.resourceTypes} handleChangeSingle={(e) => {props.resourceTypeChanged(props.index, e)}}/>
                : (<Input width={128} label="Resource Type" value={props.resource.resourceType.label} disabled className={props.changes.resourceType !== undefined ? "input-updated": "disabled-form-input"} tooltipText={props.changes.resourceType} />) }
                        <Tooltip interactive title={props.changes.startDate || ""} placement="top">
                            <div style={{width: '100%'}}>
                            <MuiPickersUtilsProvider utils={UTCUtils} moment={moment}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    InputProps={{
                                        disabled: true,
                                        className: props.changes.startDate !== undefined ? "input-updated": "",
                                        disableUnderline: true,
                                    }}
                                    disabled={(!props.year || !props.year.value) || isDisabled(rules.EDIT_CHANGELOG_START_DATE, true)}
                                    variant="inline"
                                    label="Start Date"
                                    autoOk
                                    className={props.errors && props.errors['startDate'] ? 'error' : ''}
                                    emptyLabel={(!props.year || !props.year.value) ? "Pick first year of activity" : " "}
                                    helperText={props.errors && props.errors['startDate'] ? props.errors['startDate'] : ''}
                                    error={props.errors && props.errors['startDate'] ? true : false}
                                    value={props.resource.startDate ? props.resource.startDate : null}
                                    onChange={(e) => {props.resourceStartDateChanged(props.index, moment.utc(e.format('YYYY-MM-DD')))}}
                                    minDate={`${props.year.value}-01-01`}
                                    maxDate={`${props.year.value}-12-31`}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            </div>
                        </Tooltip>
                <Input 
                disabled={isDisabled(rules.EDIT_CHANGELOG_RESOURCE_NAME, true)}
                name={"Name" + props.index}
                id={"Name" + props.index}
                autoComplete="nope"
                className={props.changes.name !== undefined ? "input-updated": isDisabled(rules.EDIT_CHANGELOG_RESOURCE_NAME, true) ? "disabled-form-input" :''} tooltipText={props.changes.name}
                errorText={props.errors && props.errors['name'] ? props.errors['name'] : ''}  label="Name" value={values.name} onChange={changeName} onBlur={(e) => {props.resourceNameChanged(props.index, e)}}/>
                {canEdit(rules.EDIT_CHANGELOG_EXPENSE_TYPE)?
                <AutoSelect 
                    label="Expense type"
                    errorText={props.errors && props.errors['expenseType'] ? props.errors['expenseType'] : ''} 
                    className={props.changes.expenseType !== undefined ? "input-updated": ''} 
                    tooltipText={props.changes.expenseType}
                    suggestions={props.expenseTypes}
                    value={props.resource.expenseType}
                    handleChangeSingle={(e) => {props.expenseTypeChanged(props.index, e)}}
                />:
                <Input 
                    label="Expense type"
                    value={props.resource.expenseType && props.resource.expenseType.label ? props.resource.expenseType.label : ""}
                    disabled
                    className={props.changes.expenseType !== undefined ? "input-updated": "disabled-form-input"} 
                    tooltipText={props.changes.expenseType}
                />
                }
            </div>
                {secondRow}
            <div>
                <ResourceItemTable 
                    changes={props.changes.values}
                    disabledInputs={isDisabled(rules.EDIT_CHANGELOG_MONTH_VALUES, true)}
                    minWidth={840}
                    resourceType={props.resource.resourceType ? props.resource.resourceType.value : ""}
                    id={props.resource.id}
                    monthStart={props.resource.startDate ? new Date(props.resource.startDate).getUTCMonth() + 1 : 13}
                    onMonthValueChanged={(e) => {props.resourceMonthAmountChanged(props.index, e)}}
                    value={props.resource.monthAmount}
                    poNumbers={props.resource.poNumbers}
                    dcs={props.resource.dcs}
                />
            </div>
            <div>
                <Tooltip interactive title={props.changes.prepayment || ""} placement="top">
                    <span style={props.changes.prepayment !== undefined ? {border: '1px solid #39acff',
                                    backgroundColor: '#e1f5fe',
                                    borderRadius: 16,
                                    padding: 5,
                                    display: 'inline-block'
                                    } : {}}>
                        <Checkbox disabled={isDisabled(rules.EDIT_CHANGELOG_RESOURCE_PREPAYMENT, true)} color="primary" checked={props.resource.prepayment || false} onChange={(e)=>{props.prepaymentChanged(props.index, e.target.checked)}}/> Prepayment required
                    </span>
                </Tooltip>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                {props.resource.currency &&
                <div className={classes.totalPriceWrapper}>
                    <span className={classes.totalPriceLabel}>
                        Total price:
                    </span>
                    <span className={classes.totalPriceValue}>
                    {getTotalPrice()} { props.resource.currency.label}
                    </span>
                </div>
                }
                {props.resource.resourceType && isTimeAndMaterial(props.resource.resourceType.value) &&
                    <div className={classes.totalPriceWrapper}>
                        <span className={classes.totalPriceLabel}>
                            Total number of man-days: 
                        </span>
                        <span className={classes.totalPriceValue}>
                        {getTotalAmountOfManDays()} 
                        </span>
                    </div>
                }
                </div>
                <div style={{marginLeft: 'auto', paddingTop:5, display: 'flex', flexDirection: 'column'}}>
                    <Button color="primary" 
                            component="span"
                            className="removed-text-padding"
                            onClick={props.changelogFormDuplicateResourceItem.bind('', props.resource.key)}
                            disabled={isDisabled(rules.CAN_DUPLICATE_CHANGELOG_RESOURCE, true) || props.additionDisabled}
                            >
                            <FileCopy />
                            DUPLICATE RESOURCE
                    </Button>
                    {canFunction(props.role, rules.IS_BACKOFFICE_OR_ADMIN) && props.resource.resourceModelId ?
                        <div style={{marginTop: 10}}>
                        <Button color="primary" 
                                component="span"
                                className="removed-text-padding"
                                onClick={()=>{navigateToResourceModel(props.resource.resourceModelId)}}
                                >
                                <AssignmentIndOutlinedIcon />
                                Resource Model
                        </Button></div> : null
                    }
                </div>
            </div>
        </div>
    )
}
export default memo(ResourceItem);