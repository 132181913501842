import { put, takeEvery, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import axios, { axiosForFileUploadFunction } from '../axios';
import axios2 from 'axios';
import * as dataExportActions from '../store/actions/exportData';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import { saveAs } from 'file-saver';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import excelTypes from '../constants/excelTypes';

export function* dataExportSaga(action) {
    try {
        yield put(dataExportActions.loadingChanged({loading: true, url: action.payload}));
        const response = yield axios.get(action.payload);
        const instance = axiosForFileUploadFunction(response.data);
        const file = yield instance.get('')
        // file.data = "sep=," + '\n' + file.data;
        var csvFile = new Blob([file.data], {type: 'text/csv'});
        saveAs(csvFile, 'export.csv');
        yield put(dataExportActions.fileExportFinished());
        yield put(dataExportActions.loadingChanged({loading: false, url: action.payload}));
    } catch(error) {
        yield put(dataExportActions.fileExportFailed());
        yield put(dataExportActions.loadingChanged({loading: false, url: action.payload}));
        yield put(errorDialogActions.openErrorDialog("There was an error while generating CSV. Please try again later"));
    }
}
const formatValue = (value, type) => {
    if (type === excelTypes.Date) {
        if (!value) {
            return null;
        }
        let date = new Date(value.split("Z")[0])
        return new Date( Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    } else {
        return value;
    }
}
export function* exportExcelFileSaga(action) {
    try {
        yield put(dataExportActions.loadingChanged({loading: true, url: action.payload.url}));
        let tableState = yield select((store) => store.table.parsedFilters[action.payload.tableName]);
        const url = yield axios.get(action.payload.url, {params: encodeParamObject(tableState)});
        const response = yield axios2.get(url.data);
        const Excel = require('exceljs');
        var workbook = new Excel.Workbook();
        var sheet = workbook.addWorksheet('Test');
        if (response.data && Array.isArray(response.data) && response.data.length) {
            sheet.columns = Object.keys(response.data[0]).map((key) => {
            let obj = {style:{}}
            if (action.payload.fieldsTypes[key]) {
                obj.style.numFmt = action.payload.fieldsTypes[key]
            }
                return {
                    header: key,
                    key: key,
                    width: 20,
                    ...obj
                }
            });
            response.data.forEach((row) => {
                const rowFormatted = Object.keys(row).reduce((accumulator, currentValue) => {
                    return {
                        ...accumulator,
                        [currentValue]: formatValue(row[currentValue], action.payload.fieldsTypes[currentValue])
                    }
                }, {});
            sheet.addRow(rowFormatted);
            })
        }
        const getDate = (date) => {
            function pad2(n) {  // always returns a string
                return (n < 10 ? '0' : '') + n;
                
            }
            return date.getFullYear() +
            pad2(date.getMonth() + 1) + 
            pad2(date.getDate()) +
            pad2(date.getHours()) +
            pad2(date.getMinutes()) +
            pad2(date.getSeconds());

        }
        const buffer = yield workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer.buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, `${getDate(new Date())}_${action.payload.fileName}.xlsx`);
        yield put(dataExportActions.loadingChanged({loading: false, url: action.payload.url}));
    } catch (error) {
        yield put(dataExportActions.fileExportFailed());
        yield put(dataExportActions.loadingChanged({loading: false, url: action.payload.url}));
        yield put(errorDialogActions.openErrorDialog("There was an error while generating Excel file. Please try again later"));
    }
}
const sagas =  [
    takeEvery(actionTypes.DATA_EXPORT_STARTED, dataExportSaga),
    takeEvery(actionTypes.DATA_TO_EXCEL_STARTED, exportExcelFileSaga)
]
export default sagas