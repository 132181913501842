import * as actionTypes from '../actions/actionTypes';

const initialState = {
    vendorRates: {},
    loading: false,
    columns: []
}
const vendorRatesViewerReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.VENDOR_RATE_LIST_CHANGE_LOADING_SPINNER: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.INITIALIZE_VENDOR_RATES_VIEWER_FINISHED: {
            return {
                ...state,
                loading: false
            }
        }
        // case actionTypes.GET_VENDOR_RATES_LIST_STARTED: {
        //     return {
        //         ...initialState,
        //         loading: true,
        //     }
        // }
        case actionTypes.GET_VENDOR_RATES_LIST_FINISHED: {
            const selectedItems = {};
            action.payload.items.forEach((item) => {
                if (item.isDeleted) {
                    selectedItems[item.id] = true;
                }
            });
            return {
                ...state,
                loading: false,
                vendorRates: action.payload,
                selectedItems: selectedItems
            }
        }
        case actionTypes.GET_VENDOR_RATES_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                vendorRates: {}
            }
        }
        case actionTypes.UPDATE_VENDOR_RATES_COLUMNS_STARTED:
        case actionTypes.UPDATE_VENDOR_RATES_COLUMNS_FAILED:    
        case actionTypes.UPDATE_VENDOR_RATES_COLUMNS_FINISHED:
        case actionTypes.GET_VENDOR_RATES_COLUMNS_FAILED:  
        case actionTypes.GET_VENDOR_RATES_COLUMNS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_VENDOR_RATES_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default vendorRatesViewerReducer;