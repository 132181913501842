import React, { Component } from 'react';
import DataTable from '../../../../components/DataTable/DataTable2'
import DataExportContainer from '../../../../containers/DataExport/DataExportContainer';
export default class StaticPickList extends Component {

    state = {
        filtered: {
        }
    }

    onFilteredChanged = (event) => {
        const propName = event.target.name;
        const value = event.target.value;
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (row[key].toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) === -1) {
                    isValid = false;
                }
            })
            return isValid;
        })
    }

    render() {
        const filteredData = this.filterData(this.props.data);
        return (
            <div>
                <DataExportContainer url={"api/export/"+this.props.namespace}/>
                <DataTable
                    {...this.props}
                    filtered={this.state.filtered}
                    general={this.props.general ? this.props.general : {}}
                    onFilteredChanged={this.onFilteredChanged}
                    data={filteredData}  
                />
            </div>
        );
    }
}