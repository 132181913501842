import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Input from '../../../components/Input/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ResourceTable from './ResourceTable';
import assignmentSites from '../../../constants/assignmentSites';
import resourceTypes from '../../../constants/resourceTypes';
import Button from '@material-ui/core/Button';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import history from '../../../history';
import { isPerDiemVisible, isAssignmentSiteVisible, isLocationVisible, isGradeVisible } from '../../../utils/typesAssertions'; 
import { resourceTypesEnums } from '../../../constants/resourceTypes';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
    wrapper: {
        border: '1px solid #c8cace',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: -15,
        '& > *': {
            flexBasis: 272,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }

    },
    inputPadding: {
        display: 'none'
    },
    secondRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -15,
        '& > *': {
            flexBasis: 128,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: 15,
            paddingBottom: 16
        }
    },
    totalPriceWrapper: {
        paddingLeft: 16,
    },
    totalPriceLabel: {
        color: '#595959',
        fontSize: 16
    },
    totalPriceValue: {
        color: '#262626',
        fontSize: 16,
        paddingLeft: 12
    },
    thirdRowWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }  
});
const defaultString = ""
const Resource = (props) => {

    const classes = useStyles();
    let secondRow = null;
    const location = (
        <Input 
            width={128} 
            label="Location" 
            value={props.resource.location}  
            disabled 
            className={props.changes && props.changes.location ? "input-updated ":"disabled-form-input"}
            tooltipText={props.changes && props.changes.location}
        />);
    const assignmentSite = (
        <Input 
            width={128} 
            label="Assignment Site" 
            value={assignmentSites[props.resource.assignmentSite]}  
            disabled 
            className={props.changes && props.changes.assignmentSite ? "input-updated ":"disabled-form-input"}
            tooltipText={props.changes && props.changes.assignmentSite}
        />);
    const profileGrade = (
        <Input 
            width={128} 
            label="Profile/Grade" 
            value={props.resource.grade}  
            disabled 
            className={props.changes && props.changes.grade ? "input-updated ":"disabled-form-input"}
            tooltipText={props.changes && props.changes.grade}
        />);
    const unitPrice = (
    <Input 
        width={128} 
        label="Unit Price" 
        value={props.resource.unitPrice}  
        disabled 
        className={props.changes && props.changes.unitPrice ? "input-updated ":"disabled-form-input"}
        tooltipText={props.changes && props.changes.unitPrice}
    />);
    const perDiem = !isPerDiemVisible(props.resource.resourceType, props.resource.assignmentSite) ? null :
    (<Input 
        width={128} 
        label="Per Diem" 
        value={props.resource.perDiem} 
        disabled 
        className={props.changes && props.changes.perDiem ? "input-updated ":"disabled-form-input"}
        tooltipText={props.changes && props.changes.perDiem}
    />);
    const unitPriceDisabled = (
    <Input 
        width={128} 
        label="Unit Price" 
        value={props.resource.unitPrice}  
        disabled={true}
        className={props.changes && props.changes.unitPrice ? "input-updated ":"disabled-form-input"}
        tooltipText={props.changes && props.changes.unitPrice}
    />)
    const currency = (
    <Input 
        width={128} 
        label="Currency" 
        value={props.resource.currency} 
        disabled 
        className={props.changes && props.changes.currency ? "input-updated ":"disabled-form-input"}
        tooltipText={props.changes && props.changes.currency}
    />);
    if (props.resource.resourceType !== undefined) {
        secondRow = (
            <div className={classes.secondRow}>
                {isLocationVisible(props.resource.resourceType) ? location : null}
                {isAssignmentSiteVisible(props.resource.resourceType) ? assignmentSite : null}
                {isGradeVisible(props.resource.resourceType) ? profileGrade : null}
                {unitPrice}
                {isPerDiemVisible(props.resource.resourceType, props.resource.assignmentSite) ? perDiem : null}
                {currency}
            </div>
        )
    }

    function getTotalPrice() {
        let totalPrice = 0;
        if (props.resource.actualUnitPrice) {
            Object.keys(props.resource.actualUnitPrice).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.actualUnitPrice[key]))) {
                    return;
                }
                totalPrice += (parseFloat(props.resource.actualUnitPrice[key])* parseFloat(props.resource.unitPrice));
            })
        }
        if (props.resource.actualPerDiem) {
            Object.keys(props.resource.actualPerDiem).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.actualPerDiem[key]))) {
                    return;
                }
                if (isPerDiemVisible(props.resource.resourceType, props.resource.assignmentSite)) {
                    totalPrice += (parseFloat(props.resource.actualPerDiem[key])* parseFloat(props.resource.perDiem));
                }
            })
        }
        if (props.resource.overtime125) {
            Object.keys(props.resource.overtime125).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.overtime125[key]))) {
                    return;
                }
                totalPrice += (parseFloat(props.resource.overtime125[key])* parseFloat(props.resource.unitPrice))*(1.25/8);
            })
        }
        if (props.resource.overtime150) {
            Object.keys(props.resource.overtime150).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.overtime150[key]))) {
                    return;
                }
                totalPrice += (parseFloat(props.resource.overtime150[key])* parseFloat(props.resource.unitPrice))*(1.5/8);
            })
        }
        if (props.resource.overtime175) {
            Object.keys(props.resource.overtime175).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.overtime175[key]))) {
                    return;
                }
                totalPrice += (parseFloat(props.resource.overtime175[key])* parseFloat(props.resource.unitPrice))*(1.75/8);
            })
        }
        if (props.resource.overtime200) {
            Object.keys(props.resource.overtime200).forEach(key => {
                if (Number.isNaN(parseFloat(props.resource.overtime200[key]))) {
                    return;
                }
                totalPrice += (parseFloat(props.resource.overtime200[key])* parseFloat(props.resource.unitPrice))*(2/8);
            })
        }
        return  <NumberFormat 
            value={totalPrice}
            displayType={'text'}
            thousandSeparator 
            decimalScale={2}
            fixedDecimalScale={2}
            allowedDecimalSeparators={[".",","]}
            isNumericString
            />;
    }
    const navigateToChangelog = () => {
        history.push('/changelogs/viewAll/'+ props.resource.changelogId);
    }
    return (
        <div className={classes.wrapper}>
            <div className={classes.row}>
                <Input 
                    width={128} 
                    label="Resource Type" 
                    value={resourceTypes[props.resource.resourceType] || ''} 
                    disabled 
                    className={props.changes && props.changes.resourceType ? "input-updated ":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.resourceType}
                />
                <Input 
                    disabled 
                    value={props.resource.name || ''} 
                    label="Name"
                    className={props.changes && props.changes.name ? "input-updated ":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.name}
                />
                <Input 
                    disabled 
                    value={props.resource.expenseType || ''} 
                    label="Expense Type"
                    className={props.changes && props.changes.expenseType ? "input-updated ":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.expenseType}
                />
            </div>
                {secondRow}
            <div>
                <ResourceTable
                    reviewMode={props.reviewMode}
                    changes={props.changes}
                    resourceType={props.resource.resourceType}
                    minWidth={840} 
                    generateDc={props.resource.generateDc}
                    resourceModelFormPoChanged={props.resourceModelFormPoChanged}
                    resourceModelFormActualsChanged={props.resourceModelFormActualsChanged}
                    planned={props.resource.planned}
                    actualUnitPrice={props.resource.actualUnitPrice}
                    actualPerDiem={props.resource.actualPerDiem}
                    overtime125={props.resource.overtime125}
                    overtime150={props.resource.overtime150}
                    overtime175={props.resource.overtime175}
                    overtime200={props.resource.overtime200}
                    perDiemEditable={isPerDiemVisible(props.resource.resourceType, props.resource.assignmentSite)}
                    overtimeEditable={props.resource.resourceType === resourceTypesEnums["T&M"]}
                    po={props.resource.po}
                    dcLinks={props.resource.dcLinks}
                />
            </div>
            <div className={classes.thirdRowWrapper}>
                <span>
                    {props.reviewMode ? 
                    <Input 
                        disabled 
                        width={128}
                        label="Generate DC"
                        value={props.resource.generateDc ? "Yes" : "No"} 
                        className={props.changes && props.changes.generateDc ? "input-updated" : defaultString}
                        tooltipText={props.changes && props.changes.generateDc}
                    /> :
                    <React.Fragment>
                        <Tooltip interactive title={props.changes.generateDc || defaultString} placement="top">
                            <span style={props.changes.generateDc !== undefined ? {border: '1px solid #39acff',
                                            backgroundColor: '#e1f5fe',
                                            borderRadius: 16,
                                            padding: 5,
                                            display: 'inline-block'
                                            } : {}}>
                                <Checkbox color="primary" checked={props.resource.generateDc !== undefined ? props.resource.generateDc : true} onChange={props.resourceModelFormGenerateDcChanged}/>
                                <span>Generate DCs</span>
                            </span>
                        </Tooltip>
                    </React.Fragment>
                    }
                </span>
                <div className={classes.totalPriceWrapper}>
                    <span className={classes.totalPriceLabel}>
                        Total price
                    </span>
                    <span className={classes.totalPriceValue}>
                    {getTotalPrice()} {props.resource.currency}
                    </span>
                </div>
            </div>
            {props.reviewMode ? null :
            <div style={{marginLeft: 'auto', paddingTop:5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <div style={{marginTop: 10}}>
                    <Button color="primary" 
                            component="span"
                            className="removed-text-padding"
                            onClick={navigateToChangelog}
                            >
                            <AssignmentIndOutlinedIcon />
                            Open Changelog
                    </Button>
                </div>
            </div>
            }
        </div>
    )
}
export default Resource;