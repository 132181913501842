import React, { Component } from 'react'
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import {canFunction} from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
import history from '../../../../history';
import { shouldComponentUpdate } from '../../../../store/reducers/ChangeLogFormReducer';

const mapStateToProps = (state) => {
    return {
        isDraft: state.changelogForm.isDraft,
        comment: state.changelogForm.comment,
        submitting: state.changelogForm.submitting,
        isEditing: state.changelogData.changelog && state.changelogData.changelog.id,
        isChangelogOwner: canFunction('', rules.IS_REQUESTOR) && !canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        editMode: state.changelogForm.editMode,
        canGeneratePdf: state.changelogForm.id && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && !state.changelogForm.isDraft,
        shouldUpdate: shouldComponentUpdate(state.changelogForm)
        // isChangelogOwner: state.changelogForm.approver && state.changelogForm.approver.value ?
        //     state.changelogForm.approver.value === state.user.username : false,
    }
};

const mapDispatchToProps = (dispatch) => ({
    deleteDraft: () =>
        dispatch(changelogFormActionsCreators.deleteChangelogDraftStarted()),
    submitFormStarted: () => 
        dispatch(changelogFormActionsCreators.submitChangelogFormStarted()),
    submitEditStarted: () =>
        dispatch(changelogFormActionsCreators.submitEditChangelogFormStarted()),
    resetForm: () => 
        dispatch(changelogFormActionsCreators.resetForm()),
    onEditClicked: () =>
        dispatch(changelogFormActionsCreators.changelogFormEditModeChanged(true)),
    createChangelogPdf: () =>
        dispatch(changelogFormActionsCreators.createChangelogPdf())
    }
);

class FormSubmitPanelContainer extends Component {
    shouldComponentUpdate(nextProps) {
        return this.props.shouldUpdate || this.props.editMode !== nextProps.editMode;
    }
    cancelForm = () => {
        this.props.resetForm();
        if (history.location.pathname.indexOf('/changelogs/viewAll') !== -1 && canFunction('', rules.NAVIGATE_CHANGELOG_VIEWALL)) {
            history.push('/changelogs/viewAll');
        } else {
            history.push('/changelogs/my');
        }
    }
    submitForm = () => {
        if (this.props.isEditing) {
            this.props.submitEditStarted();
        } else {
            this.props.submitFormStarted();
        }
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 100}}>
                <Fab variant="extended" className="form-submit-panel form-cancel" onClick={this.cancelForm}>Cancel</Fab>
                {(canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && !this.props.editMode) && this.props.editing && !this.props.isDraft ?
                    <div style={{paddingLeft: 10, paddingRight: 10}}><Fab variant="extended" className="form-submit-panel form-submit" onClick={this.props.onEditClicked}>Edit</Fab></div> : null
                }
                {(canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && this.props.editMode) || this.props.isChangelogOwner || !this.props.editing || this.props.isDraft ?
                    <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-submit" onClick={this.submitForm}>Submit</Fab>
                : null
                }
                {this.props.isDraft ? 
                <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-cancel" onClick={this.props.deleteDraft}>Delete</Fab> : null
                }
                {this.props.canGeneratePdf ? 
                <Fab disabled={this.props.submitting} variant="extended" className="form-submit-panel form-cancel form-extended-button" onClick={this.props.createChangelogPdf}>Generate pdf</Fab> : null
                }
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitPanelContainer);