import * as actionTypes from '../actions/actionTypes';
import { createSelector } from 'reselect';

const initialState = {
    resourceModel: {
    },
    loading: false,
    submitting: false,
    showChanges: false,
    changes: {}
}

const resourceModelReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.RESOURCE_MODEL_FORM_LATEST_CHANGES_CHANGED: {
            return {
                ...state,
                changes: action.payload
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_SHOW_CHANGES_CHANGED: {
            return {
                ...state,
                showChanges: action.payload
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_HISTORY_FINISHED: {
            return {
                ...state,
                history: action.payload
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_HISTORY_FAILED: {
            return {
                ...state,
                history: []
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_SUBMIT_STARTED: {
            return {
                ...state,
                submitting: true,
                loading: true
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_SUBMIT_FAILED: {
            return {
                ...state,
                submitting: false,
                loading: false
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_SUBMIT_FINISHED: {
            return {
                ...state,
                submitting: false,
                loading: false
            }
        }
        case actionTypes.RESET_RESOURCE_MODEL_FORM: {
            return {
                ...initialState
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.GET_RESOURCE_MODEL_FINISHED: {
            return {
                ...state,
                loading: false,
                resourceModel: action.payload
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_PO_CHANGED: {
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    po: action.payload
                }
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_ACTUALS_CHANGED: {
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    [action.payload.name]: action.payload.value
                }
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_ATTACHMENT_ADDED: {
            const newAttachments = [...state.resourceModel.attachments];
            const file = action.payload.target.files[0];
            file.key = Math.random();
            newAttachments.push(file);
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    attachments: newAttachments
                }
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_ATTACHMENT_DELETED: {
            const deleteFile = state.resourceModel.attachments.find((file) => {
                return file.key === action.payload.key;
            })
            const deleteIndex = state.resourceModel.attachments.indexOf(deleteFile);
            const newAttachments = [
                ...state.resourceModel.attachments.slice(0, deleteIndex),
                ...state.resourceModel.attachments.slice(deleteIndex + 1)
            ];
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    attachments: newAttachments
                }
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_COMMENT_CHANGED: {
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    comment: action.payload
                }
            }
        }
        case actionTypes.RESOURCE_MODEL_FORM_GENERATE_DC_CHANGED: {
            return {
                ...state,
                resourceModel: {
                    ...state.resourceModel,
                    generateDc: action.payload
                }
            }
        }

        default: {
            return state
        }
    }
}
export default resourceModelReducer;

export const getChanges = (state) => state.resourceModelForm.changes
export const getShowChanges = (state) => state.resourceModelForm.showChanges
export const getRmChanges = createSelector([getShowChanges, getChanges], (showChanges, changes) => {
    return showChanges ? changes : {}
});