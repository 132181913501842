import * as actionTypes from './actionTypes';

export const showDrawer =  (myParam) => {
    return {type: actionTypes.SHOW_DRAWER, payload: myParam};
};
export const hideDrawer =  (myParam) => {
    return {type: actionTypes.HIDE_DRAWER, payload: myParam};
};
export const toggleDrawer =  (myParam) => {
    return {type: actionTypes.TOGGLE_DRAWER, payload: myParam};
};
export const setNavbarStatusComment =  (myParam) => {
    return {type: actionTypes.SET_NAVBAR_STATUS_COMMENT, payload: myParam};
};
export const changelogTabSelectionChanged =  (myParam) => {
    return {type: actionTypes.CHANGELOG_TAB_SELECTION_CHANGED, payload: myParam};
};
