import { put, takeEvery, takeLatest, delay, select, all } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as changelogsActionCreators from '../store/actions/regionalChangelogActions';
import axios from '../axios';
import { statuses } from '../constants/changelogStatuses';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import history from '../history';
import { getUserDomains, getDomains } from './domainsSagas';
import * as userActions from '../store/actions/User';
import {formatDateWithoutHours} from '../utils/dateFormatter';
import * as infoDialogActions from '../store/actions/infoDialogActions';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import * as backgroundTaskActions from '../store/actions/backgroundTask';

function* getChangelogColumnsConfig(action) {
    //action payload -> domainName
    let columns = yield localStorage.getItem(action.payload.tableName + process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(changelogsActionCreators.getRegionalChangelogColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(changelogsActionCreators.getRegionalChangelogColumnsFailed())
    }
}
function* modalVisiblitySaga(action) {
    try {
        if (action.payload) {
            yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalLoadingChanged(true));
            yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalDisabledChanged(true));
            let response = yield axios.get('api/budgetPlans/notifications/status');
            let modalMessage = "";
            if (response.data.currentAmount > 0) {
                modalMessage = `There ${response.data.currentAmount === 1 ? "is" :"are"} ${response.data.currentAmount} new notification${response.data.currentAmount === 1 ? "" :"s"}. Do you want to send ${response.data.currentAmount === 1 ? "it" : "them"}?`;
                yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalDisabledChanged(false));
            } else if (response.data.previousAmount > 0) {
                modalMessage = `There ${response.data.previousAmount === 1 ? "is" :"are"} ${response.data.previousAmount} notification${response.data.previousAmount === 1 ? "" :"s"} that ${response.data.previousAmount === 1 ? "has" :"have"} already been sent. Do you want to send ${response.data.previousAmount === 1 ? "it" : "them"} again?`;
                yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalDisabledChanged(false));
            } else {
                modalMessage = "There are no notifications to send."
            }
            yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalQuestionChanged(modalMessage));
            yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalLoadingChanged(false));
        } else {
            yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalQuestionChanged(""))
        }
    } catch(error) {
        yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalLoadingChanged(false));
        yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalQuestionChanged("There was an error while fetching data about notifications availability."))
    }
}
function* sendNotifications(actions) {
    try {
        yield put(changelogsActionCreators.sendBudgetPlanNotificationsModalVisibilityChanged(false));
        yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: true, key: 'notifications', message: "Sending notifications."}});
        yield put(backgroundTaskActions.backgroundTaskVisibilityChanged(true));
        yield put(backgroundTaskActions.backgroundTaskMessageChanged("Sending Notifications"));
        yield axios.post('api/budgetPlans/notifications/send');
        yield put(infoDialogActions.openInfoDialog("Notifications has been sent successfully"));
        yield put(backgroundTaskActions.backgroundTaskVisibilityChanged(false));
        yield put(backgroundTaskActions.backgroundTaskMessageChanged(""));
        yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: false, message: ""}});
        yield put(changelogsActionCreators.sendBudgetPlanNotificationsFinished())
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while sending notifications."));
        yield put(backgroundTaskActions.backgroundTaskVisibilityChanged(false));
        yield put(backgroundTaskActions.backgroundTaskMessageChanged(""));
        yield put(changelogsActionCreators.sendBudgetPlanNotificationsFailed())
        yield put({type: actionTypes.SNACKBAR_CHANGED, payload: {open: false, message: ""}});
    }
}
function* saveChangeLogColumnsConfig(action) {
    yield localStorage.setItem(action.payload.tableName + process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getChangelogColumnsConfig(action)
}

function* getChangeLogs(action) {
    try {
        const userOnly = action.payload.userOnly;
        const planByCostCenter = yield select((store) => store.regionalChangelogs.planByCostCenter.value);
        let filterUrl = "api/";
        if (userOnly) {
            filterUrl +="user/"
        }
        if (planByCostCenter) {
            filterUrl += "budgetPlans/costCenter";
        } else {
            filterUrl +="budgetPlans/activity"
        }
        let params = action.payload.params;    
        if (action.payload.wait) {
            yield delay(500);
        }
        const data = yield axios.get(filterUrl, {params: encodeParamObject(params)});
        let changelogs = [];
        if (data.data.items.length !== 0) {
            changelogs = data.data.items.map((changelog) => {
                return {
                    ...changelog,
                    hitRate: changelog.hitRate ? changelog.hitRate + " %" : "",
                    lastImportedDate: changelog.lastImportedDate ? formatDateWithoutHours(changelog.lastImportedDate) : "",
                    totalBudget: formatNumber(changelog.totalBudget),
                    planYTD: formatNumber(changelog.planYTD),
                    actualsYTD: formatNumber(changelog.actualsYTD),
                    planYTG:  formatNumber(changelog.planYTG)
                }
            });
        }
        const dataCopy = {
            ...data.data
        }
        dataCopy.items = changelogs;
        yield put(changelogsActionCreators.getRegionalChangelogsFinished(dataCopy));
    } catch (error) {
        yield put(changelogsActionCreators.getRegionalChangelogsFailed(error));
    }
}
const formatNumber = (number) => {
    if (number === null || number === undefined) {
        return ""
    }
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
function* initializeChangelogListView(action) {
    try {
        yield* getChangeLogs(action);
    } catch(e) {
        // yield put(changelogsActionCreators.changelogListViewLoadingSpinnerChanged(false));
    } 
}

export default [
    takeLatest(actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_MODAL_VISIBILITY_CHANGED, modalVisiblitySaga),
    takeLatest(actionTypes.SEND_BUDGET_PLAN_NOTIFICATIONS_STARTED, sendNotifications),
    takeLatest(actionTypes.INITIALIZE_REGIONAL_CHANGELOG_LIST, initializeChangelogListView),
    takeEvery(actionTypes.GET_REGIONAL_CHANGELOG_COLUMNS_STARTED, getChangelogColumnsConfig),
    takeEvery(actionTypes.UPDATE_REGIONAL_CHANGELOG_COLUMNS, saveChangeLogColumnsConfig),
    takeLatest(actionTypes.GET_REGIONAL_CHANGELOGS_STARTED, getChangeLogs),
];