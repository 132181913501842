import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    resourceTypes: [],
}

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_STARTED: {
            return {
                ...state,
                loading: true,
            }
        }
        case actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_FAILED: {
            return {
                ...state,
                resourceTypes: [],
                loading: false
            }
        }
        case actionTypes.GET_RESOURCE_TYPE_BY_VENDOR_FINISHED: {
            return {
                ...state,
                resourceTypes: action.payload,
                loading: false
            }
        }
        case actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_FAILED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.EDIT_RESOURCE_TYPE_BY_VENDOR_FINISHED: {
            return {
                ...state,
                loading: false,
            }
        }
        default: {
            return state;
        }
    }
}
export default UserReducer;