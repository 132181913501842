import * as actionTypes from './actionTypes';

export const fileExportStarted =  (myParam) => {
    return {type: actionTypes.DATA_EXPORT_STARTED, payload: myParam};
};
export const fileExportFailed =  (myParam) => {
    return {type: actionTypes.DATA_EXPORT_FAILED, payload: myParam};
};
export const fileExportFinished =  (myParam) => {
    return {type: actionTypes.DATA_EXPORT_FINISHED, payload: myParam};
};
export const loadingChanged = (myParam) => {
    return {type: actionTypes.EXPORT_DATA_LOADING_CHANGED, payload: myParam};
}
export const dataToExcelStarted = (myParam) => {
    return {type: actionTypes.DATA_TO_EXCEL_STARTED, payload: myParam};
}
export const dataToExcelFinished = (myParam) => {
    return {type: actionTypes.DATA_TO_EXCEL_FINISHED, payload: myParam};
}
export const dataToExcelFailed = (myParam) => {
    return {type: actionTypes.DATA_TO_EXCEL_FAILED, payload: myParam};
}