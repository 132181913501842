import { put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as changelogsActionCreators from '../store/actions/Changelogs';
import axios from '../axios';
import { statuses } from '../constants/changelogStatuses';
import { encodeParamObject } from '../utils/queryParamsFormatter';
import history from '../history';
import { getUserDomains, getDomains } from './domainsSagas';
import * as userActions from '../store/actions/User';
import formatDate from '../utils/dateFormatter';

function* getChangelogColumnsConfig(action) {
    //action payload -> domainName
    let columns = yield localStorage.getItem(action.payload.domain + "-"+action.payload.role +"-changelog-column-config"+process.env.REACT_APP_APP_VERSION);
    if (columns) {
        yield put(changelogsActionCreators.getChangelogColumnsFinished(JSON.parse(columns)))
    } else {
        yield put(changelogsActionCreators.getChangelogColumnsFailed())
    }
}

function* saveChangeLogColumnsConfig(action) {
    yield localStorage.setItem(action.payload.domain+ "-"+action.payload.role +"-changelog-column-config"+process.env.REACT_APP_APP_VERSION, action.payload.columns);
    yield* getChangelogColumnsConfig(action)
    yield put(changelogsActionCreators.updateChangelogColumnsFinished())
}

function* getChangeLogs(action) {
    try {
        let domains;
        let filterUrl;
        if (history.location.pathname.indexOf("my") !== -1) {
            filterUrl = "changelog-user";
            domains = yield select((store) => store.user.userDomains['my'])
        } else {
            filterUrl = "changelog";
            domains = yield select((store) => store.domains.domains)
        }
        let isDomainPresent = false;
        if (action.payload.domain) {
            isDomainPresent = domains.find((domain) => {
                return domain.id === action.payload.domain.id
            });
        }
        let params = action.payload.params;
        let selectedDomain = isDomainPresent || {name: 'view all', id: 'view all'}
        if (!isDomainPresent) {
            yield put(userActions.selectDomain(selectedDomain));
            params = yield select((store) => store.table.parsedFilters[`${filterUrl}${selectedDomain.id !== 'view all' ? "-" + selectedDomain.id : ""}`]);
            if (!params) {
                params = {};
            }
        }        
        let url = `${action.payload.userOnly ? "/user": "" }/changelogs${selectedDomain.name !=="view all" ? `/domain/${selectedDomain.id}` : ""}`;
        const {...other} = params
        if (action.payload.wait) {
            yield delay(500);
        }
        yield put(changelogsActionCreators.changelogListViewLoadingSpinnerChanged(true));
        const data = yield axios.get(`api${url}`, {params: encodeParamObject(other)});
        let changelogs = [];
        if (data.data.items.length !== 0) {
            changelogs = data.data.items.map((changelog) => {
                 return {
                    ...changelog,
                    approver: changelog.approver ? changelog.approver.name : "",
                    isDraft: changelog.isDraft ? "Yes" : "No",
                    status:  changelog.status !== null ? statuses[changelog.status].label : "",
                    lastModifiedDate: changelog.lastModifiedDate ? formatDate(changelog.lastModifiedDate) : "",
                    lastModifiedBy: changelog.lastModifiedBy ? `${changelog.lastModifiedBy.userEmail} (${changelog.lastModifiedBy.login})` : "",
                    createdBy: changelog.createdBy ? `${changelog.createdBy.userEmail} (${changelog.createdBy.login})` : "",
                     createdDate: changelog.createdDate ? formatDate(changelog.createdDate) : "",
                     leanIxName: changelog.leanixdto ? changelog.leanixdto.leanIxName : "",
                     leanIxUniqueId: changelog.leanixdto ? changelog.leanixdto.leanIxUniqueId : "",
                     eppmName: changelog.eppmdto ? changelog.eppmdto.eppmName : "",
                     eppmUniqueId: changelog.eppmdto ? changelog.eppmdto.eppmUniqueId : "",


                }
            });
        }
        const dataCopy = {
            ...data.data
        }
        dataCopy.items = changelogs;
        yield put(changelogsActionCreators.getChangelogsFinished(dataCopy));
    } catch (error) {
        yield put(changelogsActionCreators.getChangelogColumnsFailed(error));
    }
}
function* initializeChangelogListView(action) {
    try {
        yield put(changelogsActionCreators.changelogListViewLoadingSpinnerChanged(true));
        if (history.location.pathname.indexOf("my") !== -1) {
            yield* getUserDomains();
        } else {
            yield* getDomains();
        }
        yield* getChangeLogs(action);
    } catch(e) {
        yield put(changelogsActionCreators.changelogListViewLoadingSpinnerChanged(false));
    } 
}

export default [
    takeLatest(actionTypes.INITIALIZE_CHANGELOG_LIST_VIEW_STARTED, initializeChangelogListView),
    takeEvery(actionTypes.GET_CHANGELOG_COLUMNS_STARTED, getChangelogColumnsConfig),
    takeEvery(actionTypes.UPDATE_CHANGELOG_COLUMNS_STARTED, saveChangeLogColumnsConfig),
    takeLatest(actionTypes.GET_CHANGELOGS_STARTED, getChangeLogs),
];