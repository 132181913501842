import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '../../../components/Input/Input';
import * as actionCreators from '../../../store/actions/tags';
import Button from '@material-ui/core/Button';


const tagTypes = [
    {label: 'Budget plan', value:'isForBudgetPlan'},
    {label: 'Budget plan line', value:'isForBudgetPlanLine'},
    {label: 'Changelog', value:'isForChangelog'},
    {label: 'Changelog resource', value:'isForChangelogResource'},
    {label: 'Delivery confirmation', value:'isForDeliveryConfirmation'}
]

const mapStateToProps = (state) => {
    return {
        tagTypes: tagTypes,
        data: state.tags.items,
        loading: state.tags.loading,
        newItem: state.tags.newItem,
        selectedItems: state.tags.selectedItems,
        itemsBeingEdited: state.tags.itemsBeingEditedMap,
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    handleNewItemChanged: (e) =>
        dispatch(actionCreators.tagPickListNewItemChanged(e)),
    handleAddItemRequest: (e) => {
        dispatch(actionCreators.addTagStarted(e))
    },  
    handleEditItemRequest: (item) => {
        dispatch(actionCreators.editTagStarted(item))
    },
    handleEditItemChanged: (e) => {
        dispatch(actionCreators.tagPicklistEditItemChanged(e))
    },
    handleEditIsDeleted: (object) => {
        dispatch(actionCreators.editTagStarted(object))
    },
});

class TagsPickList extends Component {

    state = {
        filtered: {
        }
    }

    handleEditItem = (event, prop) => {
        if (this.checkIfShouldEditField(event, prop)) {
            const item = this.props.data.find((item) => {
                return item.id === parseInt(event.target.name)
            })
            const newItem = {
                ...item,
                [prop]: event.target.value
            }
            this.props.handleEditItem(newItem);
        }
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.data.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.handleEditIsDeleted(copy)
    }
    onFilteredChanged = (event, property) => {
        let propName;
        let value;
        if (!event) {
            const newFiltered  = {
                ...this.state.filtered
            }
            delete newFiltered[property];
            this.setState({
                filtered: newFiltered
            })
            return;
        }
        if (!event.target) {
            propName = property;
            value= event;
        } else {
            propName = event.target.name;
            value = event.target.value;
        }
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (!isValid) {
                    return;
                }
                if (key === 'value') {
                    isValid = row[key].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) !== -1;
                } else {
                    if (row[key] === undefined && this.state.filtered[key].value === false) {
                        isValid = true
                    } else {
                        isValid = row[key] === this.state.filtered[key].value;
                    }   
                }
            });
            return isValid;
        });
    }

    createColumns = () => {
        return [{
            header: 'Tag Name',
            dataProperty: 'value',
            isVisible: true,
            width: 290
        },
        ...this.props.tagTypes.map((tagType) => {
            return {
                header: tagType.label,
                dataProperty: tagType.value,
                isVisible: true,
                width: 140,
            }
        }),
        {
            header: "Action",
            dataProperty: "action",
            isVisible: true,
            width: 140,
            disabledFiltering: true
        },
        ]
    }
    createCustomRowCells = () => {
        const customRowCells = {
            value: (data, id, row) => {
                if (this.props.itemsBeingEdited[id] === undefined || this.props.itemsBeingEdited[id] === null) {
                return (<div style={{fontSize: '16px', textAlign: 'center'}}>{data}</div>)
                }
                return (
                    <Input
                        inputProps={{name: id}}
                        value={this.props.itemsBeingEdited[id] !== undefined && this.props.itemsBeingEdited[id] !== null ? this.props.itemsBeingEdited[id].value: data || ""}
                        onChange={(e) => {this.props.handleEditItemChanged({...this.props.itemsBeingEdited[id], value: e.target.value})}}
                    />
                )
            },
            action: (data, id, row) => {
                if (this.props.itemsBeingEdited[id] && this.props.itemsBeingEdited[id].canBeSaved) {
                    return (
                        <React.Fragment>
                            <Button color="primary" onClick={(e) => {this.props.handleEditItemChanged({...this.props.itemsBeingEdited[id], canBeSaved: false})}}>Back</Button>
                            <Button color="primary" onClick={this.props.handleEditItemRequest.bind(this, this.props.itemsBeingEdited[id])}  disabled={this.props.loading}>Save</Button>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <Button color="primary" onClick={(e) => {this.props.handleEditItemChanged({...row, canBeSaved: true})}}>Edit</Button>
                    )
                }
            },
        }
        this.props.tagTypes.forEach((tagType) => {
            customRowCells[tagType.value] = (data, id, row) => {
                return (
                    <Checkbox color="primary" disabled={!this.props.itemsBeingEdited[id]} checked={this.props.itemsBeingEdited[id] ? this.props.itemsBeingEdited[id][tagType.value] : data || false} onChange={(e) => {this.props.handleEditItemChanged({...this.props.itemsBeingEdited[id], [tagType.value]: e.target.checked})}}/>
                )
            }
        });
        return customRowCells;
    }
    addNewItemRenderer = (dataProperty) => {
        if (dataProperty === 'value') {
            return (
                <Input
                    placeholder="Add new..."
                    inputProps={{name: dataProperty}}
                    value={this.props.newItem.value || ""}
                    onChange={(e) =>this.props.handleNewItemChanged({...this.props.newItem, value: e.target.value})}
                />
            );
        }
        if (dataProperty === 'action') {
            return (
                <Button onClick={() => this.props.handleAddItemRequest(this.props.newItem)} color="primary" disabled={!this.props.newItem || !this.props.newItem.value || this.props.loading}>Add</Button>
                )
        }
        return (
            <Checkbox color="primary" checked={this.props.newItem[dataProperty] || false} onChange={(e) =>this.props.handleNewItemChanged({...this.props.newItem, [dataProperty]: e.target.checked})}/>
        )
        }
    createCustomFilters = () => {
        const customFilters = {}
        this.props.tagTypes.forEach((tagType) => {
            customFilters[tagType.value] = (filterValue, onChange) => {
                return (
                    <AutoSelect 
                        additionalData={tagType.value}
                        label={tagType.label}
                        value={filterValue}
                        isClearable={true}
                        handleChangeSingle={onChange}
                        small
                        width="100%"
                        minWidth={140}
                        suggestions={
                            [
                                { label: 'True', value: true },
                                { label: 'False', value: false}
                            ]
                        }
                    />
                )
            }
        })
        return customFilters;
    }
    getMinWidth = () => {
        return 290 + this.props.tagTypes.length * 140;
    }
    
    render() {
        const filteredData = this.filterData(this.props.data);
        return (
            <div>
                <DataTable
                    enabledAddition
                    general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                    minHeight={150}
                    selected={this.props.selectedItems}
                    onSelectedChanged={this.selectedItemsChanged}
                    loading={this.props.loading}
                    minWidth={this.getMinWidth()}
                    customColumnsWidthEnabled={true}
                    filtered={this.state.filtered}
                    onFilteredChanged={this.onFilteredChanged}
                    dropdownFilteredChanged={this.onFilteredChanged}
                    data={filteredData}  
                    customRowCells={this.createCustomRowCells()}
                    customFilters={this.createCustomFilters()}
                    addNewItemRenderer= {this.addNewItemRenderer}
                    columns={this.createColumns()}      
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsPickList);