import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubmittedBy from './components/SubmittedBy';
const mapStateToProps = (state) => {
    return {
        actualsSubmittedBy: state.deliveryConfirmationForm.actualsSubmittedBy,
    }
}
class ActualsSubmittedByContainer extends Component {
    render() {
        return (
            <SubmittedBy actualsSubmittedBy={this.props.actualsSubmittedBy}/>
        )
    }
}
export default connect(mapStateToProps, null)(ActualsSubmittedByContainer);