import React from 'react';
import Input from '../../../../components/Input/Input';
import TextArea from '../../../../components/Input/TextArea';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import AttachmentComponent from '../../ChangelogForm/components/AttachmentComponent';
import AttachmentRevision from '../../ChangelogForm/components/AttachmentRevision';
import ResourceItemOvertime from './ResourceItemOvertime';
import FormSubmitPanel from './FormSubmitPanel';
import {canFunction} from '../../../../config/Can';
import * as rules from '../../../../config/Rules';
import resourceTypes from '../../../../constants/resourceTypes';
import history from '../../../../history';
import Button from '@material-ui/core/Button';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import { resourceTypesEnums } from '../../../../constants/resourceTypes';
import { getPureText } from '../../../../utils/commentUtils'
import AutoSelect from '../../../../components/Input/AutoSelect';
import NumberFormat from 'react-number-format';
import { isPerDiemVisible, isAssignmentSiteVisible, isLocationVisible, isGradeVisible, isUnitPriceDisabledOnChangelogForm, isVendorTypeExternal, isPlannedUnitPrice, isTripplePrecision } from '../../../../utils/typesAssertions';

const styles = {
    wrapper: {
        border: '1px solid #c8cace',
        borderRadius: 25,
        padding: '24px 16px',
        width: '100%',
        position: 'relative',
        marginBottom: 24
    },
    headerWrapper: {
        marginBottom: 10,
        display: 'flex',
    },
    headerText: {
        paddingLeft: 4,
        fontSize: 16,
        lineHeight: '20px',
        color: '#595959',
        fontWeight: 'bold'
    },
    secondRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -64,
        '& > *': {
            flexBasis: 128,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: 64,
            paddingBottom: 16
        },
        '& > div > *': {
            paddingTop: 8,
            paddingBottom: 8,
        }
    },
    processignNaPadding: {
        paddingRight: 50
    },
    header: {
        paddingBottom: 8,
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '28px',
        color: '#262626'
    },
    dcUniqueId: {
        paddingLeft: 12,
        fontWeight: 400,
        color: '#262626'
    },
    commentsListContainer: {
        paddingTop: 18
    },
    commentContainer: {
        padding: 16,
        borderRadius: '25px',
        width: '100%',
        wordWrap: 'break-word'
    },
    commentHeader: {
        color: '#595959',
        fontSize: 12,
        fontWeight: '500'
    },
    commentContent: {
        color: '#262626',
        lineHeight: '20px',
        fontSize: 16,
        paddingTop: 10
    },
    totalPriceWrapper: {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    totalPriceLabel: {
        color: '#595959',
        fontSize: 16
    },
    totalPriceValue: {
        color: '#262626',
        fontSize: 16,
        paddingLeft: 12
    }  

}
const useStyles =  makeStyles(styles);
export default (props) => {
    const getTotalPrice = () => {
        let result = 0;
        if (props.actualUnitPrice && !Number.isNaN(parseFloat(props.actualUnitPrice))) {
            result += parseFloat(props.actualUnitPrice) * parseFloat(props.unitRate);
        }
        if (isPerDiemVisible(props.resourceType, props.assignmentSite) && props.actualPerDiem && !Number.isNaN(parseFloat(props.actualPerDiem))) {
            result += parseFloat(props.actualPerDiem) * parseFloat(props.perDiemRate);
        }
        if (props.overtime) {
            props.overtimeHours.forEach((overtime) => {
                if (overtime.numberOfExtraHours && !Number.isNaN(parseFloat(overtime.numberOfExtraHours))) {
                    result += (parseFloat(overtime.rate)/100) * (parseFloat(overtime.numberOfExtraHours)/8) * parseFloat(props.unitRate);
                }
            })
        }
        return  <NumberFormat 
        value={result}
        displayType={'text'}
        thousandSeparator 
        decimalScale={2}
        fixedDecimalScale={2}
        allowedDecimalSeparators={[".",","]}
        isNumericString
        />;
    }
    const canEditActuals = () => {
        if (props.revisionMode) {
            return false
        }
        if (props.status === 1 || props.status === 0 || props.status === 2) {
            if (isVendorTypeExternal(props.vendorType) && props.isSupplier) {
                if (canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) {
                    return props.isEditing;
                }
                return true;
            }
            if (!isVendorTypeExternal(props.vendorType)) {
                return canFunction('', rules.EDIT_DC_INTERNAL_ACTUAL_PRICE) && props.isEditing
            }
        }
        return false;
    }
    const canEditComments = () => {
        if (props.revisionMode) {
            return false
        }
        if (props.status === 5) {
            return canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && props.isEditing;
        }
        if (canFunction('', rules.EDIT_DC_COMMENTS) && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) {
            return props.isEditing;
        }
        if (isVendorTypeExternal(props.vendorType)) {

            if (props.status === 0) {
                return props.isDcApprover || props.isSupplier
            }
            if (props.status === 1) {
                return props.isSupplier;
            }
            if (props.status === 2) {
                return props.isDcApprover || props.isSupplier;
            }
        }
        return false;
    }
    const canEditAttachments = () => {
        if (props.revisionMode) {
            return false
        }
        if (canFunction('', rules.EDIT_DC_ATTACHMENTS) && canFunction('', rules.IS_BACKOFFICE_OR_ADMIN)) {
            return props.isEditing;
        }
        if (isVendorTypeExternal(props.vendorType)) {
            if (props.status === 0) {
                return props.isDcApprover || props.isSupplier
            }
            if (props.status === 1) {
                return props.isSupplier;
            }
            if (props.status === 2) {
                return props.isDcApprover || props.isSupplier;
            }
        }
        return false;
    }
    const navigateToResourceModel = () => {
        history.push('/resourcemodel/'+props.rmId);
    }
    const navigateToChangelog = () => {
        if (canFunction(props.role, rules.NAVIGATE_CHANGELOG_VIEWALL)) {
            history.push('/changelogs/viewAll/' + props.clId);
        } else {
            history.push('/changelogs/my/' + props.clId);
        }
    }
    const classes = useStyles();
    const currency = (<Input label="Currency" disabled width="100%" value={props.currency || ""} className="disabled-form-input"/>);
    const plannedAmountIn = (<Input label={!isPlannedUnitPrice(props.resourceType) ? "Planned amount" :"planned number of units"} type="number" precision={isTripplePrecision(props.resourceType) ? 3: 2} disabled width="100%" value={props.plannedUnitPrice || ""} className="disabled-form-input"/>);
    const actualAmountIn = (<Input errorText={props.errors.actualUnitPrice}  label={(!isPlannedUnitPrice(props.resourceType)) ? "Actual amount": "Actual number of units"} type="number" precision={isTripplePrecision(props.resourceType) ? 3: 2} disabled={!canEditActuals()} width="100%" onChange={props.actualUnitPriceChanged} value={(props.actualUnitPrice || props.actualUnitPrice === 0) ? props.actualUnitPrice : ""} className={props.changes && props.changes.actualUnitPrice !== undefined ? "input-updated" : (!canEditActuals() ? "disabled-form-input" : null)}
    tooltipText={props.changes && props.changes.actualUnitPrice !== undefined ? props.changes.actualUnitPrice : ""}/>);
    const profileGrade = (<Input label="Profile/Grade" disabled width="100%" value={props.grade || ""} className="disabled-form-input"/>);
    const unitRate = (<Input label="Unit Rate" disabled width="100%" value={(props.unitRate || props.unitRate === 0) ? props.unitRate:""} className="disabled-form-input"/>);
    const perDiemRate = isPerDiemVisible(props.resourceType, props.assignmentSite) ? (<Input label="Per diem Rate" disabled width="100%" value={(props.perDiemRate || props.perDiemRate === 0) ?  props.perDiemRate : ""} className="disabled-form-input"/>) : null;
    const plannedUnitPrice = (<Input precision={isTripplePrecision(props.resourceType) ? 3: 2} label="Planned number of units" disabled width="100%" value={(props.plannedUnitPrice || props.plannedUnitPrice === 0) ? props.plannedUnitPrice : ""} className="disabled-form-input"/>);
    const actualUnitPrice = (
        <Input 
            label="Actual number of units" 
            errorText={props.errors.actualUnitPrice} 
            precision={isTripplePrecision(props.resourceType) ? 3: 2} 
            type="number" 
            disabled={!canEditActuals()} 
            width="100%" 
            value={(props.actualUnitPrice || props.actualUnitPrice === 0 )? props.actualUnitPrice : ""} 
            onChange={props.actualUnitPriceChanged} 
            className={props.changes && props.changes.actualUnitPrice !== undefined ? "input-updated" : (!canEditActuals() ? "disabled-form-input" : null)}
            tooltipText={props.changes && props.changes.actualUnitPrice !== undefined ? props.changes.actualUnitPrice : ""}
        />);
    const plannedPerDiem = isPerDiemVisible(props.resourceType, props.assignmentSite) ? (<Input precision={isTripplePrecision(props.resourceType) ? 3: 2} label="Planned per diem" disabled width="100%" value={(props.plannedPerDiem || props.plannedPerDiem === 0 )? props.plannedPerDiem : ""} className="disabled-form-input"/>) : null;
    const actualPerDiem = isPerDiemVisible(props.resourceType, props.assignmentSite) ? (
            <Input 
            errorText={props.errors.actualPerDiem} 
            label="Actual per diem" 
            type="number" 
            precision={isTripplePrecision(props.resourceType) ? 3: 2} 
            disabled={!canEditActuals()} width="100%" 
            value={(props.actualPerDiem || props.actualPerDiem === 0) ? props.actualPerDiem: ""} 
            onChange={props.actualPerDiemChanged} 
            className={props.changes && props.changes.actualPerDiem !== undefined ? "input-updated" : (!canEditActuals() ? "disabled-form-input" : null)}
            tooltipText={props.changes && props.changes.actualPerDiem !== undefined ? props.changes.actualPerDiem : ""}
        />) : null;
    const overTime = (<div><RadioButtons header="Overtime" disabled={!canEditActuals()} horizontal={true} onChange={props.overtimeChanged} options={[{label:'Yes', value: true}, {label:'No', value: false}]} value={{value: props.overtime}} /></div>);
    let secondRow = null;
    switch(props.resourceType) {
        case resourceTypesEnums["T&E"]:
        case resourceTypesEnums.FXP:
        case resourceTypesEnums.FMS: {
            secondRow = (
                <div className={classes.secondRow}>
                    {currency}
                    {plannedAmountIn}
                    {actualAmountIn}
                </div>
            )
            break;
        }
        case resourceTypesEnums["T&M"]: {
            secondRow = (
                <div className={classes.secondRow}>
                    <div>
                        {profileGrade}
                        {unitRate}
                        {perDiemRate}
                        {currency}
                    </div>
                    <div>
                        {plannedUnitPrice}
                        {actualUnitPrice}
                        {plannedPerDiem}
                        {actualPerDiem}
                    </div>
                    <div>
                    {overTime}
                    {props.overtime ? (
                        <React.Fragment>
                            <ResourceItemOvertime 
                            changes={props.changes.overtimeHours}
                            rates={props.overtimeHours} 
                            isApproving={!canEditActuals()}
                            hoursChanged={props.deliveryConfirmationFormOvertimeHoursChanged}
                            />
                        </React.Fragment>
                        ) : null}
                    </div>
                </div>
            );
            break;
        }
        case resourceTypesEnums.IT:
        case resourceTypesEnums.Business: {
            secondRow = (
                <div className={classes.secondRow}>
                    <div>
                        {profileGrade}
                        {unitRate}
                        {currency}
                    </div>
                    <div>
                        {plannedUnitPrice}
                        {actualUnitPrice}
                    </div>
                </div>
            )
            break;
        }
        default: {
            break;
        }
    }
    return (
    <div> 
        <h3 className={classes.header}>{resourceTypes[props.resourceType]}</h3>  
        <div className={classes.wrapper}>
            <div className={classes.headerWrapper}>
                <InfoOutlined fontSize="small"/>
                <span className={classes.headerText}>DC unique ID <span className={classes.dcUniqueId}>{props.dcNumber}</span></span>
            </div>
            <div>
                {secondRow}
            </div>
            <div className={classes.totalPriceWrapper}>
                <span className={classes.totalPriceLabel}>
                    Total price
                </span>
                <span className={classes.totalPriceValue}>
                {getTotalPrice()} {props.currency}
                </span>
            </div>
            <div>
                <TextArea errorText={props.errors.comment} label="Comment" width="100%" disabled={!canEditComments()} value={props.comment} onChange={props.commentChanged} className={!canEditComments() ? "disabled-form-input" : null}></TextArea>
                <div className={classes.commentsListContainer}>
                {props.comments && props.comments.map((comment) => {
                        return (
                            <div key={comment.userName + comment.date} className={classes.commentContainer}>
                                <h4 className={classes.commentHeader}>
                                    {`${comment.userName}, ${new Date(comment.date).toISOString().slice(0,10)}`}
                                </h4>
                                <TextArea value={comment.message} disabled editorStyle={{height: 'auto', border: 0, padding: 0}} />
                            </div>
                        )
                })}
                </div>
            </div>
            {props.rejectReason && getPureText(props.rejectReason).length !== 0 && (props.status === 1 || props.statusIndex === 1) ?
            <div>
                <TextArea label="Reject reason" disabled value={props.rejectReason} editorStyle={{height: 'auto', border: 0, padding: 0}} className="disabled-form-input"></TextArea>
            </div>
            : null}
            <div>
            <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                <AttachmentComponent 
                    changes={props.changes}
                    errorText={props.errors.attachments}
                    attachments={props.attachments} 
                    disabled={!canEditAttachments()}
                    attachmentRemoved={props.attachmentDeleted}
                    attachmentAdded={props.attachmentAdded}
                    downloadAttachment={props.downloadAttachment}
                /> 
            </div> 
            <div>
                { props.hideSubmitPanel ? null :<FormSubmitPanel /> }
            </div>
            <div style={{marginLeft: 'auto', paddingTop:5, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            {canFunction(props.role, rules.IS_BACKOFFICE_OR_ADMIN) && props.rmId ?
                <div style={{marginTop: 10}}>
                    <Button color="primary" 
                        component="span"
                        className="removed-text-padding"
                        onClick={navigateToResourceModel}
                        >
                            <AssignmentIndOutlinedIcon />
                            Resource Model
                    </Button>
                </div> : null
            }
            {(canFunction(props.role, rules.IS_BACKOFFICE_OR_ADMIN) || canFunction(props.role, rules.IS_REQUESTOR)) && props.clId ?
                <div style={{marginTop: 10}}>
                    <Button color="primary" 
                        component="span"
                        className="removed-text-padding"
                        onClick={navigateToChangelog}
                        >
                            <AssignmentIndOutlinedIcon />
                            Open Changelog
                    </Button>
                </div> : null
            }
            </div>
            </div>
        </div>
    </div> 
    )
}