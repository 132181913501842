import React from 'react';
import AutoSelect from '../../../../components/Input/AutoSelect';
import Input from '../../../../components/Input/Input'
import formSectionWrapper from '../../ChangelogForm/components/FormSectionWrapper';
import Checkbox from '@material-ui/core/Checkbox'
import { isPerDiemVisible, isAssignmentSiteVisible, isLocationVisible, isGradeVisible, isUnitPriceDisabledOnChangelogForm } from '../../../../utils/typesAssertions';

const VendorRateData = (props) => {
    let inputs = null
    const unitPrice = (<Input errorText={props.errors.unitPrice} type="number"  label="Unit Price" value={props.data.unitPrice !== undefined ?  props.data.unitPrice : ''} onChange={props.vendorRatesFormUnitPriceChanged} />)
    const currency = (<AutoSelect errorText={props.errors.currency}  label="Currency" handleChangeSingle={props.vendorRatesFormCurrencyChanged} suggestions={props.currencies} value={props.data.currency !== undefined ? props.data.currency : ''} />)
    const location = (<AutoSelect errorText={props.errors.resourceLocation}  label="Location" handleChangeSingle={props.vendorRatesFormResourceLocationChanged} suggestions={props.resourceLocations} value={props.data.resourceLocation || ''}  />)
    const assignmentSite = (<AutoSelect errorText={props.errors.assignmentSite}  label="Assignment Site" handleChangeSingle={props.vendorRatesFormAssignmentSiteChanged} suggestions={props.assignmentSites} value={props.data.assignmentSite || ''}/>)
    const profileGrade = (<AutoSelect errorText={props.errors.grade}  label="Profile/Grade" handleChangeSingle={props.vendorRatesFormGradeChanged} suggestions={props.grades} value={props.data.grade || ''}  />)
    const perDiem = (!(props.data.assignmentSite && props.data.resourceType && isPerDiemVisible(props.data.resourceType.value, props.data.assignmentSite.value)) ? null : <Input type="number" errorText={props.errors.perDiemRate} label="Per Diem Rate" value={props.data.perDiemRate !== undefined ? props.data.perDiemRate : ''} onChange={props.vendorRatesFormPerDiemRateChanged}  />)
    if (props.data.resourceType && props.data.resourceType.label) {
        inputs = (
            <React.Fragment>
                {isLocationVisible(props.data.resourceType.value) ? location : null}
                {isAssignmentSiteVisible(props.data.resourceType.value) ? assignmentSite : null}
                {isGradeVisible(props.data.resourceType.value) ? profileGrade : null}
                {unitPrice}
                {props.data.assignmentSite && isPerDiemVisible(props.data.resourceType.value, props.data.assignmentSite.value) ? perDiem : null}
                {currency}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <AutoSelect errorText={props.errors.resourceType} label="Resource Type" handleChangeSingle={props.vendorRatesFormResourceTypeChanged} suggestions={props.resourceTypes} value={props.data.resourceType || ''} />
            <AutoSelect errorText={props.errors.vendor} disabled={props.data.alreadyInUse} label="Vendor" handleChangeSingle={props.vendorRatesFormVendorChanged} suggestions={props.vendors} value={props.data.vendor || ''}  />
            <AutoSelect errorText={props.errors.rateYear} label="Rate year" handleChangeSingle={props.vendorRatesFormRateYearChanged} suggestions={props.rateYears} value={props.data.rateYear || ''}  />
            {inputs}
            <Checkbox color="primary" checked={props.data.isDeleted || false} onChange={props.vendorRatesFormInactiveChanged}/>Inactive    
        </React.Fragment>
    )
}
export default formSectionWrapper(VendorRateData, "Vendor Rate data");