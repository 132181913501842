import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from './views/LandingPage/LandingPage';
import MainPage from './views/MainPage/MainPage';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import palette from './theme/palette';
import overrides from './theme/overrides';
import CssBaseline from '@material-ui/core/CssBaseline';
import DownloadDcComponent from './views/MainPage/DeliveryConfirmationForm/Form/DownloadDcComponent';
import { withRouter } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';


class AppContent extends Component {

    render () {
      const colorVariant = this.props.location.pathname.split('/')[1];
      const selectedPalette = palette(colorVariant);
      const themeBase = createMuiTheme({
        ...selectedPalette,
      });
      const theme = overrides(selectedPalette, themeBase);
        return (
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div style={this.props.backgroundTaskRunning ? {position: 'relative', height: 'calc(100% - 30px)'}: {height: '100%'}}>
                    <Switch>
                        <Route backgroundTaskRunning={this.props.backgroundTaskRunning} path="/" exact component={LandingPage}></Route>
                        <Route path="/files/processeddcs/:id" component={DownloadDcComponent}></Route>
                        <Route backgroundTaskRunning={this.props.backgroundTaskRunning} component={MainPage}></Route>
                    </Switch>
                    </div>
                    { this.props.backgroundTaskRunning ?
                    <React.Fragment>
                    <div style={{backgroundColor: '#fff', position: 'absolute', width: '100%', height: 30, display: 'flex', borderTop: '1px solid black', bottom: 0, justifyContent: 'flex-end', alignItems: 'center'}}>
                        <CircularProgress size={20} />
                        <span style={{marginLeft: 10, marginRight: 10}}>{this.props.backgroundTaskMessage}</span>
                    </div>
                    </React.Fragment>
                    : null
                }
                </ThemeProvider>
        )
    }

}
export default withRouter(AppContent);