
import { createMuiTheme } from "@material-ui/core/styles";
import typography from './typography';
export default function(selectedPalette, theme) {
  return createMuiTheme({
    ...selectedPalette,
    ...typography,
    overrides: {
        MuiTableSortLabel: {
          root: {
            color: 'none !important',
            width: '100%'
          },
          icon: {
            marginTop: 17
          }
        },
        MuiDialog: {
          root: {
              '& .MuiDialogContent-root': {
                  overflowX: 'hidden',
                  transform: 'translateZ(0)'
              },
          },
        },
        MuiDialogTitle: {
          root: {
            textTransform: 'none'
          }
        },
        MuiDialogActions: {
          root: {
            paddingBottom: 24,
            paddingLeft: 24,
            paddingRight: 24
          }
        },
        MuiFab: {
            root: {
              letterSpacing: '0.5px',
              fontWeight: 700,
              '&.navbar-fab': {
                height: 36,
                width: 120,
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: 'none'
              },
              '&.navbar-fab-long': {
                height: 36,
                width: 210,
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                boxShadow: 'none'
              },
              '&.navbar-fab-selected': {
                height: 36,
                width: 120,
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                boxShadow: 'none'
              },
              '&.navbar-fab-selected-long': {
                height: 36,
                width: 210,
                color: theme.palette.primary.main,
                backgroundColor: '#fff',
                boxShadow: 'none'
              },
              '&.landing-page-button': {
                height: 60,
                width: 260,
                '&.wide': {
                  width: 356
                },
                margin: 20,
                borderRadius: 30,
                fontWeight: 'bold'
              },
              '&.create-new-changelog-button': {
                height: 48,
                color: '#fff',
                width: 170,
                marginLeft: 18,
                borderRadius: 30,
                marginTop: 20,
                marginBottom: 20
              },
              '&.attachment': {
                height: 28,
                backgroundColor: '#fff',
                boxShadow: 'none',
                border: '1px solid #d2d2d2',
                fontSize: 10,
                cursor: 'pointer',
                marginRight: 16,
                position: 'relative',
                paddingRight: 40
              },
              '&.form-submit-panel': {
                height:36,
                width: 96,
                fontSize: 14,
                boxShadow: 'none',
                cursor: 'pointer',
                '&.form-extended-button': {
                    width: 'auto !important',
                },
                '&.form-cancel': {
                  backgroundColor: '#fff',
                  color: theme.palette.primary.main
                },
                '&.form-submit': {
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff'
                }
              }
            }
        },
        MuiPopover: {
          root: {
            '&.table-customization': {
              '& .MuiPaper-root': {
                padding: 8,
                // '& .MuiCheckbox-root': {
                //   padding: 8,
                // }
              }
            }
          }
        },
        MuiButton: {
          root: {
            fontWeight: 700,
            letterSpacing: '0.5px',
            '&.removed-text-padding': {
              padding: 0,
              '& .MuiSvgIcon-root': { 
                paddingRight: '2px'
              }
            },
            '&.blue-color': {
              fontWeight: 'bold',
              color: '#39acff'
            }
          }
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '12px'
          }
        },
        MuiDrawer: {
            paperAnchorDockedLeft: {
                borderRight: 0
            }
        },
        MuiTabs: {
          root: {
            paddingTop: 20
          },
          indicator: {
            backgroundColor: theme.palette.primary.main
          }
        },

        MuiTab: {
          root: {
            // fontSize: '20px !important',
            // lineHeight: '24px',
            fontWeight: 'bold'
          }
        },
        MuiAppBar: {
          root: {
            zIndex: theme.zIndex.drawer + 1,
          }
        },
        MuiToolbar: {
          root: {
            paddingLeft: '16px !important'
          }
        },
        MuiTablePagination: {
          input: {
            backgroundColor: 'inherit',
            border: 0,
            lineHeiht: '1.1875em',
            fontSize: 12
          },
          select: {
            padding: '6px 0 7px',
            fontSize: 12
          }
        },
        MuiFormControl: {
          root: {
            '&.error': {
              paddingBottom: 14,
              '& .MuiInputBase-root': {
                borderColor: 'red !important'

              },
              '& .MuiFormLabel-root': {
                color: 'red !important'
              }
            }

          }
        },
        MuiInputAdornment: {
          positionEnd: {
            '@media all and (-ms-high-contrast:none)': {
              marginLeft: 'auto',
            }
          }
        },
        MuiInputBase: {
            root: {
              '& .MuiInputBase-inputAdornedEnd': {
                '@media all and (-ms-high-contrast:none)': {
                  width: 'auto',
                }
              },
              border: "1px solid #a9a9a9",
              borderRadius: 25,
              backgroundColor: '#fff',
              '&.Mui-error': {
                borderColor: 'red'
              },
              "&.green input, .green textArea": {
                color: '#28c101'
              },
              "&.line-through input, .green textArea": {
                textDecoration: 'line-through'
              },
              "label + &": {
                marginTop: 16
              },
              '&$focused': {
                borderColor: theme.palette.primary.main + ' !important'
              },
  
              '&.small': {
                '& .MuiInputBase-inputAdornedEnd': {
                  paddingRight: 0
                },
                border: "1px solid #a9a9a9",
                borderRadius: 12,
                "&.onlyHeader": {
                  visibility: "hidden"
                },
                "label + &": {
                  marginTop: 16
                },
                '& input, textArea': {
                  fontSize: '10px',
                  padding: "7px 12px",
                  backgroundColor: '#fff',
                  borderRadius: 12,
                  height: '1em',
                },
                '& textArea': {
                  lineHeight: '10px'
                }
              },
              '&.input-updated': {
                borderColor: '#39acff',
                backgroundColor: '#e1f5fe',
                '& input, textArea': {
                  backgroundColor: '#e1f5fe',
                  borderRadius: 25
                }
              },
              '&.input-updated-table': {
                borderWidth: '1px',
                borderColor: '#e1f5fe',
                '& input, textArea': {
                  backgroundColor: '#39acff',
                  borderRadius: 6
                }
              },
              '&.input-deleted': {
                borderColor: 'red',
                '& input, textArea': {
                  backgroundColor: '#fbaca8',
                  borderRadius: 25
                }
              },
              '&.disabled-form-input': {
                border: 0,
                '& input, textArea': {
                  padding: '10px 16px',
                  color: 'rgba(38, 38, 38, 0.8)'
                },
                '&.changelog-popup': {
                  '& input, textArea': {
                    color: '#262626',
                    fontSize: '14px',
                    padding: '3px 12px',
                  },
                  '& textArea': {
                    lineHeight: '14px'
                  }
                }
              },
            },
            multiline: {
              padding: 0,
              lineHeight: '16px',
              '& textArea': {
                color: '#262626',
                fontSize: 16,
                padding: "9px 16px",
                lineHeight: '16px',
              },
            },
            input: {
              color: '#262626',
              fontSize: 16,
              padding: "9px 16px",
              lineHeight: 'normal',
              height: '1em',
            },
          },
          MuiInputLabel: {
            root: {
              color: '#6e6e6e',
              whiteSpace: 'nowrap',
              fontSize: 12 / 0.75,
              left: "16px !important",
              '&.small': {
                fontSize: 10 / 0.75,
                left: "12px !important",
                fontWeight: "bold",
                "&.onlyHeader": {
                  left: "3px !important",
                },
              },
              '&.disabled-form-input': {
                color: '#a9a9a9',
                '&.changelog-popup': {
                  color: '#595959',
                  fontSize: 10/0.75,
                  left: '12px !important'
                }
              },
            }
          },
          MuiFormHelperText: {
            root: {
              margin: '0 !important',
              color: 'red !important',
              paddingLeft: 12,
              fontSize: 10,
              lineHeight: '14px'
            }
          },
          MuiFormLabel: {
            root: {
              transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
              transform: 'translate(0, 1.5px) scale(0.75)',
              transformOrigin: 'top left',
              position: 'absolute',
              top: 0,
              color: '#6e6e6e',
              whiteSpace: 'nowrap',
              fontSize: 16,
              left: "16px !important",
              '&.Mui-error': {
                color: 'red !important'
              }
            }
          },
          MuiTable: {
            root: {
              '&.month-table': {
                width: 'auto',
                display:'inline-block',
                border:'1px solid #c8cace',
                borderRadius: 8,
              '@media all and (-ms-high-contrast:none)': {
                tableLayout: 'fixed',
                display: 'table !important'
              },
                overflow:'hidden',
                '& .MuiTableRow-head': {
                  backgroundColor: '#fff',
                  height: 24
                },
                '& .MuiTableCell-head': {
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontWeight: 'bold',
                  height: 24
                },
                '& .body-row': {
                  backgroundColor: '#f0f0f0',
                  '&:hover': {
                    backgroundColor: '#f0f0f0 !important',
                  }
                },
                '& .MuiInputBase-root': {
                  border: 0,
                  borderRadius: 6,
                  '&.input-updated-table': {
                    border: '1px solid' + ' #39acff',
                    // borderColor: theme.palette.primary.main,
                    '& input, textArea': {
                      backgroundColor: '#e1f5fe',
                      borderRadius: 6
                    }
                  },
                  '& .MuiInputBase-input': {
                    paddingLeft: 6,
                    paddingRight: 6,
                    fontSize: 12,
                    fontWeight: 'bold'
                  },
                  '& .MuiInputBase-inputMultiline': {
                    paddingLeft: 6,
                    paddingRight: 6,
                    fontSize: 12,
                    fontWeight: 'bold',
                    lineHeight: '12px'
                  }
                }
              },
              '&.changelog-popup': {
                display:'inline-block',
                border: 0,
                // overflow:'hidden',
                '& .MuiTableRow-head': {
                  backgroundColor: '#fff',
                  height: 24
                },
                '& .MuiTableCell-head': {
                  backgroundColor: '#fff',
                  height: 24
                },
                '& .data-table-cell': {
                  textAlign: 'center',
                  paddingLeft: 4,
                  paddingRight: 4,
                  minWidth: 58,
                }
              }
            }
          },

          MuiTableCell: {
            root: {
                height: 40,
            },
            paddingNone: {
              paddingLeft: 4,
              '&.data-table-cell': {
                paddingLeft: 16
              },
              '&.pointer': {
                cursor: 'pointer'
              },
              paddingRight: 4,
              fontSize: 12,
              color:'#000',
              '&:last-child': {
                paddingLeft: 4,
                paddingRight: 4,
              }
            }
          },
          MuiTableRow: {
            root: {
                height: 40,
                fontSize: 12,
                '&.body-row:hover': {
                    backgroundColor: theme.palette.primary.light + ' !important'
                },
                '&.header-row': {
                    backgroundColor:'#f0f0f0',
                    height: 60
                },                
            }
          },
          MuiListItem: {
            root: {
              '&.drawer-mainitem': {
                backroundColor: "white",
                borderRadius: "0px 25px 25px 0px",
                height: 40,
                "&$selected": {
                  backgroundColor: theme.palette.primary.light + "!important",
                  color: "#000",
                  fontWeight: "bold"
                }
              },
              '&.drawer-landingPage': {
                backroundColor: "white",
                borderRadius: "0px 25px 25px 0px",
                height: 40,
                "&$selected": {
                  backgroundColor: theme.palette.primary.main + "!important",
                  color: "#fff",
                  fontWeight: "bold"
                }
              },
              '&.drawer-subitem': {
                paddingLeft: 40,
                backroundColor: "white",
                borderRadius: "0px 25px 25px 0px",
                height: 32,
                "&$selected": {
                  backgroundColor: "white !important",
                  color: theme.palette.primary.dark,
                  fontWeight: 'bold'
                }
              }
            }
          },
          MuiListItemText: {
            root: {
              '&.drawer-mainitem': {
                fontSize: 14,
                paddingLeft: 12
              },
              '&.drawer-landingPage': {
                fontSize: 14,
                paddingLeft: 12,
                fontWeight: 600
              },
              '&.drawer-subitem': {
                fontSize: 13,
                paddingLeft: 12
              }
            }
          },
          MuiLink: {
            root: {
              cursor: 'pointer',
              '&.breadcrumb-link': {
                fontSize: 20,
                textTransform: 'uppercase',
                color: '#fff'
              }
            }
          },
          PrivateSwitchBase: {
            root: {
              zIndex: 0
            }
          },
          MuiFormGroup: {
            root: {
              "&.radio-group": {
                height: 52
              },
              '&.radio-group-with-header': {
                height: 36,
                marginTop: 16,
                '&.input-updated': {
                    backgroundColor: '#e1f5fe',
                    border: '1px solid #39acff',
                    padding: 16,
                    height: 70,
                    borderRadius: 16
                }
              }
            }
          },
          MuiGrid: {
            container: {
              '&.form-grid': {
                width: 'calc(100% + 80px)',
                marginLeft: -40,
                marginRight: -40,
                marginBottom: -20,
                '& .MuiGrid-item': {
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingBottom: 20,
                },   
            }
          }
        },
        MuiCheckbox: {
          root: {
            '&.confirm-form': {
              padding: 0
            }
          }
        },
        MuiSvgIcon: {
          root: {
            '&.delete-attachment': {
              height: 18,
              width: 18
            },
            '&.copy-link': {
              cursor: 'pointer'
            },
            '&.help': {
              height: 16,
              width: 16,
              color: 'rgba(0, 0, 0, 0.87)'
            },
            '&.help2': {
              height: 12,
              width: 12,
              color: 'rgba(0, 0, 0, 0.87)'
            },
            '&.delete-adornment': {
                height: 16,
                width: 16,
                color: 'hsl(0,0%,80%)',
                '&:hover': {
                color: 'hsl(0,0%,40%)'
                }
            }
          }
        },
        MuiCardContent: {
          root: {
            '&.changelog-content': {
              paddingTop: '0 !important'
            }
          }
        },
        MuiLinearProgress: {
          root: {
            width: '100%'
          }
        }
}
})
}
 