import React, { Component } from 'react';
import Tabs from '../../../components/Tabs/Tabs';
import DataTableWithServerSizePagination from '../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import * as regionalChangelogActions from '../../../store/actions/regionalChangelogActions';
import history from '../../../history';
import DataExportContainer from '../../../containers/DataExport/DataToExcelContainer';
import ExportToCsv from '../../../containers/DataExport/DataExportContainer';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import { canFunction } from '../../../config/Can';
import * as rules from '../../../config/Rules';
import Dialog from '../../../components/Dialog/Dialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import excelTypes from '../../../constants/excelTypes';

const budgetPlansByCc = [
    {
        header: "Budget plan id",
        dataProperty: 'budgetPlanId',
        width: 100,
        isVisible: true,
    },
    {
        header: "Resource line id",
        dataProperty: 'id',
        width: 100,
        isVisible: true,
    },
    {
        header: "Platform",
        dataProperty: 'domain',
        width: 120,
        isVisible: true,
    },
    {
        header: "Sub-Platform",
        dataProperty: 'subDomain',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Center",
        dataProperty: 'costCenter',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Center Description",
        dataProperty: 'costCenterDescription',
        width: 120,
        isVisible: true,
    },
    {
        header: "Budget year",
        dataProperty: 'budgetYear',
        width: 120,
        isVisible: true,
    },
    {
        header: "Expense Group",
        dataProperty: 'expenseType',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Type",
        dataProperty: 'costType',
        width: 120,
        isVisible: true,
    },
    {
        header: "Currency",
        dataProperty: 'currency',
        width: 120,
        isVisible: true,
    },
    {
        header: "Total Budget Plan",
        dataProperty: 'totalBudget',
        width: 120,
        isVisible: true,
    },
    {
        header: "Plan YTD",
        dataProperty: 'planYTD',
        width: 120,
        isVisible: true,
    },	
    {
        header: "Actuals YTD",
        dataProperty: 'actualsYTD',
        width: 120,
        isVisible: true,
    },
    {
        header: "Plan YTG",
        dataProperty: 'planYTG',
        width: 120,
        isVisible: true,
    },
    {
        header: "Hit Rate",
        dataProperty: 'hitRate',
        width: 120,
        isVisible: true,
    },
    {
        header: "Last Actuals Imported Date",
        dataProperty: 'lastImportedDate',
        width: 150,
        isVisible: true,
    }
]
const budgetPlansByCcBo = [
    ...budgetPlansByCc,
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "Line Tags",
        width: 140,
        dataProperty: 'lineTags',
        isVisible: true
    }
]
const budgetPlansByActivity = [
    {
        header: "Budget plan id",
        dataProperty: 'budgetPlanId',
        width: 100,
        isVisible: true,
    },
    {
        header: "Resource line id",
        dataProperty: 'id',
        width: 100,
        isVisible: true,
    },
    {
        header: "Platform",
        dataProperty: 'domain',
        width: 120,
        isVisible: true,
    },
    {
        header: "Sub-Platform",
        dataProperty: 'subDomain',
        width: 120,
        isVisible: true,
    },
    {
        header: "Budget year",
        dataProperty: 'budgetYear',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Center",
        dataProperty: 'costCenter',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Center Description",
        dataProperty: 'costCenterDescription',
        width: 120,
        isVisible: true,
    },
    {
        header: "Activity",
        dataProperty: 'activity',
        width: 300,
        isVisible: true,
    },
    {
        header: "WBS",
        dataProperty: 'poWbs',
        width: 120,
        isVisible: true,
    },
    {
        header: "Expense Group",
        dataProperty: 'expenseType',
        width: 120,
        isVisible: true,
    },
    {
        header: "Cost Type",
        dataProperty: 'costType',
        width: 120,
        isVisible: true,
    },
    {
        header: "Currency",
        dataProperty: 'currency',
        width: 120,
        isVisible: true,
    },
    {
        header: "Total Budget Plan",
        dataProperty: 'totalBudget',
        width: 120,
        isVisible: true,
    },
    {
        header: "Plan YTD",
        dataProperty: 'planYTD',
        width: 120,
        isVisible: true,
    },	
    {
        header: "Actuals YTD",
        dataProperty: 'actualsYTD',
        width: 120,
        isVisible: true,
    },
    {
        header: "Plan YTG",
        dataProperty: 'planYTG',
        width: 120,
        isVisible: true,
    },
    {
        header: "Hit Rate",
        dataProperty: 'hitRate',
        width: 120,
        isVisible: true,
    },
    {
        header: "Last Actuals Imported Date",
        dataProperty: 'lastImportedDate',
        width: 150,
        isVisible: true,
    }
]
const budgetPlansByActivityBo = [
    ...budgetPlansByActivity,
    {
        header: "Tags",
        width: 140,
        dataProperty: 'tags',
        isVisible: true
    },
    {
        header: "Line Tags",
        width: 140,
        dataProperty: 'lineTags',
        isVisible: true
    }
]
const years = [];
const currentYear = new Date().getFullYear();
years.push({label: currentYear -2, value: currentYear -2});
years.push({label: currentYear -1, value: currentYear -1});
years.push({label: currentYear, value: currentYear});
for (let i = 1; i <= 4; i++) {
    const year = currentYear + i;
    years.push({label: year, value: year});
}
const exportToExcelConfig = {
    activity: excelTypes.Text,
    actualsYTD: excelTypes.DecimalNumber,
    budgetPlanId: excelTypes.WholeNumber,
    budgetYear: excelTypes.WholeNumber,
    costCenter: excelTypes.Text,
    costCenterDescription: excelTypes.Text,
    costType: excelTypes.Text,
    currency: excelTypes.Text,
    domain: excelTypes.Text,
    expenseType: excelTypes.Text,
    hitRate: excelTypes.DecimalNumber,
    id: excelTypes.WholeNumber,
    lastImportedDate: excelTypes.Date,
    planYTD: excelTypes.DecimalNumber,
    planYTG: excelTypes.DecimalNumber,
    poWbs: excelTypes.Text,
    subDomain: excelTypes.Text,
    totalBudget: excelTypes.DecimalNumber,
}
const mapStateToProps = (state) => {
    let defaultColumns;
    if (state.user.roles.indexOf("backOffice") !== -1 || state.user.roles.indexOf("admin") !== -1) {
        defaultColumns = state.regionalChangelogs.planByCostCenter.value ? budgetPlansByCcBo : budgetPlansByActivityBo;
    } else {
        defaultColumns = state.regionalChangelogs.planByCostCenter.value ? budgetPlansByCc : budgetPlansByActivity;
    }
    const columns = state.regionalChangelogs.columns && state.regionalChangelogs.columns.length !== 0 ? state.regionalChangelogs.columns : defaultColumns;
    return {
        notificationModalDisabled: state.regionalChangelogs.notificationModalDisabled,
        sendingNotifications: state.regionalChangelogs.sendingNotifications,
        notificationModalOpen: state.regionalChangelogs.notificationModalOpen,
        notificationModalLoading: state.regionalChangelogs.notificationModalLoading,
        notificationModalQuestion: state.regionalChangelogs.notificationModalQuestion,
        tabs: state.regionalChangelogs.tabs,
        planByCostCenter: state.regionalChangelogs.planByCostCenter,
        tableState: state.table.parsedFilters,
        columns: columns,
        loading: state.regionalChangelogs.loading,
        count: state.regionalChangelogs.regionalChangelogs && state.regionalChangelogs.regionalChangelogs.itemsCount ? state.regionalChangelogs.regionalChangelogs.itemsCount : 0,
        currentPage: state.regionalChangelogs.regionalChangelogs && state.regionalChangelogs.regionalChangelogs.currentPage ? state.regionalChangelogs.regionalChangelogs.currentPage : 1,
        items: state.regionalChangelogs.regionalChangelogs && state.regionalChangelogs.regionalChangelogs.items ? state.regionalChangelogs.regionalChangelogs.items : [],
    }};
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(regionalChangelogActions.getRegionalChangelogColumnsStarted(payload));
        dispatch(regionalChangelogActions.initializeRegionalChangelogList({userOnly: payload.userOnly, params: payload.queryParams}));        
      },
      updateColumns: (payload) => {
        dispatch(regionalChangelogActions.updateRegionalChangelogColumns(payload));
      },
      getChangeLogs: (payload) => {
        dispatch(regionalChangelogActions.getRegionalChangelogsStarted(payload));
      },
      changeTab: (payload) => {
        dispatch(regionalChangelogActions.regionalChangelogsTabChanged(payload))
      },
      getColumns: (payload) => {
        dispatch(regionalChangelogActions.getRegionalChangelogColumnsStarted(payload))
      },
      modalOpenChanged: (payload) => 
        dispatch(regionalChangelogActions.sendBudgetPlanNotificationsModalVisibilityChanged(payload)),
      sendNotificationsStarted: (payload) =>
        dispatch(regionalChangelogActions.sendBudgetPlanNotificationsStarted(payload))
      
  });

class ChangeLogViewer extends Component {
    //should return different domains based on viewall/my changelogs tab
    isUserOnly = () => {
        return this.props.location.pathname.indexOf('/my') !== -1;
    }

    componentDidMount() {
        const tableState = this.props.tableState[this.getTableName(this.props.planByCostCenter.value)];
        const queryParameters = tableState ? {...tableState} : {};
        this.props.loadView({
            userOnly: this.isUserOnly(),
            tableName: this.getTableName(this.props.planByCostCenter.value),
            queryParameters: queryParameters,
        });
    }
    getTableName = (planByCostCenter) => {
       return `regionalchangelog${this.isUserOnly() ? "-user": "" }${planByCostCenter ? "-cc" : "-activitiy"}`
    }

    handleHistoryClick = (data, e) => {
        if (e && e.button === 2) {
            return;
        }
        if (this.isUserOnly()) {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin+`/budgetPlans/my/${data.budgetPlanId}`, "_blank"); 
            } else {
                history.push(`/budgetPlans/my/${data.budgetPlanId}`);
            }
        } else {
            if (e && (e.ctrlKey || e.button === 1)) {
                window.open(window.location.origin+`/budgetPlans/viewAll/${data.budgetPlanId}`, "_blank"); 
            } else {
                history.push(`/budgetPlans/viewAll/${data.budgetPlanId}`);
            }
        }
    }
    
    handleTabChange = (e, index) => {
        this.props.changeTab(this.props.tabs[index]);
        this.props.getColumns({tableName: this.getTableName(this.props.tabs[index].value)});
        const tableState = this.props.tableState[this.getTableName(this.props.tabs[index].value)];
        const queryParameters = tableState ? {...tableState} : {};
        const userOnly = this.isUserOnly();
        this.props.getChangeLogs({userOnly: userOnly, params: queryParameters, wait: false});
    }

    onFetchData = (data, wait) => {
        const userOnly = this.isUserOnly();
        const queryParameters = data;
        this.props.getChangeLogs({userOnly: userOnly, params: queryParameters, wait: wait});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        if (this.isUserOnly()) {
            this.props.updateColumns({tableName: this.getTableName(this.props.planByCostCenter.value), columns: payload});
        } else {
            this.props.updateColumns({tableName: this.getTableName(this.props.planByCostCenter.value), columns: payload});
        }
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        if (this.isUserOnly()) {
            this.props.updateColumns({tableName: this.getTableName(this.props.planByCostCenter.value), columns: payload});
        } else {
            this.props.updateColumns({tableName: this.getTableName(this.props.planByCostCenter.value), columns: payload});
        }
    }

    getFileName = () => {
        if (this.isUserOnly()) {
            return "BudgetPlans-MyBudgetPlans"
        }  else {
            return "BudgetPlans-ViewAll"
        }
    }
    getMinWidth = () => {
        let minWidth = 0;
        this.props.columns.forEach((column) => {
            if (column.isVisible) {
                minWidth += column.width;
            }
        });
        minWidth +=40;
        return minWidth;
    }
    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleHistoryClick, label: 'Edit'}
                    ]
                }
                rowData={data}
            />
        )
    }
    getDataExportUrl = () => {
        let filterUrl = "api/";
        if (this.isUserOnly()) {
            filterUrl +="user/"
        }
        if (this.props.planByCostCenter.value) {
            filterUrl += "budgetPlans/export/costCenter";
        } else {
            filterUrl +="budgetPlans/export/activity"
        }
        return filterUrl;
    }
    render() {
        return (
            <React.Fragment>
                <Tabs onTabChanged={this.handleTabChange} selectedTab={this.props.planByCostCenter} tabs={this.props.tabs}/>
                <Dialog 
                    open={this.props.notificationModalOpen}
                    onClose={this.props.modalOpenChanged.bind(this, false)}
                    onSubmit={this.props.sendNotificationsStarted}
                    cancelButtonText={this.props.notificationModalDisabled ? "CLOSE" : "NO"}
                    submitButtonText={this.props.notificationModalDisabled ? null : "YES" }
                    title={"Send actuals notifications"}
                >
                    <div style={{padding: 10}}>
                        <LoadingSpinner loading={this.props.notificationModalLoading}/>
                        <span style={{textAlign: 'center', display: 'inline-block'}}>{this.props.notificationModalQuestion}</span>
                    </div>
                </Dialog>
                <div style={{marginRight: 48, marginTop: 32} }>
                {
                    canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) ?
                    <div style={{display: 'flex', marginBottom: 10}}>  
                        <div style={{display: 'flex', paddingRight: 8, marginTop: 10}}>
                            <Button
                                style={{marginRight: 'auto'}} 
                                onClick={this.props.modalOpenChanged.bind(this, true)} 
                                disabled={this.props.sendingNotifications}
                                className="removed-text-padding blue-color">
                                <LaunchIcon />
                                Send actuals notifications
                            </Button>
                        </div>
                    </div>
                    : 
                    null
                }
                <div style={{display: 'flex'}}>
                <DataExportContainer 
                    url={this.getDataExportUrl()}
                    tableName={this.getTableName(this.props.planByCostCenter.value)}
                    fileName={this.getFileName()}
                    fieldsTypes={exportToExcelConfig}
                />
                {
                    canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) ?
                    <div style={{marginLeft: 20}}>
                    <ExportToCsv
                        url="api/export/budgetPlans"
                    /></div> : null
                }
                </div>
                <DataTableWithServerSizePagination
                    sortable
                    navigateToDetail={this.handleHistoryClick}
                    key={this.getTableName(this.props.planByCostCenter.value)}
                    tableName={this.getTableName(this.props.planByCostCenter.value)}
                    minWidth={this.getMinWidth()}
                    customColumnsWidthEnabled={true}
                    customFilters={{
                        budgetYear: (filterValue, onChange) => {
                            return (
                                <AutoSelect
                                    isClearable={true}
                                    additionalData="budgetYear"
                                    label="Budget Year"
                                    small
                                    width="100%"
                                    minWidth={120}
                                    suggestions={years}
                                    value={filterValue}
                                    handleChangeSingle={onChange}
                                />
                            )
                        }
                    }}
                    loading={this.props.loading}
                    count={this.props.count}
                    general={{}}
                    columns={this.props.columns}
                    onFetchData={this.onFetchData}
                    data={this.props.items}   
                    actionMenu={this.createActionMenu}
                    onColumnsOrderChanged={this.onColumnOrderChanged}
                    onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                    currentPage={this.props.currentPage}
                />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogViewer);