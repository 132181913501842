import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
const width = 340;

const StyledMenu = withStyles({
  paper: {
    borderRadius: 16,
    width: width,
    marginTop: 8,
  },
})(props => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

function createStyles(props, anchorEl) {
  const highlightFabStyles = {
    border: '4px solid ' + props.color,
    color: props.color,
    backgroundColor: '#fff',
  };
  const fabStyles = {
    root: {
      borderRadius: 30,
      margin: 20,
      color: '#fff',
      fontWeight: 'bold',
      backgroundColor: '#0068b3',
      width: width,
      '&:hover': {
        ...highlightFabStyles
      }
    },
    icon: {
      width: 80,
      height: 80,
      marginBottom: 36,
      color: '#0068b3'

    }
  }
  if (anchorEl) {
    fabStyles.root = Object.assign({}, fabStyles.root, highlightFabStyles);
    fabStyles.icon.color = props.color
  }
  return fabStyles;
}

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);


  const useStyles = makeStyles(createStyles(props, anchorEl));

  const classes = useStyles();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }


  return (
    <div style={{ display: 'flex', alignItems:'center', flexDirection:'column', marginTop: 40}}> 
      <props.icon  className={classes.icon}/>

      <Fab
        className={classes.root}
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={props.subItems.length === 1 ? props.onItemClick.bind(this, props.subItems[0].url): handleClick}
      >
        {props.name}
      </Fab>

      {props.subItems.length !== 1 ? 
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.subItems.map((option) => {
          return (
          <MenuItem key={option.action} onClick={props.onItemClick.bind(this, option.url)}>
            <ListItemText primary={option.label} />
          </MenuItem>
          )
        })}
      </StyledMenu> : null
      }
    </div>
  );
}
