import React, { Component } from 'react';
import DataTableWithServerSizePagination from '../../../../components/DataTable/DataTableWithServerSidePagination';
import SimpleMenu from '../../../../components/Menu/SimpleMenu';
import MoreVert from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux';
import * as importMasterDataActions from '../../../../store/actions/importMasterData';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import * as changelogFormActionsCreators from '../../../../store/actions/changeLogFormActions';
const defaultColumns = [
    {
        header: "Date",
        dataProperty: 'createdDateTimeUtc',
        isVisible: true,
        width: 60
    },    
    {
        header: "Imported File",
        dataProperty: 'importedFilePath',
        isVisible: true,
        width: 60
    },
    {
        header: "Result File",
        dataProperty: 'errorResultFilePath',
        isVisible: true,  
        width: 60
    },
    {
        header: "Status",
        dataProperty: 'importStatus',
        isVisible: true,  
        width: 60
    },
    {
        header: "Uploaded by",
        dataProperty: 'uploadedByUserEmail',
        isVisible: true,
        width: 60
    }
]

const mapStateToProps = (state) => {
    const columns = state.importMasterData && state.importMasterData.columns && state.importMasterData.columns.length !== 0 ? state.importMasterData.columns : defaultColumns;
    // let wbses = []
    // if (state.wbs.wbses && state.wbs.wbses.items) {
    //     wbses = state.wbs.wbses.items
    // }
    return {
        tableState: state.table.parsedFilters,
        columns: columns,
        loading: state.importMasterData.loading || state.attachment.loading,
        files: state.importMasterData.files && state.importMasterData.files.items ? state.importMasterData.files.items : [],
        currentPage: state.importMasterData.files && state.importMasterData.files.currentPage ? state.importMasterData.files.currentPage : 1,
        count: state.importMasterData.files && state.importMasterData.files.itemsCount ? state.importMasterData.files.itemsCount : 0,
    }
};
  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        let params;
        if (payload.queryParameters) {
        params = payload.queryParameters
        } else {
            params = { domain:payload.domain }
        }
        dispatch(importMasterDataActions.getImportMasterDataListStarted({params: params}));
        dispatch(importMasterDataActions.getImportMasterDataColumnsStarted(payload))
      },
      updateColumns: (payload) => {
        dispatch(importMasterDataActions.updateImportMasterDataColumnsStarted(payload));
      },
      getColumns: (payload) => {
        dispatch(importMasterDataActions.getImportMasterDataColumnsStarted(payload))
      },
      getFiles: (payload) => {
          dispatch(importMasterDataActions.getImportMasterDataListStarted(payload))
      },
      attachmentUploaded: (payload) => {
        dispatch(importMasterDataActions.importMasterDataFileUploadStarted(payload));
      },
      downloadAttachment: (payload) => {
          dispatch(changelogFormActionsCreators.changelogFormDownloadAttachment(payload))
      },
      saveFilters: (payload) => {
          dispatch(importMasterDataActions.importMasterDataFiltersChanged(payload))
      }
  });

class ImportMasterData extends Component {

    componentDidMount() {
        const tableState = this.props.tableState[this.getTableName(this.props.selectedDomain)];
        if (tableState) {
            const domain = this.props.selectedDomain;
            const queryParameters = {...tableState};
            queryParameters.domain = domain;
            this.props.loadView({
                queryParameters: queryParameters,
                domain: this.props.selectedDomain
            });
        } else {
            this.props.loadView({
                domain: this.props.selectedDomain,
            });
        }
    }
    getTableName = () => {
        return `importMasterdata`
    }


    onFetchData = (data, wait) => {
        const queryParameters = data;
        this.props.getFiles({params: queryParameters, wait: wait});
    }

    onColumnOrderChanged = (columns) => {
        const payload = JSON.stringify(columns);
        this.props.updateColumns({columns: payload});
    }

    onColumnVisibilityChanged = (newColumns) => {
        const payload = JSON.stringify(newColumns);
        this.props.updateColumns({columns: payload});
    }


    createActionMenu = (data) => {
        return (
            <SimpleMenu 
                icon={MoreVert}
                items={
                    [
                        {onClick: this.handleEditClick, label: 'no actions'}
                    ]
                }
                rowData={data}
            />
        )
    }
    downloadFile = (data) => {
        this.props.downloadAttachment({name: data});
    }
    render() {
        return (
            <React.Fragment>
                <div style={{marginRight: 48, marginTop: 32} }>
                <div style={{marginTop: 20, marginBottom: 20}}>
                <React.Fragment>
                    <input
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    accept=".csv"
                    onChange={this.props.attachmentUploaded}
                    />
                    <label htmlFor="raised-button-file">
                        <Button color="primary" 
                            component="span"
                            className="removed-text-padding">
                            <LaunchIcon />
                            Import CSV
                        </Button>
                    </label>
                </React.Fragment> 
                </div>
                {this.props.generatingDCs ?
                    <div>
                        <span>Import Master Data status</span>
                        <div style={{display: 'flex', alignItems: 'center', flex: '1', height: 20}}>
                            <LinearProgress variant="determinate" value={this.props.progress}/>
                        </div>
                    </div> 
                     : null
                 }    
                <DataTableWithServerSizePagination
                    sortable
                    tableName={this.getTableName(this.props.selectedDomain)}
                    minWidth={980}
                    customColumnsWidthEnabled={true}
                    loading={this.props.loading}
                    customRowCells={{
                        importedFilePath: (data, id, row) => {
                            return (
                                <div style={{paddingLeft: 12}}>
                                    { data ?
                                    <Button 
                                        color="primary" 
                                        component="span"
                                        className="removed-text-padding"
                                        onClick={this.downloadFile.bind(this, data)}>
                                        {data}
                                    </Button> 
                                    : null}
                                </div>
                            )
                        },
                        errorResultFilePath: (data, id, row) => {
                            return (
                                <div style={{paddingLeft: 12}}>
                                    {data ? 
                                    <Button 
                                    color="primary" 
                                    component="span"
                                    className="removed-text-padding"
                                    onClick={this.downloadFile.bind(this, data)}>
                                        {data}
                                    </Button>
                                    : null}
                                </div>
                            )
                        },
                    }}
                    saveFilterState={this.props.saveFilters}
                    count={this.props.count}
                    general={{}}
                    columns={this.props.columns}
                    onFetchData={this.onFetchData}
                    data={this.props.files}   
                    actionMenu={this.createActionMenu}
                    onColumnsOrderChanged={this.onColumnOrderChanged}
                    onColumnVisibilityChanged={this.onColumnVisibilityChanged}
                    currentPage={this.props.currentPage}
                />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportMasterData);