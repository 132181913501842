import React, { Component } from 'react';
import Menu from './components/Menu/Menu';
import withStyles from '@material-ui/styles/withStyles';
import Assignment from "@material-ui/icons/AssignmentOutlined";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedInOutlined";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import List from "@material-ui/icons/ListOutlined";
import * as rules from '../../config/Rules';
import Can, {canFunction} from '../../config/Can';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as userActionCreators from '../../store/actions/User';
import * as domainsActions from '../../store/actions/domains';
import DomainPicker from './components/DomainPicker/DomainPicker';
import AppBar from '../../components/AppBar/AppBar';
import Drawer from '../../components/Drawer/Drawer';
import MenuForNonBackOffice from './components/MenuForNonBackOffice/MenuForNonBackOffice';


const styles = (theme) => ({
    toolbar: theme.mixins.toolbar,
    wrapper: {
        width: '100%',
        // width: 'auto !important',
        backgroundColor: theme.palette.primary.main,
        height: 'auto !important',
        minHeight: '100%',
        display: 'block',
    },
    contentWrapper: {
        verticalAlign: 'middle',
        paddingTop: 40,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
    },
    domainsWrapper: {
        width: 280
    },
    viewsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        // marginBottom: 200,
        flexWrap: 'wrap'
    },
    title: {
        color: '#fff',
        fontSize: 20,
        lineHeight: '60px',
        top: 10,
        left: 24,
        textTransform: 'uppercase',
        zIndex: 2,
        marginLeft: 16
    },
    greeting: {
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
        color: '#000',
        fontSize: 40,
        paddingTop: 30,
        lineHeight: '48px'
    },
    cardsWrapper: {
        display: 'flex',
    },
    deletedBoxShadow: {
        boxShadow: 'none'
    }
})

const mapStateToProps = (state) => {
    return {
        roles: state.user.roles,
        name: state.user.name,
        selectedRole: state.user.selectedRole,
        selectedDomain: state.user.selectedDomain,
        domains: state.domains.domains,
        isBackOffice: canFunction('', rules.IS_BACKOFFICE_OR_ADMIN),
        userTabsVisible: state.user.supplierTabsVisible
    }};
  
const mapDispatchToProps = (dispatch) => ({
    selectDomain: (payload) => {
        dispatch(userActionCreators.selectDomain(payload));
        dispatch(userActionCreators.selectUserDomain(payload));
    },
    selectRole: (payload) =>
        dispatch(userActionCreators.selectUserRole(payload)),
    getCurrentlyLoggedInUser: () => {
        dispatch(userActionCreators.getCurrentlyLoggedInUser())
    },
    getDomainsStarted: () => {
        dispatch(domainsActions.getDomainsStarted())
    }
});

class LandingPage extends Component {
    state = {
        drawerOpen: true,
        views: [
            {
                name: 'Change Logs',
                description: 'Create a new changelog (a request to include or change financial amounts in the budget for a given activity) or modify an existing one. View all the changelogs created by you or where you are Delivery Confirmation approver.',
                subItems: [
                    {
                        action: rules.NAVIGATE_CHANGELOG_VIEWALL,
                        label: 'View all Change Logs',
                        url: '/changelogs/viewAll',
                    },
                    {
                        action: rules.NAVIGATE_CHANGELOG_VIEWMY,
                        label: 'Review my Change Logs',
                        url: '/changelogs/my',
                    },
                    {
                        action: rules.NAVIGATE_CHANGELOG_CREATE,
                        label: 'Create new Change Log',
                        url: '/changelogs/create'
                    }
                ],
                icon: Assignment,
                color: '#39acff'
            },
            {
                name: 'Delivery Confirmation',
                description: 'Electronic confirmation of delivery of goods and services submitted by the supplier and subsequently approved by PMI representative as per the approval workflow.',
                subItems: [
                    {
                        action: rules.NAVIGATE_DELIVERY_REVIEW,
                        label: 'View my DCs',
                        url: '/deliveryconfirmation/viewMy'
                    },
                    {
                        action: rules.NAVIGATE_DELIVERY_SUBMIT,
                        label: 'Submit my DCs',
                        url: '/deliveryconfirmation/submit'
                    },
                    {
                        action: rules.NAVIGATE_DELIVERY_FILL_IN,
                        label: 'Fill in my DCs',
                        url: '/deliveryconfirmation/submit'
                    },
                    {
                        action: rules.NAVIGATE_DELIVERY_APPROVE,
                        label: 'Approve my DCs',
                        url: '/deliveryconfirmation/approve'
                    },
                    {
                      action: rules.NAVIGATE_DELIVERY_VIEW_ALL,
                      label: 'View all',
                      url: '/deliveryconfirmation/viewAll'
                    },
                    {
                      action: rules.NAVIGATE_DELIVERY_TO_BE_PROCESSED,
                      label: 'To be processed',
                      url: '/deliveryconfirmation/toBeProcessed'
                    }
                ],
                icon: AssignmentTurnedIn,
                color: '#9f7e5f'
            },
            {
                name: 'Resource Model',
                subItems: [
                    {
                        action: rules.NAVIGATE_RESOURCEMODEL,
                        url: '/resourcemodel',
                    }
                ],
                icon: List,
                color: '#595959'
            },
            {
                name: 'Budget Plans',
                description: 'Maintain budget plans for your respective Cost Center and Expense type OR Reflect the budget for specific activities (BAU / Projects) to a more granular level. Track actuals Year to date vs the planned budget.',
                subItems: [
                    {
                      action: rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_CC,
                      label: 'Create Plans by Cost Center',
                      url: "/budgetPlans/createByCc"
                    },
                    {
                      action: rules.NAVIGATE_CREATE_BUDGET_PLAN_BY_ACTIVITY,
                      label: 'Create Plans by Activity',
                      url: "/budgetPlans/createByActivity"
                    },
                    {
                      action: rules.NAVIGATE_VIEW_MY_BUDGET_PLANS,
                      label: "View my Plans and Actuals",
                      url: "/budgetPlans/my"
                    },
                    {
                        action: rules.NAVIGATE_VIEW_ALL_BUDGET_PLANS,
                        label: "View all Plans",
                        url: "/budgetPlans/viewAll"
                    }
                  ],
                icon: AccountBalanceOutlinedIcon,
                color: '#39acff'
            },
        ],
    }
    hideDrawer = () => {
        this.setState({
            drawerOpen: false
        })
    }
    toggleDrawer = (isOpen) => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }

    componentDidMount() {
        this.props.getCurrentlyLoggedInUser();
        this.props.getDomainsStarted();
    }

    filterOptionsVisible = (role) => {
        //copy all available options
        let views = [...this.state.views];
        views = views.map((view) => {
            const viewCopy = {...view};
            viewCopy.subItems = [];
            return viewCopy
        });
        //filter options based on role
        this.state.views.forEach((view, index) => {
            view.subItems.forEach((subItem) => {
                if (canFunction(role, subItem.action)) {
                    views[parseInt(index)].subItems.push(subItem);
                }
            })
        });
        return views;
    }
    navigate = (to) => {
        this.props.history.push(to);
    }

    render() {
        const views = this.filterOptionsVisible(this.props.selectedRole);
        const domainsWithViewAll = [...this.props.domains];
        domainsWithViewAll.push({
            id:'view all',
            name: 'view all'
        });

        return (
            <div className={this.props.classes.wrapper} style={{backgroundColor: this.props.isBackOffice ? "#fff" : "" }}>
                <AppBar paths={[{
                            label: 'IT Planning and Tracking Tool',
                            url: '/'
                        }]} 
                        classes={{root: this.props.classes.deletedBoxShadow}}
                        onBreadCrumbItemClick={this.navigate}
                        hiddenActionButton={!this.props.isBackOffice}
                        onIconButtonClicked={this.toggleDrawer}
                />
                    <div className={this.props.classes.cardsWrapper}>
                        <Can 
                            role={this.props.selectedRole}
                            perform={rules.LANDINGPAGE_VIEWDOMAINS}
                            yes={() => {
                                return (
                                    <Drawer drawerWidth={300} isOpen={this.state.drawerOpen} hideDrawer={this.hideDrawer}>
                                        <div className={this.props.classes.domainsWrapper}>
                                            <DomainPicker 
                                                selectedItem={this.props.selectedDomain} 
                                                handleClick={this.props.selectDomain} 
                                                items={domainsWithViewAll}
                                            />
                                        </div>
                                    </Drawer>
                                )
                                }
                            }
                        />
                        <div style={{paddingLeft: 20, width: '100%'}}>
                            <div className={this.props.classes.toolbar} />
                            <span style={{color: !this.props.isBackOffice ? "#fff" : "" }} className={this.props.classes.greeting}>Hi {this.props.name}, what do you want to work on?</span>
                            <div className={this.props.classes.contentWrapper}>
                                <div className={this.props.classes.viewsWrapper}>
                                <Can 
                                    role={this.props.selectedRole}
                                    perform={rules.LANDINGPAGE_VIEWDOMAINS}
                                    yes={() => {
                                        return (
                                            <React.Fragment>
                                                {views.map((view) => {
                                                    return view.subItems.length !== 0 ? 
                                                (<Menu key={view.name} {...view} onItemClick={this.navigate}/>) : null;
                                                })}
                                            </React.Fragment>
                                        )
                                    }}
                                    no={() => {
                                        return (
                                            <React.Fragment>
                                                {views.map((view) => {
                                                    return view.subItems.length !== 0 ? 
                                                (<MenuForNonBackOffice key={view.name} {...view} onItemClick={this.navigate}/>) : null;
                                                })}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(LandingPage);