import { put, takeEvery, take, race, call, all } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import { select } from 'redux-saga/effects';
import * as vendorRateActions from '../store/actions/vendorRates';
import * as errorDialogActions from '../store/actions/errorDialogActions';
import { oneColumnDictionariesSagas, getResourceTypesByVendor } from './dictionariesSagas';
import {deleteFiles, attachFiles} from './formAttachmentSagas';
import axios from '../axios';
import history from '../history';
import resourceTypes from '../constants/resourceTypes';
import assignmentSites from '../constants/assignmentSites';
import { isVendorTypeExternal, isPerDiemVisible, isGradeVisible, isLocationVisible, isAssignmentSiteVisible } from '../utils/typesAssertions';
import { vendorTypeEnums } from '../constants/vendorTypes';
import scrollToErrorSaga from './scrollToErrorSaga';
import * as commentUtils from '../utils/commentUtils';

function* getDictionaries(vendorRate) {
    vendorRate = vendorRate || {};
    const [vendorsResponse, internalVendors] = yield all([
        call(axios.get, 'api/vendors', {params: {pageable: false, vendorType: vendorTypeEnums.External}}),
        call(axios.get, 'api/vendors', {params: {pageable: false, vendorType: vendorTypeEnums.Internal}}),
        call(oneColumnDictionariesSagas['grades'].get, vendorRate.gradeId),
        call(oneColumnDictionariesSagas['currencies'].get, vendorRate.currencyId),
        call(oneColumnDictionariesSagas['resourceLocations'].get, vendorRate.resourceLocationId),
        call(getResourceTypesByVendor)
    ]);
    //I assume here that PMI vendor will never be marked inactive
    if (vendorRate.vendor && vendorRate.vendor.name !== 'PMI') {
        let vendor = vendorsResponse.data.items.find((vendor) => {
            return vendor.id === vendorRate.vendor.id;
        });
        if (!vendor) {
            vendorsResponse.data.items.push(vendorRate.vendor);
        }
    }
    yield put(vendorRateActions.vendorRatesFormInternalVendorsChanged(internalVendors.data.items));
    yield put(vendorRateActions.vendorRatesFormExternalVendorsChanged(vendorsResponse.data.items));
}

function* initializeVendorRateFormSaga() {
    try {
        yield* getDictionaries();
        yield put(vendorRateActions.vendorRatesFormInitializeFinished());

    } catch (error) {
        yield put(vendorRateActions.vendorRatesFormInitializeFailed());
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}
function* initializeEditVendorRateFormSaga(action) {
    try {
        const response = yield axios.get('api/vendorrates/' + action.payload);
        const vendorRate = response.data;
        yield* getDictionaries(vendorRate);

        if (vendorRate.assignmentSite !== undefined) {
            yield put(vendorRateActions.vendorRatesFormAssignmentSiteChanged({
                label: assignmentSites[vendorRate.assignmentSite],
                value: vendorRate.assignmentSite
            }))
        }
        if (vendorRate.gradeId) {
            const grades = yield select((store) => store.grades.items);
            const grade = grades.find((grade) => {
                return grade.id === vendorRate.gradeId;
            })
            yield put(vendorRateActions.vendorRatesFormGradeChanged({
                label: grade.name,
                value: grade.id
            }))
        }
        const currencies = yield select((store) => store.currencies.items);
        const currency = currencies.find((currency) => {
            return currency.id === vendorRate.currencyId;
        });
        yield put(vendorRateActions.vendorRatesFormCurrencyChanged({
            label: currency.name,
            value: currency.id
        }));
        yield put(vendorRateActions.vendorRatesFormCommentsChanged(vendorRate.comments.map((comment) => {
            return {
                ...comment,
                message: commentUtils.getEditorState(comment.message)
            }
        })));
        yield put(vendorRateActions.vendorRatesFormAlreadyInUseChanged(vendorRate.alreadyInUse));
        yield put(vendorRateActions.vendorRatesFormIdChanged(vendorRate.id));
        yield put(vendorRateActions.vendorRatesFormPerDiemRateChanged(vendorRate.perDiemRate));
        yield put(vendorRateActions.vendorRatesFormInactiveChanged(vendorRate.isDeleted || false));
        yield put(vendorRateActions.vendorRatesFormRateYearChanged({
            label: vendorRate.rateYear,
            value: vendorRate.rateYear
        }));
        if (vendorRate.resourceLocationId) {
            const resourceLocations = yield select((store) => store.resourceLocations.items);
            const resourceLocation = resourceLocations.find((resourceLocation) => {
                return resourceLocation.id === vendorRate.resourceLocationId;
            })
            yield put(vendorRateActions.vendorRatesFormResourceLocationChanged({
                label: resourceLocation.name,
                value: resourceLocation.id
            }));
        }
        yield put(vendorRateActions.vendorRatesFormResourceTypeChanged({
            label: resourceTypes[vendorRate.resourceType],
            value: vendorRate.resourceType
        }));
        yield put(vendorRateActions.vendorRatesFormUnitPriceChanged(vendorRate.unitPrice));
        yield put(vendorRateActions.vendorRatesFormVendorChanged({
            label: vendorRate.vendor.name,
            value: vendorRate.vendor.id
        }));
        for (let i = 0; i < vendorRate.attachments.length; i++) {
            vendorRate.attachments[parseInt(i)].key = vendorRate.attachments[parseInt(i)].id;
            yield put(vendorRateActions.vendorRateFormAttachmentAdded({target: {files:[vendorRate.attachments[parseInt(i)]]}}));
        }
        yield put(vendorRateActions.vendorRateFormClientIdChanged(vendorRate.clientId));
        yield put(vendorRateActions.vendorRatesEditFormInitializeFinished())
    } catch (error) {
        yield put(vendorRateActions.vendorRatesEditFormInitializeFailed())
        yield put(errorDialogActions.openErrorDialog("Couldn't initialize form. Please try again later"));
    }
}

function* submitFormSaga() {
    try {
        const formState = yield select((state) => state.vendorRateForm);
        const errors = validate(formState);
        if (Object.keys(errors).length !== 0) {
            yield put(vendorRateActions.vendorRatesFormErrorsChanged(errors));
            yield put(vendorRateActions.vendorRatesFormSubmitFailed())
            yield* scrollToErrorSaga();
            return;
        }

        const resourceType = formState.resourceType.value;
        const resourceTypePerVendorType = yield select((store) => store.resourceTypeVendorType.resourceTypes);
        const selectedResourceTypePerVendorType = resourceTypePerVendorType.find((item) => {
            return item.resourceType === resourceType;
        })
        let vendors;
        if (!isVendorTypeExternal(selectedResourceTypePerVendorType.vendorType)) {
            vendors = formState.internalVendors;
        } else {
            vendors = formState.externalVendors;
        }
        const vendor = vendors.find((vendor) => {
            return vendor.id === formState.vendor.value;
        });
        const data = {
            clientId: formState.clientId,
            attachments: formState.attachments,
            currencyId: formState.currency.value,
            rateYear: formState.rateYear.value,
            resourceType: formState.resourceType.value,
            vendor: vendor,
            unitPrice: formState.unitPrice,
            comment: commentUtils.getPureText(formState.comment).length !== 0 ? commentUtils.prepareEditorStateForSendingToDatabase(formState.comment) : "",
            isDeleted: formState.isDeleted
        }
        if (isAssignmentSiteVisible(formState.resourceType.value)) {
            data.assignmentSite = formState.assignmentSite.value;
        }
        if (isPerDiemVisible(formState.resourceType.value, formState.assignmentSite.value)) {
            data.perDiemRate = formState.perDiemRate
        }
        if (isGradeVisible(formState.resourceType.value)) {
            data.gradeId = formState.grade.value;
        }
        if (isLocationVisible(formState.resourceType.value)) {
            data.resourceLocationId = formState.resourceLocation.value
        }

        let id = formState.id;
        const val = yield* attachFiles(formState.attachments);
        data.attachments = val;
        if (id) {
            data.id = id;
            const vendorRate = yield axios.get('api/vendorrates/'+ id);
            yield axios.put('api/vendorrates', data);
            yield* deleteFiles(formState.attachments, vendorRate.data.attachments)
            yield put(vendorRateActions.vendorRatesFormSubmitFinished());
        } else {
            yield axios.post('api/vendorrates', data);
            yield put(vendorRateActions.vendorRatesFormSubmitFinished());
        }
        history.push('/masterdata/viewallvendorrates');
    } catch(error) {
        yield put(vendorRateActions.vendorRatesFormSubmitFailed());
        yield put(errorDialogActions.openErrorDialog("There was an error while submitting form. Please try again later"));
    }
}

function* resourceTypeChanged(action) {
    try {
    const resourceType = action.payload.value;
    const resourceTypePerVendorType = yield select((store) => store.resourceTypeVendorType.resourceTypes);
    const selectedResourceTypePerVendorType = resourceTypePerVendorType.find((item) => {
        return item.resourceType === resourceType;
    })
    yield put(vendorRateActions.vendorRatesFormErrorsChanged({}));
    const selectedVendor = yield select((store) => store.vendorRateForm.vendor);
    if (!selectedVendor.value) {
        return;
    }
    const internalVendors = yield select((store) => store.vendorRateForm.internalVendors);
    const isInternalVendor = internalVendors.find((vendor) => {
        return vendor.id === selectedVendor.value;
    });
    if ((isInternalVendor && isVendorTypeExternal(selectedResourceTypePerVendorType.vendorType))
    || (!isInternalVendor && !isVendorTypeExternal(selectedResourceTypePerVendorType.vendorType))) {
        yield put(vendorRateActions.vendorRatesFormVendorChanged(''));
    }
    } catch (error) {
        yield put(errorDialogActions.openErrorDialog("There was an error while updating form data. Please try again later"));
    }
}

function* resourceTypeWrapperSaga(action) {
    yield race({
        task: call(resourceTypeChanged, action),
        cancel: take(actionTypes.RESET_VENDOR_RATES_FORM)
    })
}

function* initializeVendorRateFormWrapperSaga(action) {
    yield race({
        task: call(initializeVendorRateFormSaga, action),
        cancel: take(actionTypes.RESET_VENDOR_RATES_FORM)
    })
}
function* submitFormWrapperSaga(action) {
    yield race({
        task: call(submitFormSaga, action),
        cancel: take(actionTypes.RESET_VENDOR_RATES_FORM)
    })
}
function* initializeEditVendorRateFormWrapperSaga(action) {
    yield race({
        task: call(initializeEditVendorRateFormSaga, action),
        cancel: take(actionTypes.RESET_VENDOR_RATES_FORM)
    })
}

function validate(formState) {

    const errors = {};
    if (!formState.vendor.value) {
        errors['vendor'] = "Vendor cannot be empty";
    }
    if (!formState.rateYear.value) {
        errors['rateYear'] = "Rate year cannot be empty";
    }
    if (!formState.resourceType.label) {
        errors['resourceType'] = "Resource type cannot be empty";
    }
    if (!formState.currency.value) {
        errors['currency'] = "Currency cannot be empty";
    }
    if (!formState.unitPrice && parseInt(formState.unitPrice) !== 0) {
        errors['unitPrice'] = "Unit price cannot be empty";
    }
    if (formState.resourceType && isAssignmentSiteVisible(formState.resourceType.value)) {
        if (!formState.assignmentSite.label) {
            errors['assignmentSite'] = "Assignment Site cannot be empty";
        }
    }
    if (formState.resourceType && formState.assignmentSite && isPerDiemVisible(formState.resourceType.value, formState.assignmentSite.value) && !formState.perDiemRate && parseInt(formState.perDiemRate) !==0) {
        errors['perDiemRate'] = "Per diem rate cannot be empty";
    }
    if (formState.resourceType && isLocationVisible(formState.resourceType.value)) {
        if (!formState.resourceLocation.value) {
            errors['resourceLocation'] = "Resource location cannot be empty";
        }
    }
    if (formState.resourceType && isGradeVisible(formState.resourceType.value)) {
        if (!formState.grade.value) {
            errors['grade'] = "Profile/grade cannot be empty";
        }
    }
    return errors;
}

export default [
    takeEvery(actionTypes.VENDOR_RATES_FORM_INITIALIZE_STARTED, initializeVendorRateFormWrapperSaga),
    takeEvery(actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_STARTED, initializeEditVendorRateFormWrapperSaga),
    takeEvery(actionTypes.VENDOR_RATES_FORM_SUBMIT_STARTED, submitFormWrapperSaga),
    takeEvery(actionTypes.VENDOR_RATES_FORM_RESOURCE_TYPE_CHANGED ,resourceTypeWrapperSaga)
]