import React from 'react';
import formSectionWrapper from '../ChangelogForm/components/FormSectionWrapper';
import activityTypes from '../../../constants/activityTypes';
import Input from '../../../components/Input/Input';

export default formSectionWrapper((props) => {
    return (
        <React.Fragment>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Platform" 
                    width="100%" 
                    disabled 
                    value={props.domain || ""} 
                    className={props.changes && props.changes.domain ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.domain}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Sub-platform" 
                    width="100%" 
                    disabled 
                    value={props.subDomain || ""} 
                    className={props.changes && props.changes.subDomain ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.subDomain}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Activity type" 
                    width="100%" 
                    disabled 
                    value={activityTypes[props.activityType] || ""} 
                    className={props.changes && props.changes.activityType ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.activityType}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Activity name" 
                    width="100%" 
                    disabled 
                    value={props.activityName} 
                    className={props.changes && props.changes.activityName ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.activityName}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="PO location" 
                    width="100%" 
                    disabled 
                    value={props.poLocation} 
                    className={props.changes && props.changes.poLocation ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.poLocation}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Cost Type" 
                    width="100%" 
                    disabled 
                    value={props.costType || ""} 
                    className={props.changes && props.changes.costType ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.costType}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="PO WBS" 
                    width="100%" 
                    disabled
                    value={props.powbs || ""} 
                    className={props.changes && props.changes.powbs ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.powbs}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Input 
                    label="Year" 
                    width="100%" 
                    disabled 
                    value={props.year} 
                    className={props.changes && props.changes.year ?  "input-updated":"disabled-form-input"}
                    tooltipText={props.changes && props.changes.year}
                />
            </div>
        </React.Fragment>
    )
}, "Delivery Information")