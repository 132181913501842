import React, {Component} from 'react';
import AppBar from '../../components/AppBar/AppBar';
import Save from '@material-ui/icons/SaveOutlined';
import { withRouter } from "react-router";
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/Navbar';
import * as changelogFormActionsCreators from '../../store/actions/changeLogFormActions';
import Fab from '@material-ui/core/Fab';
import {canFunction} from '../../config/Can';
import * as rules from '../../config/Rules';
import { withStyles } from '@material-ui/styles';

const pathObject = {
    'budgetPlans': {
        'createByCc': {
            label: 'Create new plan by cost center',
        },
        'createByActivity': {
            label: 'Create new plan by activity',
        },
        'viewAll': {
            label: 'View all',
            '*': {
            }
        },
        'my': {
            label: 'My Budget plans',
            '*': {
            }
        }
    },
    'changelogs': {
        'my': {
            label: 'My Change Logs',
            "*": {
            }
        },
        'viewAll': {
            label: 'View all',
            '*': {
            }
        },
        'create': {
            label: 'Create new Change Log',
        },
    },
    'deliveryconfirmation': {
        'viewMy': {
            label: 'View my DCs',
            '*': {
            }
        },
        'submit': {
            label: 'Submit my DCs',
            '*': {
            }
        },
        'approve': {
            label: 'Approve my DCs',
            '*': {
            }
        },
        'viewAll': {
            label: 'View all',
            '*': {
            }
        },
        'toBeProcessed': {
            label: 'To Be Processed',
            '*': {
            }
        },
        'view': {
            label: 'View',
            '*': {
            }
        }
    },
    'resourcemodel': {
        label: 'Resource Model',
        '*': {
        }
    },
    'staticpicklist': {
        label: 'Edit Picklists'
    },
    'masterdata': {
        'createactivity': {
            label: 'Create new activity'
        },
        'createvendorrate': {
            label: 'Create new vendor rate'
        },
        'createwbs': {
            label: 'Create new WBS'
        },
        'viewallactivities': {
            label: 'Activities',
            '*': {
            }
        },
        'grades': {
            label: 'Grades'
        },
        'viewallvendorrates': {
            label: 'Vendor rates',
            '*': {
            }
        },
        'viewallwbses': {
            label: 'WBS',
            '*': {
            }
        },
        'vendors': {
            label: 'Vendors'
        },
        'importmasterdata': {
            label: 'Import Master Data'
        }
    },
    sapActuals: {
        label: 'SAP Actuals',
    }
}

const styles = theme => ({
    draftMargin: {
        marginRight: 336,
      [theme.breakpoints.down('md')]: {
        marginRight: 80,
      },
    },
    backOfficeButtonsPanelMargin: {
        marginLeft: 96,
        [theme.breakpoints.down('md')]: {
            marginLeft: 20,
          },
    }
  });

const mapStateToProps = (state) => {
    return {
        id: state.changelogForm.id,
        canSaveAsDraft: state.changelogForm.isDraft,
        supplierTabsVisible: state.user.supplierTabsVisible,
        selectedRole: state.user.selectedRole,
        statusComment: state.navbar.statusComment,
        submitting: state.changelogForm.submitting,
        changelogTabSelected: state.navbar.changelogTabSelected
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    toggleDrawer: () =>
        dispatch(actionCreators.toggleDrawer()),
    saveEditDraft: () => 
        dispatch(changelogFormActionsCreators.submitEditChangelogFormStarted(true)),
    saveDraft: () =>
        dispatch(changelogFormActionsCreators.submitChangelogFormStarted(true)),   
    selectHistory: () =>
        dispatch(actionCreators.changelogTabSelectionChanged("history")),
    selectComment: () =>
        dispatch(actionCreators.changelogTabSelectionChanged("comment")),
    selectCLs: () =>
        dispatch(actionCreators.changelogTabSelectionChanged("activities"))
});

class AppBarContainer extends Component {

    handleBreadCrumbItemClick = (url) => {
        this.props.history.push(url);
    }

    getBreadCrumbPaths = () => {
        const paths = [];
        paths.push({
            label: 'IT Planning and Tracking Tool',
            url: '/'
        })
        const pathObjectCopy = {...pathObject};
        if (this.props.supplierTabsVisible) {
            pathObjectCopy.deliveryconfirmation = {...pathObjectCopy.deliveryconfirmation};
            pathObjectCopy.deliveryconfirmation.submit.label = "Fill in my DCs";
        }
        const separatedPaths = this.props.location.pathname.split('/').slice(1);
        this.parsePath(pathObject, '', separatedPaths, 0, paths);
        return paths;
        
    }
    parsePath(pathObject, currentPath, paths, nestingLevel, breadcrumbPaths) {
        const newCurrentPath = `${currentPath}/${paths[parseInt(nestingLevel)]}`;
        if (!pathObject) {
            return;
        }
        if (pathObject[paths[parseInt(nestingLevel)]]) {
            if (pathObject[paths[parseInt(nestingLevel)]].label) {
                breadcrumbPaths.push({url: newCurrentPath, label:pathObject[paths[parseInt(nestingLevel)]].label});
            }
            this.parsePath(pathObject[paths[parseInt(nestingLevel)]], newCurrentPath, paths, nestingLevel + 1, breadcrumbPaths);
        } else if (pathObject['*'] && paths[parseInt(nestingLevel)]) {
            breadcrumbPaths.push({url: newCurrentPath, label:paths[parseInt(nestingLevel)]});
            this.parsePath(pathObject[paths[parseInt(nestingLevel)]], newCurrentPath, paths, nestingLevel + 1, breadcrumbPaths);
        }
    }
    saveAsDraft = () => {
        const pathName = this.props.location.pathname
        if (pathName === "/changelogs/create") {
            this.props.saveDraft();
        } else if (pathName.indexOf('/changelogs/my') || pathName.indexOf('/changelogs/viewAll')) {
            this.props.saveEditDraft();
        }
    }

    isSaveAsDraftVisible = () => {
        const pathName = this.props.location.pathname;
        return (this.props.canSaveAsDraft || pathName === '/changelogs/create')  && (pathName === '/changelogs/create' || ((pathName.indexOf('/changelogs/viewAll') !== -1 || pathName.indexOf('/changelogs/my') !== -1) && pathName.split("/")[3])) && canFunction(this.props.selectedRole, rules.SAVE_CHANGELOG_DRAFT);
    }

    areBackOfficeButtonsVisible = () => {
        const pathName = this.props.location.pathname;
        return this.props.id && !this.props.canSaveAsDraft && pathName.indexOf('/changelogs/') !==-1 && pathName.split("/")[3] && canFunction(this.props.selectedRole, rules.IS_BACKOFFICE_OR_ADMIN);
    }


    render() {
        return (
            <AppBar 
                paths={this.getBreadCrumbPaths()}
                onBreadCrumbItemClick={this.handleBreadCrumbItemClick}
                onIconButtonClicked={this.props.toggleDrawer}
                statusComment={this.props.statusComment}
            >
                <div  style={{marginLeft: 'auto', display: 'flex'}}>
                    {this.isSaveAsDraftVisible() ?
                    <span disabled={this.props.submitting} style={{display: 'flex', alignItems:'center', justifyContent: 'center', cursor:'pointer'}} onClick={this.saveAsDraft}>
                        <Save />
                        <span style={{marginLeft: 10, fontWeight: 700, letterSpacing: '0.5px'}}>SAVE AS DRAFT</span>
                    </span> : null
                    }
                    
                    <span className={this.props.classes.backOfficeButtonsPanelMargin}>
                    {this.areBackOfficeButtonsVisible() ?
                    <React.Fragment>
                        <Fab variant="extended" className={this.props.changelogTabSelected === "history" ? "navbar-fab-selected": "navbar-fab"} onClick={this.props.selectHistory}>History</Fab>
                        <Fab variant="extended" className={this.props.changelogTabSelected === "comment" ? "navbar-fab-selected": "navbar-fab"} onClick={this.props.selectComment}>Comment</Fab>
                        <Fab variant="extended" className={this.props.changelogTabSelected === "activities" ? "navbar-fab-selected-long": "navbar-fab-long"} onClick={this.props.selectCLs}>Activity Changelogs</Fab>
                    </React.Fragment>
                    : null}  
                    </span>

                </div>
            </AppBar>
        )
    }
}
export default compose(withRouter, withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AppBarContainer)
