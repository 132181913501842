import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import StatusContainer from './StatusContainer';
import LastModifiedByContainer from './LastModifiedByContainer';
import ActualsSubmittedByContainer from './ActualsSubmittedByContainer';
import ActualsApprovedByContainer from './ActualsApprovedByContainer';
import DeliveryInformationContainer from './DeliveryInformationContainer';
import RolesContainer from './RolesContainer';
import ResourcesListContainer from './ResourcesListContainer';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Dialog from '../../../../components/Dialog/Dialog';
import * as deliveryConfirmationFormActions from '../../../../store/actions/deliveryConfirmationFormActions';
import TextArea from '../../../../components/Input/TextArea';
import { isVendorTypeExternal } from '../../../../utils/typesAssertions';
import CreatedByContainer from './CreatedByContainer';
import Hidden from '@material-ui/core/Hidden';
import TagsContainer from './TagsContainer';

const styles = {
    container: {
        paddingTop: '40px',
        maxWidth: 928,
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    },
    textArea: {
        border: '0px !important',
        width: 400,
        fontFamily: 'Lato, sans-serif',
        fontSize: 14,
        outline: 'none',
        resize: 'none'
    },
    textAreaWrapper: {
        borderRadius: '16px',
        height: 120,
        border: '1px solid #a9a9a9',
        padding: 16,
        display: 'inline-block',
        position: 'relative',
        overflow: 'hidden'
    },
    textAreaLabel: {
        color: '#595959',
        fontSize: '12px',
        lineHeight: '16px'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.deliveryConfirmationForm.loading || state.deliveryConfirmationForm.submitting,
        submitAndApproveModalOpen: state.deliveryConfirmationForm.submitAndApproveModalOpen,
        dcNumber: state.deliveryConfirmationForm.dcNumber,
        rejectReason: state.deliveryConfirmationForm.rejectReason,
        rejectModalOpen: state.deliveryConfirmationForm.rejectModalOpen,
        vendorType: state.deliveryConfirmationForm.vendorType,
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: (payload) => {
        dispatch(deliveryConfirmationFormActions.initializeDeliveryConfirmationFormStarted(payload));
      },
      resetForm: () => {
        dispatch(deliveryConfirmationFormActions.resetForm());
      },
      closeSubmitAndApproveModal: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormSubmitAndApproveModalStateChanged(false))
      },
      closeRejectModal: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormRejectModalStateChanged(false))
      },
      handleModalSubmitAndApproveButtonPressed: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActionButtonClicked('submitAndApproveModal'));
      },
      handleModalRejectButtonPressed: () => {
        dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormActionButtonClicked('rejectModal'));
      },
      rejectReasonChanged: (payload) => {
          dispatch(deliveryConfirmationFormActions.deliveryConfirmationFormRejectReasonChanged(payload))
      }
  });
class DeliveryConfirmationApproverForm extends Component {
    componentDidMount() {
        this.props.loadView(this.props.location.pathname.split('/')[3]);
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render () {
        return (
            <React.Fragment>
                <Dialog 
                    open={this.props.submitAndApproveModalOpen}
                    onClose={this.props.closeSubmitAndApproveModal}
                    onSubmit={this.props.handleModalSubmitAndApproveButtonPressed}
                    cancelButtonText="NO"
                    submitButtonText="YES"
                    title={"DC " + this.props.dcNumber}
                >
                    <span>Do you really want to submit and approve?</span>
                </Dialog>
                <Dialog 
                    open={this.props.rejectModalOpen}
                    onClose={this.props.closeRejectModal}
                    onSubmit={this.props.handleModalRejectButtonPressed}
                    cancelButtonText="CANCEL"
                    submitButtonText="REJECT"
                    title={"REJECT DC " + this.props.dcNumber}
                >
                    <div style={{}}>
                        <div className={this.props.classes.textAreaLabel}>Reject reason</div>
                        <TextArea 
                            disabled={this.props.submitting}
                            value={this.props.rejectReason}
                            onChange={this.props.rejectReasonChanged}
                        />
                    </div>
                </Dialog>
                <div className={this.props.classes.container} id="delivery-confirmation-form">
                    <LoadingSpinner loading={this.props.loading} spinnerCentered={true}/>
                    <Grid container className="form-grid" spacing={2}>
                        {/* <Grid item xs={12}>
                            <h3 style={{
                                color: '#000',
                                fontSize: 40,
                                fontWeight: 'bold',
                                textAlign: 'left'
                            }}>Delivery Confirmation for CL {this.props.location.pathname.split('/')[3]}</h3>
                        </Grid> */}
                        <Grid item xs={12}>
                            <StatusContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <TagsContainer />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CreatedByContainer />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LastModifiedByContainer />
                        </Grid> 
                        <Grid item md={6} xs={12}>
                            <ActualsSubmittedByContainer />
                        </Grid>   
                        <Grid item md={6} xs={12}>
                            {isVendorTypeExternal(this.props.vendorType) ?
                            <ActualsApprovedByContainer /> : null
                            }
                        </Grid>                 
                        <Grid item md={6} xs={12}>
                            <DeliveryInformationContainer />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <RolesContainer />
                        </Grid>
                        <Grid item xs={12}>
                            <ResourcesListContainer />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}


  export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(DeliveryConfirmationApproverForm);