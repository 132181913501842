import * as actionTypes from '../actions/actionTypes';

export const sapActaulsLoadingSpinnerChanged =  (myParam) => {
    return {type: actionTypes.SAP_ACTUALS_LOADING_SPINNER_CHANGED, payload: myParam};
}
export const getSapActualsFinished =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_FINISHED, payload: myParam};
}
export const getSapActualsStarted =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_STARTED, payload: myParam};
}
export const getSapActualsFailed =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_FAILED, payload: myParam};
}
export const updateSapActualsColumnsStarted =  (myParam) => {
    return {type: actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_STARTED, payload: myParam};
}
export const updateSapActualsColumnsFailed =  (myParam) => {
    return {type: actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_FAILED, payload: myParam};
}
export const updateSapActualsColumnsFinished =  (myParam) => {
    return {type: actionTypes.UPDATE_SAP_ACTUALS_COLUMNS_FINISHED, payload: myParam};
}
export const getSapActualsColumnsStarted =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_COLUMNS_STARTED, payload: myParam};
}
export const getSapActualsColumnsFailed =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_COLUMNS_FAILED, payload: myParam};
}
export const getSapActualsColumnsFinished =  (myParam) => {
    return {type: actionTypes.GET_SAP_ACTUALS_COLUMNS_FINISHED, payload: myParam};
}

 