import React, { Component } from 'react';
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import Attachment from '@material-ui/icons/Attachment';
import Clear from '@material-ui/icons/Clear';
import withStyles from '@material-ui/core/styles/withStyles';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { connect } from 'react-redux';
import { compose } from 'redux';
const mapStateToProps = (state) => {
    return {
        loading: state.attachment.loading,
    }
};
const styles = {
    removeAttachmentIcon: {
        position: 'absolute',
        right: 8,
        top: 4,
        bottom: 4
    },
    fabSpan: {
        display: 'inline-flex',
        border: '1px solid #d2d2d2',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        paddingRight: 40,
        paddingLeft: 16,
        height: 28,
        marginBottom: 8,
        marginRight: 16,
        cursor: 'pointer'
    },
    fabSpanadded: {
        display: 'inline-flex',
        border: '1px solid #d2d2d2',
        borderColor: '#39acff',
        backgroundColor: '#e1f5fe',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        paddingRight: 40,
        paddingLeft: 16,
        height: 28,
        marginRight: 16,
        marginBottom: 8,
        cursor: 'pointer'
    },
    fabSpandeleted: {
        display: 'inline-flex',
        border: '1px solid red',
        backgroundColor: '#ff9999',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        paddingRight: 40,
        marginBottom: 8,
        paddingLeft: 16,
        height: 28,
        marginRight: 16,
    },
    text: {
        lineHeight: '26px',
        display: 'inline-block',
        fontSize: '12px',
        marginLeft: 4,
        fontWeight: 700
    }
}
class AttachmentComponent extends Component {
    state = {
        files: []
    }

    downloadFile = (key) => {
        const file = this.props.attachments.find((attachment) => {
            return attachment.key === key;
        });
        this.props.downloadAttachment(file);
    }
    render () {
        return (
            <div style={{position: 'relative', paddingBottom: this.props.errorText ? 14 : null}}>
            <LoadingSpinner loading={this.props.loading}/>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                '* >': {
                    paddingLeft: 8,
                    paddingRight: 8
                }
            }}>
                {this.props.attachments.map((file) => {
                    let selectedClass = this.props.classes['fabSpan'+ (this.props.changes && this.props.changes.attachments && this.props.changes.attachments[file.name] ? this.props.changes.attachments[file.name] : "")];
                    return (
                    <span className={selectedClass} key={file.key}>
                        <Attachment onClick={this.downloadFile.bind(this, file.key)}/>
                        <span className={this.props.classes.text} onClick={this.downloadFile.bind(this, file.key)}>
                            {file.name}
                        </span>
                        {this.props.disabled ? null : 
                        <span className={this.props.classes.removeAttachmentIcon}>
                            <span onClick={this.props.attachmentRemoved.bind(this, file)}>
                                <Clear className="delete-attachment" />
                            </span>
                        </span>
                        }
                    </span>)
                })}
                {this.props.changes && this.props.changes.attachments && this.props.changes.attachments.deleted ? 
                    this.props.changes.attachments.deleted.map(attachment => {
                        return (
                            <span className={this.props.classes.fabSpandeleted} key={attachment.name}>
                                <Attachment/>
                                <span className={this.props.classes.text}>
                                    {attachment.name}
                                </span>
                            </span>)
                    }) : ""
                }
                {this.props.disabled ? null :
                <React.Fragment>
                    <input
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={this.props.attachmentAdded}
                    />
                    <label htmlFor="raised-button-file">
                        <Button color="primary" 
                            component="span"
                            className="removed-text-padding">
                            <AddIcon />
                            ATTACH FILE
                        </Button>
                    </label>
                </React.Fragment>
                }
            </div>
            {this.props.errorText && <div className="error" style={{fontSize: 12, left: 8, color: 'red', whiteSpace: 'nowrap'}}>{this.props.errorText}</div>}
            </div>
        )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, null))(AttachmentComponent);