import { put, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '../store/actions/actionTypes';
import * as subDomainActions from '../store/actions/subDomains';
import * as navbarActionCreators from '../store/actions/Navbar';
import axios from '../axios';

export function* getSubDomains(optionalId) {
    try {
        const subDomains = yield axios.get('api/SubDomains');
        let subDomain = subDomains.data.find((subDomain) => {
            return subDomain.id === optionalId;
        })
        if (!subDomain && optionalId) {
            subDomain = axios.get('api/Subdomains/' + optionalId);
            const newSubDomains = [...subDomains];
            newSubDomains.push(subDomain.data);
            yield put(subDomainActions.getSubDomainsFinished(newSubDomains));
        } else {
            yield put(subDomainActions.getSubDomainsFinished(subDomains.data));
        }
    } catch(error) {
        yield put(subDomainActions.getSubDomainsFailed(error));
    }
}

export function* getAllSubDomains(withAlreadyInUseProp) {
    try {
        let subDomains;
        if (withAlreadyInUseProp) {
            subDomains = yield axios.get('api/SubDomains/all', {params:{withDeleted: true}});
        } else {
            subDomains = yield axios.get('api/SubDomains', {params:{withDeleted: true}});
        }
        yield put(subDomainActions.getAllSubDomainsFinished(subDomains.data));
    } catch(error) {
        yield put(subDomainActions.getAllSubDomainsFailed(error));
    }
}

function* addSubDomain(action) {
    try {
        const subDomains = yield axios.post('api/SubDomains', action.payload);
        yield* getAllSubDomains(true);
        yield* getSubDomains();
        yield put(subDomainActions.addSubDomainFinished(subDomains.data));
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(subDomainActions.addSubDomainFailed(error));
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}

function* editSubDomain(action) {
    try {
        const subDomains = yield axios.put('api/SubDomains', action.payload);
        yield* getAllSubDomains(true);
        yield* getSubDomains();
        yield put(subDomainActions.editSubDomainFinished(subDomains.data))
        yield put(navbarActionCreators.setNavbarStatusComment("Last Updated: " + new Date().toLocaleTimeString()));
    } catch(error) {
        yield put(subDomainActions.editSubDomainFailed(error))
        yield put(navbarActionCreators.setNavbarStatusComment("Update Failed: " + new Date().toLocaleTimeString()));
    }  
}



const sagas =  [
    takeEvery(actionTypes.GET_SUB_DOMAINS_STARTED, getSubDomains),
    takeEvery(actionTypes.GET_ALL_SUB_DOMAINS_STARTED, getSubDomains),
    takeEvery(actionTypes.ADD_SUB_DOMAIN_STARTED, addSubDomain),
    takeEvery(actionTypes.EDIT_SUB_DOMAIN_STARTED, editSubDomain),
]
export default sagas