import * as actionTypes from './actionTypes';

export const getChangelogStarted = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_STARTED, payload: myParam}
}
export const getChangelogFinished = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_FINISHED, payload: myParam}
}
export const getChangelogFailed = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_FAILED, payload: myParam}
}
export const getActivitiesStarted = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_STARTED, payload: myParam};
};
export const getActivitiesFailed = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_FAILED, payload: myParam};
};
export const getActivitiesFinished = (myParam) => {
    return {type: actionTypes.GET_ACTIVITIES_FINISHED, payload: myParam};
};

export const getPoWbsesByActivityStarted = (myParam) => {
    return {type: actionTypes.GET_PO_WBSES_BY_ACTIVITY_STARTED, payload: myParam};
}
export const getPoWbsesByActivityFailed = (myParam) => {
    return {type: actionTypes.GET_PO_WBSES_BY_ACTIVITY_FAILED, payload: myParam};
}
export const getPoWbsesByActivityFinished = (myParam) => {
    return {type: actionTypes.GET_PO_WBSES_BY_ACTIVITY_FINISHED, payload: myParam};
}

export const getVendorTypesByDomainStarted = (myParam) => {
    return {type: actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_STARTED, payload: myParam};
};
export const getVendorTypesByDomainFailed = (myParam) => {
    return {type: actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_FAILED, payload: myParam};
};
export const getVendorTypesByDomainFinished = (myParam) => {
    return {type: actionTypes.GET_VENDOR_TYPES_BY_DOMAIN_FINISHED, payload: myParam};
};
export const getVendorsByVendorTypeStarted = (myParam) => {
    return {type: actionTypes.GET_VENDORS_BY_VENDOR_TYPE_STARTED, payload: myParam};
};
export const getVendorsByVendorTypeFailed = (myParam) => {
    return {type: actionTypes.GET_VENDORS_BY_VENDOR_TYPE_FAILED, payload: myParam};
};
export const getVendorsByVendorTypeFinished = (myParam) => {
    return {type: actionTypes.GET_VENDORS_BY_VENDOR_TYPE_FINISHED, payload: myParam};
};
export const getVendorRatesByVendorStarted = (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_BY_VENDOR_STARTED, payload: myParam};
};
export const getVendorRatesByVendorFailed = (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_BY_VENDOR_FAILED, payload: myParam};
};
export const getVendorRatesByVendorFinished = (myParam) => {
    return {type: actionTypes.GET_VENDOR_RATES_BY_VENDOR_FINISHED, payload: myParam};
};
export const getResourceTypesByDomainAndVendorTypeStarted = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_STARTED, payload: myParam};
};
export const getResourceTypesByDomainAndVendorTypeFailed = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FAILED, payload: myParam};
};
export const getResourceTypesByDomainAndVendorTypeFinished = (myParam) => {
    return {type: actionTypes.GET_RESOURCE_TYPES_BY_DOMAIN_AND_VENDOR_TYPE_FINISHED, payload: myParam};
};
export const forceLoadingDataSpinner = (myParam) => {
    return {type: actionTypes.FORCE_LOADING_DATA_SPINNER, payload: myParam};
};

export const getSubDomainsByDomainStarted = (myParam) => {
    return {type: actionTypes.GET_SUBDOMAINS_BY_DOMAIN_STARTED, payload: myParam};
};
export const getSubDomainsByDomainFailed = (myParam) => {
    return {type: actionTypes.GET_SUBDOMAINS_BY_DOMAIN_FAILED, payload: myParam};
};
export const getSubDomainsByDomainFinished = (myParam) => {
    return {type: actionTypes.GET_SUBDOMAINS_BY_DOMAIN_FINISHED, payload: myParam};
};
export const getChangelogResourceTagsFinished = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_RESOURCE_TAGS_FINISHED, payload: myParam};
};
export const getChangelogTagsFinished = (myParam) => {
    return {type: actionTypes.GET_CHANGELOG_TAGS_FINISHED, payload: myParam};
};