import * as actionTypes from '../actions/actionTypes';
import {getEmptyComment} from '../../utils/commentUtils';

const initialState = {
    wbs: {},
    id: null,
    vendor: '',
    rateYear: '',
    resourceType: '',
    assignmentSite: '',
    resourceLocation: '',
    grade: '',
    unitPrice: '',
    perDiemRate: '',
    currency: '',
    comments: [],
    attachments: [],
    loading: false,
    errors: {},
    comment: getEmptyComment(),
    clientId: null,
    externalVendors: [],
    internalVendors: [],
    submitting: false,
    isDeleted: false,
    alreadyInUse: false
}

const getNewErrors = (state, value, errorProperty) => {
    const newErrors = {...state.errors};
    if (value) {
        newErrors[errorProperty] = ''
    }
    return newErrors;
}

const vendorRateFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.VENDOR_RATES_FORM_ALREADY_IN_USE_CHANGED: {
            return {
                ...state,
                alreadyInUse: action.payload
            }
        }
        case actionTypes.VENDOR_RATE_FORM_INACTIVE_CHANGED: {
            return {
                ...state,
                isDeleted: action.payload
            }
        }
        case actionTypes.VENDOR_RATE_FORM_CLIENT_ID_CHANGED: {
            return {
                ...state,
                clientId: action.payload
            }
        }
        case actionTypes.VENDOR_RATE_FORM_COMMENT_CHANGED: {
            return {
                ...state,
                comment: action.payload
            }
        }
        case actionTypes.VENDOR_RATES_FORM_EXTERNAL_VENDORS_CHANGED: {
            return {
                ...state,
                externalVendors: action.payload
            }
        }
        case actionTypes.VENDOR_RATES_FORM_INTERNAL_VENDORS_CHANGED: {
            return {
                ...state,
                internalVendors: action.payload
            }
        }
        case actionTypes.RESET_VENDOR_RATES_FORM: {
            return {
                ...initialState,
            }
        }
        case actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_STARTED:
        case actionTypes.VENDOR_RATES_FORM_INITIALIZE_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.VENDOR_RATES_FORM_INITIALIZE_FAILED:
        case actionTypes.VENDOR_RATES_FORM_INITIALIZE_FINISHED:
        case actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_FAILED:
        case actionTypes.VENDOR_RATES_EDIT_FORM_INITIALIZE_FINISHED: {
            return {
                ...state,
                loading: false
            }
        }
        case actionTypes.VENDOR_RATES_FORM_ERRORS_CHANGED: {
            return {
                ...state,
                errors: action.payload
            }
        }
        case actionTypes.VENDOR_RATES_FORM_SUBMIT_STARTED: {
            return {
                ...state,
                submitting: true,
                loading: true
            }
        }
        case actionTypes.VENDOR_RATES_FORM_SUBMIT_FINISHED:
        case actionTypes.VENDOR_RATES_FORM_SUBMIT_FAILED: {
            return {
                ...state,
                submitting: false,
                loading: false
            }
        }
        case actionTypes.VENDOR_RATES_FORM_ID_CHANGED: {
            return {
                ...state,
                id: action.payload
            }
        }
        case actionTypes.VENDOR_RATES_FORM_VENDOR_CHANGED: {
            return {
                ...state,
                vendor: action.payload,
                errors: getNewErrors(state, action.payload, 'vendor')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_RATE_YEAR_CHANGED: {
            return {
                ...state,
                rateYear: action.payload,
                errors: getNewErrors(state, action.payload, 'rateYear')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_RESOURCE_TYPE_CHANGED: {
            return {
                ...state,
                resourceType: action.payload,
                errors: getNewErrors(state, action.payload, 'resourceType')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_ASSIGNMENT_SITE_CHANGED: {
            return {
                ...state,
                assignmentSite: action.payload,
                errors: getNewErrors(state, action.payload, 'assignmentSite')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_RESOURCE_LOCATION_CHANGED: {
            return {
                ...state,
                resourceLocation: action.payload,
                errors: getNewErrors(state, action.payload, 'resourceLocation')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_GRADE_CHANGED: {
            return {
                ...state,
                grade: action.payload,
                errors: getNewErrors(state, action.payload, 'grade')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_UNIT_PRICE_CHANGED: {
            return {
                ...state,
                unitPrice: action.payload,
                errors: getNewErrors(state, action.payload, 'unitPrice')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_PER_DIEM_RATE_CHANGED: {
            return {
                ...state,
                perDiemRate: action.payload,
                errors: getNewErrors(state, action.payload, 'perDiemRate')
            }
        }
        case actionTypes.VENDOR_RATES_FORM_CURRENCY_CHANGED: {
            return {
                ...state,
                currency: action.payload,
                errors: getNewErrors(state, action.payload, 'currency')
            }
        }
        case actionTypes.VENDOR_RATES_COMMENTS_CHANGED: {
            return {
                ...state,
                comments: action.payload
            }
        }
        case actionTypes.VENDOR_RATES_FORM_ATTACHMENTS_CHANGED: {
            return {
                ...state,
                attachments: action.payload
            }
        }
        case actionTypes.VENDOR_RATE_FORM_ATTACHMENT_ADDED: {
            const newAttachments = [...state.attachments];
            const file = action.payload.target.files[0];
            file.key = Math.random();
            newAttachments.push(file);
            return {
                ...state,
                attachments: newAttachments
            }
        }
        case actionTypes.VENDOR_RATE_FORM_ATTACHMENT_REMOVED: {
            const deleteFile = state.attachments.find((file) => {
                return file.key === action.payload.key;
            })
            const deleteIndex = state.attachments.indexOf(deleteFile);
            const newAttachments = [
                ...state.attachments.slice(0, deleteIndex),
                ...state.attachments.slice(deleteIndex + 1)
            ];
            return {
                ...state,
                attachments: newAttachments
            }
        }
        default: {
            return state
        }
    }
}
export default vendorRateFormReducer;
