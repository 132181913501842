import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import * as vendorRateActions from '../../../../store/actions/vendorRates';
import VendorRateDataContainer from './VendorRateDataContainer';
import SubmitPanelContainer from './SubmitPanelContainer';
import CommentsContainer from './CommentsContainer';
import SupportingDocumentationContainer from './SupportingDocumentationContainer';
const styles = {
    container: {
        paddingTop: '20px',
        maxWidth: 928,
        // minHeight: '100vh',
        margin: '0 auto',
        paddingRight: '48px',
        display: 'flex',
        position: 'relative'
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.vendorRateForm.loading || state.vendorRateForm.submitting
    }
}

  const mapDispatchToProps = (dispatch) => ({
      loadView: () => {
        dispatch(vendorRateActions.vendorRatesFormInitializeStarted());
      },
      loadEditForm: (payload) =>
        dispatch(vendorRateActions.vendorRatesEditFormInitializeStarted(payload)),
      resetForm: () => {
        dispatch(vendorRateActions.resetVendorRatesForm());
      }
  });
class VendorRateForm extends Component {
    componentDidMount() {
        if (this.props.location.pathname.indexOf('/masterdata/viewallvendorrates') === -1) {
            this.props.loadView();
        } else {
            this.props.loadEditForm(this.props.location.pathname.split('/')[3]);
        }
    }
    componentWillUnmount() {
        this.props.resetForm();
    }
    render () {
        return (
            <div className={this.props.classes.container}>
                <LoadingSpinner loading={this.props.loading} spinnerCentered={true}></LoadingSpinner>
                <Grid container className="form-grid" spacing={2}>
                    <Grid item xs={12}>
                        <VendorRateDataContainer/>
                    </Grid>
                    <Grid item xs={12}>
                        <CommentsContainer />
                    </Grid>   
                    <Grid item xs={12}>
                        <SupportingDocumentationContainer />
                    </Grid>                  
                    <Grid item xs={12}>
                        <SubmitPanelContainer />
                    </Grid>
                </Grid>
            </div>
        )
    }
}


  export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(VendorRateForm);