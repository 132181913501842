import React, { Component } from 'react';
import DataTable from '../../../components/DataTable/DataTable2';
import AutoSelect from '../../../components/Input/AutoSelectWithDataOnChange';
import { connect } from 'react-redux';
import DataExportContainer from '../../../containers/DataExport/DataExportContainer';
import { getAllCostTypesParsed } from '../../../store/reducers/OneColumnPicklistReducer';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '../../../components/Input/Input';
import * as actionCreators from '../../../store/actions/ExpenseTypePickList';
import * as connectionActions from '../../../store/actions/expenseTypeCostType';

const mapStateToProps = (state) => {
    return {
        costTypes: getAllCostTypesParsed(state),
        data: state.expenseTypeCostType.data,
        loading: state.expenseTypeCostType.loading,
        newItemText: state.expenseTypeCostType.newItemExpensTypeText,
        selectedItems: state.expenseTypeCostType.selectedItems,
        itemsBeingEdited: state.expenseTypeCostType.itemsBeingEditedMap,
        glAccountsBeingEditedMap: state.expenseTypeCostType.glAccountsBeingEditedMap
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    handleConnectionChange: (obj) => {
        if (obj.toBeAdded) {
            dispatch(connectionActions.addExpenseTypeCostTypeConnectionStarted(obj));
        } else {
            dispatch(connectionActions.deleteExpenseTypeCostTypeConnectionStarted(obj));
        }
    },
    handleNewItemTextChanged: (e) =>
        dispatch(actionCreators.expenseTypePicklistNewItemExpenseTypeChanged(e)),
    handleAddItem: (e) => {
        if (e.target.value.length !== 0) {
            dispatch(actionCreators.addExpenseTypeStarted({name: e.target.value}))
        }
    },  
    handleEditItem: (item) => {
        dispatch(actionCreators.editExpenseTypeStarted(item))
    },
    handleEditText: (e) => {
        dispatch(actionCreators.expenseTypePicklistEditItemTextChanged(e))
    },
    handleEditGlAccountChanged: (e) => {
        dispatch(actionCreators.expenseTypeItemGlAccountChanged(e))
    },
    handleEditIsDeleted: (object) => {
        dispatch(actionCreators.editExpenseTypeStarted(object))
    },
    handleChangeGlAcccount: (e) => {
        dispatch(actionCreators.expenseTypeItemGlAccountChanged(e))
    }
});

class DomainResourceTypePickList extends Component {

    state = {
        filtered: {
        }
    }

    handleEnterOnAddInput = (event) => {
        if (event.key === 'Enter' && event.target.value.length !== 0) {
            this.props.handleAddItem(event);
        }
    }
    handleEditItem = (event, prop) => {
        if (this.checkIfShouldEditField(event, prop)) {
            const item = this.props.data.find((item) => {
                return item.id === parseInt(event.target.name)
            })
            const newItem = {
                ...item,
                [prop]: event.target.value
            }
            this.props.handleEditItem(newItem);
        }
    }
    handleEnterOnEditInput = (event, prop) => {
        if (event.key === 'Enter' && event.target.value.length !== 0 && this.checkIfShouldEditField(event, prop)) {
            const item = this.props.data.find((item) => {
                return item.id === parseInt(event.target.name)
            })
            const newItem = {
                ...item,
                [prop]: event.target.value
            }
            this.props.handleEditItem(newItem);
        }
    }
    checkIfShouldEditField = (event, prop) => {
        const itemToBeEdited = this.props.data.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        if (itemToBeEdited === undefined) {
            return false;
        }
        return itemToBeEdited[prop] !== event.target.value;
    }
    selectedItemsChanged = (event, isDeleted) => {
        const itemToBeEdited = this.props.data.find((item) => {
            return item.id === parseInt(event.target.name)
        });
        const copy = {
            ...itemToBeEdited,
            isDeleted: isDeleted
        }
        this.props.handleEditIsDeleted(copy)
    }
    onFilteredChanged = (event, property) => {
        let propName;
        let value;
        if (!event) {
            const newFiltered  = {
                ...this.state.filtered
            }
            delete newFiltered[property];
            this.setState({
                filtered: newFiltered
            })
            return;
        }
        if (!event.target) {
            propName = property;
            value= event;
        } else {
            propName = event.target.name;
            value = event.target.value;
        }
        this.setState((state) => {
            const newFiltered = {
                ...state.filtered,
            }
            newFiltered[propName] = value;
            return {
                filtered: newFiltered,
            };
        });
    }
    filterData = (data) => {
        return data.filter((row) => {
            let isValid = true;
            Object.keys(this.state.filtered).forEach((key) => {
                if (!isValid) {
                    return;
                }
                if (key === 'name') {
                    isValid = row[key].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) !== -1;
                } else if (key === 'glAccount') {
                    isValid = !this.state.filtered[key] || (row[key] &&  row[key].toString().toUpperCase().indexOf(this.state.filtered[key].toUpperCase()) !== -1);
                } else {
                    if (row[key] === undefined && this.state.filtered[key].value === false) {
                        isValid = true
                    } else {
                        isValid = row[key] === this.state.filtered[key].value;
                    }   
                }
            });
            return isValid;
        });
    }

    createColumns = () => {
        return [{
            header: 'Expense Type',
            dataProperty: 'name',
            isVisible: true,
            width: 290
        },
        {
            header: "GL-account",
            dataProperty: 'glAccount',
            isVisible: true,
            width: 290
        },
        ...this.props.costTypes.map((costType) => {
            return {
                header: costType.label,
                dataProperty: costType.value,
                isVisible: true,
                width: 140,
            }
        })
        ]
    }
    createCustomRowCells = () => {
        const customRowCells = {
            name: (data, id, row) => {
                return (
                    <Input
                        onBlur={(e) => this.handleEditItem(e, "name")}
                        onKeyPress={(e) => this.handleEnterOnEditInput(e, "name")}
                        inputProps={{name: id}}
                        value={this.props.itemsBeingEdited[id] !== undefined && this.props.itemsBeingEdited[id] !== null ? this.props.itemsBeingEdited[id]: data || ""}
                        onChange={this.props.handleEditText}
                    />
                )
            },
            glAccount: (data, id, row) => {
                return (
                    <Input
                        onBlur={(e) => this.handleEditItem(e, "glAccount")}
                        onKeyPress={(e) => this.handleEnterOnEditInput(e, "glAccount")}
                        inputProps={{name: id}}
                        value={this.props.glAccountsBeingEditedMap[id] !== undefined && this.props.glAccountsBeingEditedMap[id] !== null ? this.props.glAccountsBeingEditedMap[id]: data || ""}
                        onChange={this.props.handleChangeGlAcccount}
                    />
                )
            },
        }
        this.props.costTypes.forEach((costType) => {
            customRowCells[costType.value] = (data, id, row) => {
                return (
                    <Checkbox color="primary" checked={data || false} onChange={(e) => {this.props.handleConnectionChange({toBeAdded: e.target.checked, expenseTypeId: id, costTypeId: costType.value})}}/>
                )
            }
        });
        return customRowCells;
    }
    createCustomFilters = () => {
        const customFilters = {}
        this.props.costTypes.forEach((costType) => {
            customFilters[costType.value] = (filterValue, onChange) => {
                return (
                    <AutoSelect 
                        additionalData={costType.value}
                        label={costType.label}
                        value={filterValue}
                        isClearable={true}
                        handleChangeSingle={onChange}
                        small
                        width="100%"
                        minWidth={140}
                        suggestions={
                            [
                                { label: 'True', value: true },
                                { label: 'False', value: false}
                            ]
                        }
                    />
                )
            }
        })
        return customFilters;
    }
    getMinWidth = () => {
        return 290 + this.props.costTypes.length * 140;
    }
    
    render() {
        const filteredData = this.filterData(this.props.data);
        return (
            <div>
                <DataExportContainer url="api/export/expenseTypes"/>
                <DataTable
                    enabledAddition
                    general={{selectionEnabled: true, canSelectRowProperty: "id", selectionColumnHeader: "Inactive"}}
                    minHeight={150}
                    selected={this.props.selectedItems}
                    onSelectedChanged={this.selectedItemsChanged}
                    loading={this.props.loading}
                    minWidth={this.getMinWidth()}
                    customColumnsWidthEnabled={true}
                    filtered={this.state.filtered}
                    onFilteredChanged={this.onFilteredChanged}
                    dropdownFilteredChanged={this.onFilteredChanged}
                    data={filteredData}  
                    customRowCells={this.createCustomRowCells()}
                    customFilters={this.createCustomFilters()}
                    addNewItemRenderer= { (dataProperty) => {
                        if (dataProperty === 'name') {
                            return (
                                <Input
                                    placeholder="Add new..."
                                    onBlur={this.props.handleAddItem}
                                    inputProps={{name: dataProperty}}
                                    value={this.props.newItemText}
                                    onChange={this.props.handleNewItemTextChanged}
                                    onKeyPress={this.handleEnterOnAddInput}
                                />
                            );
                        }
                        return null
                        }
                    }
                    columns={this.createColumns()}      
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainResourceTypePickList);