import * as actionTypes from '../actions/actionTypes';

const initialState = {
    roles: [],
    userDomains: [],
    selectedRole: '',
    selectedDomain: {},
    username: '',
    name: '',
    selectedUserDomain: '',
    userDataLoaded: false,
    supplierTabsVisible: false,
    loading: false,
    userEmailCaseSensitive: '',
    login: ''
}

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_USER_LOGIN: {
            return {
                ...state,
                login: action.payload
            }
        }
        case actionTypes.SET_USER_EMAIL_CASE_SENSITIVE: {
            return {
                ...state,
                userEmailCaseSensitive: action.payload
            }
        }
        case actionTypes.SELECT_NAME: {
            return {
                ...state,
                name: action.payload
            }
        }
        case actionTypes.DC_SUPPLIER_TABS_VISIBILITY_CHANGED: {
            return {
                ...state,
                supplierTabsVisible: action.payload
            }
        }
        case actionTypes.GET_USER_ROLES_FINISHED: {
            return {
                ...state,
                roles: action.payload
            }
        }
        case actionTypes.SELECT_USERNAME: {
            return {
                ...state,
                username: action.payload
            }
        }
        case actionTypes.SELECT_USER_ROLE: {
            return {
                ...state,
                selectedRole: action.payload
            }
        }
        case actionTypes.SELECT_USER_DOMAIN: {
            return {
                ...state,
                selectedUserDomain: action.payload
            }
        }
        case actionTypes.SELECT_DOMAIN: {
            return {
                ...state,
                selectedDomain: action.payload
            }
        }
        case actionTypes.USER_DATA_LOADED: {
            return {
                ...state,
                userDataLoaded: true
            }
        }
        case actionTypes.GET_USER_DOMAINS_STARTED: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.GET_USER_DOMAINS_FINISHED: {
            return {
                ...state,
                userDomains: action.payload,
                loading: false
            }
        }
        case actionTypes.GET_USER_DOMAINS_FAILED: {
            return {
                ...state,
                userDomains: [],
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}
export default UserReducer;