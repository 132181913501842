import * as actionTypes from '../actions/actionTypes';

export const getSubDomainsStarted =  (myParam) => {
    return {type: actionTypes.GET_SUB_DOMAINS_STARTED, payload: myParam};
}
export const getSubDomainsFailed =  (myParam) => {
    return {type: actionTypes.GET_SUB_DOMAINS_FAILED, payload: myParam};
}
export const getSubDomainsFinished =  (myParam) => {
    return {type: actionTypes.GET_SUB_DOMAINS_FINISHED, payload: myParam};
}

export const getAllSubDomainsStarted =  (myParam) => {
    return {type: actionTypes.GET_ALL_SUB_DOMAINS_STARTED, payload: myParam};
}
export const getAllSubDomainsFailed =  (myParam) => {
    return {type: actionTypes.GET_ALL_SUB_DOMAINS_FAILED, payload: myParam};
}
export const getAllSubDomainsFinished =  (myParam) => {
    return {type: actionTypes.GET_ALL_SUB_DOMAINS_FINISHED, payload: myParam};
}

export const addSubDomainStarted =  (myParam) => {
    return {type: actionTypes.ADD_SUB_DOMAIN_STARTED, payload: myParam};
}
export const addSubDomainFailed =  (myParam) => {
    return {type: actionTypes.ADD_SUB_DOMAIN_FAILED, payload: myParam};
}
export const addSubDomainFinished =  (myParam) => {
    return {type: actionTypes.ADD_SUB_DOMAIN_FINISHED, payload: myParam};
}

export const editSubDomainStarted =  (myParam) => {
    return {type: actionTypes.EDIT_SUB_DOMAIN_STARTED, payload: myParam};
}
export const editSubDomainFailed =  (myParam) => {
    return {type: actionTypes.EDIT_SUB_DOMAIN_FAILED, payload: myParam};
}
export const editSubDomainFinished =  (myParam) => {
    return {type: actionTypes.EDIT_SUB_DOMAIN_FINISHED, payload: myParam};
}

export const deleteSubDomainStarted =  (myParam) => {
    return {type: actionTypes.DELETE_SUB_DOMAIN_STARTED, payload: myParam};
}
export const deleteSubDomainFailed =  (myParam) => {
    return {type: actionTypes.DELETE_SUB_DOMAIN_FAILED, payload: myParam};
}
export const deleteSubDomainFinished =  (myParam) => {
    return {type: actionTypes.DELETE_SUB_DOMAIN_FINISHED, payload: myParam};
}

export const subDomainPicklistNewItemDomainChanged =  (myParam) => {
    return {type: actionTypes.SUB_DOMAIN_PICKLIST_NEW_ITEM_DOMAIN_CHANGED, payload: myParam};
}
export const subDomainPicklistNewItemTextChanged =  (myParam) => {
    return {type: actionTypes.SUB_DOMAIN_PICKLIST_NEW_ITEM_TEXT_CHANGED, payload: myParam};
}
export const subDomainPicklistEditItemTextChanged =  (myParam) => {
    return {type: actionTypes.SUB_DOMAIN_PICKLIST_EDIT_ITEM_TEXT_CHANGED, payload: myParam};
}
