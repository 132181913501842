import OneColumnPickListReducer from './reducers/OneColumnPicklistReducer';
import ChangelogReducer from './reducers/ChangeLogReducer';
import NavbarReducer from './reducers/NavbarReducer';
import UserReducer from './reducers/UserReducer';
import ResourceTypesVendorTypeReducer from './reducers/ResourceTypesVendorTypeReducer';
import ChangeLogFormReducer from './reducers/ChangeLogFormReducer';
import ChangeLogDataReducer from './reducers/ChangeLogDataReducer';
import BackofficeChangelogCommentReducer from './reducers/ChangelogBackOfficeCommentReducer';
import deliveryConfirmationReducer from './reducers/DeliveryConfirmationReducer';
import resourceModelReducer from './reducers/resourceModelReducer';
import deliveryConfirmationFormReducer from './reducers/DeliveryConfirmationFormReducer';
import resourceModelFormReducer from './reducers/ResourceModelFormReducer';
import ErrorDialogReducer from './reducers/ErrorDialogReducer';
import VendorReducer from './reducers/VendorsReducer';
import ActivityFormReducer from './reducers/activityFormReducer';
import ActivityViewerReducer from './reducers/activityViewerReducer';
import wbsViewerReducer from './reducers/wbsViewerReducer';
import vendorRatesViewerReducer from './reducers/vendorRatesViewerReducer';
import WbsFormReducer from './reducers/wbsFormReducer';
import VendorRateFormReducer from './reducers/vendorRateFormReducer';
import DomainsReducer from './reducers/DomainsReducer';
import SubDomainReducer from './reducers/subDomain';
import PoLocationReducer from './reducers/PoLocationReducer';
import importMasterDataReducer from './reducers/importMasterDataReducer';
import attachmentReducer from './reducers/attachmentReducer';
import dataExportReducer from './reducers/dataExportReducer';
import staticPickListsReducer from './reducers/staticPickListsReducer';
import { combineReducers } from 'redux';
import tableReducer from './reducers/tableReducer';
import snackbarReducer from './reducers/snackbarReducer';
import expenseTypeCostTypeReducer from './reducers/expenseTypeCostTypeReducer';
import costCenterReducer from './reducers/costCenterReducer';
import regionalChangelogFormReducer from './reducers/regionalChangelogFormReducer'
import regionalChangelogsReducer from './reducers/regionalChangelogsReducer';
import sapActualsReducer from './reducers/sapActualsReducer';
import infoDialogReducer from './reducers/infoDialogReducer';
import BackgroundTaskReducer from './reducers/backgroundTaskReducer';
import tagsReducer from './reducers/TagsReducer';

const rootreducer = combineReducers({
    currencies: OneColumnPickListReducer('currencies'),
    grades: OneColumnPickListReducer('grades'),
    resourceLocations: OneColumnPickListReducer('resourceLocations'),
    expenseTypes: OneColumnPickListReducer('expenseTypes'),
    costTypes: OneColumnPickListReducer('costTypes'),
    costCenters: costCenterReducer,
    domains: DomainsReducer,
    expenseTypeCostType: expenseTypeCostTypeReducer,
    changelogs: ChangelogReducer,
    navbar: NavbarReducer,
    user: UserReducer,
    resourceTypeVendorType: ResourceTypesVendorTypeReducer,
    changelogForm: ChangeLogFormReducer,
    changelogData: ChangeLogDataReducer,
    backofficeChangelogCommments: BackofficeChangelogCommentReducer,
    deliveryConfirmation: deliveryConfirmationReducer,
    resourceModel: resourceModelReducer,
    deliveryConfirmationForm: deliveryConfirmationFormReducer,
    resourceModelForm: resourceModelFormReducer,
    error: ErrorDialogReducer,
    vendors: VendorReducer,
    activityForm: ActivityFormReducer,
    activity: ActivityViewerReducer,
    wbs: wbsViewerReducer,
    vendorRates: vendorRatesViewerReducer,
    wbsForm: WbsFormReducer,
    vendorRateForm: VendorRateFormReducer,
    subDomains: SubDomainReducer,
    poLocation: PoLocationReducer,
    importMasterData: importMasterDataReducer,
    attachment: attachmentReducer,
    dataExport: dataExportReducer,
    table: tableReducer,
    staticPicklists: staticPickListsReducer,
    snackbar: snackbarReducer,
    regionalChangelogForm: regionalChangelogFormReducer,
    regionalChangelogs: regionalChangelogsReducer,
    sapActuals: sapActualsReducer,
    infoDialog: infoDialogReducer,
    backgroundTask: BackgroundTaskReducer,
    tags: tagsReducer
})


export default rootreducer;