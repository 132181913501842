import * as actionTypes from '../actions/actionTypes';

const initialState = {
    files: {},
    loading: false,
    columns: [],
    filters: null
}

const importMasterDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.IMPORT_MASTERDATA_LOADING_SPINNER_CHANGED: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case actionTypes.IMPORT_MASTERDATA_FILTERS_CHANGED: {
            return {
                ...state,
                filters: action.payload
            }
        }
        // case actionTypes.GET_IMPORT_MASTERDATA_LIST_STARTED: {
        //     return {
        //         ...initialState,
        //         loading: true,
        //     }
        // }
        case actionTypes.GET_IMPORT_MASTERDATA_LIST_FINISHED: {
            return {
                ...state,
                loading: false,
                files: action.payload
            }
        }
        case actionTypes.GET_IMPORT_MASTERDATA_LIST_FAILED: {
            return {
                ...state,
                loading: false,
                files: {}
            }
        }
        case actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_STARTED:
        case actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_FAILED:    
        case actionTypes.UPDATE_IMPORT_MASTERDATA_COLUMNS_FINISHED:
        case actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_FAILED:  
        case actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_STARTED: {
            return {
                ...state
            }
        }
        case actionTypes.GET_IMPORT_MASTERDATA_COLUMNS_FINISHED: {
            return {
                ...state,
                columns: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default importMasterDataReducer;