import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '../../../components/Dialog/Dialog';
import CardList from '../../../components/CardList/CardList';
import ResourceModelRevisionComparator from './ResourceModelRevisionComparator';
import {canFunction} from '../../../config/Can';
import * as rules from '../../../config/Rules';
import * as resourceModelFormActions from '../../../store/actions/resourceModelFormActions';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    wrapper: {
        paddingTop: 20,
        width: 360,
        minWidth: 360, 
        height: '100%', 
        backgroundColor: 'rgba(200, 202, 206, 0.15)',
        overflow: 'auto',
    },
    toolbar: theme.mixins.toolbar
})
const mapStateToProps = (state) => {
    return {
        history: state.resourceModelForm.history || [],
        changes: state.resourceModelForm.changes,
        showChanges: state.resourceModelForm.showChanges
    }
}

const mapDispatchToProps = (dispatch) => ({
    showChangesChanged: (e) =>
        dispatch(resourceModelFormActions.resourceModelFormShowChangesChanged(e.target.checked))
    }
);


class ChangelogEditMenuContainer extends Component {
    state = {
        modalOpen: false,
        index: 0,
        length: 0
    }
    static getDerivedStateFromProps(props, current_state) {
        if (props.history.length !== current_state.length) {
            return {
                length: props.history.length,
                index: current_state.index + (props.history.length - current_state.length)
            }
        }
        return null;
    }
    viewRevision = (index) => {
        this.setState({
            modalOpen: true,
            index: index,
            length: this.props.history.length
        });
    }
    hideModal = () => {
        this.setState({
            modalOpen: false
        })
    }

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                    { this.state.modalOpen ? 
                    <Dialog fullWidth={true} maxWidth={"md"} open={this.state.modalOpen} onClose={this.hideModal} title="View revision" cancelButtonText="close">
                        <div>
                            <ResourceModelRevisionComparator 
                            history={this.props.history} 
                            index={this.state.index}/>
                        </div>
                    </Dialog> : null
                    }
                <div className={this.props.classes.toolbar}></div>
                {canFunction('', rules.IS_BACKOFFICE_OR_ADMIN) && this.props.changes && this.props.history.length >=2 ? <div><Checkbox color="primary" value={this.props.showChanges} onChange={this.props.showChangesChanged}/> Show latest changes on form</div> : null}
                <CardList 
                    history={this.props.history}
                    onViewRevisionClicked={this.viewRevision.bind(this)}
                />
            </div>
            )
    }
}
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ChangelogEditMenuContainer)