import React from 'react';
import Attachment from '@material-ui/icons/Attachment';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles(theme =>({
    fabSpanunchanged: {
        display: 'inline-flex',
        border: '1px solid #d2d2d2',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        paddingRight: 40,
        marginBottom: 8,
        paddingLeft: 16,
        height: 28,
        marginRight: 16,
    },
    fabSpanadded: {
        display: 'inline-flex',
        border: '1px solid #d2d2d2',
        borderColor: '#39acff',
        backgroundColor: '#e1f5fe',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        marginBottom: 8,
        paddingRight: 40,
        paddingLeft: 16,
        height: 28,
        marginRight: 16,
    },
    fabSpandeleted: {
        display: 'inline-flex',
        border: '1px solid red',
        backgroundColor: '#ff9999',
        position: 'relative',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        paddingRight: 40,
        paddingLeft: 16,
        marginBottom: 8,
        height: 28,
        marginRight: 16,
    },
    text: {
        lineHeight: '26px',
        display: 'inline-block',
        fontSize: '12px',
        marginLeft: 4,
        fontWeight: 700
    },  
}))
export default ({attachments}) => {
    const classes = useStyles();
    return (
    <React.Fragment>
        {attachments.map((file) => {
            let selectedClass = classes['fabSpan'+file.type];
            return (
            <span className={selectedClass} key={file.name}>
                <Attachment />
                <span className={classes.text}>
                    {file.name}
                </span>
            </span>)
        })}
    </React.Fragment>
    )
}