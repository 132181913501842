import React from 'react';
import FormSectionWrapper from '../ChangelogForm/components/FormSectionWrapper';
import TextArea from '../../../components/Input/TextArea';
import formatDate from '../../../utils/dateFormatter';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    commentsListContainer: {
        paddingTop: 18
    },
    commentContainer: {
        paddingBottom: 16,
        borderRadius: '25px',
        width: '100%',
        wordWrap: 'break-word'
    },
    commentHeader: {
        color: '#595959',
        fontSize: 12,
        fontWeight: '500'
    },
    commentContent: {
        color: '#262626',
        lineHeight: '20px',
        fontSize: 16,
        paddingTop: 10
    }
});

export default FormSectionWrapper((props) => {
    const classes = useStyles();
    return (
        <div>
            <TextArea
                label="Comment"
                value={props.comment}
                onChange={props.commentChanged}
                disabled={props.disabled}
             />
             {props.comments.length ? 
                <div className={classes.commentsListContainer}>
                    {props.comments.map((comment) => {
                        return (
                            <div key={comment.userName + comment.date} className={classes.commentContainer}>
                                <h4 className={classes.commentHeader}>
                                    {`${comment.userName}, ${formatDate(comment.date)}`}
                                </h4>
                                <TextArea value={comment.message} disabled editorStyle={{height: 'auto', border: 0, padding: 0}} />
                            </div>
                        )
                    })}
                </div>
            : null }
        </div>
        )
}, "Comments")