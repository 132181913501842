import axios from 'axios';
import { Auth } from 'aws-amplify';
import { store } from './index';
import * as actionTypes from './store/actions/actionTypes';

const axiosWithInterceptors = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
axiosWithInterceptors.defaults.headers.Pragma = "no-cache";
const interceptorFunction = function (config) {
    return Auth.currentSession()
        .then(session => {
            // User is logged in. Set auth header on all requests
            config.headers.Authorization = 'Bearer ' + session.idToken.jwtToken
            config.mode = "cors";
            config.cache = "no-cache";
            return Promise.resolve(config);
        })
        .catch((e) => {
            // No logged-in user: don't set auth header
            return Promise.resolve(config);
        })

}
axiosWithInterceptors.interceptors.request.use(interceptorFunction)

axiosWithInterceptors.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error.response) {
        store.dispatch({ type: actionTypes.INVALID_REQUEST_OCCURED, payload: 'There was an error while fetching data.' });
        return Promise.reject(error);
    }
    if (400 === error.response.status) {
        store.dispatch({ type: actionTypes.INVALID_REQUEST_OCCURED, payload: error.response.data });
        return Promise.reject(error);
    }
    if (401 === error.response.status) {
        store.dispatch({ type: actionTypes.INVALID_REQUEST_OCCURED, payload: 'You are not authorized. Please restart application' });
        return Promise.reject(error);
    }
    if (403 === error.response.status) {
        store.dispatch({ type: actionTypes.INVALID_REQUEST_OCCURED, payload: 'You don\'t have permission to access that resource.' });
        return Promise.reject(error);
    } else if (error.response.status >= 500) {
        store.dispatch({ type: actionTypes.INVALID_REQUEST_OCCURED, payload: "There was an internal server error." });
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

export default axiosWithInterceptors;

export const axiosForFileUploadFunction = (uploadURL) => {
    const axiosForFileUpload = axios.create({
        baseURL: uploadURL,
    });
    // axiosForFileUpload.interceptors.request.use(interceptorFunction);
    return axiosForFileUpload;
}