import React, {Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as exportData from '../../store/actions/exportData';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.dataExport.loading[ownProps.url],
    }
};
  
const mapDispatchToProps = (dispatch) => ({
    exportData: (payload) =>
        dispatch(exportData.dataToExcelStarted(payload)),   
});

class DataExportContainer extends Component {
    render() {
        return (
                <div style={{width: 'auto'}}>
                    <div style={{display: 'inline-block', marginTop: 10, position:'relative'}}>
                        <Button
                            disabled={this.props.loading}
                            style={{marginRight: 'auto'}} 
                            onClick={this.props.exportData.bind(this, {url: this.props.url, tableName: this.props.tableName, fileName: this.props.fileName, fieldsTypes: this.props.fieldsTypes})} 
                            className="removed-text-padding blue-color">
                            <LaunchIcon />
                            Export to Excel
                        </Button>
                        <LoadingSpinner loading={this.props.loading}/>
                    </div> 
                </div>
            )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(DataExportContainer)
